import * as React from "react"
import {Link, LinkProps} from "react-router-dom"

export interface LwLinkProps extends LinkProps {
    style?: React.CSSProperties
}

export const UnstyledLink = (props: LwLinkProps) => {
    const {style, ...rest} = props

    return <Link style={{textDecoration: "none", ...style}} {...rest} />
}
