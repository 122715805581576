/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {Box, Card, createStyles, Divider, Grid} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import React, {ReactNode} from "react"
import {AvantColors} from "../../../../../styles/AvantColors"
import {SmallBodyText, SmallLabelText} from "../../../../../styles/AvantTypography"
import {theme} from "../../../../../styles/MuiThemes"
import {
    AvantBadgeNotRatable,
    AvantBadgeNotStarted,
    AvantBadgeScoringInProgress,
    AvantBadgeSectionInProgress,
    AvantBadgeScoredByEducator,
    AvantBadgeNotAvailable,
    AvantBadgeEarlyExit
} from "../../../../generic-components/AvantBadge"
import {productStore} from "../../../../common/products/ProductStore"
import {reportsStore} from "../../stores/ReportStore"
import {
    STAMP_4S_PRODUCT_ID,
    STAMP_4SE_PRODUCT_ID,
    APT_PRODUCT_ID,
    PLACE_PRODUCT_ID,
    SHL_CONTENT_AREA_ID,
    STAMP_CEFR_PRODUCT_ID,
    STAMP_SIGN_LANGUAGE_PRODUCT_ID
} from "../../../../../util/Constants"
import {PanelBenchmarkConstants} from "../../../../../types/types"

export const LevelKeyContent = () => {
    const {loginProduct} = productStore
    const isSelfEvaluationExplanationVisible = isSelfEvaluationPanelInReportSet()
    const productId = loginProduct ? loginProduct.productId : null
    const contentAreaId = loginProduct ? loginProduct.contentAreaId : null
    let maxLevel: number

    switch (productId) {
        case STAMP_4S_PRODUCT_ID.value():
        case STAMP_SIGN_LANGUAGE_PRODUCT_ID.value():
            maxLevel = 9
            break
        case STAMP_4SE_PRODUCT_ID.value():
            maxLevel = 8
            break
        case APT_PRODUCT_ID.value():
            maxLevel = 10
            break
        case PLACE_PRODUCT_ID.value():
            maxLevel = 7
            break
        case STAMP_CEFR_PRODUCT_ID.value():
            maxLevel = 6
            break
        default:
            throw new Error(`Unsupported product ${productId} wants a level key`)
    }

    const sectionStyle: React.CSSProperties = {
        backgroundColor: "#FFF",
        padding: theme.spacing(2)
    }

    return (
        <Card
            style={{
                backgroundColor: AvantColors.REPORT_BACKGROUND_GRAY_LIGHT,
                color: AvantColors.REPORT_FONT_COLOR_GRAY_3,
                cursor: "default",
                padding: theme.spacing(3)
            }}
        >
            <Box display={"flex"}>
                <Box flexGrow={4} marginRight={3}>
                    <Box display={"flex"} marginBottom={1}>
                        <SmallLabelText style={{marginRight: theme.spacing(1)}}>
                            Levels
                        </SmallLabelText>
                        <SmallBodyText>
                            The numbers 1-{maxLevel} {
                                productId === PLACE_PRODUCT_ID.value() ? "for Writing and Speaking relate to the ACTFL Proficiency Guidelines" :
                                    productId === STAMP_CEFR_PRODUCT_ID.value() ? "relate to the CEFR scale" :
                                        "relate to the ACTFL Proficiency Guidelines"
                            } in the following manner:
                        </SmallBodyText>
                    </Box>
                    <Box style={sectionStyle}>
                        <Levels maxLevel={maxLevel} productId={productId} contentAreaId={contentAreaId}/>
                        {isSelfEvaluationExplanationVisible && <SelfEvaluationExplanation/>}
                        <Box flexGrow={1}/>
                    </Box>
                </Box>
                <Box display={"flex"} flexGrow={1} flexDirection={"column"}>
                    <SmallLabelText style={{marginRight: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                        Statuses
                    </SmallLabelText>
                    <Box flexGrow={1} style={sectionStyle}>
                        <AvantBadgeNotStarted
                            text={"Section Not Started"}
                            style={{marginBottom: theme.spacing(1)}}
                        />
                        <AvantBadgeSectionInProgress
                            text={"Section In Progress"}
                            style={{marginBottom: theme.spacing(1)}}
                        />
                        <AvantBadgeScoringInProgress
                            text={"Scoring In Progress"}
                            style={{marginBottom: theme.spacing(1)}}
                        />
                        {
                            productId === PLACE_PRODUCT_ID.value() &&
                            <AvantBadgeScoredByEducator
                                text={"Rated By Educator"}
                                style={{marginBottom: theme.spacing(1)}}
                            />
                        }
                        <AvantBadgeNotAvailable
                            text={"Not Available"}
                        />
                        <Box flexGrow={1}/>
                    </Box>
                </Box>
            </Box>
        </Card>
    )
}

interface BulletColorProps {
    color: AvantColors
}

const useBulletStyles = makeStyles(() => (
    createStyles({
        bullet: {
            display: "inline-block",
            margin: "0 8px 4px 2px",
            transform: "scale(2.5)",
            color: (props: BulletColorProps) => props.color
        }
    })
))

export const Bullet = (props: BulletColorProps) => {
    const classes = useBulletStyles(props)
    return (
        <Box component={"span"} className={classes.bullet}>•</Box>
    )
}

interface LevelsProps {
    maxLevel: number
    productId: number
    contentAreaId: number | null
}

const calculateLevelNumber = (x: number, y: number) => {
    return x * 3 + 1 + y
}
const calculateCefrLevelNumber = (x: number, y: number) => {
    return x === 2 ? x * 3 + y : x * 3 + 1 + y;
}

const calculateMarginTopValue = (y: number) => {
    return y === 0 ? 0 : 4
}

const Levels: React.FC<LevelsProps> = ({ maxLevel, productId, contentAreaId }) => {
    const levels = ["Novice", "Intermediate", "Advanced"]
    const cefrLevels = ["A Level", "B Level", "C Level"]
    const colors = [AvantColors.NOVICE_FILL, AvantColors.INTERMEDIATE_FILL, AvantColors.ADVANCED_FILL]
    const tiers = ["Low", "Mid", "High"]
    const cefrTiers = ["Pre-A1,A1,A2", "B1,B2", "C1"]

    const isCEFR = productId === STAMP_CEFR_PRODUCT_ID.value();
    const currentLevels = isCEFR ? cefrLevels : levels;
    return (
        <Grid container={true} spacing={3}>
            {currentLevels.map((level, x): ReactNode => (
                <Grid item={true} key={level + x} style={isCEFR ? { padding: "5px", width: "110px", marginLeft: "10px" } : {}}>
                    <Bullet color={colors[x]} />
                    {level.toUpperCase()}
                    {(isCEFR ? cefrTiers[x].split(",") : tiers).map((tier, y) => (
                        <Box key={level + y}>
                            {(calculateLevelNumber(x, y) <= (productId === APT_PRODUCT_ID.value() ? 9 : productId === STAMP_CEFR_PRODUCT_ID.value() ? 7 : maxLevel)) &&
                                <SmallBodyText style={{ marginTop: calculateMarginTopValue(y) }}>
                                    {productId === STAMP_CEFR_PRODUCT_ID.value() ?
                                        <>
                                            <Box component={"strong"}>{calculateCefrLevelNumber(x, y)}</Box> {tier}
                                        </>
                                        :
                                        <>
                                            <Box component={"strong"}>{calculateLevelNumber(x, y)}</Box> {level}-{tier}
                                        </>
                                    }
                                </SmallBodyText>}
                            {(calculateLevelNumber(x, y) === 6 && productId === STAMP_4SE_PRODUCT_ID.value()) &&
                                <SmallBodyText style={{marginTop: 4}}><b>6+</b> Pre-Advanced</SmallBodyText>}
                        </Box>
                    ))}
                </Grid>
            ))}

            <Grid item={true}>
                {productId === APT_PRODUCT_ID.value() &&
                    <>
                        <Bullet color={AvantColors.SUPERIOR_FILL}/>
                        <Box component={"span"}>SUPERIOR</Box>
                        <SmallBodyText><b>10</b> Superior</SmallBodyText>
                    </>
                }
                <Divider style={{margin: theme.spacing(1, 0)}}/>
                <AvantBadgeNotRatable
                    text={PanelBenchmarkConstants.PANEL_NOT_RATABLE}
                    style={{marginBottom: theme.spacing(1)}}
                />
                {productId === PLACE_PRODUCT_ID.value() && contentAreaId == SHL_CONTENT_AREA_ID.value() &&
                    <AvantBadgeEarlyExit text={PanelBenchmarkConstants.PANEL_EARLY_EXIT}/>
                }
                {(productId === STAMP_4S_PRODUCT_ID.value()
                        || productId === STAMP_4SE_PRODUCT_ID.value()|| productId === STAMP_CEFR_PRODUCT_ID.value()
                        || productId === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()) &&
                    // TODO: Remove the statement when we add Level 0 to all products
                    <SmallBodyText>
                        <Box component={"strong"}>0</Box> No Score
                    </SmallBodyText>
                }
            </Grid>
        </Grid>
    )
}

const isSelfEvaluationPanelInReportSet = () => {
    const {reportTableData} = reportsStore

    return (
        reportTableData
        && reportTableData.reportTakeRows.filter( reportTakeRow => {
            return (
                reportTakeRow.readingIsSelfEvaluationPanel === true
                || reportTakeRow.listeningIsSelfEvaluationPanel === true
            )
        }).length > 0
    )
}

const SelfEvaluationExplanation = () => (
    <Grid container={true}>
        <Grid item={true} xs={12}>
            <Divider style={{margin: theme.spacing(1, 0)}}/>
            <SmallBodyText>SE* indicates a self evaluated score</SmallBodyText>
        </Grid>
    </Grid>
)
