import {Close} from "@material-ui/icons"
import React from "react"
import {Modal} from "react-bootstrap"
import {AvantModalCloseIcon} from "../../app/generic-components/AvantModalCloseIcon"
import {theme} from "../../styles/MuiThemes"
import {
    STAMP_4S_PRODUCT_ID, STAMP_4SE_PRODUCT_ID, STAMP_SIGN_LANGUAGE_PRODUCT_ID, RECEPTIVE,
    EXPRESSIVE, NOELLA_IMAGES, IMAGES_BASE_URL, LISTENING, SELFEVALUATION
} from "../../util/Constants"
import {ProductId} from "../../validation/ValidPrimaryKey"
import Button from "../Button/Button"

interface StartTestModalProps {
    showModal: boolean
    selfEvaluation: boolean
    currentTestName: string
    closeModal: () => void
    startPanel: () => void
    productId: ProductId
}

export default class StartTestModal extends React.Component<StartTestModalProps, {}> {
    constructor(props: StartTestModalProps) {
        super(props)
        this.state = {
            showModal: props.showModal,
            currentTestName: props.currentTestName,
            selfEvaluation:props.selfEvaluation
        }
    }

    closeModal = () => {
        this.props.closeModal()
    }

    handleYes = () => {
        this.props.startPanel()
    }

    render() {
        const {showModal, selfEvaluation, currentTestName, productId} = this.props  
        let imageUrl = `${IMAGES_BASE_URL}${currentTestName}.svg`
        let imageClass = "start-test-modal__content__image"
        if (productId.value() === STAMP_4SE_PRODUCT_ID.value()) {
            const baseUrl = NOELLA_IMAGES
            imageUrl = `${baseUrl}${currentTestName.toLowerCase()}_small.jpg`
            imageClass = `${imageClass} start-test-modal__content__image--4se`
        }

        if (currentTestName === "Listening" && this.props.productId.value() === STAMP_4S_PRODUCT_ID.value()) {
            imageUrl = selfEvaluation ? SELFEVALUATION : LISTENING
        }
        else if (currentTestName === "Reading" && this.props.productId.value() === STAMP_4S_PRODUCT_ID.value()) {
            imageUrl = selfEvaluation ? SELFEVALUATION : `${IMAGES_BASE_URL}${currentTestName}.svg`
        }
        else if (currentTestName === "Listening" && this.props.productId.value() === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()) {
            imageUrl = selfEvaluation ? SELFEVALUATION : RECEPTIVE
        }
         else if (currentTestName === "Speaking" && this.props.productId.value() === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()) {
            imageUrl = selfEvaluation ? SELFEVALUATION : EXPRESSIVE
         }else{}


        return (
            <Modal className="start-test-modal" show={showModal} onHide={this.closeModal}>
                <Modal.Header closeButton={false} style={{justifyContent: "center", alignItems: "center", padding: theme.spacing(3)}}>
                        {currentTestName === "Listening" && this.props.productId.value() === STAMP_4S_PRODUCT_ID.value()
                            ? (
                                <Modal.Title data-tst-id={"start-test-modal-title"}>
                                    Start the Listening Section?
                                </Modal.Title>
                            )
                            : currentTestName === "Listening" && this.props.productId.value() === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()
                            ? (
                                <Modal.Title data-tst-id={"start-test-modal-title"}>
                                    Start the Receptive Section?
                                </Modal.Title>
                            )
                            : currentTestName === "Speaking" && this.props.productId.value() === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()
                            ? (
                                <Modal.Title data-tst-id={"start-test-modal-title"}>
                                    Start the Expressive Section?
                                </Modal.Title>
                            )
                            : 
                            (
                                <Modal.Title data-tst-id={"start-test-modal-title"}>
                                    {`Start the ${currentTestName} Section?`}
                                </Modal.Title>
                            )
                        }
                    <AvantModalCloseIcon
                        onClick={this.closeModal}
                    >
                        <Close/>
                    </AvantModalCloseIcon>
                </Modal.Header>
                <Modal.Body>
                    <div className="start-test-modal__content">
                        <div className={imageClass}>
                            <img src={imageUrl} alt=""/>
                        </div>
                        <div>
                            {currentTestName === "Listening" && this.props.productId.value() === STAMP_4S_PRODUCT_ID.value()
                                ? (
                                    <p
                                        className="start-test-modal__content__text"
                                        data-tst-id="start-test-modal-content"
                                    >
                                        {"You have selected the "}
                                        <b>listening</b>
                                        {" section of the test. Are you sure you want to continue?"}
                                    </p>
                                )
                                : currentTestName === "Listening" && this.props.productId.value() === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()
                                ? (
                                    <p
                                        className="start-test-modal__content__text"
                                        data-tst-id="start-test-modal-content"
                                    >
                                        {"You have selected the "}
                                        <b>receptive</b>
                                        {" section of the test. Are you sure you want to continue?"}
                                    </p>
                                )
                                :currentTestName === "Speaking" && this.props.productId.value() === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()
                                ? (
                                    <p
                                        className="start-test-modal__content__text"
                                        data-tst-id="start-test-modal-content"
                                    >
                                        {"You have selected the "}
                                        <b>expressive</b>
                                        {" section of the test. Are you sure you want to continue?"}
                                    </p>
                                )
                                :(
                                    <p
                                        className="start-test-modal__content__text"
                                        data-tst-id="start-test-modal-content"
                                    >
                                        {"You have selected the "}
                                        <b>{currentTestName.toLowerCase()}</b>
                                        {" section of the test. Are you sure you want to continue?"}
                                    </p>
                                )
                            }
                            <div className="start-test-modal__content__buttons">
                                <Button
                                    className="avant-button--primary start-test-modal__content__buttons__button"
                                    block={true}
                                    testId="modal-yes-button"
                                    onClick={this.handleYes}
                                >
                                    YES
                                </Button>
                                <Button
                                    className="avant-button--link start-test-modal__content__buttons__button"
                                    block={true}
                                    testId="modal-no-button"
                                    onClick={this.closeModal}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
