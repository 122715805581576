import * as React from "react"
import {PrintOptions, PrintViewContainer} from "../../common/components/PrintViewContainer"
import {findFilters, ReportRouteProps} from "../../common/components/ReportsPage"
import {BlueReportType} from "../../common/models/BlueReportType"

export const SchoolPrintAll: React.FC<ReportRouteProps> = (props) => {
    const filters = findFilters(props)
    return <PrintViewContainer filters={filters} printOptions={PrintOptions.ALL} report={BlueReportType.SCHOOL}/>
}
export const SchoolPrintSummary: React.FC<ReportRouteProps> = (props) => {
    const filters = findFilters(props)
    return <PrintViewContainer filters={filters} printOptions={PrintOptions.SUMMARY} report={BlueReportType.SCHOOL}/>
}
export const SchoolPrintLocations: React.FC<ReportRouteProps> = (props) => {
    const filters = findFilters(props)
    return <PrintViewContainer filters={filters} printOptions={PrintOptions.LIST} report={BlueReportType.SCHOOL}/>
}
