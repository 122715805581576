import * as HttpStatus from "http-status-codes"
import {observer} from "mobx-react"
import React from "react"
import {FormLabel, FormControl, FormGroup} from "react-bootstrap"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"
import {loadingSpinnerStore} from "../../app/common/loaders/LoadingSpinnerStore"
import {productStore} from "../../app/common/products/ProductStore"
import {RoutePaths} from "../../app/routes/Routes"
import ApiService from "../../services/ApiService"
import HelperService from "../../services/HelperService"
import {ApiErrorResponse, LastPage, TestState} from "../../types/types"
import {LOGIN_NAME_NOT_FOUND, SUPPORT_MESSAGE} from "../../util/Constants"
import {TakeCode} from "../../validation/TakeCode"
import {addTakeDispatches, TakeDispatches} from "../App/App"
import Button from "../Button/Button"


const mapDispatchToProps = (dispatch: Dispatch): TakeDispatches => {
    return addTakeDispatches({}, dispatch)
}

export interface LoginContinueTestProps extends RouteComponentProps<{}>, TakeDispatches {}

interface LoginContinueTestState {
    error: boolean | string
    loginName: string
}

@observer
class LoginContinueTest extends React.Component<LoginContinueTestProps, LoginContinueTestState> {
    constructor(props: LoginContinueTestProps) {
        super(props)
        this.state = {
            loginName: "",
            error: false
        }
    }

    handleLogin = (event: any) => {
        event.preventDefault()
        const lastPage: LastPage = {
            url: RoutePaths.LOGIN,
            takeCode: this.state.loginName
        }

        ApiService.checkLoginContinueState(productStore.loginProduct!!.loginId, new TakeCode(this.state.loginName)).then(
            () => {
                productStore.driver!!.helper
                    .refreshTest(this.props, lastPage, productStore.loginProduct!!)
                    .then(
                        (testState: TestState) => {
                            localStorage.setItem("lastPage", JSON.stringify(lastPage))
                            productStore.driver!!.helper.refreshTestCallback(testState, this.props)
                        },
                        (err: ApiErrorResponse) => {
                            this.setState({error: SUPPORT_MESSAGE})
                            HelperService.simpleLogout(false)
                            throw Error(`[LoginContainer.refreshTest()] unknown error in refreshTest: 
                                    ${err.message}`)
                        }
                    )
            },
            (error: ApiErrorResponse) => {
                if (error.response.status === HttpStatus.NOT_FOUND) {
                    this.setState({error: LOGIN_NAME_NOT_FOUND})
                } else if (error.response.data.kind === "RosteredTestLoginFail") {
                    this.setState({error: LOGIN_NAME_NOT_FOUND})
                } else {
                    this.setState({error: SUPPORT_MESSAGE})
                }
            }
        )
    }

    handleLoginNameChange = (event: any) => this.setState({loginName: event.target.value})

    handleCreateAnAccount = () => {
        this.props.history.push("/profile-login-name")
    }

    componentDidMount() {
        document.title = `Avant | Login`
        HelperService.enableTextSelection()

        const loginProduct = productStore.loginProduct
        if (!loginProduct) {
            this.props.history.push(RoutePaths.LOGIN)
        }

        loadingSpinnerStore.hideLoadingSpinner = true
    }

    render() {
        const driver = productStore.driver

        if (!driver) {
            return null
        }

        return (
            <div className="login">
                <div>
                    <div className="login__image-container">
                        <div>
                            <img
                                className="login__image-container__image"
                                alt=""
                                src="https://cdn.avantassessment.com/resources/common/img/avant-logo.svg"
                            />
                        </div>
                    </div>
                    <div className="login__login-container">
                        <div className="login__login-container__top">
                            <div className="login__login-container__top__title">{driver.strings.welcomeBack}</div>
                            <div className="login__login-container__top__text">{driver.strings.enterLoginName}</div>
                            {this.state.error === LOGIN_NAME_NOT_FOUND && (
                                <div className="login__login-container__top__warning" data-tst-id="login-error-banner">
                                    <div className="login__login-container__top__warning__text" data-tst-id="login-error-message">
                                        {driver.strings.continueWarningStart}
                                        {"\u00A0"}
                                        <span
                                            className="login__login-container__bottom__text--blue"
                                            onClick={this.handleCreateAnAccount}
                                        >
                                            {driver.strings.continueWarningEnd}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={
                                this.state.error === LOGIN_NAME_NOT_FOUND
                                    ? "login__login-container__form login__login-container__form-error"
                                    : "login__login-container__form"
                            }
                        >
                            <form onSubmit={this.handleLogin}>
                                <FormGroup>
                                    <FormLabel>{driver.strings.loginName}</FormLabel>
                                    <FormControl
                                        type="text"
                                        value={this.state.loginName}
                                        placeholder=""
                                        data-tst-id="login-name"
                                        onChange={this.handleLoginNameChange}
                                    />
                                </FormGroup>
                                <Button
                                    style={{marginBottom: "48px"}}
                                    className="login__login-container__button avant-button--primary"
                                    onClick={this.handleLogin}
                                >
                                    {driver.strings.login}
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    null,
    mapDispatchToProps
)(LoginContinueTest)
