import * as React from "react";
import {Button, Tooltip} from "@material-ui/core";
import PersonAdd from "@material-ui/icons/PersonAdd";
import {CleverAdhocRosterDialog} from "./rostering/CleverAdhocRosterDialog";
import {useEffect, useState} from "react";
import CleverRosterApi from "../../../../services/CleverRosterApi";

export const CustomSectionsRosterStudent: React.FC = (props) => {
    const [rosterOpen, setRosterOpen] = useState(false)
    const [canView, setCanView] = useState(false)

    useEffect(() => {
        CleverRosterApi.customSectionsAllowed()
            .then((response) => {
                if (response === true) {setCanView(true)} else {setCanView(false)}
            })
    }, [])

    const handleRosterStudents = () => {
        setRosterOpen(true)
    }
    const handleRosterClose = async () => {
        setRosterOpen(false)
    }
    return (
            <>
            {canView && (
            <Tooltip title={"Roster Student to Custom Sections"}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    startIcon={<PersonAdd />}
                    onClick={handleRosterStudents}
                >
                    Roster Custom Sections
                </Button>
            </Tooltip>
            )}
            <CleverAdhocRosterDialog
                open={rosterOpen}
                handleRosterClose={handleRosterClose}
            />
        </>
    )
}