import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {authStore} from "../common/authentication/AuthStore"
import {productStore} from "../common/products/ProductStore"
import {CLASSLINK_SSO_LOGIN_PRODUCT_ID} from "../../util/Constants"
import {LoginProductContentAreaData} from "../../types/types"
import {Redirect} from "react-router"
import {loadingSpinnerStore} from "../common/loaders/LoadingSpinnerStore"

export interface QParamObject {
    key: string,
    value: string
}
export interface ClasslinkAuthData {
    token: string,
    eulaAgreeDate: string,
    kind: string
}
export interface myInfoData {
    UserId: string,
    TenantId: string,
    StateId: string,
    StateName: string,
    BuildingId: string,
    AuthenticationType: string,
    DisplayName: string,
    FirstName: string,
    LastName: string,
    Email: string,
    LoginId: string,
    ImagePath: string,
    LanguageId: string,
    Language: string,
    DefaultTimeFormat: string,
    Profile: string,
    ProfileId: string,
    Tenant: string,
    Building: string,
    Role: string,
    Role_Level: string,
    LastAccessTime: string,
    OrgId: string,
    SourcedId: string
}
export interface classLinkData {
    classLinkToken: string,
    myInfoData: myInfoData,
   
}
export interface SSOClasslinkAuthResponse {
    success: number,
    classLinkData: classLinkData,
    classLinkRole: string,
    auth: ClasslinkAuthData,
}
export interface SSOClasslinkAuthedData {
    sso: SSOClasslinkAuthResponse,
    loginId:number
}


 const SSOLoginPageForClassLink: React.FC = observer(() => {

    // Show the loading spinner...
    loadingSpinnerStore.hideLoadingSpinner = false
    const isCancelled = React.useRef(false)
    const [qParams, setQParams] = React.useState<string | undefined>(undefined)
    const [loggedInUser, setLoggedInUser] = React.useState<string>("U")
    const [fetchComplete, setFetchComplete] = React.useState(false)

    const checkAndClearLocalStorage = () => {
        authStore.logout()
    }

    const getQueryParams = async () => {
        if(qParams==null){
            const vals = window.location.search
            const urlParams = new URLSearchParams(vals);
            const code = urlParams.get('code')
            await sendCodeToAPI(code).then(() => {
                if (!isCancelled.current) {
                    setQParams("Done")
                }
            })
        }
          
          
        }

    const sendCodeToAPI = async (code:any) => {

        checkAndClearLocalStorage()

        const res = await authStore.ssoAuthenticateForClasslink(code)
        if (res.loginId === -1) {
            setLoggedInUser("U")
            setFetchComplete(true)
            return "U"
        }
        let userT: string = ""
        let role=res.sso.classLinkRole
        if(role !== null) {
            let productName:string = "Dashboard"

            switch (role) {
                case "teacher" :
                    userT = "T"
                    productName = "Teacher Dashboard"
                    break;
                case "student" :
                    userT = "S"
                    productName = "Student Dashboard"
                    break;
                case "administrator" :
                    userT = "D"
                    productName = "Admin Dashboard"
                    break;
                case "school_administrator" :
                    userT = "D"
                    productName = "Admin Dashboard"
                    break;
                default:
                    userT = "U"
                    break;

            }
            setLoggedInUser(userT)

            if (userT !== 'U') {
                const lpInfo: LoginProductContentAreaData = {
                    loginId: res.loginId,
                    userType: userT,
                    productName: productName,
                    productId: CLASSLINK_SSO_LOGIN_PRODUCT_ID.value(),
                    contentAreaId: 0,
                    contentAreaName: "None",
                    rostered: true,
                    userName: authStore.username
                }

                productStore.setLoginProduct(lpInfo)

                setFetchComplete(true)

            } else {
                // TODO this is an error case
                // log.debug("I elsed when trying to set up group data!!!")
            }

        } else {
            setLoggedInUser("U")
        }
        setFetchComplete(true)
        return userT
    }


    useEffect( () => {
        getQueryParams()
        return () => {
            isCancelled.current = true
        }
    }, [])


    if (fetchComplete) {
        loadingSpinnerStore.hideLoadingSpinner = true
        if (loggedInUser === "T") {
            return <Redirect to={"/sso/teacher"} />
        } else if (loggedInUser === "S") {
            return <Redirect to={"/sso/student"} />
        } else if (loggedInUser === "D") {
            return <Redirect to={"/sso/distadmin"} />
        } else {
            return <Redirect to={"/sso/sso_unauthorized"} />
        }
    } else {
        // TODO this is an error case
        // log.debug("SSOLoginPage.tsx Auth Store Username: " + authStore.username)
        // log.debug("SSOLoginPage.tsx Product Store product id: " + productStore.loginProduct)
        // log.debug("SSOLoginPage.tsx Auth User Local Storage: " + localStorage.getItem("authUser"))
        // log.debug("SSOLoginPage.tsx Login product local storage: " + localStorage.getItem("loginProduct"))
    }

    return <></>
})
export default SSOLoginPageForClassLink