import React from "react"
import {
    Bar,
    BarChart,
    LabelProps,
    Rectangle,
    RectangleProps,
    ReferenceArea,
    ReferenceLine,
    ReferenceLineProps,
    ResponsiveContainer,
    Text,
    TextProps,
    XAxis,
    YAxis
} from "recharts"
import {AvantColors} from "../../../../../styles/AvantColors"
import {TestingGroupAverages} from "../../../district-school/models/ReportSummaryGraphs"
import {CompositeScoreName} from "../../models/TakeSkillAggregate"
import {findBarProperties} from "./BarUtils"
import {productStore} from "../../../../common/products/ProductStore"
import {
    STAMP_4S_PRODUCT_ID,
    STAMP_4SE_PRODUCT_ID,
    APT_PRODUCT_ID,STAMP_CEFR_PRODUCT_ID,
    STAMP_SIGN_LANGUAGE_PRODUCT_ID
} from "../../../../../util/Constants"
import {SkillAverageBar} from "../../../individual/components/graphics/SkillAverageBar/SkillAverageBar"

// TODO: Copy this into the newReports directory and change it to be more modular?

export interface SkillAverageChartProps {
    averages: TestingGroupAverages
}

interface Skill {
    label: string
    avg: number
    benchmark?: string
}

const xMinThreshold = 0.5
export const SkillAverageChart: React.FC<SkillAverageChartProps> = (
    {
        averages
    }) => {
    let data: Skill[] = averages.scores
        .map(average => ({label: average.skillName, avg: average.average}))

    data.push({label: "Composite Score", avg: averages.compositeScore == null ? 0 : averages.compositeScore})

    // Adjust 0's to not mess up x-axis presentation
    data.forEach((datum, index) => {
        if (datum.avg === null) {
            // Drop the datum, so that it won't be rendered.
            delete data[index]
        }
        else {
            if (datum.avg! <= xMinThreshold) {
                datum.avg = xMinThreshold
            }
            datum.avg = Number(datum.avg.toFixed(2))
        }
    })
    // Compact the (potentially sparse) data array.
    data = data.filter(() => true)

    let graphWidth = '100%'
    if (data.length < 5) {
        if (data.length < 2) {
            graphWidth = '35%'
        }
        else {
            // Deduct 15% for each missing bar graph.
            const graphWidthPercentage = 100 - 15 * (5 - data.length)
            graphWidth = graphWidthPercentage + '%'
        }
    }

    const {loginProduct} = productStore
    const productId = loginProduct ? loginProduct.productId : ""
    let maxLevel

    switch (productId) {
        case STAMP_4S_PRODUCT_ID.value():
        case STAMP_SIGN_LANGUAGE_PRODUCT_ID.value():
            maxLevel = 9
            break
        case STAMP_4SE_PRODUCT_ID.value():
            maxLevel = 8
            break
        case APT_PRODUCT_ID.value():
            maxLevel = 10
            break
        case STAMP_CEFR_PRODUCT_ID.value():
            maxLevel = 6
            break
        default:
            throw new Error(`Unsupported product ${productId} wants a bar chart`)
    }

    const height = 250
    const yAxisWidth = 140

    if (!maxLevel) {
        return <></>
    }

    const ticks: number[] = []
    for (let i: number = 1; i <= maxLevel; i++) {
        ticks.push(i)
    }
   const isCEFR = productId === STAMP_CEFR_PRODUCT_ID.value();
    return (
        <div style={{textAlign: "center"}}>
            <div style={{display: "inline-block", width: graphWidth}}>
                <ResponsiveContainer
                    width={"100%"}
                    height={height}
                >
                    <BarChart
                        data={data}
                    >
                        <ReferenceArea
                            y1={isCEFR ? xMinThreshold : xMinThreshold + .5}
                            y2={isCEFR ? 3 : 4}
                            fill={AvantColors.REPORT_BACKGROUND_GRAY_LIGHT}
                            shape={(props) => <SkillReferenceArea label={productId === STAMP_CEFR_PRODUCT_ID.value() ? "A Level" : "NOVICE"} {...props} />} />

                        <ReferenceArea
                            y1={isCEFR ? 3 : 4}
                            y2={isCEFR ? 5 : 7}
                            fill="#FFF"
                            shape={(props) => <SkillReferenceArea label={productId === STAMP_CEFR_PRODUCT_ID.value() ? "B Level" : "INTERMEDIATE"} {...props} />}
                        />
                        <ReferenceArea
                            y1={isCEFR ? 5 : 7}
                            y2={(maxLevel === 10) ? maxLevel - 1 : maxLevel}
                            fill={AvantColors.REPORT_BACKGROUND_GRAY_LIGHT}
                            shape={(props) => <SkillReferenceArea label={productId === STAMP_CEFR_PRODUCT_ID.value() ? "C Level" : "ADVANCED"} {...props} />}
                        />

                        {
                            maxLevel === 10 &&
                            <ReferenceArea
                                y1={maxLevel}
                                y2={maxLevel}
                                fill="#FFF"
                                shape={(props) => <SkillReferenceArea label={"SUPERIOR"} {...props} />}
                            />
                        }

                        <ReferenceLine
                            y={isCEFR ? xMinThreshold: xMinThreshold + .5}
                            shape={(props) => <YAxisReferenceLine {...props} />}
                        />
                        <ReferenceLine
                            y={isCEFR ? 3 : 4}
                            shape={(props) => <YAxisReferenceLine {...props} />}
                        />
                        <ReferenceLine
                            y={isCEFR ? 6 : 7}
                            shape={(props) => <YAxisReferenceLine {...props} />}
                        />
                        <ReferenceLine
                            y={(maxLevel === 10) ? maxLevel - 1 : maxLevel}
                            shape={(props) => <YAxisReferenceLine {...props} />}
                        />
                        <XAxis
                            axisLine={false}
                            tickLine={false}
                            dataKey="label"
                            type={"category"}
                            tick={{fontWeight: "bold", fill: AvantColors.REPORT_FONT_COLOR_BLACK, fontSize: "16px"}}
                            padding={{left: yAxisWidth, right: 0}}
                        />
                        {isCEFR ?(
                            <YAxis
                                interval={0}
                                tickCount={maxLevel}
                                axisLine={false}
                                tickLine={false}
                                ticks={ticks}
                                domain={[xMinThreshold, maxLevel + 1]}
                                width={yAxisWidth}
                                mirror={true}
                                tick={({ x, y, payload }) => {
                                    const labels = ["PA1 (1)", "A1 (2)", "A2 (3)", "B1 (4)", "B2 (5)", "C1 (6)"];
                                    const value = labels[payload.value - 1];
                                    return (
                                        <g transform={`translate(${x},${y})`}>
                                            <text
                                                x={80}
                                                y={0}
                                                dy={18}
                                                textAnchor="start"
                                                fill={AvantColors.REPORT_FONT_COLOR_GRAY_3}
                                            >
                                                {value}
                                            </text>
                                        </g>
                                    )
                                }}
                            />
                            ) 
                            :(
                            <YAxis
                                interval={0}
                                tickCount={maxLevel}
                                axisLine={false}
                                tickLine={false}
                                ticks={ticks}
                                tick={(props) => <YAxisTick {...props} />}
                                domain={[xMinThreshold, maxLevel + 1]}
                                width={yAxisWidth}
                                mirror={true}
                            />
                            )
                             }
                        <Bar
                            dataKey="avg"
                            label={(props: LabelProps) => <SkillAverageBarLabel {...props} />}
                            shape={(props) => <SkillBar {...props} />}
                            isAnimationActive={false}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

interface SkillBarProps extends RectangleProps {
    value?: number
    avg?: number
    label?: string
}

const SkillBar: React.FC<SkillBarProps> = (props) => {
    const {value, avg, x, y, width, height, label} = props
    const isComposite = label === CompositeScoreName
    const fill = findBarProperties(avg!, isComposite)
    const borderRadius = 6
    if (value! > xMinThreshold) {
        return (
            <SkillAverageBar
                id={label!.replace(" ", "-")}
                x={x!}
                y={y!}
                height={height!}
                width={width!}
                borderRadius={borderRadius}
                fill={fill}
            />
        )
    } else {
        return null
    }
}

const cefrBenchmarks = (level:number): string =>{
    let Benchmarks = {1: "PA1", 2: "A1", 3:"A2", 4: "B1", 5: "B2", 6: "C1"}
    return Benchmarks[level]
}
const SkillAverageBarLabel: React.FC<LabelProps> = ({ x, y, width, value }) => {
    const {loginProduct} = productStore
    const productId = loginProduct ? loginProduct.productId : ""
    const isCEFR = productId === STAMP_CEFR_PRODUCT_ID.value();

    if (!value || value <= xMinThreshold) {
        return null
    }

    let yPad = 5

    const xNum = Number(x) ? Number(x) : 0
    const widthNum = Number(width) ? Number(width) : 0
    const yNum = Number(y) ? Number(y) : 0

    const xPos = xNum + widthNum! / 2
    let verticalAnchor: TextProps["verticalAnchor"] = "end"
    if (Number(value) >= 2) {
        verticalAnchor = "start"
        yPad = -yPad - 2
    }

    const isIndividualReport = window.location.pathname.split("/").includes("individuals")
    const barLevel = isIndividualReport && isCEFR ? cefrBenchmarks(Math.floor(value as number)) : value

    const fill = findBarProperties(Number(value))

    return (
        <g transform={`translate(${xPos},${yNum - yPad})`}>
            <Text textAnchor="middle" verticalAnchor={verticalAnchor} fontWeight={"bold"} fontSize={14}
                fill={fill.textFill}>
                {barLevel}
            </Text>
        </g>
    )
}

interface YAxisReferenceLineProps {
    // props found in a console.log()...
    x1: number
    x2: number
    y1: number
    y2: number
}

const YAxisReferenceLine: React.FC<ReferenceLineProps & YAxisReferenceLineProps> = ({x1, x2, y1, y2}) => {
    return (
        <line
            stroke={AvantColors.REPORT_REFERENCE_LINE_GRAY}
            strokeWidth={1}
            x1={0}
            x2={x2 - x1}
            y1={y1}
            y2={y2}
            opacity={0.5}
        />
    )
}

interface YAxisTickProps {
    y: number
    width: number
    payload: {
        value: number
    }
}

const YAxisTick: React.FC<YAxisTickProps> = ({y, width, payload}) => {
    return (
        <Text x={width} y={y} verticalAnchor={"middle"} fill={AvantColors.REPORT_FONT_COLOR_GRAY_3}>
            {payload.value}
        </Text>
    )
}

interface SkillReferenceAreaProps {
    label: string | number 
    fill:  string
    y: number 
    height: number
    x?: number
    width?: number
 }
const SkillReferenceArea: React.FC<SkillReferenceAreaProps> = (props) => {

    const {y, height, fill, label, width} = props
    // console.log("**********************************************************************")
    // console.log("y: " + y)
    // console.log("height: " + height)
    // console.log("label: " + label)
    // console.log(`props: ${JSON.stringify(props)}`)
    // console.log("**********************************************************************")

    return (
        <>
            <Rectangle x={0} y={y} width={width} height={height} fill={fill} fillOpacity={1}/>
            <Text x={20} y={y! + (height! / 2)} fill={AvantColors.REPORT_FONT_COLOR_GRAY_3} verticalAnchor={"middle"}>
                {label}
            </Text>
        </>
    )
}
