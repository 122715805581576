import {createStyles, WithStyles, withStyles} from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import sanitizeHtml from "sanitize-html"
import {ItemContent} from "../../app/common/item/models/ItemContent"
import {ProductDriver} from "../../app/common/products/ProductStore"
import { IItem, ItemContentSectionEnum, ItemContentTypeEnum, ItemFormatEnum, LoginProductContentAreaData } from "../../types/types"
import {
    
    APT_PRODUCT_ID, 
   
    CONTENT_AREA_ID_ARABIC,
   
    PLACE_PRODUCT_ID, 
   
    READY_PRODUCT_ID,
   
    STAMP_SIGN_LANGUAGE_PRODUCT_ID,
    STAMP_4S_PRODUCT_ID,
   
    STAMP_4SE_PRODUCT_ID,
    STAMP_CEFR_PRODUCT_ID

} from "../../util/Constants"
import AudioPlayer from "../AudioPlayer/AudioPlayer"

const styles = createStyles({
    arabicFontSize: {
        fontSize: 20,
        lineHeight: 1.5,
        marginBottom: 8
    }
})

type Props = {
    item: IItem
    isRtlLayout: boolean
    product: LoginProductContentAreaData
    driver: ProductDriver
} & WithStyles<typeof styles>

class ResponsePrompt extends React.Component<Props, {}> {

    renderNonEditableInstructionText = (content: string): any => {
        content = sanitizeHtml(content, {
            allowedTags: ["b", "strong", "i", "em", "p"]
        })
        if (content === "null") {
            content = ""
        }
        return content
    }

    render() {
        const {item, isRtlLayout, product, driver, classes} = this.props

        if (driver === undefined) {
            return null
        }

        const prompts: ItemContent[] = []
        if (item.itemContent5 !== undefined) {
            prompts.push(item.itemContent5)
        }
        if (item.itemContent6 !== undefined) {
            prompts.push(item.itemContent6)
        }

        if (
            (product.productId === READY_PRODUCT_ID.value() ||
                product.productId === PLACE_PRODUCT_ID.value() ||
                product.productId === STAMP_4S_PRODUCT_ID.value() ||
                product.productId === STAMP_CEFR_PRODUCT_ID.value() ||
                product.productId === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value() ||
                product.productId === STAMP_4SE_PRODUCT_ID.value()) &&
            product.productId !== APT_PRODUCT_ID.value()
        ) {
            let hasChooseText = false
            prompts.forEach((prompt: ItemContent) => {
                if (prompt.content !== null && prompt.content !== undefined) {
                    const content = prompt.content.toString().toLowerCase()
                    if (item.format === ItemFormatEnum.MULTIPLE_CHOICE && content.includes("choose the best answer.")) {
                        hasChooseText = true
                    } else if (item.format === ItemFormatEnum.CHECKBOX && content.includes("choose the two best answers.")) {
                        hasChooseText = true
                    }
                }
            })
            if (item.format === ItemFormatEnum.MULTIPLE_CHOICE
                && item.choices[0]
                && item.choices[0].type !== ItemContentTypeEnum.PLAIN_IMAGE
                && !hasChooseText) {
                prompts.push({
                    id: Math.random(),
                    itemId: item.id,
                    type: ItemContentTypeEnum.TEXT,
                    section: ItemContentSectionEnum.PROMPT,
                    content: driver.strings.chooseBestAnswer,
                    position: null,
                    targetZones: [],
                    distractorOrder: 0,
                    description: null,
                    binName: item.binName
                })
            } else if (item.format === ItemFormatEnum.CHECKBOX && !hasChooseText) {
                prompts.push({
                    id: Math.random(),
                    itemId: item.id,
                    type: ItemContentTypeEnum.TEXT,
                    section: ItemContentSectionEnum.PROMPT,
                    content: driver.strings.chooseBestTwoAnswers,
                    position: null,
                    targetZones: [],
                    distractorOrder: 0,
                    description: null,
                    binName: item.binName
                })
            }
        }

        const contents: any = []
        prompts.forEach((prompt: ItemContent) => {
            let className = prompt.type.includes(ItemContentTypeEnum.RTL) || isRtlLayout ? "direction-rtl" : ""
            if (prompt) {
                if (prompt.type === ItemContentTypeEnum.PLAIN_IMAGE) {
                    contents.push(<img src={prompt.content as string} alt="" />)
                } else if (prompt.type === ItemContentTypeEnum.MP3_SOUND) {
                    contents.push(
                        <AudioPlayer
                            id={prompt.id}
                            src={prompt.content as string}
                            buttonClassName="avant-button--audio-player"
                            iconClassName="avant-button--audio-player__icon"
                            audioClassName={className}
                        />
                    )
                } else if (item.format === ItemFormatEnum.YES_NO || ItemFormatEnum.MULTIPLE_CHOICE || ItemFormatEnum.CHECKBOX) {
                    const tmpTxt = this.renderNonEditableInstructionText(prompt.content as string)
                    className =
                        item.format === ItemFormatEnum.YES_NO
                            ? `${className} yes-no-prompt`
                            : item.format === ItemFormatEnum.MULTIPLE_CHOICE
                            ? `${className} mc-prompt`
                            : item.format === ItemFormatEnum.CHECKBOX
                            ? `${className} cb-prompt`
                            : `${className} other-prompt`
                    if (
                        tmpTxt
                            .toString()
                            .toLowerCase()
                            .includes("choose the two best answers.") ||
                        tmpTxt
                            .toString()
                            .toLowerCase()
                            .includes("choose the best answer.")
                    ) {
                        className = "prompt-substring"
                    }

                    contents.push(
                        React.createElement("div", {
                            className,
                            dangerouslySetInnerHTML: {__html: tmpTxt as string}
                        })
                    )
                } else {
                    contents.push(
                        React.createElement("div", {
                            className,
                            dangerouslySetInnerHTML: {__html: prompt.content as string}
                        })
                    )
                }
            }
        })

        return (
            <>
                {contents.length > 0 && (
                    <div className="prompt">
                        {contents.map((content: any, index: number) => {
                            return (
                                <div
                                    key={index}
                                    className={classNames(
                                        "prompt-content",
                                        product.contentAreaId === CONTENT_AREA_ID_ARABIC
                                            ? classes.arabicFontSize
                                            : undefined
                                    )}
                                >
                                    {content}
                                </div>
                            )
                        })}
                    </div>
                )}
            </>
        )
    }
}

export default withStyles(styles)(ResponsePrompt)
