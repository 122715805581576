import {Print} from "@material-ui/icons"
import * as React from "react"
import {AvantPopperButton} from "../../../generic-components/AvantPopperButton"
import {AvantPopperMenu, AvantPopperMenuProps} from "../../../generic-components/AvantPopperMenu"

export const ReportsPrintButton = (props: AvantPopperMenuProps ) => {

    return (
        <AvantPopperButton
            title={<Print/>}
        >
            <AvantPopperMenu contents={props.contents}/>
        </AvantPopperButton>
    )
}
