import {ApiErrorResponse, CleverLinkResponse, SsoEntityList} from "../types/types"
import {AxiosResponse} from "axios"
import ApiService from "./ApiService"


export default class CleverApi {

    static getCleverId(): Promise<CleverLinkResponse> {
        const url: string = `${ApiService.API_URL}getCleverLink`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => { resolve(res.data)},
                (err: ApiErrorResponse) => { reject(err) }
            )
        })
    }

    static putSSOEntity(ssoEntity: SsoEntityList): Promise<Array<SsoEntityList>> {
        const url: string = `${ApiService.API_URL}sso-entity/${ssoEntity.ssoEntity.id}`

        return new Promise((resolve, reject) => {
            ApiService.put(url, ssoEntity).then(
                (res: AxiosResponse) => { resolve(res.data) },
                (err: ApiErrorResponse) => { reject(err) }
            )
        })
    }
}
