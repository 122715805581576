/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import React, {ReactElement} from "react"
import {TableCellProps} from "@material-ui/core/TableCell"
import {Box, makeStyles} from "@material-ui/core"
import {AvantColors} from "../../../../styles/AvantColors"
import {SmallBodyText} from "../../../../styles/AvantTypography"
import {theme} from "../../../../styles/MuiThemes"
import {ReportTakeRow} from "../../../reports/class/models/ReportTakeRow"
import {openSidebarWithId} from "./TestTakerSortableTable"
import {PanelSkillEnum} from "../../../../types/types"
import {SkillColumnData} from "./Columns"

interface TestTakerTableCellProps extends TableCellProps {
    noWrap?: boolean
    centerContent?: boolean
}

const useClickableStyles = makeStyles({
    clickableText: {
        "& *": {
            width: "fit-content",
            transition: "all 0.2s",
            textAlign: "center",
            fontWeight: "bold"
        },
        "&:hover *": {
            cursor: "pointer",
            color: AvantColors.avantDarkBlue,
            textDecoration: "underline",
            transform: "scale(1.3)"
        }
    }
})

export const TestTakerTableCell = (props: TestTakerTableCellProps) => {
    const {style, children, noWrap, centerContent, onClick} = props
    const clickableStyles = useClickableStyles()
    let contents = children

    if (typeof(children) === "string" || typeof(children) === "number") {
        contents = (
            <SmallBodyText
                noWrap={noWrap}
                style={{maxWidth: 120, overflow: "hidden", textOverflow: "ellipsis"}}
            >
                {children}
            </SmallBodyText>
        )
    }

    return (
        <Box
            style={{
                display: centerContent ? "flex" : undefined,
                justifyContent: "center",
                paddingRight: centerContent ? theme.spacing(2) : undefined,
                ...style
            }}
            className={onClick ? clickableStyles.clickableText : undefined}
            onClick={onClick}
        >
            {contents}
        </Box>
    )
}

function RenderColumn(
    content: ReactElement | string | number | boolean | undefined,
    openSidebar: boolean = false,
    skillColumnData: SkillColumnData = {title: "", key: ""},
    row?: ReportTakeRow
): ReactElement {
    const {noWrap, centerContent, styles} = skillColumnData
    let onClick = undefined

    if (openSidebar && row) {
        onClick = openSidebarWithId(row, skillColumnData.symbol as PanelSkillEnum, false)
    }

    return (
        <TestTakerTableCell
            noWrap={noWrap}
            centerContent={centerContent}
            style={styles}
            onClick={onClick}
        >
            {content}
        </TestTakerTableCell>
    )
}

export default RenderColumn
