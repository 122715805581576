import * as React from "react"
import {PrintOptions, PrintViewContainer} from "../../common/components/PrintViewContainer"
import {findFilters, ReportRouteProps} from "../../common/components/ReportsPage"
import {BlueReportType} from "../../common/models/BlueReportType"

export const DistrictPrintAll: React.FC<ReportRouteProps> = (props) => {
    const filters = findFilters(props)
    return <PrintViewContainer filters={filters} printOptions={PrintOptions.ALL} report={BlueReportType.DISTRICT}/>
}
export const DistrictPrintSummary: React.FC<ReportRouteProps> = (props) => {
    const filters = findFilters(props)
    return <PrintViewContainer filters={filters} printOptions={PrintOptions.SUMMARY} report={BlueReportType.DISTRICT}/>
}
export const DistrictPrintLocations: React.FC<ReportRouteProps> = (props) => {
    const filters = findFilters(props)
    return <PrintViewContainer filters={filters} printOptions={PrintOptions.LIST} report={BlueReportType.DISTRICT}/>
}
