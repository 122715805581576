/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {computed, observable} from "mobx"
import {P360ExamData, PanelSkillEnum} from "../../../../types/types"

class SidebarStore {

    /**
     * Open if this is defined
     */
    @observable
    takeId?: number

    @observable
    openProfile = true

    @observable
    openP360: boolean = false

    @observable
    P360Data?: P360ExamData[] | undefined

    @observable
    openSelfEval?: boolean = false

    @observable
    openPanel?: PanelSkillEnum

    @observable
    isPlaceC: boolean = false

    @computed
    get open(): boolean {
        return this.takeId != null
    }
}

export const sidebarStore = new SidebarStore()
