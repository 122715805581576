/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {AvantColors} from "../../../../../styles/AvantColors"
import {productStore} from "../../../../common/products/ProductStore"
import {STAMP_CEFR_PRODUCT_ID} from "../../../../../util/Constants"

enum ReportLevelSymbols {
    NOT_RATABLE = "NR",
    ZERO = "0",
    ONE = "1",
    TWO = "2",
    THREE = "3",
    FOUR = "4",
    FIVE = "5",
    SIX = "6",
    SIX_PLUS = "6+",
    SEVEN = "7",
    EIGHT = "8",
    NINE = "9",
    TEN = "10"
}

export interface BarProperties {
    textFill: string
    barFill: string
    hashFill?: string
}

function isCEFR(): boolean {
    if (productStore.loginProduct && productStore.loginProduct.productId === STAMP_CEFR_PRODUCT_ID.value()) {
        return true
    }

    return false
}

export const findBarProperties = (avg: number, isComposite?: boolean): BarProperties => {
    const noviceFill = (isComposite) ? AvantColors.NOVICE_FILL_LIGHT : AvantColors.NOVICE_FILL
    const intermediateFill = (isComposite) ? AvantColors.INTERMEDIATE_FILL_LIGHT : AvantColors.INTERMEDIATE_FILL
    const advancedFill = (isComposite) ? AvantColors.ADVANCED_FILL_LIGHT : AvantColors.ADVANCED_FILL
    const superiorFill = (isComposite) ? AvantColors.SUPERIOR_FILL_LIGHT : AvantColors.SUPERIOR_FILL

    let hashFill
    if (avg >= 3.5 && avg < 4) {
        hashFill = intermediateFill
    } else if (isCEFR() && avg >= 5.5 && avg < 6) {
        hashFill = advancedFill
    } else if (avg >= 6.5 && avg < 7) {
        hashFill = advancedFill
    } else if (avg >= 9.5 && avg < 10) {
        hashFill = superiorFill
    }


    if (avg < 2) {
        return {
            barFill: noviceFill,
            textFill: AvantColors.REPORT_FONT_COLOR_GRAY_3,
            hashFill
        }
    } else if (avg < 4) {
        return {
            barFill: noviceFill,
            textFill: AvantColors.NOVICE_FILL_TEXT,
            hashFill
        }
    } else if (avg < 6) {
        return {
            barFill: intermediateFill,
            textFill: AvantColors.REPORT_FONT_COLOR_WHITE,
            hashFill
        }
    } else if (avg < 7) {
        return {
            barFill: isCEFR() ? advancedFill : intermediateFill,
            textFill: AvantColors.REPORT_FONT_COLOR_WHITE,
            hashFill
        }
    } else if (avg < 10) {
        return {
            barFill: advancedFill,
            textFill: AvantColors.REPORT_FONT_COLOR_WHITE,
            hashFill
        }
    } else {
        return {
            barFill: superiorFill,
            textFill: AvantColors.REPORT_FONT_COLOR_WHITE,
            hashFill
        }
    }
}

export const determineBarFill = (name: string) => {
    if (name === ReportLevelSymbols.NOT_RATABLE) {
        return AvantColors.NOT_RATABLE_FILL
    } else if (name === ReportLevelSymbols.ZERO) {
        return AvantColors.avantDarkGrey
    } else if (name === ReportLevelSymbols.ONE || name === ReportLevelSymbols.TWO || name === ReportLevelSymbols.THREE) {
        return AvantColors.NOVICE_FILL
    } else if (name === ReportLevelSymbols.FOUR || name === ReportLevelSymbols.FIVE || name === ReportLevelSymbols.SIX || name === ReportLevelSymbols.SIX_PLUS) {
        if (name === ReportLevelSymbols.SIX && isCEFR()) {
            return AvantColors.ADVANCED_FILL
        }

        return AvantColors.INTERMEDIATE_FILL
    } else if (name === ReportLevelSymbols.SEVEN || name === ReportLevelSymbols.EIGHT || name === ReportLevelSymbols.NINE) {
        return AvantColors.ADVANCED_FILL
    } else {
        return AvantColors.SUPERIOR_FILL
    }
}
