import * as React from "react"
import MaterialTable, {Column} from "material-table"
import {useRef, useState} from "react"
import ApiService from "../../../services/ApiService"
import {messageStore} from "../../../app/common/messages/MessageStore"

function buildSort(orderByField: string | unknown, orderDirection: string): string {
    let sortString = ""
    if (orderByField) {
        sortString = `&sort=${orderByField},${orderDirection}`
    }
    return sortString
}

function buildPredicate(filters: any): string {
    const predicateArray: string[] = []
    filters.forEach((filter: any) => {
        if (filter.column.field === "name") {
            predicateArray.push(`message=contains(${filter.value})`)
        }
        if (filter.column.field === "value") {
            predicateArray.push(`value=contains(${filter.value})`)
        }
    })
    if (predicateArray.length === 0) {
        return ""
    }
    return `&${predicateArray.join("&")}`
}

async function fetchP360Messages(query: any) {
    const sort = (query.orderBy) ? buildSort(query.orderBy.field, query.orderDirection) : ""
    const predicate = (query.filters) ? buildPredicate(query.filters) : ""

    const response = await ApiService.get(`${ApiService.API_URL}rest/appSettings?page=${query.page}&size=${query.pageSize}${sort}${predicate}`)
    const wallMessageData = response.data._embedded.appSettings
    return {
        data: wallMessageData,
        page: response.data.page.number,
        totalCount: response.data.page.totalElements
    }
}

export const AppSettingsBase: React.FC = () => {
    const tableRef = React.createRef()
    const isCanceled = useRef(false)

    const patchRow = (patchLink: string , data: {}) => {
        ApiService.patch(patchLink, data)
            .then(_ => {
                if (!isCanceled.current) {
                    // @ts-ignore
                    if (tableRef.current) { tableRef.current.onQueryChange() }
                    messageStore.setInfoMessage("Updated Row")
                }
            })
            .catch(e => {if (!isCanceled.current) {messageStore.setErrorMessage(e.toString())}})
    }

    const initialColumns: Column<any>[] = [
        {
            title: "Name",
            field: "name",
            sorting: false,
            searchable: false,
            removable: false,
        },
        {
            title: "Value",
            field: "value",
            sorting: false,
            searchable: false,
            removable: false,
        },
        {
            title: "Created",
            field: "created",
            sorting: false,
            searchable: false,
            removable: false,
        },
        {
            title: "Updated",
            field: "updated",
            sorting: false,
            searchable: false,
            removable: false,
        },
    ]

    const [columns] = useState(initialColumns)

    return (
        <>
            <MaterialTable
                tableRef={tableRef}
                title="Applicatin Settings"
                columns={columns}
                data={fetchP360Messages}
                options={{
                    filtering: true,
                    search: true,
                    showTitle: true,
                    toolbar: true,
                    padding: "dense",
                    pageSize: 100,
                    pageSizeOptions: [100, 200, 500, 1000],
                    exportButton: true,
                    columnsButton: false,
                    debounceInterval: 500,
                    exportFileName: "AppSettings",
                    tableLayout: "auto",
                }}
                cellEditable={{
                    onCellEditApproved: (newValue: any, oldValue: any, rowData: any, columnDef: any) => {
                        return new Promise((resolve, reject) => {
                            const updateField = {}
                            updateField[columnDef.field] = newValue
                            patchRow(rowData._links.self.href, updateField)
                        })
                    }
                }}

            />
        </>
    )
}

export const AppSettingsAdmin = React.memo(AppSettingsBase)
