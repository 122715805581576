import Button, {ButtonProps} from "@material-ui/core/Button"
import withStyles from "@material-ui/core/styles/withStyles"
import * as React from "react"
import {Link, LinkProps} from "react-router-dom"

const AvantStyledButton = withStyles({
    root: {
        boxShadow: "none",
        borderRadius: "6px",
        backgroundColor: "#2B5DD8",
        borderColor: "#2B5DD8",
        color: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#6085E1",
            borderColor: "#6085E1",
            color: "#FFFFFF"
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#2045A1",
            borderColor: "#2045A1",
            color: "#FFFFFF"
        },
    },
})(Button)

export const AvantButton = (props: ButtonProps) => {
    const {style, ...rest} = props

    return (
        <AvantStyledButton variant={"contained"} color={"primary"} style={{boxShadow: "none", ...style}} {...rest} />
    )
}

export const AvantButtonLink = (props: ButtonProps & LinkProps) => {
    const {style, ...rest} = props

    return (
        // @ts-ignore
        <AvantStyledButton variant={"contained"} color={"primary"} component={Link} style={{boxShadow: "none", ...style}} {...rest} />
    )
}
