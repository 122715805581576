import platform from "platform"
import React from "react"
import {Col, Modal, Row} from "react-bootstrap"
import { withRouter,RouteComponentProps} from "react-router-dom";
import {ProductDriver} from "../../app/common/products/ProductStore"
import {IItem, RecorderEnum, RecordWarningEnum, TLocalAnswer, VOICE_RECORDER} from "../../types/types"
import {JEENIE_PRODUCT_ID} from "../../util/Constants"
import {to} from "../../util/elvis"
import Button from "../Button/Button"
import {VoiceRecorder} from "../voice-recording/VoiceRecorder"
import ApiService from "../../services/ApiService"

type TComponentProps = {
    item: IItem
    languageName: string
    localAnswer: TLocalAnswer | null
    driver: ProductDriver
    autoSaving: boolean
    recorderSupported: RecorderEnum
    recordingWarningSeen: RecordWarningEnum
    recordingSidsToSave: string
    voiceRecorder: VOICE_RECORDER
    handleAnswerChange: (answer: TLocalAnswer | null) => void
    setSeenRecordingWarning: (state: RecordWarningEnum) => void
    setRecorderSupported: (recorder: RecorderEnum) => void
    setRecordingSidsToSave: (recordingSids: string) => void
    onOggBlobAvailable: (oggBlob: Blob) => void
} & RouteComponentProps<any>

interface IComponentState {
    showNoRecordingModal: boolean
    showBadVersionModal: boolean
    responseMaximumLengthSeconds: number
}

class Speaking extends React.Component<TComponentProps, IComponentState> {
    constructor(props: TComponentProps) {
        super(props)
        this.state = {
            showNoRecordingModal: false,
            showBadVersionModal: false,
            responseMaximumLengthSeconds: 0,
        }
    }

    componentDidMount() {
        if (
            platform.os &&
            platform.os.family === "iOS" &&
            platform.os.version &&
            (parseInt(platform.os.version, 10) < 11 ||
                platform.os.version === "11.2.2" ||
                platform.os.version === "11.2.5")
        ) {
            this.setState({showBadVersionModal: true})
        }
        this.getResponseMaximumLengthSeconds()
    }

    setRecorderSupported = (recorder: RecorderEnum) => {
        this.props.setRecorderSupported(recorder)
    }

    setRecordingWarningSeen = (state: RecordWarningEnum) => {
        this.props.setSeenRecordingWarning(state)
    }

    setRecordingSidsToSave = (recordingSids: string) => {
        this.props.setRecordingSidsToSave(recordingSids)
    }


    onOggBlobAvailable = (blob: Blob | null) => {
        this.props.handleAnswerChange(blob)
    }

    closeNoRecordingModal = () => {
        this.setState({showNoRecordingModal: false})
    }

    closesBadVersionModal = () => {
        this.setState({showBadVersionModal: false})
        this.props.history.push("/dashboard")
    }

    getResponseMaximumLengthSeconds = () => {
        ApiService.getResponseMaximumLengthSpeakingSeconds()
            .then(response => {
                // If we got back a number...
                if ( response && !isNaN(Number(response)) ) {
                    let maxSec = Number(response)
                    if (Number.isInteger(maxSec) && maxSec > 0) {
                        this.setState({'responseMaximumLengthSeconds': maxSec})
                    }
                }
            })
            // There's no .catch() here intentionally; this should fail quietly if the API service function call gets an error.
    }


    renderSecondsAsMinutesAndSeconds = (seconds: number) => {
        const fullMinutes = Math.floor(seconds / 60)
        const remainderSeconds = seconds % 60

        const sIfPlural = (count: number): string => {
            return ( count === 1 ? '' : 's' )
        }

        // Initialize an empty string:
        let minutesAndSecondsText: string = ''
        // If we have full minutes, render the minute count with label:
        minutesAndSecondsText += ( fullMinutes > 0 ? fullMinutes + ' minute' + sIfPlural(fullMinutes) : '' )
        // If we have both minutes and seconds, render "and" between them:
        minutesAndSecondsText += ( fullMinutes > 0 && remainderSeconds > 0 ? ' and ' : '' )
        // If we have partial minutes, render the second count with label:
        minutesAndSecondsText += ( remainderSeconds > 0 ? remainderSeconds + ' second' + sIfPlural(remainderSeconds) : '' )

        return minutesAndSecondsText
    }

    renderPrompt = (): JSX.Element => {
        let out = <p className="speaking__prompt">Record your response below.</p>

        if (this.props.driver.config.PRODUCT_ID === JEENIE_PRODUCT_ID.value()) {
            out = <p className="speaking__prompt">Record your interpretation below.</p>
        }

        return out
    }

    renderRememberTo = (): JSX.Element => {
        const {responseMaximumLengthSeconds} = this.state

        let languageName = this.props.languageName
        if (languageName.toLowerCase().includes("chinese")) {
            languageName = "Mandarin"
        }
        if (languageName === "Arabic") {
            languageName = "Modern Standard Arabic"
        }
        let out = (
            <>
                <p className="speaking__remember">Remember to...</p>
                <div className="writing__remember-point">
                    <div className="writing__remember-point__icon">
                        <i className="material-icons writing__remember-point__icon__check">done</i>
                    </div>
                    {"\u00A0"} Speak in {languageName}
                </div>
                <div className="writing__remember-point">
                    <div className="writing__remember-point__icon">
                        <i className="material-icons writing__remember-point__icon__check">done</i>
                    </div>
                    {"\u00A0"} Use complete sentences
                </div>
                <div className="writing__remember-point">
                    <div className="writing__remember-point__icon">
                        <i className="material-icons writing__remember-point__icon__check">done</i>
                    </div>
                    {"\u00A0"} Say as much as you can
                    {
                        responseMaximumLengthSeconds > 0
                        ? ', up to ' + this.renderSecondsAsMinutesAndSeconds(responseMaximumLengthSeconds)
                        : ''
                    }
                </div>
                <div className="writing__remember-point">
                    <div className="writing__remember-point__icon">
                        <i className="material-icons writing__remember-point__icon__check">done</i>
                    </div>
                    {"\u00A0"} Listen to your recording
                </div>
            </>
        )

        if (this.props.driver.config.PRODUCT_ID === JEENIE_PRODUCT_ID.value()) {
            out = (
                <>
                    <p className="speaking__remember">Remember to...</p>
                    <div className="writing__remember-point">
                        <div className="writing__remember-point__icon">
                            <i className="material-icons writing__remember-point__icon__check">done</i>
                        </div>
                        {"\u00A0"} Speak in {languageName}
                    </div>
                    <div className="writing__remember-point">
                        <div className="writing__remember-point__icon">
                            <i className="material-icons writing__remember-point__icon__check">done</i>
                        </div>
                        {"\u00A0"} Interpret the spirit of the message
                    </div>
                    <div className="writing__remember-point">
                        <div className="writing__remember-point__icon">
                            <i className="material-icons writing__remember-point__icon__check">done</i>
                        </div>
                        {"\u00A0"} Listen to your recording
                    </div>
                </>
            )
        }

        return out
    }

    render() {
        const recordingUrl = to<string>(
            this.props.item.flvRecordingUrl,
            new Error("Can't create a speaking item without a recording url!")
        )
        const recordingId = to<string>(
            this.props.item.speakingFileName,
            new Error("Can't create speaking item without a speaking File name!")
        )

        return (
            <>
                {
                    <div key={this.props.item.id} data-tst-id="choices-wrapper">
                        <div className="speaking">
                            {this.renderPrompt()}
                            <VoiceRecorder
                                recordingUrl={recordingUrl}
                                recordingId={recordingId}
                                saveFinalRecordings={true}
                                voiceRecorder={this.props.voiceRecorder}
                                seenRecordingWarning={this.props.recordingWarningSeen}
                                setRecordingSidsToSave={this.setRecordingSidsToSave}
                                setRecorderSupported={this.setRecorderSupported}
                                setSeenRecordingWarning={this.setRecordingWarningSeen}
                                recordingSids={this.props.recordingSidsToSave}
                                onOggBlobAvailable={this.onOggBlobAvailable}
                                maxLengthSeconds={this.state.responseMaximumLengthSeconds}
                            />
                            {this.renderRememberTo()}
                        </div>


                        <Modal
                            size="sm"
                            show={this.state.showNoRecordingModal}
                            onHide={this.closeNoRecordingModal}
                        >
                            <Modal.Header closeButton={false} className="center-text">
                                <Modal.Title>Nothing Recorded</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="center-text">
                                <p>
                                    You did not record anything! Please record a response before pressing the next
                                    button.
                                </p>
                                <Row>
                                    <Col sm={{span: 6, offset: 3}}>
                                        <Button
                                            className="avant-button avant-button--primary avant-button--default"
                                            onClick={this.closeNoRecordingModal}
                                            block={true}
                                        >
                                            OK
                                        </Button>
                                    </Col>
                                </Row>
                            </Modal.Body>
                        </Modal>
                    </div>}

                <Modal size="sm" show={this.state.showBadVersionModal} onHide={this.closesBadVersionModal}>
                    <Modal.Header closeButton={false} className="center-text">
                        <Modal.Title>Problem with your browser</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="center-text">
                        <p>Sorry, but you are using a browser that is unsupported for speaking.</p>
                        <Row>
                            <Col sm={{span: 6, offset: 3}}>
                                <Button
                                    className="avant-button avant-button--primary avant-button--default"
                                    onClick={this.closesBadVersionModal}
                                    block={true}
                                >
                                    OK
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default withRouter(Speaking)
