import {Box, Button, Grid, Paper, TextField} from "@material-ui/core"
import {observer} from "mobx-react"
import React from "react"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"
import {productStore} from "../../app/common/products/ProductStore"
import {postProfileSuccess} from "../../redux/app/actions"
import ApiService from "../../services/ApiService"
import HelperService from "../../services/HelperService"
import {LabelText} from "../../styles/AvantTypography"
import {theme} from "../../styles/MuiThemes"
import {ApiErrorResponse, ApiPanelGraph, LastPage, Profile, State, Take, TestState} from "../../types/types"
import {TakeId} from "../../validation/ValidPrimaryKey"
import {addTakeDispatches, TakeDispatches} from "../App/App"
import {HEADER_HEIGHT} from "../Header/Header"
import {validateEmail} from "../../util/validators"
import {messageStore} from "../../app/common/messages/MessageStore"
import {SUPPORT_MESSAGE} from "../../util/Constants"

interface FormErrors {
    firstName: string[]
    lastName: string[]
    testTakerEmail: string[]
    confirmTestTakerEmail: string[]
}

interface StateToProps {
    take: Take | null
    takeCode: string
    panelGraph: ApiPanelGraph
}

function mapStateToProps(state: State): StateToProps {
    return {
        take: state.app.take as Take,
        takeCode: state.app.takeCode,
        panelGraph: state.app.panelGraph!
    }
}

interface DispatchToProps extends TakeDispatches {
    profileSuccessDispatch: (profile: Profile) => void
}

function mapDispatchToProps(dispatch: Dispatch): DispatchToProps {
    const dispatchs = {
        profileSuccessDispatch: (profile: Profile) => {
            dispatch(postProfileSuccess(profile))
        }
    }
    return addTakeDispatches(dispatchs, dispatch)
}

export type TComponentProps = StateToProps & DispatchToProps & RouteComponentProps<any>

interface ApiProfileContainerState {
    error: boolean | string
    profile: Profile
    confirmTestTakerEmail: string
    take: Take | null
    formErrors: FormErrors
    showModal: boolean
    loaded: boolean
}

@observer
class AptProfileFormContainer extends React.Component<TComponentProps, ApiProfileContainerState> {
    private readonly isReadOnly: boolean = false

    constructor(props: TComponentProps) {
        super(props)
        this.state = {
            error: false,
            profile: {
                firstName:
                    this.props.take && this.props.take.profile && this.props.take.profile.firstName
                        ? this.props.take.profile.firstName
                        : "",
                lastName:
                    this.props.take && this.props.take.profile && this.props.take.profile.lastName
                        ? this.props.take.profile.lastName
                        : "",
                testTakerEmail:
                    this.props.take && this.props.take.profile && this.props.take.profile.testTakerEmail
                        ? this.props.take.profile.testTakerEmail
                        : "",
                schoolName: this.props.take && this.props.take.profile ? this.props.take.profile.schoolName : "",
                testGroupName: this.props.take && this.props.take.profile ? this.props.take.profile.testGroupName : ""
            },
            confirmTestTakerEmail:
                this.props.take && this.props.take.profile && this.props.take.profile.testTakerEmail
                    ? this.props.take.profile.testTakerEmail
                    : "",
            take: this.props.take,
            formErrors: {
                firstName: [],
                lastName: [],
                testTakerEmail: [],
                confirmTestTakerEmail: []
            },
            showModal: false,
            loaded: false
        }

        this.isReadOnly = productStore.isReadOnlyProfile(this.state.profile.firstName, this.state.profile.lastName)
    }

    async componentDidMount() {
        HelperService.enableTextSelection()
        await this.getData()
    }

    async componentDidUpdate() {
        if (this.props.take !== this.state.take) {
            this.setState({take: this.props.take})
        }
        if (this.props.take && this.props.take.profile && this.props.take.profile !== this.state.profile) {
            this.setState({profile: this.props.take.profile})
        }
        await this.getData()
    }

    getData = async () => {
        const {takeCode, panelGraph} = this.props
        const driver = productStore.driver
        const product = productStore.loginProduct
        const {loaded} = this.state
        if (driver && !loaded) {
            this.setState({loaded: true})
            document.title = `${driver.config.PRODUCT_TITLE} | Profile`
            let lastPage: LastPage | null = HelperService.getLastPageFromLs()
            if (lastPage) {
                const testState: TestState = await driver.helper.refreshTest(this.props, lastPage, product!)
                if (!testState.take) {
                    throw Error("testState.take is null or undefined")
                }
                if (!testState.take.profile) {
                    throw Error("testState.take.profile is null or undefined")
                }
                this.setState({take: testState.take, profile: testState.take.profile})
            } else {
                lastPage = {
                    url: "/apt-profile",
                    takeCode,
                    panelGraphId: panelGraph.id
                }
                localStorage.setItem("lastPage", JSON.stringify(lastPage))
            }
        }
    }

    handleFirstNameChange = (e: any) => {
        const profile: Profile = this.state.profile
        profile.firstName = e.target.value
        this.setState({profile})
    }

    handleLastNameChange = (e: any) => {
        const profile: Profile = this.state.profile
        profile.lastName = e.target.value
        this.setState({profile})
    }

    handleEmailChange = (e: any) => {
        const profile = this.state.profile
        profile.testTakerEmail = e.target.value
        this.setState({profile})
    }

    handleConfirmEmailChange = (e: any) => {
        this.setState({confirmTestTakerEmail: e.target.value})
    }

    validateFirstName(profile : Profile): string[] {
        const {firstName} = profile
        if (!firstName) {
            return ["Plase enter your first name."]
        }

        return []
    }

    validateLastName(profile : Profile): string[] {
        const {lastName} = profile
        if (!lastName) {
            return ["Plase enter your last name."]
        }

        return []
    }

    validateTestTakerEmail = (profile: Profile) => {
        const {testTakerEmail} = profile

        if (testTakerEmail) {
            if(!validateEmail(testTakerEmail!)) {
                return ["Please enter a valid email."]
            }
        }

        return []
    }

    validateConfirmTestTakerEmail = (profile: Profile) => {
        const {confirmTestTakerEmail} = this.state
        const {testTakerEmail} = profile

        if (testTakerEmail) {
            if (confirmTestTakerEmail === "") {
                return ["Please confirm your email."]
            }

            if (confirmTestTakerEmail !== testTakerEmail) {
                return ["Your email and email confirmation must match."]
            }
        }

        return []
    }

    validate = (profile: Profile): { hasErrors: boolean, formErrors: FormErrors } => {
        const formErrors: FormErrors = {
            firstName: this.validateFirstName(profile),
            lastName: this.validateLastName(profile),
            testTakerEmail: this.validateTestTakerEmail(profile),
            confirmTestTakerEmail: this.validateConfirmTestTakerEmail(profile),
        }

        let hasErrors = false

        Object.values(formErrors).forEach((value: string[]) => {
            if (value && value.length > 0) {
                hasErrors = true
            }
        })

        return {
           hasErrors,
           formErrors
        }
    }

    handleSubmit = (ev: any) => {
        ev.preventDefault()

        this.setState({
            formErrors: {
                firstName: [],
                lastName: [],
                testTakerEmail: [],
                confirmTestTakerEmail: [],
            }
        })

        if (this.props.take === null) {
            messageStore.setErrorMessage(SUPPORT_MESSAGE)

            return
        }

        const errors = this.validate(this.state.profile)

        if (errors.hasErrors) {
            this.setState({
                formErrors: errors.formErrors
            })

            return
        }

        ApiService
            .postProfile(
                new TakeId(this.props.take.id),
                this.state.profile,
                productStore.driver!.config.APP_PRODUCT_NAME.toLowerCase()
            ).then(() => {
                this.props.profileSuccessDispatch(this.state.profile)
                this.props.history.push("/dashboard")
            })
            .catch((error: ApiErrorResponse) => {
                messageStore.setErrorMessage(error.response.data.message)
            })
    }

    render() {

        return (
            <Box style={{display: "flex", justifyContent: "center"}}>
                <Box component={"form"} style={{marginTop: HEADER_HEIGHT + theme.spacing(4)}}>
                    <Paper style={{
                        padding: theme.spacing(8)
                    }}
                           elevation={4}
                    >
                        <Grid container={true} spacing={2}>
                            <Grid item={true} xs={12}>
                                <LabelText className="large avant-blue">CREATE PROFILE</LabelText>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <LabelText
                                    style={{fontWeight: "bold", marginTop: theme.spacing(1)}}
                                >
                                    *Required
                                </LabelText>
                            </Grid>
                            <Grid item xs={6} spacing={2}>
                                <Grid item={true} xs={12}>
                                    {
                                        this.state.formErrors && this.state.formErrors.firstName.length > 0 &&
                                            <Box className={"error"}>
                                                {this.state.formErrors.firstName.join(" ")}
                                            </Box>
                                    }
                                    <FirstLastTextField
                                        value={this.state.profile.firstName || ""}
                                        label={"First name"}
                                        testId={"first-name"}
                                        onChange={this.handleFirstNameChange}
                                        readOnly={this.isReadOnly}
                                    />
                                </Grid>
                                <Grid item={true} xs={12}>
                                    {
                                        this.state.formErrors && this.state.formErrors.lastName.length > 0 &&
                                        <Box className={"error"}>
                                            {this.state.formErrors.lastName.join(" ")}
                                        </Box>
                                    }
                                    <FirstLastTextField
                                        value={this.state.profile.lastName || ""}
                                        label={"Last name"}
                                        testId={"last-name"}
                                        onChange={this.handleLastNameChange}
                                        readOnly={this.isReadOnly}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={6} spacing={2}>
                                <Grid item xs={12}>
                                    {
                                        this.state.formErrors && this.state.formErrors.testTakerEmail.length > 0 &&
                                        <Box className={"error"}>
                                            {this.state.formErrors.testTakerEmail.join(" ")}
                                        </Box>
                                    }
                                    <LabelText
                                        style={{
                                            marginTop: "8px",
                                            color: "#000",
                                            fontWeight: 700,
                                            fontSize: "13px"
                                        }}
                                    >
                                        Email: NOT REQUIRED. Complete only if instructed to do so by proctor/teacher
                                    </LabelText>
                                    <TextField
                                        value={this.state.profile.testTakerEmail}
                                        data-tst-id={"email"}
                                        onChange={this.handleEmailChange}
                                        variant={"outlined"}
                                        style={{width: "100%"}}
                                        autoComplete={"off"}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        this.state.formErrors && this.state.formErrors.confirmTestTakerEmail.length > 0 &&
                                        <Box className={"error"}>
                                            {this.state.formErrors.confirmTestTakerEmail.join(" ")}
                                        </Box>
                                    }
                                    <LabelText
                                        style={{
                                            marginTop: "8px",
                                            color: "#000",
                                            fontWeight: 700,
                                            fontSize: "13px"
                                        }}
                                    >
                                        Confirm Email
                                    </LabelText>
                                    <TextField
                                        value={this.state.confirmTestTakerEmail}
                                        data-tst-id={"confirm-email"}
                                        onChange={this.handleConfirmEmailChange}
                                        variant={"outlined"}
                                        style={{width: "100%"}}
                                        autoComplete={"off"}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        type={"submit"}
                                        variant={"contained"}
                                        color={"primary"}
                                        data-tst-id="submit"
                                        onClick={this.handleSubmit}
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "14px",
                                            height: "35px"
                                        }}
                                    >
                                        SUBMIT
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Box>
        )
    }
}

const FirstLastTextField = (props: { value: string, label: string, testId: string, onChange: (e: any) => void, readOnly: boolean }) => {
    return (
        <>
            <LabelText
                style={{
                    marginTop: "8px",
                    color: "#000",
                    fontWeight: 700,
                    fontSize: "14px"
                }}
            >
                {props.label}*
            </LabelText>
            <TextField
                value={props.value}
                data-tst-id={props.testId}
                onChange={props.onChange}
                disabled={props.readOnly}
                variant={"outlined"}
                style={{width: "100%"}}
                required={true}
                autoComplete={"off"}
            />
        </>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AptProfileFormContainer)
