import {observable} from "mobx"
import AdminHelper from "../../../components/admin-portal/AdminHelper"
import {AdminItemContainerHelper} from "../../../components/admin-portal/AdminItemContainerHelper"
import AdvanceHelper from "../../../components/advance/AdvanceHelper"
import {AdvanceItemContainerHelper} from "../../../components/advance/AdvanceItemContainerHelper"
import {AptItemContainerHelper} from "../../../components/apt/AptItemContainerHelper"
import {ItemContainerHelper} from "../../../components/ItemContainerHelper"
import {PlaceItemContainerHelper} from "../../../components/place/PlaceItemContainerHelper"
import {ReadyItemContainerHelper} from "../../../components/ready/ReadyItemContainerHelper"
import {Stamp4SItemContainerHelper} from "../../../components/stamp4s/Stamp4SItemContainerHelper"
import {Stamp4SeItemContainerHelper} from "../../../components/stamp4se/Stamp4SeItemContainerHelper"
import {WorldSpeakItemContainerHelper} from "../../../components/worldspeak/WorldSpeakItemContainerHelper"
import {ConfigurationService, GenericConfiguration} from "../../../services/ConfigurationService"
import {GenericHelper, GenericHelperInterface} from "../../../services/GenericHelper"
import {LoginProductContentAreaData, VoiceRecorder} from "../../../types/types"
import {
    ADMIN_PRODUCT_ID,
    ADVANCE_PRODUCT_ID,
    APT_PRODUCT_ID,
    CLEVER_SSO_LOGIN_PRODUCT_ID,
    PLACE_PRODUCT_ID,
    READY_PRODUCT_ID,
    STAMP_4S_PRODUCT_ID,
    STAMP_4SE_PRODUCT_ID,
    WORLDSPEAK_PRODUCT_ID,
    STAMP_CEFR_PRODUCT_ID,
    STAMP_SIGN_LANGUAGE_PRODUCT_ID,
    CLASSLINK_SSO_LOGIN_PRODUCT_ID,
    NYCDOE_SSO_LOGIN_PRODUCT_ID,
    SAML_SSO_LOGIN_PRODUCT_ID
} from "../../../util/Constants"
import {Env, EnvUtils} from "../../../util/EnvUtils"
import {localizationStrings, LocalStrings} from "../../../util/LocalStrings"
import {ProductId} from "../../../validation/ValidPrimaryKey"
import {messageStore} from "../messages/MessageStore"

export class ProductStore {

    private static readonly LOGIN_PRODUCT_STORAGE_KEY = "loginProduct"

    @observable
    loginProduct?: LoginProductContentAreaData

    @observable
    driver?: ProductDriver

    isReadOnlyProfile(firstName?:string, lastName?:string, testTakerId?:string, confirmTestTakerId?:string): boolean {
        return this.loginProduct!!.rostered && !!firstName && !!lastName && !!testTakerId && !!confirmTestTakerId
    }

    setLoginProduct = (lp?: LoginProductContentAreaData) => {
        if (lp) {
            localStorage.setItem(ProductStore.LOGIN_PRODUCT_STORAGE_KEY, JSON.stringify(lp))
        }
        this.loginProduct = lp
        if (this.loginProduct) {
            this.driver = this.setDriverWithProductId(new ProductId(this.loginProduct.productId))
        }
    }

    loadLoginProduct = () => {
        const raw = localStorage.getItem(ProductStore.LOGIN_PRODUCT_STORAGE_KEY)
        if (raw) {
            this.loginProduct = JSON.parse(raw)
            if (this.loginProduct) {
                this.driver = this.setDriverWithProductId(new ProductId(this.loginProduct.productId))
            }
        }
    }

    setDriverWithProductId = (productId: ProductId): ProductDriver => {

        let driver

        switch (productId.value()) {
            case ADVANCE_PRODUCT_ID.value():
                driver = new AdvanceDriver(productId)
                break
            case APT_PRODUCT_ID.value():
                driver = new AptDriver(productId)
                break
            case PLACE_PRODUCT_ID.value():
                driver = new PlaceDriver(productId)
                break
            case ADMIN_PRODUCT_ID.value():
                driver = new AdminDriver(productId)
                break
            case STAMP_4S_PRODUCT_ID.value():
                driver = new Stamp4SDriver(productId)
                break
            case STAMP_4SE_PRODUCT_ID.value():
                driver = new Stamp4SeDriver(productId)
                break
            case WORLDSPEAK_PRODUCT_ID.value():
                driver = new WorldSpeakDriver(productId)
                break
            case STAMP_SIGN_LANGUAGE_PRODUCT_ID.value():
                driver = new Stamp4SDriver(productId)
                break
            case READY_PRODUCT_ID.value():
                driver = new ReadyDriver(productId)
                break
            case STAMP_CEFR_PRODUCT_ID.value():
                driver = new Stamp4SDriver(productId)
                break
            case CLEVER_SSO_LOGIN_PRODUCT_ID.value():
                driver = new CleverSSODriver(productId)
                break
            case CLASSLINK_SSO_LOGIN_PRODUCT_ID.value():
                driver = new ClasslinkSSODriver(productId)
                break
            case NYCDOE_SSO_LOGIN_PRODUCT_ID.value():
                driver = new NYCDOESSODriver(productId)
                break
            case SAML_SSO_LOGIN_PRODUCT_ID.value():
                driver = new SAMLLinkSSODriver(productId)
                break
            default:
                throw Error(`[driverService] No driver available for product id ${productId.value()}`)
        }

        if (driver.config.FEATURE_FLAG_HTML5_RECORDING_DISABLED) {
            driver.voiceRecorder = undefined
        }

        // Probably turn drivers into an observable themselves at some point.
        messageStore.supportEmail = driver.config.SUPPORT_EMAIL
        return driver
    }
}

export const productStore = new ProductStore()


export abstract class ProductDriver {
    productId = ADVANCE_PRODUCT_ID

    abstract helper: GenericHelperInterface
    config: GenericConfiguration
    itemContainerHelper: ItemContainerHelper
    voiceRecorder?: VoiceRecorder
    urlEnv: string
    strings: LocalStrings

    constructor(productId: ProductId, config: GenericConfiguration) {
        const isStaging = EnvUtils.current() === Env.STAGING
        const isDev = EnvUtils.current() === Env.DEV
        this.productId = productId
        this.urlEnv = isDev ? "-dev" : isStaging ? "-staging" : ""
        localizationStrings.setLanguage("en")
        this.strings = localizationStrings
        this.itemContainerHelper = new AdvanceItemContainerHelper()
        this.config = config
    }
}

export class AdvanceDriver extends ProductDriver {
    helper: AdvanceHelper

    constructor(productId: ProductId) {
        super(productId, ConfigurationService.advanceConfig)
        this.itemContainerHelper = new AdvanceItemContainerHelper()
        this.helper = GenericHelper.advanceHelper
        localizationStrings.setLanguage(this.config.LANGUAGE)
        this.strings = localizationStrings
    }
}

export class CleverSSODriver extends ProductDriver {
    helper: GenericHelper

    // TODO fix this with proper helper and config data
    constructor(productId: ProductId) {
        super(productId, ConfigurationService.aptConfig)
        this.itemContainerHelper = new AptItemContainerHelper()
        this.helper = GenericHelper.aptHelper
        localizationStrings.setLanguage(this.config.LANGUAGE)
        this.strings = localizationStrings
    }

}
// created ClasslinkSSODriver for classlink
export class ClasslinkSSODriver extends ProductDriver {
    helper: GenericHelper
    constructor(productId: ProductId) {
        super(productId, ConfigurationService.aptConfig)
        this.itemContainerHelper = new AptItemContainerHelper()
        this.helper = GenericHelper.aptHelper
        localizationStrings.setLanguage(this.config.LANGUAGE)
        this.strings = localizationStrings
    }

}

export class NYCDOESSODriver extends ProductDriver {
    helper: GenericHelper
    constructor(productId: ProductId) {
        super(productId, ConfigurationService.aptConfig)
        this.itemContainerHelper = new AptItemContainerHelper()
        this.helper = GenericHelper.aptHelper
        localizationStrings.setLanguage(this.config.LANGUAGE)
        this.strings = localizationStrings
    }
}

export class SAMLLinkSSODriver extends ProductDriver {
    helper: GenericHelper
    constructor(productId: ProductId) {
        super(productId, ConfigurationService.aptConfig)
        this.itemContainerHelper = new AptItemContainerHelper()
        this.helper = GenericHelper.aptHelper
        localizationStrings.setLanguage(this.config.LANGUAGE)
        this.strings = localizationStrings
    }

}

export class AptDriver extends ProductDriver {
    helper: GenericHelper

    constructor(productId: ProductId) {
        super(productId, ConfigurationService.aptConfig)
        this.itemContainerHelper = new AptItemContainerHelper()
        this.helper = GenericHelper.aptHelper
        localizationStrings.setLanguage(this.config.LANGUAGE)
        this.strings = localizationStrings

        this.config.TESTING_COORDINATOR_URL = `https://apt-report${this.urlEnv}.avantassessment.com/avant/do/teacherlogin`
    }
}

export class PlaceDriver extends ProductDriver {
    helper: GenericHelper

    constructor(productId: ProductId) {
        super(productId, ConfigurationService.placeConfig)
        this.itemContainerHelper = new PlaceItemContainerHelper()
        this.helper = GenericHelper.placeHelper
        localizationStrings.setLanguage(this.config.LANGUAGE)
        this.strings = localizationStrings

        this.config.TESTING_COORDINATOR_URL = `https://placement-report${this.urlEnv}.avantassessment.com/avant/do/teacherlogin`
    }
}

export class ReadyDriver extends ProductDriver {
    helper: GenericHelper

    constructor(productId: ProductId) {
        super(productId, ConfigurationService.readyConfig)
        this.itemContainerHelper = new ReadyItemContainerHelper()
        this.helper = GenericHelper.readyHelper
        localizationStrings.setLanguage(this.config.LANGUAGE)
        this.strings = localizationStrings

    }
}

export class AdminDriver extends ProductDriver {
    helper: AdminHelper

    constructor(productId: ProductId) {
        super(productId, ConfigurationService.adminConfig)
        this.itemContainerHelper = new AdminItemContainerHelper()
        this.helper = GenericHelper.adminHelper
        localizationStrings.setLanguage(this.config.LANGUAGE)
        this.strings = localizationStrings
    }
}

export class WorldSpeakDriver extends ProductDriver {
    helper: GenericHelper

    constructor(productId: ProductId) {
        super(productId, ConfigurationService.worldSpeakConfig)
        this.itemContainerHelper = new WorldSpeakItemContainerHelper()
        this.helper = GenericHelper.worldSpeakHelper
        localizationStrings.setLanguage(this.config.LANGUAGE)
        this.strings = localizationStrings
    }
}

export class Stamp4SDriver extends ProductDriver {
    helper: GenericHelper

    constructor(productId: ProductId) {
        super(productId, ConfigurationService.stamp4s)
        this.helper = GenericHelper.stamp4sHelper
        this.itemContainerHelper = new Stamp4SItemContainerHelper()
        localizationStrings.setLanguage(this.config.LANGUAGE)
        this.strings = localizationStrings
    }
}

export class Stamp4SeDriver extends ProductDriver {
    helper: GenericHelper

    constructor(productId: ProductId) {
        super(productId, ConfigurationService.stamp4se)
        this.helper = GenericHelper.stamp4seHelper
        this.itemContainerHelper = new Stamp4SeItemContainerHelper()
        localizationStrings.setLanguage(this.config.LANGUAGE)
        this.strings = localizationStrings
    }
}
