import {DatePicker} from "@mui/x-date-pickers-pro"
import {Moment} from "moment"
import React from "react"
import moment from "moment"

interface IComponentProps {
    onDateChanged: (onDateChanged: Moment) => void
    currentDate?: Moment | null
    pickerLabel: string
}

// const locales = ['en-us', 'en-gb', 'zh-cn', 'de'];
//
// type LocaleKey = (typeof locales)[number];

interface IComponentFormat {
    currentDate?: Moment
    errors: string[]
    // locale: LocaleKey
}

export default class AvantDatePicker extends React.Component<IComponentProps, IComponentFormat> {
    constructor(props: IComponentProps) {
        super(props)

        this.state = {
            currentDate: props.currentDate === null ? undefined : props.currentDate,
            errors: [],
            // locale: 'en-gb'
        }
    }

    componentDidUpdate(prevProps: IComponentProps, prevState: IComponentFormat) {
        if (prevProps.currentDate !== this.props.currentDate) {
            this.setState({currentDate: moment(this.props.currentDate)})
        }
    }

    onChange = (date: Moment | null) => {

        if (date !== null && date !== undefined) {
            this.setState({currentDate: date})
            this.props.onDateChanged(moment(date))
        }
    }

    render() {
        // get list of item formats from enum by iterating the enums and getting their values
        return (
                <DatePicker
                    sx={{minWidth: 145, marginTop: '3px'}}
                    label={this.props.pickerLabel}
                    value={this.state.currentDate ? this.state.currentDate : undefined}
                    onChange={this.onChange}
                    data-tst-id="keyExpirationDate"
                />
        )
    }
}
