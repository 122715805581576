export class PanelOutOfTimeError extends Error {
    redirectRoute: string

    constructor(redirectRoute: string) {
        super("Test time expired")
        this.redirectRoute = redirectRoute
        // Set the prototype explicitly. Issue from Typescript 2.1
        Object.setPrototypeOf(this, PanelOutOfTimeError.prototype)
    }
}
