import {MenuItem, TextField} from "@material-ui/core"
import React from "react"
import {GroupMaster, GroupType} from "../../app/common/group/models/GroupMaster"
import {TEvent} from "../../types/types"
import {GroupId} from "../../validation/ValidPrimaryKey"

interface IComponentProps {
    onSelectChanged: (
        currentSelectedGroup?: GroupId,
        state?: GroupId,
        district?: GroupId,
        school?: GroupId,
        classroom?: GroupId,
        customer?: GroupId,
        location?: GroupId
    ) => void

    currentSelectedGroup?: GroupId

    states: GroupMaster[]
    districts: GroupMaster[]
    schools: GroupMaster[]
    classrooms: GroupMaster[]
    customers: GroupMaster[]
    locations: GroupMaster[]
    // These allow this component to inform it's parent that it's ready to
    // receive data about a given group. Leaving it with a `void` return type
    // allows the parent component the freedom to determine how it will send
    // the updated data to this component via props.
    //
    // If these are left undefined, then it will NOT show the related dropdown toggle
    requestDistricts?: (stateId: GroupId) => void
    requestSchools?: (districtId: GroupId) => void
    requestClassrooms?: (schoolId: GroupId) => void
    requestCustomers?: (customerId: GroupId) => void
    requestLocations?: (locationId: GroupId) => void

    // Based on the results of the various drop downs
    currentState?: GroupId
    currentDistrict?: GroupId
    currentSchool?: GroupId
    currentClassroom?: GroupId
    currentCustomer?: GroupId
    currentLocation?: GroupId
}

export default class GroupSelectDropDown extends React.Component<IComponentProps, {}> {

    static requestGroups(event: TEvent, requestFunc?: (groupId: GroupId) => void): GroupId | undefined {
        const groupId = event.target.value !== "" ? new GroupId(Number(event.target.value)) : undefined
        if (groupId && requestFunc) {
            requestFunc(groupId)
        }
        return groupId
    }

    onStateChange = (event: TEvent) => {
        const requestFunc = (stateId: GroupId) => {
            if (this.props.requestDistricts) {
                this.props.requestDistricts(stateId)
            }
            if (this.props.requestCustomers) {
                this.props.requestCustomers(stateId)
            }
        }

        const currentState = GroupSelectDropDown.requestGroups(event, requestFunc)
        this.setState({currentState})
        this.props.onSelectChanged(currentState, currentState)
    }

    onDistrictChange = (event: TEvent) => {
        const currentDistrict = GroupSelectDropDown.requestGroups(event, this.props.requestSchools)
        this.setState({currentDistrict})
        this.props.onSelectChanged(currentDistrict, this.props.currentState, currentDistrict)
    }

    onSchoolChange = (event: TEvent) => {
        const currentSchool = GroupSelectDropDown.requestGroups(event, this.props.requestClassrooms)
        this.setState({currentSchool})
        this.props.onSelectChanged(currentSchool, this.props.currentState, this.props.currentDistrict, currentSchool)
    }

    onClassroomChange = (event: TEvent) => {
        const currentClassroom = GroupSelectDropDown.requestGroups(event)
        this.setState({currentClassroom})
        this.props.onSelectChanged(
            currentClassroom,
            this.props.currentState,
            this.props.currentDistrict,
            this.props.currentSchool,
            currentClassroom
        )
    }

    onCustomerChange = (event: TEvent) => {
        const currentCustomer = GroupSelectDropDown.requestGroups(event, this.props.requestLocations)
        this.setState({currentCustomer})
        this.props.onSelectChanged(
            currentCustomer,
            this.props.currentState,
            undefined,
            undefined,
            undefined,
            currentCustomer
        )
    }

    onLocationChange = (event: TEvent) => {
        const currentLocation = GroupSelectDropDown.requestGroups(event)
        this.setState({currentLocation})
        this.props.onSelectChanged(
            currentLocation,
            this.props.currentState,
            undefined,
            undefined,
            undefined,
            this.props.currentCustomer,
            currentLocation
        )
    }

    renderMenuItemList = (groupList: GroupMaster[], kind: GroupType) => {
        return groupList.map((group) => {
            return (
                <MenuItem key={`${kind}-${group.id}`} value={group.id}>
                    {group.name}
                </MenuItem>
            )
        })
    }

    render() {
        const stateValue = this.props.currentState ? this.props.currentState.value() : ""
        const districtValue = this.props.currentDistrict ? this.props.currentDistrict.value() : ""
        const schoolValue = this.props.currentSchool ? this.props.currentSchool.value() : ""
        const classroomValue = this.props.currentClassroom ? this.props.currentClassroom.value() : ""
        const customerValue = this.props.currentCustomer ? this.props.currentCustomer.value() : ""
        const locationValue = this.props.currentLocation ? this.props.currentLocation.value() : ""

        const stateLabelId = "state-dropdown"
        const districtLabelId = "district-dropdown"
        const schoolLabelId = "school-dropdown"
        const classroomLabelId = "classroom-dropdown"
        const customerLabelId = "customer-dropdown"
        const locationLabelId = "location-dropdown"

        const showDistrict = this.props.currentState && this.props.requestDistricts && !this.props.currentCustomer
        const showSchool = this.props.currentDistrict && this.props.requestSchools
        const showClassroom = this.props.currentSchool && this.props.requestClassrooms
        const showCustomer = this.props.currentState && this.props.requestCustomers && !this.props.currentDistrict
        const showLocation = this.props.currentCustomer && this.props.requestLocations

        return (
            <>
                <TextField
                    id={stateLabelId}
                    select={true}
                    label={"State"}
                    value={stateValue}
                    onChange={this.onStateChange}
                >
                    {this.renderMenuItemList(this.props.states, GroupType.STATE)}
                </TextField>
                {showDistrict && (
                    <TextField
                        id={districtLabelId}
                        select={true}
                        label={"District"}
                        value={districtValue}
                        onChange={this.onDistrictChange}
                    >
                        {this.renderMenuItemList(this.props.districts, GroupType.DISTRICT)}
                    </TextField>
                )}

                {showSchool && (
                    <TextField
                        id={schoolLabelId}
                        select={true}
                        label={"School"}
                        value={schoolValue}
                        onChange={this.onSchoolChange}
                    >
                        {this.renderMenuItemList(this.props.schools, GroupType.SCHOOL)}
                    </TextField>
                )}
                {showClassroom && (
                    <TextField
                        id={classroomLabelId}
                        select={true}
                        label={"Classroom"}
                        value={classroomValue}
                        onChange={this.onClassroomChange}
                    >
                        {this.renderMenuItemList(this.props.classrooms, GroupType.CLASS)}
                    </TextField>
                )}
                {showCustomer && (
                    <TextField
                        id={customerLabelId}
                        select={true}
                        label={"Customer"}
                        value={customerValue}
                        onChange={this.onCustomerChange}
                    >
                        {this.renderMenuItemList(this.props.customers, GroupType.CUSTOMER)}
                    </TextField>
                )}
                {showLocation && (
                    <TextField
                        id={locationLabelId}
                        select={true}
                        label={"Location"}
                        value={locationValue}
                        onChange={this.onLocationChange}
                    >
                        {this.renderMenuItemList(this.props.locations, GroupType.LOCATION)}
                    </TextField>
                )}
            </>
        )
    }
}
