/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {Box} from '@mui/material'
import * as React from "react"
import {ReportFilters} from "../../reports/common/models/ReportFilters"
import {ReportGraphFilters} from "../../reports/common/models/ReportGraphFilters"
import {BlueReportType} from "../../reports/common/models/BlueReportType"
import {LoadingSpinner} from "../../common/loaders/LoadingSpinner"
import {LevelKey} from "../../reports/common/components/level-key/LevelKey"
import {ReportsDownloadMenu} from "../../reports/common/components/ReportsDownloadMenu"
import {DistrictReportsPrintMenu} from "../../reports/district-school/components/DistrictReportsPrintMenu"
import {SchoolReportsPrintMenu} from "../../reports/district-school/components/SchoolReportsPrintMenu"
import {ClassReportsPrintMenu} from "../../reports/class/components/ClassReportsPrintMenu"
import {DateRange} from "@mui/x-date-pickers-pro"
import moment, {Moment} from "moment"
import {
    AvantControlledDateRangePicker, DatePickerOptions
} from "../../reports/common/components/AvantNewDatePicker/AvantControlledDateRangePicker"
import {ReportUtils} from "../../reports/ReportUtils"
import {AvantDateRange} from "../../../util/TimeUtil"
import {useHistory} from "react-router"
import {ReportSubPaths} from "../../reports/ReportSubPaths"

interface FilterAndPrintOptionsProps {
    filters: ReportFilters
    query: ReportGraphFilters,
    reportType: BlueReportType
}

export const reportsRecentActivityValue = "recentActivity"
export const reportsAssessmentStartValue = "assessmentStart"

export const FilterAndPrintOptions = (props: FilterAndPrintOptionsProps) => {
    const {filters, query, reportType} = props
    const history = useHistory()

    if (filters && !filters.productId) {
        return <LoadingSpinner />
    }

    const getDateRanges = (): DateRange<Moment> => {
        const start: Moment | null = filters.startDate ? moment(filters.startDate) : null
        const end: Moment | null = filters.endDate ? moment(filters.endDate) : null
        return [start, end]
    }

    const updateReportDateRange = (range: DateRange<Moment>, options: DatePickerOptions | null): void => {
        if (!range[0] || !range[1]) {
            return
        }

        const dateRange: AvantDateRange = {
            startDate: range[0],
            endDate: range[1]
        }

        const selectedValues = []
        if (options) {
            if (options.recentActivity) {
                selectedValues.push(reportsRecentActivityValue)
            }
            if (options.startDate) {
                selectedValues.push(reportsAssessmentStartValue)
            }
        }

        const linkFilters: ReportFilters = ReportUtils.rangeAndValuesToFilters(filters.productId, filters.groupId, dateRange, selectedValues)
        let link: string = ReportSubPaths.class(linkFilters)

        if (reportType === BlueReportType.DISTRICT) {
            link = ReportSubPaths.district(linkFilters)
        } else if (reportType === BlueReportType.SCHOOL) {
            link = ReportSubPaths.school(linkFilters)
        }

        history.push(link)
    }

    return (
        <Box displayPrint={"none"}>
            <div style={{display: "flex", alignItems: "flex-end"}}>
                <div style={{flexGrow: 1}}/>
                <AvantControlledDateRangePicker dateRange={getDateRanges()} setParentDateRange={updateReportDateRange} />
                <LevelKey/>
                <PrintMenu printMenu={reportType} filters={filters} query={query}/>
                <ReportsDownloadMenu {...query}/>
            </div>
        </Box>
    )
}

interface PrintMenuProps {
    printMenu: BlueReportType
    filters: ReportFilters
    query: ReportGraphFilters
}

const PrintMenu: React.FC<PrintMenuProps> = ({printMenu, filters, query}) => {
    switch (printMenu) {
        case BlueReportType.DISTRICT:
            return <DistrictReportsPrintMenu filters={filters}/>
        case BlueReportType.SCHOOL:
            return <SchoolReportsPrintMenu filters={filters}/>
        case BlueReportType.CLASS:
            return <ClassReportsPrintMenu filters={filters} query={query}/>
        default:
            return null
    }
}
