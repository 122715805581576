/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import * as Sentry from "@sentry/browser"
import axios, {AxiosError, AxiosResponse} from "axios"
import jwtDecode from "jwt-decode"
import {computed, observable} from "mobx"
import moment from "moment"
import {
    GoogleAuthQRCodeResponse,
    TwoFactorAuthResponse
} from "../../../components/admin-portal/AdminTwoFactorAuthentication"
import ApiService from "../../../services/ApiService"
import NycDoeApi from "../../../services/NycDoeApi"
import {
    ApiErrorResponse,
    JwtSubject,
    JwtToken,
    LoginProductContentAreaData,
    OneTimeLoginTake,
    RefreshTokenData,
} from "../../../types/types"
import {log, prettyJson} from "../../../util/Logging"
import {SSOClasslinkAuthedData} from "../../classlink/ClasslinkLoginpage"
import {QParamObject, SSOAuthedData, SSOAuthResponse} from "../../login/SSOLoginPage"
import {NycDoeOauthResponse, SSONycDoeAuthedData} from "../../nycdoe/NYCDOEUtils"
import {AuthenticationRequest, BBProctoredTestTakerAuthRequest} from "./models/AuthenticationRequest"
import {AuthenticationResponse} from "./models/AuthenticationResponse"
import {AuthenticationSampleTestResponse} from "./models/AuthenticationSampleTestResponse"
import {AuthUser} from "./models/AuthUser"
import {UserType} from "./models/UserType"

export class AuthStore {

    static TOKEN_REFRESH_SECONDS: number
    static TOKEN_EXPIRES: number

    // this is used in the refreshItemToken method
    private static REFRESHING_ITEM_TOKEN: boolean
    // this is used in the Axios interceptors
    private static REFRESHING_TOKEN: boolean = false
    private static readonly AUTH_USER_KEY = "authUser"
    private static readonly EULA_AGREE_KEY = "agreedToEULA"

    @observable
    authError: string = ""

    @observable
    agreedToEULA: boolean = false

    @observable
    username?: string

    @observable
    ssoResponseData?: SSOAuthResponse

    @observable
    private authUser?: AuthUser


    authenticate = (payload: AuthenticationRequest): Promise<AuthUser> => {
        const url: string = `${ApiService.API_URL}authenticate`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.post(url, payload)).then(
                (response: AxiosResponse) => {

                    this.convertTokenToAuthUserAndSet(response.data.data)
                    resolve(this.auth!!)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }


    oneTimeLoginTake = async (takeId: number, token: string): Promise<OneTimeLoginTake> => {
        const url: string = `${ApiService.API_URL}authenticate/otlt/${takeId}/${token}`

        // not catching errors because they should bubble up.
        const response = await ApiService.addInteractionBlockingRequest(ApiService.get(url))
        this.convertTokenToAuthUserAndSet(response.data.authUser)
        return response.data

    }

    oneTimeLoginTake2 = async (takeId: number, token: string): Promise<OneTimeLoginTake> => {
        const url: string = `${ApiService.API_URL}authenticate/otlt2/${takeId}/${token}`

        // not catching errors because they should bubble up.
        const response = await ApiService.addInteractionBlockingRequest(ApiService.get(url))
        this.convertTokenToAuthUserAndSet(response.data.authUser)
        return response.data
    }

    /**
     * This will "Authenticate" a proctored test-taker. The user must be following the link provided by the
     * proctor and they must be rostered in the class to make this call.
     * @param payload AuthenticationRequest
     */
    bbProctorAuth = (payload: BBProctoredTestTakerAuthRequest): Promise<LoginProductContentAreaData> => {
        // TODO call Api End Point with AuthenticationRequest
        const url: string = `${ApiService.API_URL}v1/authenticate/bb_proctor_auth`

        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.post(url, payload)).then(
                (response: AxiosResponse) => {

                    const product = response.data.product
                    const token = response.data.token

                    const decoded: JwtToken = jwtDecode(token) as JwtToken
                    const sub: JwtSubject = JSON.parse(decoded.sub)

                    log.debug("Proctor/Auth USer")
                    log.debug("Proctor/Product")
                    log.debug(prettyJson(product))
                    // this.convertTokenToAuthUserAndSet(authUser.token)
                    const authUser: AuthUser = {
                        token: token,
                        tokenTimeRemaining: sub.timeRemaining,
                        expires: moment.unix(decoded.exp).toISOString(),
                        loginId: sub.userId as number,
                        userId: null,
                        userType: sub.userType,
                        eulaAgreeDate: null,
                        userName: sub.userName,
                        ssoType: sub.ssoType,
                        ssoToken: sub.ssoToken,
                        ssoId: sub.ssoId,
                        ssoUserId: sub.ssoUserId,
                        userPermissions: sub.userPermissions,
                        ssoDisplayName: null
                    }

                    // Set auth user in authStore
                    this.auth = authUser

                    // Lets return the data to setup the productStore
                    resolve(product)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })


    }

    ssoAuthenticate = async (body: QParamObject[]): Promise<SSOAuthedData> => {
        const url: string = `${ApiService.API_URL}getSSOToken`
        const res = await ApiService.post(url, body)
        const token = res.data.auth.token
        const decodeToken: JwtToken = jwtDecode(res.data.auth.token) as JwtToken
        const subject: JwtSubject = JSON.parse(decodeToken.sub)
        const loginIdNum: number = isNaN(Number(subject.userId)) ? -1 : Number(subject.userId)
        const cleverName = res.data.cleverData.meData.data.name
        const displayName = cleverName ? `${cleverName.first} ${cleverName.middle} ${cleverName.last}` : ''

        this.auth = {
            token,
            tokenTimeRemaining: subject.timeRemaining,
            expires: moment.unix(decodeToken.exp).toISOString(),
            loginId: loginIdNum,
            userId: subject.userId as string,
            userType: subject.userType,
            eulaAgreeDate: res.data.auth.eulaAgreeDate,
            userName: subject.userName,
            ssoType: subject.ssoType,
            ssoToken: subject.ssoToken,
            ssoId: subject.ssoId,
            ssoUserId: subject.ssoUserId,
            userPermissions: subject.userPermissions,
            ssoDisplayName: displayName
        }
        log.debug("The userType in the JWT is: " + subject.userType)

        this.username = subject.userName == null ? undefined : subject.userName

        this.ssoResponseData = res.data
        if (this.ssoResponseData != null) {
            log.debug("We have ssodata in the store!")
        } else {
            log.debug("We have NO ssodata in the store!")
        }

        const ret: SSOAuthedData = {sso: res.data, loginId: loginIdNum}
        return ret
    }
    //In Progress...

    ssoAuthenticateForClasslink= async (code: any): Promise<SSOClasslinkAuthedData> => {
        const url: string = `${ApiService.API_URL}getSSOTokenClassLink`
        const dataObject ={key:"code",value:code}
        const res = await ApiService.post(url, dataObject)
        if (res.data.success === -1) {
            return {sso: res.data, loginId: -1}
        }
        const token = res.data.auth.token
        const decodeToken: JwtToken = jwtDecode(token) as JwtToken
        const subject: JwtSubject = JSON.parse(decodeToken.sub)
        const loginIdNum: number = isNaN(Number(subject.userId)) ? -1 : Number(subject.userId)
        const displayName = res.data.classLinkData.myInfoData.DisplayName
        this.auth = {
            token:res.data.auth.token,
            tokenTimeRemaining: subject.timeRemaining,
            expires: moment.unix(decodeToken.exp).toISOString(),
            loginId: loginIdNum,
            userType: subject.userType,
            eulaAgreeDate: res.data.auth.eulaAgreeDate,
            userName: subject.userName,
            ssoDisplayName:displayName ,
            ssoType: subject.ssoType,
            ssoToken: subject.ssoToken,
            ssoId: subject.ssoId,
            ssoUserId: subject.ssoUserId,
            userPermissions: subject.userPermissions,
            userId: subject.userId as string,

        }
        this.username = subject.userName == null ? undefined : subject.userName
        this.ssoResponseData = res.data
        const ret: SSOClasslinkAuthedData = {sso: res.data, loginId: loginIdNum}
    
        return ret
    }

    // in progress
    ssoAuthenticateNYCDOE = async (nycDoeOauthResponse: NycDoeOauthResponse): Promise<SSONycDoeAuthedData> => {
        const url: string = `${ApiService.API_URL}getNYCDOEOneRosterToken`

        const res = await NycDoeApi.getNycDoeOidcUserinfoAndJWT(url, nycDoeOauthResponse)

        const token = res.sso.auth.token
        const decodeToken: JwtToken = jwtDecode(token) as JwtToken
        const subject: JwtSubject = JSON.parse(decodeToken.sub)
        const loginIdNum: number = isNaN(Number(subject.userId)) ? -1 : Number(subject.userId)
        const displayName = (res.sso.nycDoeData.myInfoData.firstName !== null && res.sso.nycDoeData.myInfoData.firstName !== undefined && res.sso.nycDoeData.myInfoData.lastName !== null && res.sso.nycDoeData.myInfoData.lastName !== undefined) ? `${res.sso.nycDoeData.myInfoData.firstName} ${res.sso.nycDoeData.myInfoData.lastName}` : "Teacher"

        this.auth = {
            token: token,
            tokenTimeRemaining: subject.timeRemaining,
            expires: moment.unix(decodeToken.exp).toISOString(),
            loginId: loginIdNum,
            userType: subject.userType,
            eulaAgreeDate: null,    // TODO: FIX THIS
            userName: subject.userName,
            ssoDisplayName: displayName,
            ssoType: subject.ssoType,
            ssoToken: subject.ssoToken,
            ssoId: subject.ssoId,
            ssoUserId: subject.ssoUserId,
            userPermissions: subject.userPermissions,
            userId: subject.userId as string,

        }
        this.username = subject.userName == null ? undefined : subject.userName
        // this.ssoResponseData = res
        const ret: SSONycDoeAuthedData = {sso: res.sso, loginId: loginIdNum}

        return ret
    }

    //.............
    authenticateForSampleTest = (request: AuthenticationRequest): Promise<AuthenticationSampleTestResponse> => {
        const url: string = `${ApiService.API_URL}authenticate/sample-test`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.post(url, request)).then(
                (response: AxiosResponse) => {
                    const authUser: AuthUser = this.convertTokenToAuthUserAndSet(response.data.data.authUser)
                    const loginProduct: LoginProductContentAreaData = response.data.data.loginProduct
                    const result: AuthenticationSampleTestResponse = {
                        authUser,
                        loginProduct
                    }
                    resolve(result)
                },
                (err) => {
                    reject(err)
                }
            )
        })
    }

    refreshToken = (requestedUrl: string) => {
        const isRefreshableUrl =
            this.authUser && this.authUser.token && //must have a token to refresh it.
            // Teacher reports
            requestedUrl.search("reports/products") > 0 ||
            //Any of these URL do not need a refresh AND most likely don't have a valid token
            (
                requestedUrl.search("refresh-token") === -1 &&
                requestedUrl.search("authenticate") === -1 &&
                requestedUrl.search("roster") === -1 &&
                requestedUrl.search("examity_registration") === -1 &&
                requestedUrl.search("error") === -1 &&
                requestedUrl.search("products/") === -1 &&
                requestedUrl.search("voice") === -1 &&
                requestedUrl.search("register") === -1 &&
                requestedUrl.search("license-keys") === -1 &&
                requestedUrl.search("password/") === -1 &&
                requestedUrl.search("forgot-password") === -1 &&
                requestedUrl.search("getSSOToken") === -1 &&
                requestedUrl.search("message-wall") === -1 &&
                requestedUrl.search("proctor") === -1 &&
                requestedUrl.search("logins/products") === -1 &&
                requestedUrl.search("logins/verify") === -1
            )

        const shouldRefresh = isRefreshableUrl && !AuthStore.REFRESHING_TOKEN
        if (shouldRefresh && this.authUserwillExpireSoon()) {
            AuthStore.REFRESHING_TOKEN = true
            const url: string = `${ApiService.API_URL}refresh-token`
            const refreshTokenData: RefreshTokenData = {
                secondsTillExpiration: AuthStore.TOKEN_EXPIRES
            }

            axios.post(url, refreshTokenData).then(
                (res) => {
                    this.convertTokenToAuthUserAndSet(res.data.data)
                    setTimeout(() => {
                        AuthStore.REFRESHING_TOKEN = false
                    }, AuthStore.TOKEN_REFRESH_SECONDS * 1000)
                },
                (err: ApiErrorResponse) => {
                    Sentry.captureException(err)
                    setTimeout(() => {
                        AuthStore.REFRESHING_TOKEN = false
                    }, AuthStore.TOKEN_REFRESH_SECONDS * 1000)
                }
            )
        }
    }

    refreshItemToken = (payload: RefreshTokenData) => {
        if (!AuthStore.REFRESHING_ITEM_TOKEN) {
            AuthStore.REFRESHING_ITEM_TOKEN = true
            const url: string = `${ApiService.API_URL}refresh-token`
            axios.post(url, payload).then(
                (res) => {
                    authStore.convertTokenToAuthUserAndSet(res.data.data)
                    setTimeout(() => {
                        AuthStore.REFRESHING_ITEM_TOKEN = false
                    }, AuthStore.TOKEN_REFRESH_SECONDS * 1000)
                },
                (err: ApiErrorResponse) => {
                    Sentry.captureException(err)
                    setTimeout(() => {
                        AuthStore.REFRESHING_ITEM_TOKEN = false
                    }, AuthStore.TOKEN_REFRESH_SECONDS * 1000)
                }
            )
        }
    }

    generateGoogleAuthQRCode = (loginId: number | null): Promise<GoogleAuthQRCodeResponse> => {
        const url: string = `${ApiService.API_URL}google-auth-setup/${loginId}`

        return new Promise((resolve, reject) => {
            axios.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: AxiosError) => {
                    reject(err)
                }
            )
        })
    }

    enableTwoFactorAuth = (loginId: number | null, password: string): Promise<TwoFactorAuthResponse> => {
        const url: string = `${ApiService.API_URL}google-auth-setup`

        return new Promise((resolve, reject) => {
            axios.post(url, {loginId, password}).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: AxiosError) => {
                    reject(err)
                }
            )
        })
    }

    convertTokenToAuthUserAndSet = (authenticationResponse: AuthenticationResponse): AuthUser => {
        const decoded: JwtToken = jwtDecode(authenticationResponse.token) as JwtToken
        const subject: JwtSubject = JSON.parse(decoded.sub)
        const authUser: AuthUser = {
            token: authenticationResponse.token,
            tokenTimeRemaining: subject.timeRemaining,
            expires: moment.unix(decoded.exp).toISOString(),
            loginId: subject.userId as number,
            userId: null,
            userType: subject.userType,
            eulaAgreeDate: authenticationResponse.eulaAgreeDate ? moment(authenticationResponse.eulaAgreeDate) : null,
            userName: subject.userName,
            ssoType: subject.ssoType,
            ssoToken: subject.ssoToken,
            ssoId: subject.ssoId,
            ssoUserId: subject.ssoUserId,
            userPermissions: subject.userPermissions,
            ssoDisplayName: null
        }

        this.auth = authUser
        return authUser
    }

    //************************************************************ SAML ************************************************************
    convertSAMLTokenToAuthUserAndSet = (jwtString: string): SSOAuthedData => {
        const decoded: JwtToken = jwtDecode(jwtString) as JwtToken
        const subject: JwtSubject = JSON.parse(decoded.sub)
        // const userType = (subject.userType === UserType.D || subject.userType === UserType.C) ? UserType.T : subject.userType
        const authUser: AuthUser = {
            token: jwtString,
            tokenTimeRemaining: subject.timeRemaining,
            expires: moment.unix(decoded.exp).toISOString(),
            loginId: subject.userId as number,
            userId: null,
            userType: subject.userType,
            eulaAgreeDate: null,
            userName: subject.userName,
            ssoType: subject.ssoType,
            ssoToken: subject.ssoId,
            ssoId: subject.ssoId,
            ssoUserId: subject.ssoUserId,
            userPermissions: subject.userPermissions,
            ssoDisplayName: null
        }

        this.auth = authUser
        this.username = subject.userName ? subject.userName : undefined
        this.ssoResponseData = {
            success: 1,
            cleverData: {
                cleverToken: "",
                meData: {
                    type: "",
                    data: {
                        id: "",
                        district: "",
                        type: "",
                        credentials: {
                            district_username: ""
                        },
                        name: {
                            first: "",
                            last: "",
                            middle: ""
                        }
                    },
                    links: []
                }
            },
            auth: {
                token: jwtString,
                eulaAgreeDate: "",
                kind: ""
            }
        }

        return {
            sso: this.ssoResponseData,
            loginId: subject.userId as number
        }
    }

    //************************************************************ SAML ************************************************************

    loadAuth = () => {
        if (!this.authUser) {
            const raw = localStorage.getItem(AuthStore.AUTH_USER_KEY)
            if (raw) {
                this.auth = JSON.parse(raw)
            } else {
                this.authUser = undefined
            }
        }

        if (!this.authUser || !this.authUserIsValid()) {
            this.authUser = undefined
        }
    }

    logout = () => {
        this.auth = undefined
        localStorage.clear()
    }

    setAuthUserUserNamePortal = (userName: string) => {
        if (this.authUser !== undefined && this.authUser !== null) {
            this.authUser.ssoDisplayName = userName
        }

    }

    didTheyAgree = () => {
        const raw = localStorage.getItem(AuthStore.EULA_AGREE_KEY)
        if (raw) {
            this.agreedToEULA = JSON.parse(raw)
            return JSON.parse(raw)
        } else {
            return false
        }
    }

    agreeToEula = () =>  {
        localStorage.setItem(AuthStore.EULA_AGREE_KEY, JSON.stringify(true))
        this.agreedToEULA = true
    }

    isTestTakerEditingAllowed = () => {
        const editTestTakersPermission = 'edit-test-takers'

        return Boolean(this.auth && this.auth.userPermissions && this.auth.userPermissions.includes(editTestTakersPermission))
    }


    @computed
    get auth(): AuthUser | undefined {
        if (!this.authUserIsValid()) {
            return undefined
        }
        return this.authUser
    }

    set auth(authUser: AuthUser | undefined) {
        if (authUser) {
            localStorage.setItem(AuthStore.AUTH_USER_KEY, JSON.stringify(authUser))
            axios.defaults.headers.common["X-Avant-Auth-Token"] = authUser.token
        } else {
            localStorage.removeItem(AuthStore.AUTH_USER_KEY)
            axios.defaults.headers.common["X-Avant-Auth-Token"] = ""
        }
        this.authUser = authUser
    }

    @computed
    get userType(): UserType | undefined {
        if (this.auth) {
            return this.auth.userType
        }
        return undefined
    }

    /*
    @computed
    get ssoId(): string | undefined {
        if (this.auth) {
            if (this.auth.ssoId ===  null) {
                return undefined
            }
            return this.auth.ssoId
        }
        return undefined
    }

     */

    @computed
    get isSsoDistrictAuthorized(): boolean {
        const authUser = this.auth
        if (authUser == null) { return false }

        if (authUser.ssoToken == null) { return false }
        return true
    }

    @computed
    get isSsoAvantAuthorized(): boolean {
        const authUser = this.auth
        if (authUser == null) { return false }
        if (this.isSsoDistrictAuthorized === false) { return false }
        if (authUser.ssoId == null) { return false }
        return true
    }


    private authUserIsValid = (): boolean => {
        if (!this.authUser) {
            return false
        }
        if (this.authUser.token && this.authUser.expires) {
            const expires = new Date(this.authUser.expires)
            const now = new Date()
            const expired = now > expires
            if (!expired) {
                return true
            }
        }
        return false
    }

    private authUserwillExpireSoon = (): boolean => {
        if (!this.authUser) { //if we don't have a token there is no need to refresh -- this is a null state
            return false
        }
        if (this.authUser.token && this.authUser.expires) {
            const expires = new Date(this.authUser.expires)
            const now = new Date()
            //Soon is current defined as half of the time to expire.
            now.setSeconds(now.getSeconds() + this.authUser.tokenTimeRemaining / 2)
            // log.debug(`authUserwillExpireSoon:  ${expires} > ${now}`)
            if (now > expires) {
                return true
            }
        }
        return false
    }

}

export const authStore = new AuthStore()
