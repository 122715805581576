import {Paper} from "@material-ui/core"
import * as Sentry from "@sentry/browser"
import {observer} from "mobx-react"
import * as React from "react"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"
import {addTakeDispatches, TakeDispatches} from "../../components/App/App"
import PTHandler from "../../services/PTHandler"
import {LastPage} from "../../types/types"
import {log, prettyJson} from "../../util/Logging"
import {authStore} from "../common/authentication/AuthStore"
import {AuthUser} from "../common/authentication/models/AuthUser"
import {AvantLogo} from "../common/AvantLogo"
import {productStore} from "../common/products/ProductStore"

// Add the ability for the driver to change this page to the test page.
const mapDispatchToProps = (dispatch: Dispatch): TakeDispatches => {
    return addTakeDispatches({}, dispatch)
}

interface OneTimeLoginMatch  {
    takeId?: string,
    otpToken?: string
}
interface OneTimeLoginState {
    loginState: string,
    errMessage: string
}

@observer
export class OneTimeLoginTakeBase extends React.Component<RouteComponentProps<OneTimeLoginMatch>, OneTimeLoginState, {}>  {

    constructor(props: RouteComponentProps<OneTimeLoginMatch>) {
        super(props)
        this.state = {
            loginState: "init",
            errMessage: ""
        }
    }

    sendProctoredUserTOTest = async () => {
        authStore.logout()
        const appToken = await PTHandler.isAppRunning()
        if (!appToken) {
            this.setState({loginState: "not_proctored"})
            return
        }

        if (this.props.match.params.takeId == null) {
            this.setState({loginState: "invalid"})
            return
        }
        if (this.props.match.params.otpToken == null) {
            this.setState({loginState: "invalid"})
            return
        }
        const takeId = parseInt(this.props.match.params.takeId, 10)
        const otpToken = this.props.match.params.otpToken

        try {


            const response = await authStore.oneTimeLoginTake(takeId, otpToken)
            const loginProduct = response.loginProduct
            const authUserRaw = response.authUser
            if (!authStore.auth) {
                // this is here to remove any possibility of a race condition
                // we where having a issue with authStore.auth being null in this function
                authStore.convertTokenToAuthUserAndSet(authUserRaw)
            }
            const authUser: AuthUser | undefined = authStore.auth

            // I have purposfully left these console.log in place so if I have not fixed the race
            // condition this will give me more information in the sentry.io logs.
            log.info(prettyJson(response))
            log.info(prettyJson(loginProduct))

            if (authUser && loginProduct) {
                this.setState({loginState: "loggingIn"})

                Sentry.configureScope((scope) => {
                    // TODO update login.tsx to match so we have the same information across the board

                    scope.setTag("id", takeId.toString()) // allow sentry to collect user data

                    scope.setTag("proctored", "true")

                    scope.setTag("loginId", authUser.loginId ? authUser.loginId.toString() : "MISSING")
                    scope.setTag("userId", authUser.userId ? authUser.userId : "MISSING")
                    scope.setTag("userType", authUser.userType ? authUser.userType : "MISSING")

                    scope.setTag("takeId", takeId.toString())

                    const username = authUser.userName ? authUser.userName : "MISSING"
                    if (username) {
                        // Standard opp-code: 12345-4s-sp-1
                        const usernamePieces = username.split("-")
                        const isProbablyOppCode = usernamePieces.length === 3
                        if (isProbablyOppCode) {
                            scope.setTag("oppCode", usernamePieces[0])
                        }
                    }

                })
                productStore.setLoginProduct(loginProduct)

                const lastPage: LastPage = {
                    url: "/profile-login-name",
                    takeCode: loginProduct.userName
                }

                const testState = await productStore.driver!.helper.refreshTest(this.props, lastPage, loginProduct)
                // the next step changes the react state to a different page.
                await productStore.driver!.helper.refreshTestCallback(testState, this.props)

            } else {
                // OK this is a start the user should not be in.  But we need to make sure to let the user, just in case
                // so they don't wait forever on this screen.
                let extraInfo = "Missing: "
                if (authUser) { extraInfo += "auth "}
                if (loginProduct) {extraInfo += "product "}

                Sentry.withScope((scope) => {
                    scope.setTag("id", takeId.toString()) // allow sentry to collect user data
                    scope.setTag("proctored", "true")
                    scope.setTag("takeId", takeId.toString())
                    scope.setTag("otp", otpToken)
                    Sentry.captureException(`Missing ${extraInfo}`)
                })
                this.setState({
                    loginState: "error",
                    errMessage: `Something went wrong with your login and you need to use a new login url. Please ask the proctor to contact Avant Support. (${extraInfo} information)`
                })
            }
        } catch (e) {
            const typedError = e as Error
            Sentry.withScope((scope) => {
                scope.setTag("id", takeId.toString()) // allow sentry to collect user data
                scope.setTag("proctored", "true")
                scope.setTag("takeId", takeId.toString())
                scope.setTag("otp", otpToken)
                Sentry.captureException(typedError.message)
            })

            this.setState({loginState: "error", errMessage: typedError.message})
        }

    }

    componentDidMount(): void {
        this.sendProctoredUserTOTest()
    }

    render() {
        return (
            <div style={{marginTop: 8}}>
                <AvantLogo title={"Test Login"}/>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Paper style={{padding: "15px", textAlign: "center"}}>
                        {this.state.loginState === "init" && (
                            <div> Initializing our OTP is: {this.props.match.params.otpToken}</div>
                        )}
                        {this.state.loginState === "invalid" && (
                            <div>Can not login you into the test, your one time url is no login valid</div>
                        )}
                        {this.state.loginState === "loggingIn" && (
                            <div>Setting up your test.</div>
                        )}
                        {this.state.loginState === "error" && (
                            <div style={{maxWidth: 960}}>
                                <p>ERROR: {this.state.errMessage}</p>
                                <p>
                                    Please contact  our Support Team at <a href="mailto:support@avantassessment.com">support@avantassessment.com</a> or
                                    call 1 (888) 713-7887 between 5:00 a.m. to 5:00 p.m. Pacific Time Monday-Friday and 6:30 a.m to 2:30 p.m. Saturday.
                                </p>
                            </div>
                        )}
                        {this.state.loginState === "not_proctored" && (
                            <div style={{maxWidth: 960}}>
                                <h2>You are not in a proctored environment.</h2>
                                <p>If you see that you are not in a proctored environment please contact Avant Support.</p>
                                <p>Otherwise please <a href="https://app.avantassessment.com/test">re-login</a> to start a schedule proctored test.</p>
                                <p>
                                    Contact Avant Support at <a href="mailto:support@avantassessment.com">support@avantassessment.com</a> or
                                    call 1 (888) 713-7887 between 5:00 a.m. to 5:00 p.m. Pacific Time Monday-Friday and 6:30 a.m to 2:30 p.m. Saturday.
                                </p>
                            </div>
                        )}

                    </Paper>
                </div>
            </div>
        )
    }

}

export default connect(
    null,
    mapDispatchToProps
)(OneTimeLoginTakeBase)

