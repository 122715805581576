import {AxiosResponse} from "axios"
import {VerificientLogin} from "../app/roster/RosterSelectTake"
import {ApiErrorResponse} from "../types/types"
import ApiService from "./ApiService"

export interface VerificientAvailableLogins {
    username: string,
    contentAreaId: number,
    takeId: number | null
}
export interface  VerificientSigninReturn {
    redirectUrl?: string | undefined
    proctorEduToken?: string | undefined
}

export interface  ProctorLoginReturn {
    redirectUrl: string,
    studentRosterErrors: string[],
    bbProctorToken: string | undefined
}

export default class ProctorApi {

    static verificientSigninAndRedirect(payload: VerificientLogin): Promise<VerificientSigninReturn> {
        const url: string = `${ApiService.API_URL}proctor/redirect`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.post(url, payload, true).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    // reject(error)
                }
            ))
        })
    }

    static loginToVerificient(payload: VerificientLogin): Promise<VerificientAvailableLogins[]> {
        const url: string = `${ApiService.API_URL}proctor/login`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.post(url, payload)).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static verificientProctorLogin(payload: VerificientLogin): Promise<ProctorLoginReturn> {
        const url: string = `${ApiService.API_URL}proctor/proctor_login`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.post(url, payload)).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static verificientInstructorLogin(payload: VerificientLogin): Promise<VerificientSigninReturn> {
        const url: string = `${ApiService.API_URL}proctor/instructor_login`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.post(url, payload)).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static verificientStudentLoginCheck(loginId: string): Promise<VerificientSigninReturn> {
        const url: string = `${ApiService.API_URL}proctor/student_login_check/${loginId}`
        return new Promise((resolve, reject) =>
            ApiService.get(url)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        )
    }

    static verificientStudentLogin(loginId: string, loginName: string): Promise<VerificientSigninReturn> {
        const url: string = `${ApiService.API_URL}proctor/student_login`
        return new Promise((resolve, reject) =>
            ApiService.addInteractionBlockingRequest(
            ApiService.post(url, {loginId, loginName})
                .then(response => resolve(response.data))
                .catch(error => reject(error))
            )
        )
    }

}
