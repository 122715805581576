import * as React from "react"
import {SSOUnauthorizedDistrictAdmin} from "./components/SSOUnauthorizedDistrictAdmin"
import {authStore} from "../../common/authentication/AuthStore"
import {RouteComponentProps} from "react-router-dom"

/**
 * This page takes an SSO user (authenticated at the SSO) and determines if the District or School Admin has been authorized to use the product
 */
export const ssoDistrictAdminLandingPage: React.FC<RouteComponentProps> = (props) => {
    if (authStore.isSsoAvantAuthorized) {
        props.history.push("/sso/distadmin/setup")
    }
    return (
        <SSOUnauthorizedDistrictAdmin/>
    )
}
