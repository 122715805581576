import {AppConfig, ProductSubDomainEnum, TestEngineOptions} from "../types/types"
import {to} from "../util/elvis"

declare const appConfigs: AppConfig

export const APP_CONFIG: AppConfig = appConfigs

export class GenericConfiguration {
    MEDIA_BASE_URL: string = ""
    CDN_BASE_URL: string = ""
    AUDIO_CHECK_RECORDING_URL: string = ""
    SUPPORT_EMAIL: string = ""
    REFRESH_TOKEN_COOLDOWN: number
    EXPIRE_TOKEN_MODAL_BUFFER: number
    AUTH_TOKEN_SECONDS_TILL_EXPIRE: number
    ENV: string = ""
    product: ProductSubDomainEnum
    TEST_ENGINE_CONFIG: TestEngineOptions
    APP_NAME_VERBOSE: string = ""
    APP_PRODUCT_NAME: string = ""
    PRODUCT_TITLE: string = ""
    PRODUCT_ID: number
    TESTING_COORDINATOR_URL: string = ""

    WR_SAVE_COOLDOWN: number
    TOTAL_TEST_TIME: number
    IS_RTL_LAYOUT: boolean

    DEV_DEFAULT_PASSWORD?: string
    DEV_DEFAULT_USERNAME?: string
    DEV_DEFAULT_TAKECODE?: string
    TAKE_CODE_LABEL?: string
    TEST_CODES: string[]

    FEATURE_FLAG_HTML5_RECORDING_DISABLED: boolean
    DISABLE_EULA: boolean
    LANGUAGE: string = ""
    COMMIT_HASH: string = ""

    constructor(product: ProductSubDomainEnum) {
        this.product = product
        this.MEDIA_BASE_URL = to<string>(APP_CONFIG.MEDIA_BASE_URL, new Error("Uninitialized MEDIA_BASE_URL"))
        this.CDN_BASE_URL = to<string>(APP_CONFIG.CDN_BASE_URL, new Error("Uninitialized CDN_BASE_URL"))
        this.AUDIO_CHECK_RECORDING_URL = to<string>(
            APP_CONFIG.AUDIO_CHECK_RECORDING_URL,
            new Error("Uninitialized AUDIO_CHECK_RECORDING_URL")
        )
        this.SUPPORT_EMAIL = to<string>(APP_CONFIG.SUPPORT_EMAIL, new Error("Uninitialized SUPPORT_EMAIL"))
        this.REFRESH_TOKEN_COOLDOWN = to<number>(
            APP_CONFIG.REFRESH_TOKEN_COOLDOWN,
            new Error("Uninitialized REFRESH_TOKEN_COOLDOWN")
        )
        this.EXPIRE_TOKEN_MODAL_BUFFER = to<number>(
            APP_CONFIG.EXPIRE_TOKEN_MODAL_BUFFER,
            new Error("Uninitialized EXPIRE_TOKEN_MODAL_BUFFER")
        )
        this.AUTH_TOKEN_SECONDS_TILL_EXPIRE = to<number>(
            APP_CONFIG.AUTH_TOKEN_SECONDS_TILL_EXPIRE,
            new Error("Uninitialized AUTH_TOKEN_SECONDS_TILL_EXPIRE")
        )
        this.ENV = to<string>(APP_CONFIG.ENV, new Error("Uninitialized ENV"))

        this.FEATURE_FLAG_HTML5_RECORDING_DISABLED = to<boolean>(
            APP_CONFIG.FEATURE_FLAG_HTML5_RECORDING_DISABLED,
            new Error("Please explicitly set the HTML5 Feature Flag or remove it if obsolete")
        )

        // I feel like these shouldn't have to be defaulted, but they are
        this.WR_SAVE_COOLDOWN = to<number>(APP_CONFIG.WR_SAVE_COOLDOWN, new Error("Uninitialized WR_SAVE_COOLDOWN"))
        this.TOTAL_TEST_TIME = to<number>(APP_CONFIG.TOTAL_TEST_TIME, new Error("Uninitialized TOTAL_TEST_TIME"))
        this.IS_RTL_LAYOUT = to<boolean>(APP_CONFIG.IS_RTL_LAYOUT, new Error("Uninitialized IS_RTL_LAYOUT"))

        this.COMMIT_HASH = to<string>(APP_CONFIG.commitHash, "unset")

        // Hey do we have configuration for this product? Explode if not.
        if (!APP_CONFIG[this.product]) {
            throw new Error("Configuration does not exist for " + this.product)
        }

        // Required Product Specific Configs
        this.TEST_ENGINE_CONFIG = to<TestEngineOptions>(
            APP_CONFIG[this.product].TEST_ENGINE_CONFIG,
            new Error("Uninitialized TEST_ENGINE_CONFIG")
        )

        this.APP_NAME_VERBOSE = to<string>(
            APP_CONFIG[this.product].APP_NAME_VERBOSE,
            new Error("Uninitialized APP_NAME_VERBOSE")
        )
        this.APP_PRODUCT_NAME = to<string>(
            APP_CONFIG[this.product].APP_PRODUCT_NAME,
            new Error("Uninitialized APP_PRODUCT_NAME")
        )
        this.PRODUCT_TITLE = to<string>(APP_CONFIG[this.product].PRODUCT_TITLE, new Error("Uninitialized PRODUCT_TITLE"))
        this.PRODUCT_ID = to<number>(APP_CONFIG[this.product].PRODUCT_ID, new Error("Uninitialized PRODUCT_ID"))

        // Product specific overrides
        this.MEDIA_BASE_URL = to<string>(APP_CONFIG[this.product].MEDIA_BASE_URL, this.MEDIA_BASE_URL)
        this.AUDIO_CHECK_RECORDING_URL = to<string>(
            APP_CONFIG[this.product].AUDIO_CHECK_RECORDING_URL,
            this.AUDIO_CHECK_RECORDING_URL
        )
        this.SUPPORT_EMAIL = to<string>(APP_CONFIG[this.product].SUPPORT_EMAIL, this.SUPPORT_EMAIL)
        this.REFRESH_TOKEN_COOLDOWN = to<number>(
            APP_CONFIG[this.product].REFRESH_TOKEN_COOLDOWN,
            this.REFRESH_TOKEN_COOLDOWN
        )
        this.EXPIRE_TOKEN_MODAL_BUFFER = to<number>(
            APP_CONFIG[this.product].EXPIRE_TOKEN_MODAL_BUFFER,
            this.EXPIRE_TOKEN_MODAL_BUFFER
        )
        this.AUTH_TOKEN_SECONDS_TILL_EXPIRE = to<number>(
            APP_CONFIG[this.product].AUTH_TOKEN_SECONDS_TILL_EXPIRE,
            this.AUTH_TOKEN_SECONDS_TILL_EXPIRE
        )
        this.ENV = to<string>(APP_CONFIG[this.product].ENV, this.ENV)
        this.WR_SAVE_COOLDOWN = to<number>(APP_CONFIG[this.product].WR_SAVE_COOLDOWN, this.WR_SAVE_COOLDOWN)
        this.TOTAL_TEST_TIME = to<number>(APP_CONFIG[this.product].TOTAL_TEST_TIME, this.TOTAL_TEST_TIME)
        this.IS_RTL_LAYOUT = to<boolean>(APP_CONFIG[this.product].IS_RTL_LAYOUT, this.IS_RTL_LAYOUT)
        this.TESTING_COORDINATOR_URL = APP_CONFIG[this.product].TESTING_COORDINATOR_URL
            ? APP_CONFIG[this.product].TESTING_COORDINATOR_URL
            : ""
        this.DISABLE_EULA = APP_CONFIG[this.product].DISABLE_EULA ? APP_CONFIG[this.product].DISABLE_EULA : ""

        // TODO not sure if this is right?
        this.DEV_DEFAULT_PASSWORD = APP_CONFIG[this.product].DEV_DEFAULT_PASSWORD
        this.DEV_DEFAULT_TAKECODE = APP_CONFIG[this.product].DEV_DEFAULT_TAKECODE
        this.DEV_DEFAULT_USERNAME = APP_CONFIG[this.product].DEV_DEFAULT_USERNAME
        this.TAKE_CODE_LABEL = APP_CONFIG[this.product].TAKE_CODE_LABEL
        this.TEST_CODES = APP_CONFIG[this.product].TEST_CODES
        this.LANGUAGE = APP_CONFIG[this.product].LANGUAGE
    }
}

const adminConfig = new GenericConfiguration(ProductSubDomainEnum.ADMIN)
adminConfig.DISABLE_EULA = to<boolean>(APP_CONFIG[adminConfig.product].DISABLE_EULA, adminConfig.DISABLE_EULA)

const aptConfig = new GenericConfiguration(ProductSubDomainEnum.APT)
aptConfig.TAKE_CODE_LABEL = to<string>(
    APP_CONFIG[aptConfig.product].TAKE_CODE_LABEL,
    new Error("Uninitialized TAKE_CODE_LABEL")
)

const placementConfig = new GenericConfiguration(ProductSubDomainEnum.PLACE)
placementConfig.TAKE_CODE_LABEL = to<string>(
    APP_CONFIG[placementConfig.product].TAKE_CODE_LABEL,
    new Error("Uninitialized TAKE_CODE_LABEL")
)

export class ConfigurationService {
    static adminConfig = adminConfig
    static aptConfig = aptConfig
    static advanceConfig = new GenericConfiguration(ProductSubDomainEnum.ADVANCE)
    static placeConfig = placementConfig
    static jeenieConfig = new GenericConfiguration(ProductSubDomainEnum.JEENIE)
    static worldSpeakConfig = new GenericConfiguration(ProductSubDomainEnum.WORLD_SPEAK)
    static readyConfig = new GenericConfiguration(ProductSubDomainEnum.READY)
    static stamp4s = new GenericConfiguration(ProductSubDomainEnum.STAMP_4S)
    static stamp4se = new GenericConfiguration(ProductSubDomainEnum.STAMP4_4SE)
}
