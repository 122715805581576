import {computed, observable} from "mobx"
import {ApiErrorResponse, P360ExamData, PanelSkillEnum} from "../../../../types/types"
import ApiService from "../../../../services/ApiService"
import {AxiosResponse} from "axios"

class SidebarStore {

    /**
     * Open if this is defined
     */
    @observable
    takeId?: number

    @observable
    openProfile = true

    @observable
    openP360: boolean = false

    @observable
    P360Data?: P360ExamData[] | undefined

    @observable
    openSelfEval?: boolean = false

    @observable
    openPanel?: PanelSkillEnum

    @observable
    isPlaceC: boolean = false

    @computed
    get open(): boolean {
        return this.takeId != null
    }

    getSecuredAsset = async (url: string): Promise<string> => {
        const endPoint: string = `${ApiService.API_URL}asset/get-secured-asset`

        return await ApiService.post(endPoint, {url}, true)
            .then((res: AxiosResponse) => {
                return res.data.url
            })
            .catch((err: ApiErrorResponse) => {
                throw err
            })
    }
}

export const sidebarStore = new SidebarStore()
