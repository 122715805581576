import * as React from "react"
import ProctorApi, {VerificientAvailableLogins} from "../../services/ProctorApi"
import {H3Text} from "../../styles/AvantTypography"
import {CONTENT_AREA_ID_TO_LANGUAGE_NAME} from "../../util/Constants"
import {loadingSpinnerStore} from "../common/loaders/LoadingSpinnerStore"
import {VerificientLogin} from "./RosterSelectTake"

import {Grid} from "@material-ui/core"

const MS_DELAY_BEFORE_REDIRECT = 2000

export interface ProcessingTakeProps {
    loginData: VerificientLogin,
    take: VerificientAvailableLogins
}
const ProcessingTake: React.FC<ProcessingTakeProps> = (props) => {
    const language = CONTENT_AREA_ID_TO_LANGUAGE_NAME[props.take.contentAreaId]

    ProctorApi.verificientSigninAndRedirect(props.loginData)
        .then(redirectResponse => {
            if (redirectResponse.redirectUrl != null) {
                if (redirectResponse.redirectUrl.length > 0) {
                    loadingSpinnerStore.hideLoadingSpinner = false
                    // setup for humans to possible read the page
                    setTimeout(() => {
                        // The bang here is becasue we have already proven redirectResponse is not equal to undefined
                        window.location.replace(redirectResponse.redirectUrl!)
                    }, MS_DELAY_BEFORE_REDIRECT)
                }
            }

        })

    return (
        <Grid container={true}>
            <Grid item={true} xs={12}>
                <H3Text>We found the {language} test, sending you to the online proctor.</H3Text>
            </Grid>
        </Grid>
    )
}
export default ProcessingTake
