import {observer} from "mobx-react"
import moment from "moment"
import React from "react"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"
import {authStore} from "../../app/common/authentication/AuthStore"
import {setAdvanceReportDistrictId} from "../../redux/app/actions"
import ApiService from "../../services/ApiService"
import HelperService from "../../services/HelperService"
import {AdvanceEducator, AdvanceEducatorLanguage, AdvanceModuleState, SelectOption, State} from "../../types/types"
import Button from "../Button/Button"
import Dropdown from "../Dropdown/Dropdown"
import AdvanceReportEducatorModal from "./AdvanceReportEducatorModal"

interface StateToProps {
    districtId: number
    school?: string
    language?: string
}

const mapStateToProps = (state: State): StateToProps => {
    return {
        districtId: state.app.advanceReportDistrictId!,
        school: state.app.advanceReportSchool,
        language: state.app.advanceReportLanguage
    }
}

interface DispatchToProps {
    setDistrictId: (districtId: number) => void
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchToProps => {
    return {
        setDistrictId: (districtId: number) => {
            dispatch(setAdvanceReportDistrictId(districtId))
        }
    }
}

interface AdvanceReportEducatorsProps extends StateToProps, DispatchToProps, RouteComponentProps {}

interface AdvanceReportEducatorsState {
    educators?: AdvanceEducator[]
    highestCompletedOptions?: SelectOption[]
    languageOptions?: SelectOption[]
    schoolOptions?: SelectOption[]
    keyword: string
    language: string
    school: string
    highestCompleted: string
    educator?: AdvanceEducator
    showModal: boolean
}

@observer
class AdvanceReportEducators extends React.Component<AdvanceReportEducatorsProps, AdvanceReportEducatorsState> {
    state: AdvanceReportEducatorsState

    constructor(props: AdvanceReportEducatorsProps) {
        super(props)
        this.state = {
            language: props.language ? props.language : "All",
            school: props.school ? props.school : "All",
            highestCompleted: "Any",
            keyword: "",
            showModal: false
        }
    }

    componentDidMount() {
        HelperService.enableTextSelection()
        this.getEducators()
    }

    componentDidUpdate() {
        const {districtId, history} = this.props
        if (districtId && !this.state.educators) {
            this.getEducators()
        } else if (!districtId) {
            history.push("/advance-report")
        }
    }

    getEducators = () => {
        const userId: number = authStore.auth!!.loginId!
        const groupId: number = this.props.districtId
        ApiService
            .getAdvanceEducatorsReport(userId, groupId)
            .then((educators: AdvanceEducator[]) => {
                const languageOptions: SelectOption[] = []
                const schoolOptions: SelectOption[] = []

                const seenLanguages = new Set<string>()
                const seenSchools = new Set<string>()
                educators.forEach((educator: AdvanceEducator) => {
                    if (!seenSchools.has(educator.school.name)) {
                        seenSchools.add(educator.school.name)
                        schoolOptions.push({label: educator.school.name, value: educator.school.name})
                    }
                    educator.languages.forEach((language: AdvanceEducatorLanguage) => {
                        if (!seenLanguages.has(language.name)) {
                            seenLanguages.add(language.name)
                            languageOptions.push({label: language.name, value: language.name})
                        }
                    })
                    educator.languages.sort((a: AdvanceEducatorLanguage, b: AdvanceEducatorLanguage) => {
                        if (a.name < b.name) {
                            return -1
                        } else if (a.name > b.name) {
                            return 1
                        } else {
                            return 0
                        }
                    })
                })
                languageOptions.sort((a: SelectOption, b: SelectOption) => {
                    if (a.label < b.label) {
                        return -1
                    } else if (a.label > b.label) {
                        return 1
                    } else {
                        return 0
                    }
                })
                languageOptions.unshift({label: "All", value: "All"})
                schoolOptions.sort((a: SelectOption, b: SelectOption) => {
                    if (a.label < b.label) {
                        return -1
                    } else if (a.label > b.label) {
                        return 1
                    } else {
                        return 0
                    }
                })
                schoolOptions.unshift({label: "All", value: "All"})

                const highestCompletedOptions: SelectOption[] = [
                    {
                        label: "Any",
                        value: "Any"
                    },
                    {
                        label: "Module 1",
                        value: "Module 1"
                    },
                    {
                        label: "Module 2",
                        value: "Module 2"
                    },
                    {
                        label: "Module 3",
                        value: "Module 3"
                    },
                    {
                        label: "Module 4",
                        value: "Module 4"
                    },
                    {
                        label: "Module 5",
                        value: "Module 5"
                    },
                    {
                        label: "Module 6",
                        value: "Module 6"
                    },
                    {
                        label: "Certification",
                        value: "Certification"
                    }
                ]

                this.setState({educators, highestCompletedOptions, languageOptions, schoolOptions})
            })
            .catch(() => {
                // TODO handle error
            })
    }

    downloadCsv = () => {
        let text: string =
            "First Name, Last Name, Username, Language, Total time, " +
            "Module 1 Status, Module 1 Time, Module 1 Completion Date, " +
            "Module 2 Status, Module 2 Time, Module 2 Completion Date, " +
            "Module 3 Status, Module 3 Time, Module 3 Completion Date, " +
            "Module 4 Status, Module 4 Time, Module 4 Completion Date, " +
            "Module 5 Status, Module 5 Time, Module 5 Completion Date, " +
            "Module 6 Status, Module 6 Time, Module 6 Completion Date, " +
            "Practice Test Time, " +
            "Certification Status, Certification Time, Certification Date\n"

        const educators: AdvanceEducator[] = this.applyFilters(this.state.educators!)
        educators.forEach((educator: AdvanceEducator) => {
            const names = educator.name.split(" ")
            const firstName = names[0]
            let lastName = ""
            names.forEach((name: string, index: number) => {
                if (index !== 0) {
                    lastName += `${name} `
                }
            })
            lastName = lastName.trim()
            const username = educator.username

            educator.languages.forEach((language: AdvanceEducatorLanguage) => {
                const m1RawCompletionDate = language.modules.m1.completionDate
                const m1CompletionDate =
                    m1RawCompletionDate !== null && m1RawCompletionDate !== undefined
                        ? moment(m1RawCompletionDate).format("M/D/YYYY")
                        : ""
                const m2RawCompletionDate = language.modules.m2.completionDate
                const m2CompletionDate =
                    m2RawCompletionDate !== null && m2RawCompletionDate !== undefined
                        ? moment(m2RawCompletionDate).format("M/D/YYYY")
                        : ""
                const m3RawCompletionDate = language.modules.m3.completionDate
                const m3CompletionDate =
                    m3RawCompletionDate !== null && m3RawCompletionDate !== undefined
                        ? moment(m3RawCompletionDate).format("M/D/YYYY")
                        : ""
                const m4RawCompletionDate = language.modules.m4.completionDate
                const m4CompletionDate =
                    m4RawCompletionDate !== null && m4RawCompletionDate !== undefined
                        ? moment(m4RawCompletionDate).format("M/D/YYYY")
                        : ""
                const m5RawCompletionDate = language.modules.m5.completionDate
                const m5CompletionDate =
                    m5RawCompletionDate !== null && m5RawCompletionDate !== undefined
                        ? moment(m5RawCompletionDate).format("M/D/YYYY")
                        : ""
                const m6RawCompletionDate = language.modules.m6.completionDate
                const m6CompletionDate =
                    m6RawCompletionDate !== null && m6RawCompletionDate !== undefined
                        ? moment(m6RawCompletionDate).format("M/D/YYYY")
                        : ""
                const certRawCompletionDate = language.modules.cert.completionDate
                const certCompletionDate =
                    certRawCompletionDate !== null && certRawCompletionDate !== undefined
                        ? moment(certRawCompletionDate).format("M/D/YYYY")
                        : ""

                text += `${firstName}, ${lastName}, ${username}, ${language.name}, ${language.totalTimeTaken}, `
                text += `${language.modules.m1.status}, ${language.modules.m1.timeTaken}, ${m1CompletionDate}, `
                text += `${language.modules.m2.status}, ${language.modules.m2.timeTaken}, ${m2CompletionDate}, `
                text += `${language.modules.m3.status}, ${language.modules.m3.timeTaken}, ${m3CompletionDate}, `
                text += `${language.modules.m4.status}, ${language.modules.m4.timeTaken}, ${m4CompletionDate}, `
                text += `${language.modules.m5.status}, ${language.modules.m5.timeTaken}, ${m5CompletionDate}, `
                text += `${language.modules.m6.status}, ${language.modules.m6.timeTaken}, ${m6CompletionDate}, `
                text += `${language.modules.practice.timeTaken}, `
                text += `${language.modules.cert.status}, ${language.modules.cert.timeTaken}, ${certCompletionDate}\n`
            })
        })

        // Create hidden link
        const blob = new Blob([text], {type: "text/csv;charset=utf8;"})
        const element = document.createElement("a")
        document.body.appendChild(element)
        element.setAttribute("href", window.URL.createObjectURL(blob))
        element.setAttribute("download", "advance_report.csv")
        element.style.display = ""
        element.click()
        document.body.removeChild(element)
    }

    moduleStateToIcon = (state: AdvanceModuleState): React.ReactNode => {
        switch (state) {
            case AdvanceModuleState.LOCKED:
                return <i className="material-icons advance-report__container__data__percent__lock--failed">lock</i>
            case AdvanceModuleState.UNLOCKED:
                return <div className="advance-report__container__data__percent__circle--unlocked"/>
            case AdvanceModuleState.IN_PROGRESSS:
                return <div className="advance-report__container__data__percent__circle--in-progress"/>
            case AdvanceModuleState.FAILED:
                return (
                    <div className="advance-report__container__data__percent__circle--failed">
                        <div className="x-container">
                            <i className="material-icons x-container__x">close</i>
                        </div>
                    </div>
                )
            case AdvanceModuleState.COMPLETED:
                return (
                    <div className="advance-report__container__data__percent__circle--completed">
                        <div className="check-container">
                            <i className="material-icons check-container__check">done</i>
                        </div>
                    </div>
                )
            default:
                throw Error(`[Advance Report Educators] Unexpected module state ${state}`)
        }
    }

    handleKeywordChange = (event: any) => {
        this.setState({keyword: event.target.value})
    }

    handleLanguageChange = (language: string) => {
        this.setState({language})
    }

    handleSchoolChange = (school: string) => {
        this.setState({school})
    }

    handleHighestCompletedChange = (highestCompleted: string) => {
        this.setState({highestCompleted})
    }

    handleModalVisibility = (showModal: boolean) => {
        this.setState({showModal})
    }

    openEducatorView = (educator: AdvanceEducator) => {
        this.setState({educator, showModal: true})
    }

    renderEducatorLanguages = (languages: AdvanceEducatorLanguage[], index: number) => {
        const className = "advance-report__container__data"
        const childClass =
            "advance-report__container__data__child grid-col-5 advance-report__container__data__child--no-hover"
        return (
            <div className={className}>
                {languages.map((language: AdvanceEducatorLanguage) => {
                    let languageClass = "advance-report__container__data grid-row "
                    if (index % 2 !== 0) {
                        languageClass += "advance-report__container__data--dark"
                    }
                    const percentClass = "advance-report__container__data__percent grid-col-1 grid-col grid-col-height"
                    return (
                        <>
                            <div className="advance-report__container__data-line"/>
                            <div className={languageClass}>
                                <div className={childClass}>
                                    <span className="advance-report__container__data__child__title">
                                        {language.name}
                                    </span>
                                </div>
                                <div className={percentClass}>{this.moduleStateToIcon(language.modules.m1.status)}</div>
                                <div className={percentClass}>{this.moduleStateToIcon(language.modules.m2.status)}</div>
                                <div className={percentClass}>{this.moduleStateToIcon(language.modules.m3.status)}</div>
                                <div className={percentClass}>{this.moduleStateToIcon(language.modules.m4.status)}</div>
                                <div className={percentClass}>{this.moduleStateToIcon(language.modules.m5.status)}</div>
                                <div className={percentClass}>{this.moduleStateToIcon(language.modules.m6.status)}</div>
                                <div className={percentClass}>
                                    {this.moduleStateToIcon(language.modules.cert.status)}
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>
        )
    }

    renderEducators = (educators: AdvanceEducator[]) => {
        return (
            <>
                {educators.map((educator: AdvanceEducator, index: number) => {
                    const borderClass = index === educators.length - 1 ? "" : "advance-report__container__border"
                    let languageClass =
                        "advance-report__container__data advance-report__container__data--hover grid-row "
                    if (index % 2 !== 0) {
                        languageClass += "advance-report__container__data--dark"
                    }
                    return (
                        <div
                            key={Math.random()}
                            className={borderClass}
                            onClick={() => this.openEducatorView(educator)}
                        >
                            <div className={languageClass}>
                                <div className="advance-report__container__data__parent grid-col-5">
                                    <span className="advance-report__container__data__parent__title">
                                        {!educator.name.includes("null") &&
                                        educator.name !== null &&
                                        educator.name !== undefined
                                            ? educator.name
                                            : educator.username}
                                    </span>
                                </div>
                            </div>
                            {this.renderEducatorLanguages(educator.languages, index)}
                        </div>
                    )
                })}
            </>
        )
    }

    applyFilters = (unfilteredEducators: AdvanceEducator[]): AdvanceEducator[] => {
        const educators: AdvanceEducator[] = []
        for (const educator of unfilteredEducators) {
            let keywordMatch = false
            if (
                (this.state.keyword &&
                    this.state.keyword !== "" &&
                    educator.name.toLowerCase().includes(this.state.keyword.toLowerCase())) ||
                this.state.keyword === ""
            ) {
                keywordMatch = true
            }

            let schoolMatch = false
            if (educator.school.name === this.state.school || this.state.school === "All") {
                schoolMatch = true
            }

            let languageMatch = false
            if (this.state.language === "All") {
                languageMatch = true
            } else {
                for (const language of educator.languages) {
                    if (language.name === this.state.language) {
                        languageMatch = true
                        break
                    }
                }
            }

            let highestCompletedMatch = false
            if (this.state.highestCompleted === "Any") {
                highestCompletedMatch = true
            } else {
                for (const language of educator.languages) {
                    const isValidLanguage = this.state.language === "All" || language.name === this.state.language
                    if (
                        this.state.highestCompleted === "Module 1" &&
                        language.modules.m1.status === "COMPLETED" &&
                        language.modules.m2.status !== "COMPLETED" &&
                        isValidLanguage
                    ) {
                        highestCompletedMatch = true
                    } else if (
                        this.state.highestCompleted === "Module 2" &&
                        language.modules.m2.status === "COMPLETED" &&
                        language.modules.m3.status !== "COMPLETED" &&
                        isValidLanguage
                    ) {
                        highestCompletedMatch = true
                    } else if (
                        this.state.highestCompleted === "Module 3" &&
                        language.modules.m3.status === "COMPLETED" &&
                        language.modules.m4.status !== "COMPLETED" &&
                        isValidLanguage
                    ) {
                        highestCompletedMatch = true
                    } else if (
                        this.state.highestCompleted === "Module 4" &&
                        language.modules.m4.status === "COMPLETED" &&
                        language.modules.m5.status !== "COMPLETED" &&
                        isValidLanguage
                    ) {
                        highestCompletedMatch = true
                    } else if (
                        this.state.highestCompleted === "Module 5" &&
                        language.modules.m5.status === "COMPLETED" &&
                        language.modules.m6.status !== "COMPLETED" &&
                        isValidLanguage
                    ) {
                        highestCompletedMatch = true
                    } else if (
                        this.state.highestCompleted === "Module 6" &&
                        language.modules.m6.status === "COMPLETED" &&
                        language.modules.cert.status !== "COMPLETED" &&
                        isValidLanguage
                    ) {
                        highestCompletedMatch = true
                    } else if (
                        this.state.highestCompleted === "Certification" &&
                        language.modules.cert.status === "COMPLETED" &&
                        isValidLanguage
                    ) {
                        highestCompletedMatch = true
                    }
                }
            }

            if (keywordMatch && schoolMatch && languageMatch && highestCompletedMatch) {
                educators.push(educator)
            }
        }

        return educators
    }

    render() {
        let educators: AdvanceEducator[] = []
        if (this.state.educators) {
            educators = this.applyFilters(this.state.educators)
        }
        const headingsClass = "advance-report__container__headings advance-report__container__border grid-row"
        return (
            <div className="advance-report">
                {this.state.educators && this.state.language && this.state.school && (
                    <>
                        <div className="advance-report__top">
                            <div className="advance-report__top__title">
                                <div className="advance-report__top__title__district">
                                    {this.state.educators[0].districtName}
                                </div>
                                <div className="advance-report__top__title__dashboard">Educators</div>
                            </div>
                            <div>
                                <Button
                                    className="advance-report__top__download-button avant-button--secondary"
                                    onClick={this.downloadCsv}
                                >
                                    DOWNLOAD CSV
                                </Button>
                            </div>
                        </div>
                        <div className="advance-report__filters">
                            <div>
                                <div className="advance-report__filters__label">Keyword Search</div>
                                <input
                                    className="advance-report__filters__input"
                                    type="text"
                                    value={this.state.keyword}
                                    placeholder=""
                                    onChange={this.handleKeywordChange}
                                />
                            </div>
                            {this.state.languageOptions && (
                                <div>
                                    <div className="advance-report__filters__label">Language</div>
                                    <Dropdown
                                        value={this.state.language}
                                        options={this.state.languageOptions}
                                        onChange={this.handleLanguageChange}
                                    />
                                </div>
                            )}
                            {this.state.schoolOptions && (
                                <div>
                                    <div className="advance-report__filters__label">School</div>
                                    <Dropdown
                                        value={this.state.school}
                                        options={this.state.schoolOptions}
                                        onChange={this.handleSchoolChange}
                                    />
                                </div>
                            )}
                            {this.state.highestCompletedOptions && (
                                <div>
                                    <div className="advance-report__filters__label">Highest Completed</div>
                                    <Dropdown
                                        value={this.state.highestCompleted}
                                        options={this.state.highestCompletedOptions}
                                        onChange={this.handleHighestCompletedChange}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="advance-report__container grid">
                            <div className={headingsClass}>
                                <div className="grid-col-5">
                                    <i className="material-icons advance-report__container__icon">person</i>
                                    Name
                                </div>
                                <div className="grid-col-1 grid-col">M1</div>
                                <div className="grid-col-1 grid-col">M2</div>
                                <div className="grid-col-1 grid-col">M3</div>
                                <div className="grid-col-1 grid-col">M4</div>
                                <div className="grid-col-1 grid-col">M5</div>
                                <div className="grid-col-1 grid-col">M6</div>
                                <div className="grid-col-1 grid-col">Cert</div>
                            </div>
                            {this.renderEducators(educators)}
                        </div>
                        <AdvanceReportEducatorModal
                            educator={this.state.educator ? this.state.educator : this.state.educators[0]}
                            showModal={this.state.showModal}
                            handleModalVisibility={this.handleModalVisibility}
                        />
                    </>
                )}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdvanceReportEducators)
