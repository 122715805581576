import {Dialog, DialogTitle, Grid, makeStyles, Paper, Typography, List, ListItem, ListItemText} from "@material-ui/core"
import * as React from "react"
import Button from "@material-ui/core/Button"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {SsoClassUpdateWritingMethodSummary} from "../../../../types/types";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        maxWidth: '350px',
        color: theme.palette.text.secondary,
    },
}))

interface SSOBulkClassSetupConfirmProps {
    handleOK: () => void
    handleCancel: () => void
    productName: string | null
    testLanguage: string | null
    estimatedStartDate: string | null
    writingTestMethod: string | null
    countToChange: number
    bulkClassUpdateSummaries: SsoClassUpdateWritingMethodSummary[]
}

export const SSOBulkClassSetupConfirm: React.FC<SSOBulkClassSetupConfirmProps> = (props) => {
    const classes = useStyles()

    return (
        <Dialog  onClose={props.handleCancel} aria-labelledby="Class Roster" open={true}>
            <DialogTitle id="simple-dialog-title">
                Confirm Test
            </DialogTitle>

            <Paper className={classes.paper} elevation={3} >
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        {props.countToChange === 0 && (
                            <Typography>Please select some testing groups to change.</Typography>
                        )}
                        {props.countToChange > 0 && (
                            <>
                                <Typography align={"left"}>You are about to set up tests for the the following testing groups. You will not be able to make any changes after you set up the test. Please review the information and choose to continue or cancel the operation. Would you like to continue?</Typography>
                                <List>
                                    <ListItem>
                                        <ListItemText primary={`Product Name: ${props.productName}`} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={`Test Language: ${props.testLanguage}`} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={`Estimated Start Date: ${props.estimatedStartDate}`} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={`Writing Test Method: ${props.writingTestMethod}`} />
                                    </ListItem>
                                </List>
                            </>
                        )}
                    </Grid>

                    <Grid item xs={6}>
                        <Button variant="contained" color="secondary" onClick={props.handleCancel}>No, Cancel</Button>
                    </Grid>
                    <Grid item xs={6}>
                        {props.countToChange > 0 && (
                            <Button variant="contained" color="primary" onClick={props.handleOK}>Yes, Continue</Button>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        {props.countToChange > 0 && (
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Testing Group Name</TableCell>
                                        <TableCell>Writing Test Method</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                    {props.bulkClassUpdateSummaries.map((summary) => (
                                    <TableRow key={summary.groupId.toString()}>
                                        <TableCell>{summary.className}</TableCell>
                                        <TableCell>{summary.writingMethod}</TableCell>
                                    </TableRow>
                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        )}
                    </Grid>

                </Grid>
            </Paper>

        </Dialog>
    )
}
