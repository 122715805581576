import {Coords} from "./StampSkillTriangle"
import {parse} from 'svg-path-round-corners/dist/es5/parse'
import {serialize} from 'svg-path-round-corners/dist/es5/serialize'
import {roundCorners} from 'svg-path-round-corners/dist/es5/index'

const TO_RAD = (Math.PI / 180)
const PHI: number = 55.0 //angle of the bottom for the inverted pyramid.

export const OVERALL_DISPLAY_HEIGHT = 350.0
export const SKILL_TRIANGLE_HEIGHT: number = 340.0
export const SKILL_TRIANGLE_ORIGIN_X: number = 0

const ROUNDING = 10 //The amount of rounding on a vertex of a path.

/**
 * Functions that work on the Skill Triangles
 * The Skill Triangle/Pyramid is isosceles triangle. ∠φθθ  (∠PHI THETA THETA)
 * Line Segments: bba
 * Height: h
 *   +--- a ---+
 *        +-a'-+
 *    ----+---- ∠θ    ∧
 *    \   |h  /       |
 *  b  \  |  / b      |  Negative Y
 *      \ | /         |
 *       \|/          |
 *        + <--- 0,0 origin
 *     ∠φ = 55°
 * https://brilliant.org/wiki/properties-of-isosceles-triangles/
 *
 * We create congruent triangles to line up and create the levels of the skills
 */
export default class SkillTriangleUtils {

    /**
     * Get the right X coordinate
     *
     * The X coordinate is the length of A' since the origin bisects A
     * Using ∆A'Bh we can get the length of A' with sin(φ/2) = Opp/Hyp => H*sin(φ/2) = O => b*sin(φ/2) = A'.
     *
     * @param b Length of the b line segment
     */
    static topRightX = (b: number): number => {
        const aPrime = b * Math.sin(PHI/2 * TO_RAD)
        return aPrime
    }
    /**
     * Get the Left X coordinate
     *
     * Same are RightX but in neg space
     *
     * @param b Length of the b line segment
     */
    static topLeftX = (b: number): number => {
        return SkillTriangleUtils.topRightX(b) * (-1)
    }

    /**
     * Get the Y coordinate
     *
     * The Y coordinate is h and to be consistant with these functions we are using b to find h.
     * Using ∆A'Bh we can get the length of b with cos(φ/2) = Adj/Hyp => H*cos(φ/2) = A => b*cos(φ/2) = h
     * Y is in negative space
     *
     * @param b Length of the b line segment
     */
    static topLeftY = (b: number): number => {
        const h = b * Math.cos(PHI/2 * TO_RAD)
        return h * (-1)
    }
    /**
     * Get the Y coordinate
     *
     * The whole triangle is in the neg Y space and the Y is the same for both sides.
     *
     * @param b Length of the b line segment
     */
    static topRightY = (b: number): number => {
        return SkillTriangleUtils.topLeftY(b)
    }

    /**
     * Finds the length of sides b of the isosceles triangle.
     *
     * This b value is used in all of XY calcs above and sets the scale for the all of the
     * triangles that we create.
     * Using ∆A'Bh we can get the length of h with cos(φ/2) = Adj/Hyp => cos(φ/2)/A = H => cos(φ/2)/h = b
     *
     * @param h This is the full height in pixels of the triangle
     * @param scale Is fractional number between 0 and 1. 1 is the full sized triangle. To create the congruent
     *              triangles for the skill tree pass the fraction(s) of the whole so if the are ten levels and
     *              you want the 3rd skill level triangle scale = 3/10  (level/max levels)
     */
    static findB = (h: number, scale: number): number => {
        // b1 = h/(cos(phi/2))
        return (h * scale) / Math.cos(PHI/2 * TO_RAD)
    }

    /**
     * Creates a scale value/ratio based on the level desired and the maximum levels.
     *
     * @param desiredLevel
     * @param maxLevels
     */
    static createScale = (desiredLevel: number, maxLevels: number):number => { return desiredLevel / maxLevels }

    /**
     * Creates a closed path of a congruent triangle which is rounded.  This string path is mean to be
     * used in a SVG <path> tag as the "d" attribute.
     *
     * @param scale Is fractional number between 0 and 1. 1 is the full sized triangle. To create the congruent
     *              triangles for the skill tree pass the fraction(s) of the whole so if the are ten levels and
     *              you want the 3rd skill level triangle scale = 3/10  (level/max levels)
     */
    static getPathPoints = (scale: number): string => {
        const b = SkillTriangleUtils.findB(SKILL_TRIANGLE_HEIGHT, scale)
        const topLeftXVar: number = SkillTriangleUtils.topLeftX(b)
        const topLeftYVar: number = SkillTriangleUtils.topLeftY(b)
        const topRightXVar: number = SkillTriangleUtils.topRightX(b)
        const topRightYVar: number = SkillTriangleUtils.topRightY(b)
        const vertex = "0,0"
        const path =  `M ${vertex} L${topLeftXVar},${topLeftYVar} L${topRightXVar},${topRightYVar} Z`
        const roundedPath = serialize(roundCorners(parse(path), ROUNDING))
        return roundedPath
    }

    /**
     * Creates a closed path of a congruent triangle which is rounded that can be used inside/inset another triangle.
     * This string path is mean to be used in a SVG <path> tag as the "d" attribute.
     *
     * @param scale Is fractional number between 0 and 1. 1 is the full sized triangle. To create the congruent
     *              triangles for the skill tree pass the fraction(s) of the whole so if the are ten levels and
     *              you want the 3rd skill level triangle scale = 3/10  (level/max levels)
     * @param inset This as a number in pixels to shrink the triangle in all directions.
     */
    static getPathPointsInset = (scale: number,inset:number): string => {
        const b = SkillTriangleUtils.findB(SKILL_TRIANGLE_HEIGHT, scale)
        const topLeftXVar: number = SkillTriangleUtils.topLeftX(b)+inset
        const topLeftYVar: number = SkillTriangleUtils.topLeftY(b)+inset
        const topRightXVar: number = SkillTriangleUtils.topRightX(b)-inset
        const topRightYVar: number = SkillTriangleUtils.topRightY(b)+inset
        const vertex = "0,-" + String(inset)
        //produce the path for the V so it get rounded because anything less than the
        //whole triangle, the top two corners are NOT rounded. One one vertex is created
        let path =  `M${topLeftXVar},${topLeftYVar} L${vertex} L${topRightXVar},${topRightYVar} `
        if (scale==1) {
            //fullsized pyramid? if so round the whole thing by closing the path and creating two more
            //vertices.
            path = path + "Z"
        }
        let roundedPath = serialize(roundCorners(parse(path), ROUNDING-1))
        if (roundedPath.charAt(-1)==="Z") {
            roundedPath = roundedPath + "Z"
        }
        return roundedPath
    }

    /**
     * Creates a line segment that cuts the triangle at a particular point, given by scale.
     * This string path is mean to be used in a SVG <path> tag as the "d" attribute.
     *
     * @param scale Is fractional number between 0 and 1. 1 is the full sized triangle. To create the congruent
     *              triangles for the skill tree pass the fraction(s) of the whole so if the are ten levels and
     *              you want the 3rd skill level triangle scale = 3/10  (level/max levels)
     */
    static getTriangleStriationPoints = (scale: number): string => {
        const b = SkillTriangleUtils.findB(SKILL_TRIANGLE_HEIGHT, scale)
        const topLeftXVar: number = SkillTriangleUtils.topLeftX(b)
        const topLeftYVar: number = SkillTriangleUtils.topLeftY(b)
        const topRightXVar: number = SkillTriangleUtils.topRightX(b)
        const topRightYVar: number = SkillTriangleUtils.topRightY(b)
        return `M${topLeftXVar},${topLeftYVar} L${topRightXVar},${topRightYVar}`
    }

    /**
     * Give the Y coordinant for a triangle for a scaled size.
     * This can be used to position items that based on the triangle level
     *
     * @param scale Is fractional number between 0 and 1. 1 is the full sized triangle. To create the congruent
     *              triangles for the skill tree pass the fraction(s) of the whole so if the are ten levels and
     *              you want the 3rd skill level triangle scale = 3/10  (level/max levels)
     */
    static getStriationY= (scale: number): number => {
        const b = SkillTriangleUtils.findB(SKILL_TRIANGLE_HEIGHT, scale)
        return SkillTriangleUtils.topRightY(b)
    }

    static getLevelNumbers = (arrayOfLevels: string[]): Coords[] => {

        const step = (1.0 / arrayOfLevels.length)
        const levelNumberArray = []

        let i: number = 0

        for (i; i <= arrayOfLevels.length; i++) {

            const val = step * i

            const b = SkillTriangleUtils.findB(SKILL_TRIANGLE_HEIGHT, val)
            const topLeftX = SkillTriangleUtils.topLeftX(b)
            const topLeftY = SkillTriangleUtils.topLeftY(b)

            levelNumberArray.push({txt: arrayOfLevels[i-1], x: topLeftX, y: topLeftY})
        }

        return levelNumberArray
    }

}
