/*
 * ******************************************************
 *  * Copyright (C) 2010-Present Avant Assessment
 *  * All Rights Reserved
 *  ******************************************************
 */

import ApiService from "../../../../services/ApiService"
import {SamlIdpConfigRow} from "../SamlIdpConfigTool/SamlIdpConfigTool";
import {CreateSamlIdpConfigPostBody} from "../SamlIdpConfigTool/CreateSamlIdpConfigModal";

export default class DevToolsApiService {

    static getSamlIdpConfig = (): Promise<SamlIdpConfigRow[]> => {

        const url = `${ApiService.API_URL}admin/saml-tools/idp-config`

        return ApiService.get(url).then(
            (response) => {
                return response.data
            },
            (error) => {
                console.error("Error getting SAML IDP config", error)
                return []
            }
        )
    }

    static editSamlIdpConfig = (samlIdpConfig: SamlIdpConfigRow) => {

        const url = `${ApiService.API_URL}admin/saml-tools/edit-saml-idp`
        const putBody: CreateSamlIdpConfigPostBody = {
            entityId: samlIdpConfig.entityID,
            metaDataUrl: samlIdpConfig.metadataURL,
            metaDataXml: samlIdpConfig.metaDataXml,
            active: samlIdpConfig.active,
            ssoId: samlIdpConfig.ssoId
        }
        return ApiService.put(url, putBody).then(
            (response) => {
                return response.data
            },
            (error) => {
                console.error("Error updating SAML IDP config", error)
                return []
            }
        )
    }

    static deleteSamlIdpConfig = (id: String) => {

        const url = `${ApiService.API_URL}admin/saml-tools/delete-saml-idp/${id}`
        return ApiService.delete(url).then(
            (response) => {
                return response.data
            },
            (error) => {
                console.error("Error updating SAML IDP config", error)
                return []
            }
        )
    }
}