/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import React, {ChangeEvent, useState} from "react"
import {SidebarListItem} from "./SidebarList"
import {Button, TextField} from "@material-ui/core"
import {validateEmail} from "../../../../util/validators"
import {messageStore} from "../../../common/messages/MessageStore"
import {Profile} from "../../../../types/types"
import ApiService from "../../../../services/ApiService"
import {TakeId} from "../../../../validation/ValidPrimaryKey"
import {productStore} from "../../../common/products/ProductStore"


interface ProfileEmailProps {
    testTakerEmail?: string
    takeId: number
    profile: Map<string, string>
}

export const ProfileEmail: React.FC<ProfileEmailProps> = ({testTakerEmail, takeId, profile}) => {
    const [email, setEmail] = useState<string>(testTakerEmail || "")
    const [editEmail, setEditEmail] = useState<boolean>(false)

    const saveEmail = (): void => {
        // Validate the email first
        if (email != "" && !validateEmail(email)) {
            messageStore.setErrorMessage("Please enter a valid email address.")
            return
        }

        // Get only the values needed by the backend to update the profile
        const payload: Profile = {
            testTakerId: profileCopy.get("testTakerId"),
            firstName: profileCopy.get("firstName"),
            lastName: profileCopy.get("lastName"),
            testTakerEmail: email
        }

        ApiService
            .postProfile(new TakeId(takeId!), payload, productStore.driver!.config.APP_PRODUCT_NAME.toLowerCase())
            .then(() => {
                messageStore.setInfoMessage(`Email successfully updated for test taker: ${payload.testTakerId}`)
                setEditEmail(false)
            })
    }

    const emailField = (): string | JSX.Element => (
        <>
            <TextField
                value={email}
                disabled={!editEmail}
                onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setEmail(e.target.value)}
            />
            {editEmail ?
                <Button
                    variant={"contained"}
                    color={"primary"}
                    size={"small"}
                    // Disable the submit button if the email hasn't been changed
                    disabled={email == testTakerEmail}
                    onClick={() => saveEmail()}

                >
                    Update
                </Button> :
                <Button
                    variant={"contained"}
                    color={"primary"}
                    size={"small"}
                    onClick={() => setEditEmail(true)}
                >
                    Edit
                </Button>
            }
        </>
    )

    // profile is Map but none of the Map functions are working, so I'm making a new Map here
    const profileCopy: Map<string, string> = new Map<string, string>()

    Object.entries(profile).forEach(([key, value]) => {
        profileCopy.set(key, value)
    })

    if (profileCopy.get("completed") === "false") {
        return <></>
    }

    return (
        <SidebarListItem
            primary={"Email:"}
            secondary={emailField()}
        />
    )
}
