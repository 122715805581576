import {P360ScheduleStudentPayload} from "../types/types";
import ApiService from "./ApiService";
import {AxiosError, AxiosResponse} from "axios";

export interface ScheduleProctorTimeSlotError {
    responseData: any
    loginUUID: string
    requestedTimeslot: P360ScheduleStudentPayload
}

export default class P360ApiService {

    static scheduleProctorTimeslot = (loginUUID: string, requestedTimeslot: P360ScheduleStudentPayload): Promise<AxiosResponse> => {
        const url: string = `${ApiService.API_URL}p360/schedule-student/${loginUUID}`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.post(url, requestedTimeslot).then(
                    (res) => {
                        resolve(res)
                    },
                    (error: AxiosError) => {
                        const errorResponse: ScheduleProctorTimeSlotError = {responseData: error.response!!.data, loginUUID, requestedTimeslot}
                        reject(errorResponse)
                    }
                )
            )
        })
    }

}