import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core"
import {styled} from "@material-ui/core/styles"
import {observer} from "mobx-react"
import React from "react"
import {AvantColors} from "../../../../../styles/AvantColors"
import {SmallBodyText} from "../../../../../styles/AvantTypography"
import {TimeUtil} from "../../../../../util/TimeUtil"
import {AvantCircularProgress} from "../../../../common/loaders/AvantCircularProgress"
import {takeSearchStore} from "../../../../common/take/TakeSearchStore"
import {ReportFilters} from "../../../common/models/ReportFilters"
import {reportsStore} from "../../../common/stores/ReportStore"
import {ReportSubPaths} from "../../../ReportSubPaths"
import {ClassInfoRow} from "../../models/ClassInfoRow"
import {DistrictOrSchoolTableCellLink, SchoolTableHeaderCell} from "./DistrictOrSchoolTableCommon"
import {schoolTableStore} from "./DistrictOrSchoolTableStore"
import LanguageUtils from "../../../../../util/LanguageUtils"

interface SchoolTableContainerProps {
    reportData?: ClassInfoRow[]
    filters: ReportFilters
    printing?: boolean
}

@observer
export class SchoolTableContainer extends React.Component<SchoolTableContainerProps> {
    render() {
        const {reportData, filters, printing} = this.props
        if (reportData == null) {
            return <AvantCircularProgress/>
        }
        return <SchoolTable reportData={reportData} filters={filters} printing={printing}/>
    }
}

interface SchoolTableProps {
    reportData: ClassInfoRow[]
    filters: ReportFilters
    printing?: boolean
}

// Depends on instances already being fully loaded
@observer
class SchoolTable extends React.Component<SchoolTableProps> {

    componentDidMount(): void {
        schoolTableStore.rows = this.props.reportData
        schoolTableStore.rows.forEach(row => row.dataAvailable = takeSearchStore.groupIdsWithData.has(row.id))
        schoolTableStore.resort()
    }

    render() {
        const {filters, printing} = this.props
        const groupsWithData = reportsStore.groupsWithData
        return (
            <Table size={"small"}>
                <TableHead>
                    <SchoolTableRow>
                        <SchoolTableHeaderCell title={"Group Name"} property={"name"}/>
                        <SchoolTableHeaderCell title={"Language"} property={"language"}/>
                        <SchoolTableHeaderCell title={"Last Testing Date"} property={"lastTestingDate"}/>
                        <SchoolTableHeaderCell title={"Data Available in Date Range"} property={"dataAvailable"}/>
                    </SchoolTableRow>
                </TableHead>
                <SchoolTableBody>
                    {schoolTableStore.rows.map((row: ClassInfoRow) => {
                        let hasData = false
                        let hasDataYesNo
                        if (groupsWithData != null) {
                            hasData = !!groupsWithData.includes(row.id)
                            if (hasData) {
                                hasDataYesNo = "Yes"
                            } else {
                                hasDataYesNo = "No"
                            }
                        }

                        const language = LanguageUtils.removeTilde(row.language)

                        return (
                            <SchoolTableRow key={row.id}>
                                <DistrictOrSchoolTableCellLink
                                    contents={row.name}
                                    link={ReportSubPaths.class({...filters, groupId: row.id})}
                                    enabled={hasData}
                                    school={true}
                                    printing={printing}
                                />
                                <SchoolSecondaryCell enabled={hasData}>{language}</SchoolSecondaryCell>
                                <SchoolSecondaryCell enabled={hasData}>{TimeUtil.formatMillisToUsDate(row.lastTestingDate)}</SchoolSecondaryCell>
                                <SchoolSecondaryCell enabled={hasData}>{hasDataYesNo}</SchoolSecondaryCell>
                            </SchoolTableRow>
                        )
                    })}
                </SchoolTableBody>
            </Table>
        )
    }
}

const SchoolTableBody = styled(TableBody)({
    "& tr:nth-child(odd)": {
        backgroundColor: AvantColors.REPORT_BACKGROUND_GRAY_LIGHT
    }
})
const SchoolTableRow = styled(TableRow)({
    display: "grid",
    gridTemplateColumns: "2fr 1fr 1fr 2fr",
    "& td": {
        height: "35px",
        display: "flex",
        alignItems: "center",
    }
})

interface SchoolSecondaryCellProps {
    enabled: boolean
}

const SchoolSecondaryCell: React.FC<SchoolSecondaryCellProps> = ({children, enabled}) => (
    <TableCell
        style={{
            border: "none",
            borderLeft: `1px solid ${AvantColors.REPORT_BACKGROUND_GRAY_DARK}`,
        }}
    >
        <SmallBodyText
            style={{
                color: AvantColors.REPORT_FONT_COLOR_GRAY_3,
                opacity: enabled ? 1 : 0.75
            }}
        >
            {children}
        </SmallBodyText>
    </TableCell>
)
