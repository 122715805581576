import {ApiUrlInfo, UrlInfo} from "../types/types"
import {APP_LWR_CASE} from "./Constants"
import {to} from "./elvis"

export type UrlSubDomain = string

export function getLocalHostProductInfo() {
    return {env: "local", domain: "localhost", protocol: "http", topLevel: ""}
}

export function getUrlInfo(url: URL): UrlInfo {
    if (url.href.includes("local")) {
        return getLocalHostProductInfo()
    }
    const pieces = url.hostname.split(".")
    const protocol = url.protocol
    const topLevel = to<string>(pieces.pop(), new Error(`Expected URL ${url} to have top level domain`))
    const domain = to<string>(pieces.pop(), new Error(`Expected URL ${url} to have domain`))
    const subdomain = getSubdomainFromUrl(url)
    const env = subdomain.replace(APP_LWR_CASE, "")
    return {env, protocol, domain, topLevel}
}

export function getSubdomainFromUrl(url: URL): UrlSubDomain {
    const pieces = url.hostname.split(".")
    pieces.pop() // Remove .com
    pieces.pop() // remove .avantassessment
    return pieces.join(".") // rejoin with . since we might have nested subdomains
}

export function deriveApiUrl(): ApiUrlInfo {
    const url = new URL(window.location.href)
    const info = getUrlInfo(url)

    let apiUrl =
        info.env.includes("local") || info.env.includes("192.168")
            ? new URL(`http://${url.hostname}:8080`)
            : new URL(`${info.protocol}//api${info.env}.${info.domain}.${info.topLevel}`)

    if (apiUrl.host.includes("ngrok")) {
        // apiUrl = new URL("https://avantassessment-developers-blue-api.ngrok.dev/")
        apiUrl = new URL("http://localhost:8080/")
    }
    return {apiUrl, info}
}
