/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {Divider} from "@material-ui/core"
import {SidebarList, SidebarListItem} from "./SidebarList"
import React from "react"
import {TakeSearchResult} from "../../../../common/take/models/TakeSearchResult"
import {
    APT_PRODUCT_ID,
    PLACE_PRODUCT_ID,
    STAMP_4S_PRODUCT_ID,
    STAMP_4SE_PRODUCT_ID,
    STAMP_CEFR_PRODUCT_ID,
    STAMP_SIGN_LANGUAGE_PRODUCT_ID
} from "../../../../../util/Constants"
import {TimeUtil} from "../../../../../util/TimeUtil"
import PlaceReportUtils, {PLACEIndividualReport} from "../../../PlaceFamily/utils/PlaceReportUtils"
import PlaceProfileConstants from "../../../../../components/place/PlaceProfileConstants";
import {ProfileEmail} from "./ProfileEmail"

interface ProfileProps {
    takeResult: TakeSearchResult
}

interface PlaceProfileProps {
    takeResult: TakeSearchResult
    placeProfile?: PLACEIndividualReport
}

const Profile4S: React.FC<ProfileProps> = (props) => {
    const {
        code, externalId, language, grade, firstLanguage, familyMembersOften, familyLanguage,
        yearsStudyingOrSpoken, monthsAbroad, testTakerEmail, takeId, profile
    } = props.takeResult

    return (
        <SidebarList>
            <Divider component={"hr"}/>
            <SidebarListItem primary={"Login Name:"} secondary={code}/>
            <SidebarListItem primary={"Test Taker ID:"} secondary={externalId}/>
            <SidebarListItem primary={"Test Language:"} secondary={language}/>
            <SidebarListItem primary={"Current Grade:"} secondary={grade}/>
            <SidebarListItem primary={"Native Language:"} secondary={firstLanguage}/>
            <SidebarListItem primary={"Speaks with Family:"} secondary={familyMembersOften}/>
            <SidebarListItem primary={"Language at Home:"} secondary={familyLanguage}/>
            <SidebarListItem primary={"Years Speaking:"} secondary={yearsStudyingOrSpoken}/>
            <SidebarListItem primary={"Lived Abroad:"} secondary={monthsAbroad ? `${monthsAbroad} months` : ""}/>
            <ProfileEmail testTakerEmail={testTakerEmail} takeId={takeId} profile={profile}/>
        </SidebarList>
    )
}

const Profile4Se: React.FC<ProfileProps> = (props) => {
    const {
        code, externalId, language, grade, profileCompleted
    } = props.takeResult


    return (
        <SidebarList>
            <Divider component={"hr"}/>
            <SidebarListItem primary={"Login Name:"} secondary={code}/>
            <SidebarListItem primary={"Test Taker ID:"} secondary={externalId}/>
            <SidebarListItem primary={"Test Language:"} secondary={language}/>
            <SidebarListItem primary={"Current Grade:"} secondary={grade}/>
            <SidebarListItem primary={"Profile Completed Date:"} secondary={
                TimeUtil.formatMilliToMonthDayYearLong(Number(profileCompleted))
            }/>
        </SidebarList>
    )
}

const ProfileAPT: React.FC<ProfileProps> = (props) => {
    const { code, testTakerEmail, takeId, profile } = props.takeResult

    return (
        <SidebarList>
            <Divider component={"hr"}/>
            <SidebarListItem primary={"Login Name:"} secondary={code}/>
            <ProfileEmail testTakerEmail={testTakerEmail} takeId={takeId} profile={profile} />
        </SidebarList>
    )
}

const ProfilePLACE: React.FC<PlaceProfileProps> = (props) => {
    if (props.placeProfile == null) {
        return <div/>
    }

    const {familyLanguageId, language, login, familyMembers, tests} = props.placeProfile
    const {externalId, gradesStudied, testTakerEmail, yearsOfStudy} = props.takeResult

    const famSpeakNonEng = (): string => {
        if(familyLanguageId == null || familyLanguageId < 1) {
            return "No"
        }
        return "Yes"
    }

    return (
        <SidebarList>
            <Divider component={"hr"}/>
            <SidebarListItem primary={"Login Name:"} secondary={login}/>
            <SidebarListItem primary={"Test Taker ID:"} secondary={externalId}/>
            <SidebarListItem primary={"Email:"} secondary={testTakerEmail}/>
            <SidebarListItem primary={"Test Language:"} secondary={language}/>
            <SidebarListItem primary={"Years Studied:"} secondary={yearsOfStudy}/>
            <SidebarListItem primary={"Grades of Study:"} secondary={gradesStudied === '' ? 'None' : gradesStudied}/>
            <SidebarListItem primary={"Tests Taken:"} secondary={PlaceReportUtils.formattedTests(tests)}/>
            <SidebarListItem primary={"Family speaks Non-English at home:"} secondary={famSpeakNonEng()}/>
            <SidebarListItem primary={"Language at Home:"} secondary={PlaceProfileConstants.getLanguageNameById(Number(familyLanguageId))}/>
            <SidebarListItem primary={"Family Members:"} secondary={familyMembers.join(", ").replace(/,(\s*)$/, "").replace("Other, ", "")}/>
        </SidebarList>
    )
}

const ProfileGeneric: React.FC<ProfileProps> = (props) => {
    const {
        code, externalId, language
    } = props.takeResult

    return (
        <SidebarList>
            <Divider component={"hr"}/>
            <SidebarListItem primary={"Login Name:"} secondary={code}/>
            <SidebarListItem primary={"Test Taker ID:"} secondary={externalId}/>
            <SidebarListItem primary={"Test Language:"} secondary={language}/>
        </SidebarList>
    )
}

function selectSidebar(info: ProfileInfoProps) {
    switch (info.profileInfo.productId) {
        case STAMP_4SE_PRODUCT_ID.value():
            return (<Profile4Se takeResult={info.profileInfo}/>)
        case STAMP_4S_PRODUCT_ID.value():
            return <Profile4S takeResult={info.profileInfo}/>
        case STAMP_CEFR_PRODUCT_ID.value():
            return <Profile4S takeResult={info.profileInfo}/>
        case STAMP_SIGN_LANGUAGE_PRODUCT_ID.value():
            return <Profile4S takeResult={info.profileInfo}/>    
        case APT_PRODUCT_ID.value():
            return <ProfileAPT takeResult={info.profileInfo}/>
        case PLACE_PRODUCT_ID.value():
            return <ProfilePLACE takeResult={info.profileInfo} placeProfile={info.placeProfileInfo}/>
        default:
            return <ProfileGeneric takeResult={info.profileInfo}/>
    }
}

interface ProfileInfoProps {
    profileInfo: TakeSearchResult
    placeProfileInfo?: PLACEIndividualReport
}

const ProfileInfo: React.FC<ProfileInfoProps> = (props) => {
    const selectedProfile = selectSidebar(props)
    return (<>{selectedProfile}</>)
}
export default ProfileInfo
