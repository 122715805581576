import React from "react"
import MessageDropDown from "./MessageDropDown"

export default class NoMicErrorMessage extends React.Component<{}, {}> {
    render() {
        return (
            <div className="GetHelpMessage">
                <MessageDropDown
                    title={"Microphone Error"}
                    message={
                        "It appears that we have lost access to your microphone. " +
                        "Please alert your test proctor of the issue."
                    }
                    inputDevices={[]}
                />
            </div>
        )
    }
}
