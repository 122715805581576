import * as React from "react"
import {ReactElement, useState} from "react"
import {RouteComponentProps} from "react-router-dom"
import {H3Text} from "../../styles/AvantTypography"
import {SUPPORT_EMAIL} from "../../util/Constants"
import {AvantLogo} from "../common/AvantLogo"
import MultipleTakes from "./MultipleTakes"
import ProcessingTake from "./ProcessingTake"

import {Grid, Paper} from "@material-ui/core"
import {VerificientAvailableLogins} from "../../services/ProctorApi"


export interface VerificientLogin {
    username: string,
    password: string,
    loginId?: number,
    takeId?: number | null;
}
interface Take {
    takeId: number | null;
    username: string,
    contentAreaId: number,
}

export interface LocationState {
    loginData: VerificientLogin;
    takes: Take[];
}
interface RosterSelectTakeProps extends RouteComponentProps<{}, any, LocationState> {}
function convertTakesToLogins(takes: Take[]): VerificientAvailableLogins[] {
    return takes.map(take => {
        return {
            username: take.username,
            contentAreaId:take.contentAreaId,
            takeId: take.takeId ? take.takeId : 0, 
        };
    });
}

export const RosterSelectTake: React.FC<RosterSelectTakeProps> = (props) => {
    const loginData: VerificientLogin = {
        username : props.location.state.loginData.username,
        password : props.location.state.loginData.password,
        takeId:  props.location.state.takes[0] ? props.location.state.takes[0].takeId : null
    }

    const handleRedirect = (index: number): void => {
        loginData.takeId = props.location.state.takes[index].takeId
        const take: VerificientAvailableLogins = {
            username: props.location.state.takes[index].username,
            contentAreaId: props.location.state.takes[index].contentAreaId,
            takeId: (props.location.state.takes[index] && props.location.state.takes[index].takeId) ? props.location.state.takes[index].takeId : 0
        }
        setComponent(<ProcessingTake loginData={loginData} take={take}/>)
    }

    const getDefaultComponent = (): ReactElement => {
        let comp = <NoTakes/>
        if (props.location.state.takes.length === 1) {
            comp = <ProcessingTake loginData={loginData} take={props.location.state.takes[0]}/>
        } else if (props.location.state.takes.length >= 1) {
            comp = <MultipleTakes takes={convertTakesToLogins(props.location.state.takes)} handleSelectedTake={handleRedirect}/>
}
        return comp
    }
    const [component, setComponent] = useState<ReactElement>(getDefaultComponent())

    return (
        <div style={{marginTop: 8}}>
            <AvantLogo title={"Remote Proctoring"}/>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Paper style={{padding: "15px", textAlign: "center"}}>{component}</Paper>
            </div>
        </div>
    )
}

const NoTakes: React.FC = () => {
    return (
        <Grid container={true}>
            <Grid item={true} xs={12}>
                <H3Text>We did not find any active tests for you to take.</H3Text>
            </Grid>
            <Grid item={true} xs={12}>
                <p style={{marginTop: "20px"}}>If you feel that there should be active tests please contact us at
                    &nbsp;<a href={SUPPORT_EMAIL}>{`${SUPPORT_EMAIL}`}</a>
                </p>
            </Grid>
        </Grid>
    )
}
