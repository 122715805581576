/*
 * ******************************************************
 *  * Copyright (C) 2010-Present Avant Assessment
 *  * All Rights Reserved
 *  ******************************************************
 */

import React, {useEffect} from "react"
import {P360Scheduling} from "../../../../types/types"
import DevToolsApi from "../../../../services/DevToolsApi"
import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material"

export const BulkP360LoginScheduleTool: React.FC = () => {
    const [schedules, setSchedules] = React.useState<P360Scheduling[]>([])
    const [selectedScheduleIds, setSelectedScheduleIds] = React.useState<string[]>([])

    useEffect(() => {
        DevToolsApi.getP360ScheduleList().then((schedules: P360Scheduling[]) => {
            const sortedSchedules = schedules.sort((scheduleA: P360Scheduling, scheduleB: P360Scheduling) => {

                const scheduleALowerCase = scheduleA.title.toLowerCase()
                const scheduleBLowerCase = scheduleB.title.toLowerCase()
                if (scheduleALowerCase > scheduleBLowerCase) {
                    return 1
                }
                if (scheduleALowerCase < scheduleBLowerCase) {
                    return -1
                } else {
                    return 0
                }
            })
            setSchedules(sortedSchedules)
        })
    }, [])

    const handleSelectionChange = (event: SelectChangeEvent<typeof selectedScheduleIds>) => {
        // const {target: {value }} = event

        const selectedValue = event.target.value as string[]
        setSelectedScheduleIds(selectedValue)
    }

    return (
        <FormControl sx={{m: 1, minWidth: 120}} fullWidth={true} id={"BulkP360ScheduleSelectTool"}>
            <InputLabel id="bulk-select-p360-schedule">Select P360 Schedules</InputLabel>
            <Select
                labelId="bulk-select-p360-schedule"
                id="bulk-select-p360-schedule-select"
                label="Select P360 Schedules"
                multiple={true}
                value={selectedScheduleIds}
                onChange={handleSelectionChange}
                renderValue={(selected) => {
                    const selectedScheduleTitles = schedules.filter((schedule: P360Scheduling) => {
                        return selectedScheduleIds.includes(schedule.p360SchedulingId)
                    })

                    return selectedScheduleTitles.map((schedule: P360Scheduling) => {
                        return schedule.title
                    }).join(",")

                }}
            >
                {schedules.map((schedule: P360Scheduling, index: number) => {
                    return (
                        <MenuItem
                            key={`PermissionSelection-${schedule.p360SchedulingId}-${index}`}
                            value={schedule.p360SchedulingId}
                        >
                            <Checkbox id={`PermissionSelection-${schedule.p360SchedulingId}-${index}`} checked={selectedScheduleIds.includes(schedule.p360SchedulingId)} />
                            <ListItemText primary={schedule.title} />
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}