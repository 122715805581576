import {AxiosResponse} from "axios"
import {observable} from "mobx"
import ApiService from "../../../services/ApiService"
import {log, prettyJson} from "../../../util/Logging"
import {GroupId} from "../../../validation/ValidPrimaryKey"
import {GroupInfo} from "./models/GroupInfo"
import {GroupMaster, ManageGroupsGroupMasterCronDTO} from "./models/GroupMaster"

class GroupMasterStore {

    @observable
    defaultGroup?: GroupMaster = undefined

    @observable
    currentSubGroups?: GroupMaster[] = undefined

    @observable
    groupInfo?: GroupInfo = undefined

    findGroupInfoWithTakeId = (takeId: number) => {
        ApiService.get(`${this.url()}/info/take/${takeId}`).then(
            (response: AxiosResponse<GroupInfo>) => {
                log.debug(`Found group info with take id ${takeId} info: ${prettyJson(response.data)}`)
                this.groupInfo = response.data
            }
        )
    }


    findGroupInfo = (id: number) => {
        ApiService.get(`${this.url()}/${id}/info`).then(
            (response: AxiosResponse<GroupInfo>) => {
                // log.debug(`findGroup`)
                // log.debug(`Found group info with group id ${id} info: ${prettyJson(response.data)}`)
                this.groupInfo = response.data
            }
        )
    }

    findDistrictReportGroupInfo = (id: number) => {
        ApiService.get(`${this.url()}/${id}/districtinfo`).then(
            (response: AxiosResponse<GroupInfo>) => {
                this.groupInfo = response.data
            }
        )
    }

    selectGroup = (id: number) => {
        ApiService.get(`${this.url()}/${id}/select`).then(
            (response: AxiosResponse<GroupMaster>) => {
                // log.debug(`selectGroup`)
                // log.debug(`Found group info with group id ${id} info: ${prettyJson(response.data)}`)
                this.defaultGroup = response.data
            }
        )
    }

    findDefaultGroup = () => {
        ApiService.get(`${this.url()}`).then(
            (response: AxiosResponse<GroupMaster>) => {
                // log.debug(`findDefaultGroup`)
                this.defaultGroup = response.data
                // log.debug(`Default group set to ${prettyJson(response.data)}`)
            }
        )
    }

    findDefaultSAMLGroup = (productId: number) => {
        ApiService.get(`${this.url()}/saml-groups/${productId}`).then(
            (response: AxiosResponse<GroupMaster>) => {
                // log.debug(`findDefaultGroup`)
                this.defaultGroup = response.data
                // log.debug(`Default group set to ${prettyJson(response.data)}`)
            }
        )
    }

    getGroupById = (groupId: GroupId): Promise<GroupMaster> => {
        return new Promise((resolve, reject) => {
            ApiService.get(`${this.url()}/${groupId}/select`).then(
                (response: AxiosResponse) => {
                    this.defaultGroup = response.data
                    resolve(response.data)
                },
                (err) => reject(err))
        })
    }

    // TODO: Make this not return a promise, the CST TakeSearch.tsx file would need to be refactored pretty
    // dramatically to make that work, but we should do that soon.
    getSubGroupsForGroup = (parentId: GroupId | null): Promise<GroupMaster[]> => {
        let queryUrl: string
        if (parentId === null) {
            queryUrl = `${this.url()}/root/subgroups`
        }
        else {
            queryUrl = `${this.url()}/${parentId}/subgroups`
        }
        return new Promise((resolve, reject) => {
            ApiService.get(queryUrl).then(
                (response: AxiosResponse) => {
                    this.currentSubGroups = response.data
                    resolve(response.data)
                },
                (err) => reject(err))
        })
    }

    getSubGroupsForGroupCron = (parentId: GroupId | null): Promise<ManageGroupsGroupMasterCronDTO[]> => {
        let queryUrl: string
        if (parentId === null) {
            queryUrl = `${this.url()}/root/subgroupscron`
        }
        else {
            queryUrl = `${this.url()}/${parentId}/subgroupscron`
        }
        return new Promise((resolve, reject) => {
            ApiService.get(queryUrl).then(
                (response: AxiosResponse) => {
                    this.currentSubGroups = response.data
                    resolve(response.data)
                },
                (err) => reject(err))
        })
    }

    createGroup = (name: string, parentGroupId: number | null, groupType: string): Promise<GroupMaster> => {
        let queryUrl: string
        if (parentGroupId === null) {
            queryUrl = `${this.url()}/creategroup/root/${groupType}/${name}`
        }
        else {
            queryUrl = `${this.url()}/creategroup/${parentGroupId}/${groupType}/${name}`
        }
        return new Promise((resolve, reject) => {
            ApiService.get(queryUrl).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (err) => reject(err))
        })
    }

    renameGroup = (name: string, groupId: number): Promise<GroupMaster> => {
        let queryUrl: string = `${this.url()}/renamegroup/${groupId}/${name}`
        return new Promise((resolve, reject) => {
            ApiService.get(queryUrl).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (err) => reject(err))
        })
    }

    mergeGroups = (receiverGroupId: number, donorGroupId: number): Promise<number> => {
        let queryUrl: string = `${this.url()}/mergegroups/${receiverGroupId}/${donorGroupId}`
        return new Promise((resolve, reject) => {
            ApiService.get(queryUrl).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (err) => reject(err))
        })
    }

    private url = () => `${ApiService.API_URL}groupmasters`
}

export const groupMasterStore = new GroupMasterStore()
