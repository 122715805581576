/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import React, {ReactInstance, useRef, useState} from "react"
import {EmptyComponent} from "../../generic-components/EmptyComponent"
import {takeSearchStore} from "../../common/take/TakeSearchStore"
import {reportsStore} from "../../reports/common/stores/ReportStore"
import {RedirectToReport} from "../../reports/common/components/FindReportIdOrRedirect"
import {productStore} from "../../common/products/ProductStore"
import {groupMasterStore} from "../../common/group/GroupMasterStore"
import {AvantCircularProgress, CircularProgressSize} from "../../common/loaders/AvantCircularProgress"
import {PrintButtonsContainer, PrintViewButton} from "../../reports/common/components/PrintViewButton"
import ReactToPrint from "react-to-print"
import {Box} from "@material-ui/core"
import {theme} from "../../../styles/MuiThemes"
import {Brand} from "../../../components/Brand/Brand"
import {getReportStructure} from "../ReportStructures"
import {getReportTypeFromURL, Report} from "../Report"
import {findFilters, ReportRouteProps} from "../../reports/common/components/ReportsPage"
import {PrintOptions} from "../../reports/common/components/PrintViewContainer"
import {UserType} from "../../common/authentication/models/UserType"
import {BlueReportType} from "../../reports/common/models/BlueReportType"

const PrintOptionsMap: Map<string, PrintOptions> = new Map([
    ["print-all", PrintOptions.ALL],
    ["print-list", PrintOptions.LIST],
    ["print-summary", PrintOptions.SUMMARY],
])

const ReportTypeMap: Map<UserType, BlueReportType> = new Map([
    [UserType.D, BlueReportType.DISTRICT],
    [UserType.SC, BlueReportType.SCHOOL],
    [UserType.T, BlueReportType.CLASS],
])

export function extractPrintOption(): PrintOptions | undefined {
    const urlArray = window.location.pathname.split("/")
    const option = urlArray.find((v: string) => v.includes("print"))

    if (option) {
        return PrintOptionsMap.get(option)
    }

    return undefined
}

export function getCurrentUserType(): UserType | undefined {
    const product = productStore.loginProduct
    const userType = product ? product.userType : ""

    if (userType) {
        return UserType[userType]
    }

    return undefined
}

export function getReportTypeFromCurrentUser(): BlueReportType | undefined {
    const userType = getCurrentUserType()

    if (userType) {
        return ReportTypeMap.get(userType)
    }

    return undefined
}

const PrintViewContainer = (props: ReportRouteProps) => {
    const filters = findFilters(props)
    const printOptions = extractPrintOption()
    const reportType = getReportTypeFromURL()

    const ele: ReactInstance = new EmptyComponent({})
    const componentRef = useRef<HTMLDivElement | null>(null)
    const [cancel, setCancel] = useState<boolean>(false)

    if (takeSearchStore.instances.length === 0 && reportsStore.reportGraphData == null) {
        return <RedirectToReport />
    }

    if (cancel) {
        return <RedirectToReport reportFilters={filters} blueReportType={BlueReportType[reportType!]} />
    }

    const product = productStore.loginProduct
    const productName = product ? product.productName : ""

    const groupInfo = groupMasterStore.groupInfo
    if (groupInfo == null) {
        return <AvantCircularProgress size={CircularProgressSize.SMALL} />
    }

    const reportStructure = getReportStructure(filters.productId, reportType)

    if (!reportStructure) {
        return <></>
    }

    return (
        <>
            <PrintButtonsContainer>
                <PrintViewButton onClick={() => setCancel(true)}>Cancel</PrintViewButton>
                <ReactToPrint
                    trigger={() => <PrintViewButton>Print</PrintViewButton>}
                    content={() => componentRef.current ? componentRef.current : ele}
                    pageStyle={""}
                />
            </PrintButtonsContainer>

            <div ref={componentRef}>
                <Box position={"absolute"} top={theme.spacing(2)} right={theme.spacing(2)}>
                    <Brand appName={productName} />
                </Box>

                <Report reportStructure={reportStructure} filters={filters} printOptions={printOptions} reloadReport={() => {}} />
            </div>
        </>
    )
}

export default PrintViewContainer
