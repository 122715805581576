import {TimeUtil} from "../../util/TimeUtil"
import {reportsAssessmentStartValue, reportsRecentActivityValue} from "./common/components/FilterAndPrintOptions"
import {ReportFilters} from "./common/models/ReportFilters"
import {PlaceReportGraphFilters} from "./common/components/AvantNewDatePicker/AvantNewDateRangePickerHelpers"
import moment from "moment"

export class ReportSubPaths {
    static readonly REPORTS_BASE = "/reports"
    static readonly reports = (productId?: number) => `${ReportSubPaths.REPORTS_BASE}/products/${productId == null ? ":productId" : productId}`
    static readonly districts = (productId?: number) => `${ReportSubPaths.reports(productId)}/districts`
    static readonly schools = (productId?: number) => `${ReportSubPaths.reports(productId)}/schools`
    static readonly classes = (productId?: number) => `${ReportSubPaths.reports(productId)}/classes`
    static readonly individuals = (productId?: number) => `${ReportSubPaths.reports(productId)}/individuals`
    static readonly REPORTS_REDIRECT = `${ReportSubPaths.REPORTS_BASE}/redirect`

    static readonly district = (filters?: ReportFilters) =>
        `${ReportSubPaths.districts(filters ? filters.productId : undefined)}/${filters ? filters.groupId : ":groupId"}${makeParamsForStartAndEnd(filters)}`

    static readonly districtPrintAll = (filters?: ReportFilters) =>
        `${ReportSubPaths.districts(filters ? filters.productId : undefined)}/print-all/${filters ? filters.groupId : ":groupId"}${makeParamsForStartAndEnd(filters)}`

    static readonly districtPrintSummary = (filters?: ReportFilters) =>
        `${ReportSubPaths.districts(filters ? filters.productId : undefined)}/print-summary/${filters ? filters.groupId : ":groupId"}${makeParamsForStartAndEnd(filters)}`

    static readonly districtPrintLocations = (filters?: ReportFilters) =>
        `${ReportSubPaths.districts(filters ? filters.productId : undefined)}/print-locations/${filters ? filters.groupId : ":groupId"}${makeParamsForStartAndEnd(filters)}`


    static readonly school = (filters?: ReportFilters) =>
        `${ReportSubPaths.schools(filters ? filters.productId : undefined)}/${filters ? filters.groupId : ":groupId"}${makeParamsForStartAndEnd(filters)}`

    static readonly schoolPrintAll = (filters?: ReportFilters) =>
        `${ReportSubPaths.schools(filters ? filters.productId : undefined)}/print-all/${filters ? filters.groupId : ":groupId"}${makeParamsForStartAndEnd(filters)}`

    static readonly schoolPrintSummary = (filters?: ReportFilters) =>
        `${ReportSubPaths.schools(filters ? filters.productId : undefined)}/print-summary/${filters ? filters.groupId : ":groupId"}${makeParamsForStartAndEnd(filters)}`

    static readonly schoolPrintList = (filters?: ReportFilters) =>
        `${ReportSubPaths.schools(filters ? filters.productId : undefined)}/print-groups/${filters ? filters.groupId : ":groupId"}${makeParamsForStartAndEnd(filters)}`


    static readonly class = (filters?: ReportFilters) =>
        `${ReportSubPaths.classes(filters ? filters.productId : undefined)}/${filters ? filters.groupId : ":groupId"}${makeParamsForStartAndEnd(filters)}`

    static readonly classPrintSummary = (filters?: ReportFilters) =>
        `${ReportSubPaths.classes(filters ? filters.productId : undefined)}/print-summary/${filters ? filters.groupId : ":groupId"}${makeParamsForStartAndEnd(filters)}`

    static readonly classPrintList = (filters?: ReportFilters | PlaceReportGraphFilters) =>
        `${ReportSubPaths.classes(filters ? filters.productId : undefined)}/print-list/${filters ? filters.groupId : ":groupId"}${makeParamsForStartAndEnd(filters)}`

    static readonly classPrintAll = (filters?: ReportFilters) =>
        `${ReportSubPaths.classes(filters ? filters.productId : undefined)}/print-all/${filters ? filters.groupId : ":groupId"}${makeParamsForStartAndEnd(filters)}`


    static readonly individual = (takeId?: number, filters?: ReportFilters) =>
        `${ReportSubPaths.individuals(filters ? filters.productId : undefined)}/${takeId ? takeId : ":takeId"}`

    static readonly individualPrintAll = (productId?: number) =>
        `${ReportSubPaths.individuals(productId)}/print-all`

    static readonly individualPrintSelected = (productId?: number) =>
        `${ReportSubPaths.individuals(productId)}/print-selected`


    static readonly promptsPrintAll = (productId?: number) =>
        `${ReportSubPaths.individuals(productId)}/print-all-prompts`

    static readonly promptsPrintSelected = (productId?: number) =>
        `${ReportSubPaths.individuals(productId)}/print-selected-prompts`

}

export const makeParamsForStartAndEnd = (filters?: ReportFilters | PlaceReportGraphFilters) => {
    const startDate = filters ? moment(filters.startDate) : moment()
    const endDate = filters ? moment(filters.endDate) : moment()

    if (filters != null && startDate != null && endDate != null) {
        return `?startDate=${TimeUtil.formatToISODate(startDate)}&endDate=${TimeUtil.formatToISODate(endDate)}` +
            `&${reportsAssessmentStartValue}=${filters.assessmentStart}&${reportsRecentActivityValue}=${filters.recentActivity}`

    }
    return ""
}
