import React from "react"
import Button from "../../Button/Button"

interface IComponentProps {
    onCancel: (event: any) => void
    onConfirm: (event: any) => void
}

export default class DeleteConfirmationOverlay extends React.Component<IComponentProps, {}> {

    render() {
        return (
            <div className={"DeleteConfirmationOverlay"}>
                {/*<div className={"DeleteConfirmationOverlay__background"} />*/}
                <div className={"DeleteConfirmationOverlay__text"}>
                    <div className={"DeleteConfirmationOverlay__text__header"}>Delete Recording?</div>
                    <div className={"DeleteConfirmationOverlay__text__message"}>This will replace the recording you just</div>
                    <div className={"DeleteConfirmationOverlay__text__messagebox"}>made. Do you want to record again? </div>
                </div>
                <div className={"DeleteConfirmationOverlay__text__box"}>
                    <Button className={"DeleteConfirmationOverlay__cancel"} onClick={this.props.onCancel}>
                        Cancel
                    </Button>
                    <Button className={"DeleteConfirmationOverlay__confirm"} onClick={this.props.onConfirm}>
                        Yes, Record Again
                    </Button>
                </div>
            </div>
        )
    }
}
