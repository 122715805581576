/**
 * This from the example file to be able to change the UI on the Video Recorder
 * https://github.com/fbaiodias/react-video-recorder/tree/main/src/defaults
 */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from './button'
import RecordButton from './record-button'
import StopButton from './stop-button'
import Timer from './timer'
import Countdown from './countdown'
import VideoRecordingButton from './VideoRecordingButton'

const ActionsWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5%;
  display: flex;
  align-items: center;
  justify-content: center;

  
`
let recordCount = 1
let flag = false

const Actions = ({
                     t,
                     isVideoInputSupported,
                     isInlineRecordingSupported,
                     thereWasAnError,
                     isRecording,
                     isCameraOn,
                     streamIsReady,
                     isConnecting,
                     isRunningCountdown,
                     isReplayingVideo,
                     countdownTime,
                     timeLimit,
                     showReplayControls,
                     replayVideoAutoplayAndLoopOff,
                     useVideoInput,

                     onTurnOnCamera,
                     onTurnOffCamera,
                     onOpenVideoInput,
                     onStartRecording,
                     onStopRecording,
                     onPauseRecording,
                     onResumeRecording,
                     onStopReplaying,
                     onConfirm
                 }) => {
    const renderContent = () => {
        const shouldUseVideoInput =
            !isInlineRecordingSupported && isVideoInputSupported

        if (
            (!isInlineRecordingSupported && !isVideoInputSupported) ||
            thereWasAnError ||
            isConnecting ||
            isRunningCountdown
        ) {
            return null
        }

        if (isReplayingVideo) {

            const Rerecord = () => {
                onStopReplaying()
                recordCount = 0
            }
            return (
                <VideoRecordingButton
                    // type='button'
                    onClick={Rerecord}
                    label={"Erase and Re-Record"}
                    data-qa='BeginVoiceRecordingButton'
                    check={flag}
                >
                    {/* {t('Erase and Rerecord')} */}
                </VideoRecordingButton>
            )
        }
        
        if (isRecording) {
            const Stop = () => {
                onStopRecording()
                if (recordCount === 0) {
                    flag = true
                    recordCount = 1
                } else {
                    flag = false
                }
            }
            return (
                <VideoRecordingButton
                    type='button'
                    onClick={Stop}
                    label={"Stop Recording"}
                    data-qa='stop-recording'
                />
            )
        }

        if (isCameraOn && streamIsReady) {
            return (
                <VideoRecordingButton onClick={onStartRecording} label={"Begin Recording"} />
            )
        }
        if (useVideoInput) {
            return (
                <Button type='button' onClick={onOpenVideoInput} data-qa='open-input'>
                    {t('Upload a video')}
                </Button>
            )
        }

        return shouldUseVideoInput ? (
            <Button type='button' onClick={onOpenVideoInput} data-qa='open-input'>
                {t('Record a video')}
            </Button>
        ) : (
            <Button type='button' onClick={onTurnOnCamera} data-qa='turn-on-camera' style={{position: "unset",marginTop:"-114px"}}>
                {t('Turn my camera ON')}
            </Button>
        )
    }

    return (
        <div>
            {isRecording && <Timer timeLimit={timeLimit} />}
            {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
            <ActionsWrapper>{renderContent()}</ActionsWrapper>
        </div>
    )
}

Actions.propTypes = {
    t: PropTypes.func,
    isVideoInputSupported: PropTypes.bool,
    isInlineRecordingSupported: PropTypes.bool,
    thereWasAnError: PropTypes.bool,
    isRecording: PropTypes.bool,
    isCameraOn: PropTypes.bool,
    streamIsReady: PropTypes.bool,
    isConnecting: PropTypes.bool,
    isRunningCountdown: PropTypes.bool,
    countdownTime: PropTypes.number,
    timeLimit: PropTypes.number,
    showReplayControls: PropTypes.bool,
    replayVideoAutoplayAndLoopOff: PropTypes.bool,
    isReplayingVideo: PropTypes.bool,
    useVideoInput: PropTypes.bool,

    onTurnOnCamera: PropTypes.func,
    onTurnOffCamera: PropTypes.func,
    onOpenVideoInput: PropTypes.func,
    onStartRecording: PropTypes.func,
    onStopRecording: PropTypes.func,
    onPauseRecording: PropTypes.func,
    onResumeRecording: PropTypes.func,
    onStopReplaying: PropTypes.func,
    onConfirm: PropTypes.func
}

export default Actions