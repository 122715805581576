import {observer} from "mobx-react"
import React, {useState} from "react"
import {BreadcrumbHeader} from "../common/components/Breadcrumbs"
import ReactToPrint from "react-to-print"
import {AvantPopperButton} from "../../generic-components/AvantPopperButton"
import {Print} from "@material-ui/icons"
import {IndividualReportHeader} from "../individual/components/IndividualReportHeader"
import {ReportPrintFooter} from "../common/components/ReportPrintFooter"
import {ReportPrintBrand} from "../common/components/ReportPrintBrand"
import {AvantExpansionPanel} from "../../generic-components/AvantExpansionPanel"
import {AvantCircularProgress} from "../../common/loaders/AvantCircularProgress"
import {Box, Paper, Table, TableBody, TableCell, TableHead, TableRow,} from "@material-ui/core"
import {AvantColors} from "../../../styles/AvantColors"
import {findFilters} from "../common/components/ReportsPage"
import {groupMasterStore} from "../../common/group/GroupMasterStore"
import {H4Text} from "../../../styles/AvantTypography"
import {PanelBenchmarkConstants, PanelSkillEnum, PanelSkillFriendlyNameEnum} from "../../../types/types"
import PlaceReportUtils, {PLACEIndividualReport} from "./utils/PlaceReportUtils"
import {ReportFilters} from "../common/models/ReportFilters"
import {RouteComponentProps} from "react-router"
import PlaceProfileConstants from "../../../components/place/PlaceProfileConstants"
import ApiServicePlace from "./ApiServicePlace";

export const placeSkillToFriendlyName: Map<string, string> = new Map([
    [PanelSkillEnum.READING, "Reading Comprehension"],
    [PanelSkillEnum.GRAMMAR, "Contextualized Grammar"],
])

export const shlSkillToFriendlyName: Map<string, string> = new Map([
    [PanelSkillEnum.VOCABULARY, "Lexical"],
    [PanelSkillEnum.DICTATION, PanelSkillFriendlyNameEnum.DICTATION],
    [PanelSkillEnum.TRANSLATION, PanelSkillFriendlyNameEnum.TRANSLATION],
    [PanelSkillEnum.SENTENCE_COMPLETION, PanelSkillFriendlyNameEnum.SENTENCE_COMPLETION],
    [PanelSkillEnum.VERB_CONJUGATION, PanelSkillFriendlyNameEnum.VERB_CONJUGATION],
])

const placeFamilySkillsToName: Map<string, string> = new Map([
    ...Array.from(placeSkillToFriendlyName.entries()),
    ...Array.from(shlSkillToFriendlyName.entries()),
    [PanelSkillEnum.WRITING, PanelSkillFriendlyNameEnum.WRITING],
    [PanelSkillEnum.SPEAKING, PanelSkillFriendlyNameEnum.SPEAKING]
])
interface RouteComponentState {
    report: string;  
   
}

const selfEvalQuestions = [
    "Interpretive Mode - Listening",
    "Interpretive Mode - Reading",
    "Interpersonal Mode - Speaking",
    "Presentational Mode - Speaking",
    "Presentational Mode - Writing"
]


export const PLACEIndividualReportPage: React.FC<RouteComponentProps<{productId: string, takeId: string}>> = observer((props) => {
    const takeId = parseInt(props.match.params.takeId)
    const filters = findFilters(props)
    const routeComponentState = props.location.state
    const [reportData, setReportData] = useState<PLACEIndividualReport | null>()
    const [isLoading, setIsLoading] = useState(true)

    // If we don't have any report data defined (which should happen on the first pass through this component function)...
    if (reportData === undefined) {
        // If we did not receive state data from the route component (probably because this was opened in a new tab or window)...
        if (routeComponentState === undefined) {
            // Make an API call to get the test instance report:
            ApiServicePlace.getTestInstance(takeId).then(testTakerInstanceReport => {
                // Save the report data in state as a PlaceTestTakerInstanceReport object:
                setReportData(testTakerInstanceReport ? PlaceReportUtils.buildPlaceIndividualReport(testTakerInstanceReport) : null)
                // Turn off the is-loading flag:
                setIsLoading(false)
            })
        } else {
            // Save the report data in state as a PlaceTestTakerInstanceReport object:
            // setReportData(JSON.parse(routeComponentState.report))
            setReportData(JSON.parse((routeComponentState as RouteComponentState).report))
            // Turn off the is-loading flag:
            setIsLoading(false)
        }
    }

    return <PLACEIndividualReportDetails takeId={takeId} isLoading={isLoading} reportData={reportData} filters={filters} includeBrand={true}/>
})


interface PLACEIndividualReportDetailsProps {
    takeId: number
    isLoading: boolean
    reportData: PLACEIndividualReport | null | undefined
    filters?: ReportFilters
    print?: boolean
    includeBrand: boolean
}

export const PLACEIndividualReportDetails: React.FC<PLACEIndividualReportDetailsProps> = observer((props) => {
    const {takeId, isLoading, reportData, filters, print, includeBrand} = props

    if (isLoading) {
        return (
            <Box>
                Retrieving individual report for Take ID {takeId}&hellip;
                <AvantCircularProgress/>
            </Box>
        )
    }

    if (reportData === undefined || reportData === null) {
        return (
            <Box>
                No data for Take ID {takeId}
            </Box>
        )
    }

    let componentRef: any
    const setRef = (ref: any) => componentRef = ref

    let reportPrintBrand = <></>
    if (includeBrand) {
        reportPrintBrand = <ReportPrintBrand/>
    }

    const famSpeaksNonEng = (familyLanguageId: number | null): string => {
        if(familyLanguageId == null || familyLanguageId < 1) {
            return "No"
        }
        return "Yes"
    }

    const handlePlaceC = (level: [string, string | number]) => {
        if (
            level[0] === PanelSkillEnum.WRITING &&
            (reportData.product_package === "plc" || reportData.product_package === "shl-c")
        ) {
            if (reportData.finishDate) {
                return PanelBenchmarkConstants.PANEL_RATED_BY_EDUCATOR
            }

            return PanelBenchmarkConstants.PANEL_IN_PROGRESS
        }

        return level[1]
    }

    const familyLanguageName = PlaceProfileConstants.getLanguageNameById(Number(reportData.familyLanguageId))

    return  <div style={{maxWidth: 1100, margin: "0 auto", width: "70vw"}} className={"individual_reports_container"}>

                    {print == null && filters && (
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            { (groupMasterStore && groupMasterStore.groupInfo)
                                ? <BreadcrumbHeader
                                    groupInfo={groupMasterStore.groupInfo}
                                    individual={`${reportData.firstName} ${reportData.lastName}`}
                                    filters={filters}
                                />
                                : <Box width={1} mb={7.4}></Box>
                            }
                            <ReactToPrint
                                trigger={() => <AvantPopperButton title={<Print/>} />}
                                content={() => componentRef}
                                pageStyle={"@media print {margin: 0.5in}"}
                            />
                        </div>
                    )}

                    <div ref={setRef} style={{display: "flex", flexDirection: "column"}}>
                        {reportPrintBrand}
                        <IndividualReportHeader
                            firstName={reportData.firstName}
                            lastName={reportData.lastName}
                            language={reportData.language}
                            loginName={reportData.login}
                            date={reportData.finishDate}
                            groupData={reportData.groupData}
                        />

                        <AvantExpansionPanel title={"Avant Score"} score={reportData.score}>
                            <Table>
                                <TableBody>
                                    {
                                        reportData.levels.map((level, index) => {
                                            const symbol = level[0]
                                            const panelLevel = handlePlaceC(level)

                                            return (
                                                <TableRow
                                                    style={{backgroundColor: (index + 1) % 2 === 0 ? AvantColors.REPORT_BACKGROUND_GRAY_DARK : ""}}
                                                    key={symbol}
                                                >
                                                    <TableCell>
                                                        {placeFamilySkillsToName.get(symbol)}
                                                    </TableCell>
                                                    <TableCell align={"right"}>
                                                        <strong style={{marginRight: "5rem"}}>{panelLevel}</strong>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                    <TableRow
                                        style={{backgroundColor: (reportData.levels.length) % 2 === 1 ? AvantColors.REPORT_BACKGROUND_GRAY_DARK : ""}}
                                    >
                                        <TableCell>
                                            Total Average
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <strong style={{marginRight: "5rem"}}>{reportData.total_average ? reportData.total_average : ""}</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </AvantExpansionPanel>

                        <AvantExpansionPanel title={"Self Evaluation"} score={reportData.candoAverage}>
                            <Table>
                                <TableBody>
                                    {
                                        selfEvalQuestions.map((canDo: string, index: number) => {
                                            return (
                                                <TableRow
                                                    style={{backgroundColor: (index + 1) % 2 === 0 ? AvantColors.REPORT_BACKGROUND_GRAY_DARK : ""}}
                                                    key={canDo}
                                                >
                                                    <TableCell>
                                                        {canDo}
                                                    </TableCell>
                                                    <TableCell align={"right"}>
                                                        <strong style={{marginRight: "5rem"}}>{reportData[`cando_${index + 1}`]}</strong>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }

                                    <TableRow style={{backgroundColor: AvantColors.REPORT_BACKGROUND_GRAY_DARK}}>
                                        <TableCell>
                                            Proficiency Self-Evaluation Level
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <strong style={{marginRight: "5rem"}}>{reportData.candoAverage}</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </AvantExpansionPanel>

                        <Paper style={{ backgroundColor: AvantColors.REPORT_BACKGROUND_GRAY_DARK, marginBottom: "1rem" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <H4Text style={{color: "#000"}}>Test Taker Profile:</H4Text>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <strong>Email:</strong> {reportData.email}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <strong>Years studied {reportData.language}:</strong> {reportData.years}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <strong>Grades formerly Studied {reportData.language}:</strong> {reportData.grades.join(", ")}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <strong>Test Taken in {reportData.language}:</strong> {reportData.tests ? PlaceReportUtils.formattedTests(reportData.tests) : "None"}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <strong>Family speaks Non-English at home:  </strong> {famSpeaksNonEng(reportData.familyLanguageId)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {
                                            reportData.familyLanguageId &&
                                            famSpeaksNonEng(reportData.familyLanguageId) == "Yes" &&
                                            <TableCell>
                                                <strong>Language at Home:</strong> {familyLanguageName}
                                            </TableCell>
                                        }
                                    </TableRow>
                                    <TableRow>
                                        {
                                            reportData.familyMembers.join() &&
                                            reportData.familyLanguageId &&
                                            famSpeaksNonEng(reportData.familyLanguageId) == "Yes" &&
                                            <TableCell>
                                                <strong>Family Members speaking {familyLanguageName}:</strong> {reportData.familyMembers.join(", ").replace(/,(\s*)$/, "").replace("Other, ", "")}
                                            </TableCell>
                                        }
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Paper>

                        <ReportPrintFooter/>
                    </div>
                </div>
})
