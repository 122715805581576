import React from "react"
import videojs from "video.js"

import "video.js/dist/video-js.css"

export interface IVideoPlayerSource {
    src: string
    type: string
}

export interface IComponentProps {
    autoplay: boolean
    controls: boolean
    sources: IVideoPlayerSource[]
}

export default class VideoPlayer extends React.Component<IComponentProps> {
    player: any
    videoNode: any


    componentDidMount() {
        // instantiate video.js
        // TODO: figure out if this is the way to deal with this after upgrade
        // @ts-ignore
        this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
        })
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }

    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render() {
        return (
            <div data-vjs-player={true}>
                <video ref={(node) => (this.videoNode = node)} className="video-js" data-tst-id="video-player"/>
            </div>
        )
    }
}
