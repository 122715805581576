import * as React from "react"
import {useEffect} from "react"
import ApiService from "../../../../services/ApiService"
import {groupMasterStore} from "../../../common/group/GroupMasterStore"
import {RouteComponentProps} from "react-router-dom"
//import {ReportSubPaths} from "../../../reports/ReportSubPaths"
//import {LoginProductContentAreaData} from "../../../../types/types";
//import {CONTENT_AREA_ID_TO_LANGUAGE_NAME} from "../../../../util/Constants";
//import {productStore} from "../../../common/products/ProductStore";


export const SSODistrictReports: React.FC<RouteComponentProps> = (props) => {
    console.log("_________")
    const isCancelled = React.useRef(false)
    // const [groupId, setGroupId] = React.useState<number | undefined>(undefined)

    const getSSOEntity = async () => {
        const response = await ApiService.getSSOEntity()
        if (!isCancelled.current && response.groupId) {
            /*
            const loginProduct: LoginProductContentAreaData = {
                //loginId: rowData.logins.id,
                userType: "D",
                //productId: rowData.logins.productId,
                productName: "District Report",
                //contentAreaId: rowData.logins.contentareaid,
                //contentAreaName: CONTENT_AREA_ID_TO_LANGUAGE_NAME[rowData.logins.contentareaid],
                rostered: true,
                //userName: response.,
            }

             */

            // Setup state for the report
            //productStore.setLoginProduct(loginProduct)
            groupMasterStore.selectGroup(response.groupId)
            //props.history.push(ReportSubPaths.REPORTS_REDIRECT)
        }

    }
    useEffect(() => {
        getSSOEntity()
        return () => {
            isCancelled.current = true
        }
    })

    return (
        <div style={{textAlign: "center"}}>
            <div className="login__login-container" style={{display: "inline-block"}}>
                <div className="login__login-container__top">
                    <h2>District Reports</h2>
                    <p>Blue reports will show here.</p>
                </div>
            </div>
        </div>
    )

}
