import {isUndefined} from "util"
import {Section} from "../../app/section/models/Section"
import {Answer, IAction, ItemStore, RecordWarningEnum} from "../../types/types"
import {USER_LOGOUT} from "../app/actions"
import {
    LOGOUT_IN_TEST,
    STOP_TEST,
    UPDATE_CORRECT_ANSWER,
    UPDATE_ITEM_CORRECT_ANSWER,
    UPDATE_ITEM_INSTRUCTIONS,
    UPDATE_ITEM_TRAINING,
    UPDATE_LAST_ITEM_INDEX,
    UPDATE_RECORDING_WARNING_SEEN,
    UPDATE_RESPONSES,
    UPDATE_SECTION,
    UPDATE_TEST_DURATION,
    UPDATE_TIME_REMAINING
} from "./actions"

export const itemInitialState: ItemStore = {
    currentSection: null,
    logoutInTest: false,
    recordingWarningSeen: RecordWarningEnum.UNSEEN,
    stopTest: false,
    testDuration: 0
}

export const item = (state: ItemStore = itemInitialState, action: IAction) => {
    let currentSection: Section
    let length: number
    switch (action.type) {
        case USER_LOGOUT:
            return itemInitialState
        case UPDATE_SECTION:
            return {
                ...state,
                currentSection: action.result
            }
        case UPDATE_ITEM_CORRECT_ANSWER:
            if (state.currentSection === null) {
                throw new Error("Current section is null")
            }
            currentSection = {...state.currentSection}
            if (currentSection.correctAnswers.has(action.result.oldCorrectAnswer.id)) {
                currentSection.correctAnswers.delete(action.result.oldCorrectAnswer.id)
                const key = `${action.result.newCorrectAnswer.id}${action.result.newCorrectAnswer.binName}`
                currentSection.correctAnswers.set(key, action.result.newCorrectAnswer)
            }
            length = currentSection.items[action.result.itemIndex].correctAnswers.length
            if (length > 0) {
                for (let i = 0; i < length; i++) {
                    const correctAnswer: string =
                        currentSection.items[action.result.itemIndex].correctAnswers[i]
                    const newCorrectAnswerId: string = action.result.newCorrectAnswer.id as string
                    if (correctAnswer === (action.result.oldCorrectAnswer.id as string)) {
                        currentSection.items[action.result.itemIndex].correctAnswers[i] = newCorrectAnswerId
                        break
                    }
                }
            }

            return {
                ...state,
                currentSection
            }
        case UPDATE_ITEM_TRAINING:
            if (state.currentSection === null) {
                throw new Error("Current section is null")
            }
            currentSection = {...state.currentSection}
            if (isUndefined(currentSection.items[action.result.itemIndex].training)) {
                throw new Error(`Training is undefined for item at index ${action.result.itemIndex}`)
            }
            currentSection.items[action.result.itemIndex].training!.content = action.result.content

            return {
                ...state,
                currentSection
            }
        case UPDATE_ITEM_INSTRUCTIONS:
            if (state.currentSection === null) {
                throw new Error("Current section is null")
            }
            currentSection = {...state.currentSection}
            const index = action.result.itemIndex
            const itemContentId = action.result.itemContentId
            const content = action.result.content

            if (currentSection.items[index] !== undefined) {
                if (
                    currentSection.items[index].itemContent1 !== undefined &&
                    currentSection.items[index].itemContent1!.id === itemContentId
                ) {
                    currentSection.items[index].itemContent1!.content = content
                } else if (
                    currentSection.items[index].itemContent2 !== undefined &&
                    currentSection.items[index].itemContent2!.id === itemContentId
                ) {
                    currentSection.items[index].itemContent2!.content = content
                } else if (
                    currentSection.items[index].itemContent3 !== undefined &&
                    currentSection.items[index].itemContent3!.id === itemContentId
                ) {
                    currentSection.items[index].itemContent3!.content = content
                } else if (
                    currentSection.items[index].itemContent4 !== undefined &&
                    currentSection.items[index].itemContent4!.id === itemContentId
                ) {
                    currentSection.items[index].itemContent4!.content = content
                } else if (
                    currentSection.items[index].itemContent5 !== undefined &&
                    currentSection.items[index].itemContent5!.id === itemContentId
                ) {
                    currentSection.items[index].itemContent5!.content = content
                } else if (
                    currentSection.items[index].itemContent6 !== undefined &&
                    currentSection.items[index].itemContent6!.id === itemContentId
                ) {
                    currentSection.items[index].itemContent6!.content = content
                }
            }

            return {
                ...state,
                currentSection
            }
        case UPDATE_RESPONSES:
            const newResponses = new Map(state.currentSection!.responses)
            const answer: Answer = action.result
            newResponses.set(`${answer.itemId}${answer.binName}`, action.result)

            return {
                ...state,
                currentSection: {
                    ...state.currentSection,
                    responses: newResponses
                }
            }
        case UPDATE_CORRECT_ANSWER:
            const newCorrectAnswers = new Map(state.currentSection!.correctAnswers)
            if (action.result.correctAnswer) {
                newCorrectAnswers.set(
                    `${action.result.itemId}${action.result.correctAnswer.binName}`,
                    action.result.correctAnswer
                )
            }

            return {
                ...state,
                currentSection: {
                    ...state.currentSection,
                    correctAnswers: newCorrectAnswers
                }
            }

        case UPDATE_LAST_ITEM_INDEX:
            return {
                ...state,
                currentSection: {
                    ...state.currentSection,
                    lastItemIndex: action.result
                }
            }

        case UPDATE_TIME_REMAINING:
            return {
                ...state,
                currentSection: {
                    ...state.currentSection,
                    timeRemaining: action.result.timeRemaining,
                    timeUsed: action.result.timeUsed
                }
            }
        case LOGOUT_IN_TEST:
            return {
                ...state,
                logoutInTest: action.result
            }
        case STOP_TEST:
            return {
                ...state,
                stopTest: action.result
            }
        case UPDATE_TEST_DURATION:
            return {
                ...state,
                testDuration: action.result
            }

        case UPDATE_RECORDING_WARNING_SEEN:
            return {
                ...state,
                recordingWarningSeen: action.result
            }

        default:
            return state
    }
}
