import React from "react"
import {TestCodeSearchForm} from "./TestCodeSearchForm"
import {ApiLogin} from "../../types/types"
import ApiService from "../../services/ApiService"
import {TestCodeManagementTable} from "./TestCodeManagementTable"
import lodash from "lodash"
import {observer} from "mobx-react"
import {messageStore} from "../common/messages/MessageStore"
import {MessageView} from "../common/messages/MessageView"

export interface UpdateTestCodesDTO {
    loginId: number
    isHandwritten: boolean
}

export const TestCodeManagement: React.FC<UpdateTestCodesDTO[]> = observer((id, handwritten) => {

    const [searchString, setSearchString] = React.useState("")
    const [loginsFromApi, setLoginsFromApi] = React.useState<ApiLogin[]>([])

    const onSearchLoginsByUsername = async (username: string) => {
        const logins: ApiLogin[] = await ApiService.searchLoginByUsernameAndUserType(username, "S")
        const initialSort = lodash.orderBy(logins, "username", "asc")
        setLoginsFromApi(initialSort)
    }

    const onChangeHandwrittenStatus = async (data: UpdateTestCodesDTO) => {
        await ApiService.updateTestCodeHandwrittenStatus(data)
        onSearchLoginsByUsername(searchString)
        const testCodeUpdated: ApiLogin = loginsFromApi.find((obj: ApiLogin) => obj.id === data.loginId)!
        messageStore.setInfoMessage(`Test code: ${testCodeUpdated.username} now has handwritten set to: ${data.isHandwritten}`)
    }

    const onSearch = () => {
        onSearchLoginsByUsername(searchString)
    }

    return (
        <>
            <TestCodeSearchForm
                setSearchString={setSearchString}
                testCodeSearchString={searchString}
                onSearch={onSearch}
            />

            <TestCodeManagementTable
                testCodeData={loginsFromApi}
                setTestCodeData={setLoginsFromApi}
                updateTestCode={onChangeHandwrittenStatus}
                onSearch={onSearch}
            />

            <MessageView />
        </>
    )
})
