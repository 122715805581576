import {Close} from "@material-ui/icons"
import React from "react"
import {Modal} from "react-bootstrap"
import {AvantModalCloseIcon} from "../../app/generic-components/AvantModalCloseIcon"
import {theme} from "../../styles/MuiThemes"
import Button from "../Button/Button"

interface ConfirmationModalProps {
    showModal: boolean
    modalTitle: string
    noClickedOrClose: () => void
    yesClicked: () => void
}

class ConfirmationModal extends React.Component<ConfirmationModalProps, {}> {
    constructor(props: ConfirmationModalProps) {
        super(props)
        this.state = {
            showModal: props.showModal
        }
    }

    closeModal = () => {
        this.props.noClickedOrClose()
    }

    handleYes = () => {
        this.props.yesClicked()
    }

    render() {
        const {showModal, modalTitle} = this.props

        return (
            <Modal className="confirmation-modal" show={showModal} onHide={this.closeModal}>
                <Modal.Header closeButton={false} style={{justifyContent: "center", alignItems: "center", padding: theme.spacing(3)}}>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <AvantModalCloseIcon
                        onClick={this.closeModal}
                    >
                        <Close/>
                    </AvantModalCloseIcon>
                </Modal.Header>
                <Modal.Body>
                    <div className="confirmation-modal__content__buttons">
                        <Button
                            className="avant-modal-button--primary confirmation-modal__content__buttons__button"
                            block={true}
                            testId="modal-yes-button"
                            onClick={this.handleYes}
                        >
                            YES
                        </Button>
                        <Button
                            className="avant-modal-button--primary confirmation-modal__content__buttons__button"
                            block={true}
                            testId="modal-no-button"
                            onClick={this.closeModal}
                        >
                            No
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default ConfirmationModal
