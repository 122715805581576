export enum AvantColors {
    avantDarkerBlue = "#011728",
    avantDarkBlue = "#3B5470",
    avantGreyBlue = "#738CA7",
    avantBlue = "#4F6FD2",
    avantLightBlue = "#E8F2F8",
    avantLightGrey = "#BFCBCC",
    avantDarkGrey = "#7F7F7F",
    avantTeal = "#1ABC9C",
    avantLightOrange = "#F5A623",
    avantOrange = "#E67E22",
    avantDarkOrange = "#D35400",
    avantRed = "#E74C3C",
    avantDarkTeal = "#16A085",
    avantTealTransparent = "rgba(22, 160, 133, 0.1)",

    voiceRecorderTxtRed = "#D0021B",
    voiceRecorderBgRed = "#D0021B",
    voiceRecorderBgLightred = "#F9E6E8",
    voiceRecorderTooltipBgGrey = "rgba(97, 97, 97, 0.9)",
    voiceRecorderTxtWhite = "#FFFFFF",
    voiceRecorderLinkTxtLightblue = "#519AC8",
    voiceRecorderHeaderTxtDarkblue = "#3B5470",
    voiceRecorderButtonOrange = "#E67E22",
    voiceRecorderBgLightblue = "#E8F2F8",
    voiceRecorderBgBlue = "#519AC8",
    voiceRecorderTxtBlue = "#519AC8",

    CLOSE_BUTTON_GRAY = "#E7E7E7",

    SUPERIOR_FILL = "#7F33BA",
    ADVANCED_FILL = "#547CDF",
    INTERMEDIATE_FILL = "#84CA72",
    NOVICE_FILL = "#FDC113",

    SUPERIOR_FILL_LIGHT = "#BF99DC",
    ADVANCED_FILL_LIGHT = "#A6BAEC",
    INTERMEDIATE_FILL_LIGHT = "#AEDDA1",
    NOVICE_FILL_LIGHT = "#FEE395",

    NOVICE_FILL_TEXT = "#9E4300",

    NOT_RATABLE_FILL = "#EE8D93",
    REPORT_FONT_COLOR_GRAY_1 = "#3B3832",
    REPORT_FONT_COLOR_GRAY_2 = "#585858",
    REPORT_FONT_COLOR_GRAY_3 = "#767676",
    REPORT_FONT_COLOR_BLACK = "#231F20",
    REPORT_FONT_COLOR_WHITE = "#ffffff",

    REPORT_BACKGROUND_GRAY_LIGHT = "#FAFAFA",
    REPORT_BACKGROUND_GRAY_MED = "#F9F9F9",
    REPORT_BACKGROUND_GRAY_DARK = "#F3F3F3",
    REPORT_BOX_BORDER = "#CDCDCD",
    REPORT_BOX_BORDER_BLUE = "rgba(43,93,216,0.5)",

    ERROR_TEXT = "#F93D5C",

    REPORT_LINK_TEXT_BLUE = "#2B5DD8",

    REPORT_REFERENCE_LINE_GRAY = "#E8E8E8",

    REPORT_BACKGROUND_BLUE = "#E9EEFB",

    REPORT_YELLOW = "#FFC900",
    PRIMARY_BLUE = "#285DD8",
}

export enum REPORT_HASH_COLORS {
    YELLOW = "#FBE08B",
    GREEN = "#B8DBA6",
    BLUE = "#A9BAE8",
    PURPLE = "#BBABDD"
}
export enum REPORT_HASH_FILL_COLORS {
    YELLOW = "#F4F1EB",
    GREEN = "#ECF5E8",
    BLUE = "#E8ECF7",
    PURPLE = "#EEE9F6"
}
