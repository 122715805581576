import React from "react"
import * as uuid from "uuid"
import {GenericImageMap, ImageMapArea} from "../types/types"
import {to} from "../util/elvis"

export interface ImageMapProps {
    src: string
    map: GenericImageMap
    fillColor: string
    hoverStrokeColor: string
    selectedStrokeColor: string
    lineWidth: number
    active: boolean
    onClick?: (area: ImageMapArea) => void
    onImageClick?: (event: any) => void
    onLoad?: () => void
    onMouseEnter?: (area: ImageMapArea, index: number, event: any) => void
    onMouseLeave?: (area: ImageMapArea, index: number, event: any) => void
    correctAnswer: string | null
    answer: string | null
    width?: number
    height?: number
}

interface ImageMapState {
    selectedArea: ImageMapArea
    answer: string | null
}

export default class ImageMap extends React.Component<ImageMapProps, ImageMapState> {
    styles: {
        container: object
        canvas: object
        img: object
    }
    canvas: any
    container: any
    img: any
    ctx?: CanvasRenderingContext2D

    constructor(props: ImageMapProps) {
        super(props)
        const absPos = {position: "absolute", top: 0, left: 0}
        this.styles = {
            container: {
                position: "relative",
                width: this.props.width ? this.props.width : undefined,
                textAlign: "center",
                margin: "auto"
            },
            canvas: {...absPos, pointerEvents: "none"},
            img: {...absPos, userSelect: "none"}
        }
        this.state = {
            answer: null,
            selectedArea: {
                id: "Z",
                shape: "rect",
                coords: [0, 0, 0, 0]
            }
        }
    }

    resetState = () => {
        this.setState({
            answer: null,
            selectedArea: {
                id: "Z",
                shape: "rect",
                coords: [0, 0, 0, 0]
            }
        })
    }

    drawRect = (coords: string) => {
        const [left, top, right, bot] = coords.split(",").map((coor: string) => parseInt(coor, 10))

        if (this.ctx) {
            this.ctx.strokeRect(left - 1, top - 1, right - left + 2, bot - top + 2)
            this.ctx.fillRect(left, top, right - left, bot - top)
        }
    }

    initCanvas = () => {
        this.resetState()
        if (this.props.width) {
            this.img.width = this.props.width
        }

        this.canvas.width = this.img.clientWidth
        this.canvas.height = this.img.clientHeight
        this.container.style.width = this.img.clientWidth + "px"
        this.container.style.height = this.img.clientHeight + "px"
        this.ctx = this.canvas.getContext("2d")

        if (!this.ctx) {
            throw new Error("Context was null in initCanvas")
        }

        this.ctx.fillStyle = this.props.fillColor
        this.ctx.strokeStyle = this.props.hoverStrokeColor
        this.ctx.lineWidth = this.props.lineWidth
        if (this.props.onLoad) {
            this.props.onLoad()
        }

        const correctAreaIndex: number | undefined = this.props.map.areas.findIndex((area) => area.id === this.props.correctAnswer)

        if (correctAreaIndex > -1) {
            const correctArea: ImageMapArea = this.props.map.areas[correctAreaIndex]
            if (this.props.onClick) {
                this.props.onClick(correctArea)
                this.drawSelected(correctArea.coords.join(","))
                this.setState({selectedArea: correctArea})
            }
        }
    }

    hoverOn = (area: ImageMapArea, index: number, event: any) => {
        this.drawSelected()

        if (this.props.active && this.state.selectedArea.id !== area.id) {
            this.drawRect(event.target.getAttribute("coords"))
        }

        if (this.props.onMouseEnter) {
            this.props.onMouseEnter(area, index, event)
        }
    }

    hoverOff = (area: ImageMapArea, index: number, event: any) => {
        this.clearRect(event.target.getAttribute("coords"))
        if (this.props.active && this.state.selectedArea.id === area.id) {
            if (!this.ctx) {
                return
            }
            this.ctx.strokeStyle = this.props.selectedStrokeColor
            this.drawRect(event.target.getAttribute("coords"))
            this.ctx.strokeStyle = this.props.hoverStrokeColor
        }

        this.drawSelected()

        if (this.props.onMouseLeave) {
            this.props.onMouseLeave(area, index, event)
        }
    }

    drawSelected = (selectedArea?: string) => {
        if (selectedArea === undefined) {
            selectedArea = this.state.selectedArea.coords.join(",")
        }
        if (!this.ctx) {
            return
        }
        this.ctx.strokeStyle = this.props.selectedStrokeColor
        this.clearRect(selectedArea)
        this.drawRect(selectedArea)
        this.ctx.strokeStyle = this.props.hoverStrokeColor
    }

    clearRect = (coords: string) => {
        const [left, top, right, bot] = coords.split(",").map((coor: string) => parseInt(coor, 10))
        if (!this.ctx) {
            return
        }
        this.ctx.clearRect(left - 3, top - 3, right - left + 6, bot - top + 6)
    }

    click = (area: ImageMapArea, index: number, event: any) => {
        this.drawSelected()

        if (this.props.onClick) {
            event.preventDefault()
            this.props.onClick(area)
        }
    }

    renderAreas = () => {
        return this.props.map.areas.map((area, index) => (
            <area
                data-tst-id={index}
                key={Math.random().toString()}
                shape={area.shape}
                coords={area.coords.join(",")}
                onMouseOver={this.hoverOn.bind(this, area, index)}
                onMouseOut={this.hoverOff.bind(this, area, index)}
                onClick={this.click.bind(this, area, index)}
                href={area.href}
                alt={"area"}/>
        ))
    }

    componentDidUpdate() {
        if (this.props.answer && this.props.answer !== this.state.answer) {
            let area = this.props.map.areas.find((mapArea: ImageMapArea) => {
                return mapArea.id === this.props.answer
            })

            area = to<ImageMapArea>(area, Error(`[ImageMap] Could not find map.area for id ${this.props.answer}`))
            this.clearRect(this.state.selectedArea.coords.toString())
            this.drawSelected(area.coords.join(","))
            this.setState({
                answer: this.props.answer,
                selectedArea: area
            })
        }
    }

    render() {
        const key = uuid.v4.toString()
        return (
            <div className="map-with-apple" key={key} style={this.styles.container} ref={(node) => (this.container = node)} tabIndex={0}>
                <img
                    data-tst-id="selectable-response"
                    draggable={false}
                    src={this.props.src}
                    useMap={`#${this.props.map.name}`}
                    alt=""
                    ref={(node) => (this.img = node)}
                    onLoad={this.initCanvas}
                    onClick={this.props.onImageClick}
                    className="img-item-3"
                />
                <canvas ref={(node) => (this.canvas = node)} style={this.styles.canvas} />
                <map name={this.props.map.name}>{this.renderAreas()}</map>
            </div>
        )
    }
}
