import React from "react"
import {isNullOrUndefined} from "util"

import {
    GenericImageMap,
    IItem,
    ImageMapArea,
    LoginProductContentAreaData,
    TargetZone,
    TLocalAnswer
} from "../../types/types"
import {APT_PRODUCT_ID} from "../../util/Constants"
import ImageMap from "../ImageMap"

interface IComponentProps {
    item: IItem
    answer: TLocalAnswer
    product: LoginProductContentAreaData
    handleAnswerChange: (localAnswer: TLocalAnswer) => void
}

export default class ZoneClick extends React.Component<IComponentProps, {}> {

    onClickZone = (area: ImageMapArea) => {
        this.props.handleAnswerChange(area.id)
    }

    render() {
        const item: IItem = this.props.item
        if (item.targetZones !== undefined) {
            const imageUrl: string = item.choices[0].content as string
            const height: number = !isNullOrUndefined(item.choices[0].height) ? item.choices[0].height! : 200
            const width: number = !isNullOrUndefined(item.choices[0].width) ? item.choices[0].width! : 200
            const targetZones: TargetZone[] = item.targetZones

            const imageMap: GenericImageMap = {
                name: "image-map",
                areas: []
            }

            targetZones.forEach((targetZone: TargetZone) => {
                const coords: number[] = []
                coords.push(targetZone.areaminx)
                coords.push(targetZone.areaminy)
                coords.push(targetZone.areamaxx)
                coords.push(targetZone.areamaxy)
                const area: ImageMapArea = {
                    id: targetZone.position,
                    shape: "rect",
                    coords
                }
                imageMap.areas.push(area)
            })

            let correctAnswer = null
            if (item.correctAnswers && item.correctAnswers.length > 0) {
                correctAnswer = item.correctAnswers[0]
            }

            const isApt = this.props.product.productId === APT_PRODUCT_ID.value()

            return (
                <div className="zone-click">
                    {!isApt && (
                        <div className="zone-click__prompt" style={{width}}>
                            Click to select the best part of the image.
                        </div>
                    )}
                    <ImageMap
                        src={imageUrl}
                        map={imageMap}
                        answer={this.props.answer as string}
                        onClick={this.onClickZone}
                        data-tst-id={this.props.answer}
                        fillColor="rgba(255, 255, 255, 0.58)"
                        hoverStrokeColor="rgba(205, 205, 205, 0.58)"
                        selectedStrokeColor="rgba(255, 201, 0, 0.58)"
                        lineWidth={3}
                        correctAnswer={correctAnswer}
                        active={true}
                        height={height}
                        width={width}
                    />
                </div>
            )
        }
        return
    }
}
