import {FormControl, MenuItem} from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import React from "react"
import {
    ADVANCE_PRODUCT_ID,
    APT_PRODUCT_ID,
    JEENIE_PRODUCT_ID,
    PLACE_PRODUCT_ID,
    READY_PRODUCT_ID,
    STAMP_4S_PRODUCT_ID,
    STAMP_4SE_PRODUCT_ID,
    WORLDSPEAK_PRODUCT_ID,
    STAMP_CEFR_PRODUCT_ID,
    STAMP_SIGN_LANGUAGE_PRODUCT_ID
} from "../../../util/Constants"
import {ProductId} from "../../../validation/ValidPrimaryKey"

interface IComponentProps {
    onProductChanged: (selectedProduct?: ProductId) => void
    currentProduct?: ProductId
    required?: boolean
    // Because we are reusing this dropdown in more than one place and want to omit Advance as an option in the
    // content viewer tool, I'm introducing an optional prop "isContentViewer" which is a boolean that will be passed
    // as true if the component is being rendered on the Content Viewer page. I then only render out the menu item for
    // Advance if that prop is not true
    isContentViewer?: boolean
}

interface IComponentState {
    currentProduct?: ProductId
    errors: string[]
}

export default class ProductSelectDropDown extends React.Component<IComponentProps, IComponentState> {
    state: IComponentState

    constructor(props: IComponentProps) {
        super(props)
        this.state = {
            currentProduct: props.currentProduct === null ? undefined : props.currentProduct,
            errors: []
        }
    }

    componentDidUpdate(prevProps: IComponentProps) {
        if (this.props.currentProduct !== prevProps.currentProduct) {
            this.setState({
                currentProduct: this.props.currentProduct === null ? undefined : this.props.currentProduct
            })
        }
    }

    handleChange = (event: any) => {
        const currentProduct = event.target.value !== "" ? new ProductId(Number(event.target.value)) : undefined
        this.setState({currentProduct})
        this.props.onProductChanged(currentProduct)
    }

    render() {
        const errors = this.state.errors
        const currentProduct = this.state.currentProduct ? this.state.currentProduct.value() : ""

        const selectLabelId = "product-dropdown"
        const required = (this.props.required) ? this.props.required : false
        return (
            <FormControl>
                <div className="error" hidden={errors.length === 0}>
                    {errors.join(" ") || "placeholder"}
                </div>
                <TextField
                    select={true}
                    onChange={this.handleChange}
                    value={currentProduct}
                    helperText="Product"
                    id={selectLabelId}
                    style={{width: 200}}
                    required={required}
                >
                    <MenuItem value={""}></MenuItem>
                    <MenuItem
                        key={"product-STAMP_4SE_PRODUCT_ID"}
                        id={`id-STAMP_4SE_PRODUCT_ID`}
                        value={STAMP_4SE_PRODUCT_ID.value()}
                        data-tst-id={STAMP_4SE_PRODUCT_ID.value()}
                    >
                        Stamp4Se
                    </MenuItem>
                    <MenuItem
                        key={"product-STAMP_4S_PRODUCT_ID"}
                        id={"product-STAMP_4S_PRODUCT_ID"}
                        value={STAMP_4S_PRODUCT_ID.value()}
                        data-tst-id={STAMP_4S_PRODUCT_ID.value()}
                    >
                        Stamp4S
                    </MenuItem>
                    <MenuItem
                        key={"product-PLACE_PRODUCT_ID"}
                        id={"product-PLACE_PRODUCT_ID"}
                        value={PLACE_PRODUCT_ID.value()}
                        data-tst-id={PLACE_PRODUCT_ID.value()}
                    >
                        PLACE
                    </MenuItem>
                    <MenuItem key={"product-APT_PRODUCT_ID"} id={"product-APT_PRODUCT_ID"}
                              value={APT_PRODUCT_ID.value()}
                              data-tst-id={APT_PRODUCT_ID.value()}

                    >
                        Apt
                    </MenuItem>
                    <MenuItem
                        key={"product-WORLDSPEAK_PRODUCT_ID"}
                        id={"product-WORLDSPEAK_PRODUCT_ID"}
                        value={WORLDSPEAK_PRODUCT_ID.value()}
                        data-tst-id={WORLDSPEAK_PRODUCT_ID.value()}
                    >
                        WorldSpeak
                    </MenuItem>
                    <MenuItem
                        key={"product-STAMP_CEFR_PRODUCT_ID"}
                        id={"product-STAMP_CEFR_PRODUCT_ID"}
                        value={STAMP_CEFR_PRODUCT_ID.value()}
                        data-tst-id={STAMP_CEFR_PRODUCT_ID.value()}
                    >
                        STAMP CEFR
                    </MenuItem>
                    <MenuItem
                        key={"product-STAMP_SIGN_LANGUAGE_PRODUCT_ID"}
                        id={"product-STAMP_SIGN_LANGUAGE_PRODUCT_ID"}
                        value={STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()}
                        data-tst-id={STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()}
                    >
                        STAMP Sign Language
                    </MenuItem>
                    <MenuItem
                        key={"product-READY_PRODUCT_ID"}
                        id={"product-READY_PRODUCT_ID"}
                        value={READY_PRODUCT_ID.value()}
                        data-tst-id={READY_PRODUCT_ID.value()}
                    >
                        Ready
                    </MenuItem>
                    { this.props.isContentViewer !== true &&
                        <MenuItem
                            key={"product-ADVANCE_PRODUCT_ID"}
                            id={"product-ADVANCE_PRODUCT_ID"}
                            value={ADVANCE_PRODUCT_ID.value()}
                            data-tst-id={ADVANCE_PRODUCT_ID.value()}
                        >
                            Advance
                        </MenuItem>
                    }
                    <MenuItem
                        key={"product-JEENIE_PRODUCT_ID"}
                        id={"product-JEENIE_PRODUCT_ID"}
                        value={JEENIE_PRODUCT_ID.value()}
                        data-tst-id={JEENIE_PRODUCT_ID.value()}
                    >
                        Jeenie
                    </MenuItem>
                </TextField>
            </FormControl>
        )
    }
}
