/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {Divider, List, ListItem} from "@material-ui/core"
import {observable} from "mobx"
import {observer} from "mobx-react"
import * as React from "react"
import {LinkText, SmallBodyText} from "../../../../../styles/AvantTypography"
import {theme} from "../../../../../styles/MuiThemes"
import {ApiItemResponse, ApiPanel, ItemFormatEnum, PanelLevel} from "../../../../../types/types"
import {AvantCircularProgress} from "../../../../common/loaders/AvantCircularProgress"
import {TakePanelStore} from "../../../../common/take-panel/TakePanelStore"
import {SidebarResponseModal} from "./SidebarResponseModal"
import {AvantColors} from "../../../../../styles/AvantColors"

interface SidebarResponsesProps {
    takePanelId: number
    fullName: string
}

@observer
export class SidebarResponses extends React.Component<SidebarResponsesProps> {

    private takePanelStore = new TakePanelStore()

    componentDidMount(): void {
        this.takePanelStore.getDetailedTakePanel(this.props.takePanelId)
    }

    render() {
        const takePanel = this.takePanelStore.detailedTakePanel
        if (takePanel == null) {
            return <AvantCircularProgress/>
        }

        const filteredResponses = takePanel.responses.filter(
            (response: ApiItemResponse) => response.itemFormat !== ItemFormatEnum.BLANK
                && !response.itemName.toLowerCase().includes("sample")
                && !response.itemName.toLowerCase().includes("practice")
            ).sort((responseA, responseB) => responseA.id - responseB.id)

        return (
            <List
                dense={true}
                style={{padding: theme.spacing(3)}}
            >
                <Divider style={{marginBottom: theme.spacing(2)}}/>
                {filteredResponses
                    .map((response: ApiItemResponse, idx: number) => {
                        const score = takePanel.responseLevels![response.id]
                        // Defaults to strength indicator.
                        let indicatorOrReason = takePanel.responseStrengthIndicators![response.id]

                        if (score === PanelLevel.PANEL_NOT_RATABLE || score === PanelLevel.PANEL_LEVEL_0) {
                            // Change to reason code if response level is 0 or NR.
                            indicatorOrReason = takePanel.responseReasonCodes![response.id]
                        }

                        return (
                            <SidebarResponse
                                key={response.id}
                                response={response}
                                responseNumber={idx + 1}
                                fullName={this.props.fullName}
                                score={score}
                                indicatorOrReason={indicatorOrReason}
                                benchmark={takePanel.responseBenchmarks![response.id]}
                                takePanelId={takePanel.id}
                                panel={takePanel.panel}
                            />
                        )
                    })}
            </List>
        )
    }
}

interface SidebarResponseProps {
    takePanelId: number
    response: ApiItemResponse
    responseNumber: number
    fullName: string
    score?: string,
    indicatorOrReason?: string,
    benchmark?: string,
    panel: ApiPanel
}

@observer
class SidebarResponse extends React.Component<SidebarResponseProps> {

    @observable
    open = false

    disabled = () => {
        const {response} = this.props
        if (response.respondedValue == null || (response.respondedValue.length === 0 && response.itemFormat !== ItemFormatEnum.SPEAKING_ORAL)) {
            return true
        } else {
            return false
        }
    }

    render() {
        const {responseNumber, response, score, indicatorOrReason, benchmark, fullName, takePanelId, panel} = this.props
        return (
            <>
                <ListItem disableGutters={true}>
                    <LinkText
                        disabled={this.disabled()}
                        style={{
                            paddingRight: theme.spacing(2),
                            cursor: "pointer",
                            color: this.disabled() ? undefined : AvantColors.REPORT_LINK_TEXT_BLUE,
                            whiteSpace: "nowrap",
                        }}
                        onClick={() => this.open = true}
                    >
                        Response {responseNumber}
                    </LinkText>
                    {score && <SmallBodyText>Score: {score} {indicatorOrReason} {benchmark}</SmallBodyText>}
                </ListItem>
                <SidebarResponseModal
                    takePanelId={takePanelId}
                    score={score}
                    indicatorOrReason={indicatorOrReason}
                    benchmark={benchmark}
                    response={response}
                    responseNumber={responseNumber}
                    fullName={fullName}
                    panel={panel}
                    isOpen={this.open}
                    close={() => this.open = false}
                />
            </>
        )
    }
}
