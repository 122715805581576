import {AxiosResponse} from "axios"
import ApiService from "../../services/ApiService"
import HelperService from "../../services/HelperService"
import {ApiErrorResponse} from "../../types/types"
import {PanelId, TakeId} from "../../validation/ValidPrimaryKey"
import {Section} from "./models/Section"
import {observable} from "mobx"

export class SectionStore {

    @observable
    textTypes?: string[] = undefined

    @observable
    itemCount?: number = undefined


    findCurrentSectionWithLastItemIndex(
        takeId: TakeId,
        panelId: PanelId,
        lastItemIndex: number,
        randomItemOrder: boolean
    ) {
        return this.findCurrentSection(takeId, panelId, randomItemOrder, lastItemIndex)
    }

    // Ideal world this doesn't return a promise, and everything that consumes it just observers the changes to currentSection. I want to keep our refactoring
    // gradual, though, so not rewriting section to be completely non-redux at this point.
    findCurrentSection(
        takeId: TakeId,
        panelId: PanelId,
        randomItemOrder: boolean = false,
        itemIndex?: number
    ): Promise<Section> {
        let url: string = `${ApiService.API_URL}takes/${takeId}/panels/${panelId}/current-section`
        if (this.textTypes && this.itemCount) {
            url += `?item-content-types=${this.textTypes}&item-count=${this.itemCount}`
        }


        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.post(url)).then(
                (res: AxiosResponse) => {
                    const section: Section = HelperService.processSection(
                        res.data,
                        takeId.value(),
                        panelId.value(),
                        randomItemOrder,
                        ApiService.MEDIA_URL.href
                    )
                    if (itemIndex !== undefined) {
                        section.lastItemIndex = itemIndex
                    }
                    resolve(section)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

}

export const sectionStore = new SectionStore()
