import React from "react"
import FontAwesome from "react-fontawesome"

interface ISpecialCharacters {
    lowercase: string[]
    uppercase: string[]
}

interface IComponentProps {
    contentAreaId: number
    insertCharacter: (character: string) => void
}

interface IComponentState {
    showUppercase: boolean
}

export default class SpecialCharacterKeyboard extends React.Component<IComponentProps, IComponentState> {
    state: IComponentState

    // In confluence search "content area id" to find the ids for these languages.
    // https://twentyideas.atlassian.net/wiki/spaces/AAC/pages/228425806/Content+Area+ID+to+Language
    private specialChars = new Map<number, ISpecialCharacters>([
        [
            2, // Spanish
            {
                lowercase: ["á", "é", "í", "ñ", "ó", "ú", "ü", "¿", "¡", "€", "«", "»"],
                uppercase: ["Á", "É", "Í", "Ñ", "Ó", "Ú", "Ü"]
            }
        ],
        [
            3, // French
            {
                lowercase: ["à", "â", "æ", "ç", "é", "è", "ê", "ë", "ï", "î", "ô", "œ", "ù", "û", "ü", "ÿ", "€", "«", "»"],
                uppercase: ["À", "Â", "Æ", "Ç", "É", "È", "Ê", "Ë", "Ï", "Î", "Ô", "Œ", "Ù", "Û", "Ü", "Ÿ"]
            }
        ],
        [
            4, // German
            {
                lowercase: ["ä", "ö", "ü", "ß", "€", "«", "»", "„", "“", "‚", "‘"],
                uppercase: ["Ä", "Ö", "Ü", "ẞ"]
            }
        ],
        [
            8, // Turkish
            {
                lowercase: ["ç", "ğ", "ö", "ş", "ü", "â", "û", "ı", "î", "æ"],
                uppercase: ["Ç", "Ğ", "Ö", "Ş", "Ü", "Â", "Û", "İ", "Î", "£", "€", "ß", "₺"]
            }
        ],
        [
            12, // Italian
            {
                lowercase: ["à", "è", "é", "ì", "î", "ò", "ó", "ù", "€", "«", "»"],
                uppercase: ["À", "È", "É", "Ì", "Î", "Ò", "Ó", "Ù"]
            }
        ],
        [
            13, // Yoruba
            {
                lowercase: ["á", "à", "é", "è", "ẹ", "ẹ́", "ẹ̀", "í", "ì", "ń", "ǹ", "ó", "ò", "ọ", "ọ́", "ọ̀", "ṣ", "ú", "ù"],
                uppercase: ["Á", "À", "É", "È", "Ẹ", "Ẹ́", "Ẹ̀", "Í", "Ì", "Ń", "Ǹ", "Ó", "Ò", "Ọ", "Ọ́", "Ọ̀", "Ṣ", "Ú", "Ù"]
            }
        ],
        [
            33, // Portuguese
            {
                lowercase: ["ã", "á", "à", "â", "ç", "é", "ê", "í", "õ", "ó", "ô", "ú", "ü", "€", "«", "»"],
                uppercase: ["Ã", "Á", "À", "Â", "Ç", "É", "Ê", "Í", "Õ", "Ó", "Ô", "Ú", "Ü"]
            }
        ],
        [
            39, // Polish
            {
                lowercase: ["ą", "ć", "ę", "ł", "ń", "ó", "ś", "ź", "ż", "€", "„", "“", "«", "»"],
                uppercase: ["Ą", "Ć", "Ę", "Ł", "Ń", "Ó", "Ś", "Ź", "Ż"]
            }
        ],
        [
            95, // Espanol
            {
                lowercase: ["á", "é", "í", "ñ", "ó", "ú", "ü", "¿", "¡", "€", "«", "»"],
                uppercase: ["Á", "É", "Í", "Ñ", "Ó", "Ú", "Ü"]
            }
        ],
        [
            101, // Haitian-Creole
            {
                lowercase: ["à", "è", "ò"],

                uppercase: ["À", "È", "Ò"]
            }
        ],
        [
            103, // Samoan
            {
                lowercase: ["ā", "ē", "ī", "ō", "ū", "'", "ʻ"],
                uppercase: ["Ā", "Ē", "Ī", "Ō", "Ū"]
            }
        ],
        [
            107, // Czech
            {
                lowercase: ["á", "č", "ď", "é", "ě", "í", "ň", "ó", "ř", "š", "ť", "ú", "ů", "ý", "ž", "„", "“"],
                uppercase: ["Á", "Č", "Ď", "É", "Ě", "Í", "Ň", "Ó", "Ř", "Š", "Ť", "Ú", "Ů", "Ý", "Ž"]
            }
        ],
        [
            110, //Marshallese
            {
                lowercase: ["ā", "l̗", "m̗", "n̗", "n̄", "o̗", "ō", "ū"],
                uppercase: ["Ā", "L̗", "M̗", "N̗", "N̄", "O̗", "Ō", "Ū"]
            }
        ],
        [
            111, // Chuukese
            {
                lowercase: ["á", "é", "ó", "ú"],
                uppercase: ["Á", "É", "Ó", "Ú"]
            }
        ],
        [
            113,
            {
                lowercase: ["á", "é", "í", "ñ", "ó", "ú", "ü", "¿", "¡", "€", "«", "»"],
                uppercase: ["Á", "É", "Í", "Ñ", "Ó", "Ú", "Ü"]
            }
        ],
        [
            116, // Hawaiian
            {
                lowercase: ["ā", "ē", "ī", "ō", "ū", "ʻ"],
                uppercase: ["Ā", "Ē", "Ī", "Ō", "Ū"]
            }
        ],
        [
            119, // Spanish Monolingual
            {
                lowercase: ["á", "é", "í", "ñ", "ó", "ú", "ü", "¿", "¡", "€", "«", "»"],
                uppercase: ["Á", "É", "Í", "Ñ", "Ó", "Ú", "Ü"]
            }
        ],
        [
            121, // Cabo Verdean
            {
                lowercase: ["ã", "á", "à", "â", "ç", "é", "ê", "í", "õ", "ó", "ô", "ú", "ü", "€", "«", "»"],
                uppercase: ["Ã", "Á", "À", "Â", "Ç", "É", "Ê", "Í", "Õ", "Ó", "Ô", "Ú", "Ü"]
            }
        ],
    ])

    constructor(props: IComponentProps) {
        super(props)
        this.state = {
            showUppercase: false
        }
    }

    handleShowUppercase = () => {
        this.setState({showUppercase: !this.state.showUppercase})
    }

    render() {
        const specialCharacters: ISpecialCharacters | undefined = this.specialChars.get(this.props.contentAreaId)
        const characters: string[] = this.state.showUppercase
            ? specialCharacters
                ? specialCharacters.uppercase
                : []
            : specialCharacters
                ? specialCharacters.lowercase
                : []

        const icon = this.state.showUppercase ? "arrow-circle-down" : "arrow-circle-up"

        return (
            <>
                {specialCharacters !== undefined && (
                    <>
                        <div className="special-characters__line"/>
                        <div className="special-characters">
                            <FontAwesome
                                name={icon}
                                className="special-characters__icon"
                                onClick={this.handleShowUppercase}
                            />
                            {characters.map((character: string, index: number) => {
                                let characterClass = "special-characters__character"
                                if (index === characters.length - 1) {
                                    characterClass = `${characterClass} special-characters__character--last`
                                }
                                return (
                                    <div
                                        key={Math.random()}
                                        className={characterClass}
                                        onClick={() => this.props.insertCharacter(character)}
                                    >
                                        {character}
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )}
            </>
        )
    }
}
