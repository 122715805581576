import React, {Dispatch, FormEvent, useEffect, useState} from "react"
import {Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@material-ui/core"
import {ApiLogin, TEvent} from "../../../types/types"
import ApiService from "../../../services/ApiService"
import {messageStore} from "../../../app/common/messages/MessageStore"

const searchLoginByUsername = async (userName: string, updateLoginData: Dispatch<any>): Promise<void> => {
    const logins: ApiLogin[] = await ApiService.searchLoginByUsernameAndUserType(userName, "S")

    // We only want to return a single test code for now so throw an error if the search returns no logins or multiple.
    // TODO: Come up with a good way to handle multiple logins
    if (logins.length === 1) {
        updateLoginData(logins[0])
    } else {
        messageStore.setErrorMessage(`TestCode: ${userName} not found`)
    }
}

interface TestCodeSearchProps {
    testCode: string
    updateTestCode: Dispatch<any>
    updateLoginData: Dispatch<any>
}

const TestCodeSearch: React.FC<TestCodeSearchProps> = ({testCode, updateLoginData, updateTestCode}) => (
    <Grid item={true} xs={12}>
        <form
            onSubmit={(event: FormEvent<HTMLFormElement>) => {
                event.preventDefault()
                searchLoginByUsername(testCode, updateLoginData)
            }}
        >
            <TextField
                id={"test-code-id"}
                name={"Test Code"}
                value={testCode}
                placeholder={"Enter Test Code..."}
                onChange={(e: TEvent) => updateTestCode(e.target.value)}
            />
            <Button
                type={"submit"}
                variant={"contained"}
                color={"primary"}
                style={{marginLeft: "2rem"}}
                disabled={testCode ==="" && true}
            >
                Search Test Code
            </Button>
        </form>
    </Grid>
)

interface TestCodeDataTableProps {
    loginData: ApiLogin
}
// TODO: Update this to show more information
const TestCodeDataTable: React.FC<TestCodeDataTableProps> = ({loginData}) => {
    const loginLink = `https://avant.assessment.com/proctored/${loginData.id}`

    return (
        <Grid item={true} xs={12}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell key={"testCode"}>
                            Test Code
                        </TableCell>
                        <TableCell key={"loginLink"}>
                            Login Link
                        </TableCell>
                        {loginData.schedulingLink &&
                            <TableCell key={"schedulingLink"}>
                                Scheduling Link
                            </TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            {loginData.username}
                        </TableCell>
                        <TableCell>
                            {loginLink}
                        </TableCell>
                        {loginData.schedulingLink &&
                            <TableCell>
                                {loginData.schedulingLink}
                            </TableCell>
                        }
                    </TableRow>
                </TableBody>
            </Table>
        </Grid>
    )
}

const saveSchedulingLink = (
    event: FormEvent<HTMLFormElement>,
    loginId: number,
    testCode: string,
    schedulingLink: string,
    updateLoginData: Dispatch<any>
) => {
    event.preventDefault()

    ApiService.submitProctor360SchedulingLink(loginId, schedulingLink).then(() => {
        messageStore.setInfoMessage(`Updated Testcode: ${testCode}`)
        searchLoginByUsername(testCode, updateLoginData)
    })
}

interface SchedulingLinkFieldProps {
    loginId: number
    testCode: string
    updateLoginData: Dispatch<any>
}

const SchedulingLinkField: React.FC<SchedulingLinkFieldProps> = ({loginId, testCode, updateLoginData}) => {
    const [schedulingLink, updateSchedulingLink] = useState("")

    return (
        <Grid item={true} xs={12}>
            <form onSubmit={(e: FormEvent<HTMLFormElement>) => saveSchedulingLink(e, loginId, testCode, schedulingLink, updateLoginData)}>
                <TextField
                    id={"scheduling-link-id"}
                    name={"Scheduling Link"}
                    value={schedulingLink}
                    placeholder={"Enter Scheduling Link..."}
                    onChange={(e: TEvent) => updateSchedulingLink(e.target.value)}
                    style={{width: 500}}
                />
                <Button
                    type={"submit"}
                    variant={"contained"}
                    color={"primary"}
                    style={{marginLeft: "2rem"}}
                    disabled={(schedulingLink === "" || testCode === "") && true}
                >
                   Save Scheduling Link
                </Button>
            </form>
        </Grid>
    )
}

export const Proctor360Setup: React.FC = () => {
    const [testCode, updateTestCode] = useState("")
    const [loginData, updateLoginData] = useState<ApiLogin>()
    const [iframeUrl, updateIframeUrl] = useState("")

    useEffect(() => {
        ApiService.getProctor360Url().then((response: string) => {
            updateIframeUrl(response)
        })
    }, [])

    return (
        <Grid container={true} spacing={2}>
            <TestCodeSearch
                testCode={testCode}
                updateLoginData={updateLoginData}
                updateTestCode={updateTestCode}
            />
            {loginData &&
                <>
                    <TestCodeDataTable loginData={loginData}/>
                    <SchedulingLinkField loginId={loginData.id} testCode={testCode} updateLoginData={updateLoginData}/>
                </>
            }
            <Grid item={true} xs={12}>
                {iframeUrl ?
                    // TODO: Adjust the iframe height when we get the proctor 360 url to fit the setup form properly
                    <iframe src={iframeUrl} frameBorder={"1"} width={"100%"} height={"500"}/> :
                    <div className={"loader"}/>
                }
            </Grid>
        </Grid>
    )
}
