/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/
import MaterialTable, {Column} from "material-table"
import * as React from "react"
import {useRef, useState} from "react"
import {messageStore} from "../../../app/common/messages/MessageStore"
import ApiService from "../../../services/ApiService"
import {CONTENT_AREA_ID_TO_LANGUAGE_NAME} from "../../../util/Constants"

function buildSort(orderByField: string | unknown, orderDirection: string): string {
    let sortString = ""
    if (orderByField) {
        sortString = `&sort=${orderByField},${orderDirection}`
    }
    return sortString
}

function buildPredicate(filters: any): string {
    const predicateArray: string[] = []
    filters.forEach((filter: any) => {
        if (filter.column.field === "schoolGroup") {
            predicateArray.push(`schoolGroup=contains(${filter.value})`)
        }
        if (filter.column.field === "name") {
            predicateArray.push(`name=contains(${filter.value})`)
        }
        if (filter.column.field === "districtSsoId") {
            predicateArray.push(`districtSsoId=contains(${filter.value})`)
        }
        if (filter.column.field === "pkg") {
            predicateArray.push(`pkg=contains(${filter.value})`)
        }

        if (filter.column.field === "enableHandwritten") {
            const value = (filter.value === "checked")
            predicateArray.push(`enableHandwritten=eq(${value})`)
        }


        if (filter.column.field === "contentAreaId") {
            // Filter languages based on what the user is typing in the language field.
            const contentAreaIds: string[] = []

            // Language in the foreach is an array containing a contentareaid and it's language name.
            Object.entries(CONTENT_AREA_ID_TO_LANGUAGE_NAME).forEach((language: [string, string]) => {
                if (language[1].toLowerCase().includes(filter.value.toLowerCase())) {
                    contentAreaIds.push(language[0])
                }
            })

            contentAreaIds.forEach((contentAreaId: string) => predicateArray.push(`contentareaid=${contentAreaId}`))
        }

    })
    if (predicateArray.length === 0) {
        return ""
    }
    return `&${predicateArray.join("&")}`
}

async function fetchCustomClassMapData(query: any) {
    const sort = (query.orderBy) ? buildSort(query.orderBy.field, query.orderDirection) : ""
    const predicate = (query.filters) ? buildPredicate(query.filters) : ""

    const response = await ApiService.get(`${ApiService.API_URL}rest/customClassMaps?page=${query.page}&size=${query.pageSize}${sort}${predicate}`)
    const classMaps = response.data._embedded.customClassMaps
    return {
        data: classMaps,
        page: response.data.page.number,
        totalCount: response.data.page.totalElements
    }
}

export const CustomSectionMapAdminBase: React.FC = () => {
    const tableRef = React.createRef()
    const isCanceled = useRef(false)
    const initialColumns: Column<any>[] = [
        {
            title: "District SSO Id",
            field: "districtSsoId",
            hidden: false,
            sorting: true,
            searchable: true,
            removable: true,
            editable: "always",
            defaultSort: "asc",
        },
        {
            title: "School Group",
            field: "schoolGroup",
            hidden: false,
            sorting: true,
            filtering: true,
            searchable: true,
            removable: true,
            editable: "always",
        },
        {
            title: "Import Name",
            field: "name",
            hidden: false,
            sorting: true,
            searchable: true,
            removable: true,
            editable: "always",
        },
        {
            title: "Configuration",
            field: "pkg",
            removable: true,
            editable: "always"
        },
        {
            title: "Content Area Id",
            field: "contentAreaId",
            removable: true,
            editable: "always"
        },
        {
            title: "Ad-Hoc Testing",
            field: "addHocTestingGroup",
            sorting: false,
            searchable: false,
            removable: true,
            type: "boolean",
            editable: "always",
        },
        {
            title: "Enable Handwritten",
            field: "enableHandwritten",
            sorting: false,
            searchable: false,
            removable: true,
            type: "boolean",
            editable: "always",
        },
    ]
    const [columns] = useState(initialColumns)

    const addEntityRow = (newData: any): Promise<void> => {
        return new Promise((resolve, reject) => {
            ApiService.post(`${ApiService.API_URL}rest/customClassMaps`, newData)
                .then(_ => {
                    if (!isCanceled.current) { resolve() }
                })
                .catch(e => {
                    if (!isCanceled.current) {messageStore.setErrorMessage(e.toString())}
                    reject()
                })
        })
    }
    const deleteRow = (dataToDelete: any): Promise<void> => {
        return new Promise((resolve, reject) => {
            ApiService.delete(dataToDelete._links.self.href, dataToDelete)
                .then(_ => {
                    if (!isCanceled.current) { resolve() }
                })
                .catch(e => {
                    if (!isCanceled.current) {messageStore.setErrorMessage(e.toString())}
                    reject()
                })
        })
    }
    const patchRow = (patchLink: string , data: {}) => {
        return new Promise((resolve, reject) => {
            ApiService.patch(patchLink, data)
                .then(_ => {
                    if (!isCanceled.current) {
                        // @ts-ignore
                        if (tableRef.current) {tableRef.current.onQueryChange()}
                        messageStore.setInfoMessage("Updated Row")
                    }
                })
                .catch(e => {
                    if (!isCanceled.current) {
                        messageStore.setErrorMessage(e.toString())
                    }
                    reject()
                })
        })
    }

    return (
        <>
            <MaterialTable
                tableRef={tableRef}
                title="Custom Section Map"
                columns={columns}
                data={fetchCustomClassMapData}
                editable={{
                    onRowAdd: addEntityRow,
                    onRowDelete: deleteRow,
                }}
                cellEditable={{
                    onCellEditApproved: (newValue: any, oldValue: any, rowData: any, columnDef: any) => {
                        return new Promise((resolve, reject) => {
                            if (!isCanceled.current) {
                                const updateField = {}
                                updateField[columnDef.field] = newValue
                                patchRow(rowData._links.self.href, updateField)
                                    .then(_ => {resolve()})
                                    .catch(e => {
                                        if (!isCanceled.current) {
                                            messageStore.setErrorMessage(e.toString())
                                        }
                                        reject()
                                    })
                            }

                        })
                    }
                }}
                options={{
                    filtering: true,
                    addRowPosition: "first",
                    search: true,
                    showTitle: true,
                    toolbar: true,
                    padding: "dense",
                    pageSize: 100,
                    pageSizeOptions: [100, 200, 500, 1000],
                    exportButton: true,
                    columnsButton: true,
                    debounceInterval: 500,
                    exportFileName: "CustomSectionMap",
                    tableLayout: "auto",
                }}
                actions={[
                    {icon: "", hidden: true , onClick: _ => {/*Dummy so action column show so columns don't slide when adding*/}}
                ]}
            />
        </>
    )
}

export const CustomSectionMapAdmin = React.memo(CustomSectionMapAdminBase)
