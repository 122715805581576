import {AxiosError, AxiosResponse} from "axios"
import {groupBy} from "lodash"
import {computed, observable} from "mobx"
import moment from "moment"
import {avantHistory} from "../../../components/App/App"
import ApiService from "../../../services/ApiService"
import {ApiErrorResponse} from "../../../types/types"
import {log, prettyJson} from "../../../util/Logging"
import {ReportUtil} from "../../../util/ReportUtil"
import {ReportSubPaths} from "../../reports/ReportSubPaths"
import {GroupMaster} from "../group/models/GroupMaster"
import {messageStore} from "../messages/MessageStore"
import {TakeSearchQuery} from "./models/TakeSearchQuery"
import {TakeSearchResult} from "./models/TakeSearchResult"

export interface TakeSearchResultsByLanguage {
    [key: string]: TakeSearchResult[]
}

export interface ApiTakeSearchResult {
    takeId: number
    code: string
    testCode: string
    contentAreaId: number
    productId: number
    created: Date
    isHandWritten: boolean
    oppCode: string
    oppNum: string
    groups: GroupMaster[]
    firstName?: string
    lastName?: string
    durationMinutes?: number
    takePanels: ApiTakePanelSearchResult[]
    resets: []
    profile: Map<string, string>
    dateBillable?: number
    proctorType?: string
    earnedBadgeId?: string
    testPackage?: string
}

export interface ApiTakePanelSearchResult {
    takePanelId: number
    panelId: number
    level?: string
    skill: string
    panelName: string
    startTime: string
    finishTime?: string
    scaledScore?: number
    selfEvaluated?: boolean
    itemsTakenCount?: number
    itemsAccurateCount?: number
    percentCorrect?: number
    benchmark?: string
}

export interface Reset {
    contentAreaId?: number
    groups?: number[]
    loginId: number
    panelId?: number
    productId: number
    resetAction: string
    resetComment?: string
    resetDate: number
    resetterUsername: string
    resumeKey?: string
    takeId?: number
    testTakerId?: number
}

export interface ResetSummary {
    reset: ResetActionSummary,
    retake: ResetActionSummary
}

export interface ResetActionSummary {
    count: number,
    lastResetDate: number | null,
    daysSinceLastReset: number | null
}

export class TakeSearchStore {

    @observable
    numInstancesLoaded = 0

    @observable
    instances: TakeSearchResult[] = []

    @observable
    downloadComplete: boolean = false

    @observable
    downloadInProgress = false

    @observable
    groupIdsWithData: Set<number> = new Set()

    @observable
    sideBarInfo?: TakeSearchResult

    private startTime?: Date

    adminSearchTestInstances = (qry: TakeSearchQuery): Promise<ApiTakeSearchResult[]> => {
        const qString = this.queryStringFromQuery(qry)
        const url: string = `${ApiService.API_URL}takes/search${qString}`
        return ApiService.addInteractionBlockingRequest(
            ApiService.get(url)
                .then((res: AxiosResponse) => {
                    return res.data
                })
                .catch((err: ApiErrorResponse) => {
                    throw err
                })
        )
    }

    getTestInstanceReport = async (qry: TakeSearchQuery): Promise<Blob> => {
        const qString = this.queryStringFromQuery(qry)
        const url = `${ApiService.API_URL}takes/search/admin-report${qString}`
        return ApiService.addInteractionBlockingRequest(
            ApiService.get(url)
                .then((res: AxiosResponse) => {
                    const bin = atob(res.data.report)
                    const ab = this.s2ab(bin)
                    return new Blob([ab],
                        {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"}
                    )
                })
        )
    }

    findSidebarInfoForTake = (takeId: number) => {
        this.sideBarInfo = undefined
        ApiService.addInteractionBlockingRequest(
            ApiService.get(`${ApiService.API_URL}takes/sidebar-info/${takeId}`, true).then(
                (response: AxiosResponse<TakeSearchResult>) => {
                    // log.debug(`Found sidebar info with takeid ${takeId} info: ${prettyJson(response.data)}`)
                    this.sideBarInfo = response.data
                }
            )
        )
    }

    getCsvReportRows = (): string[] => {
        if (!this.downloadComplete) {
            return []
        }
        const columns = this.instances.map((take) => ReportUtil.takeReportColumns(take))
        if (columns.length === 0) {
            return []
        }
        const headers = columns[0].map((row) => row.header).join(",")
        const rows = [headers]
        columns
            .map((row) => row.map((r) => r.val).join(","))
            .forEach((row) => rows.push(row))
        return rows
    }

    searchInstances = (query: TakeSearchQuery): void => {
        this.instances = []
        this.downloadComplete = false
        this.downloadInProgress = true
        log.debug(`Search for instances with query ${prettyJson(query)}`)
        this.startTime = new Date()

        this.searchMoreInstances(query, 0)
    }

    private searchMoreInstances = (query: TakeSearchQuery, page: number) => {
        const pageNWeeksAhead = moment(query.fromDate!!).add(page, "weeks")
        const oneWeekAfterPage = moment.min(moment(query!!.fromDate!!).add(page!! + 1, "weeks"), query.toDate!!)
        const pagedDateRange = {...query}
        pagedDateRange.fromDate = pageNWeeksAhead
        pagedDateRange.toDate = oneWeekAfterPage
        if (query.toDate && query.toDate!!.isBefore(pagedDateRange.fromDate!!)) {
            if (!this.downloadComplete) {
                const groupIdsWithData: Set<number> = new Set()
                this.instances.forEach(instance => {
                    instance.groups.forEach(group => groupIdsWithData.add(group.id))
                })
                this.groupIdsWithData = groupIdsWithData
                const ms = new Date().getTime() - this.startTime!!.getTime()
                log.debug(`Took ${ms}ms to calculate groups with ids.`)
                log.debug("take download complete. Takes quantity " + this.instances.length)
            }
            this.downloadComplete = true
            this.downloadInProgress = false
            return
        }

        const qString = this.queryStringFromQuery(pagedDateRange)
        const url = `${ApiService.API_URL}takes/search/${qString}`
        ApiService.get(url)
            .then((res: AxiosResponse) => {
                    const newTakes = res.data as TakeSearchResult[]
                    this.instances = this.instances.concat(newTakes)
                    log.debug("take download continuing. Takes quantity " + newTakes.length)
                    this.searchMoreInstances(query, page + 1)
                },
                (err: AxiosError) => {
                    this.downloadComplete = true
                    if (err.response!.status === 401) {
                        avantHistory.push(ReportSubPaths.REPORTS_REDIRECT)
                        messageStore.setErrorMessage("You don't have access to view this report. Redirecting you to your default report.")
                    } else {
                        messageStore.setErrorMessage("Sorry, there was an error downloading test results.")
                    }
                })
    }

    @computed
    get instancesByLanguage(): TakeSearchResultsByLanguage {
        const instances = this.instances
        if (instances == null) {
            return {}
        }
        return groupBy(this.instances, (instance: TakeSearchResult) => instance.language)
    }

    // https://stackoverflow.com/questions/34993292/how-to-save-xlsx-data-to-file-as-a-blob
    private s2ab = (s: string) => {
        const buf = new ArrayBuffer(s.length)
        const view = new Uint8Array(buf)
        for (let i = 0; i !== s.length; ++i) {
            // tslint:disable-next-line:no-bitwise
            view[i] = s.charCodeAt(i) & 0xFF
        }
        return buf
    }


    private queryStringFromQuery = (qry: TakeSearchQuery) => {

        const {
            productId,
            contentAreaId,
            testCode,
            loginName,
            fromDate,
            toDate,
            billableOnly,
            groupId,
            isHandwritten,
            proctorType,
            searchFromFinishTime,
            searchByTakePanelTimes,
            includeHeaders,
            testPackage,
        } = qry

        const params: string[] = []

        if (productId) {
            params.push(`productId=${productId}`)
        }

        if (contentAreaId) {
            params.push(`contentAreaId=${contentAreaId}`)
        }

        if (testCode) {
            params.push(`testCode=${testCode}`)
        }

        if (loginName) {
            params.push(`loginName=${loginName}`)
        }

        if (fromDate) {
            params.push(`fromUnixTimestamp=${fromDate.unix()}`)
        }

        if (toDate) {
            params.push(`toUnixTimestamp=${toDate.unix()}`)
        }

        if (isHandwritten) {
            params.push(`isHandwritten=${isHandwritten}`)
        }

        if (proctorType) {
            params.push(`proctorType=${proctorType}`)
        }

        if (groupId) {
            params.push(`groupId=${groupId}`)
        }

        if (billableOnly) {
            params.push(`billableOnly=${billableOnly}`)
        }

        if (searchFromFinishTime !== undefined) {
            params.push(`searchFromFinishTime=${searchFromFinishTime}`)
        }
        if (searchByTakePanelTimes !== undefined) {
            params.push(`searchByTakePanelTimes=${searchByTakePanelTimes}`)
        }

        if (includeHeaders) {
            params.push(`includeHeaders=${includeHeaders}`)
        }

        if(testPackage) {
            params.push(`testPackage=${testPackage}`)
        }

        // TODO: Notice the .rep[lace for +, add more replaces if more chars needed
        const datums = params.length > 0 ? `?${
            params.join("&")
                .replace(/%/g, "%25")
                .replace(/\+/g, "%2B")
                .replace(/#/g, "%23")
                .replace(/!/g, "%21")
                .replace(/\$/g, "%24")
                .replace(/'/g, "%27")
                .replace(/\(/g, "%28")
                .replace(/\)/g, "%29")
                .replace(/\*/g, "%2A")
                .replace(/,/g, "%2C")
                .replace(/\//g, "%2F")
                .replace(/:/g, "%3A")
                .replace(/;/g, "%3B")
                .replace(/\{/g, "%7B")
                .replace(/}/g, "%7D")
                .replace(/`/g, "%60")
                .replace(/\|/g, "%7C")
                .replace(/\^/g, "%5E")
        }` : ""

        return datums
    }
}

export const takeSearchStore = new TakeSearchStore()

