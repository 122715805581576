/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {createStyles, Container, List, ListItem, makeStyles, Box} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {AvantColors} from "../../../../styles/AvantColors"
import {BodyText, H1Text, H3Text, H5Text} from "../../../../styles/AvantTypography"
import {theme} from "../../../../styles/MuiThemes"
import {PanelLevel, PanelSkillOrder} from "../../../../types/types"
import {StatusSymbols} from "../../../../util/Constants"
import {AvantCircularProgress} from "../../../common/loaders/AvantCircularProgress"
import {TakePanelSkillReport} from "../../../common/take-panel/models/TakePanelSkillReport"
import {takePanelStore} from "../../../common/take-panel/TakePanelStore"
import {ApiTakePanelSearchResult} from "../../../common/take/TakeSearchStore"
import {StampSkillTriangle} from "./graphics/SkillTriangle/StampSkillTriangle"
import {STAMP_CEFR_PRODUCT_ID} from "../../../../util/Constants"
import { productStore } from "../../../common/products/ProductStore"

const useStyles = makeStyles(() =>
    createStyles({
        header: {
            textAlign: "center",
            width: "calc(100% + 4rem)",
            backgroundColor: AvantColors.REPORT_BACKGROUND_GRAY_DARK,
            letterSpacing: "2px",
            padding: theme.spacing(1),
            borderRadius: "5px",
            transform: "translateX(-2rem)"
        },
        h5: {
            color: "#000",
            fontWeight: 700,
            textAlign: "left",
            paddingLeft: "2rem"
        },
        section: {
            display: "grid",
            gridTemplateColumns: "240px 4fr 5fr",
            "& > *": {
                borderRight: `solid ${AvantColors.REPORT_BACKGROUND_GRAY_DARK} 2px`
            }
        },
        triangle_Container: {
            width: "100%",
            border: "1px solid white",
            background: AvantColors.REPORT_FONT_COLOR_WHITE
        }
    })
)

interface TakePanelSkillReportProps {
    takePanels: ApiTakePanelSearchResult[],
    handwritten: boolean,
    takeId: number
}

export const TakePanelSkillReportView: React.FC<TakePanelSkillReportProps> = ({takePanels, takeId}) => {
    const sectionStyles = useStyles().section

    const [reports, setReports] = useState<TakePanelSkillReport[]>()

    useEffect(() => {
        takePanelStore
            .getTakeSkillReport(takeId)
            .then(r => setReports(r))
    }, [])
    if (!reports) {
        return (<AvantCircularProgress/>)
    }
    const sortPanelsBySkill = (): ApiTakePanelSearchResult[] => {
        const orderedSkills: ApiTakePanelSearchResult[] = []

        PanelSkillOrder.forEach(skill => {
            const skillPanel = takePanels.find((tp) => {
                return tp.skill === skill
            })
            if (skillPanel != null) {
                orderedSkills.push(skillPanel)
            } else {
                orderedSkills.push({takePanelId: 0, skill, startTime: "", panelName: "", panelId: 0})
            }
        })
        return orderedSkills
    }

    return (
        <Box style={{color: AvantColors.REPORT_FONT_COLOR_GRAY_3, margin: theme.spacing(1, 2)}}>
            <Box className={sectionStyles}>
                <Box/>
            </Box>
            {sortPanelsBySkill().map((panel: ApiTakePanelSearchResult) => {
                const report = reports[panel.skill]

                // Early return in case of an empty report.
                if (typeof(report) === 'undefined') {
                    return '';
                }

                return (
                    <TakePanelSingleSkill
                        {...report}
                        key={`${panel.takePanelId}-${panel.skill}`}
                    />
                )
            })}
        </Box>
    )
}


const TakePanelSingleSkill: React.FC<TakePanelSkillReport> = (report: TakePanelSkillReport) => {
    const isCEFR = productStore.loginProduct && productStore.loginProduct.productId === STAMP_CEFR_PRODUCT_ID.value()
    const classes = useStyles()

    const {skill, level, highLevel, levelDescriptions, powerUpSuggestions, benchmark, allLevels, selfEvaluation} = report

    // Chaining reverse() and filter() was causing a rare issue where levels wouldn't actually be reversed...
    const filteredLevels = allLevels.filter(level => level !== PanelLevel.PANEL_NOT_RATABLE && level !== PanelLevel.PANEL_LEVEL_0)
    const reportableLevels = filteredLevels.reverse()

    // The function below is only used to convert numbers properly for the skill triangles
    const getNumberLevelFromString = (val: string) : number => {
        if (val == "6+") {
            return 7
        }

        return !isNaN(Number(val)) && val !== StatusSymbols.SECTION_IN_ERROR ? Number(val) : 0
    }

    const selfEvaluationBox = (level: string) => {
        return <Container>
            <Container style={{textAlign: 'center', lineHeight: '3em', marginTop: '1.5em'}}>Self Evaluation</Container>
            <H1Text style={{textAlign: 'center', lineHeight: '2em', marginBottom: '2.5em'}}>{level}</H1Text>
        </Container>
    }

    return (
        <Box>
            <Box className={`${classes.section} ${classes.header}`}>
                <Box>
                    <H3Text textTransform={"uppercase"}>
                        {skill}{selfEvaluation === true ? ' - SE' : ''}
                    </H3Text>
                </Box>
                <Box>
                    <H5Text className={classes.h5}>
                        Level Description
                    </H5Text>
                </Box>
                <Box>
                    <H5Text className={classes.h5}>
                        Suggestions
                    </H5Text>
                </Box>
            </Box>
            <Box className={classes.section}>
                {selfEvaluation === true
                ? <Container style={{border: '1px solid black', margin: '3px 0'}}>
                    { selfEvaluationBox(level) }
                </Container>
                : <Box>
                    <Box style={{display: "flex", alignItems: "center"}}>
                        <H1Text
                            style={{
                                paddingRight: theme.spacing(2)
                            }}
                        >
                            {
                             isCEFR ? benchmark :   (Object.values(StatusSymbols).includes(level as StatusSymbols)) ? "" : level
                            }
                        </H1Text>
                        <H5Text fontWeight={500} style={{paddingTop: 2}}>
                        {isCEFR ? '' : benchmark}
                        </H5Text>
                    </Box>
                    <Box className={classes.triangle_Container}>
                        <StampSkillTriangle
                            numberOfLevels={reportableLevels.length}
                            avantMaxLevel={getNumberLevelFromString(highLevel)}
                            avantLevel={getNumberLevelFromString(level)}
                            arrayOfLevels={reportableLevels}
                            skill={skill}
                        />
                    </Box>
                </Box>
                }
                <Box style={{backgroundColor: AvantColors.REPORT_BACKGROUND_GRAY_MED}}>
                    <Box>
                        {levelDescriptions && level !== StatusSymbols.SECTION_IN_ERROR ? (
                            <>
                                <BodyText style={{paddingLeft: theme.spacing(1), paddingTop: theme.spacing(1)}}>
                                    {
                                        (Object.values(StatusSymbols).includes(level as StatusSymbols) || level === "" || level === PanelLevel.PANEL_LEVEL_0)
                                            ? ""
                                            : "At this level, learners are able to:"
                                    }
                                </BodyText>
                                <UnorderedListOfItems listItems={levelDescriptions[skill]} level={level}/>
                            </>
                        ) : (
                            <BodyText
                                style={{
                                    textAlign: "center",
                                    whiteSpace: "pre-line",
                                    padding: theme.spacing(2),
                                    color: AvantColors.ERROR_TEXT
                                }}>
                                {levelDescriptions[skill]}
                            </BodyText>
                        )}
                    </Box>
                </Box>
                <Box style={{backgroundColor: AvantColors.REPORT_BACKGROUND_GRAY_LIGHT}}>
                    <Box>
                        {powerUpSuggestions &&
                        <>
                            <BodyText style={{paddingLeft: theme.spacing(1), paddingTop: theme.spacing(1)}}>
                                {
                                    (Object.values(StatusSymbols).includes(level as StatusSymbols) || level === "")
                                        ? ""
                                        : "Suggestions to ‘Power Up’ to the next level:"}
                            </BodyText>
                            <UnorderedListOfItems listItems={powerUpSuggestions[skill]}/>
                        </>
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )

}

interface UnorderedListOfItemsProps {
    header?: string
    listItems?: string[]
    level?: string
}

const UnorderedListOfItems: React.FC<UnorderedListOfItemsProps> = ({listItems, level}) => {
    if (listItems) {
        if (listItems.length > 1) {
            if (level === PanelLevel.PANEL_NOT_RATABLE || level === PanelLevel.PANEL_LEVEL_0) {
                return (
                    <>
                        {listItems.map((item: string, i: number) => (
                            <BodyText key={i} style={{
                                padding: theme.spacing(1),
                                fontStyle: level === PanelLevel.PANEL_NOT_RATABLE ? "italic" : "normal",
                            }}>
                                {item}
                            </BodyText>
                        ))}
                    </>
                )
            }

            // Normal Panel Levels format
            return (
                <List>
                    {listItems.map((item: string, i: number) => {
                        if (item.includes("*For Latin:")) {
                            const textArray = item.split(":")

                            return (
                                <ListItem key={i} dense={true}>
                                    <BodyText
                                        style={{
                                            display: "list-item",
                                            listStyleType: "disc",
                                            marginLeft: theme.spacing(1),
                                            color: AvantColors.REPORT_FONT_COLOR_GRAY_2,
                                            fontSize: 16,
                                            lineHeight: "20px"
                                        }}
                                    >
                                        <Box component={"span"} fontWeight={"bold"}>{`${textArray[0]}:`} </Box>{textArray[1]}
                                    </BodyText>
                                </ListItem>
                            )
                        }

                        return (
                            <ListItem key={i} dense={true}>
                                <BodyText
                                    style={{
                                        display: "list-item",
                                        listStyleType: "disc",
                                        marginLeft: theme.spacing(1),
                                        color: AvantColors.REPORT_FONT_COLOR_GRAY_2,
                                        fontSize: 16,
                                        lineHeight: "20px"
                                    }}
                                >
                                    {item}
                                </BodyText>
                            </ListItem>
                        )
                    })}
                </List>
            )
        }

        return (
            <BodyText style={{
                fontStyle: "italic",
                padding: theme.spacing(1)
            }}>
                {listItems[0] ? listItems[0] : ""}
            </BodyText>
        )
    }

    return null
}
