import {observer} from "mobx-react"
import moment from "moment"
import React from "react"
import {Alert, Card, Col, Container, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"
import {isNullOrUndefined} from "util"
import {productStore} from "../../app/common/products/ProductStore"
import {postProfileSuccess} from "../../redux/app/actions"
import ApiService from "../../services/ApiService"
import HelperService from "../../services/HelperService"
import {ApiPanelGraph, LastPage, Profile, State, Take, TestState} from "../../types/types"
import {CONTENT_AREA_ID_TO_LANGUAGE_NAME, SUPPORT_MESSAGE} from "../../util/Constants"
import {TakeId} from "../../validation/ValidPrimaryKey"
import {addTakeDispatches, TakeDispatches} from "../App/App"
import Button from "../Button/Button"
import {authStore} from "../../app/common/authentication/AuthStore"

interface IFormErrors {
    firstName: string[]
    lastName: string[]
    testTakerId: string[]
    grade: string[]
    confirmTestTakerId: string[]
}

interface IStateToProps {
    take: Take | null
    takeCode: string
    panelGraph: ApiPanelGraph
}

function mapStateToProps(state: State): IStateToProps {
    return {
        take: state.app.take as Take,
        takeCode: state.app.takeCode,
        panelGraph: state.app.panelGraph!
    }
}

interface IDispatchToProps extends TakeDispatches {
    profileSuccessDispatch: (profile: Profile) => void
}

function mapDispatchToProps(dispatch: Dispatch): IDispatchToProps {
    const dispatchs = {
        profileSuccessDispatch: (profile: Profile) => {
            dispatch(postProfileSuccess(profile))
        }
    }
    return addTakeDispatches(dispatchs, dispatch)
}


export type TComponentProps = IStateToProps & IDispatchToProps & RouteComponentProps<any>

interface IComponentState {
    error: boolean | string
    profile: Profile
    confirmTestTakerId: string
    take: Take | null
    languageName: string
    formErrors: IFormErrors
    formError: string | null
    loaded: boolean
}

@observer
class Stamp4SeProfileFormContainer extends React.Component<TComponentProps, IComponentState> {
    private grades = [
        "",
        "K",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "16+"
    ]


    // This needs to exist outside the state system. This is to determine if the required fields were already filled.
    // If they're not we'll let them fill them.
    private isReadOnly: boolean = false

    constructor(props: TComponentProps) {
        super(props)
        this.state = {
            error: false,
            profile: {
                firstName:
                    props.take && props.take.profile && props.take.profile.firstName
                        ? props.take.profile.firstName
                        : "",
                lastName:
                    props.take && props.take.profile && props.take.profile.lastName ? props.take.profile.lastName : "",
                testTakerId:
                    props.take && props.take.profile && props.take.profile.testTakerId
                        ? props.take.profile.testTakerId
                        : "",
                grade:
                    props.take && props.take.profile && props.take.profile.grade
                        ? props.take.profile.grade
                        : this.grades[0],
                schoolName:
                    props.take && props.take.profile && props.take.profile.schoolName
                        ? props.take.profile.schoolName
                        : "",
                testGroupName:
                    props.take && props.take.profile && props.take.profile.testGroupName
                        ? props.take.profile.testGroupName
                        : ""
            },
            confirmTestTakerId:
                props.take && props.take.profile && props.take.profile.testTakerId
                    ? props.take.profile.testTakerId
                    : "",
            languageName: "",
            formErrors: {
                firstName: [],
                lastName: [],
                testTakerId: [],
                grade: [],
                confirmTestTakerId: []
            },
            formError: null,
            take: this.props.take,
            loaded: false
        }

        this.isReadOnly = productStore.isReadOnlyProfile(this.state.profile.firstName, this.state.profile.lastName, this.state.profile.testTakerId, this.state.confirmTestTakerId)
    }

    async componentDidMount() {
        HelperService.enableTextSelection()
        await this.getData()

        if (productStore.loginProduct!!.rostered && this.props.take && this.props.take.profile && this.props.take.profile.testTakerId) {
            this.setState({confirmTestTakerId: this.props.take.profile.testTakerId})
        }
    }

    async componentDidUpdate() {
        if (this.props.take !== this.state.take) {
            this.setState({take: this.props.take})
        }
        if (this.props.take && this.props.take.profile && this.props.take.profile !== this.state.profile) {
            this.setState({profile: this.props.take.profile})
        }
        await this.getData()
    }

    getData = async () => {
        const driver = productStore.driver
        const product = productStore.loginProduct
        const {takeCode, panelGraph} = this.props
        const {loaded} = this.state
        if (driver && !loaded) {
            this.setState({loaded: true})
            document.title = `${driver.config.PRODUCT_TITLE} | Profile`
            let lastPage: LastPage | null = HelperService.getLastPageFromLs()
            if (lastPage) {
                const testState: TestState = await driver.helper.refreshTest(this.props, lastPage, product!)
                if (!testState.take) {
                    throw Error("testState.take is null or undefined")
                }
                if (!testState.take.profile) {
                    throw Error("testState.take.profile is null or undefined")
                }
                const languageId: number = this.props.panelGraph.contentAreaId
                const languageName: string = CONTENT_AREA_ID_TO_LANGUAGE_NAME[languageId]

                this.isReadOnly = productStore.isReadOnlyProfile(testState.take.profile.firstName, testState.take.profile.lastName, testState.take.profile.testTakerId, testState.take.profile.testTakerId)
                if (this.isReadOnly) {
                    this.setState({confirmTestTakerId:
                            testState.take && testState.take.profile && testState.take.profile.testTakerId !== undefined
                                ? testState.take.profile.testTakerId
                                : "",}
                    )
                }

                this.setState({take: testState.take, profile: testState.take.profile, languageName})
            } else {
                lastPage = {
                    url: "/stamp4se-profile",
                    takeCode,
                    panelGraphId: panelGraph.id
                }
                localStorage.setItem("lastPage", JSON.stringify(lastPage))
                const languageId: number = this.props.panelGraph.contentAreaId
                const languageName: string = CONTENT_AREA_ID_TO_LANGUAGE_NAME[languageId]
                this.setState({languageName})
            }
        }
    }

    handleFirstNameChange = (e: any) => {
        const profile: Profile = this.state.profile
        profile.firstName = e.target.value
        this.setState({profile})
    }

    handleLastNameChange = (e: any) => {
        const profile: Profile = this.state.profile
        profile.lastName = e.target.value
        this.setState({profile})
    }

    handleTestTakerIdChange = (e: any) => {
        const profile: Profile = this.state.profile
        profile.testTakerId = e.target.value
        this.setState({profile})
    }

    handleConfirmTestTakerIdChange = (e: any) => {
        this.setState({confirmTestTakerId: e.target.value})
    }

    handleGradeChange = (e: any) => {
        const profile: Profile = this.state.profile
        profile.grade = e.target.value
        this.setState({profile})
    }

    validateFirstName = (profile: Profile): string[] => {
        const firstName = profile.firstName
        if (isNullOrUndefined(firstName) || firstName.length === 0) {
            return ["Please enter your first name."]
        }
        return []
    }

    validateLastName = (profile: Profile): string[] => {
        const lastName = profile.lastName
        if (isNullOrUndefined(lastName) || lastName.length === 0) {
            return ["Please enter your last name."]
        }
        return []
    }

    validateTestTakerId = (profile: Profile): string[] => {
        const studentId = profile.testTakerId
        if (isNullOrUndefined(studentId) || studentId.length === 0) {
            return ["Please enter your test taker id."]
        }
        return []
    }

    validateConfirmTestTakerId = (profile: Profile): string[] => {
        const confirmTestTakerId = this.state.confirmTestTakerId
        const testTakerId = profile.testTakerId
        if (isNullOrUndefined(confirmTestTakerId) || confirmTestTakerId.length === 0) {
            return ["Please confirm your test taker id."]
        }
        if (confirmTestTakerId !== testTakerId) {
            return ["Your test taker id and test taker id confirmation must match."]
        }
        return []
    }

    validate = (profile: Profile): { hasErrors: boolean; formErrors: IFormErrors } => {
        const formErrors: IFormErrors = {
            firstName: this.validateFirstName(profile),
            lastName: this.validateLastName(profile),
            testTakerId: this.validateTestTakerId(profile),
            confirmTestTakerId: this.validateConfirmTestTakerId(profile),
            grade: []
        }

        let hasErrors: boolean = false
        Object.keys(formErrors).forEach((key: string) => {
            const value: string[] = formErrors[key]
            if (value && value.length > 0) {
                hasErrors = true
            }
        })

        return {
            hasErrors,
            formErrors
        }
    }

    handleSubmit = () => {
        this.setState({
            formError: null,
            formErrors: {
                firstName: [],
                lastName: [],
                testTakerId: [],
                grade: [],
                confirmTestTakerId: []
            }
        })
        if (this.props.take === null) {
            this.setState({
                error: SUPPORT_MESSAGE
            })
            return
        }

        const errors = this.validate(this.state.profile)
        if (errors.hasErrors) {
            this.setState({
                formError: "There are errors on the form.",
                formErrors: errors.formErrors
            })
        } else {
            const profile = this.state.profile
            if (moment(this.state.profile.dateOfBirth).isSameOrAfter(moment())) {
                profile.dateOfBirth = undefined
            }
            ApiService
                .postProfile(
                    new TakeId(this.props.take.id),
                    profile,
                    productStore.driver!!.config.APP_PRODUCT_NAME.toLowerCase()
                )
                .then(() => {
                    this.props.profileSuccessDispatch(this.state.profile)
                    this.props.history.push("/dashboard")
                })
                .catch(() => {
                    this.setState({
                        error: SUPPORT_MESSAGE
                    })
                })
        }
    }

    renderGeneralInformation = (): JSX.Element => {
        const resumeText =
            "Fill in the Test Code, Password, and Name fields with the EXACT Test Code, " +
            "Password and Name you used to log into the test previously."
        const loginNameText =
            "* Remember to type in your name as it appears above if you need to log in again and resume the test."
        return (
            <div style={{paddingLeft: "3rem"}}>
                <Row>
                    <Col>
                        <FormLabel>If you need to resume the test:</FormLabel>
                        <ol>
                            <li>Go back to the Login Page.</li>
                            <li>{resumeText}</li>
                        </ol>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <FormLabel htmlFor="login-name" style={{display: "inline-block"}}>
                                You entered the following Login Name:
                            </FormLabel>
                            <p style={{display: "inline-block"}} id="login-name" tabIndex={0}>
                                {"\u00A0"} {this.props.takeCode}
                            </p>
                        </div>
                        <p>{loginNameText}</p>
                    </Col>
                </Row>
            </div>
        )
    }

    renderRequiredInformation = (): JSX.Element => {

        return (
            <Row>
                <Col sm={6}>
                    <FormGroup>
                        <FormLabel>First Name*</FormLabel>
                        <div
                            className={
                                this.state.formError && this.state.formErrors.firstName.length > 0
                                    ? "error"
                                    : "error not-visible"
                            }
                        >
                            {this.state.formErrors.firstName.join(" ") || ""}
                        </div>
                        <FormControl
                            type="text"
                            value={this.state.profile.firstName}
                            placeholder=""
                            data-tst-id="first-name"
                            onChange={this.handleFirstNameChange}
                            readOnly={this.isReadOnly}
                        />
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Last Name*</FormLabel>
                        <div
                            className={
                                this.state.formError && this.state.formErrors.lastName.length > 0
                                    ? "error"
                                    : "error not-visible"
                            }
                        >
                            {this.state.formErrors.lastName.join(" ") || ""}
                        </div>
                        <FormControl
                            type="text"
                            value={this.state.profile.lastName}
                            placeholder=""
                            data-tst-id="last-name"
                            onChange={this.handleLastNameChange}
                            readOnly={this.isReadOnly}
                        />
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Grade</FormLabel>
                        <FormControl
                            as="select"
                            placeholder="select"
                            data-tst-id="grade"
                            onChange={this.handleGradeChange}
                            value={this.state.profile.grade}
                            readOnly={this.isReadOnly && this.state.profile.grade != null}
                            disabled={this.isReadOnly && this.state.profile.grade != null}
                        >
                            {this.grades.map((grade: string) => {
                                return (
                                    <option key={grade} value={grade}>
                                        {grade}
                                    </option>
                                )
                            })}
                        </FormControl>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <FormLabel>Test Taker ID*</FormLabel>
                        <div
                            className={
                                this.state.formError && this.state.formErrors.testTakerId.length > 0
                                    ? "error"
                                    : "error not-visible"
                            }
                        >
                            {this.state.formErrors.testTakerId.join(" ") || ""}
                        </div>
                        <FormControl
                            type="text"
                            value={this.state.profile.testTakerId}
                            placeholder=""
                            data-tst-id="student-id"
                            onChange={this.handleTestTakerIdChange}
                            readOnly={this.isReadOnly}
                        />
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Confirm Test Taker ID*</FormLabel>
                        <div
                            className={
                                this.state.formError && this.state.formErrors.confirmTestTakerId.length > 0
                                    ? "error"
                                    : "error not-visible"
                            }
                        >
                            {this.state.formErrors.confirmTestTakerId.join(" ") || ""}
                        </div>
                        <FormControl
                            type="text"
                            value={this.state.confirmTestTakerId}
                            placeholder=""
                            data-tst-id="confirm-student-id"
                            onChange={this.handleConfirmTestTakerIdChange}
                            readOnly={this.isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
        )
    }

    render() {
        return (
            <div className="profile-form-container">
                {this.state.error && (
                    <div className="alerts">
                        <Alert variant="danger" onClose={() => this.setState({error: false})}>
                            {this.state.error}
                        </Alert>
                    </div>
                )}
                <Container>
                    <Row>
                        <Col sm={12}>
                            {this.state.take && (
                                <Card>
                                    <div className="profile-form content-container">
                                        <p className="large" style={{fontWeight: "bold", color: "#000000"}}>
                                            CREATE PROFILE
                                        </p>

                                        <p style={{fontWeight: "bold", marginTop: "8px"}}>*Required</p>
                                        {(authStore.auth && !authStore.auth.ssoId) && this.renderGeneralInformation()}

                                        {this.renderRequiredInformation()}

                                        <Row>
                                            <Col
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                                className="margin-top"
                                            >
                                                <Button
                                                    className="avant-button--default avant-button--primary"
                                                    testId="submit"
                                                    onClick={this.handleSubmit}
                                                >
                                                    SUBMIT
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Stamp4SeProfileFormContainer)
