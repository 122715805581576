import {createStyles, Divider, Drawer, IconButton, makeStyles, Toolbar} from "@material-ui/core"
import {ArrowDropDown, Close} from "@material-ui/icons"
import {observer} from "mobx-react"
import React from "react"
import {AvantColors} from "../../../../../styles/AvantColors"
import {H3Text, H5Text} from "../../../../../styles/AvantTypography"
import {
    P360ExamData,
    PanelBenchmarkConstants,
    PanelLevel,
    PanelSkillEnum,
    PanelSkillOrder
} from "../../../../../types/types"
import {
    PLACE_PRODUCT_ID,
    REPORTS_MAX_MINUTES_TO_DISPLAY,
    STAMP_4SE_PRODUCT_ID,
    STAMP_CEFR_PRODUCT_ID
} from "../../../../../util/Constants"
import {EnumUtil} from "../../../../../util/EnumUtil"
import {TimeUtil} from "../../../../../util/TimeUtil"
import {TakeSearchResult} from "../../../../common/take/models/TakeSearchResult"
import {ApiTakePanelSearchResult, takeSearchStore} from "../../../../common/take/TakeSearchStore"
import {
    AvantBadgeEarlyExit,
    AvantBadgeNotRatable,
    AvantBadgeNotStarted,
    AvantBadgeScoringInProgress,
    AvantBadgeSectionInProgress
} from "../../../../generic-components/AvantBadge"
import {sidebarStore} from "../../stores/SideBarStore"
import {SidebarList, SidebarListItem} from "./SidebarList"
import {SidebarResponses} from "./SidebarResponses"
import ProfileInfo from "./ProfileInfo"
import {takePanelStore} from "../../../../common/take-panel/TakePanelStore"
import {TakePanelSkillReport} from "../../../../common/take-panel/models/TakePanelSkillReport"
import {observable} from "mobx"
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import {productStore} from "../../../../common/products/ProductStore"
import {PLACEIndividualReport} from "../../../PlaceFamily/utils/PlaceReportUtils"
import {findPLACEIndividualReport} from "../../../PlaceFamily/PLACEReportPage"
import {AvantButton} from "../../../../generic-components/AvantButton";
import {messageStore} from "../../../../common/messages/MessageStore";
import LanguageUtils from "../../../../../util/LanguageUtils"
import {cloneDeep} from "lodash"



const useDrawerStyles = makeStyles(() =>
    createStyles({
        paper: {
            width: 320
        }
    })
)

const useExpandedStyles = makeStyles(() =>
    createStyles({
        root: {
            "&$expanded": {
                minHeight: 0
            }
        },
        content: {
            margin: 0,
            "&$expanded": {
                margin: 0
            }
        },
        expanded: {}
    })
)

interface TestTakerSidebarContainerProps {
    takeId: number
    isPlaceC?: boolean
    P360Data?: P360ExamData[]
    isP360: boolean
}

@observer
export class TestTakerSidebarContainer extends React.Component<TestTakerSidebarContainerProps> {

    @observable
    availableSkills: TakePanelSkillReport[] = []

    @observable
    PLACEProfileInfo?: PLACEIndividualReport

    componentDidMount(): void {
        takeSearchStore.findSidebarInfoForTake(this.props.takeId)
        takePanelStore.getTakeSkillReport(this.props.takeId).then(res => this.availableSkills = res)
        if (productStore.loginProduct && productStore.loginProduct.productId === PLACE_PRODUCT_ID.value()) {
            this.PLACEProfileInfo = JSON.parse(findPLACEIndividualReport(this.props.takeId)) as PLACEIndividualReport
        }
    }

    renderSortedPanels = (panel: ApiTakePanelSearchResult, sortedPanels: ApiTakePanelSearchResult[], fullName: string, expandedClasses: Record<"content" | "expanded" | "root", string>) => {
        const info = takeSearchStore.sideBarInfo
        if (info == null) {
            return null
        }
        const panelDisplayName = String(EnumUtil.getEnumKeyByEnumValue(PanelSkillEnum, panel.skill)).replace(/_/g, ' ')
        const { isHandWritten, productId } = info
        const totalTime: string | undefined =
            TimeUtil.deltaTimeInMinutesOrEmptyStr(
                Number(panel.startTime), Number(panel.finishTime),
                REPORTS_MAX_MINUTES_TO_DISPLAY
            )
        let completedDate: string | React.ReactNode = TimeUtil.formatMilliToMonthDayYearLong(Number(panel.finishTime))
        if (!panel.finishTime && panel.skill === PanelSkillEnum.WRITING && isHandWritten) {
            completedDate = ""
        } else if (!panel.finishTime) {
            completedDate = <AvantBadgeSectionInProgress/>
        }

        let startDate: string | React.ReactNode = TimeUtil.formatMilliToMonthDayYearHoursMinutesLong(Number(panel.startTime))
        if (!panel.startTime) {
            startDate = <AvantBadgeNotStarted/>
        }

        const isCEFR = productStore.loginProduct && productStore.loginProduct.productId === STAMP_CEFR_PRODUCT_ID.value()
        let panelLevel: React.ReactNode = isCEFR ? panel.benchmark : panel.level
        let scaledScore: React.ReactNode = panel.scaledScore

        // Prepare accuracy details (Detailed Percentage Score) for display:
        let accuracyDetails = null
        const isGrammarOrReading = (skill: string) => (skill === PanelSkillEnum.GRAMMAR || skill === PanelSkillEnum.READING)
        const isValidAccuracyNumbers = (itemsAccurateCount: any, itemsTakenCount: any, percentCorrect: any) => (itemsAccurateCount || itemsAccurateCount === 0) && itemsTakenCount && (percentCorrect || percentCorrect === 0)
        if (isGrammarOrReading(panel.skill) && panel.finishTime && isValidAccuracyNumbers(panel.itemsAccurateCount, panel.itemsTakenCount, panel.percentCorrect)) {
            accuracyDetails = `${panel.itemsAccurateCount}/${panel.itemsTakenCount} = ${panel.percentCorrect}%`
        }

        // TODO: make it so all panel statuses are calculated in the API  (Maybe create writing panels for handwritten codes on creation?)
        // TODO: Once logic is fixed server side for statuses, make sure to remove conditional logic for individual reports as well
        if (panel.skill === PanelSkillEnum.WRITING && isHandWritten) {
            const isStamp4Se = productId == STAMP_4SE_PRODUCT_ID.value()
            const rdPanel = sortedPanels.find((p: ApiTakePanelSearchResult) => { return p.skill === PanelSkillEnum.READING })
            const wrPanel = sortedPanels.find((p: ApiTakePanelSearchResult) => { return p.skill === PanelSkillEnum.WRITING })

            if (panelLevel === PanelLevel.PANEL_PRINTED) {
                panelLevel = PanelBenchmarkConstants.PANEL_PRINTED
            } else if (panelLevel === PanelLevel.PANEL_STARTED) {
                panelLevel = <AvantBadgeSectionInProgress/>
            } else if (panelLevel === PanelLevel.PANEL_COMPLETE) {
                panelLevel = <AvantBadgeScoringInProgress/>
                if (this.props.isPlaceC) {
                    panelLevel = "Rated By Educator"
                }
            } else if (rdPanel && ((rdPanel.finishTime != null && wrPanel == null)
                || (rdPanel.finishTime != null && wrPanel && wrPanel.finishTime == null && wrPanel.level !== PanelLevel.PANEL_STARTED)
                || isStamp4Se)) {
                panelLevel = PanelBenchmarkConstants.PANEL_PRINTABLE
            } else {
                panelLevel = PanelBenchmarkConstants.PANEL_PENDING
            }
        } else if ((panel.skill === PanelSkillEnum.READING || panel.skill === PanelSkillEnum.LISTENING) && panel.selfEvaluated) {
            panelLevel = "Self Evaluated " + panel.level
        } else if (panel.takePanelId === 0) {
            panelLevel = <AvantBadgeNotStarted/>
            scaledScore = undefined
            completedDate = undefined
            // If SHL and the take is finished then we have an early exit
            if (
                this.PLACEProfileInfo
                && ["shl", "shl-b", "shl-c"].includes(this.PLACEProfileInfo.product_package as string)
                && this.PLACEProfileInfo.finishDate != null
            ) {
                panelLevel = <AvantBadgeEarlyExit/>
            }
        } else if (panelLevel === PanelLevel.PANEL_STARTED) {
            panelLevel = <AvantBadgeSectionInProgress/>
        } else if (panelLevel === PanelLevel.PANEL_COMPLETE) {
            panelLevel = <AvantBadgeScoringInProgress/>
            // check to see if takeid an lead us to product_package which needs to be 'pl-c'
            if (this.props.isPlaceC) {
                panelLevel = "Rated By Educator"
            }
        } else if (panelLevel === PanelLevel.PANEL_NOT_RATABLE) {
            panelLevel = <AvantBadgeNotRatable/>
        }

        return (
            <Accordion key={panel.skill} style={{margin: 0}} defaultExpanded={sidebarStore.openPanel === panel.skill}>
                <AccordionSummary
                    classes={expandedClasses}
                    expandIcon={<ArrowDropDown style={{fill: AvantColors.REPORT_FONT_COLOR_GRAY_3}}/>}
                >
                    <H5Text>
                        {panelDisplayName}
                    </H5Text>
                </AccordionSummary>

                <AccordionDetails style={{flexDirection: "column", padding: 0}}>
                    <Divider/>
                    <SidebarList>
                        <SidebarListItem primary={"Status/Score"} secondary={panelLevel}/>
                        {
                            (
                                (panel.skill === PanelSkillEnum.READING || panel.skill === PanelSkillEnum.LISTENING) &&
                                !panel.selfEvaluated &&
                                <SidebarListItem primary={"Scaled Score:"} secondary={scaledScore}/>
                            )
                        }

                        {
                            productId === PLACE_PRODUCT_ID.value() &&
                            <SidebarListItem primary={"Detailed Percentage Score:"} secondary={accuracyDetails}/>
                        }

                        <SidebarListItem primary={"Section Start Time:"}
                                         secondary={startDate}
                                         isHandwritten={isHandWritten}
                                         panelDisplayName={panelDisplayName}
                        />
                        <SidebarListItem primary={"Total Time in Section:"}
                                         secondary={totalTime}
                                         isHandwritten={isHandWritten}
                                         panelDisplayName={panelDisplayName}
                        />
                        <SidebarListItem primary={"Section Completed Date:"}
                                         secondary={completedDate}
                                         isHandwritten={isHandWritten}
                                         panelDisplayName={panelDisplayName}
                        />
                    </SidebarList>

                    {panel.takePanelId !== 0 && (panel.skill === PanelSkillEnum.WRITING || panel.skill === PanelSkillEnum.SPEAKING || panel.skill === PanelSkillEnum.SPEAKING_ORAL) && (
                        <SidebarResponses takePanelId={panel.takePanelId} fullName={fullName}/>
                    )}

                </AccordionDetails>
            </Accordion>
        )
    }

    renderSelfEval = (expandedClasses: Record<"content" | "expanded" | "root", string>): JSX.Element | null => {
        const info = this.PLACEProfileInfo

        if (info == null) {
            return null
        }

        const selfEvalQuestions = [
            "Interpretive Mode - Listening",
            "Interpretive Mode - Reading",
            "Interpersonal Mode - Speaking",
            "Presentational Mode - Speaking",
            "Presentational Mode - Writing"
        ]

        return (
           <Accordion defaultExpanded={sidebarStore.openSelfEval} style={{margin: 0}}>
               <AccordionSummary
                   classes={expandedClasses}
                   style={{margin: "initial"}}
                   expandIcon={<ArrowDropDown style={{fill: AvantColors.REPORT_FONT_COLOR_GRAY_3}}/>}
               >
                   <H5Text>Self Evaluation</H5Text>
               </AccordionSummary>
               <AccordionDetails style={{padding: 0}}>
                   <SidebarList>
                       {selfEvalQuestions.map((canDo: string, index: number) => {
                           return (
                               <SidebarListItem key={canDo} primary={canDo} secondary={info[`cando_${index + 1}`]}/>
                           )
                       })}

                       <SidebarListItem primary={"Proficiency Self-Evaluation Level"} secondary={info["candoAverage"]} />
                   </SidebarList>
               </AccordionDetails>
           </Accordion>
       )
    }

    render() {
        const info = cloneDeep(takeSearchStore.sideBarInfo)
        if (info == null) {
            return null
        }

        info.language = LanguageUtils.removeTilde(info.language)

        const toSort = [...info.takePanels]
        // We compare the # of takepanels the student has (we only have in-progress or completed) to the total # of skills in the take,
        // If it's less than we find which one's are missing are create blank takepanels (which will show as not started)
        if (this.availableSkills && toSort.length < Object.keys(this.availableSkills).length) {
            Object.values(this.availableSkills).forEach(skill => {
                if (!toSort.find(panel => panel.skill === skill.skillSymbol)) {
                    toSort.push({skill: skill.skillSymbol, takePanelId: 0, panelId: 0, panelName: "", startTime: "", scaledScore: 0})
                }
            })
        }
        const sortedPanels = toSort.sort((a, b) => PanelSkillOrder.indexOf(a.skill as PanelSkillEnum) - PanelSkillOrder.indexOf(b.skill as PanelSkillEnum))

        return <TestTakerSidebar sidebarInfo={info} placeSidebarInfo={this.PLACEProfileInfo} sortedPanels={sortedPanels} renderSortedPanels={this.renderSortedPanels} renderSelfEval={this.renderSelfEval} P360Data={this.props.P360Data} isP360={this.props.isP360}/>
    }
}

interface TestTakerSidebarProps {
    sidebarInfo: TakeSearchResult
    placeSidebarInfo?: PLACEIndividualReport
    sortedPanels: ApiTakePanelSearchResult[]
    renderSortedPanels: (
        panel: ApiTakePanelSearchResult,
        sortedPanels: ApiTakePanelSearchResult[],
        fullName: string,
        expandedClasses: Record<"content" | "expanded" | "root", string>
    ) => JSX.Element | null
    renderSelfEval: (
        expandedClasses: Record<"content" | "expanded" | "root", string>
    ) => JSX.Element | null
    P360Data?: P360ExamData[]
    isP360: boolean
}

const TestTakerSidebar = (props: TestTakerSidebarProps) => {
    const {sidebarInfo, placeSidebarInfo, sortedPanels, renderSortedPanels, renderSelfEval, P360Data, isP360} = props
    const {
        firstName, lastName, productId
    } = sidebarInfo
    const drawerClasses = useDrawerStyles()
    const expandedClasses = useExpandedStyles()
    const fullName = `${firstName} ${lastName}`

    const showProfileAccordion = (): boolean => {
        return !sidebarStore.openPanel && !sidebarStore.openSelfEval && !sidebarStore.openP360
    }

    // these set of conditionsals will determine if the scheduled tab should be default opened or not
    const showScheduledAccordion = (): boolean => {
        return !sidebarStore.openPanel && !sidebarStore.openSelfEval && sidebarStore.openP360
    }

    const showScheduledExamDetails = (P360ExamData: P360ExamData) => {
        let scheduledAt
        let testLink = ""

        if ( (typeof(P360ExamData.scheduledAt) !== 'undefined') && (P360ExamData.scheduledAt !== null) ) {
            isScheduled = true
            scheduledAt = TimeUtil.formatMilliToMonthDayYearHoursMinutesLong(Number(P360ExamData.scheduledAt))
            testLink = P360ExamData.testLink

            return <>
                <Divider component={"hr"}/>
                <SidebarListItem primary={"Scheduled Time:"} secondary={scheduledAt}/>
                <SidebarListItem primary={"Link to Test:"} secondary={
                    <AvantButton
                        onClick={() => {navigator.clipboard.writeText(testLink).then(() => {
                            messageStore.setMessage({message: "Copied....", type: 1})})}}
                        size={"small"}
                    >
                        Copy Link
                    </AvantButton>}
                />
                <SidebarListItem primary={""} secondary={testLink}/>
            </>
        }
        else {
            return <></>
        }

    }

    let isScheduled = P360Data && P360Data.length > 0

    return (
        <Drawer open={sidebarStore.open} onClose={() => sidebarStore.takeId = undefined} classes={drawerClasses} anchor={"right"}>
            <Toolbar>
                <H3Text>
                    {fullName}
                </H3Text>
                <div style={{flexGrow: 1}}/>
                <IconButton
                    onClick={() => sidebarStore.takeId = undefined}
                >
                    <Close fontSize={"small"}/>
                </IconButton>
            </Toolbar>

            {
                isP360 &&
                <Accordion defaultExpanded={showScheduledAccordion()} style={{margin: 0}}>
                    <AccordionSummary
                        classes={expandedClasses}
                        style={{margin: "initial"}}
                        expandIcon={<ArrowDropDown style={{fill: AvantColors.REPORT_FONT_COLOR_GRAY_3}}/>}
                    >
                        <H5Text>
                            Schedule Status
                        </H5Text>
                    </AccordionSummary>
                    <AccordionDetails style={{padding: 0}}>
                        <SidebarList>
                            <Divider component={"hr"}/>

                            <SidebarListItem primary={"Scheduled:"} secondary={isScheduled ? "Yes" : "No"}/>
                            { isScheduled && P360Data && P360Data.map(showScheduledExamDetails) }
                        </SidebarList>
                    </AccordionDetails>
                </Accordion>
            }

            <Accordion defaultExpanded={showProfileAccordion()} style={{margin: 0}}>
                <AccordionSummary
                    classes={expandedClasses}
                    style={{margin: "initial"}}
                    expandIcon={<ArrowDropDown style={{fill: AvantColors.REPORT_FONT_COLOR_GRAY_3}}/>}
                >
                    <H5Text>
                        Profile
                    </H5Text>
                </AccordionSummary>
                <AccordionDetails style={{padding: 0}}>
                    <ProfileInfo profileInfo={sidebarInfo} placeProfileInfo={placeSidebarInfo}/>
                </AccordionDetails>
            </Accordion>

            {productId === PLACE_PRODUCT_ID.value() && renderSelfEval(expandedClasses)}

            {sortedPanels.map((panel: ApiTakePanelSearchResult) => renderSortedPanels(panel, sortedPanels, fullName, expandedClasses))}
        </Drawer>

    )
}
