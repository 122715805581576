import ApiService from "../../../services/ApiService"
import * as React from "react"
import MaterialTable, {Column} from "material-table"
import {useRef, useState} from "react"
import {Grid} from "@material-ui/core"
import Button from "@material-ui/core/Button"

function buildSort(orderByField: string | unknown, orderDirection: string): string {
    let sortString = ""
    if (orderByField) {
        sortString = `&sort=${orderByField},${orderDirection}`
    }
    return sortString
}

function buildPredicate(filters: any): string {
    const predicateArray: string[] = []
    filters.forEach((filter: any) => {
        if (filter.column.field === "testName") {
            predicateArray.push(`testName=contains(${filter.value})`)
        }
        if (filter.column.field === "testlink") {
            predicateArray.push(`testlink=contains(${filter.value})`)
        }
        if (filter.column.field === "email") {
            predicateArray.push(`email=contains(${filter.value})`)
        }
        if (filter.column.field === "alternateemail") {
            predicateArray.push(`alternateemail=contains(${filter.value})`)
        }
    })
    if (predicateArray.length === 0) {
        return ""
    }
    return `&${predicateArray.join("&")}`
}

const handleBulkConfirmationClick = () => {
    ApiService.get(`${ApiService.API_URL}p360/get-student-schedules`)
}

export const P360StudentSchedulesAdminBase: React.FC = () => {
    const tableRef = React.createRef()

    const initialColumns: Column<any>[] = [
        {
            title: "testname",
            field: "testName",
            sorting: false,
            searchable: false,
            removable: false,
        },
        {
            title: "p360ExamRemoteId",
            field: "p360ExamRemoteId",
            sorting: false,
            searchable: false,
            removable: false,
        },
        {
            title: "testlink",
            field: "testLink",
            sorting: false,
            searchable: false,
            removable: false,
        },
        {
            title: "scheduledat",
            field: "scheduledAt",
            type: "datetime",
            sorting: false,
            searchable: false,
            removable: false,
        },
        {
            title: "email",
            field: "email",
            sorting: false,
            searchable: false,
            removable: false,
        },
        {
            title: "alternateemail",
            field: "alternateEmail",
            sorting: false,
            searchable: false,
            removable: false,
        },
        {
            title: "create_date",
            field: "createDate",
            type: "datetime",
            sorting: false,
            searchable: false,
            removable: false,
        },
        {
            title: "last_touched",
            field: "lasttouched",
            type: "datetime",
            sorting: false,
            searchable: false,
            removable: false,
        },
        {
            title: "schedulingacknowledgementemailsent",
            field: "isCanceled.current",
            type: "boolean",
            sorting: false,
            searchable: false,
            removable: false,
        },
    ]
    const [columns] = useState(initialColumns)

    const isCanceled = useRef(false)

    const fetchData = async (query: any) => {
        const sort = (query.orderBy) ? buildSort(query.orderBy.field, query.orderDirection) : ""
        const predicate = (query.filters) ? buildPredicate(query.filters) : ""

        const response = await ApiService.get(`${ApiService.API_URL}rest/p360ExamDatas?page=${query.page}&size=${query.pageSize}${sort}${predicate}`)
        if (isCanceled.current) {
            return {data: null, page: 1, totalCount: 0}
        } else {
            const responseData = response.data._embedded.p360ExamDatas
            return {
                data: responseData,
                page: response.data.page.number,
                totalCount: response.data.page.totalElements
            }
        }
    }


    return (
        <>
            <h1>P360 Student Sessions</h1>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={6} style={{textAlign: "center"}}>
                    <Button variant="contained" color="primary" onClick={handleBulkConfirmationClick}>Get Student Schedules From P360</Button>
                </Grid>
            </Grid>

            <MaterialTable
                tableRef={tableRef}
                title="P360 Student Sessions"
                columns={columns}
                data={fetchData}
                options={{
                    filtering: true,
                    search: true,
                    showTitle: true,
                    toolbar: true,
                    padding: "dense",
                    pageSize: 100,
                    pageSizeOptions: [100, 200, 500, 1000],
                    exportButton: true,
                    columnsButton: false,
                    debounceInterval: 500,
                    exportFileName: "P360Schedules",
                    tableLayout: "auto",
                }}
            />
        </>
    )
}

export const P360StudentSchedulesAdmin = React.memo(P360StudentSchedulesAdminBase)
