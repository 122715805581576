import React, { useEffect } from 'react'
import ApiService from "../../services/ApiService"
import axios, {AxiosResponse} from "axios"

interface MessageWall{
    message: string,
    display: boolean
}

const getMessageWallData = (callback: (response: MessageWall) => void) => {
    ApiService.get(`${ApiService.API_URL}logins/message-wall`, true).then(
        (response: AxiosResponse) => {
            callback(response.data)
        }
    )
}

const MessageWall: React.FC = () => {
    const [state, setState] = React.useState<MessageWall>({ message: '', display: false })
    const cancelToken = axios.CancelToken.source()

    useEffect(() => {
        getMessageWallData(({ message, display }) => {
            setState({ message, display })
        })

        return () => {
            cancelToken.cancel()
        }
    }, [])

    if (!state || !state.display) {
        return null
    }

    return (
        <div className="login__login-container__top__warning" style={{ marginBottom: '1rem', width: '460px' }}>
            <h3 className="login__login-container__top__warning__text" style={{ marginBottom: '1rem', textAlign: 'center' }}>SYSTEM UPDATE ALERT!</h3>
            <p className="login__login-container__top__warning__text">{state.message}</p>
        </div>
    )
}

export default MessageWall
