import {Grid, IconButton, Tooltip} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import EditIcon from "@material-ui/icons/Edit"
import MaterialTable, {Column} from "material-table"
import * as React from "react"
import {useState} from "react"
import {P360CreateSchedule} from "../../../app/proctor/P360CreateSchedule/P360CreateSchedule"
import ApiService from "../../../services/ApiService"
import moment from "moment"

function buildSort(orderByField: string | unknown, orderDirection: string): string {
    let sortString = ""
    if (orderByField) {
        sortString = `&sort=${orderByField},${orderDirection}`
    }
    return sortString
}

function buildPredicate(filters: any): string {
    const predicateArray: string[] = []
    filters.forEach((filter: any) => {
        if (filter.column.field === "message") {
            predicateArray.push(`message=contains(${filter.value})`)
        }
        if (filter.column.field === "title") {
            predicateArray.push(`title=contains(${filter.value})`)
        }
    })
    if (predicateArray.length === 0) {
        return ""
    }
    return `&${predicateArray.join("&")}`
}


async function fetchData(query: any) {

    const sortKey = query.orderBy === undefined ? "scheduleStartDate" : query.orderBy.field
    const initialSortDir = query.orderDirection === "" ? "desc" : query.orderDirection.field
    const sort = buildSort(sortKey, initialSortDir)
    const predicate = (query.filters) ? buildPredicate(query.filters) : ""

    const response = await ApiService.get(`${ApiService.API_URL}rest/p360Schedulings?page=${query.page}&size=${query.pageSize}${sort}${predicate}`)
    const responseData = response.data._embedded.p360Schedulings
    return {
        data: responseData,
        page: response.data.page.number,
        totalCount: response.data.page.totalElements
    }
}

const handleBulkConfirmationClick = () => {
    ApiService.get(`${ApiService.API_URL}p360/get-schedules-from-p360`)
}

type TableComponentType = {
    onQueryChange: () => void;
};

export const P360SchedulingBase: React.FC = () => {
    const tableRef = React.createRef<TableComponentType>()

    const [editP360ScheduleData, setEditP360ScheduleData] = useState(null)

    const renderActions = (rowData: any): React.ReactFragment => {
        return <>
            <Tooltip title={"Edit this schedule"}>
                <IconButton
                    onClick={() => {
                    setEditP360ScheduleData(rowData)
                    setOpenEmptyP360Schedule(true)
                    }}
                >
                    <EditIcon />
                </IconButton>
            </Tooltip>
        </>
    }

    const initialColumns: Column<any>[] = [
        {
            title: "Actions",
            field: "actions",
            render: renderActions,
            filtering: false,
        },
        {
            title: "Title",
            field: "title",
            sorting: true,
            searchable: true,
            removable: false,
        },
        {
            title: "Schedule Remote Id",
            field: "p360ScheduleRemoteId",
            sorting: false,
            searchable: false,
            removable: false,
            hidden: true
        },
        {
            title: "Start Date",
            field: "scheduleStartDate",
            type: "datetime",
            sorting: true,
            searchable: false,
            removable: false,
            render: rowData => {
                return moment(rowData.scheduleStartDate).local().format("MM/DD/YYYY - hh:mm A")
            }
        },
        {
            title: "End Date",
            field: "scheduleEndDate",
            type: "datetime",
            sorting: false,
            searchable: false,
            removable: false,
            render: rowData => {
                return moment(rowData.scheduleEndDate).local().format("MM/DD/YYYY - hh:mm A")
            }
        },
        {
            title: "Schedule Type",
            field: "scheduleType",
            sorting: false,
            searchable: false,
            removable: false,
            hidden: true
        },
        {
            title: "Total Seats",
            field: "totalSeats",
            sorting: false,
            searchable: false,
            removable: false,
        },
        {
            title: "Slots",
            field: "slots",
            sorting: false,
            searchable: false,
            removable: false,
            render: rowData => JSON.stringify(rowData.slots)
        },
        {
            title: "Create Date",
            field: "createDate",
            type: "date",
            sorting: false,
            searchable: false,
            removable: false,
        },
        {
            title: "Last Touched",
            field: "lasttouched",
            type: "date",
            sorting: false,
            searchable: false,
            removable: false,
        },
    ]

    const [columns] = useState(initialColumns)

    const [openEmptyP360Schedule, setOpenEmptyP360Schedule] = useState(false)

    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone

    const handleModel=()=>{
        (tableRef.current as TableComponentType).onQueryChange()
                    setEditP360ScheduleData(null)
                    setOpenEmptyP360Schedule(false)
    }
    return (
        <>
            <h1>P360 Schedules</h1>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={6} style={{textAlign: "center"}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBulkConfirmationClick}
                    >
                        Get Schedules From P360
                    </Button>
                    &nbsp;
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenEmptyP360Schedule(true)}
                    >
                        P360 Create Schedule
                    </Button>
                </Grid>
            </Grid>

            <MaterialTable
                tableRef={tableRef}
                title={`P360 Schedules (all times in: ${timeZoneName} time)`}
                columns={columns}
                data={fetchData}
                options={{
                    filtering: true,
                    search: false,
                    showTitle: true,
                    toolbar: true,
                    padding: "dense",
                    pageSize: 100,
                    pageSizeOptions: [100, 200, 500, 1000],
                    exportButton: true,
                    columnsButton: false,
                    debounceInterval: 500,
                    exportFileName: "P360StudentSessions",
                    tableLayout: "auto",
                }}
            />

            <Dialog
                open={openEmptyP360Schedule}
                onClose={() => handleModel()}
                aria-labelledby="form-dialog-title"
                maxWidth={"md"}
                fullWidth={true}
            >
                <DialogTitle id="form-dialog-title">Edit schedule</DialogTitle>
                <DialogContent style={{ overflowX: "hidden" }}>
                    <P360CreateSchedule existingScheduleData={editP360ScheduleData} modalClose={() => handleModel()}/>
                </DialogContent>
            </Dialog>
        </>
    )
}

export const P360SchedulingAdmin = React.memo(P360SchedulingBase)
