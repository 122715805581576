import React from "react"
import { RouteComponentProps } from "react-router"
import { AvantLogo } from "../common/AvantLogo"
import { ErrorMessage } from "./ErrorMessage"
import { AvantProctorForm } from "./AvantProctorForm"
import ApiService from "../../services/ApiService"
import MessageWall from "../../components/Login/MessageWall"

function validateTestCodeId(testCodeId: string): boolean {
    if (+testCodeId === NaN) {
        return false
    }
    return true
}
interface AvnatProctorPageProps extends RouteComponentProps<any> {
    loginId?: string
    isMiddlebury: string
}
interface State {
    activeComponent?: React.ReactFragment
    testCodeId?: string
    verifyLoginMessage?: string
}
export class AvantProctorPage extends React.Component<AvnatProctorPageProps, State> {
    constructor(props: AvnatProctorPageProps) {
        super(props)
        let testCodeId = ""
        let activeComponent = <ErrorMessage errorMessage={`We are are validating your request.`} />
        const reference: string = this.props.match.params.loginId
        if (reference == null) {
            activeComponent = <ErrorMessage errorMessage={`Your url was in error. (url:${reference})`} />
            //we will create a new SKU prefix something like vpr, For now I'm using vpr here
        } else if (reference.startsWith("vpp-") || reference.startsWith("vpr-")) {
            // expecting to get something like vpp-14548-1000126113%E2%80%8B OR vpp-14548-1000126113
            const matches = reference.match(/^([^-]+)-([^-]+)-(\d+)/)
            if (matches && matches.length === 4) { // the matches + the original
                testCodeId = matches[3]
            }
        } else {
            // expecting 1000126113%E2%80%8B OR 1000126113
            const matches = reference.match(/^(\d+)/)
            if (matches && matches.length === 2) { // the matches + the original
                testCodeId = matches[1]
            }
        }
        this.state = {
            testCodeId,
            activeComponent
        }
    }
    componentDidMount() {
        if (this.state && this.state.testCodeId) {
            const { testCodeId } = this.state
            if (validateTestCodeId(testCodeId)) {
                ApiService.verifyLogin(+testCodeId)
                    .then(_ => {
                        // we got a 200.... all is good.
                        const activeComponent = <AvantProctorForm loginId={+testCodeId} isMiddlebury={this.props.isMiddlebury} />
                        this.setState({ activeComponent })
                    })
                    .catch(error => {
                        const activeComponent = <ErrorMessage errorMessage={error.message} />
                        this.setState({ activeComponent })
                    })
            }
        }
    }
    render() {
        return (
            <div style={{ marginTop: 8 }}>
                <AvantLogo title={"Online Remote Proctoring Registration"} />
                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    <MessageWall />
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    {this.state.activeComponent}
                </div>
            </div>
        )
    }
}

