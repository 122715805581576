/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/
import React from "react"
import {productStore} from "../../app/common/products/ProductStore"
import {ProgressBar} from "react-bootstrap"

import {ApiPanelGraph, Panel, TakePanel, ApiItemResponse} from "../../types/types"
import {Section} from "../../app/section/models/Section"
import HelperService from "../../services/HelperService"
import {
    STAMP_4S_PRODUCT_ID,
    STAMP_4SE_PRODUCT_ID,
    STAMP_SIGN_LANGUAGE_PRODUCT_ID,
    RECEPTIVE,
    LISTENING,
    EXPRESSIVE,
    NOELLA_IMAGES,
    IMAGES_BASE_URL,
    SELFEVALUATION
} from "../../util/Constants"
import {getProgress} from "../StatusBar/StatusBar"
import Button from "../Button/Button"
import { H4Text } from "../../styles/AvantTypography"

interface Props {
    startedPanel: Panel
    startedTakePanel: TakePanel
    openModal: (currentPanelId: number, currentTestName: string, selfEvaluation: boolean) => void
    panelGraph: ApiPanelGraph
    currentSection: Section | null
    skillToTestName: Map<string, string>
}
const RenderStartedSkill: React.FC<Props> = ({
    startedPanel,
    startedTakePanel,
    openModal,
    panelGraph,
    currentSection,
    skillToTestName
}) => {
    let progress: number[] = []

    if (currentSection) {
        // Sort the responses for this skill by id to get the most recent response
        startedTakePanel.responses.sort((a: ApiItemResponse, b: ApiItemResponse) => b.id - a.id)
        const lastResponse = startedTakePanel.responses[0]
        const itemId = lastResponse ? lastResponse.itemId : null
        const progressInSection = HelperService.getProgressInSection(currentSection, itemId, true)
        progress = getProgress(currentSection, progressInSection)
    }
    const is4Se = productStore.driver!!.productId.value() === STAMP_4SE_PRODUCT_ID.value()

    const continueBaseClass = "dashboard__started-skill__content__image"
    const continueClass = is4Se ? `${continueBaseClass} ${continueBaseClass}__4se` : continueBaseClass

    const name = skillToTestName.get(startedPanel.skill)
    const testName = name ? name : ""
    const isSelfEvaluated = startedPanel.selfEvaluation || false

    const imageUrl = (is4Se: boolean) => {
        if (is4Se) {
            return `${NOELLA_IMAGES}${testName.toLowerCase()}_small.jpg`
        } else if (testName === "Listening" && panelGraph.productId === STAMP_4S_PRODUCT_ID.value()) {
            return isSelfEvaluated ? SELFEVALUATION : LISTENING
        } else if (testName === "Listening" && panelGraph.productId === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()) {
            return isSelfEvaluated ? SELFEVALUATION : RECEPTIVE
        } else if (testName === "Speaking" && panelGraph.productId === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()) {
            return isSelfEvaluated ? SELFEVALUATION : EXPRESSIVE
        } else {
            return isSelfEvaluated ? SELFEVALUATION : `${IMAGES_BASE_URL}${testName}.svg`
        }
    }

    return (
        <div className="dashboard__started-skill">
            <div className="dashboard__started-skill__content">
                <div className={continueClass}>
                    <img src={imageUrl(is4Se)} alt="" />
                </div>
                <div>
                    <H4Text className="dashboard__started-skill__content__title">
                    {testName === "Listening" && panelGraph.productId === STAMP_4S_PRODUCT_ID.value()
                            ? "Listening"
                            :testName === "Listening" && panelGraph.productId === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()?"Receptive":testName === "Speaking" && panelGraph.productId === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()?"Expressive": testName}

                    </H4Text>
                    <div className="dashboard__started-skill__content__progress">
                        {progress.map((progressInSection: number, index: number) => {
                            return (
                                <ProgressBar
                                    key={index + 1}
                                    className="dashboard__started-skill__content__progress__bar"
                                    now={progressInSection}
                                />
                            )
                        })}
                        <p className="dashboard__started-skill__content__progress__text" style={{display: "none"}}>
                            {`${startedTakePanel.currentSection - 1} / ${
                                startedTakePanel.numSections
                            } section(s) complete`}
                        </p>
                    </div>
                    <div className="dashboard__started-skill__content__buttons">
                        <Button
                            className="avant-button--primary dashboard__started-skill__content__buttons__button"
                            block={true}
                            testId="modal-yes-button"
                            onClick={() => openModal(startedPanel!.id, testName, isSelfEvaluated)}
                        >
                            CONTINUE
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RenderStartedSkill
