import {Dialog, DialogTitle, Grid, makeStyles, Paper, Typography} from "@material-ui/core"
import * as React from "react"

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}))

interface SSOBulkActionProps {
    totalCount: number
    current: number
}

export const SSOBulkAction: React.FC<SSOBulkActionProps> = (props) => {
    const classes = useStyles()
    return (
        <Dialog aria-labelledby="Class Roster" open={true}>
            <DialogTitle id="simple-dialog-title">
                Bulk Update
            </DialogTitle>
            <Paper className={classes.paper} elevation={3} >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                            <Typography>Updating {props.current} of {props.totalCount}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Dialog>
    )
}
