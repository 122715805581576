/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {observable} from "mobx"
import {PanelSkillEnum, PanelSkillOrder, SortDirection} from "../../../../types/types"
import {ReportTakeRow} from "../../../reports/class/models/ReportTakeRow"
import {HeaderRow} from "./TestTakerTableContainer"
import {orderBy as lodashOrderBy} from "lodash"
import {productStore} from "../../../common/products/ProductStore"
import {PLACE_PRODUCT_ID, READY_PRODUCT_ID} from "../../../../util/Constants"

export interface PanelSkill {
    symbol: string,
    name: string
}

class TestTakerSortableTableStore {
    @observable
    order: SortDirection = "asc"

    @observable
    orderBy: keyof ReportTakeRow = "lastName"

    @observable
    availableSkills: PanelSkill[] = []

    @observable
    rows: ReportTakeRow[] = []

    @observable
    headerRow: HeaderRow[] = []

    @observable
    selected: number[] = []

    @observable
    selectedRow?: ReportTakeRow

    public isPlace(): boolean {
        return productStore && productStore.loginProduct !== null && (
            productStore.loginProduct!.productId === PLACE_PRODUCT_ID.value()
            || productStore.loginProduct!.productId === READY_PRODUCT_ID.value()
        )
    }

    public setRows(rows: ReportTakeRow[]) {
        this.rows = rows

        this.sortRows()
    }

    public sortAvailableSkills(skills: PanelSkill[]) {
        this.availableSkills = skills
            .slice()
            .sort((a: PanelSkill, b: PanelSkill) => {
                return PanelSkillOrder.indexOf(a.symbol as PanelSkillEnum) - PanelSkillOrder.indexOf(b.symbol as PanelSkillEnum)
            })
    }

    public sortRows() {
        this.rows = lodashOrderBy(this.rows.slice(), [row => {
            let value = row[this.orderBy]
            if (typeof value === "string") {
                value = value.toLowerCase()
                // TODO: Add logic for sorting composite scores for place as they are strings to support * levels
            } else if (!value) {
                value = 0
            }
            return value
        }, "lastName", "firstName"], [this.order, "asc"])
    }
}

const testTakerSortableTableStore = new TestTakerSortableTableStore()

export default testTakerSortableTableStore
