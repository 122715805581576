import {createStyles, withStyles, WithStyles} from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import {IItem, ItemContentTypeEnum, LoginProductContentAreaData, PassageSelectTarget, TLocalAnswer} from "../../types/types"
import {APT_PRODUCT_ID, CONTENT_AREA_ID_ARABIC} from "../../util/Constants"

const styles = createStyles({
    arabicFontSize: {
        fontSize: 20,
        lineHeight: 1.5
    }
})

type PassageSelectProps = {
    item: IItem
    isRtlLayout: boolean
    answer: TLocalAnswer
    product: LoginProductContentAreaData
} & WithStyles<typeof styles>

class PassageSelect extends React.Component<PassageSelectProps> {

    createTarget = (target: PassageSelectTarget) => {
        const {product, classes} = this.props
        let className = target.type.includes("rtl") || this.props.isRtlLayout ? "direction-rtl" : ""
        if (product.productId === APT_PRODUCT_ID.value() || product.contentAreaId === CONTENT_AREA_ID_ARABIC) {
            className = classNames(className, classes.arabicFontSize)
        }

        const hasCorrectAnswer = this.props.item.correctAnswers ? this.props.item.correctAnswers.length > 0 : null
        let correctAnswer
        if (hasCorrectAnswer) {
            correctAnswer = (
                <div>
                    CORRECT ANSWERS:
                    <span className="correct-answer">{this.props.item.correctAnswers.join(", ")}</span>
                </div>
            )
        }

        let answerClass = "ps-target-text"
        if (this.props.answer) {
            answerClass = `${answerClass} ps-target-text--selected`
        }
        if (product.productId === APT_PRODUCT_ID.value() || product.contentAreaId === CONTENT_AREA_ID_ARABIC) {
            answerClass = classNames(answerClass, classes.arabicFontSize)
        }

        return (
            <div  key={target.id}>
                <div className={className}>
                    {target.content.map((obj: any, index: number) => {
                        if (obj.type === ItemContentTypeEnum.PS_DASH_TEXT) {
                            return (
                                <span key={index} className={answerClass}>
                                    {this.props.answer || "______"}
                                </span>
                            )
                        } else {
                            return React.createElement("span", {
                                key: index,
                                dangerouslySetInnerHTML: {__html: obj.text}
                            })
                        }
                    })}
                </div>
                <br />
                <br />
                {correctAnswer}
            </div>
        )
    }

    render() {
        return this.props.item.psTargets.map(this.createTarget)
    }
}

export default withStyles(styles)(PassageSelect)
