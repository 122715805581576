import {observer} from "mobx-react"
import React from "react"
import {FormLabel, FormControl, FormGroup} from "react-bootstrap"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"
import {loadingSpinnerStore} from "../../../app/common/loaders/LoadingSpinnerStore"
import {RoutePaths} from "../../../app/routes/Routes"

import {setLicenseKey} from "../../../redux/app/actions"
import ApiService from "../../../services/ApiService"
import HelperService from "../../../services/HelperService"
import {ApiErrorResponse, ApiLicenseKey, AppConfig} from "../../../types/types"
import Button from "../../Button/Button"

declare const appConfigs: AppConfig


interface IDispatchToProps {
    setLicenseKeyDispatch: (licenseKey: ApiLicenseKey) => void
}

function mapDispatchToProps(dispatch: Dispatch): IDispatchToProps {
    return {
        setLicenseKeyDispatch: (licenseKey: ApiLicenseKey) => {
            dispatch(setLicenseKey(licenseKey))
        }
    }
}

type TComponentProps = IDispatchToProps & RouteComponentProps<any>

interface IComponentState {
    error: boolean | string
    licenseKey: string
}

@observer
class ClaimLicenseKey extends React.Component<TComponentProps, IComponentState> {
    alreadyClaimedMessage = "That license key has already been claimed. Please try again or"

    constructor(props: TComponentProps) {
        super(props)
        this.state = {
            error: false,
            licenseKey: ""
        }
    }

    handleLicenseKeyChange = (event: any) => {
        this.setState({licenseKey: event.target.value})
    }

    handleSubmit = (event: any) => {
        event.preventDefault()
        if (this.state.licenseKey === "" || !this.isValidKey(this.state.licenseKey)) {
            this.setState({error: "You must enter a valid license key."})
            return
        }

        ApiService.validateLicenseKey(this.state.licenseKey).then(
            (licenseKey: ApiLicenseKey) => {
                this.props.setLicenseKeyDispatch(licenseKey)
                this.props.history.push(`/register?key=${this.state.licenseKey}`)
            },
            (err: ApiErrorResponse) => {
                let error = ""
                switch (err.response.data.kind) {
                    case "LicenseKeyAlreadyClaimed":
                        error = this.alreadyClaimedMessage
                        break
                    case "LicenseKeyInvalidLicenseKeyFormatException":
                    case "LicenseKeyNotFound":
                        error = "We didn’t recognize that license key. Please try again."
                        break
                    case "LicenseKeyExpiredException":
                        error =
                            `That license key has already expired. Please connect Avant support at` +
                            ` ${appConfigs.SUPPORT_EMAIL} to get a new one.`
                }
                this.setState({error})
            }
        )
    }

    handleReturnToLogin = () => {
        this.props.history.push(RoutePaths.LOGIN)
    }

    isValidKey = (key: string) => {
        const keyLength = 8
        const alphabet = new Set<string>([
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "J",
            "K",
            "L",
            "M",
            "N",
            "P",
            "Q",
            "R",
            "T",
            "W",
            "X",
            "Y",
            "3",
            "4",
            "6",
            "7",
            "8",
            "9"
        ])
        let isValid: boolean = true
        if (key.length > keyLength || key.length < keyLength) {
            isValid = false
        }
        for (const character of key) {
            if (!alphabet.has(character)) {
                isValid = false
                break
            }
        }
        return isValid
    }

    componentDidMount() {
        document.title = `Avant | Claim License Key`
        HelperService.enableTextSelection()
        loadingSpinnerStore.hideLoadingSpinner = true
    }

    render() {
        const loginButtonClass = "login__login-container__button avant-button--primary"
        return (
            <div className="login">
                <div>
                    <div className="login__image-container">
                        <img
                            className="login__image-container__image"
                            alt=""
                            src="https://cdn.avantassessment.com/resources/common/img/avant-logo.svg"
                            tabIndex={0}
                        />
                        <div className="login__image-container__text">ADVANCE</div>
                    </div>
                    <div className="login__login-container">
                        <div className="login__login-container__top">
                            <div className="login__login-container__top__title">Enter your license key</div>
                            {this.state.error && (
                                <div className="login__login-container__top__warning">
                                    <div className="login__login-container__top__warning__text">
                                        {this.state.error !== this.alreadyClaimedMessage ? (
                                            <>{this.state.error}</>
                                        ) : (
                                            <>
                                                {this.state.error}
                                                {"\u00A0"}
                                                <span
                                                    className="login__login-container__top__warning__text--blue"
                                                    onClick={this.handleReturnToLogin}
                                                >
                                                    login.
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={
                                this.state.error
                                    ? "login__login-container__form login__login-container__form-error"
                                    : "login__login-container__form"
                            }
                        >
                            <form onSubmit={this.handleSubmit}>
                                <FormGroup>
                                    <FormLabel>License Key</FormLabel>
                                    <FormControl
                                        type="text"
                                        value={this.state.licenseKey}
                                        placeholder=""
                                        data-tst-id="password"
                                        onChange={this.handleLicenseKeyChange}
                                    />
                                </FormGroup>
                                <Button className={loginButtonClass} onClick={this.handleSubmit} type="submit">
                                    SUBMIT
                                </Button>
                            </form>
                        </div>
                        <div className="login__login-container__bottom">
                            <div className="login__login-container__bottom__text">
                                Already have an account?
                                {"\u00A0"}
                                <a href="#"
                                    className="login__login-container__bottom__text--blue"
                                    onClick={this.handleReturnToLogin}
                                >
                                    Click to return to login.
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    null,
    mapDispatchToProps
)(ClaimLicenseKey)
