import React from "react"
import Button from "../../../Button/Button"

interface IComponentProps {
    onClick: (event: any) => void
    recordingDuration: number
}

interface IComponentState {
    currentListenTime: number
}

export default class StopListeningButton extends React.Component<IComponentProps, IComponentState> {
    constructor(props: IComponentProps) {
        super(props)

        this.state = {currentListenTime: 0}
    }

    render() {
        return (
            <Button className={"StopListeningButton"} onClick={this.props.onClick}>
                <div className={"StopListeningButton__icon"} tabIndex={0}/>
                <span className={"StopListeningButton__text"}>Stop</span>
            </Button>
        )
    }
}
