import {observer} from "mobx-react"
import React from "react"
import {Alert} from "react-bootstrap"
import {connect} from "react-redux"
import {authStore} from "../../app/common/authentication/AuthStore"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"
import {loadingSpinnerStore} from "../../app/common/loaders/LoadingSpinnerStore"
import {productStore} from "../../app/common/products/ProductStore"
import {Section} from "../../app/section/models/Section"
import {sectionStore} from "../../app/section/SectionStore"
import {updateSection} from "../../redux/item/actions"
import HelperService from "../../services/HelperService"
import PTHandler from "../../services/PTHandler"
import DashboardContainer from "../DashboardContainer/DashboardContainer"
import {ApiErrorResponse, ApiPanelGraph, ApiTakePanelStateEnum, State, Take, TestState} from "../../types/types"
import {APT_PRODUCT_ID, STAMP_4SE_PRODUCT_ID, SUPPORT_MESSAGE} from "../../util/Constants"
import {PanelId, TakeId} from "../../validation/ValidPrimaryKey"
import {addTakeDispatches, avantHistory, TakeDispatches} from "../App/App"
import StartTestModal from "../StartTestModal/StartTestModal"
import ApiService from "../../services/ApiService"
import {RoutePaths} from "../../app/routes/Routes"

interface StateToProps {
    section: Section | null
    take: Take
    takeCode: string
    testState: TestState
    panelGraph: ApiPanelGraph
}

function mapStateToProps(state: State): StateToProps {
    return {
        section: state.item.currentSection,
        take: state.app.take!,
        takeCode: state.app.takeCode,
        testState: state.app.testState!,
        panelGraph: state.app.panelGraph!
    }
}

// @ts-ignore
async function endPTSession() {
    await PTHandler.endExam()
}

interface DispatchToProps extends TakeDispatches {
    updateSectionDispatch: (bin: Section) => void
}

function mapDispatchToProps(dispatch: Dispatch): DispatchToProps {
    const dispatches = {
        updateSectionDispatch: (section: Section) => {
            dispatch(updateSection(section))
        }
    }
    return addTakeDispatches(dispatches, dispatch)
}

interface DashboardProps extends StateToProps, DispatchToProps, RouteComponentProps {}

interface DashboardState {
    error: boolean | string
    loaded: boolean
    take: Take
    showModal: boolean
    currentPanelId: number
    currentTestName: string
    selfEvaluation: boolean
    currentSection: Section | null
    triedToStartPanel: boolean
}

@observer
class Dashboard extends React.Component<DashboardProps, DashboardState> {
    state: DashboardState
    constructor(props: DashboardProps) {
        super(props)
        this.state = {
            error: false,
            loaded: false,
            take: props.take,
            showModal: false,
            currentPanelId: -1,
            currentTestName: "",
            selfEvaluation: false,
            currentSection: null,
            triedToStartPanel: false
        }
    }

    async componentDidMount() {
        HelperService.enableTextSelection()
        await this.getData()
        //This spot guarantees that LOGGED_IN gets set, this is important for
        //past uses that log in new.   This can possibly later be taken out.
        ApiService.updateTakeState(this.props.take.id, "LOGGED_IN")
    }

    async componentDidUpdate() {
        await authStore.didTheyAgree()
        await this.getData()
    }

    getData = async () => {
        const driver = productStore.driver
        const {loaded, take, currentSection} = this.state
        if (driver && !loaded) {
            const product = productStore.loginProduct
            this.setState({loaded: true})
            document.title = `${driver.config.PRODUCT_TITLE} | Dashboard`
            let lastPage = HelperService.getLastPageFromLs()
            if (lastPage) {
                try {
                    if (product == null) {
                        throw Error("Can't get test state while product is null.")
                    }
                    const testState = await driver.helper.refreshTest(this.props, lastPage, product)
                    if (!testState.take) {
                        throw Error("[Dashboard.componentDidMount] testState.take is null or undefined")
                    }
                    loadingSpinnerStore.hideLoadingSpinner = true
                    this.setState({take: testState.take})
                } catch (error) {
                    this.setState({
                        error: SUPPORT_MESSAGE
                    })

                    throw new Error(`Failed to refresh test. ${error}`)

                    // throw new Error(
                    //     `Failed to refresh test. ${error?.response?.data?.error ?? 'UNKNOWN ERROR'} - ${error?.response?.data?.message}`
                    // )
                }
            } else {
                lastPage = {
                    url: "/dashboard",
                    takeCode: this.props.takeCode,
                    panelGraphId: this.props.panelGraph.id
                }
                localStorage.setItem("lastPage", JSON.stringify(lastPage))
            }
        }

        if (take && currentSection == null) {
            const inProgressSkillList = take.takePanels.filter((p) => p.panelState === ApiTakePanelStateEnum.STARTED)

            if (inProgressSkillList.length > 1) {
                throw new Error(`There should never be more than 1 skill in progress at a time!`)
            }

            const inProgressSkill = inProgressSkillList.length > 0 ? inProgressSkillList[0] : null

            if (inProgressSkill) {
                sectionStore.findCurrentSection(new TakeId(take.id), new PanelId(inProgressSkill.panelId)).then(
                    (section: Section) => {
                        this.setState({currentSection: section})
                    },
                    (err: ApiErrorResponse) => {
                        this.setState({
                            error: SUPPORT_MESSAGE
                        })
                        throw new Error(
                            `Failed to get panel. ${err.response.data.error} - ${err.response.data.message}`
                        )
                    }
                )
            }
        }
    }

    openModal = (currentPanelId: number, currentTestName: string, selfEvaluation: boolean) => {
        this.setState({showModal: true, currentPanelId, currentTestName, selfEvaluation})
    }

    closeModal = () => {
        this.setState({showModal: false})
    }

    logout = () => {
        HelperService.logout(undefined)
    }

    agreeToEULA = () => {
        authStore.agreeToEula()
    }

    disagree = () => {
        const authUser = authStore && authStore.auth ? authStore.auth : null
        const isSSOLogin = authUser && authUser.ssoId ? true : false

        if (isSSOLogin) avantHistory.push(RoutePaths.SSO_STUDENT)
        else this.logout()
    }

    startPanel = () => {
        const {take, currentPanelId, currentTestName, triedToStartPanel} = this.state
        const {updateSectionDispatch, history} = this.props
        const driver = productStore.driver

        this.setState({showModal: false})
        sectionStore.findCurrentSection(new TakeId(take.id), new PanelId(currentPanelId)).then(
            (section: Section) => {
                if (section.items.length > 0) {
                    section.config = driver!!.config.TEST_ENGINE_CONFIG
                    section.config.testName = currentTestName
                    updateSectionDispatch(section)
                    const url: string = "/item"
                    this.setState({triedToStartPanel: false})
                    history.push(url)
                } else {
                    if (!triedToStartPanel) {
                        this.setState({triedToStartPanel: true})
                        this.startPanel()
                    } else {
                        this.setState({
                            error: SUPPORT_MESSAGE
                        })
                        throw new Error("No items returned from current section after two tries")
                    }
                }
            },
            (err: ApiErrorResponse) => {
                this.setState({
                    error: SUPPORT_MESSAGE
                })
                throw new Error(`Failed to get panel. ${err.response.data.error} - ${err.response.data.message}`)
            }
        )
    }
    render() {
        const driver = productStore.driver
        const {error, take, showModal, currentTestName, selfEvaluation} = this.state
        const is4Se = productStore.driver!!.productId.value() === STAMP_4SE_PRODUCT_ID.value()
        const isAPT = productStore.driver!!.productId.value() === APT_PRODUCT_ID.value()

        return (
            <div className="dashboard">
                {error && (
                    <div className="alerts">
                        <Alert variant="danger" onClose={() => this.setState({error: false})}>
                            {error}
                        </Alert>
                    </div>
                )}
                {driver && take ? (
                    <>
                        <div className="center-text" id="content">
                            <DashboardContainer
                                take={this.state.take}
                                panelGraph={this.props.panelGraph}
                                agreeToEULA={this.agreeToEULA}
                                openModal={this.openModal}
                                logout={this.logout}
                                currentSection={this.state.currentSection}
                            />
                        </div>
                        {(authStore.agreedToEULA || is4Se || isAPT) && (
                            <StartTestModal
                                showModal={showModal}
                                selfEvaluation={selfEvaluation}
                                currentTestName={currentTestName}
                                closeModal={this.closeModal}
                                startPanel={this.startPanel}
                                productId={driver.productId}
                            />
                        )}
                    </>
                ) : (
                    <div className="center-text" style={{fontWeight: "normal"}}>
                        <h2>Loading...</h2>
                    </div>
                )}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
