/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/
import React, {useRef, useState} from "react"
import ApiService, {SUFResult, SUFRow} from "../../services/ApiService";
import {Grid, makeStyles, Typography} from "@material-ui/core";
import Button from "react-bootstrap/Button";
import {loadingSpinnerStore} from "../../app/common/loaders/LoadingSpinnerStore";
import {AxiosError} from "axios";
import {theme} from "../../styles/MuiThemes";
import CodeGenTestTakerListTable from "./CodeGenTestTakerListTable";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {ExpandMore} from "@material-ui/icons"
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import moment from "moment";

const useStyles = makeStyles({
    gridRoot: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonStyle: {
        margin: theme.spacing(2)
    },
    accordionRoot: {
        width: '100%',
    }
});
enum CodeGenFlowState {
    STARTING = "starting",
    DOWNLOAD_CSV = "dlcsv",
    UPLOADED_SUF = "uploaded",
    SUBMIT_PRODUCTS_FOR_CODE_CREATION = "submitted",
    ERROR = "ERROR"
}
interface CodeGen2State {
    selectedFile: File | null,
    success: boolean,
    error: string | undefined,
    state: CodeGenFlowState,
    sufError: boolean,
    sufResult2: SUFResult[],
    selectedSufResult2: SUFResult[],
    codeCount: number | undefined
}
const CodeGen2Container: React.FC = () => {

    const classes = useStyles()

    const [codeGenState, setCodeGenState] = useState<CodeGen2State>({
        selectedFile: null,
        success: true,
        error: undefined,
        state: CodeGenFlowState.STARTING,
        sufError: false,
        sufResult2: [],
        selectedSufResult2: [],
        codeCount: undefined
    })


    const fileInputRef = useRef<HTMLInputElement>(null)

    function cancelSession(){
        if (fileInputRef.current) {
            fileInputRef.current.value=""
        }
        setCodeGenState({
            selectedFile: null,
            success: true,
            error: undefined,
            state: CodeGenFlowState.STARTING,
            sufError: false,
            sufResult2: [],
            selectedSufResult2: [],
            codeCount: undefined
        })
    }

    const uploadSUFButtonPress = () => {
        if(codeGenState.selectedFile && !codeGenState.selectedFile.name.includes(".xls")){
            setCodeGenState({...codeGenState, state: CodeGenFlowState.STARTING, error: "incorrect file type"})
            alert("Incorrect file type. Please try again")
            return
        }

        if(codeGenState.selectedFile){
            loadingSpinnerStore.hideLoadingSpinner = false
            ApiService.uploadAndValidateSUF(codeGenState.selectedFile).then(
                (res: SUFResult[] ) => {
                    let productRows: SUFResult[] = []
                    let codeCount: number = 0
                    res.forEach((sufResult) => {
                        if (sufResult.success) {
                            productRows.push(sufResult)
                        }
                        codeCount += sufResult.sufRows.length * 2
                    })
                    setCodeGenState({...codeGenState, state: CodeGenFlowState.SUBMIT_PRODUCTS_FOR_CODE_CREATION, sufResult2: productRows, codeCount: codeCount})
                    loadingSpinnerStore.hideLoadingSpinner = true
                },
                (error: AxiosError) => {
                    setCodeGenState({...codeGenState, state: CodeGenFlowState.ERROR, error: error.message})
                    alert("Please select a file to submit")
                    // TODO add TOAST (MUI Snackbar) for error
                    loadingSpinnerStore.hideLoadingSpinner = true
                })
        }

    }

    function onFileChange(e: React.ChangeEvent<HTMLInputElement>){
        if (e.target.files && e.target.files[0]) {
            setCodeGenState({...codeGenState, selectedFile: e.target.files[0]})
        }
    }

    const downloadCSV2 = () => {

        // TODO: Find a better construct to make this happen. There has to be a better way

        const xlsxHeaders: string =
            "State, " +
            "Organization, " +
            "Sub-Organization, " +
            "Testing Group, " +
            "Language, " +
            "Open Date, " +
            "Close Date, " +
            "Test Code, " +
            "Password, " +
            "User Type" +
            "\n"

        const csv: string[] = []

        codeGenState.sufResult2.forEach((curSufResult2: SUFResult, index: number): void => {
            // This will be the product header row
            csv.push("(" + (index + 1) + "/" + codeGenState.sufResult2.length + "), Product: " + curSufResult2.productPackage + "\n")
            csv.push(xlsxHeaders)

            // Add all test codes to the CSV
            curSufResult2.sufRows.forEach((curSufRow: SUFRow): void => {
                if (curSufRow.success ) {
                    // All data plus test taker login code and PW
                    csv.push(`"${curSufResult2.state}","${curSufResult2.districtOrCustomer}","${curSufRow.school}","${curSufRow.classOrLocation}","${curSufRow.language}","${moment(curSufRow.openDate).format("MM/DD/YYYY")}","${moment(curSufRow.closeDate).format("MM/DD/YYYY")}","${curSufRow.student.username}","${curSufRow.student.pw}","Test Takers"\n`)
                    // Mostly blanks for this row so Teacher code and password align with student code and password columns
                    csv.push(`,,,,,,,"${curSufRow.teacher.username}","${curSufRow.teacher.pw}","Proctor/Results Login"\n`)
                }
                // TODO: Think about re-implementing this logic in the future to show errors in the CSV
                // else {
                //     // All data plus test taker login code and PW
                //     csv.push(`"${curSufResult2.state}","${curSufResult2.districtOrCustomer}","${curSufRow.schoolOrLocation}","${curSufRow.className}","${curSufRow.language}","${moment(curSufRow.openDate).format("MM/DD/YYYY")}","${moment(curSufRow.closeDate).format("MM/DD/YYYY")}","ERROR","ERROR","${curSufRow.error}"\n`)
                //     // Mostly blanks for this row so Teacher code and password align with student code and password columns
                //     csv.push(`,,,,,,,"ERROR","ERROR","${curSufRow.error}"\n`)
                // }
            })
        })

        const blob = new Blob(csv, {type: "text/csv;charset=utf8;"})
        const element = document.createElement("a")
        document.body.appendChild(element)
        element.setAttribute("href", window.URL.createObjectURL(blob))

        element.setAttribute("download", "Codes.csv")

        element.style.display = ""
        element.click()
        document.body.removeChild(element)
    }

    const submitToCreateCodes = () => {
        loadingSpinnerStore.hideLoadingSpinner = false
        ApiService.submitSUFToCreateCodes(codeGenState.selectedSufResult2).then(
            (result: SUFResult[]) => {
                setCodeGenState({...codeGenState, state: CodeGenFlowState.DOWNLOAD_CSV, sufResult2: result})
                loadingSpinnerStore.hideLoadingSpinner = true
            },
            (error: AxiosError) => {
                // TODO Handle error results
                loadingSpinnerStore.hideLoadingSpinner = true
            }
        )
    }

    const renderCancelButton = () => {
        const buttonText = codeGenState.state === CodeGenFlowState.DOWNLOAD_CSV ? "Upload another SUF" : "Cancel"
        return (
            <Button size="lg" onClick={cancelSession} className={classes.buttonStyle}>
                {buttonText}
            </Button>
        )
    }

    const selectSufResultToCreat = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: SUFResult) => {
        // e.stopPropagation makes it so the accordion does not open when you select the checkbox
        e.stopPropagation()
        if (codeGenState.selectedSufResult2.includes(value)) {
            const result: SUFResult[] = codeGenState.selectedSufResult2.filter((sufResult: SUFResult) => sufResult != value)
            setCodeGenState({...codeGenState, selectedSufResult2: result})
        } else {
            setCodeGenState({...codeGenState, selectedSufResult2: [...codeGenState.selectedSufResult2, value]})
        }
    }

    return (
        <>
            <Grid container={true} spacing={0} className={classes.gridRoot}>
                <Grid item={true} xs={12} className={classes.centerContent}>
                    <h1>Avant Code Generator 2</h1>
                </Grid>
                    {codeGenState.codeCount && (
                        <Grid item={true} xs={12} className={classes.centerContent}>
                            <h2>Number of codes to be created: {codeGenState.codeCount}</h2>
                        </Grid>
                    )}
                    {
                        codeGenState.state === CodeGenFlowState.STARTING && (
                            <>
                                <Grid item={true} xs={12} className={classes.centerContent}>
                                    <h2>Upload Avant Setup Form (SUF)</h2>
                                </Grid>
                                <Grid item={true} className={classes.centerContent} xs={12}>
                                    <input
                                        type="file"
                                        name="file"
                                        onChange={onFileChange}
                                        ref={fileInputRef}
                                    />
                                </Grid>
                                <Grid item={true} className={classes.centerContent} xs={12}>
                                    <Button
                                        className={classes.buttonStyle}
                                        size="lg"
                                        disabled={codeGenState.selectedFile == null}
                                        onClick={uploadSUFButtonPress}>
                                        Upload File
                                    </Button>
                                    {renderCancelButton()}
                                </Grid>

                            </>
                        )
                    }
                {
                    (codeGenState.state === CodeGenFlowState.UPLOADED_SUF || CodeGenFlowState.DOWNLOAD_CSV) && codeGenState.sufResult2.length < 1 && (
                        <Grid item={true} xs={12} className={classes.centerContent}>
                            No Results
                        </Grid>
                    )
                }
                {
                    (codeGenState.state === CodeGenFlowState.SUBMIT_PRODUCTS_FOR_CODE_CREATION || codeGenState.state === CodeGenFlowState.DOWNLOAD_CSV) && codeGenState.sufResult2.length > 0 && (
                        <>
                            {
                                codeGenState.sufResult2.map((sufResultEntry: SUFResult, idx: number) => {
                                    // This means we won't be able to create a code in this group. The issue needs to be fixed before the code can be created
                                    const resultRowError: boolean = sufResultEntry.sufRows.filter((sufRow: SUFRow) => !sufRow.success).length > 0
                                    return (
                                        <Grid
                                            item={true}
                                            container={true}
                                            xs={12}
                                            key={`${idx}-${sufResultEntry.productPackage}`}
                                        >
                                            <Accordion
                                                TransitionProps={{ unmountOnExit: true }}
                                                className={classes.accordionRoot}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMore />}
                                                    aria-controls={sufResultEntry.productPackage}
                                                    id="SufRowProductHeadereAcordion"
                                                >
                                                    {
                                                        codeGenState.state !== CodeGenFlowState.DOWNLOAD_CSV && (
                                                            <Checkbox
                                                                disabled={resultRowError}
                                                                onClick={(event) => selectSufResultToCreat(event, sufResultEntry)}
                                                            />
                                                        )
                                                    }

                                                    <Typography
                                                        variant={"h4"}
                                                        color={resultRowError ? "error" : "initial"}
                                                        className={classes.centerContent}
                                                    >
                                                        {sufResultEntry.productPackage} (Code Count: {sufResultEntry.sufRows.length * 2}) {resultRowError && "(Error: Expand to see details)"}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid
                                                        item={true}
                                                        xs={12}
                                                        className={classes.centerContent}
                                                        key={idx}
                                                    >
                                                        <CodeGenTestTakerListTable row={sufResultEntry}/>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )
                                })
                            }
                            <>
                                <Grid item={true} xs={12} className={classes.centerContent}>
                                    { codeGenState.state === CodeGenFlowState.SUBMIT_PRODUCTS_FOR_CODE_CREATION && (
                                            <Button
                                                size="lg"
                                                disabled={codeGenState.sufError || codeGenState.selectedSufResult2.length < 1}
                                                onClick={submitToCreateCodes}
                                                className={classes.buttonStyle}
                                            >
                                                Create Codes
                                            </Button>
                                        )
                                    }
                                    {
                                        codeGenState.state === CodeGenFlowState.DOWNLOAD_CSV && (
                                            <Button
                                                size="lg"
                                                className={classes.buttonStyle}
                                                onClick={downloadCSV2}
                                            >
                                                Download Codes
                                            </Button>
                                        )
                                    }
                                    {renderCancelButton()}
                                </Grid>
                            </>

                        </>
                    )
                }
            </Grid>
        </>
    )
}

export default CodeGen2Container
