import React from "react"

interface ButtonProps {
    /**
     * sets the class
     */
    className: string
    /**
     * sets the content
     */
    children?: React.ReactNode
    /**
     * handle interaction
     * @param event is the on click event
     */
    onClick?: (event?: any) => void
    /**
     * sets the type
     */
    type?: "button" | "submit" | "reset"
    /**
     * a handle for integration tests
     */
    testId?: string
    /**
     * treats the button as a block element
     */
    block?: boolean
    /**
     * disables the button
     */
    disabled?: boolean
    /**
     * sets the value
     */
    value?: string
    /**
     * styles the component
     */
    style?: React.CSSProperties
}

export default class Button extends React.Component<ButtonProps, {}> {

    render() {
        let className: string = this.props.className
        if (this.props.block) {
            className += " avant-button--block"
        }
        if (this.props.disabled) {
            className += " avant-button--disabled"
        }
        return (
            <button
                style={this.props.style}
                type={this.props.type ? this.props.type : "button"}
                className={className}
                value={this.props.value ? this.props.value : undefined}
                onClick={this.props.onClick}
                data-tst-id={this.props.testId}
                disabled={this.props.disabled}
            >
                {this.props.children}
            </button>
        )
    }
}
