import {/*FormControl,*/ FormGroup, /*Input,*/ InputLabel, MenuItem, Select, TextField} from "@material-ui/core"
import Button from "@material-ui/core/Button/Button"
import Snackbar from "@material-ui/core/Snackbar/Snackbar"
import ApiService from "../../services/ApiService"
import React from "react"
import {TEvent, PanelGraphSummary, PanelGraphData} from "../../types/types"
import {theme} from "../../styles/MuiThemes";


interface SetTestCodePanelGraphState {
    complete: boolean,
    msg: string,
    testCodeToUpdate: string,
    currentPanelGraph: PanelGraphSummary,
    newPanelGraph: any,
    newTestCode: string,
    panelGraphListInSameContentArea: PanelGraphSummary[]
}

export class SetTestCodePanelGraph extends React.Component<{}, SetTestCodePanelGraphState> {

    constructor(props: {}) {
        super(props)
        this.state = {
            complete: false,
            msg: "",
            testCodeToUpdate: "",
            currentPanelGraph: {panelgraphid: 0, name: '', contentareaid: 0, skills: [], productId:0},
            newPanelGraph: "",
            newTestCode: "",
            panelGraphListInSameContentArea: []
        }
    }

    getPanelGraphDataByUsername = async (username: string) => {
        const panelGraphData: PanelGraphData = await ApiService.getPanelGraphDataByUsername(username)

        if (panelGraphData.currentPanelGraph.panelgraphid === 0) {
            this.setState({
                msg: `There are no panel graphs associated with Test Code ${username}.`
            })
        }
        else {
            this.setState({
                currentPanelGraph: panelGraphData.currentPanelGraph,
                panelGraphListInSameContentArea: panelGraphData.panelGraphListInSameContentArea
            })
        }
    }

    updateTestCodePanelGraph = async (testCodeToUpdate: string, newTestCode: string, newPanelGraph: string) => {
        const updateResult: string = await ApiService.updateTestCodePanelGraph(testCodeToUpdate, newTestCode, newPanelGraph)

        this.setState({
            msg: updateResult,
            complete: true
        })
    }

    snackBar = () => {

        const {msg} = this.state

        const onClose = () => {
            this.setState({msg: ""})
        }

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                open={!!msg}
                autoHideDuration={6000}
                onClose={onClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{msg}</span>}
                action={[
                    <Button key={"undo"} color={"secondary"} size={"small"} onClick={onClose}>
                        Got it!
                    </Button>
                ]}
            />
        )
    }

    findTestCodeForm = () => {
        const onFind = () => {
            this.getPanelGraphDataByUsername(this.state.testCodeToUpdate)
        }

        return (
            <div>
                <FormGroup>
                    <div style={{margin: theme.spacing(1,1)}}>
                        <TextField
                            style={{width: "100%"}}
                            label="Test Code to Update"
                            type="text"
                            value={this.state.testCodeToUpdate}
                            placeholder="Test Code to Update"
                            data-tst-id="find-test-code-to-update"
                            onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                                if (e.key === "Enter") {
                                    onFind()
                                }
                            }}
                            onChange={(e: TEvent) => {
                                this.setState({
                                    testCodeToUpdate: e.target.value
                                })
                            }}
                            autoFocus={true}
                        />
                    </div>
                    <div style={{margin: theme.spacing(1,1)}}>
                        <Button
                            variant={"outlined"}
                            onClick={onFind}
                        >Find Test Code</Button>
                    </div>
                </FormGroup>
            </div>
        )
    }

    updateTestCodePanelGraphForm = () => {
        const onUpdateTestCode = () => {
            this.updateTestCodePanelGraph(this.state.testCodeToUpdate, this.state.newTestCode, this.state.newPanelGraph)
        }

        return (
            <div>
                <FormGroup>

                    <div style={{margin: theme.spacing(1,1)}}>
                        <TextField
                            style={{width: "100%"}}
                            label="Test Code to Update"
                            type="text"
                            value={this.state.testCodeToUpdate}
                            data-tst-id="test-code-to-update"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </div>

                    <div style={{margin: theme.spacing(1,1)}}>
                        <TextField
                            style={{width: "100%"}}
                            label="Current Panel Graph"
                            type="text"
                            value={this.state.currentPanelGraph.name + ' (' + this.state.currentPanelGraph.skills.join('/').toLowerCase() + ')'}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </div>

                    <div style={{margin: theme.spacing(1,1)}}>
                        <TextField
                            style={{width: "100%"}}
                            label="New Test Code"
                            type="text"
                            value={this.state.newTestCode}
                            placeholder="New Test Code"
                            data-tst-id="new-test-code"
                            onChange={(e: TEvent) => {
                                this.setState({
                                    newTestCode: e.target.value
                                })
                            }}
                        />
                    </div>

                    <div style={{margin: theme.spacing(1,1)}}>
                        <InputLabel shrink>
                            New Panel Graph
                        </InputLabel>
                        <Select
                            style={{width: "100%"}}
                            value={this.state.newPanelGraph}
                            onChange={(e) => {
                                this.setState({
                                    newPanelGraph: e.target.value
                                })
                            }}
                            displayEmpty
                            autoFocus={true}
                            required={true}
                        >
                            {this.state.panelGraphListInSameContentArea.map(panelGraph =>
                                (panelGraph.panelgraphid !== this.state.currentPanelGraph.panelgraphid) ? this.newPanelGraphMenuItem(panelGraph) : null
                            )}
                        </Select>
                    </div>
                    <div hidden={this.state.complete} style={{margin: theme.spacing(1,1)}}>
                        <Button
                            variant={"outlined"}
                            onClick={onUpdateTestCode}
                            disabled={this.state.newPanelGraph === ""}
                        >Update Test Code</Button>
                    </div>

                </FormGroup>
            </div>
        )
    }

    newPanelGraphMenuItem(panelGraph: PanelGraphSummary) {
        return (
            <MenuItem
                key={panelGraph.panelgraphid}
                value={panelGraph.panelgraphid}
            >
                {panelGraph.name} ({panelGraph.skills.join('/').toLowerCase()})
            </MenuItem>
        )
    }

    render() {
        return (
            <>
                {this.state.panelGraphListInSameContentArea.length > 0 ? this.updateTestCodePanelGraphForm() : this.findTestCodeForm()}
                {this.snackBar()}
            </>
        )
    }

}

