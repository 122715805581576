/**
 * Given a nullable or undefinable variable, prove it is not null or undefined, otherwise return a defined, non-null
 * default value
 * @param {T} a Variable to prove is defined and non-null
 * @param {T} b Default to return if `a` is proven to be undefined or null
 * @returns {T} A value which is not null and defined
 */
import {TCurrentTargetValue} from "../types/types"

/**
 * Given a nullable or undefinable variable, prove it is not null or undefined, throw an Error
 * @param {T | null | undefined} a Variable to prove is a defined, non-null type
 * @param {Error} err Error to throw if the value IS null or undefined
 * @returns {T} A defined, non-null value
 */
export function elvis<T>(a: T | null | undefined, err: Error): T {
    if (a == null) {
        throw err
    } else {
        return a
    }
}

/**
 * Given a nullable or undefinable value, prove it is not null or undefined. Otherwise you
 * can throw an error, supply a default, or supply a lambda that returns a default
 * @param {T} proveIsNotNull The value to prove is not null/undefined, returned if it is not null or undefined
 * @param {Error | (() => T) | T} doIfIsNull What to do if it is null
 * @returns {T} the value as a non-null type.
 */
export function to<T>(proveIsNotNull: T | null | undefined, doIfIsNull: Error | T | (() => T)): T {
    if (proveIsNotNull === null || proveIsNotNull === undefined) {
        return coerceFailed(doIfIsNull)
    } else {
        return proveIsNotNull
    }
}

/**
 * When one of these functions fails to coerce to the
 * desired type they can either throw an error, return a default, or supply an
 * anonymous function which returns the desired ty
 * @param {Error | (() => T) | T} failureAction
 * @returns {T}
 */
export function coerceFailed<T>(failureAction: Error | T | (() => T)): T {
    if (failureAction instanceof Error) {
        throw failureAction
    } else if (typeof failureAction === "function") {
        return (failureAction as () => T)()
    } else {
        return failureAction
    }
}

export function formString(formInput: TCurrentTargetValue, doIfCannotCoerce: Error | string | (() => string)): string {
    if (!formInput) {
        return coerceFailed(doIfCannotCoerce)
    } else {
        if (typeof formInput === "string") {
            return formInput
        } else if (typeof formInput === "number") {
            return formInput.toString()
        } else {
            return coerceFailed(doIfCannotCoerce)
        }
    }
}
