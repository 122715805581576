import {createStyles, makeStyles, Paper} from "@material-ui/core"
import {Grid} from "@mui/material"
import React from "react"
import {AvantColors} from "../../../../styles/AvantColors"
import {BodyText, H1Text, LabelText} from "../../../../styles/AvantTypography"
import {theme} from "../../../../styles/MuiThemes"
import {productStore} from "../../../common/products/ProductStore"
import {PLACE_PRODUCT_ID} from "../../../../util/Constants"
import {GroupType} from "../../../common/group/models/GroupMaster"
import LanguageUtils from "../../../../util/LanguageUtils"

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            background: AvantColors.REPORT_BACKGROUND_GRAY_DARK,
            width: "100%"
        },
        section: {
            alignItems: "flex-end",
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%"
        },
        districtSchool: {
            fontSize: "1.5rem",
            marginTop: "1rem"
        }
    }),
)

export interface GroupData {
    id: number
    type: string
    name: string
}

interface IndividualReportHeaderProps {
    firstName?: string
    lastName?: string
    loginName?: string
    language?: string
    date?: string
    groupData?: GroupData[]
}

export const IndividualReportHeader: React.FC<IndividualReportHeaderProps> = (props) => {
    const classes = useStyles()

    if (props.groupData === undefined) {
        return <div/>
    }

    const productId = productStore.loginProduct ? productStore.loginProduct.productId : null
    const {groupData} = props
    const language = LanguageUtils.removeTilde(props.language)

    const districtOrCustomer = groupData.find(group => group.type === GroupType.DISTRICT || group.type === GroupType.CUSTOMER)
    const schoolOrLocation = groupData.find(group => group.type === GroupType.SCHOOL || group.type === GroupType.LOCATION)

    const dateType = productId === PLACE_PRODUCT_ID.value() ? "Finish Date" : "Start Date"

    if (districtOrCustomer === undefined || schoolOrLocation === undefined) {
        return <div/>
    }

    return (
        <Paper className={classes.root}>
            <Grid container spacing={1}  className={classes.section}>
                <Grid  item xs={12} sm={9} md={6} lg ={3} xl={3}>
                    <Grid>
                    <H1Text>
                        {props.firstName} {props.lastName}
                    </H1Text>
                    </Grid>
                    <Grid className={classes.districtSchool}>
                        {districtOrCustomer.name} | {schoolOrLocation.name}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg={3} xl={3} >
                <LabelAndText label={"Login Name"} text={props.loginName}/>
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg ={3} xl={3} >
                <LabelAndText label={"Language"} text={language}/>
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg ={3} xl={3} >
                <LabelAndText label={dateType} text={props.date}/>
                </Grid>
            </Grid>

        </Paper>
    )
}

export const LabelAndText = (props: { label: string, text?: string }) => (
    <div style={{display: "flex", marginTop: theme.spacing(1)}}>
        <LabelText style={{marginRight: theme.spacing(1)}}>{props.label}:</LabelText>
        <BodyText>{props.text}</BodyText>
    </div>
)
