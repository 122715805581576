import * as React from "react"
import {ReportTakeRow} from "../../../reports/class/models/ReportTakeRow"
import {UnstyledLink} from "../../../generic-components/UnstyledLink"
import {IconButton} from "@material-ui/core"
import {Assessment} from "@material-ui/icons"
import {TimeUtil} from "../../../../util/TimeUtil"
import {MAX_DISPLAYABLE_TEST_DURATION} from "../../../reports/ReportUtils"
import {PanelLevel, PanelSkillEnum} from "../../../../types/types"
import {
    AvantBadgeEarlyExit, AvantBadgeNotRatable,
    AvantBadgeNotStarted,
    AvantBadgeScoredByEducator, AvantBadgeScoringInProgress, AvantBadgeSectionInProgress
} from "../../../generic-components/AvantBadge"
import {groupMasterStore} from "../../../common/group/GroupMasterStore"
import {reportsStore} from "../../../reports/common/stores/ReportStore"
import {ReportSubPaths} from "../../../reports/ReportSubPaths"
import {productStore} from "../../../common/products/ProductStore"
import {PLACE_PRODUCT_ID} from "../../../../util/Constants"
import {findPLACEIndividualReport} from "../../../reports/PlaceFamily/PLACEReportPage"
import RenderColumn, {TestTakerTableCell} from "./TestTakerTableCell"
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid-pro"

export interface SkillColumnData {
    title: string
    key: string
    symbol?: PanelSkillEnum,
    scaledScoreKey?: string
    noWrap?: boolean
    centerContent?: boolean
    styles?: React.CSSProperties
}

function getBadgeOrScore(
    scaledScoreCalc: boolean,
    skillValNullable?: string,
    scaledValNullable?: string,
    isPlaceC?: boolean,
    isSHL?: boolean
) {
    const skillVal = skillValNullable == null ? "" : skillValNullable
    const scaledVal = scaledValNullable == null ? "" : scaledValNullable
    if ((skillVal === PanelLevel.PANEL_NOT_STARTED || skillVal === "") && scaledVal === "") {
        if (isSHL) {
            return <AvantBadgeEarlyExit/>
        }

        return <AvantBadgeNotStarted/>
    }
    if (skillVal === PanelLevel.PANEL_COMPLETE && isPlaceC) {
        return <AvantBadgeScoredByEducator/>
    }
    if (skillVal === PanelLevel.PANEL_COMPLETE && scaledVal === "") {
        return <AvantBadgeScoringInProgress/>
    }
    if (skillVal === PanelLevel.PANEL_STARTED && scaledVal === "") {
        return <AvantBadgeSectionInProgress/>
    }
    if (skillVal === PanelLevel.PANEL_NOT_RATABLE && scaledVal === "") {
        return <AvantBadgeNotRatable/>
    }
    if (scaledVal === "" && scaledScoreCalc) {
        return ""
    }
    if (scaledVal !== "") {
        return parseInt(scaledVal)
    }
    return skillVal
}

function rowDurationString(duration?: number): string {
    if (duration) {
        const durStr = (duration > MAX_DISPLAYABLE_TEST_DURATION) ?
            `${MAX_DISPLAYABLE_TEST_DURATION}+`:
            `${duration}`

        return `${durStr} mins`
    }

    return ""
}

// Returns stringified data that will be passed down to
// individual reports via the react link's state
function additionalReportData(row: ReportTakeRow): string {
    const productId = (productStore.loginProduct) ? productStore.loginProduct.productId : null
    switch (productId) {
        case PLACE_PRODUCT_ID.value():
            return findPLACEIndividualReport(row.takeId)
        default:
            return JSON.stringify(groupMasterStore.groupInfo)
    }
}

export const baseLeftTableColumns: GridColDef[] = [
    {
        field: "report",
        headerName: "Report",
        renderCell: (params: GridRenderCellParams<any,any>) => {
            const {row} = params
            return (
                <TestTakerTableCell>
                    <UnstyledLink
                        to={{
                            // TODO: Make this link work
                            pathname: ReportSubPaths.individual(row.takeId, reportsStore.searchFilters),
                            state: {report: additionalReportData(row)}
                        }}
                        target={['pl', 'plb', 'plc'].includes(row.product_package!) ? '_blank' : ''}
                    >
                        <IconButton
                            size={"small"}
                            aria-label="Student Summary"
                        >
                            <Assessment/>
                        </IconButton>
                    </UnstyledLink>
                </TestTakerTableCell>
            )
        },
        width: 100,
    },
    {
        field: "lastName",
        headerName: "Last Name",
        renderCell: (params: GridRenderCellParams<any, string>) => RenderColumn(params.value, true, {
            title: "",
            key: "",
            styles: {textAlign: "left"}
        }, params.row),
        width: 150,
    },
    {
        field: 'firstName',
        headerName: "First Name",
        renderCell: (params: GridRenderCellParams<any, string>) => RenderColumn(params.value),
        width: 150,
    },
]

const baseRightTableColumns: GridColDef[] = [
    {
        field: "resumeKey",
        headerName: "Login Name",
        renderCell: (params: GridRenderCellParams<any, string>) => RenderColumn(params.value, true)
    },
    {
        field: "compositeScore",
        headerName: "Composite Score",
        renderCell: (params: GridRenderCellParams<any, number>) => RenderColumn(
            params.value !== 0 && params.value != null ? params.value : "",
            false,
        )
    },
    {
        field: "startDate",
        headerName: "Start Date",
        renderCell: (params: GridRenderCellParams<any, number>) =>
            RenderColumn(TimeUtil.formatMilliToMonthDayYearHoursMinutesLong(params.value))
    },
    {
        field: "finishDate",
        headerName: "Finish Date",
        renderCell: (params: GridRenderCellParams<any, number>) =>
            RenderColumn(TimeUtil.formatMilliToMonthDayYearHoursMinutesLong(params.value))
    },
    {
        field: "testDuration",
        headerName: "Length",
        renderCell: (params: GridRenderCellParams<any, number>) => RenderColumn(rowDurationString(params.value)),
    },
]

export function buildReportColumns(skillColumns: SkillColumnData[], printing: boolean): {left: GridColDef[], right: GridColDef[]} {
    const columns = {left: [...baseLeftTableColumns], right: [...baseRightTableColumns]}

    columns.left.forEach((column: GridColDef, index: number) => {
        // Remove all columns except for first and last name on printing screens
        if (printing) {
            if (column.headerName !== "Last Name" && column.headerName !== " First Name") {
                columns.left.splice(index, 1)
                return
            }
        }
    })

    const skills: GridColDef[] = []
    if (skillColumns) {
        skillColumns.forEach((skill: SkillColumnData) => {
            const column: GridColDef = {
                field: skill.key,
                headerName: skill.title,
            }

            if (skill.title.includes("Scaled") && skill.scaledScoreKey) {
                column.field = skill.scaledScoreKey
                column.renderCell = (params: GridRenderCellParams<any, any>) => {
                    const {row} = params
                    if (skill.scaledScoreKey && row[skill.scaledScoreKey]) {
                        return RenderColumn(
                            getBadgeOrScore(true, row[skill.key], row[skill.scaledScoreKey]),
                            false,
                            skill
                        )
                    }
                    return <></>
                }

                skills.push(column)
            } else {
                column.renderCell = (params: GridRenderCellParams) => RenderColumn(
                    getBadgeOrScore(false, params.row[skill.key]),
                    true, skill, params.row
                )

                skills.push((column))
            }
        })

        // Add the skill columns after the login name
        columns.right.splice(
            columns.right.map((col: GridColDef) => col.headerName).indexOf("Composite Score"),
            0,
            ...skills
        )
    }

    columns.right.forEach((column: GridColDef)=> {
        column.flex = 1
    })

    return columns
}
