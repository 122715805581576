import React from "react"
import {HashPattern} from "../HashPattern"
import {BarProperties} from "../../../../common/components/aggregate-charts/BarUtils"


export interface SkillAverageBarProps {
    id: string,
    x: number,
    y: number,
    height: number,
    width: number,
    borderRadius: number,
    fill: BarProperties
}

interface BarSvgProps {
    id: string,
    x: number,
    y: number,
    height: number,
    width: number,
    borderRadius: number,
    fill: string
}


const BarSvg: React.FC<BarSvgProps> = ({id, x, y, height, width, borderRadius, fill}) => {
    return (
        <path
            id={id}
            d={`
                M${x},${y! + height!},
                v${-height! + borderRadius}
                q0,-${borderRadius} ${borderRadius},-${borderRadius}
                h${width! - borderRadius}
                q${borderRadius},0 ${borderRadius},${borderRadius}
                v${height! - borderRadius}
                z 
            `}
            fill={fill}
        >
        </path>
    )
}

export const SkillAverageBar: React.FC<SkillAverageBarProps> = (props) => {
    const hashId = `${props.id}hash`
    const hashFill = props.fill.hashFill
    return (
        <>
            <BarSvg {...props} fill={props.fill.barFill}/>
            {hashFill && (
                <>
                    <HashPattern id={hashId} stroke={hashFill} fillColor={props.fill.barFill}/> {/* fill the stripes with the same as the color fill */}
                    <BarSvg {...props} height={5} fill={`url(#${hashId})`}/>
                </>
            )
            }
        </>
    )
}
