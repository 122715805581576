/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {Theme} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import createStyles from "@material-ui/core/styles/createStyles"
import makeStyles from "@material-ui/core/styles/makeStyles"
import TextField from "@material-ui/core/TextField"
import Tooltip from "@material-ui/core/Tooltip"
import React, {memo, useEffect} from "react"
import {H2Text} from "../../../../styles/AvantTypography"
import {AvantPaper} from "../../../generic-components/AvantPaper"
import {PlaceSearchFilters} from "./AvantNewDatePicker/AvantNewDateRangePickerHelpers"
import {AvantCircularProgress} from "../../../common/loaders/AvantCircularProgress"
import {AvantControlledDateRangePicker, DatePickerOptions} from "./AvantNewDatePicker/AvantControlledDateRangePicker"
import {DateRange} from "@mui/x-date-pickers-pro";
import moment, {Moment} from "moment";

const TestGroupInstanceSearchStyles = makeStyles((theme: Theme) =>
    createStyles({
        root_TestGroupInstanceSearch: {
            padding: theme.spacing(1),
            "& .MuiTextField-root": {
                margin: theme.spacing(1),
                width: "24ch",
            },
            maxWidth: 1440,
        },
        grid: {
            flexGrow: 1
        },
        buttonContainer: {
            display: "flex"
        },
        buttonHolder: {
            marginLeft: "auto",
            marginRight: theme.spacing(1),
        },
        buttons: {
            margin: theme.spacing(1)
        },
        label: {
            zIndex: 0
        },
        dateRangePickerStyles: {
            "& .MuiInputBase-root": {
                paddingTop: 0,
                paddingBottom: 0,
                "& .MuiButtonBase-root": {
                    paddingTop: 8.5,
                    paddingBottom: 8.5,
                },
                "& .MuiInputBase-input": {
                    paddingTop: 8.5,
                    paddingBottom: 8.5,
                }
            }
        }
    })
)



interface TestGroupInstanceSearchProps {
    clearParentSearchFilters: () => void,
    submitParentSearchFilters: () => void,
    parentFilters: PlaceSearchFilters,
    setParentFilters: (e: React.ChangeEvent<HTMLInputElement>) => void,
    searchPressed: boolean,
    jsxLocationElementList: React.ReactNode[],
    jsxLanguageElementList: React.ReactNode[],
    setParentDateRange: (range: any, options: DatePickerOptions|null) => void
}

// TODO: We still need to re-factor the date picker before it can be re-used. We also need to wire up the search to the API
const TestGroupInstanceSearch: React.FC<TestGroupInstanceSearchProps> = (
    {
        clearParentSearchFilters,
        submitParentSearchFilters,
        parentFilters,
        setParentFilters,
        searchPressed,
        jsxLocationElementList,
        jsxLanguageElementList,
        setParentDateRange
    }
    ) => {

    const classes = TestGroupInstanceSearchStyles()

    useEffect(() => {

    }, [jsxLanguageElementList, jsxLocationElementList]);

    const geDateRanges = (): DateRange<Moment> => {
        const start: Moment | null = parentFilters.dateRangeFilters.startDate ? moment(parentFilters.dateRangeFilters.startDate) : null
        const end: Moment | null = parentFilters.dateRangeFilters.endDate ? moment(parentFilters.dateRangeFilters.endDate) : null
        return [start,end]
    }


    return (
        <AvantPaper>
            <div className={classes.root_TestGroupInstanceSearch} data-tst-id={"TestGroupInstanceSearch-Container"}>
                <div data-tst-id={"TestGroupInstanceSearch-Title"}>
                    <H2Text>Search</H2Text>
                </div>
                { jsxLanguageElementList.length > 0 && jsxLocationElementList.length > 0
                    ? (
                        <div data-tst-id={"TestGroupInstanceSearch-FormContainer"}>
                            <form noValidate={true} autoComplete={"on"}>
                                <Grid container={true} className={classes.grid} spacing={1}>
                                    <Grid item={true} xs={"auto"}>
                                        <Tooltip
                                            title={"Select testing group location"}
                                        >
                                            <TextField
                                                className={classes.label}
                                                data-tst-id={"SearchFormSchoolName"}
                                                margin={"dense"}
                                                select={true}
                                                fullWidth={true}
                                                name={"Location"}
                                                label={"Location"}
                                                variant={"outlined"}
                                                value={parentFilters.selectedLocationId}
                                                onChange={setParentFilters}
                                                disabled={jsxLocationElementList.length === 1}
                                            >
                                                {jsxLocationElementList}
                                            </TextField>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item={true} xs={"auto"}>
                                        <Tooltip title={"Language Select"}>
                                            <TextField
                                                className={classes.label}
                                                data-tst-id="SearchFormLanguageName"
                                                disabled={jsxLanguageElementList.length === 1}
                                                margin="dense"
                                                select={true}
                                                fullWidth={true}
                                                name="Language"
                                                label="Language"
                                                variant="outlined"
                                                value={parentFilters.contentAreaId}
                                                onChange={setParentFilters}
                                            >
                                                {jsxLanguageElementList}
                                                {/*/!* TODO: Move to PLACEReportPage.tsx... *!/*/}
                                                {/*{ languagesAvailable.length > 1 &&*/}
                                                {/*    <MenuItem aria-label={"Language Select All"} value={0}>All Languages</MenuItem>*/}
                                                {/*}*/}
                                                {/*{*/}
                                                {/*    languagesAvailable*/}
                                                {/*        .sort((a, b) =>*/}
                                                {/*            (a.name > b.name) ? 1 : -1)*/}
                                                {/*        .map(opt => (*/}
                                                {/*            <MenuItem*/}
                                                {/*                aria-label={`Language Select ${opt.name}`}*/}
                                                {/*                key={opt.id}*/}
                                                {/*                value={opt.id}*/}
                                                {/*            >*/}
                                                {/*                {opt.name}*/}
                                                {/*            </MenuItem>*/}
                                                {/*    ))*/}
                                                {/*}*/}
                                            </TextField>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item={true} xs={"auto"}>
                                        <Tooltip title={"First Name Filter"}>
                                            <TextField
                                                className={classes.label}
                                                data-tst-id={"SearchFormFirstName"}
                                                aria-label={"Search Form First Name Filter"}
                                                margin={"dense"}
                                                fullWidth={true}
                                                label={"First Name"}
                                                name={"firstName"}
                                                variant={"outlined"}
                                                value={parentFilters.firstName}
                                                onChange={setParentFilters}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item={true} xs={"auto"}>
                                        <Tooltip title={"Last Name Filter"}>
                                            <TextField
                                                className={classes.label}
                                                data-tst-id={"SearchFormLastName"}
                                                aria-label={"Search Form Last Name Filter"}
                                                margin={"dense"}
                                                fullWidth={true}
                                                label={"Last Name"}
                                                name={"lastName"}
                                                variant={"outlined"}
                                                value={parentFilters.lastName}
                                                onChange={setParentFilters}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item={true} xs={"auto"}>
                                        <Tooltip title={"Login Name Filter"}>
                                            <TextField
                                                className={classes.label}
                                                data-tst-id={"SearchFormLoginName"}
                                                aria-label={"Search Form Login Name Filter"}
                                                margin={"dense"}
                                                fullWidth={true}
                                                label={"Login Name"}
                                                name={"loginName"}
                                                variant={"outlined"}
                                                value={parentFilters.loginName}
                                                onChange={setParentFilters}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item={true} xs={"auto"}>
                                        <AvantControlledDateRangePicker
                                            setParentDateRange={setParentDateRange}
                                            dateRange={geDateRanges()}
                                        />
                                    </Grid>
                                </Grid>
                                <div
                                    data-tst-id={"PlaceReportSearchButtonContainer"}
                                    className={classes.buttonContainer}
                                >
                                    <div
                                        className={classes.buttonHolder}
                                    >
                                        { searchPressed &&
                                            <Tooltip title={"Clear Selected Filters"}>
                                                <Button
                                                    variant={"outlined"}
                                                    color={"primary"}
                                                    aria-label={"Clear Search Filters"}
                                                    className={classes.buttons}
                                                    onClick={clearParentSearchFilters}
                                                >
                                                    Clear
                                                </Button>
                                            </Tooltip>
                                        }
                                        <Tooltip
                                            title={"Search For Test Instances"}
                                        >
                                            <Button
                                                variant={"contained"}
                                                color={"primary"}
                                                aria-label={"Search Using Filters"}
                                                className={classes.buttons}
                                                onClick={submitParentSearchFilters}
                                            >
                                                Search
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <AvantCircularProgress />
                    )
                }


            </div>
        </AvantPaper>
    )

}

export default memo(TestGroupInstanceSearch)
