import React, {createRef} from "react"
import {AuthUser} from "../../app/common/authentication/models/AuthUser"

import {ProductDriver} from "../../app/common/products/ProductStore"
import {IItem, ItemFormatEnum, LoginProductContentAreaData, TestEngineOptions, TLocalAnswer, VideoContent} from "../../types/types"
import Instructions from "../Instructions"

interface InstructionPanelProps {
    item: IItem
    takePanelId?: number
    config: TestEngineOptions
    driver: ProductDriver
    isRtlLayout?: boolean
    authUser: AuthUser
    product: LoginProductContentAreaData
    handleEditItemContent: (itemContentId: number, content: string, itemId?: number) => Promise<void>
    handleUpdateItemFile: (itemContentId: number, content: string | VideoContent) => void
    handleAnswerChange: (answer: TLocalAnswer | null) => void
    setInstructionContent: (instance: HTMLDivElement) => void
}

export default class InstructionPanel extends React.Component<InstructionPanelProps, {}> {
    instructions: any | undefined

    constructor(props: InstructionPanelProps) {
        super(props)
        this.instructions = createRef()
    }

    render() {
        const {
            item,
            takePanelId,
            isRtlLayout,
            config,
            authUser,
            driver,
            handleAnswerChange,
            handleEditItemContent,
            handleUpdateItemFile,
            setInstructionContent,
            product
        } = this.props

        return (
            <div className={item.format === ItemFormatEnum.BLANK ? "item-instructions__blank-item" : undefined}>
                <div ref={(node: HTMLDivElement) => setInstructionContent(node)}>
                    <Instructions
                        data-tst-id={`instructions-${item.id}`}
                        handleSelectText={handleAnswerChange}
                        takePanelId={takePanelId!!}
                        item={item}
                        isRtlLayout={isRtlLayout}
                        innerRef={this.instructions}
                        smallImages={config.smallImages}
                        authUser={authUser}
                        handleEditItemContent={handleEditItemContent}
                        handleUpdateItemFile={handleUpdateItemFile}
                        driver={driver}
                        testName={config.testName ? config.testName : ""}
                        product={product}
                        handleWindowResize={() => { return }}
                    />
                </div>
            </div>
        )
    }
}
