import {FormControl, MenuItem, TextField} from "@material-ui/core"
import React from "react"

interface LevelSelectProps {
    onLevelChanged: (selectedLevel?: string) => void
    currentLevel?: string
    disabled?: boolean
}

interface LevelSelectState {
    currentLevel?: string
    errors: string[]
}

export default class LevelSelectDropdown extends React.Component<LevelSelectProps, LevelSelectState> {
    constructor(props: LevelSelectProps) {
        super(props)

        this.state = {
            currentLevel: props.currentLevel === null ? undefined : props.currentLevel,
            errors: []
        }
    }

    componentDidUpdate(prevProps: LevelSelectProps) {
        if (this.props.currentLevel !== prevProps.currentLevel) {
            this.setState({
                currentLevel: this.props.currentLevel === null ? undefined : this.props.currentLevel
            })
        }
    }

    handleChange = (event: any) => {
        const currentLevel = event.target.value !== null ? event.target.value : undefined
        this.setState({ currentLevel })
        this.props.onLevelChanged(currentLevel)
    }

    renderMenuItems = () => {
        const menuItems: JSX.Element[] = []

        for (let x = 1; x <= 10; x++) {
            menuItems.push(
                <MenuItem
                    key={`level-${x}`}
                    id={`level-${x}`}
                    value={x}
                    data-tst-id={x}
                >
                    {x}
                </MenuItem>
            )
        }

        return menuItems
    }

    render() {
        const { errors } = this.state
        const currentLevel = this.state.currentLevel ? this.state.currentLevel : ""

        return (
            <FormControl>
                <div className="error" hidden={errors.length === 0}>
                    {errors.join(" ") || "placeholder"}
                </div>
                <TextField
                    id={"level-dropdown"}
                    select={true}
                    onChange={this.handleChange}
                    value={currentLevel}
                    helperText="Level"
                    style={{width: 200}}
                    disabled={this.props.disabled}
                >
                    <MenuItem value={undefined} />
                    {this.renderMenuItems()}
                </TextField>
            </FormControl>
        )
    }
}