import React from "react"
import {authStore} from "../../app/common/authentication/AuthStore"
import HelperService from "../../services/HelperService"
import {avantHistory} from "../App/App"
import Button from "../Button/Button"
import {Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid} from "@material-ui/core";
import {H4Text} from "../../styles/AvantTypography";
import {AvantColors} from "../../styles/AvantColors";
import {RoutePaths} from "../../app/routes/Routes";
import {theme} from "../../styles/MuiThemes"
import {observer} from "mobx-react";
import {AgreementContent} from "./TestTakerAgreementContent"

interface TestTakerAgreementModalProps {
    testName: string
    agree: () => void

}
@observer
class TestTakerAgreementModal extends React.Component<TestTakerAgreementModalProps, {}> {
    constructor(props: TestTakerAgreementModalProps) {
        super(props)
    }
    // async UNSAFE_componentWillMount() {
    //     authStore.didTheyAgree()
    // }

    async componentDidUpdate() {
        await authStore.didTheyAgree()
    }

    agreeToEULA = () => {
        this.props.agree()
    }
    disagree = () => {
        const authUser = (authStore && authStore.auth) ? authStore.auth : null
        const isSSOLogin = (authUser && authUser.ssoId) ? true : false

        if(isSSOLogin) avantHistory.push(RoutePaths.SSO_STUDENT)
        else HelperService.logout(undefined)
    }
    renderDialogContent = (testName: string ): JSX.Element => {         
     return <AgreementContent testName={testName}/>      
    }
    render() {
        return (
            <Dialog open={!authStore.agreedToEULA} aria-labelledby="form-dialog-title" PaperProps={{
                style: {
                    boxShadow: '0px 9px 46px 8px rgb(0 0 0 / 12%)',
                }}}>

                <DialogTitle disableTypography={true} style={{textAlign: "center", padding: theme.spacing(2)}}>
                    <H4Text style={{fontWeight: 900, color: AvantColors.REPORT_FONT_COLOR_BLACK}}>Test Taker Guidelines</H4Text>
                </DialogTitle>
                <Divider style={{width: "100%"}}/>
                <DialogContent>
                    {this.renderDialogContent(this.props.testName)}
                </DialogContent>
                <DialogActions>
                    <Grid container={true} style={{margin: "0 0 0 0" }} alignItems={"center"} alignContent={"center"} justify={"center"} spacing={6}>
                        <Grid item={true} xs={6}>
                            <Button
                                className="avant-button--primary avant-button--block avant-button--default"
                                block={true}
                                testId="agree-to-test"
                                onClick={this.agreeToEULA}
                            >
                                I AGREE
                            </Button>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Button
                                className="avant-button--secondary avant-button--block avant-button--default"
                                block={false}
                                testId="disagree-logout"
                                onClick={this.disagree}
                            >
                                DISAGREE, LOGOUT
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        )
    }
}
export default TestTakerAgreementModal




