import React from "react"
import {IItem, TLocalAnswer} from "../../types/types"
import SpecialCharacterKeyboard from "../SpecialCharacterKeyboard/SpecialCharacterKeyboard"

interface FillInTheBlankProps {
    item: IItem
    answers: TLocalAnswer
    handleAnswersChange: (answers: string[]) => void
    setBlankCount: (count: number) => void
}

interface FillInTheBlankState {
    currentAnswers: string[]
}

export default class FillInTheBlank extends React.Component<FillInTheBlankProps, FillInTheBlankState> {
    mapOfInputElements: Map<number, HTMLInputElement> = new Map()
    currentIndex: number = 0
    currentCursorPosition = 0

    constructor(props: FillInTheBlankProps) {
        super(props)
        this.state = {
            currentAnswers: props.answers ? (props.answers as string[]) : []
        }
    }

    componentDidMount() {
        const {item, setBlankCount} = this.props
        const contents: string[] = (item.choices[0].content as string).split(/<>/)
        setBlankCount(contents.length - 1)
    }

    componentDidUpdate() {
        const element: HTMLInputElement | undefined = this.mapOfInputElements.get(this.currentIndex)
        if (element !== undefined) {
            element.focus()
            element.setSelectionRange(this.currentCursorPosition, this.currentCursorPosition)
        }

        this.mapOfInputElements.forEach(element => {
            element.onpaste = () => false
            element.oncopy = () => false
            element.oncut = () => false
            element.onselect = () => false
            element.ondragover = () => false // ondragover and ondrop will prevent users from dragging and dropping
            element.ondrop = () => false //  content into the textarea during writing tests
            element.spellcheck = false
            element.autocomplete = "off"
        })
    }

    setTextInputRef = (inputBox: HTMLInputElement, index: number) => {
        this.mapOfInputElements.set(index, inputBox)
    }

    handleAnswerChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const element: HTMLInputElement | undefined = this.mapOfInputElements.get(this.currentIndex)
        if (element !== undefined) {
            this.currentCursorPosition = element.selectionStart ? element.selectionStart : 0
        }
        const answer: string = event.target.value
        const answers = this.props.answers as string[]
        answers[index] = answer
        this.props.handleAnswersChange(answers)
    }

    insertCharacter = (character: string) => {
        const curIdx = this.currentIndex
        const answers = this.props.answers as string[]
        if (curIdx > -1) {
            const tmpElement: HTMLInputElement = this.mapOfInputElements.get(curIdx)!!
            if (tmpElement !== undefined && tmpElement !== null) {
                const firstIndex = tmpElement.selectionStart ? tmpElement.selectionStart : 0
                const lastIndex = tmpElement.selectionEnd ? tmpElement.selectionEnd : 0
                const insertVal = tmpElement.value.slice(0, firstIndex) + character + tmpElement.value.slice(lastIndex)
                if (lastIndex === tmpElement.value.length && lastIndex === firstIndex) {
                    tmpElement.value += character
                } else {
                    tmpElement.value = insertVal
                }

                answers[curIdx] = tmpElement.value
                this.currentCursorPosition = firstIndex + character.length
                this.props.handleAnswersChange(answers)
            }
        }
    }

    render() {
        const item: IItem = this.props.item
        const contents: string[] = (item.choices[0].content as string).split(/<>/)
        const hasCorrectAnswers = this.props.item.correctAnswers.length > 0
        const answers = this.props.answers as string[]
        return (
            <div key={item.id} data-tst-id="choices-wrapper" style={{paddingTop: 10}}>
                {contents.map((content: string, index: number) => {
                    const className = `input-box-${index}`
                    const nameByIndex = index.toString(10)
                    return (
                        <span key={Math.random()}>
                            {content}
                            {index !== contents.length - 1 && (
                                <input
                                    className={className}
                                    type="text"
                                    autoComplete="off"
                                    name={nameByIndex}
                                    onChange={(data: React.ChangeEvent<HTMLInputElement>) =>
                                        this.handleAnswerChange(index, data)
                                    }
                                    value={answers[index]}
                                    ref={(inputBox: HTMLInputElement) => this.setTextInputRef(inputBox, index)}
                                    onFocus={() => (this.currentIndex = index)}
                                />
                            )}
                        </span>
                    )
                })}
                <div className="special-characters-keyboard">
                    <SpecialCharacterKeyboard
                        contentAreaId={parseInt(this.props.item.languageId, 10)}
                        insertCharacter={this.insertCharacter}
                    />
                </div>
                {hasCorrectAnswers && (
                    <div style={{marginTop: 10}}>
                        !!! <span className="debug-correct-answer">{item.correctAnswers.join("; ")}</span>
                    </div>
                )}
            </div>
        )
    }
}
