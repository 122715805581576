import {Grid} from "@material-ui/core"
import * as React from "react"
import {CreateHash} from "./CreateHash"
import {ValidateHash} from "./ValidateHash"
import {Masquarade} from "./Masquarade";
import { authStore } from "../../../app/common/authentication/AuthStore";
import { MASQUARADE } from "../../../util/Constants";
export const BasicTools: React.FC = () => {
    return (
        <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
                <CreateHash/>
            </Grid>
            <Grid item={true} xs={12}>
                <ValidateHash/>
            </Grid>
            {authStore && authStore.auth && authStore.auth.userPermissions && authStore.auth.userPermissions.includes(MASQUARADE) && ( 
            <Grid item={true} xs={12}>
                <Masquarade/>
            </Grid>)}
        </Grid>
    )
}
