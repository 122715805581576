/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import React, {useState} from "react";
import {
    Box, Button,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch,
    TextField
} from "@material-ui/core"
import ProductSelectDropDown from "../../form-components/ProductSelectDropdown/ProductSelectDropDown"
import {ContentAreaId, GroupId, ProductId} from "../../../validation/ValidPrimaryKey"
import LanguageSelectDropDown from "../../form-components/LanguageSelectionDropDown/LanguageSelectDropDown"
import {Moment, TEvent} from "../../../types/types"
import AvantDatePicker from "../../form-components/AvantDatePicker/AvantDatePicker"
import {CredlyBadgeDropDown, GroupSelectDropDownContainer} from "./CredlySearchFormDropdowns"
import {theme} from "../../../styles/MuiThemes"
import moment from "moment"
import {H1Text} from "../../../styles/AvantTypography"

interface SearchFormProps {
    onSubmit: (errors: any) => void
}

export interface CredlyReportFilters {
    completedFromDate?: string,
    completedToDate?: string,
    badgeAwardedFromDate?: string,
    badgeAwardedToDate?: string,
    badgeEligible: boolean,
    productId?: number,
    groupId?: number,
    testCode: string,
    contentAreaId?: number,
    firstName: string,
    lastName: string,
    loginName: string,
    email: string,
    badgeId?: string
}

const defaultFilters: CredlyReportFilters = {
    completedFromDate: moment().subtract(1, "month").format(),
    completedToDate: moment().hour(23).minute(59).second(59).format(),
    badgeAwardedFromDate: undefined,
    badgeAwardedToDate: undefined,
    badgeEligible: true,
    productId: undefined,
    groupId: undefined,
    testCode: "",
    contentAreaId: undefined,
    firstName: "",
    lastName: "",
    loginName: "",
    email: "",
    badgeId: "",
}

const CredlyReportSearchForm: React.FC<SearchFormProps> = ({onSubmit}) => {
    const [reportQueryFilters, setFilters] = useState<CredlyReportFilters>(defaultFilters)

    const updateFilters = (filter: string, value: any) => {
       setFilters((prevState: CredlyReportFilters) => ({
           ...prevState,
           [filter]: value,
       }))
    }

    const resetFilters = () => {
        setFilters(defaultFilters)
    }

    return (
        <Box marginBottom={theme.spacing(1)}>
            <H1Text>Credly Report Tools</H1Text>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormGroup>
                                <ProductSelectDropDown
                                    data-tst-id={"product-select"}
                                    currentProduct={reportQueryFilters.productId ? new ProductId(reportQueryFilters.productId) : undefined}
                                    onProductChanged={(productId?: ProductId) => updateFilters("productId", Number(productId))}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <FormGroup
                                data-tst-id={"language-select"}
                            >
                                <LanguageSelectDropDown
                                    currentLanguage={reportQueryFilters.contentAreaId ? new ContentAreaId(reportQueryFilters.contentAreaId) : undefined}
                                    onLanguageChanged={(contentAreaId: ContentAreaId | undefined) => updateFilters("contentAreaId", Number(contentAreaId))}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <FormGroup style={{padding: theme.spacing(2), paddingLeft: 0}}>
                        <CredlyBadgeDropDown updateFilters={updateFilters}/>
                    </FormGroup>
                    <FormGroup>
                       <FormControlLabel
                           control={(
                               <Switch
                                   checked={reportQueryFilters.badgeEligible}
                                   onChange={() => updateFilters("badgeEligible", !reportQueryFilters.badgeEligible)}
                                   value={reportQueryFilters.badgeEligible}
                                   color={"primary"}
                               />
                           )}
                           label={"Credly Badge Eligible"}
                       />
                    </FormGroup>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormGroup>
                                <TextField
                                    label={"Test Code"}
                                    data-tst-id={"test-code"}
                                    type={"text"}
                                    value={reportQueryFilters.testCode}
                                    placeholder={"Test-Code"}
                                    onChange={(event: TEvent) => updateFilters("testCode", event.target.value)}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <FormGroup>
                                <TextField
                                    label={"Login Name"}
                                    data-tst-id={"login-name"}
                                    type={"text"}
                                    value={reportQueryFilters.loginName}
                                    placeholder={"Login Name"}
                                    onChange={(event: TEvent) => updateFilters("loginName", event.target.value)}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormGroup>
                                <TextField
                                    label={"First Name"}
                                    data-tst-id={"first-name"}
                                    type={"text"}
                                    value={reportQueryFilters.firstName}
                                    placeholder={"First Name"}
                                    onChange={(event: TEvent) => updateFilters("firstName", event.target.value)}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <FormGroup>
                                <TextField
                                    label={"Last Name"}
                                    data-tst-id={"last-name"}
                                    type={"text"}
                                    value={reportQueryFilters.lastName}
                                    placeholder={"Last Name"}
                                    onChange={(event: TEvent) => updateFilters("lastName", event.target.value)}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <FormGroup>
                        <TextField
                            label={"Email"}
                            data-tst-id={"email"}
                            type={"text"}
                            value={reportQueryFilters.email}
                            placeholder={"Email"}
                            onChange={(event: TEvent) => updateFilters("email", event.target.value)}
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <FormGroup
                        data-tst-id={"group-select"}
                    >
                        <GroupSelectDropDownContainer
                            updateFilters={updateFilters}
                            currentlySelectedGroup={reportQueryFilters.groupId ? new GroupId(reportQueryFilters.groupId) : undefined}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={2}>
                    <FormGroup
                        style={{marginTop: theme.spacing(2), marginBottom: theme.spacing(2)}}
                        data-tst-id="keyStartDate"
                    >
                        <AvantDatePicker
                            pickerLabel={"From Date"}
                            currentDate={moment(reportQueryFilters.completedFromDate)}
                            onDateChanged={(fromDate: Moment) => updateFilters("completedFromDate", fromDate.format())}
                        />
                    </FormGroup>
                    <FormGroup
                        data-tst-id="keyEndDate"
                        style={{marginTop: theme.spacing(2), marginBottom: theme.spacing(2)}}
                    >
                        <AvantDatePicker
                            pickerLabel={"To Date"}
                            currentDate={moment(reportQueryFilters.completedToDate)}
                            onDateChanged={(toDate: Moment) => {
                                updateFilters(
                                    "completedToDate",
                                    toDate.hour(23).minute(59).second(59).format()
                                )
                            }}
                        />
                    </FormGroup>
                </Grid>
                <Grid item={true} xs={2}>
                    <FormGroup
                        style={{marginTop: theme.spacing(2), marginBottom: theme.spacing(2)}}
                        data-tst-id="keyBadgeStartDate"
                    >
                        <AvantDatePicker
                            pickerLabel={"Badge Awarded From Date"}
                            currentDate={reportQueryFilters.badgeAwardedFromDate ? moment(reportQueryFilters.badgeAwardedFromDate) : undefined}
                            onDateChanged={(fromDate: Moment) => updateFilters("badgeAwardedFromDate", fromDate.format())}
                        />
                    </FormGroup>
                    <FormGroup
                        data-tst-id="keyBadgeEndDate"
                        style={{marginTop: theme.spacing(2), marginBottom: theme.spacing(2)}}
                    >
                        <AvantDatePicker
                            pickerLabel={"Badge Awarded To Date"}
                            currentDate={reportQueryFilters.badgeAwardedToDate ? moment(reportQueryFilters.badgeAwardedToDate) : undefined}
                            onDateChanged={(toDate: Moment) => {
                                updateFilters(
                                    "badgeAwardedToDate",
                                    toDate.hour(23).minute(59).second(59).format()
                                )
                            }}
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Button
                type={"submit"}
                color={"primary"}
                variant={"contained"}
                style={{marginRight: theme.spacing(1)}}
                data-tst-id={"submit"}
                onClick={() => onSubmit(reportQueryFilters)}
            >
                Search
            </Button>
            <Button
                type={"submit"}
                color={"secondary"}
                variant={"contained"}
                onClick={() => resetFilters()}
            >
                Reset
            </Button>
        </Box>
    )
}

export default CredlyReportSearchForm;
