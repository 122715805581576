import * as Sentry from "@sentry/browser"
import {AxiosResponse} from "axios"
import {observable} from "mobx"
import ApiService from "../../../services/ApiService"
import {ApiErrorResponse} from "../../../types/types"
import {itemStore} from "../../common/item/ItemStore"
import {ItemContent} from "../../common/item/models/ItemContent"
import {ItemHistory} from "../../common/item/models/ItemHistory"
import {messageStore} from "../../common/messages/MessageStore"

export class EditableTrainingContentStore {

    @observable
    currentItemTrainingContent?: string

    @observable
    itemHistory?: ItemHistory[]

    @observable
    editedTrainingContent = ""

    @observable
    changesMade = false

    @observable
    itemContentId?: number

    @observable
    itemId?: number

    private static itemContentUrl = () => `${ApiService.API_URL}item-contents`

    findItemContent = (itemContentId: number) => {
        const url: string = `${EditableTrainingContentStore.itemContentUrl()}/${itemContentId}`
        ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
            (response: AxiosResponse<ItemContent>) => {
                const content: ItemContent = response.data
                this.currentItemTrainingContent = content.content as string
                this.itemContentId = itemContentId
            },
            (error: ApiErrorResponse) => {
                Sentry.captureException(error)
                messageStore.setDefaultError()
            }
        )
    }

    updateItemTraining = (itemContentId: number, content: string, itemId: number) => {
        const url: string = `${EditableTrainingContentStore.itemContentUrl()}/${itemContentId}`
        ApiService.addInteractionBlockingRequest(ApiService.patch(url, {
            content
        })).then(
            (response: AxiosResponse<ItemContent>) => {
                if (response.data.id === this.itemContentId) {
                    const itemContent: ItemContent = response.data
                    this.currentItemTrainingContent = itemContent.content as string
                    itemStore.getItemAuditLog(itemId)
                }
            },
            (error: ApiErrorResponse) => {
                Sentry.captureException(error)
                messageStore.setDefaultError()
            }
        )
    }

    save = () => {
        if (this.currentItemTrainingContent === this.editedTrainingContent || !this.changesMade) {
            return
        }

        const trimmedContent = this.editedTrainingContent.trim()
        if (trimmedContent.length === 0) {
            return
        }
        this.updateItemTraining(this.itemContentId!, trimmedContent, this.itemId!)
        this.changesMade = false
    }
}

export const editableTrainingContentStore = new EditableTrainingContentStore()
