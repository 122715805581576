import {Dialog, DialogContent, DialogTitle, Divider} from "@material-ui/core"
import {observer} from "mobx-react"
import React from "react"
import {FormControl, FormGroup} from "react-bootstrap"
import {AvantColors} from "../../styles/AvantColors"
import {H4Text} from "../../styles/AvantTypography"
import {theme} from "../../styles/MuiThemes"
import {LoginProductContentAreaData, SelectOption} from "../../types/types"
import Button from "../Button/Button"
import {LoginFormStore} from "./Login"

interface LoginProductSelectionModalProps {
    store: LoginFormStore
    handleLoginProduct: (loginProduct: LoginProductContentAreaData) => void
}

@observer
export class LoginProductSelectionModal extends React.Component<LoginProductSelectionModalProps> {

    closeLoginModal = () => {
        const loginId = this.props.store.loginId
        const loginIdToData: Map<string, LoginProductContentAreaData> = this.props.store.loginIdToData
        if (loginId != null) {
            this.props.store.showProductModal = false
            if (!loginIdToData.has(loginId)) {
                throw Error(`this.state.loginIdToData is missing loginId ${loginId}`)
            }
            const loginProduct: LoginProductContentAreaData = loginIdToData.get(loginId)!
            this.props.handleLoginProduct(loginProduct)
        }
    }

    render() {
        return (
            <Dialog
                open={this.props.store.showProductModal}
                onClose={this.closeLoginModal}
                data-tst-id="product-modal"
                PaperProps={{style: {width: 360, padding: theme.spacing(2)}}}
            >
                <DialogTitle disableTypography={true} style={{textAlign: "center", padding: theme.spacing(2)}}>
                    <H4Text style={{fontWeight: 900, color: AvantColors.REPORT_FONT_COLOR_BLACK}}>Choose a product</H4Text>
                </DialogTitle>
                <Divider style={{width: "100%"}}/>
                <DialogContent style={{textAlign: "center"}}>
                    <FormGroup controlId="formControlsSelect">
                        <FormControl
                            as="select"
                            placeholder="select"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.store.loginId = event.target.value}
                            value={this.props.store.loginId}
                            data-tst-id="product-dropdown"
                            autoFocus={true}
                        >
                            {this.props.store.loginOptions &&
                            this.props.store.loginOptions.map((product: SelectOption) => {
                                return (
                                    <option
                                        key={product.value}
                                        value={product.value}
                                        data-tst-id={product.testId}
                                    >
                                        {product.label}
                                    </option>
                                )
                            })}
                        </FormControl>
                    </FormGroup>
                    <div>
                        <Button
                            className="login__login-container__modal-button avant-button--primary"
                            onClick={this.closeLoginModal}
                            testId={"ok-button"}
                        >
                            OK
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}
