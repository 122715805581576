import {isNullOrUndefined} from "util"
import * as winston from "winston"
import {ValidData} from "./ValidData"

export class TakeCode extends ValidData<string> {
    constructor(rawTakeCode: string, error?: Error) {
        super(rawTakeCode.trim(), error)

        if (rawTakeCode !== rawTakeCode.trim()) {
            winston.warn("TakeCode.tsx trimmed input string")
        }
    }

    protected validate(takeCode: string): boolean {
        return !isNullOrUndefined(takeCode)
    }
}
