import * as React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import createStyles from "@material-ui/core/styles/createStyles"
import {PACKAGE_TO_FULL_NAME, SsoEntity, SSOLicenseCount} from "../../../../types/types"
import MaterialTable from "material-table"
import {authStore} from "../../../common/authentication/AuthStore"
const DistrictLicenseCountBannerStyles = makeStyles(() =>
    createStyles({
        root_DistrictLicenseCountBanner: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
    })
)

interface DistrictLicenseCountBannerProps {
    entity: SsoEntity | null
    assignedSeatCountsByProduct: {[key: string]: number}
    consumedSeatCountsByProduct: {[key: string]: number}
}

const DistrictLicenseCountBannerBase: React.FC<DistrictLicenseCountBannerProps> = (props) => {
    const classes = DistrictLicenseCountBannerStyles()
    const licenses = props.entity && props.entity.licenses_sold ? props.entity.licenses_sold : []

    const getPackage = (row: SSOLicenseCount): string => {
        return row.productPackage ? PACKAGE_TO_FULL_NAME[row.productPackage] : ""
    }

    const getReservedSeatCount = (row: SSOLicenseCount): number => {
        // Initialize the return value to zero as a fallback.
        let reservedSeatCount: number = 0

        // We expect a short string here, like '4e' or 'hw'.
        let productPackage = String(row.productPackage)

        // If there's a matching value in the assignedSeatCountsByProduct object...
        if (typeof props.assignedSeatCountsByProduct[productPackage] !== "undefined") {
            // Set the return value to the value from the assignedSeatCountsByProduct object.
            reservedSeatCount = props.assignedSeatCountsByProduct[productPackage]
        }

        return reservedSeatCount
    }

    const getConsumedSeatCount = (row: SSOLicenseCount): number => {
        // Initialize the return value to zero as a fallback.
        let consumedSeatCount: number = 0

        // We expect a short string here, like '4e' or 'hw'.
        let productPackage = String(row.productPackage)

        // If there's a matching value in the consumedSeatCountsByProduct object...
        if (typeof props.consumedSeatCountsByProduct[productPackage] !== "undefined") {
            // Set the return value to the value from the consumedSeatCountsByProduct object.
            consumedSeatCount = props.consumedSeatCountsByProduct[productPackage]
        }

        return consumedSeatCount
    }

    return (
        <div className={classes.root_DistrictLicenseCountBanner}>
            <MaterialTable
                title="Available Licenses"
                columns={
                    authStore && authStore.auth && authStore.auth.ssoType === "ClassLink"
                        ? [
                              {title: "Product", field: "productPackage", render: (rowData) => getPackage(rowData)},
                              {title: "Purchased Seats", field: "count"},
                              {
                                  title: "Reserved Seats",
                                  field: "assignedSeatCount",
                                  render: (rowData) => getReservedSeatCount(rowData)
                              },
                              {
                                  title: "Consumed",
                                  field: "consumedSeatCount",
                                  render: (rowData) => getConsumedSeatCount(rowData)
                              }
                          ]
                        : [
                              {title: "Product", field: "productPackage", render: (rowData) => getPackage(rowData)},
                              {title: "Purchased Seats", field: "count"}
                              //Note: For Clever: Reserved and Consumed Seats column is kept hidden for the time being, whenever the data will be accurate, we'll unhide it.
                              // { title: "Reserved Seats", field: "assignedSeatCount", render: rowData => getReservedSeatCount(rowData)},
                              // { title: "Consumed", field: "consumedSeatCount", render: rowData => getConsumedSeatCount(rowData)},
                          ]
                }
                data={licenses}
                options={{
                    search: false,
                    showTitle: false,
                    toolbar: false,
                    paging: false,
                    padding: "dense",
                    thirdSortClick: false
                }}
                style={{width: "100%"}}
            />
        </div>
    )
}

export const DistrictLicenseCountBanner = React.memo(DistrictLicenseCountBannerBase)
