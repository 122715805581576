import * as HttpStatus from "http-status-codes"
import {observer} from "mobx-react"
import React from "react"
import {FormLabel, FormControl, FormGroup} from "react-bootstrap"
import FontAwesome from "react-fontawesome"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"
import {loadingSpinnerStore} from "../../app/common/loaders/LoadingSpinnerStore"
import {productStore} from "../../app/common/products/ProductStore"
import ApiService from "../../services/ApiService"
import HelperService from "../../services/HelperService"
import {ApiErrorResponse, LastPage, TestState} from "../../types/types"
import {
    LOGIN_NAME_FOUND,
    ROSTERED_LOGIN_NAME_NOT_FOUND,
    SUPPORT_MESSAGE
} from "../../util/Constants"
import {TakeCode} from "../../validation/TakeCode"
import {addTakeDispatches, TakeDispatches} from "../App/App"
import Button from "../Button/Button"


const mapDispatchToProps = (dispatch: Dispatch): TakeDispatches => {
    return addTakeDispatches({}, dispatch)
}

interface ProfileLoginNameProps extends RouteComponentProps, TakeDispatches {}

interface ProfileLoginNameState {
    error: boolean | string
    loginName: string
}

@observer
class ProfileLoginName extends React.Component<ProfileLoginNameProps, ProfileLoginNameState> {
    constructor(props: ProfileLoginNameProps) {
        super(props)
        this.state = {
            loginName: "",
            error: false
        }
    }

    handleNext = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        this.setState({error: false})
        const lastPage: LastPage = {
            url: "/profile-login-name",
            takeCode: this.state.loginName
        }
        ApiService.checkLoginNewState(productStore.loginProduct!!.loginId, new TakeCode(this.state.loginName)).then(
            () => {
                this.setState({error: LOGIN_NAME_FOUND})
            },
            (error: ApiErrorResponse) => {
                if (error.response.status === HttpStatus.NOT_FOUND) {
                    productStore.driver!!.helper
                        .refreshTest(this.props, lastPage, productStore.loginProduct!!)
                        .then(
                            (testState: TestState) => {
                                localStorage.setItem("lastPage", JSON.stringify(lastPage))
                                productStore.driver!!.helper.refreshTestCallback(
                                    testState,
                                    this.props
                                )
                            },
                            (err: ApiErrorResponse) => {
                                this.setState({error: SUPPORT_MESSAGE})
                                HelperService.simpleLogout(false)
                                throw Error(`[LoginContainer.refreshTest()] unknown error in refreshTest: 
                                    ${err.message}`)
                            }
                        )
                } else if (
                    error.response.status === HttpStatus.FORBIDDEN &&
                    error.response.data.kind === "LoginNameTestAlreadyStarted"
                ) {
                    // Display an error
                    this.setState({error: LOGIN_NAME_FOUND})
                } else if (
                        error.response.status === HttpStatus.FORBIDDEN &&
                        error.response.data.kind === "RosteredTestLoginFail"
                    ) {
                        // Display an error that the login name doesnt exist
                        this.setState({error: ROSTERED_LOGIN_NAME_NOT_FOUND})
                } else {
                        this.setState({error: SUPPORT_MESSAGE})
                }

            }
        )
    }

    handleLoginNameChange = (event: any) => {
        this.setState({loginName: event.target.value})
    }

    handleContinueTest = () => {
        this.props.history.push("/login-continue-test")
    }

    componentDidMount() {
        document.title = `Avant | Profile`
        HelperService.enableTextSelection()

        loadingSpinnerStore.hideLoadingSpinner = true
    }

    render() {
        const driver = productStore.driver!

        return (
            <div className="profile">
                <div className="profile__profile-container" id="content">
                    <div className="profile__profile-container__top">
                        <div className="profile__profile-container__top__title">{driver.strings.setUpYourProfile}</div>
                        {/* TODO add profile progress bar */}
                    </div>
                    <div className="profile__profile-container__bottom">
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <div style={{width: "620px"}}>
                                <div className="profile__profile-container__bottom__title">
                                    {driver.strings.createLoginName}
                                </div>
                                <div className="profile__profile-container__bottom__text">
                                    {driver.strings.loginNameDescription}
                                    {"\u00A0"}
                                    <span className="profile__profile-container__bottom__text--italic">
                                        {driver.strings.loginNameExamples}
                                    </span>
                                    {this.state.error === LOGIN_NAME_FOUND && (
                                        <div className="profile__profile-container__warning">
                                            <div className="profile__profile-container__warning__text">
                                                {driver.strings.loginNameExistsPart1}
                                                {"\u00A0"}
                                                <span
                                                    className="profile__profile-container__warning__text--blue"
                                                    onClick={this.handleContinueTest}
                                                >
                                                    {driver.strings.loginNameExistsPart2}
                                                </span>
                                                {"\u00A0"}
                                                {driver.strings.loginNameExistsPart3}
                                            </div>
                                        </div>
                                    )}
                                    {this.state.error === ROSTERED_LOGIN_NAME_NOT_FOUND && (
                                        <div className="profile__profile-container__warning">
                                            <div className="profile__profile-container__warning__text">
                                                {driver.strings.rosteredLoginNotFound}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                this.state.error === LOGIN_NAME_FOUND ||
                                this.state.error === ROSTERED_LOGIN_NAME_NOT_FOUND
                                    ? "profile__profile-container__form profile__profile-container__form--error"
                                    : "profile__profile-container__form"
                            }
                        >
                            <form onSubmit={this.handleNext}>
                                <div>
                                    <FormGroup>
                                        <FormLabel>{driver.strings.loginName}</FormLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.loginName}
                                            placeholder=""
                                            data-tst-id="login-name"
                                            onChange={this.handleLoginNameChange}
                                        />
                                    </FormGroup>
                                    <Button
                                        testId={"login-name-submit"}
                                        className="profile__profile-container__form__button"
                                        onClick={this.handleNext}
                                    >
                                        <span style={{display: "inline-block", verticalAlign: "middle"}}>
                                            {driver.strings.next}
                                        </span>
                                        <FontAwesome
                                            className="profile__profile-container__form__button__arrow"
                                            name="chevron-circle-right"
                                        />
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    null,
    mapDispatchToProps
)(ProfileLoginName)

