import * as Sentry from "@sentry/browser"
import * as HttpStatus from "http-status-codes"
import {computed, observable} from "mobx"
import {observer} from "mobx-react"
import React from "react"
import FontAwesome from "react-fontawesome"
import {RouteComponentProps} from "react-router"
import {Link} from "react-router-dom"
import {authStore} from "../../app/common/authentication/AuthStore"
import {AuthenticationRequest} from "../../app/common/authentication/models/AuthenticationRequest"
import {AuthUser} from "../../app/common/authentication/models/AuthUser"
import {UserType} from "../../app/common/authentication/models/UserType"
import {AvantLogo} from "../../app/common/AvantLogo"
import {loadingSpinnerStore} from "../../app/common/loaders/LoadingSpinnerStore"
import {productStore} from "../../app/common/products/ProductStore"
import {ReportSubPaths} from "../../app/reports/ReportSubPaths"
import {ReportUtils} from "../../app/reports/ReportUtils"
import {RoutePaths} from "../../app/routes/Routes"
import {APP_CONFIG} from "../../services/ConfigurationService"
import HelperService from "../../services/HelperService"
import {ApiErrorResponse, LoginProductContentAreaData, SelectOption} from "../../types/types"
import {
    ADMIN_PRODUCT_ID,
    ADVANCE_PRODUCT_ID,
    APT_PRODUCT_ID,
    PLACE_PRODUCT_ID,
    READY_PRODUCT_ID,
    STAMP_4S_PRODUCT_ID,
    STAMP_4SE_PRODUCT_ID,
    STAMP_SIGN_LANGUAGE_PRODUCT_ID,
    WORLDSPEAK_PRODUCT_ID,
    STAMP_CEFR_PRODUCT_ID
} from "../../util/Constants"
import {MultifactorAuthTypes} from "../../util/Constants"
import {EnvUtils} from "../../util/EnvUtils"
import {getUrlInfo} from "../../util/UrlUtil"
import LoginForm from "./LoginForm"
import {LoginProductSelectionModal} from "./LoginProductSelectionModal"
import MessageWall from "./MessageWall"

interface LoginProps extends RouteComponentProps<{}> {
    testLogin?: boolean
}

export class LoginFormStore {
    @observable
    error: string = ""
    @observable
    username: string = ""
    @observable
    password: string = ""
    @observable
    totp: string = "" // Time-based one time password
    @observable
    authenticating: boolean = false
    @observable
    showProductModal: boolean = false
    @observable
    loginOptions: SelectOption[] = []
    @observable
    loginId?: string
    @observable
    loginIdToData: Map<string, LoginProductContentAreaData> = new Map<string, LoginProductContentAreaData>()

    // For password only view
    @observable
    displayPasswordOnly = false
    @observable
    isAdvance = false
    @observable
    isAdmin = false

    resetFormFieldsAndPasswordInfo = () => {
        this.error = ""
        this.username = ""
        this.password = ""
        this.displayPasswordOnly = false
        this.isAdvance = false
        this.isAdmin = false
    }

    @computed
    get isNotTestCode(): boolean {
        return this.isAdvance || this.isAdmin
    }

    handleError = (error: ApiErrorResponse) => {
        let errorMessage: string = ""
        if (this.displayPasswordOnly) {
            let field = "test code"
            if (this.isNotTestCode) {
                field = "email"
            }
            switch (error.response.status) {
                case HttpStatus.BAD_REQUEST:
                    errorMessage = 'The Google Authenticator password you entered was invalid. Please try again.'
                    break
                case HttpStatus.NOT_FOUND:
                    errorMessage = `The ${field} and password combination you entered was not found. Please try again.`
                    break
                case HttpStatus.FORBIDDEN:
                    errorMessage = `Your account has expired. Please contact ${APP_CONFIG.SUPPORT_EMAIL}`
                    break
                default:
                    errorMessage = `There was an unexpected error.`
            }
        } else {
            switch (error.response.status) {
                case HttpStatus.NOT_FOUND:
                    errorMessage = "The combination you entered is incorrect. Please try again."
                    break
                case HttpStatus.FORBIDDEN:
                    errorMessage = `The testing access window for this test code has expired. Please contact your testing administrator or ${APP_CONFIG.SUPPORT_EMAIL}`
                    break
                default:
                    errorMessage = `There was an unexpected error.`
            }
        }
        this.error = errorMessage
    }
}

export class TestLogin extends React.Component<LoginProps> {
    render() {
        return <GenericLogin {...this.props} testLogin={true}/>
    }
}

export class Login extends React.Component<LoginProps> {
    render() {
        return <GenericLogin {...this.props} testLogin={false}/>
    }
}

@observer
class GenericLogin extends React.Component<LoginProps> {
    reportUrls: Map<number, string>

    store: LoginFormStore

    constructor(props: LoginProps) {
        super(props)

        this.store = new LoginFormStore()

        const url = new URL(window.location.href)
        const urlInfo = getUrlInfo(url)
        let env = urlInfo.env
        let port = ""
        let pfx = "https"
        if (env === "local") {
            env = "-local"
            pfx = "http"
            port = ":8443"
        }
        // These redirect are not only used for teacher redirects but also for the Admin Product redirects

        this.reportUrls = new Map<number, string>([
            // APT_PRODUCT_ID is released as Blue.
            [
                APT_PRODUCT_ID.value(), `${pfx}://apt-report${env}.avantassessment.com${port}/avant/do/teacherlogin`
            ],
            // PLACE_PRODUCT_ID is released as Blue (except for SHL).
            [
                PLACE_PRODUCT_ID.value(),
                `${pfx}://placement-report${env}.avantassessment.com${port}/avant/do/teacherlogin`
            ],
            [
                READY_PRODUCT_ID.value(), `${pfx}://ready-report${env}.avantassessment.com${port}/avant/do/teacherlogin`
            ],
            // STAMP_4S_PRODUCT_ID is released as Blue.
            [
                STAMP_4S_PRODUCT_ID.value(),
                `${pfx}://stamp4s-report${env}.avantassessment.com${port}/avant/do/teacherlogin`
            ],
            // STAMP_4SE_PRODUCT_ID is released as Blue.
            [
                STAMP_4SE_PRODUCT_ID.value(),
                `${pfx}://stamp4se-report${env}.avantassessment.com${port}/avant/do/teacherlogin`
            ],
            [
                WORLDSPEAK_PRODUCT_ID.value(),
                `${pfx}://worldspeak-report${env}.avantassessment.com${port}/avant/do/teacherlogin`
            ],
            [
                READY_PRODUCT_ID.value(), `${pfx}://ready-report${env}.avantassessment.com${port}/avant/do/teacherlogin`
            ]
        ])
    }

    componentDidMount() {
        document.title = `Avant | Login`
        HelperService.enableTextSelection()
        loadingSpinnerStore.hideLoadingSpinner = true
        if(localStorage.getItem("authUser") != null || localStorage.getItem("loginProduct") != null) {
            HelperService.logout()
        }
        if (productStore.loginProduct != null) {
            window.location.reload()
        }

        const {search} = this.props.location
        // When an admin user is redirected from `/test` to `/login`
        if (search.includes("username")) {
            // Get the username param from the url and store it in the login form store
            const params = new URLSearchParams(search)
            const username = params.get("username") as string
            this.store.username = username
        }
    }

    authenticate = (loginProduct: LoginProductContentAreaData) => {
        productStore.setLoginProduct(loginProduct)

        // Check for totp
        if (loginProduct.multifactorAuthType === MultifactorAuthTypes.TFA && !this.store.totp) {
            this.store.error = "Please enter your Google Authenticator password."
            return
        }

        const authenticationRequest: AuthenticationRequest = {
            username: this.store.username.trim(),
            password: this.store.password.trim(),
            totp: this.store.totp.trim(),
            loginId: loginProduct.loginId,
            secondsUntilExpiration: APP_CONFIG.AUTH_TOKEN_SECONDS_TILL_EXPIRE
        }
        authStore.authenticate(authenticationRequest).then(
            (authUser: AuthUser) => {
                Sentry.configureScope((scope) => {
                    const username = this.store.username
                    if (username) {
                        // Standard opp-code: 12345-4s-sp-1
                        const usernamePieces = username.split("-")
                        const isProbablyOppCode = usernamePieces.length === 3
                        if (isProbablyOppCode) {
                            scope.setTag("oppCode", usernamePieces[0])
                        }
                        scope.setTag("loginId", `${loginProduct.loginId}`)
                    }
                })
                switch (loginProduct.productId) {
                    case ADVANCE_PRODUCT_ID.value():
                        if (authUser.eulaAgreeDate) {
                            if (authUser.userType === "D") {
                                this.props.history.push("/advance-report")
                            } else {
                                this.props.history.push("/advance-dashboard")
                            }
                        } else {
                            this.props.history.push("/advance-eula")
                        }
                        break
                    case ADMIN_PRODUCT_ID.value():
                        this.props.history.push("/admin-dashboard")
                        break
                    default:
                        if (ReportUtils.isValidUserType(authStore.userType)) {
                            this.props.history.push(ReportSubPaths.REPORTS_REDIRECT)
                        } else {
                            this.props.history.push("/login-start-or-continue")
                        }
                }
            },
            (error: ApiErrorResponse) => {
                this.store.handleError(error)
                this.store.authenticating = false
            }
        )
    }

    handleLoginProduct = (loginProduct: LoginProductContentAreaData) => {
        const reportsUserTypeArray: string[] = [
            UserType.D.toString(),
            UserType.C.toString(),
            UserType.SC.toString(),
            UserType.T.toString()
        ]

        // These products are now in the Blue experience by default.
        const blueProductsArray: number[] = [
            APT_PRODUCT_ID.value(),
            STAMP_4S_PRODUCT_ID.value(),
            STAMP_4SE_PRODUCT_ID.value(),
            STAMP_SIGN_LANGUAGE_PRODUCT_ID.value(),
            PLACE_PRODUCT_ID.value(),
            STAMP_CEFR_PRODUCT_ID.value()
        ]

        productStore.setLoginProduct(loginProduct)

        if (
            (reportsUserTypeArray.includes(loginProduct.userType)
                && loginProduct.productId !== ADVANCE_PRODUCT_ID.value()) ||
            (loginProduct.userType === UserType.A &&
                loginProduct.productId !== ADVANCE_PRODUCT_ID.value() &&
                loginProduct.productId !== ADMIN_PRODUCT_ID.value())
        ) {

            if (
                (
                    blueProductsArray.includes(loginProduct.productId) ||

                    // Manual get parameter to force the Blue experience.
                    this.props.location.search.includes("go-blue=true") ||

                    // Dev environments always use the Blue experience.
                    EnvUtils.isDev()
                ) &&
                    // Admin product not in the Blue experience.
                    loginProduct.userType !== UserType.A
            ) {
                if (this.props.testLogin) {
                    this.authenticate(loginProduct)
                } else {
                    this.displayPasswordOnlyEntry()
                }
            } else {
                let url = this.reportUrls.get(loginProduct.productId)
                if (url !== undefined && url !== null) {
                    url += `?username=${this.store.username}`
                    window.location.href = url
                }
            }
        } else if (loginProduct.userType === UserType.A && loginProduct.productId === ADMIN_PRODUCT_ID.value()) {
            if (this.props.testLogin) {
                this.redirectToLoginScreen()
            }
            this.displayPasswordOnlyEntry()
        } else {
            if (this.props.testLogin) {
                this.authenticate(loginProduct)
            } else {
                this.displayPasswordOnlyEntry()
            }
        }
    }

    displayPasswordOnlyEntry = () => {
        this.store.displayPasswordOnly = true
        document.title = `Avant | Login Password`
        loadingSpinnerStore.hideLoadingSpinner = true
        const productName = productStore.loginProduct ? productStore.loginProduct.productName.toLowerCase() : ""
        this.store.isAdvance = productName.includes("advance")
        this.store.isAdmin = productName.includes("admin")
    }

    redirectToLoginScreen = (): void => {
        // Clear out relevant data so we don't trigger a refresh
        localStorage.clear()
        productStore.loginProduct = undefined

        // Redirect the user to the '/login' screen
        this.props.history.push({
            pathname: RoutePaths.LOGIN,
            search: `?username=${this.store.username}`
        })
    }

    render() {
        const {testLogin} = this.props
        const {isAdvance, isAdmin} = this.store
        const {loginProduct} = productStore

        let logoTitle
        if (isAdvance) {
            logoTitle = "ADVANCE"
        } else if (isAdmin) {
            logoTitle = "ADMIN PORTAL"
        } else if (testLogin) {
            logoTitle = "TESTING"
        }

        return (
            <div className="login">
                <div>
                    <AvantLogo title={logoTitle}/>
                    <MessageWall/>
                    <div className="login__login-container" data-tst-id="test-login-container">
                        <LoginTop store={this.store} testLogin={testLogin}/>
                        <LoginForm
                            store={this.store}
                            testLogin={testLogin}
                            handleLoginProduct={this.handleLoginProduct}
                            authenticate={this.authenticate}
                            twoFactorAuth={loginProduct && (loginProduct.userType === UserType.A && loginProduct.multifactorAuthType === MultifactorAuthTypes.TFA)}
                        />
                        <LoginBottom {...this.props} store={this.store}/>
                    </div>
                </div>
                <LoginProductSelectionModal store={this.store} handleLoginProduct={this.handleLoginProduct}/>
            </div>
        )
    }
}

interface LoginTopProps {
    testLogin?: boolean
    store: LoginFormStore
}

@observer
class LoginTop extends React.Component<LoginTopProps> {
    render() {
        const {store} = this.props
        let field = "test code"
        if (store.isNotTestCode) {
            field = "email"
        }
        const enterText = store.displayPasswordOnly ? "Please enter your password" : "Please enter your login information"
        return (
            <div className="login__login-container__top">
                <div className="login__login-container__top__title">{enterText}</div>
                {store.displayPasswordOnly ? (
                    <div
                        className="login__login-container__top__text login__login-container__top__link"
                        onClick={store.resetFormFieldsAndPasswordInfo}
                    >
                        <FontAwesome name="chevron-circle-left login__login-container__top__icon"/>
                        {`Your ${field}: ${authStore.username}`}
                    </div>
                ) : null}
                {this.props.testLogin && !store.displayPasswordOnly ? null : (
                    <div className="login__login-container__top__text">
                        Access reports, access Avant ADVANCE, or take a test.
                    </div>
                )}
                {this.props.store.error && (
                    <div className="login__login-container__top__warning" data-tst-id="login-error-banner">
                        <div className="login__login-container__top__warning__text" data-tst-id="login-error-message">{this.props.store.error}</div>
                    </div>
                )}
            </div>
        )
    }
}

interface LoginBottomProps extends RouteComponentProps<{}> {
    testLogin?: boolean
    store: LoginFormStore
}

@observer
class LoginBottom extends React.Component<LoginBottomProps> {

    handleEnterLicenseKey = () => {
        this.props.history.push("/claim")
    }

    handleProctorClick = () => {
        this.props.history.push(RoutePaths.LOGIN)
    }

    render() {
        const {testLogin, store} = this.props
        if (store.displayPasswordOnly) {
            return this.loginBottomForPasswordOnly()
        }
        if (testLogin) {
            return this.loginBottomForTest()
        }
        const firstTimeAdvanceStyle = {
            fontSize: 18
        }
        return (
            <div className="login__login-container__bottom">
                <div style={firstTimeAdvanceStyle}>First time ADVANCE user?</div>
                <span
                    className={"login__login-container__bottom__text--blue"}
                    style={{
                        color: "#2B5DD8",
                        ...firstTimeAdvanceStyle
                    }}
                    onClick={this.handleEnterLicenseKey}
                >
                    Enter your license key here.
                </span>
            </div>
        )
    }

    private loginBottomForTest = () => {
        const darkContainerClass =
            "login__login-container__bottom login__login-container__bottom--dark " +
            "login__login-container__bottom--no-margin"
        return (
            <>
                <div className="login__login-container__bottom">
                    <div className="login__login-container__bottom__text">
                        If you are a proctor or teacher,
                        {"\u00A0"}
                        <span className="login__login-container__bottom__text--blue" onClick={this.handleProctorClick}>
                            click here
                        </span>
                        .
                    </div>
                </div>
                <div className={darkContainerClass}>
                    <TechCheckLink/>
                </div>
            </>
        )
    }

    private loginBottomForPasswordOnly = () => {
        const store = this.props.store
        const forgotPasswordClass =
            "login__login-container__bottom__text " +
            "login__login-container__bottom__text--margin login__login-container__bottom__text--blue"
        let imageContainerBottomClass = "login__login-container__bottom"
        if (!store.isNotTestCode) {
            imageContainerBottomClass = `${imageContainerBottomClass} login__login-container__bottom--dark`
        }

        return (
            <div className={imageContainerBottomClass}>
                {store.isNotTestCode ? (
                    <div
                        className={forgotPasswordClass}
                        onClick={this.handleForgotPassword}
                    >
                        Forgot password?
                    </div>
                ) : (
                    <TechCheckLink/>
                )}
            </div>
        )
    }

    private handleForgotPassword = () => {
        const store = this.props.store
        const userName = store.username ? store.username : ""
        this.props.history.push(`/reset-password?${userName}`)
    }
}

const TechCheckLink = () => (
    <div className="login__login-container__bottom__text">
        Have you completed the {" "}
        <span>
            <Link
                className="login__login-container__bottom__text--blue"
                to={RoutePaths.TECH_CHECK}
                target={"_blank"}
            >
                Technology Check
            </Link>
        </span>
        {" "} yet?
    </div>
)
