import * as Sentry from "@sentry/browser"
import {Severity} from "@sentry/types"
import {detect} from "detect-browser"
import React from "react"
import {Button, Col, Modal, Row} from "react-bootstrap"
import {RecorderEnum, RecordWarningEnum, VOICE_RECORDER} from "../../types/types"
// import {MINIMUM_VOICE_RECORDING_RECORDING_LENGTH} from "../../util/Constants"
import {OpusVoiceRecorder} from "./OpusVoiceRecorder/OpusVoiceRecorder"
import {TwilioVoiceRecorder} from "./TwilioVoiceRecorder/TwilioVoiceRecorder"

interface IComponentProps {
    recordingUrl: string
    recordingId: string
    saveFinalRecordings: boolean
    seenRecordingWarning: RecordWarningEnum
    setRecordingSidsToSave: (recordingSids: string) => void
    setSeenRecordingWarning?: (state: RecordWarningEnum) => void
    setRecorderSupported: (recorder: RecorderEnum) => void
    recordingSids: string
    voiceRecorder: VOICE_RECORDER
    onOggBlobAvailable: (oggBlob: Blob | null) => void
    maxLengthSeconds: number
}

interface IComponentState {
    recorderSupported: RecorderEnum
    showWarningModal: boolean
    currentRecorder?: RecorderEnum
}

export class VoiceRecorder extends React.Component<IComponentProps, IComponentState> {
    constructor(props: IComponentProps) {
        super(props)

        this.state = {
            recorderSupported: RecorderEnum.UNKNOWN,
            showWarningModal: false
        }

        this.closeWarningModal = this.closeWarningModal.bind(this)
    }


    closeWarningModal() {
        this.setState({showWarningModal: false})
        const seenWarning = this.props.setSeenRecordingWarning
        if (seenWarning) {
            seenWarning(RecordWarningEnum.SEEN)
        }
    }

    setTwilioRecorder() {
        this.setState({recorderSupported: RecorderEnum.TWILIO})
        this.props.setRecorderSupported(RecorderEnum.TWILIO)
    }

    setOpusRecorder() {
        this.setState({recorderSupported: RecorderEnum.OPUS})
        this.props.setRecorderSupported(RecorderEnum.OPUS)
    }

    onRecorderFailure = (recorder: VOICE_RECORDER) => {
        switch (recorder) {
            case VOICE_RECORDER.TWILIO:
                console.log("Falling back to the native recorder") // we we can see this in sentry playback
                Sentry.captureMessage("Falling back to the native recorder", Severity.Info)
                this.setState({currentRecorder: RecorderEnum.OPUS})
                break
            case VOICE_RECORDER.NATIVE:
                throw new Error("Native recorder encountered an error")
            default:
                this.setState({currentRecorder: RecorderEnum.OPUS})
                break
        }

    }

    opus = () => {
        return (<OpusVoiceRecorder
            key={"opus-recorder"}
            saveFinalRecordings={this.props.saveFinalRecordings}
            onOggBlobAvailable={this.props.onOggBlobAvailable}
            onFailure={() => {
                this.onRecorderFailure(VOICE_RECORDER.NATIVE)
            }}
            onReady={() => {
                this.setState({recorderSupported: RecorderEnum.OPUS})
                Sentry.captureMessage("Using Opus Recorder", Severity.Info)
            }}
            maxLengthSeconds={this.props.maxLengthSeconds}
        />)
    }

    twilio = () => {
        return (
            // <TwilioVoiceRecorder
            //     key={"twilio-recorder"}
            //     minRecordingLengthMilliseconds={MINIMUM_VOICE_RECORDING_RECORDING_LENGTH}
            //     saveFinalRecordings={this.props.saveFinalRecordings}
            //     setRecordingSidsToSave={this.props.setRecordingSidsToSave}
            //     recordingSids={this.props.recordingSids}
            //     onFailure={() => {
            //         this.onRecorderFailure(VOICE_RECORDER.TWILIO)
            //     }}
            //
            //     onReady={() => {
            //         Sentry.captureMessage("Using Twilio Recorder", Severity.Info)
            //         this.setState({recorderSupported: RecorderEnum.TWILIO})
            //     }}
            // />)
        <TwilioVoiceRecorder />
        )

    }

    render() {
        const {showWarningModal, currentRecorder} = this.state
        const {voiceRecorder} = this.props
        const returnValue = []

        returnValue.push(
            <Modal
                key={"recording-modal"}
                size="sm"
                show={showWarningModal}
                onHide={this.closeWarningModal}
            >
                <Modal.Header closeButton={false} className="center-text">
                    <Modal.Title>Previous Recording Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body className="center-text">
                    <p>
                        You have a partially recorded answer from a previous session that will be replaced when you
                        record again.
                    </p>
                    <Row>
                        <Col sm={{span: 6, offset: 3}}>
                            <Button variant="primary" onClick={this.closeWarningModal} block={true}>
                                OK
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )

        const browser = detect()

        const isMobileSafari = browser && browser.name === "ios" && browser.os === "iOS"

        if (isMobileSafari) {
            returnValue.push(this.opus())
        } else if (currentRecorder) {
            switch (currentRecorder) {
                case "twilio":
                    returnValue.push(this.twilio())
                    break
                case "opus":
                    returnValue.push(this.opus())
                    break
                case "unknown":
                    break
            }
        } else {
            switch (voiceRecorder) {
                case VOICE_RECORDER.NATIVE:
                    returnValue.push(this.opus())
                    break
                case VOICE_RECORDER.TWILIO:
                    returnValue.push(this.twilio())
                    break
            }
        }

        console.debug('Displaying Voice Recorder') //put here so we see it in the sentry dump
        return returnValue
    }
}
