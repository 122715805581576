import {action, observable} from "mobx"
import {ProductPackage} from "../../../types/types"
import ApiService from "../../../services/ApiService"

export class ProductPackageStore {

    @observable
    productPackages: ProductPackage[] = []

    @observable
    productPackageToName: Map<string, string> = new Map<string, string>()

    @action
    getListOfProductPackages = async () => {
        const res = await ApiService.getListOfProductPackages()

        res.forEach((productPackage: ProductPackage) => {
            this.productPackageToName[productPackage.code] = productPackage.longName
        })

        this.productPackageToName["hw"] = "Handwritten Writing Section"

        this.productPackages = res.sort((a: ProductPackage, b: ProductPackage) => a.code.localeCompare(b.code))
    }
}

const productPackageStore = new ProductPackageStore()
export default productPackageStore
