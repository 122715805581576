import * as React from "react"
import {Grid, makeStyles, Paper} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import {LabelText} from "../../../../styles/AvantTypography"
import { Warning } from '@mui/icons-material'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}))

interface SSOSyncProps {
    handleSyncClick: () => void
    syncCompleted: string | null
}

const SSOSyncBase: React.FC<SSOSyncProps> = (props) => {
    const classes = useStyles()
    return (
        <Paper elevation={3} className={classes.paper}>
            <Grid container spacing={3}>
                <Grid item xs={12} style={{textAlign: "center"}}>
                <Grid>
                <LabelText style={{ color: "black" }}> 
                Performing a data  synchronization will update the Class List. This will add newly shared classes and will remove the classes you stopped sharing. </LabelText>
                </Grid>
                <Grid style={{ border: '1px solid #FFE4B5', backgroundColor: '#FFFFCC', padding: '10px', borderRadius: '5px' }}>
                <LabelText style={{ color: "black" }}> 
                <Warning style={{ color: 'black', marginRight: "10px"}} /> 
                Doing a data sync while students are actively testing may cause an incomplete sync. Please plan on syncing either before or after students are testing.</LabelText>
                </Grid>
                  <Button variant="contained" color="primary" onClick={props.handleSyncClick} style={{ marginTop: "10px" }}>Perform Data Synchronization</Button>
                </Grid>
                <Grid item xs={12}>
                    {props.syncCompleted && (<LabelText>Last Full Sync: {new Date(props.syncCompleted).toString()}</LabelText>)}
                    {!props.syncCompleted && (<LabelText>No synchronization has been performed.</LabelText>)}
                    
                </Grid>
            </Grid>
        </Paper>
    )
}

export const SSOSync = React.memo(SSOSyncBase)
