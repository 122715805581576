/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import * as React from "react"
import {SidebarList, SidebarListItem} from "./SidebarList"
import {
    STAMP_4S_PRODUCT_ID,
    STAMP_4SE_PRODUCT_ID,
    STAMP_CEFR_PRODUCT_ID,
    STAMP_SIGN_LANGUAGE_PRODUCT_ID
} from "../../../../util/Constants"
import {TakeSearchResult} from "../../../common/take/models/TakeSearchResult"
import {ProfileEmail} from "./ProfileEmail"

export interface ProfileInfoProps {
    profileInfo: TakeSearchResult
}

interface ProfileItems {
    listItems: {title: string, key: string}[]
    showEmail?: boolean
}

const ProfileInfo = ({profileInfo}: ProfileInfoProps) => {
    const profileItems: ProfileItems | undefined = ProfileItemsMap.get(profileInfo.productId)
    if (!profileItems) {
        return <></>
    }

    return (
        <SidebarList>
            {profileItems.listItems.map((item: {title: string, key: string}) => (
                <SidebarListItem key={item.title} primary={`${item.title}:`} secondary={profileInfo[item.key]} />
            ))}
            {profileItems.showEmail &&
                <ProfileEmail testTakerEmail={profileInfo.testTakerEmail} takeId={profileInfo.takeId} profile={profileInfo.profile} />
            }
        </SidebarList>
    )
}

const Profile4S: ProfileItems = {
    listItems: [
        {
            title: "Login Name",
            key: "code",
        },
        {
            title: "Student ID",
            key: "externalId",
        },
        {
            title: "Test Language",
            key: "language",
        },
        {
            title: "Current Grade",
            key: "grade",
        },
        {
            title: "Native Language",
            key: "firstLanguage",
        },
        {
            title: "Speaks with Family",
            key: "familyMembersOften",
        },
        {
            title: "Language at Home",
            key: "familyLanguage",
        },
        {
            title: "Years Speaking",
            key: "yearsStudyingOrSpoken",
        },
        {
            title: "Lived Abroad",
            key: "monthsAbroad",
        },
    ],
    showEmail: true,
}

const Profile4Se: ProfileItems = {
    listItems: [
        {
            title: "Login Name",
            key: "code",
        },
        {
            title: "Student ID",
            key: "externalId",
        },
        {
            title: "Test Language",
            key: "language",
        },
        {
            title: "Current Grade",
            key: "grade",
        },
        {
            title: "Profile Completed Date",
            key: "profileCompleted", // TODO: handle formatting
        }
    ],
    showEmail: false,
}

function filterTimeAbroad(item: {key: string, title: string}): boolean {
   if (item.key === "monthsAbroad" || item.key === "yearsAbroad") {
       return false
   }

   return true
}

const ProfileSL: ProfileItems = {
    // Filter out monthsAbroad and yearsAbroad
    listItems: Profile4S.listItems.filter(filterTimeAbroad),
    showEmail: true,
}

const ProfileItemsMap: Map<number, ProfileItems> = new Map([
    [STAMP_4S_PRODUCT_ID.value(), Profile4S],
    [STAMP_4SE_PRODUCT_ID.value(), Profile4Se],
    [STAMP_SIGN_LANGUAGE_PRODUCT_ID.value(), ProfileSL],
    [STAMP_CEFR_PRODUCT_ID.value(), Profile4S],
])

export default ProfileInfo
