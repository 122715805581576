import {  CircularProgress } from "@material-ui/core"
import {observer} from "mobx-react"
import moment from "moment"
import React from "react"
import {Container, Grid ,Paper } from '@mui/material';
import {RouteComponentProps} from "react-router"
import {isNullOrUndefined} from "util"
import {authStore} from "../../app/common/authentication/AuthStore"
import {loadingSpinnerStore} from "../../app/common/loaders/LoadingSpinnerStore"
import {productStore} from "../../app/common/products/ProductStore"
import {ApiTakePanel} from "../../app/common/take-panel/models/ApiTakePanel"
import ApiService from "../../services/ApiService"
import {
    ApiErrorResponse,
    PanelSkillEnum,
    UserDataByPage,
    UserDataFilters,
    UserDataLanguage,
    UserDataWithGroupsAndTakePanels,
    UserReportData,
    UserReportLanguage,
    UserReportModule
} from "../../types/types"
import {SUPPORT_MESSAGE} from "../../util/Constants"
import AdvanceReportList from "../AdvanceReport/AdvanceReportList"
import AdvanceReportSearch from "../AdvanceReport/AdvanceReportSearch"
import AdvanceReportChangeLanguages from "../AdvanceReport/AdvanceReportChangeLanguages"

interface AdminAdvanceUserListState {
    error: boolean | string
    users?: UserDataWithGroupsAndTakePanels[]
    usersData?: UserReportData[]
    currentLanguageId?: number
    page: number
    size: number
    totalPages: number
    hasUserData: boolean
    filters?: UserDataFilters
    userType?: string
}

@observer
export class AdminAdvanceUserList extends React.Component<RouteComponentProps, AdminAdvanceUserListState> {
    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            error: false,
            page: 0,
            size: 10,
            totalPages: 1,
            hasUserData: false
        }
    }

    componentDidMount() {
        loadingSpinnerStore.hideLoadingSpinner = true
    }

    componentDidUpdate() {
        const driver = productStore.driver
        if (driver) {
            document.title = `${driver.config.PRODUCT_TITLE} | Report`
        }
    }

    getUserData = (filters: UserDataFilters): Promise<UserReportData[]> => {
        return new Promise<UserReportData[]>((resolve, reject) => {
            ApiService.getAdvanceAdminReport(filters.page, this.state.size, filters).then(
                (userDataByPage: UserDataByPage) => {
                    const users = userDataByPage.contents
                    const usersData: UserReportData[] = this.processUserData(users)
                    this.setState({
                        users,
                        usersData,
                        page: userDataByPage.page,
                        size: userDataByPage.size,
                        totalPages: userDataByPage.totalPages,
                        filters: filters
                    })
                    resolve(usersData)
                },
                (error: ApiErrorResponse) => {
                    this.setState({
                        error: SUPPORT_MESSAGE
                    })
                    reject()
                    throw new Error(`Failed to get users. ${error.response.data.error}`)
                }
            )
        })
    }

    getUserDataFull = (filters: UserDataFilters): Promise<UserReportData[]> => {
        return new Promise<UserReportData[]>((resolve, reject) => {
            ApiService.getAdvanceAdminReportFull(filters.page, this.state.size, filters).then(
                (userDataByPage: UserDataByPage) => {
                    const users = userDataByPage.contents
                    const usersData: UserReportData[] = this.processUserData(users)
                    resolve(usersData)
                },
                (error: ApiErrorResponse) => {
                    this.setState({
                        error: SUPPORT_MESSAGE
                    })
                    reject()
                    throw new Error(`Failed to get users. ${error.response.data.error}`)
                }
            )
        })
    }

    processUserData = (users: UserDataWithGroupsAndTakePanels[]): UserReportData[] => {
        const usersData: UserReportData[] = []
        users.forEach((user: UserDataWithGroupsAndTakePanels) => {
            const userReportData: UserReportData = {
                username: user.username,
                firstName: user.firstName ? user.firstName : "",
                lastName: user.lastName ? user.lastName : "",
                languages: new Map<number, UserReportLanguage>(),
                schoolName: user.schoolName ? user.schoolName : "",
                schoolId: user.schoolId ? user.schoolId : -1,
                districtName: user.districtName ? user.districtName : "",
                districtId: user.districtId ? user.districtId : -1,
                stateName: user.stateName ? user.stateName : "",
                stateId: user.stateId ? user.stateId : -1
            }
            user.languages.forEach((language: UserDataLanguage) => {
                const userReportLanguage: UserReportLanguage = {
                    name: language.name,
                    duration: 0,
                    modules: new Map<string, UserReportModule>()
                }
                userReportLanguage.modules.set(PanelSkillEnum.MODULE_1, this.createModule("Module 1"))
                userReportLanguage.modules.set(PanelSkillEnum.MODULE_2, this.createModule("Module 2"))
                userReportLanguage.modules.set(PanelSkillEnum.MODULE_3, this.createModule("Module 3"))
                userReportLanguage.modules.set(PanelSkillEnum.MODULE_4, this.createModule("Module 4"))
                userReportLanguage.modules.set(PanelSkillEnum.MODULE_5, this.createModule("Module 5"))
                userReportLanguage.modules.set(PanelSkillEnum.MODULE_6, this.createModule("Module 6"))
                userReportLanguage.modules.set(PanelSkillEnum.PRACTICE_TEST, this.createModule("Practice Test"))
                userReportLanguage.modules.set(PanelSkillEnum.CERTIFICATION_TEST, this.createModule("Certification Test"))
                userReportData.languages.set(language.id, userReportLanguage)
            })
            if (user.takePanels) {
                user.takePanels.forEach((takePanel: ApiTakePanel) => {
                    switch (takePanel.panel.skill) {
                        case PanelSkillEnum.MODULE_1:
                            if (this.moduleExists(userReportData, takePanel, PanelSkillEnum.MODULE_1)) {
                                if (takePanel.timeTaken) {
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.MODULE_1)!.duration += takePanel.timeTaken
                                }
                                if (takePanel.level === "P") {
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.MODULE_1)!.passed = true
                                }
                            }
                            break
                        case PanelSkillEnum.MODULE_2:
                            if (this.moduleExists(userReportData, takePanel, PanelSkillEnum.MODULE_2)) {
                                if (takePanel.timeTaken) {
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.MODULE_2)!.duration += takePanel.timeTaken
                                }
                                if (takePanel.level === "P" && takePanel.totalPanelScore > 0) {
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.MODULE_2)!.passed = true
                                }
                            }
                            break
                        case PanelSkillEnum.MODULE_3:
                            if (this.moduleExists(userReportData, takePanel, PanelSkillEnum.MODULE_3)) {
                                if (takePanel.timeTaken) {
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.MODULE_3)!.duration += takePanel.timeTaken
                                }
                                if (takePanel.level === "P" && takePanel.totalPanelScore > 0) {
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.MODULE_3)!.passed = true
                                }
                            }
                            break
                        case PanelSkillEnum.MODULE_4:
                            if (this.moduleExists(userReportData, takePanel, PanelSkillEnum.MODULE_4)) {
                                if (takePanel.timeTaken) {
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.MODULE_4)!.duration += takePanel.timeTaken
                                }
                                if (takePanel.level === "P" && takePanel.totalPanelScore > 0) {
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.MODULE_4)!.passed = true
                                }
                            }
                            break
                        case PanelSkillEnum.MODULE_5:
                            if (this.moduleExists(userReportData, takePanel, PanelSkillEnum.MODULE_5)) {
                                if (takePanel.timeTaken) {
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.MODULE_5)!.duration += takePanel.timeTaken
                                }
                                if (takePanel.level === "P" && takePanel.totalPanelScore > 0) {
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.MODULE_5)!.passed = true
                                }
                            }
                            break
                        case PanelSkillEnum.MODULE_6:
                            if (this.moduleExists(userReportData, takePanel, PanelSkillEnum.MODULE_6)) {
                                if (takePanel.timeTaken) {
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.MODULE_6)!.duration += takePanel.timeTaken
                                }
                                if (takePanel.level === "P") {
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.MODULE_6)!.passed = true
                                }
                            }
                            break
                        case PanelSkillEnum.PRACTICE_TEST:
                            if (this.moduleExists(userReportData, takePanel, PanelSkillEnum.PRACTICE_TEST)) {
                                if (takePanel.timeTaken) {
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.PRACTICE_TEST)!.duration += takePanel.timeTaken
                                }
                                if (takePanel.level === "P") {
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.PRACTICE_TEST)!.passed = true
                                }
                            }
                            break
                        case PanelSkillEnum.CERTIFICATION_TEST:
                            if (this.moduleExists(userReportData, takePanel, PanelSkillEnum.CERTIFICATION_TEST)) {
                                if (takePanel.timeTaken) {
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.CERTIFICATION_TEST)!.duration += takePanel.timeTaken
                                }
                                if (takePanel.level === "P") {
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.CERTIFICATION_TEST)!.passed = true
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.CERTIFICATION_TEST)!.datePassed = moment(takePanel.finishTime).format("l")
                                    userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!.modules
                                        .get(PanelSkillEnum.CERTIFICATION_TEST)!.score =
                                        ((takePanel.totalPanelScore / takePanel.panel.itemCount) * 100)
                                }
                                if (takePanel.totalPanelScore) {
                                    const oldScore = userReportData.languages
                                        .get(takePanel.panel.contentAreaId)!
                                        .modules.get(PanelSkillEnum.CERTIFICATION_TEST)!.score
                                    if (oldScore && takePanel.totalPanelScore > oldScore) {
                                        userReportData.languages
                                            .get(takePanel.panel.contentAreaId)!
                                            .modules.get(PanelSkillEnum.CERTIFICATION_TEST)!.score = takePanel.totalPanelScore
                                    }
                                }
                            }
                            break
                        default:
                            break
                    }
                })
            }
            userReportData.languages.forEach((language: UserReportLanguage) => {
                language.modules.forEach((module: UserReportModule) => {
                    language.duration += module.duration
                })
            })

            usersData.push(userReportData)
        })
        return usersData
    }

    setHasUserData = (hasUserData: boolean) => {
        this.setState({hasUserData})
    }

    setFilters = (filters: UserDataFilters) => {
        this.setState({filters})
    }

    moduleExists = (userReportData: UserReportData, takePanel: ApiTakePanel, skill: string): boolean => {
        return (
            !isNullOrUndefined(userReportData.languages.get(takePanel.panel.contentAreaId)) &&
            !isNullOrUndefined(userReportData.languages.get(takePanel.panel.contentAreaId)!.modules.get(skill))
        )
    }

    createModule = (name: string) => {
        return {
            name,
            passed: false,
            duration: 0
        }
    }

    render() {
        const loginId: number | null = authStore.auth!!.loginId
        const driver = productStore.driver

        if (loginId === null) {
            return <CircularProgress/>
        }

        if (driver) {
            document.title = `${driver.config.PRODUCT_TITLE} | Report`
        }

        return (
            <Container>
    <Grid container spacing={3}>
        <Grid item xs={12}>
            <Paper sx={{ marginBottom: '34px', padding: 2 }}> 
                <AdvanceReportChangeLanguages />
            </Paper>
        </Grid>
    </Grid>
    <Grid container spacing={3}>
        <Grid item xs={12}>
            {!this.state.hasUserData ? (
                <Paper sx={{ padding: 2 }}>
                    <AdvanceReportSearch
                        loginId={loginId}
                        userType={this.state.userType ? this.state.userType : "A"}
                        getUserData={this.getUserData}
                        setHasUserData={this.setHasUserData}
                    />
                </Paper>
            ) : (
                <AdvanceReportList
                    users={this.state.usersData!}
                    page={this.state.page}
                    totalPages={this.state.totalPages}
                    getUserData={this.getUserData}
                    getUserDataFull={this.getUserDataFull}
                    setHasUserData={this.setHasUserData}
                    filters={this.state.filters!}
                />
            )}
        </Grid>
    </Grid>
</Container>
        )
    }
}

