import {observer} from "mobx-react"
import React from "react"
import {FormControl, FormGroup, FormLabel} from "react-bootstrap"
import {RouteComponentProps} from "react-router"
import {loadingSpinnerStore} from "../../app/common/loaders/LoadingSpinnerStore"
import {RoutePaths} from "../../app/routes/Routes"
import ApiService from "../../services/ApiService"
import HelperService from "../../services/HelperService"
import {theme} from "../../styles/MuiThemes"
import Button from "../Button/Button"

interface ResetPasswordState {
    error: boolean
    errorMessage: string
    email: string
    emailSent: boolean
}

@observer
export class ResetPassword extends React.Component<RouteComponentProps, ResetPasswordState> {
    constructor(props: RouteComponentProps) {
        super(props)
        const arg = window.location.href.split("?")
        const userName = arg.length > 1 ? arg[1] : ""
        this.state = {
            email: userName,
            error: false,
            errorMessage: "",
            emailSent: false
        }
    }

    handleSubmit = (event: any) => {
        event.preventDefault()
        this.setState({error: false})
        ApiService.sendForgotPasswordEmail(this.state.email).then(() => {
            this.setState({emailSent: true})
        })
    }

    handleEmailChange = (event: any) => {
        this.setState({email: event.target.value})
    }

    handleReturnToLogin = () => {
        this.props.history.push(RoutePaths.LOGIN)
    }

    renderTopText = () => {
        const text =
            "Enter the email address you registered with and we will send you an email with a " +
            "link to reset your password."
        return (
            <div className="login__login-container__top">
                <div className="login__login-container__top__title">Reset Password</div>
                <div className="login__login-container__top__text">{text}</div>
            </div>
        )
    }

    renderResetPasswordForm = () => {
        return (
            <div
                className={
                    this.state.error
                        ? "login__login-container__form login__login-container__form-error"
                        : "login__login-container__form"
                }
            >
                <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <FormLabel>Email</FormLabel>
                        <FormControl
                            type="text"
                            value={this.state.email}
                            placeholder=""
                            data-tst-id="password"
                            onChange={this.handleEmailChange}
                        />
                    </FormGroup>
                    <Button
                        className="login__login-container__button avant-button--primary"
                        onClick={this.handleSubmit}
                        type="submit"
                    >
                        SUBMIT
                    </Button>
                </form>
            </div>
        )
    }

    renderBottomText = () => {
        return (
            <div className="login__login-container__bottom">
                <div className="login__login-container__bottom__text login__login-container__bottom__text--margin">
                    Need login help?
                    {"\u00A0"}
                    <a className="login__login-container__bottom__text--blue" href="mailto:support@avantassessment.com">
                        Contact Support
                    </a>
                </div>
                <div className="login__login-container__bottom__text">
                    Remembered your password?
                    {"\u00A0"}
                    <span className="login__login-container__bottom__text--blue" onClick={this.handleReturnToLogin}>
                        Click to return to login.
                    </span>
                </div>
            </div>
        )
    }

    renderCheckEmail = () => {
        const emailSent = `An email has been sent to ${this.state.email}`
        const checkInbox =
            "Please check your inbox for an email from Avant Assessment and follow the instructions there."
        return (
            <div className="login__login-container__top">
                <div className="login__login-container__top__title">Reset Password</div>
                <div className="login__login-container__top__text">{emailSent}</div>
                <div className="login__login-container__top__text">{checkInbox}</div>
            </div>
        )
    }

    componentDidMount() {
        document.title = `Avant | Reset Password`
        HelperService.enableTextSelection()
        loadingSpinnerStore.hideLoadingSpinner = true
    }

    render() {
        return (
            <div className="login">
                <div>
                    <div className="login__image-container">
                        <img
                            className="login__image-container__image"
                            alt=""
                            src="https://cdn.avantassessment.com/resources/common/img/avant-logo.svg"
                        />
                        <div className="login__image-container__text">ADVANCE</div>
                    </div>
                    <div className="login__login-container">
                        {!this.state.emailSent ? (
                            <>
                                {this.renderTopText()}
                                {this.renderResetPasswordForm()}
                                {this.renderBottomText()}
                            </>
                        ) : (
                            <>
                                {this.renderCheckEmail()}
                                <div style={{display: "flex", justifyContent: "center", marginBottom: theme.spacing(2)}}>
                                    <Button
                                        className="login__login-container__button avant-button--primary"
                                        onClick={this.handleReturnToLogin}
                                    >
                                        RETURN TO LOGIN
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
