import {observer} from "mobx-react"
import moment from "moment"
import * as React from "react"
import {Redirect} from "react-router"
import {prettyJson} from "../../../../util/Logging"
import {groupMasterStore} from "../../../common/group/GroupMasterStore"
import {GroupType} from "../../../common/group/models/GroupMaster"
import {AvantCircularProgress} from "../../../common/loaders/AvantCircularProgress"
import {productStore} from "../../../common/products/ProductStore"
import {ReportSubPaths} from "../../ReportSubPaths"
import {ReportFilters} from "../models/ReportFilters"

export const RedirectToReport: React.FC<{ reportFilters?: ReportFilters, blueReportType?: string }> = observer(({reportFilters, blueReportType}) => {

    const defaultGroup = groupMasterStore.defaultGroup
    if (defaultGroup == null || productStore.loginProduct == null) {
        return <AvantCircularProgress/>
    }
    const filters: ReportFilters = reportFilters || {
        groupId: defaultGroup.id,
        productId: productStore.loginProduct!.productId,
        startDate: moment().subtract(6, "months"),
        endDate: moment(),
        recentActivity: true,
        assessmentStart: false
    }

    let to
    switch ((blueReportType && GroupType[blueReportType]) ? GroupType[blueReportType] : defaultGroup.groupType) {
        case GroupType.CLASS:
        case GroupType.LOCATION:
        case GroupType.CUSTOMER:
            to = ReportSubPaths.class(filters)
            break
        case GroupType.SCHOOL:
            to = ReportSubPaths.school(filters)
            break
        case GroupType.DISTRICT:
            to = ReportSubPaths.district(filters)
            break
        default:
            throw new Error("Unexpected type in group master store for viewing reports: " + prettyJson(defaultGroup))
    }
    return <Redirect to={to}/>
})
