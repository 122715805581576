import {ApiErrorResponse, ClassLinkResponse} from "../types/types"
import {AxiosResponse} from "axios"
import ApiService from "./ApiService"

export default class ClasslinkApi {
    static getClasslinkId(): Promise<ClassLinkResponse> {
        const url: string = `${ApiService.API_URL}getClassLinkURL`
        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }
}
