import {observer} from "mobx-react"
import * as React from "react"
import {groupMasterStore} from "../../../common/group/GroupMasterStore"
import {AvantCircularProgress, CircularProgressSize} from "../../../common/loaders/AvantCircularProgress"
import {AvantPopperMenuItem} from "../../../generic-components/AvantPopperMenu"
import {ReportsPrintButton} from "../../common/components/ReportsPrintButton"
import {ReportFilters} from "../../common/models/ReportFilters"
import {ReportSubPaths} from "../../ReportSubPaths"
import {reportsStore} from "../../common/stores/ReportStore"
import {ReportGraphFilters} from "../../common/models/ReportGraphFilters"

export const ClassReportsPrintMenu: React.FC<{ filters: ReportFilters, query: ReportGraphFilters }> = observer(({filters, query}) => {
    const numSelected = reportsStore.selectedTakeIds.length

    const isHandwritten = reportsStore.reportTableData && reportsStore.reportTableData.reportTakeRows.length > 0 ?
        reportsStore.reportTableData.reportTakeRows.map(t => t.handwritten).reduce((p, c) => p || c) : false

    if (!reportsStore.instancesData) {
        reportsStore.findDownloadedReportTableData(query)
    }

    const groupInfo = groupMasterStore.groupInfo
    if (!groupInfo || !reportsStore.instancesData) {
        return <AvantCircularProgress size={CircularProgressSize.SMALL}/>
    }


    const menuContents: AvantPopperMenuItem[] = [
        {
            name: "Group Report",
            submenuItems: [
                {
                    name: "Summary",
                    link: ReportSubPaths.classPrintSummary(filters)
                },
                {
                    name: "Summary and List",
                    link: ReportSubPaths.classPrintAll(filters)
                },
                {
                    name: "List",
                    link: ReportSubPaths.classPrintList(filters)
                }
            ]
        },
        {
            name: "Individual Reports",
            submenuItems: [
                {
                    name: "All",
                    link: ReportSubPaths.individualPrintAll(filters.productId)
                },
                {
                    name: `Selected - ${numSelected}`,
                    link: numSelected > 0 ? ReportSubPaths.individualPrintSelected(filters.productId) : ""
                }
            ]
        },
        {
            name: "Writing Prompts",
            submenuItems: [
                {
                    name: "All",
                    link: ReportSubPaths.promptsPrintAll()
                },
                {
                    name: `Selected - ${numSelected}`,
                    link: numSelected > 0 ? ReportSubPaths.promptsPrintSelected() : ""
                }
            ]
        }
    ]

    if (!isHandwritten) {
        menuContents.pop()
    }

    return <ReportsPrintButton
        contents={menuContents}
    />
})
