/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {AxiosError, AxiosResponse} from "axios"
import {observable} from "mobx"
import ApiService from "../../../../services/ApiService"
// import {log, prettyJson} from "../../../../util/Logging"
import {TakeSearchResult} from "../../../common/take/models/TakeSearchResult"
import {ClassInfoRow} from "../../district-school/models/ClassInfoRow"
import {ReportSummaryGraphs} from "../../district-school/models/ReportSummaryGraphs"
import {SchoolInfoRow} from "../../district-school/models/SchoolInfoRow"
import {ReportGraphFilters} from "../models/ReportGraphFilters"
import {avantHistory} from "../../../../components/App/App"
import {ReportSubPaths} from "../../ReportSubPaths"
import {messageStore} from "../../../common/messages/MessageStore"
import {ReportFilters} from "../models/ReportFilters"
import {TableDataResults} from "../../class/models/TableDataResults"
import {authStore} from "../../../common/authentication/AuthStore"

export class ReportStore {

    @observable
    individualReport?: TakeSearchResult

    @observable
    searchFilters?: ReportFilters

    @observable
    schoolReportData?: ClassInfoRow[]

    @observable
    districtReportData?: SchoolInfoRow[]

    @observable
    reportGraphData?: ReportSummaryGraphs

    @observable
    reportTableData?: TableDataResults

    @observable
    instancesData?: TakeSearchResult[]

    @observable
    instancesInProgress: boolean = false

    @observable
    csvDownload?: string[]

    @observable
    csvDownloadInProgress: boolean = false


    @observable
    selectedTakeIds: number[] = []


    @observable
    groupsWithData?: number[]

    @observable
    latestClass?: number

    // CSV Report Download
    @observable
    remainingTakeIdsToDownload?: number[]

    @observable
    currentIdsToDownload?: number[]

    findSchoolReportData = (schoolId: number) => {
        this.schoolReportData = undefined
        ApiService.get(`${this.url()}/schools/${schoolId}`, true).then(
            (response: AxiosResponse<ClassInfoRow[]>) => {
                // log.debug(`Found school report data with id ${schoolId} info: ${prettyJson(response.data)}`)
                this.schoolReportData = response.data
            }
        )
    }

    findSSOSchoolReportData = (schoolId: number, productId: number) => {
        this.schoolReportData = undefined
        ApiService.get(`${this.url()}/sso/schools/${schoolId}/${productId}`, true).then(
            (response: AxiosResponse<ClassInfoRow[]>) => {
                this.schoolReportData = response.data
            }
        )
    }

    findGroupsWithData = (filters: ReportGraphFilters) => {
        // log.debug(`Requesting report groups with data with ${prettyJson(filters)}`)
        this.groupsWithData = undefined
        ApiService.post(`${this.url()}/groups-with-data`, filters, true).then(
            (response: AxiosResponse<number[]>) => {
                // log.debug(`Found district report graph data with id ${districtId} info: ${prettyJson(response.data)}`)
                this.groupsWithData = response.data
            }
        )
    }

    findReportGraphs = (filters: ReportGraphFilters) => {
        // log.debug(`Requesting report graph with ${prettyJson(filters)}`)
        this.reportGraphData = undefined
        ApiService.post(`${this.url()}/graph-data`, filters, true).then(
            (response: AxiosResponse<ReportSummaryGraphs>) => {
                // log.debug(`Found district report graph data with id ${districtId} info: ${prettyJson(response.data)}`)
                this.reportGraphData = response.data
            }
        )
    }

    findReportTableData = (filters: ReportGraphFilters) => {
        // log.debug(`Requesting report table data with ${prettyJson(filters)}`)
        this.reportTableData = undefined
        this.instancesData = undefined
        this.instancesInProgress = false
        ApiService.post(`${this.url()}/table-data`, filters, true).then(
            (response: AxiosResponse<TableDataResults>) => {
                this.reportTableData = response.data
            }
        )
    }

    resetDownloads() {
        this.instancesData = undefined
        this.instancesInProgress = false
        this.resetCsvDownload()
    }

    private resetCsvDownload = () => {
        this.currentIdsToDownload = undefined
        this.remainingTakeIdsToDownload = undefined
        this.csvDownload = undefined
        this.csvDownloadInProgress = false
    }

    findDownloadedReportTableData = (filters: ReportGraphFilters) => {
        // log.debug(`Requesting report table data with ${prettyJson(filters)}`)
        this.instancesData = undefined
        this.instancesInProgress = true
        ApiService.post(`${this.url()}/download`, filters, true)
            .then((res: AxiosResponse) => {
                    this.instancesData = res.data as TakeSearchResult[]
                    // log.debug("take download continuing. Takes quantity " + this.instancesData.length)
                    this.instancesInProgress = false
                },
                (err: AxiosError) => {
                    this.instancesInProgress = false
                    if (err.response!.status === 401) {
                        avantHistory.push(ReportSubPaths.REPORTS_REDIRECT)
                        messageStore.setErrorMessage("You don't have access to view this report. Redirecting you to your default report.")
                    } else {
                        messageStore.setErrorMessage("Sorry, there was an error downloading test results.")
                    }
                })
    }

    startCsvDownload = (filters: ReportGraphFilters) => {
        this.csvDownloadInProgress = true
        this.csvDownload = undefined
        const batchSize = 25
        const concurrentDownloads = 4
        ApiService.post(`${this.url()}/download/ids`, filters, true)
            .then((res: AxiosResponse) => {
                    this.remainingTakeIdsToDownload = res.data as number[]

                    // Get header batch first, then start the concurrent downloads
                    this.findCsvRows(filters.groupId, batchSize, true).then(() => {
                        for (let i = 0; i < concurrentDownloads; i++) {
                            this.findCsvRows(filters.groupId, batchSize, false)
                        }
                    })
                },
                (err: AxiosError) => {
                    this.csvDownloadInProgress = false
                    if (err.response!.status === 401) {
                        avantHistory.push(ReportSubPaths.REPORTS_REDIRECT)
                        messageStore.setErrorMessage("You don't have access to view this report. Redirecting you to your default report.")
                    } else {
                        messageStore.setErrorMessage("Sorry, there was an error downloading test results.")
                    }
                })

    }
    private getBatch = (ids: number[], batchSize: number) => [ids.slice(0, batchSize), ids.slice(batchSize)]

    private findCsvRows = (groupId: number, batchSize: number, showHeaders: boolean): Promise<void> => {

        // Killing the job early
        if (this.remainingTakeIdsToDownload === undefined) {
            this.resetCsvDownload()
            return new Promise<void>(() => {
            })
        }

        // Nothing to retrieve, so return
        if (this.currentIdsToDownload !== undefined && this.currentIdsToDownload.length === 0) {
            return new Promise<void>(() => {
            })
        }


        const [next, rest] = this.getBatch(this.remainingTakeIdsToDownload!!, batchSize)
        this.currentIdsToDownload = next
        this.remainingTakeIdsToDownload = rest
        const includeHeaders = `?includeHeaders=${showHeaders}`
        return ApiService.post(`${this.url()}/download/csv${includeHeaders}`, {
            ids: this.currentIdsToDownload!!,
            groupId,
            userType: authStore.userType
        }, true)
            .then((res: AxiosResponse) => {
                    const newRows = res.data as string[]
                    this.csvDownload = (this.csvDownload || []).concat(newRows)
                    if ((this.remainingTakeIdsToDownload || []).length === 0) {
                        this.csvDownloadInProgress = false
                    } else {
                        this.findCsvRows(groupId, batchSize, false)
                    }
                },
                (err: AxiosError) => {
                    if (err.response!.status === 401) {
                        avantHistory.push(ReportSubPaths.REPORTS_REDIRECT)
                        messageStore.setErrorMessage("You don't have access to view this report. Redirecting you to your default report.")
                    } else {
                        // TODO: Sentry logging
                        this.resetCsvDownload()
                        messageStore.setErrorMessage("Sorry, there was an error downloading test results.")
                    }
                })

    }


    findDistrictReportData = (districtId: number) => {
        this.districtReportData = undefined
        ApiService.get(`${this.url()}/districts/${districtId}`, true).then(
            (response: AxiosResponse<SchoolInfoRow[]>) => {
                // log.debug(`Found district report data with id ${districtId} info: ${prettyJson(response.data)}`)
                this.districtReportData = response.data
            }
        )
    }

    findSSODistrictReport = (districtId: number, productId: number) => {
        this.districtReportData = undefined
        ApiService.get(`${this.url()}/sso/districts/${districtId}/${productId}`, true).then(
            (response: AxiosResponse<SchoolInfoRow[]>) => {
                this.districtReportData = response.data
            }
        )
    }

    findIndividualReport = async (takeId: number) => {
        const url: string = `${this.url()}/individual/${takeId}`
        const response: AxiosResponse<TakeSearchResult> = await ApiService.get(url, true)
        this.individualReport = response.data
    }

    private url = () => `${ApiService.API_URL}reports`
}

export const reportsStore = new ReportStore()
