import React from 'react'
import {createStyles, makeStyles, Theme} from "@material-ui/core"
import { AvantColors } from "../../../../../../styles/AvantColors"
import {OVERALL_DISPLAY_HEIGHT, SKILL_TRIANGLE_ORIGIN_X} from "./SkillTriangleUtils"
import { productStore } from '../../../../../common/products/ProductStore'
import { STAMP_CEFR_PRODUCT_ID } from '../../../../../../util/Constants'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backgroundLines: {
            // Grey Background lines
            stroke: AvantColors.REPORT_FONT_COLOR_GRAY_3,
            strokeWidth: 1
        },
        backgroundRect: {
            fill: "#FAFAFA",
        },
        imageText: {
            fontFamily: "sans-serif",
            fontSize: "24px",
            fill: AvantColors.REPORT_FONT_COLOR_GRAY_3,
        },
    }),
)



interface ThreeStriationSkillTriangleBackgroundProps {
    levelCount: number
}

export const ThreeStriationSkillTriangleBackground: React.FC<ThreeStriationSkillTriangleBackgroundProps> = ({levelCount}) => {

    const classes = useStyles()

    const equalStriation: boolean = levelCount === 9

    const intermediateStriationTopBorder = (equalStriation ? 4 : 3) * (OVERALL_DISPLAY_HEIGHT/levelCount) + (equalStriation ? 18 : 14)
    const noviceStriationTopBorder = (equalStriation ? 7 : 6) * (OVERALL_DISPLAY_HEIGHT/levelCount) + (equalStriation ? 15 : 10)
    const advanceRectangleHeight = (equalStriation ? 3 : 2) * (OVERALL_DISPLAY_HEIGHT/levelCount) + 2.5
    const noviceAndIntRectangleHeight = 3 * (OVERALL_DISPLAY_HEIGHT/levelCount) + 2.5

    const TEXT_Y_OFFSET = 20

    return (
        <>
            <rect
                id={"advanced-range-striation-box"}
                x={SKILL_TRIANGLE_ORIGIN_X}
                y={55}
                width={"100%"}
                height={advanceRectangleHeight}
                className={classes.backgroundRect}
            />
            <rect
                id={"novice-range-striation-box"}
                x={SKILL_TRIANGLE_ORIGIN_X}
                y={noviceStriationTopBorder}
                height={noviceAndIntRectangleHeight}
                width={"100%"}
                className={classes.backgroundRect}
            />

            <text
                id={"advanced-level-area-text"}
                x={10}
                y={(advanceRectangleHeight) + TEXT_Y_OFFSET}
                className={classes.imageText}
            >
            {productStore.loginProduct && productStore.loginProduct.productId === STAMP_CEFR_PRODUCT_ID.value() ? "BAND C" : "ADV" }
            </text>
            <text
                id={"intermediate-level-area-text"}
                x={10}
                y={(noviceAndIntRectangleHeight) + advanceRectangleHeight}
                className={classes.imageText}
            >
              {productStore.loginProduct && productStore.loginProduct.productId === STAMP_CEFR_PRODUCT_ID.value() ? "BAND B" : "INT" }
            </text>
            <text
                id={"novice-level-area-text"}
                x={10}
                y={(noviceAndIntRectangleHeight) + advanceRectangleHeight + noviceAndIntRectangleHeight}
                className={classes.imageText}
            >
            {productStore.loginProduct && productStore.loginProduct.productId === STAMP_CEFR_PRODUCT_ID.value() ? "BAND A" : "NOV" }
            </text>
            <line
                id={"advances-level-striation-top-border-line"}
                x1={SKILL_TRIANGLE_ORIGIN_X}
                y1={"55"}
                x2={"100%"}
                y2={"55"}
                className={classes.backgroundLines}
            />
            <line
                id={"intermediate-level-striation-top-border-line"}
                x1={SKILL_TRIANGLE_ORIGIN_X}
                y1={intermediateStriationTopBorder}
                x2={"100%"}
                y2={intermediateStriationTopBorder}
                className={classes.backgroundLines}
            />
            <line
                id={"novice-level-striation-top-border-line"}
                x1={SKILL_TRIANGLE_ORIGIN_X}
                y1={noviceStriationTopBorder}
                x2={"100%"}
                y2={noviceStriationTopBorder}
                className={classes.backgroundLines}
            />
            <line
                id={"novice-level-striation-bottom-border-line"}
                x1={SKILL_TRIANGLE_ORIGIN_X}
                y1={"405"}
                x2={"100%"}
                y2={"405"}
                className={classes.backgroundLines}
            />
        </>
    )


}
