import React from "react"
import {RouteComponentProps} from "react-router"
import {AvantLogo} from "../common/AvantLogo"
import {ErrorMessage} from "./ErrorMessage"
import ApiService from "../../services/ApiService"
import MessageWall from "../../components/Login/MessageWall"
import {ScheduleProctorForm} from "./ScheduleProctorForm";

interface RosterPageProps extends RouteComponentProps<any> {
    loginId?: string
}
interface State {
    activeComponent?: React.ReactFragment
    testCodeId?: string
    verifyLoginMessage?: string
}
export class ScheduleProctorPage extends React.Component<RosterPageProps, State> {
    constructor(props: RosterPageProps) {
        super(props)

        let testCodeId = ""
        let activeComponent = <ErrorMessage errorMessage={`We are are validating your request.`}/>

        const reference: string = this.props.match.params.loginId
        if (reference == null) {
            activeComponent = <ErrorMessage errorMessage={`Your url was in error. (url:${reference})`}/>
        } else {
            //TODO may need REGEX filtering on URL
            testCodeId = reference
        }

        this.state = {
            testCodeId,
            activeComponent
        }

    }

    // Verify login here...
    componentDidMount() {
       if (this.state && this.state.testCodeId) {
                ApiService.verifyLoginByUUID(this.state.testCodeId)
                     .then(_ => {
                        // we got a 200.... all is good.
                        ApiService.get(`${ApiService.API_URL}p360/get-test-schedule/${this.state.testCodeId}`)
                            .then((timeslots => {
                                const activeComponent = <ScheduleProctorForm loginId={this.state.testCodeId!} proctorTimeSlots={timeslots.data} />
                                this.setState({activeComponent})
                            }))
                                    .catch(error => {
                                const activeComponent = <ErrorMessage errorMessage={error.message}/>
                                this.setState({activeComponent})
                        })
                    })
                    .catch(error => {
                        const activeComponent = <ErrorMessage errorMessage={error.message}/>
                        this.setState({activeComponent})
                    })
        }
    }

    render() {
        return (
            <div style={{marginTop: 8}}>
                <AvantLogo title={"Scheduling For Remote Proctoring"}/>
                <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                    <MessageWall />
                </div>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    {this.state.activeComponent}
                </div>
            </div>
        )
    }
}

