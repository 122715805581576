import * as Sentry from "@sentry/browser"
import {AxiosResponse} from "axios"
import {observable} from "mobx"
import {reject, resolve} from "q"
import ApiService from "../../../services/ApiService"
import HelperService from "../../../services/HelperService"
import {ApiErrorResponse, ApiItem, IItem} from "../../../types/types"
import {messageStore} from "../messages/MessageStore"
import {ItemHistory} from "./models/ItemHistory"

export class ItemStore {

    static get itemUrl(): string {
        return `${ApiService.API_URL}items`
    }

    private static itemAuditLogUrl = () => `${ApiService.API_URL}item-contents/auditLog`

    @observable
    apiError?: ApiErrorResponse

    @observable
    currentItemTrainingContent?: string

    @observable
    itemHistory?: ItemHistory[]

    @observable
    currentItem?: IItem

    getItem = (itemId: number) => {
        const url: string = `${ItemStore.itemUrl}/${itemId}`
        ApiService.get(url).then(
            (res: AxiosResponse) => {
                this.currentItem =
                    HelperService.parseItem(res.data.data as ApiItem, ApiService.MEDIA_URL.href)
                resolve()
            },
            (err: ApiErrorResponse) => {
                this.apiError = err
                reject()
            }
        )
    }

    getItemAuditLog = (itemId: number) => {
        const url: string = `${ItemStore.itemAuditLogUrl()}/${itemId}`
        ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
            (response: AxiosResponse<ItemHistory[]>) => {
                this.itemHistory = response.data
            },
            (error: ApiErrorResponse) => {
                Sentry.captureException(error)
                messageStore.setDefaultError()
            }
        )
    }
}

export const itemStore = new ItemStore()
