import * as HttpStatus from "http-status-codes"
import {observer} from "mobx-react"
import React from "react"
import {FormLabel, FormControl, FormGroup} from "react-bootstrap"
import {Checkbox, CheckboxGroup} from "react-checkbox-group"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"

import {isNullOrUndefined} from "util"
import * as validator from "validator"
import {loadingSpinnerStore} from "../../../app/common/loaders/LoadingSpinnerStore"
import {RoutePaths} from "../../../app/routes/Routes"
import {setLicenseKey} from "../../../redux/app/actions"
import ApiService from "../../../services/ApiService"
import HelperService from "../../../services/HelperService"
import {
    ApiErrorResponse,
    ApiLanguage,
    ApiLicenseKey,
    ApiProductLanguages,
    Choice,
    LastPage,
    NumberChoice,
    Product,
    State,
    UserForm
} from "../../../types/types"
import {to} from "../../../util/elvis"
import {ProductId} from "../../../validation/ValidPrimaryKey"
import {Brand} from "../../Brand/Brand"
import Button from "../../Button/Button"
import {PasswordChecker} from "../../PasswordChecker/PasswordChecker"
import ProfileProgress, {IProfileProgressCircle} from "../../ProfileProgress/ProfileProgress"

interface IStoreToProps {
    licenseKeyObj: ApiLicenseKey | null
}

function mapStateToProps(state: State): IStoreToProps {
    return {
        licenseKeyObj: state.app.licenseKey
    }
}

interface IDispatchToProps {
    setLicenseKeyDispatch: (licenseKey: ApiLicenseKey) => void
}

function mapDispatchToProps(dispatch: Dispatch): IDispatchToProps {
    return {
        setLicenseKeyDispatch(licenseKey: ApiLicenseKey) {
            dispatch(setLicenseKey(licenseKey))
        }
    }
}

type TComponentProps = IStoreToProps & IDispatchToProps & RouteComponentProps<any>

interface ILocalUserForm extends UserForm {
    password: string
    password2: string
    stateId: number
    districtId?: number
    schoolId?: number
}

interface IFormErrors {
    email?: string
    firstName?: string
    lastName?: string
    password?: string
    password2?: string
    products?: string
    studentLanguageIds?: string
    stateId?: string
}

interface IComponentState {
    loading: boolean
    licenseKeyObj: ApiLicenseKey | null
    productId: number | null
    isRegistered: boolean
    languageCount: number
    stateIdChoices: NumberChoice[]
    languageIdChoices: Choice[]
    formError: string | null
    formErrors: IFormErrors
    userForm: ILocalUserForm
    languageChoices: string[]
    submitEnabled: boolean
}

@observer
class RegisterUser extends React.Component<TComponentProps, IComponentState> {
    private formErrorFields = [
        "firstName",
        "lastName",
        "email",
        "password",
        "password2",
        "products",
        "studentLanguageIds",
        "stateId"
    ]

    constructor(props: TComponentProps) {
        super(props)
        const loading = !this.props.licenseKeyObj
        const productId = this.props.licenseKeyObj ? this.props.licenseKeyObj.productId : null
        const products = new Map<number, Product>()
        if (productId) {
            const product: Product = {
                productId,
                admin: false,
                studentLanguageIds: []
            }
            products.set(productId, product)
        }
        const languageCount: number = this.props.licenseKeyObj ? this.props.licenseKeyObj.languageCount : 1

        const blankUserForm: ILocalUserForm = {
            id: null,
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            password2: "",
            products,
            stateId:
                this.props.licenseKeyObj && this.props.licenseKeyObj.stateId ? this.props.licenseKeyObj.stateId : -1,
            districtId:
                this.props.licenseKeyObj && this.props.licenseKeyObj.districtId
                    ? this.props.licenseKeyObj.districtId
                    : undefined,
            schoolId:
                this.props.licenseKeyObj && this.props.licenseKeyObj.schoolId
                    ? this.props.licenseKeyObj.schoolId
                    : undefined
        }
        const userForm = {...blankUserForm}

        this.state = {
            loading,
            licenseKeyObj: this.props.licenseKeyObj,
            productId,
            isRegistered: false,
            languageIdChoices: [],
            submitEnabled: false,
            stateIdChoices: [
                {value: 118, label: "Alabama"},
                {value: 117, label: "Alaska"},
                {value: 116, label: "Arizona"},
                {value: 119, label: "Arkansas"},
                {value: 108, label: "California"},
                {value: 95, label: "Colorado"},
                {value: 82, label: "Connecticut"},
                {value: 112, label: "Delaware"},
                {value: 192, label: "District of Columbia"},
                {value: 104, label: "Florida"},
                {value: 153, label: "Georgia"},
                {value: 120, label: "Hawaii"},
                {value: 123, label: "Idaho"},
                {value: 67, label: "Illinois"},
                {value: 121, label: "Indiana"},
                {value: 122, label: "Iowa"},
                {value: 154, label: "Kansas"},
                {value: 124, label: "Kentucky"},
                {value: 155, label: "Louisiana"},
                {value: 126, label: "Maine"},
                {value: 130, label: "Maryland"},
                {value: 125, label: "Massachusetts"},
                {value: 86, label: "Michigan"},
                {value: 131, label: "Minnesota"},
                {value: 127, label: "Mississippi"},
                {value: 128, label: "Missouri"},
                {value: 129, label: "Montana"},
                {value: 138, label: "Nebraska"},
                {value: 137, label: "Nevada"},
                {value: 135, label: "New Hampshire"},
                {value: 134, label: "New Jersey"},
                {value: 132, label: "New Mexico"},
                {value: 71, label: "New York"},
                {value: 136, label: "North Carolina"},
                {value: 133, label: "North Dakota"},
                {value: 27, label: "Ohio"},
                {value: 156, label: "Oklahoma"},
                {value: 14, label: "Oregon"},
                {value: 41, label: "Pennsylvania"},
                {value: 140, label: "Rhode Island"},
                {value: 141, label: "South Carolina"},
                {value: 142, label: "South Dakota"},
                {value: 143, label: "Tennessee"},
                {value: 144, label: "Texas"},
                {value: 145, label: "Utah"},
                {value: 147, label: "Vermont"},
                {value: 35, label: "Virginia"},
                {value: 6463, label: "Virgin Islands"},
                {value: 146, label: "Washington"},
                {value: 151, label: "West Virginia"},
                {value: 148, label: "Wisconsin"},
                {value: 152, label: "Wyoming"}
            ],
            formError: null,
            formErrors: {},
            userForm,
            languageCount,
            languageChoices: []
        }
    }

    componentDidMount() {
        document.title = `Avant | Create Account`
        const lastPage: LastPage = {
            url: "/register"
        }
        localStorage.setItem("lastPage", JSON.stringify(lastPage))
        const licenseKey = HelperService.getUrlParameter("key")

        if (licenseKey !== null) {
            let licenseKeyObj: ApiLicenseKey

            if (this.props.licenseKeyObj == null) {
                this.setState({loading: true})

                ApiService
                    .validateLicenseKey(licenseKey)
                    .then(
                        (res: ApiLicenseKey) => {
                            licenseKeyObj = res
                            return ApiService.getProductLanguages(new ProductId(licenseKeyObj.productId))
                        },
                        (err: ApiErrorResponse) => {
                            throw new Error(`Invalid license key: ${err.response.data.kind}`)
                        }
                    )
                    .then(
                        (productLanguages: ApiProductLanguages) => {
                            const languageIdChoices = productLanguages.languages.map((lang: ApiLanguage) => {
                                return {
                                    value: lang.id + "",
                                    label: lang.name
                                }
                            })

                            this.setState({languageIdChoices, licenseKeyObj})
                            this.props.setLicenseKeyDispatch(licenseKeyObj)
                            this.props.history.push(`/register?key=${licenseKey}`)
                        },
                        (err: ApiErrorResponse) => {
                            if (err.response !== undefined) {
                                throw new Error(`Could not find language ids for productId ${licenseKeyObj.productId}`)
                            }
                        }
                    )
            } else {
                // Grab languages
                licenseKeyObj = to<ApiLicenseKey>(this.props.licenseKeyObj, Error("licenseKeyObj not found on props"))
                this.setState({loading: true})
                ApiService.getProductLanguages(new ProductId(licenseKeyObj.productId)).then(
                    (productLanguages: ApiProductLanguages) => {
                        const languageIdChoices = productLanguages.languages.map((lang: ApiLanguage) => {
                            return {
                                value: lang.id + "",
                                label: lang.name
                            }
                        })
                        const userForm = this.createInitialUserForm(licenseKeyObj)
                        this.setState({languageIdChoices, userForm, loading: false})
                    },
                    () => {
                        throw Error(`Could not find language ids for productId ${licenseKeyObj.productId}`)
                    }
                )
            }
        } else {
            this.props.history.push("/claim")
        }
        loadingSpinnerStore.hideLoadingSpinner = true
    }

    componentDidUpdate() {
        if (this.props.licenseKeyObj && this.props.licenseKeyObj !== this.state.licenseKeyObj) {
            const productId = this.props.licenseKeyObj.productId
            const userForm = this.createInitialUserForm(this.props.licenseKeyObj)
            if (productId !== this.state.productId || userForm !== this.state.userForm) {
                this.setState({
                    userForm,
                    productId,
                    licenseKeyObj: this.props.licenseKeyObj
                })
            }
        }

        if (this.state.languageIdChoices.length > 0 && this.props.licenseKeyObj && this.state.loading) {
            this.setState({loading: false})
        }
    }

    createInitialUserForm = (licenseKeyObj: ApiLicenseKey): ILocalUserForm => {
        const products = this.state.userForm.products
        const studentLanguageIds: number[] = []

        const product: Product = {
            productId: licenseKeyObj.productId,
            admin: false,
            studentLanguageIds
        }
        products.set(product.productId, product)

        const stateId = licenseKeyObj.stateId ? licenseKeyObj.stateId : this.state.stateIdChoices[0].value
        const districtId = licenseKeyObj.districtId ? licenseKeyObj.districtId : undefined
        const schoolId = licenseKeyObj.schoolId ? licenseKeyObj.schoolId : undefined
        return {
            ...this.state.userForm,
            products,
            stateId,
            districtId,
            schoolId
        }
    }

    onCheckboxChange = () => {
        // placeholder for typescript. on click logic is handled in onCbListGroupClick
    }

    handleLanguageChange = (value: string) => {
        const choices: string[] = this.state.languageChoices
        const index: number = choices.indexOf(value)
        if (choices.length < this.state.languageCount || (choices.length === this.state.languageCount && index > -1)) {
            if (index > -1) {
                choices.splice(index, 1)
            } else {
                choices.push(value)
            }
            const userForm = this.state.userForm
            const studentLanguageIds = choices.map((choice: string) => {
                return parseInt(choice, 10)
            })
            const productId = to<number>(this.state.productId, Error("productId not found on state."))
            const oldProduct = this.state.userForm.products.get(productId)
            let admin: boolean = false
            if (oldProduct) {
                admin = oldProduct.admin
            }
            userForm.products.set(productId, {productId, admin, studentLanguageIds})
            this.setState({userForm})
        }
    }

    handleEmailChange = (event: any) => {
        const userForm = {...this.state.userForm, email: event.target.value.trim().toLowerCase()}
        this.setState({userForm})
    }

    handleFirstNameChange = (event: any) => {
        const userForm = {...this.state.userForm, firstName: event.target.value.trim()}
        this.setState({userForm})
    }

    handleLastNameChange = (event: any) => {
        const userForm = {...this.state.userForm, lastName: event.target.value.trim()}
        this.setState({userForm})
    }

    handlePasswordChange = (event: any) => {
        const userForm = {...this.state.userForm, password: event.target.value}
        this.setState({userForm})
    }

    handlePassword2Change = (event: any) => {
        const userForm = {...this.state.userForm, password2: event.target.value}
        this.setState({userForm})
    }

    getPasswordErrors = (values: ILocalUserForm): string | undefined => {
        if (values.password.length === 0) {
            return "Please enter a password."
        }
        return undefined
    }

    getPassword2Errors = (values: ILocalUserForm): string | undefined => {
        if (values.password2.length === 0) {
            return "Please confirm your password."
        } else if (values.password2 !== values.password) {
            return "Password does not match"
        }
        return undefined
    }


    getStudentLanguageErrors = (values: ILocalUserForm): string | undefined => {
        const productId = to<number>(this.state.productId, Error("productId not found on state."))
        const product = values.products.get(productId)
        if (!product) {
            return `You must select ${this.state.languageCount} language(s).`
        }
        if (product.studentLanguageIds.length < this.state.languageCount) {
            return `You must select ${this.state.languageCount} language(s).`
        }
        return undefined
    }

    getStateErrors = (values: ILocalUserForm): string | undefined => {
        return isNullOrUndefined(values.stateId) ? "Please select a state." : undefined
    }

    getFirstNameErrors = (values: ILocalUserForm): string | undefined => {
        if (!values.firstName) {
            return "You must enter your first name."
        }
        return undefined
    }

    getLastNameErrors = (values: ILocalUserForm): string | undefined => {
        if (!values.lastName) {
            return "You must enter your last name."
        }
        return undefined
    }

    getEmailErrors = (values: ILocalUserForm): string | undefined => {
        if (!values.email) {
            return "Please enter your email."
        }
        if (!validator.isEmail(values.email)) {
            return "Please enter a valid email."
        }
        return undefined
    }

    validate = (values: ILocalUserForm): { hasErrors: boolean; formErrors: IFormErrors } => {
        const formErrors: IFormErrors = {
            email: this.getEmailErrors(values),
            firstName: this.getFirstNameErrors(values),
            lastName: this.getLastNameErrors(values),
            password: this.getPasswordErrors(values),
            password2: this.getPassword2Errors(values),
            studentLanguageIds: this.getStudentLanguageErrors(values),
            stateId: this.getStateErrors(values)
        }

        let hasErrors: boolean = false
        this.formErrorFields.forEach((field: string) => {
            if (formErrors[field]) {
                hasErrors = true
            }
        })
        return {
            hasErrors,
            formErrors
        }
    }

    handleSubmit = () => {
        this.setState({
            formError: null,
            formErrors: {}
        })
        const errors = this.validate(this.state.userForm)
        if (errors.hasErrors) {
            this.setState({
                formError: "There are errors on the form.",
                formErrors: errors.formErrors
            })
            return
        }

        const licenseKeyObj = to<ApiLicenseKey>(this.state.licenseKeyObj, Error("licenseKeyObj not found on state."))
        const key = to<string>(licenseKeyObj.key, Error("License key is null or undefined."))
        ApiService
            .registerUser(key, this.state.userForm)
            .then(() => {
                this.setState({isRegistered: true})
            })
            .catch((error: ApiErrorResponse) => {
                if (error.response.status === HttpStatus.CONFLICT) {
                    errors.hasErrors = true
                    errors.formErrors.email = error.response.data.errors[0]
                    this.setState({
                        formError: "There are errors on the form.",
                        formErrors: errors.formErrors
                    })
                }
            })
    }

    handleGotoLogin = () => {
        this.props.history.push(RoutePaths.ADVANCE_LOGIN)
    }

    registeredPanel() {
        const circles: IProfileProgressCircle[] = [{state: "visited"}, {state: "active"}]
        const textBegin = "An email has been sent to "
        const textEnd =
            ". Please check your email for instructions. " +
            "If it doesn’t appear within a few minutes, check your spam folder."
        return (
            <div className="register">
                <div className="register__container grid">
                    <div className="register__container__top grid-row">
                        <div className="grid-col-12">
                            <div className="register__container__top__title">Create an Account</div>
                            <ProfileProgress circles={circles}/>
                        </div>
                    </div>
                    <div className="register__container__bottom grid-row">
                        <div className="grid-col-8 grid-offset-2">
                            <div className="register__container__bottom__title">Confirm Your Account</div>
                            <div className="register__container__bottom__text">
                                {textBegin}
                                <span className="register__container__bottom__text--bold">
                                    {this.state.userForm.email}
                                </span>
                                {textEnd}
                            </div>
                        </div>
                    </div>
                    <div className="grid-row">
                        <div className="grid-col-4 grid-offset-4">
                            <Button className="register__container__form__button" onClick={this.handleGotoLogin}>
                                GO TO LOGIN
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderPasswords = (password: string, confirmPassword: string) => {
        return (
            <div className="register__container__form grid-row">
                <div className="grid-col-4 grid-offset-2">
                    <div className="grid-row">
                        <div className="grid-col-8">
                            <div className={this.state.formErrors.password ? "register__container__form--error" : ""}>
                                <FormGroup>
                                    <FormLabel>Password</FormLabel>
                                    <FormControl
                                        type="password"
                                        placeholder=""
                                        value={this.state.userForm.password}
                                        onChange={this.handlePasswordChange}
                                        data-tst-id="password"
                                    />
                                    {this.state.formErrors.password && (
                                        <div className="register__container__form--error__message">
                                            {this.state.formErrors.password}
                                        </div>
                                    )}
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div className="register__container__form grid-row">
                        <div className="grid-col-8">
                            <div className={this.state.formErrors.password2 ? "register__container__form--error" : ""}>
                                <FormGroup>
                                    <FormLabel>Confirm Password</FormLabel>
                                    <FormControl
                                        type="password"
                                        placeholder=""
                                        value={this.state.userForm.password2}
                                        onChange={this.handlePassword2Change}
                                        data-tst-id="confirm-password"
                                    />
                                    {this.state.formErrors.password2 && (
                                        <div className="register__container__form--error__message">
                                            {this.state.formErrors.password2}
                                        </div>
                                    )}
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="register__container__form grid-col-4 grid-col">
                    <PasswordChecker
                        password={password}
                        confirmPassword={confirmPassword}
                        className="register__container__form__password-checker"
                    />
                </div>
            </div>
        )
    }

    renderForm() {
        const circles: IProfileProgressCircle[] = [{state: "active"}, {state: "inactive"}]
        return (
            <div className="register">
                <div className="register__container grid">
                    <div className="register__container__top grid-row">
                        <div className="grid-col-12">
                            <div className="register__container__top__title">Create an Account</div>
                            <ProfileProgress circles={circles}/>
                        </div>
                    </div>
                    <div style={{marginTop: "24px"}} className="register__container__form grid-row">
                        <div className="grid-col-8 grid-offset-2">
                            <div
                                className={
                                    this.state.formErrors.studentLanguageIds ? "register__container__form--error" : ""
                                }
                            >
                                <FormGroup>
                                    <div style={{marginBottom: "12px"}}>
                                        <FormLabel>Choose Your {this.state.languageCount} Language(s)</FormLabel>
                                        {this.state.formErrors.studentLanguageIds && (
                                            <div
                                                style={{display: "inline-block", marginLeft: "6px"}}
                                                className="register__container__form--error__message"
                                            >
                                                {this.state.formErrors.studentLanguageIds}
                                            </div>
                                        )}
                                    </div>
                                    <CheckboxGroup
                                        name="checkbox"
                                        value={this.state.languageChoices}
                                        onChange={this.onCheckboxChange}
                                    >
                                        <div>
                                            {this.state.languageIdChoices.map((choice: Choice) => {
                                                return (
                                                    <div
                                                        style={{
                                                            display: "inline-block",
                                                            width: "155px",
                                                            marginBottom: "17px"
                                                        }}
                                                        onClick={() => this.handleLanguageChange(choice.value)}
                                                        key={`language-${choice.value}`}
                                                    >
                                                        <Checkbox value={choice.value}/>
                                                        <span
                                                            style={{
                                                                paddingLeft: "11px",
                                                                verticalAlign: "middle",
                                                                cursor: "pointer"
                                                            }}
                                                        >
                                                            {choice.label}
                                                        </span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </CheckboxGroup>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div className="register__container__form grid-row">
                        <div className="grid-col-4 grid-offset-2">
                            <div className={this.state.formErrors.firstName ? "register__container__form--error" : ""}>
                                <FormGroup>
                                    <FormLabel>First Name</FormLabel>
                                    <FormControl
                                        type="text"
                                        placeholder=""
                                        value={this.state.userForm.firstName}
                                        onChange={this.handleFirstNameChange}
                                        data-tst-id="first-name"
                                    />
                                    {this.state.formErrors.firstName && (
                                        <div className="register__container__form--error__message">
                                            {this.state.formErrors.firstName}
                                        </div>
                                    )}
                                </FormGroup>
                            </div>
                        </div>
                        <div className="grid-col-4 grid-col">
                            <div className={this.state.formErrors.lastName ? "register__container__form--error" : ""}>
                                <FormGroup>
                                    <FormLabel>Last Name</FormLabel>
                                    <FormControl
                                        type="text"
                                        placeholder=""
                                        value={this.state.userForm.lastName}
                                        onChange={this.handleLastNameChange}
                                        data-tst-id="last-name"
                                    />
                                    {this.state.formErrors.lastName && (
                                        <div className="register__container__form--error__message">
                                            {this.state.formErrors.lastName}
                                        </div>
                                    )}
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div className="register__container__form grid-row">
                        <div className="grid-col-8 grid-offset-2">
                            <div className={this.state.formErrors.email ? "register__container__form--error" : ""}>
                                <div className="wide-form">
                                    <FormGroup>
                                        <FormLabel>Email</FormLabel>
                                        <FormControl
                                            type="email"
                                            placeholder=""
                                            value={this.state.userForm.email}
                                            onChange={this.handleEmailChange}
                                            data-tst-id="email"
                                        />
                                        {this.state.formErrors.email && (
                                            <div className="register__container__form--error__message">
                                                {this.state.formErrors.email}
                                            </div>
                                        )}
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderPasswords(this.state.userForm.password, this.state.userForm.password2)}
                    <div className="register__container__form grid-row">
                        <div className="grid-col-4 grid-offset-4">
                            <Button className="register__container__form__button" onClick={this.handleSubmit}>
                                SUBMIT
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        if (!this.state.productId) {
            this.props.history.push("/claim")
        }
        return (
            <>
                <div className="header">
                    <Brand appName="ADVANCE"/>
                </div>
                {this.state.loading ? (
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <h2>Validating Key...</h2>
                    </div>
                ) : (
                    <>{!this.state.isRegistered ? this.renderForm() : this.registeredPanel()}</>
                )}
            </>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterUser)
