import React from "react"


export const HashPattern: React.FC<{ id: string, stroke: string, fillColor: string }> = ({id, stroke,fillColor}) => {
    return (
        <pattern
            id={id}
            x={"0"}
            y={"0"}
            width={"100%"}
            height={"15"}        // Increase this number to decrease the density of hashed lines
            patternUnits={"userSpaceOnUse"}
            patternTransform={"rotate(62.5)"}

        >
            <rect width="100%" height="100%" fill={fillColor}/>

            <line
                x1={0}
                y1={0}
                x2={700}
                y2={0}
                strokeWidth={10}
                strokeLinejoin={"round"}
                stroke={stroke}
            />
        </pattern>
    )
}
