import {observable} from "mobx"

class ScreenUtil {

    @observable
    screenWidth: number

    constructor() {
        this.screenWidth = window.innerWidth
        this.onResize()
        window.addEventListener("resize", this.onResize)
    }

    private onResize = () => {
        this.screenWidth = window.innerWidth
    }
}

export const screenUtil = new ScreenUtil()
