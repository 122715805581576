import {
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, Grid, Typography, Paper
} from "@material-ui/core"
import {default as React} from "react"
import ApiService from "../../../services/ApiService"
import {loadingSpinnerStore} from "../../common/loaders/LoadingSpinnerStore"
import {messageStore} from "../../common/messages/MessageStore"
import {TimeUtil} from "../../../util/TimeUtil";
import moment from "moment";

const style = {
    paper: {
        margin: "10px",
        padding: "10px"
    },
    buttons: {
        marginTop: "15px",
        marginBottom: "10px",
        marginLeft: "20px"
    }
}

export interface SSOTeacherConfirmSetupProps {
    onClose: () => void,
    closeBoth: () => void,
    product_package: string,
    groupId: number
    useHandwritten: boolean,
    estDate: Date,
    nameOfClass: string | null,
    languageSelected: string | null,
    panelGraphId: number
}

export class SSOTeacherAssessmentConfirm extends React.Component<SSOTeacherConfirmSetupProps, {}> {

    handleClose = (): void => {this.props.onClose()}

    handleSave = (): void => {
            loadingSpinnerStore.hideLoadingSpinner = false
            const userDate = this.props.estDate
            const utcDate = TimeUtil.usDateToUtcDate(userDate)
            ApiService.setupTest(this.props.groupId, this.props.panelGraphId, this.props.useHandwritten, utcDate)
                .then(response => {
                    if (response) {
                        loadingSpinnerStore.hideLoadingSpinner = true
                        this.props.closeBoth()
                        messageStore.setInfoMessage("The test has been set up. When you are ready to start testing, click allow test to grant student access.")
                    }
                })
                .catch((_) => {
                        loadingSpinnerStore.hideLoadingSpinner = true
                        messageStore.setInfoMessage("We could not set up this test. Please contact support.")
                    }
                )

    }

    render() {
        return (
            <Dialog onClose={this.handleClose} aria-labelledby="Confirm Test" open={true}>
                <DialogTitle id="simple-dialog-title">Confirm Test</DialogTitle>
                <DialogContent>
                    <DialogContentText>You are about to setup the following test for <b>{this.props.nameOfClass}.</b></DialogContentText>
                    <DialogContentText>You will not be able to make any changes after you setup the test.
                        Please review the information and choose to continue or cancel the operation.
                        Would you like to continue?
                    </DialogContentText>

                    <Paper style={style.paper}>
                        <Grid container wrap="nowrap" spacing={2} >
                            <Grid item>
                                <Typography variant={"body2"}>Product Name:</Typography>
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography noWrap>{this.props.product_package}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper style={style.paper}>
                        <Grid container wrap="nowrap" spacing={2} >
                            <Grid item>
                                <Typography variant={"body2"}>Test Language:</Typography>
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography noWrap>{this.props.languageSelected}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper style={style.paper}>
                        <Grid container wrap="nowrap" spacing={2} >
                            <Grid item>
                                <Typography variant={"body2"}>Estimated Start Date:</Typography>
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography noWrap>{TimeUtil.formatToUsDate(moment(this.props.estDate))}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper style={style.paper}>
                        <Grid container wrap="nowrap" spacing={2} >
                            <Grid item>
                                <Typography variant={"body2"}>Writing Test Method:</Typography>
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography noWrap>{this.props.useHandwritten ? "Handwritten" : "Keyboard"}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>

                    <DialogActions style={style.buttons}>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={this.handleClose}
                            aria-label={"Close with out saving"}
                        >
                            No, Cancel
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={this.handleSave}
                            aria-label={"Confirm correct test settings"}
                        >
                            Yes, Continue
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }
}
