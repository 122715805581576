import * as React from 'react'
import {SUFResult} from "../../services/ApiService"
import MaterialTable, {Column} from "material-table"
import moment from "moment";

interface CodeGenTestTakerTableProps {
    row: SUFResult, // Add SUFRow
}

interface SUFRowWithSUFResult {
    product: string,
    state: string,
    district: string,
    school: string,
    class: string,
    language: string,
    openDate: string,
    closeDate: string,
    studentCode: string,
    teacherCode: string,
    error: string | undefined
}


const CodeGenTestTakerListTable: React.FC<CodeGenTestTakerTableProps> = ({row}) => {

    const sufRowTestTakerHeaders: Column<SUFRowWithSUFResult>[] = [
        {title: "Product Package",field: "product", type: "string"},
        {title: "State",field: "state", type: "string"},
        {title: "District\\Customer",field: "district", type: "string"},
        {title: "School",field: "school", type: "string"},
        {title: "Class\\Locacation",field: "class", type: "string"},
        {title: "Language", field: "language", type: "string"},
        {title: "Test Open Date",field: "openDate", type: "date"},
        {title: "Test Close Date",field: "closeDate", type: "date"},
        {title: "Student Code",field: "studentCode", type: "string"},
        {title: "Teacher Code",field: "teacherCode", type: "string"},
        {title: "Error",field: "error", type: "string"},
    ]

    const getTestTakerRows = (): SUFRowWithSUFResult[] => {
        return row.sufRows.map((suf): SUFRowWithSUFResult => {

            return {
                product: row.productPackage,
                state: row.state,
                district: row.districtOrCustomer,
                school: suf.school ? suf.school : "BLANK",
                class: suf.classOrLocation ? suf.classOrLocation : "BLANK",
                language: suf.language ? suf.language : "BLANK",
                openDate: suf.openDate ? moment(suf.openDate).format("MM/DD/YYYY") : "BLANK",
                closeDate: suf.closeDate ? moment(suf.closeDate).format("MM/DD/YYYY") : "BLANK",
                studentCode: suf.student ? suf.student.username : "BLANK",
                teacherCode: suf.teacher ? suf.teacher.username : "BLANK",
                error: suf.error ? suf.error : "",
            }
        })
    }

    return (
        <div style={{width: '100%'}}>
            <MaterialTable
                data={getTestTakerRows()}
                columns={sufRowTestTakerHeaders}
                options={
                    {
                        selection: false,
                        paging: false,
                        search: false,
                        toolbar: false,
                        rowStyle: (rowData: SUFRowWithSUFResult) => ({
                            color: (rowData.error && rowData.error.length > 0) ? "red" : "black"
                        })
                    }
                }
            />
        </div>
    )
}

export default CodeGenTestTakerListTable
