import React from "react"
import MessageDropDown from "./MessageDropDown"

interface IComponentProps {
    inputDevices: MediaDeviceInfo[]
}

export default class GetHelpMessage extends React.Component<IComponentProps, {}> {

    render() {
        return (
            <div className="GetHelpMessage">
                <MessageDropDown title={""} message={""} inputDevices={this.props.inputDevices} />
            </div>
        )
    }
}
