/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import { observer } from "mobx-react"
import moment from "moment"
import React from "react"
import { Alert, Card, Col, Container, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { RouteComponentProps } from "react-router"
import { Dispatch } from "redux"
import { isNullOrUndefined } from "util"
import { productStore } from "../../app/common/products/ProductStore"
import { postProfileSuccess } from "../../redux/app/actions"
import ApiService from "../../services/ApiService"
import HelperService from "../../services/HelperService"
import { ApiPanelGraph, LastPage, Profile, State, Take, TestState } from "../../types/types"
import { CONTENT_AREA_ID_TO_LANGUAGE_NAME, ProctorTypes, SUPPORT_MESSAGE } from "../../util/Constants"
import { TakeId } from "../../validation/ValidPrimaryKey"
import { addTakeDispatches, TakeDispatches } from "../App/App"
import Button from "../Button/Button"
import PlaceProfileConstants, { IProfileLanguage } from "../place/PlaceProfileConstants"
import { H4Text } from "../../styles/AvantTypography"
import { theme } from "../../styles/MuiThemes"
import { authStore } from "../../app/common/authentication/AuthStore"
import LanguageUtils from "../../util/LanguageUtils"
import { validateEmail } from "../../util/validators"

interface IFormErrors {
    firstName: string[]
    lastName: string[]
    testTakerId: string[]
    confirmTestTakerId: string[]
    grade: string[]
    firstLanguage: string[]
    otherFirstLanguage: string[]
    familySpeaksTestLanguage: string[]
    howOftenLanguageIsSpoken: string[]
    yearsStudyingOrSpoken: string[]
    studiedAbroad: string[]
    monthsAbroad: string[]
    yearsAbroad: string[]
    testTakerEmail: string[]
    confirmTestTakerEmail: string[]
}
interface IStateToProps {
    take: Take | null
    takeCode: string
    panelGraph: ApiPanelGraph
}
function mapStateToProps(state: State): IStateToProps {
    return {
        take: state.app.take as Take,
        takeCode: state.app.takeCode,
        panelGraph: state.app.panelGraph!
    }
}
interface IDispatchToProps extends TakeDispatches {
    profileSuccessDispatch: (profile: Profile) => void
}

function mapDispatchToProps(dispatch: Dispatch): IDispatchToProps {
    const dispatchs = {
        profileSuccessDispatch: (profile: Profile) => {
            dispatch(postProfileSuccess(profile))
        }
    }
    return addTakeDispatches(dispatchs, dispatch)
}
export type TComponentProps = IStateToProps & IDispatchToProps & RouteComponentProps<any>

interface IComponentState {
    error: boolean | string
    profile: Profile
    confirmTestTakerId: string
    confirmTestTakerEmail: string
    otherFamilyMember?: string
    take: Take | null
    languageName: string
    formErrors: IFormErrors
    formError: string | null
    loaded: boolean
    famSpeaksLang: string
    timeSpoken: string
    studyAbroad: string
}
@observer
class Stamp4SProfileFormContainer extends React.Component<TComponentProps, IComponentState> {
    private grades = [
        "",
        "K",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "16+"
    ]
    // This needs to exist outside the state system. This is to determine if the required fields were already filled.
    // If they're not we'll let them fill them.
    private isReadOnly: boolean = false

    constructor(props: TComponentProps) {
        super(props)
        PlaceProfileConstants.languages.sort((a: IProfileLanguage, b: IProfileLanguage) => {
            // Special case: Always sort "OTHER" to last position.
            if (b.name === "OTHER" || a.name < b.name) {
                return -1
            }
            if (a.name > b.name) {
                return 1
            }
            return 0
        })
        this.state = {
            error: false,
            profile: {
                firstName:
                    props.take && props.take.profile && props.take.profile.firstName !== undefined
                        ? props.take.profile.firstName
                        : "",
                lastName:
                    props.take && props.take.profile && props.take.profile.lastName !== undefined
                        ? props.take.profile.lastName
                        : "",
                testTakerId:
                    props.take && props.take.profile && props.take.profile.testTakerId !== undefined
                        ? props.take.profile.testTakerId
                        : "",
                grade:
                    props.take && props.take.profile && props.take.profile.grade !== undefined
                        ? props.take.profile.grade
                        : this.grades[0],
                firstLanguage:
                    props.take && props.take.profile && props.take.profile.firstLanguage !== undefined
                        ? props.take.profile.firstLanguage
                        : PlaceProfileConstants.languages[0].name,
                otherFirstLanguage:
                    props.take && props.take.profile && props.take.profile.otherFirstLanguage !== undefined
                        ? props.take.profile.otherFirstLanguage
                        : "",
                familySpeaksTestLanguage:
                    props.take && props.take.profile && props.take.profile.familySpeaksTestLanguage !== undefined
                        ? props.take.profile.familySpeaksTestLanguage
                        : undefined,
                howOftenLanguageIsSpoken:
                    props.take && props.take.profile && props.take.profile.howOftenLanguageIsSpoken !== undefined
                        ? props.take.profile.howOftenLanguageIsSpoken
                        : "",
                testTakerEmail:
                    props.take && props.take.profile && props.take.profile.testTakerEmail !== undefined
                        ? props.take.profile.testTakerEmail
                        : "",
                yearsStudyingOrSpoken:
                    props.take && props.take.profile && props.take.profile.yearsStudyingOrSpoken !== undefined
                        ? props.take.profile.yearsStudyingOrSpoken
                        : "",
                studiedAbroad:
                    props.take && props.take.profile && props.take.profile.studiedAbroad !== undefined
                        ? props.take.profile.studiedAbroad
                        : undefined,
                monthsAbroad:
                    props.take && props.take.profile && props.take.profile.monthsAbroad !== undefined
                        ? props.take.profile.monthsAbroad
                        : "",
                yearsAbroad:
                    props.take && props.take.profile && props.take.profile.yearsAbroad !== undefined
                        ? props.take.profile.yearsAbroad
                        : "",
                schoolName: props.take && props.take.profile ? props.take.profile.schoolName : "",
                testGroupName: props.take && props.take.profile ? props.take.profile.testGroupName : ""
            },
            confirmTestTakerId:
                props.take && props.take.profile && props.take.profile.testTakerId !== undefined
                    ? props.take.profile.testTakerId
                    : "",
            confirmTestTakerEmail:
                props.take && props.take.profile && props.take.profile.testTakerEmail !== undefined
                    ? props.take.profile.testTakerEmail
                    : "",
            otherFamilyMember: "",
            languageName: "",
            formErrors: {
                firstName: [],
                lastName: [],
                testTakerId: [],
                confirmTestTakerId: [],
                grade: [],
                firstLanguage: [],
                otherFirstLanguage: [],
                familySpeaksTestLanguage: [],
                howOftenLanguageIsSpoken: [],
                yearsStudyingOrSpoken: [],
                studiedAbroad: [],
                monthsAbroad: [],
                yearsAbroad: [],
                testTakerEmail: [],
                confirmTestTakerEmail: [],
            },
            formError: null,
            take: props.take,
            loaded: false,
            famSpeaksLang: "",
            timeSpoken: "",
            studyAbroad: ""
        }
        this.isReadOnly = productStore.isReadOnlyProfile(this.state.profile.firstName, this.state.profile.lastName, this.state.profile.testTakerId, this.state.confirmTestTakerId)
    }
    async componentDidMount() {
        HelperService.enableTextSelection()
        await this.getData()
    }
    async componentDidUpdate() {
        if (this.props.take !== this.state.take) {
            this.setState({ take: this.props.take })
        }
        if (this.props.take && this.props.take.profile && this.props.take.profile !== this.state.profile) {
            this.setState({ profile: this.props.take.profile })
        }
        await this.getData()
    }
    getData = async () => {
        const driver = productStore.driver
        const product = productStore.loginProduct
        const { takeCode, panelGraph } = this.props
        const { loaded } = this.state
        if (driver && !loaded) {
            this.setState({ loaded: true })
            document.title = `${driver.config.PRODUCT_TITLE} | Profile`
            let lastPage: LastPage | null = HelperService.getLastPageFromLs()
            if (lastPage) {
                const testState: TestState = await driver.helper.refreshTest(this.props, lastPage, product!)
                if (!testState.take) {
                    throw Error("testState.take is null or undefined")
                }
                if (!testState.take.profile) {
                    throw Error("testState.take.profile is null or undefined")
                }
                const languageId: number = this.props.panelGraph.contentAreaId
                let languageName = LanguageUtils.removeTilde(CONTENT_AREA_ID_TO_LANGUAGE_NAME[languageId])
                languageName = LanguageUtils.removeMonolingual(languageName)

                this.isReadOnly = productStore.isReadOnlyProfile(testState.take.profile.firstName, testState.take.profile.lastName, testState.take.profile.testTakerId, testState.take.profile.testTakerId)
                if (this.isReadOnly) {
                    this.setState({
                        confirmTestTakerId:
                            testState.take && testState.take.profile && testState.take.profile.testTakerId !== undefined
                                ? testState.take.profile.testTakerId
                                : "",
                    }
                    )
                }

                this.setState({ take: testState.take, profile: testState.take.profile, languageName })
            } else {
                lastPage = {
                    url: "/stamp4s-profile",
                    takeCode,
                    panelGraphId: panelGraph.id
                }
                localStorage.setItem("lastPage", JSON.stringify(lastPage))
                const languageId: number = this.props.panelGraph.contentAreaId
                const languageName: string = CONTENT_AREA_ID_TO_LANGUAGE_NAME[languageId]
                this.setState({ languageName })
            }
        }
    }
    handleFirstNameChange = (e: any) => {
        const profile: Profile = this.state.profile
        profile.firstName = e.target.value
        this.setState({ profile })
    }
    handleLastNameChange = (e: any) => {
        const profile: Profile = this.state.profile
        profile.lastName = e.target.value
        this.setState({ profile })
    }
    handleTestTakerIdChange = (e: any) => {
        const profile: Profile = this.state.profile
        profile.testTakerId = e.target.value
        this.setState({ profile })
    }
    handleConfirmTestTakerIdChange = (e: any) => {
        this.setState({ confirmTestTakerId: e.target.value })
    }
    handleGradeChange = (e: any) => {
        const profile: Profile = this.state.profile
        profile.grade = e.target.value
        this.setState({ profile })
    }
    handleEmailChange = (e: any) => {
        const profile: Profile = this.state.profile
        profile.testTakerEmail = e.target.value
        this.setState({ profile })
    }
    handleConfirmEmailChange = (e: any) => {
        this.setState({ confirmTestTakerEmail: e.target.value })
    }
    validateFirstName(profile: Profile): string[] {
        const firstName = profile.firstName
        if (isNullOrUndefined(firstName) || firstName.length === 0) {
            return ["Please enter your first name."]
        }
        return []
    }
    validateLastName(profile: Profile): string[] {
        const lastName = profile.lastName
        if (isNullOrUndefined(lastName) || lastName.length === 0) {
            return ["Please enter your last name."]
        }
        return []
    }
    validateTestTakerId(profile: Profile): string[] {
        const testTakerId = profile.testTakerId
        if (isNullOrUndefined(testTakerId) || testTakerId.length === 0) {
            return ["Please enter your test taker id."]
        }
        return []
    }
    validateConfirmTestTakerId(profile: Profile): string[] {
        const confirmTestTakerId = this.state.confirmTestTakerId
        const testTakerId = profile.testTakerId
        if (isNullOrUndefined(confirmTestTakerId) || confirmTestTakerId.length === 0) {
            return ["Please confirm your test taker id."]
        }
        if (confirmTestTakerId !== testTakerId) {
            return ["Your test taker id and test taker id confirmation must match."]
        }
        return []
    }
    validateOtherFirstLanguage(profile: Profile): string[] {
        const firstLanguage = profile.firstLanguage
        if (!isNullOrUndefined(firstLanguage) && firstLanguage === "OTHER") {
            const otherFirstLanguage = profile.otherFirstLanguage
            if (isNullOrUndefined(otherFirstLanguage) || otherFirstLanguage.length === 0) {
                return ["Please enter the other first language."]
            }
        }
        return []
    }
    validateTestTakerEmail(profile: Profile): string[] {
        const testTakerEmail = profile.testTakerEmail

        // Email isn't currently required, so we don't do anything if the field is empty
        if (testTakerEmail) {
            if (!validateEmail(testTakerEmail!)) {
                return ["Please enter a valid email."]
            }
        }

        return []
    }
    validateConfirmTestTakerEmail(profile: Profile): string[] {
        const confirmTestTakerEmail = this.state.confirmTestTakerEmail
        const testTakerEmail = profile.testTakerEmail

        if (testTakerEmail) {
            if (confirmTestTakerEmail === "") {
                return ["Please confirm your email."]
            }

            if (confirmTestTakerEmail !== testTakerEmail) {
                return ["Your email and email confirmation must match."]
            }
        }

        return []
    }
    validate = (profile: Profile): { hasErrors: boolean; formErrors: IFormErrors } => {
        const formErrors: IFormErrors = {
            firstName: this.validateFirstName(profile),
            lastName: this.validateLastName(profile),
            testTakerId: this.validateTestTakerId(profile),
            confirmTestTakerId: this.validateConfirmTestTakerId(profile),
            grade: [],
            firstLanguage: [],
            otherFirstLanguage: this.validateOtherFirstLanguage(profile),
            familySpeaksTestLanguage: [],
            howOftenLanguageIsSpoken: [],
            yearsStudyingOrSpoken: [],
            studiedAbroad: [],
            monthsAbroad: [],
            yearsAbroad: [],
            testTakerEmail: this.validateTestTakerEmail(profile),
            confirmTestTakerEmail: this.validateConfirmTestTakerEmail(profile),
        }

        let hasErrors: boolean = false
        Object.keys(formErrors).forEach((key: string) => {
            const value: string[] = formErrors[key]
            if (value && value.length > 0) {
                hasErrors = true
            }
        })

        return {
            hasErrors,
            formErrors
        }
    }
    handleSubmit = () => {
        this.setState({
            formError: null,
            formErrors: {
                firstName: [],
                lastName: [],
                testTakerId: [],
                confirmTestTakerId: [],
                grade: [],
                firstLanguage: [],
                otherFirstLanguage: [],
                familySpeaksTestLanguage: [],
                howOftenLanguageIsSpoken: [],
                yearsStudyingOrSpoken: [],
                studiedAbroad: [],
                monthsAbroad: [],
                yearsAbroad: [],
                testTakerEmail: [],
                confirmTestTakerEmail: [],
            }
        })
        if (this.props.take === null) {
            this.setState({
                error: SUPPORT_MESSAGE
            })
            return
        }
        const errors = this.validate(this.state.profile)
        if (errors.hasErrors) {
            this.setState({
                formError: "There are errors on the form.",
                formErrors: errors.formErrors
            })
        } else {
            const profile = this.state.profile

            if (moment(this.state.profile.dateOfBirth).isSameOrAfter(moment())) {
                profile.dateOfBirth = undefined
            }
            ApiService
                .postProfile(
                    new TakeId(this.props.take.id),
                    profile,
                    productStore.driver!!.config.APP_PRODUCT_NAME.toLowerCase()
                )
                .then(() => {
                    this.props.profileSuccessDispatch(this.state.profile)
                    this.props.history.push("/dashboard")
                })
                .catch(() => {
                    this.setState({
                        error: SUPPORT_MESSAGE
                    })
                })
        }
    }
    renderGeneralInformation = (): JSX.Element => {
        const resumeText =
            "Fill in the Test Code, Password, and Name fields with the EXACT Test Code, " +
            "Password and Name you used to log into the test previously."
        const loginNameText =
            "* Remember to type in your name as it appears above if you need to log in again and resume the test."
        return (
            <div style={{ paddingLeft: theme.spacing(2) }} id="content">
                <Row>
                    <Col>
                        <FormLabel>If you need to resume the test:</FormLabel>
                        <ol>
                            <li>Go back to the Login Page.</li>
                            <li>{resumeText}</li>
                        </ol>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div tabIndex={0}>
                            <FormLabel style={{ display: "inline-block" }}>
                                You entered the following Login Name:
                            </FormLabel>
                            <p data-tst-id="entered-take-code" style={{ display: "inline-block" }}>
                                {"\u00A0"} {this.props.takeCode}
                            </p>
                        </div>
                        <p>{loginNameText}</p>
                    </Col>
                </Row>
            </div>
        )
    }
    renderRequiredInformation = (): JSX.Element => {
        //Testing to see if proctorType exists and that it is NOT blank
        const isProctored: boolean = productStore && productStore.loginProduct && productStore.loginProduct.proctorType
            ? !/^\s*$/.test(productStore.loginProduct.proctorType)
            : false
        return (
            <Row>
                <Col sm={6}>
                    <FormGroup>
                        <FormLabel htmlFor="fname">First Name*</FormLabel>
                        <div
                            className={
                                this.state.formError && this.state.formErrors.firstName.length > 0
                                    ? "error"
                                    : "error not-visible"
                            }
                        >
                            {this.state.formErrors.firstName.join(" ") || ""}
                        </div>
                        <FormControl
                            type="text"
                            value={this.state.profile.firstName}
                            placeholder=""
                            data-tst-id="first-name"
                            onChange={this.handleFirstNameChange}
                            readOnly={this.isReadOnly}
                            id="fname"
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="lname">Last Name*</FormLabel>
                        <div
                            className={
                                this.state.formError && this.state.formErrors.lastName.length > 0
                                    ? "error"
                                    : "error not-visible"
                            }
                        >
                            {this.state.formErrors.lastName.join(" ") || ""}
                        </div>
                        <FormControl
                            type="text"
                            value={this.state.profile.lastName}
                            placeholder=""
                            data-tst-id="last-name"
                            onChange={this.handleLastNameChange}
                            readOnly={this.isReadOnly}
                            id="lname"
                        />
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <FormLabel htmlFor="test-id">Test Taker ID*</FormLabel>
                        <div
                            className={
                                this.state.formError && this.state.formErrors.testTakerId.length > 0
                                    ? "error"
                                    : "error not-visible"
                            }
                        >
                            {this.state.formErrors.testTakerId.join(" ") || ""}
                        </div>
                        <FormControl
                            type="text"
                            value={this.state.profile.testTakerId}
                            placeholder=""
                            data-tst-id="student-id"
                            onChange={this.handleTestTakerIdChange}
                            readOnly={(productStore.loginProduct && !isProctored) && this.isReadOnly}
                            id="test-id"
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="taker-id">Confirm Test Taker ID*</FormLabel>
                        <div
                            className={
                                this.state.formError && this.state.formErrors.confirmTestTakerId.length > 0
                                    ? "error"
                                    : "error not-visible"
                            }
                        >
                            {this.state.formErrors.confirmTestTakerId.join(" ") || ""}
                        </div>
                        <FormControl
                            type="text"
                            value={this.state.confirmTestTakerId}
                            placeholder=""
                            data-tst-id="confirm-student-id"
                            onChange={this.handleConfirmTestTakerIdChange}
                            readOnly={(productStore.loginProduct && productStore.loginProduct.proctorType !== ProctorTypes.PROCTORTRACK.valueOf()) && this.isReadOnly}
                            id="taker-id"
                        />
                    </FormGroup>
                </Col>
            </Row>
        )
    }
    renderOptionalInformation = (): JSX.Element => {
        return (
            <Row>
                <Col sm={6}>
                    <FormGroup>
                        <FormLabel>Grade</FormLabel>
                        <FormControl
                            as="select"
                            placeholder="select"
                            data-tst-id="grade"
                            onChange={this.handleGradeChange}
                            value={this.state.profile.grade}
                            id="grade"
                            readOnly={this.isReadOnly && this.state.profile.grade != null}
                            disabled={this.isReadOnly && this.state.profile.grade != null}
                        >
                            {this.grades.map((grade: string) => {
                                return (
                                    <option key={grade} value={grade}>
                                        {grade}
                                    </option>
                                )
                            })}
                        </FormControl>
                    </FormGroup>
                </Col>

                <Col xs={6}>
                    <FormGroup>
                        <div
                            className={
                                this.state.formError && this.state.formErrors.testTakerEmail.length > 0
                                    ? "error"
                                    : "error not-visible"
                            }
                        >
                            {this.state.formErrors.testTakerEmail.join(" ") || ""}
                        </div>
                        <FormLabel>
                            Email: NOT REQUIRED. Complete only if instructed to do so by proctor/teacher
                        </FormLabel>
                        <FormControl
                            type="text"
                            value={this.state.profile.testTakerEmail}
                            placeholder=""
                            data-tst-id="email"
                            onChange={this.handleEmailChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <div
                            className={
                                this.state.formError && this.state.formErrors.confirmTestTakerEmail.length > 0
                                    ? "error"
                                    : "error not-visible"
                            }
                        >
                            {this.state.formErrors.confirmTestTakerEmail.join(" ") || ""}
                        </div>
                        <FormLabel>
                            Confirm Email
                        </FormLabel>
                        <FormControl
                            type="text"
                            value={this.state.confirmTestTakerEmail}
                            placeholder=""
                            data-tst-id="email"
                            onChange={this.handleConfirmEmailChange}
                        />
                    </FormGroup>
                </Col>
            </Row>
        )
    }
    render() {
        return (
            <div className="profile-form-container">
                {this.state.error && (
                    <div className="alerts">
                        <Alert variant="danger" onClose={() => this.setState({ error: false })}>
                            {this.state.error}
                        </Alert>
                    </div>
                )}
                <Container>
                    <Row>
                        <Col sm={12}>
                            {this.state.take && (
                                <Card>
                                    <div className="profile-form content-container">
                                        <H4Text style={{ color: "#000" }}>
                                            CREATE PROFILE
                                        </H4Text>
                                        <p style={{ fontWeight: "bold", marginTop: "8px" }}>*Required</p>
                                        {(authStore.auth && !authStore.auth.ssoId) && this.renderGeneralInformation()}

                                        {this.renderRequiredInformation()}
                                        {this.renderOptionalInformation()}

                                        <Row>
                                            <Col
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                                className="margin-top"
                                            >
                                                <Button
                                                    className="avant-button--default avant-button--primary"
                                                    testId="submit"
                                                    onClick={this.handleSubmit}
                                                >
                                                    SUBMIT
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Stamp4SProfileFormContainer)
