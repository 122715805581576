import moment from "moment"
import {ApiTakePanel} from "../app/common/take-panel/models/ApiTakePanel"
import {Section} from "../app/section/models/Section"
import {sectionStore} from "../app/section/SectionStore"
import AdminHelper from "../components/admin-portal/AdminHelper"
import AdvanceHelper from "../components/advance/AdvanceHelper"
import {
    ApiAptProfile,
    ApiGroup,
    ApiPanelGraph,
    ApiPlaceProfile,
    ApiReadyProfile,
    ApiTake,
    ApiTakePanelStateEnum,
    JeenieProfile,
    LastPage,
    LoginProductContentAreaData,
    PanelSkillEnum,
    Profile,
    Stamp4SProfile,
    Take,
    TakePanel,
    TestState,
    TestStatusEnum
} from "../types/types"
import {to} from "../util/elvis"
import {TakeCode} from "../validation/TakeCode"
import {PanelGraphId, PanelId, TakeId} from "../validation/ValidPrimaryKey"
import ApiService from "./ApiService"

interface GenericHelperConfig {
    type: HelperType
    finishTestLocation: string
    profileLocation: string
    testStateProfileStatus: TestStatusEnum
    dashboard: string
    profileView: string
    hasDashboard: boolean
}

enum HelperType {
    STAMP_4S,
    STAMP_4SE,

    // Place type includes World Speak
    PLACE,
    WORLD_SPEAK,
    READY,
    JEENIE,
    APT,
}

const stamp4sConfig: GenericHelperConfig = {
    type: HelperType.STAMP_4S,
    finishTestLocation: "/stamp-4s-finish-test",
    profileLocation: "/stamp-4s-profile",
    testStateProfileStatus: TestStatusEnum.DASHBOARD,
    dashboard: "/dashboard",
    profileView: "/dashboard",
    hasDashboard: true

}

const stamp4seConfig: GenericHelperConfig = {
    type: HelperType.STAMP_4SE,
    finishTestLocation: "/stamp-4se-finish-test",
    profileLocation: "/stamp-4se-profile",
    testStateProfileStatus: TestStatusEnum.PROFILE_VIEW,
    dashboard: "/dashboard",
    profileView: "/dashboard",
    hasDashboard: true
}

const aptConfig: GenericHelperConfig = {
    type: HelperType.APT,
    testStateProfileStatus: TestStatusEnum.PROFILE_VIEW,
    hasDashboard: true,
    profileLocation: "/apt-profile",
    dashboard: "/dashboard",
    profileView: "/dashboard",
    finishTestLocation: "/apt-finish-test"
}

const placeConfig: GenericHelperConfig = {
    type: HelperType.PLACE,
    finishTestLocation: "/place-finish-test",
    profileLocation: "/place-profile",
    testStateProfileStatus: TestStatusEnum.PROFILE_VIEW,
    dashboard: "/auto-start-dashboard",
    profileView: "/auto-start-dashboard",
    hasDashboard: false
}

const readyConfig: GenericHelperConfig = {
    type: HelperType.READY,
    testStateProfileStatus: TestStatusEnum.PROFILE_VIEW,
    hasDashboard: false,
    profileLocation: "/ready-profile",
    finishTestLocation: "/ready-finish-test",
    dashboard: "/auto-start-dashboard",
    profileView: "/auto-start-dashboard"
}

const worldSpeakConfig: GenericHelperConfig = {
    type: HelperType.WORLD_SPEAK,
    finishTestLocation: "/place-finish-test",
    profileLocation: "/worldspeak-profile",
    testStateProfileStatus: TestStatusEnum.PROFILE_VIEW,
    dashboard: "/auto-start-dashboard",
    profileView: "/auto-start-dashboard",
    hasDashboard: false
}

const jeenieConfig: GenericHelperConfig = {
    type: HelperType.JEENIE,
    testStateProfileStatus: TestStatusEnum.PROFILE_VIEW,
    finishTestLocation: "",
    profileLocation: "/jeenie-profile",
    dashboard: "/dashboard",
    profileView: "/jeenie-profile",
    hasDashboard: false
}

export interface GenericHelperInterface {
    refreshTest(props: any, lastPage: LastPage, loginData: LoginProductContentAreaData): Promise<TestState>

    refreshTestCallback(testState: TestState, props: any): void
}

export class GenericHelper implements GenericHelperInterface {

    static stamp4sHelper = new GenericHelper(stamp4sConfig)
    static stamp4seHelper = new GenericHelper(stamp4seConfig)
    static worldSpeakHelper = new GenericHelper(worldSpeakConfig)
    static jeenieHelper = new GenericHelper(jeenieConfig)
    static placeHelper = new GenericHelper(placeConfig)
    static aptHelper = new GenericHelper(aptConfig)
    static readyHelper = new GenericHelper(readyConfig)

    // Very different / nongeneric helpers
    static advanceHelper = new AdvanceHelper()
    static adminHelper = new AdminHelper()

    private constructor(private config: GenericHelperConfig) {

    }

    processTake = (apiTake: ApiTake): Take => {
        const takePanels: TakePanel[] = apiTake.takePanels.map((apiTakePanel: ApiTakePanel) => {
            return {
                benchmark: apiTakePanel.benchmark,
                level: apiTakePanel.level,
                currentSection: apiTakePanel.currentSection,
                numSections: apiTakePanel.numSections,
                timeRemaining: apiTakePanel.timeRemaining,
                timeUsed: apiTakePanel.timeUsed,
                panel: apiTakePanel.panel,
                panelId: apiTakePanel.panel.id,
                panelState: apiTakePanel.panelState,
                responses: apiTakePanel.responses,
                startTime: apiTakePanel.startTime ? apiTakePanel.startTime : null,
                finishTime: apiTakePanel.finishTime ? apiTakePanel.finishTime : null
            }
        })

        let schoolName: string = ""
        let testGroupName: string = ""
        apiTake.groups.forEach((group: ApiGroup) => {
            if (group.groupType === PanelSkillEnum.SENTENCE_COMPLETION) {
                schoolName = group.name
            }
            if (group.groupType === "CL") {
                testGroupName = group.name
            }
        })

        switch (this.config.type) {
            case HelperType.STAMP_4S:
            case HelperType.STAMP_4SE:
                return this.createTakeStampTests(schoolName, testGroupName, apiTake, takePanels)

            case HelperType.PLACE:
            case HelperType.WORLD_SPEAK:
                return this.createTakePlaceWorldSpeak(schoolName, testGroupName, apiTake, takePanels)

            case HelperType.JEENIE:
                return this.createTakeJeenieTests(apiTake, takePanels)

            case HelperType.APT:
                return this.createTakeProfileApt(schoolName, testGroupName, apiTake, takePanels)

            case HelperType.READY:
                return this.createTakeProfileReady(schoolName, testGroupName, apiTake, takePanels)

            default:
                throw new Error(`No return specified for ${this.config.type}`)
        }
    }

    getTestState = (take: Take, loginData: LoginProductContentAreaData): TestState => {
        const availablePanels = take.availablePanels
        const takePanels = take.takePanels
        let testStatus: TestStatusEnum = TestStatusEnum.DASHBOARD
        let panelId: number | undefined

        if (take.profile == null) {
            throw Error("[AptHelper.getTestState] take.profile is null or undefined")
        }

        const isSelfEvalHelperType = [HelperType.PLACE, HelperType.WORLD_SPEAK, HelperType.READY].indexOf(this.config.type) !== -1

        if (
            (loginData.rostered && take.profile.completed !== "true") ||
            take.profile.firstName === "" ||
            take.profile.firstName === undefined
        ) {
            testStatus = TestStatusEnum.PROFILE
        } else if (
            isSelfEvalHelperType
            && take.profile
            && take.profile.selfEvaluationIndex != null
            && take.profile.selfEvaluationIndex !== -1) {
            testStatus = TestStatusEnum.SELF_EVALUATION
        } else if (take.profile) {
            testStatus = this.config.testStateProfileStatus
        } else if (availablePanels.length === 0) {
            testStatus = TestStatusEnum.FINISH_TEST
        } else {
            takePanels.forEach((takePanel: TakePanel) => {
                if (takePanel.panelState === ApiTakePanelStateEnum.STARTED) {
                    testStatus = TestStatusEnum.CONTINUE_PANEL
                    panelId = takePanel.panelId
                }
            })
        }

        // Only if it doesn't have a dashboard
        if (!this.config.hasDashboard && panelId == null && take.availablePanels.length > 0) {
            panelId = take.availablePanels[0].id
        }

        return {
            testStatus,
            take,
            panelId
        }
    }

    refreshTest = async (
        props: any,
        lastPage: LastPage,
        loginData: LoginProductContentAreaData
    ): Promise<TestState> => {
        props.setTakeCodeDispatch(lastPage.takeCode)
        const panelGraphs: ApiPanelGraph[] = await ApiService.fetchPanelGraphs()
        props.setPanelGraphDispatch(panelGraphs[0])
        const takeCode = to<string>(
            lastPage.takeCode,
            Error(`[Helper.refreshTest] Expecting a takeCode on lastPage.`)
        )
        const apiTake: ApiTake = await ApiService.fetchTake(
            new TakeCode(takeCode),
            new PanelGraphId(panelGraphs[0].id),
            loginData.loginId
        )
        const take: Take = this.processTake(apiTake)
        if (this.config.hasDashboard) {
            take.availablePanels.sort((a, b) => {
                if (a.skill === PanelSkillEnum.LISTENING || a.skill === PanelSkillEnum.SPEAKING) {
                    return 1
                }
                if (a.skill === PanelSkillEnum.READING || a.skill === PanelSkillEnum.WRITING) {
                    return -1
                }
                return 0
            })
        }

        const testState: TestState = this.getTestState(take, loginData)

        if (testState.panelId !== undefined) {
            take.currentPanelId = testState.panelId
        } else if (!this.config.hasDashboard && take.availablePanels.length > 0) {
            take.currentPanelId = take.availablePanels[0].id
        }

        props.updateTakeDispatch(take)
        props.setTestStateDispatch(testState)

        return testState
    }

    refreshTestCallback = (testState: TestState, props: any) => {
        const localTestState: TestState = testState
        switch (localTestState.testStatus) {
            case TestStatusEnum.PROFILE:
                props.history.push(this.config.profileLocation)
                break
            case TestStatusEnum.SELF_EVALUATION:
                props.history.push("/self-evaluation")
                break
            case TestStatusEnum.PROFILE_VIEW:
                props.history.push(this.config.profileView)
                break
            case TestStatusEnum.DASHBOARD:
                props.history.push(this.config.dashboard)
                break
            case TestStatusEnum.TEST:
                props.history.push("/jeenie-test")
                break
            case TestStatusEnum.CONTINUE_PANEL:
                if (localTestState.take == null) {
                    throw Error("[AptHelper.refreshTestCallback] localTestState.take is null or undefined")
                }
                sectionStore.findCurrentSection(new TakeId(localTestState.take.id), new PanelId(localTestState.panelId!))
                    .then((section: Section) => {
                        section.config = props.driver.config.TEST_ENGINE_CONFIG
                        props.updateSectionDispatch(section)
                        props.history.push("/item")
                    })
                break
            case TestStatusEnum.FINISH_TEST:
                props.history.push(this.config.finishTestLocation)
                break
            default:
                throw new Error("Default Case Reached in panel stuff")
        }
    }

    private createTakeProfileApt = (schoolName: string, testGroupName: string, apiTake: ApiTake, takePanels: TakePanel[]) => {
        let firstName: string = ""
        let lastName: string = ""
        let completed: string = ""

        const apiProfile: ApiAptProfile | null = apiTake.profile as ApiAptProfile
        if (apiProfile) {
            firstName = apiProfile.firstName
            lastName = apiProfile.lastName
            completed = apiProfile.completed
        }

        const profile: Profile = {
            firstName,
            lastName,
            schoolName,
            testGroupName,
            completed
        }

        return {
            id: apiTake.id,
            takeCode: apiTake.code,
            currentPanelId: null,
            takePanels,
            availablePanels: apiTake.availablePanels,
            profile,
            created: apiTake.created
        }
    }

    private createTakeStampTests = (schoolName: string, testGroupName: string, apiTake: ApiTake, takePanels: TakePanel[]) => {
        let firstName: string = ""
        let lastName: string = ""
        let testTakerId: string = ""
        let dateOfBirth: string = moment().toISOString()
        let completed: string = ""
        let grade: string | undefined  = undefined

        const apiProfile: Stamp4SProfile | null = apiTake.profile as Stamp4SProfile

        if (apiProfile) {
            firstName = apiProfile.firstName
            lastName = apiProfile.lastName
            testTakerId = apiProfile.testTakerId
                ? apiProfile.testTakerId
                : apiProfile.yourname
                    ? apiProfile.yourname
                    : ""
            completed = apiProfile.completed
            grade = apiProfile.grade != null ? apiProfile.grade : undefined

            // TODO test this for 4S and 4Se
            if (apiProfile.dayOfBirth && apiProfile.monthOfBirth && apiProfile.yearOfBirth) {
                dateOfBirth = `${apiProfile.dayOfBirth}/${apiProfile.monthOfBirth}/${apiProfile.yearOfBirth}`
            }
        }

        const profile: Profile = {
            firstName,
            lastName,
            testTakerId,
            schoolName,
            testGroupName,
            dateOfBirth,
            completed,
            grade
        }

        return {
            id: apiTake.id,
            takeCode: apiTake.code,
            currentPanelId: null,
            takePanels,
            availablePanels: apiTake.availablePanels,
            profile,
            created: apiTake.created
        }
    }

    private createTakeJeenieTests = (apiTake: ApiTake, takePanels: TakePanel[]) => {
        let firstName: string = ""
        let lastName: string = ""
        let testTakerEmail: string = ""
        let testTakerPhone: string = ""
        let completed: string = ""

        const apiProfile: JeenieProfile | null = apiTake.profile as JeenieProfile

        if (apiProfile) {
            firstName = apiProfile.firstName
            lastName = apiProfile.lastName
            testTakerEmail = apiProfile.testTakerEmail
            testTakerPhone = apiProfile.testTakerPhone
            completed = apiProfile.completed
        }

        const profile: Profile = {
            firstName,
            lastName,
            testTakerEmail,
            testTakerPhone,
            completed
        }

        return {
            id: apiTake.id,
            takeCode: apiTake.code,
            currentPanelId: null,
            takePanels,
            availablePanels: apiTake.availablePanels,
            profile,
            created: apiTake.created
        }
    }

    private createTakePlaceWorldSpeak = (schoolName: string, testGroupName: string, apiTake: ApiTake, takePanels: TakePanel[]) => {
        let firstName: string = ""
        let lastName: string = ""
        let testTakerId: string = ""
        let testTakerEmail: string = ""
        let yearsStudyingOrSpoken: string = ""
        let gradesStudiedIn: string[] = []
        const testsTaken: string[][] = []
        let familySpeaksOtherLanguage: boolean | undefined
        let familyLanguage: number = -1 // the top language in the profile language list
        let familyMembers: string[] = []
        let otherFamilyMember: string = ""
        let languageConfidence: string = ""
        let contentAreaId: number = 0
        const userId: number = -1
        const takeCode: string = apiTake.code
        let selfEvaluationIndex: number = 0
        let completed: string = ""

        const apiProfile: ApiPlaceProfile | null = apiTake.profile as ApiPlaceProfile

        if (apiProfile) {
            firstName = apiProfile.firstname
            lastName = apiProfile.lastname

            if (apiProfile.testTakerId) {
                testTakerId = apiProfile.testTakerId
            } else if (apiProfile.yourname) {
                testTakerId = apiProfile.yourname
            } else {
                testTakerId = ""
            }

            testTakerEmail = apiProfile.testTakerEmail

            completed = (
                apiProfile.cando_1
                && apiProfile.cando_2
                && apiProfile.cando_3
                && apiProfile.cando_4
                && apiProfile.cando_5
                && apiProfile.testsheetlevel
            )
                ? "true"
                : "false"

            yearsStudyingOrSpoken = apiProfile.years

            if (apiProfile.grades) {
                gradesStudiedIn = apiProfile.grades.split(",")
            }

            if (apiProfile.test_ap) {
                testsTaken.push(["test_ap", apiProfile.test_ap])
            }
            if (apiProfile.test_ib) {
                testsTaken.push(["test_ib", apiProfile.test_ib])
            }
            if (apiProfile.test_satii) {
                testsTaken.push(["test_satii", apiProfile.test_satii])
            }
            if (apiProfile.test_stamp) {
                testsTaken.push(["test_stamp", apiProfile.test_stamp])
            }
            if (apiProfile.test_opi) {
                testsTaken.push(["test_opi", apiProfile.test_opi])
            }
            if (apiProfile.test_wpt) {
                testsTaken.push(["test_wpt", apiProfile.test_wpt])
            }
            if (apiProfile.test_opic) {
                testsTaken.push(["test_opic", apiProfile.test_opic])
            }
            if (apiProfile.foreignlang_athome === "yes") {
                familySpeaksOtherLanguage = true
            }
            if (apiProfile.familylanguageid) {
                familyLanguage = parseInt(apiProfile.familylanguageid, 10)
            }
            if (apiProfile.familymembers) {
                familyMembers = apiProfile.familymembers.split(",")
            }
            if (apiProfile.familymembers_other) {
                otherFamilyMember = apiProfile.familymembers_other
            }

            languageConfidence = apiProfile.scale
            contentAreaId = parseInt(apiProfile.contentareaid, 10)

            selfEvaluationIndex = this.calculateSelfEvaluationIndex(apiProfile)
        }

        const profile: Profile = {
            firstName,
            lastName,
            schoolName,
            testGroupName,
            testTakerId,
            testTakerEmail,
            yearsStudyingOrSpoken,
            gradesStudiedIn,
            testsTaken,
            familySpeaksOtherLanguage,
            familyLanguage,
            familyMembers,
            otherFamilyMember,
            languageConfidence,
            contentAreaId,
            userId,
            takeCode,
            selfEvaluationIndex,
            completed
        }

        return {
            id: apiTake.id,
            takeCode: apiTake.code,
            currentPanelId: null,
            takePanels,
            availablePanels: apiTake.availablePanels,
            profile,
            created: apiTake.created
        }
    }

    private createTakeProfileReady = (schoolName: string, testGroupName: string, apiTake: ApiTake, takePanels: TakePanel[]) => {
        let firstName: string = ""
        let lastName: string = ""
        let phone: string = ""
        let email: string = ""
        let selfEvaluationIndex: number = 0
        let completed: string = ""

        const apiProfile: ApiReadyProfile | null = apiTake.profile as ApiReadyProfile
        if (apiProfile) {
            firstName = apiProfile.firstName
            lastName = apiProfile.lastName
            phone = apiProfile.phone
            email = apiProfile.email
            completed = apiProfile.completed
            selfEvaluationIndex = this.calculateSelfEvaluationIndex(apiProfile)
        }

        const profile: Profile = {
            firstName,
            lastName,
            phone,
            email,
            selfEvaluationIndex,
            completed
        }

        return {
            id: apiTake.id,
            takeCode: apiTake.code,
            currentPanelId: null,
            takePanels,
            availablePanels: apiTake.availablePanels,
            profile,
            created: apiTake.created
        }
    }

    private calculateSelfEvaluationIndex(profile: { cando_1?: string, cando_2?: string, cando_3?: string, cando_4?: string, cando_5?: string }): number {
        if (profile.cando_5) {
            return -1
        }
        if (profile.cando_4) {
            return 4
        }
        if (profile.cando_3) {
            return 3
        }
        if (profile.cando_2) {
            return 2
        }
        if (profile.cando_1) {
            return 1
        }
        return 0

    }
}
