import {FormControl, MenuItem} from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import React from "react"
import {ItemFormatEnum} from "../../../types/types"

interface IComponentProps {
    onItemFormatChanged: (selectedItemFormat: ItemFormatEnum) => void
    currentItemFormat?: ItemFormatEnum
    disabled?: boolean
}

interface IComponentFormat {
    currentItemFormat?: ItemFormatEnum
    errors: string[]
}

export default class ItemFormatSelectDropDown extends React.Component<IComponentProps, IComponentFormat> {
    constructor(props: IComponentProps) {
        super(props)

        this.state = {
            currentItemFormat: props.currentItemFormat === null ? undefined : props.currentItemFormat,
            errors: []
        }
    }

    componentDidUpdate(prevProps: IComponentProps) {
        if (this.props.currentItemFormat !== prevProps.currentItemFormat) {
            this.setState({
                currentItemFormat: this.props.currentItemFormat === null ? undefined : this.props.currentItemFormat
            })
        }
    }

    handleChange = (event: any) => {
        const currentFormat = event.target.value

        this.setState({
            currentItemFormat: currentFormat
        })
        this.props.onItemFormatChanged(currentFormat)
    }

    render() {
        // get list of item formats from enum by iterating the enums and getting their values
        const selectableFormats: string[] = Object.keys(ItemFormatEnum).map((k) => ItemFormatEnum[k])
        const errors = this.state.errors
        // TextField doesn't like receiving a value of `undefined`, so we pass an empty string instead.
        const currentFormat = this.state.currentItemFormat ? this.state.currentItemFormat : ""
        return (
            <FormControl>
                <div className="error" hidden={errors.length === 0}>
                    {errors.join(" ") || "placeholder"}
                </div>
                <TextField
                    id={"format-select-id"}
                    select={true}
                    onChange={this.handleChange}
                    value={currentFormat}
                    style={{width: 200}}
                    helperText={"Item Format"}
                    disabled={this.props.disabled}
                >
                    <MenuItem value={""}></MenuItem>
                    {selectableFormats.map((format) => {
                        return (
                            <MenuItem key={format} value={format} id={`format-${format.replace(/\s/g, "")}`}>
                                {format}
                            </MenuItem>
                        )
                    })}
                </TextField>
            </FormControl>
        )
    }
}
