/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import React from "react"
import {AvantExpansionPanel} from "../../../generic-components/AvantExpansionPanel"
import {AccordionDetails, Box, Grid} from "@material-ui/core"
import {DateRangeHeaderText} from "../../../reports/common/components/report-page/DateRangeHeaderText"
import {ReportProps} from "../../ReportData"
import {ReportSummaryGraph, SummarySkillGraph} from "../../../reports/district-school/models/ReportSummaryGraphs"
import {theme} from "../../../../styles/MuiThemes"
import {SkillAverageChart} from "../../../reports/common/components/aggregate-charts/SkillAverageChart"
import {GraphHelper} from "../../../reports/common/components/report-page/GraphHelper"
import {LanguageBar} from "../../../reports/district-school/components/DistrictOrSchoolReportPage";
import {DistributionChart} from "../../../reports/common/components/aggregate-charts/DistributionChart"
import {PrintOptions} from "../../../reports/common/components/PrintViewContainer"

const SummaryCharts = ({printOptions, isMultiLanguage, filters, graphData}: ReportProps) => {
    const {startDate, endDate} = filters

    if (printOptions === PrintOptions.LIST) return <></>

    return (
        <AvantExpansionPanel
            style={{breakAfter: "page"}}
            expanded={printOptions != null ? true : undefined}
            title={"Summary"}
        >
            <AccordionDetails
                style={{
                    display: "inherit",
                    padding: printOptions != null ? 4 : undefined,
                    width: "100%",
                }}
            >
                {
                    !isMultiLanguage &&
                    <>
                        <DateRangeHeaderText header={"Testing Group Averages"} from={startDate} to={endDate} />
                        <SkillAverageChart averages={graphData!.testingGroupAverages!} />
                    </>
                }
                <Box alignItems={"center"} display={"flex"}>
                    <DateRangeHeaderText header={"Distribution of Test Takers Across Levels"} from={startDate} to={endDate} />
                    <Box flexGrow={1} />
                    <GraphHelper/>
                    {/*Graph Helper*/}
                </Box>
                {graphData && graphData.graphs.length < 1 && (
                    <Box>
                        No data to display.  Summary graphs will appear when a test taker has completed a
                        test section and is displayed in the Test Instances List.
                    </Box>
                )}
                {graphData && graphData.graphs.map((languageSummaryData: ReportSummaryGraph) => {
                    return (
                        <Box
                            className={"distribution-graphs"}
                            key={languageSummaryData.language}
                            style={{breakInside: "avoid"}}
                        >
                            {
                                isMultiLanguage &&
                                <LanguageBar language={languageSummaryData.language}/>
                            }
                            <Grid
                                container
                                justify={"center"}
                                style={{marginTop: theme.spacing(1), width: "100%"}}
                            >
                                {languageSummaryData.summarySkillGraph.map((skillGraph: SummarySkillGraph) => (
                                    <DistributionChart skillData={skillGraph} productId={filters.productId} key={skillGraph.skillName}/>
                                ))}
                            </Grid>
                        </Box>
                    )
                })}
            </AccordionDetails>
        </AvantExpansionPanel>
    )
}

export default SummaryCharts
