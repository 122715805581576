import * as React from "react"
import {ButtonProps} from "@material-ui/core/Button"
import {Button} from "@material-ui/core"

export const PrintButtonsContainer: React.FC<{}> = ({children}) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px auto"
            }}
        >
            {children}
        </div>
    )
}

export class PrintViewButton extends React.Component<ButtonProps> {
    render() {
        return (
            <Button
                {...this.props}
                style={{margin: "0 10px"}}
                variant={"contained"}
                color={"primary"}
            >
                {this.props.children}
            </Button>
        )
    }
}
