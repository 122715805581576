import React from "react"
import {Brand} from "../../../../components/Brand/Brand"
import {productStore} from "../../../common/products/ProductStore"

export const ReportPrintBrand = () => {
    const product = productStore.loginProduct
    const productName = (product) ? product.productName : ""

    return (
        <div
            style={{
                position: "fixed",
                top: 8,
                right: 57.5
            }}
        >
            <Brand appName={productName}/>
        </div>
    )
}
