import {createStyles, makeStyles} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import React from "react"
import {SmallBodyText} from "../../styles/AvantTypography"
import {theme} from "../../styles/MuiThemes"
import {StatusSymbols} from "../../util/Constants"

export enum StatusColors {
    SECTION_NOT_RATABLE_FILL = "#FFE2DB",
    SECTION_NOT_RATABLE_COLOR = "#D0021B",
    SECTION_NOT_STARTED_FILL = "#EAEAEA",
    SECTION_NOT_STARTED_COLOR = "#767676",
    SECTION_IN_PROGRESS_FILL = "#D0EDDD",
    SECTION_IN_PROGRESS_COLOR = "#156238",
    SCORING_IN_PROGRESS_FILL = "#FFC6FA",
    SCORING_IN_PROGRESS_COLOR = "#88157D",
    SCORED_BY_EDUCATOR_FILL = "#EAEAEA",
    SCORED_BY_EDUCATOR_COLOR = "#767676",
    NOT_AVAILABLE_FILL = "#FFFFFF",
    NOT_AVAILABLE_COLOR = "#585858",
}

const useBadgeStyles = makeStyles(
    createStyles({
        root: {
            color: (props: StatusButtonProps) => props.textcolor,
            backgroundColor: (props: StatusButtonProps) => props.fillcolor,
            height: 20,
            width: 25,
            fontSize: "1.25rem",
            fontWeight: "bold",
            borderRadius: "3px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 2
        },
        button: {
            "&:hover": {
                backgroundColor: (props: StatusButtonProps) => props.textcolor,
                color: (props: StatusButtonProps) => props.fillcolor,
            },
        },
        largeText: {
            color: (props: StatusButtonProps) => props.textcolor,
            backgroundColor: (props: StatusButtonProps) => props.fillcolor,
            height: 20,
            width: 25,
            fontSize: "1.75rem",
            fontWeight: "bold",
            borderRadius: "3px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 2
        },
    })
)

interface StatusButtonProps {
    textcolor: StatusColors
    fillcolor: StatusColors
    children: StatusSymbols
    text?: string
    style?: React.CSSProperties
}

const AvantBadge: React.FC<StatusButtonProps> = ({textcolor, fillcolor, children, text, style}) => {
    const classes = useBadgeStyles({textcolor, fillcolor} as StatusButtonProps)
    return (
        <div style={{display: "flex", alignItems: "center", ...style}}>
            <span className={`${classes.root}`}>{children}</span>
            {text && (
                <SmallBodyText style={{marginLeft: theme.spacing(1)}}>{text}</SmallBodyText>
            )}
        </div>
    )
}

interface AvantBadgeProps {
    text?: string
    style?: React.CSSProperties
}

export const AvantBadgeNotRatable = (props: AvantBadgeProps) => {
    return (
        <AvantBadge
            textcolor={StatusColors.SECTION_NOT_RATABLE_COLOR}
            fillcolor={StatusColors.SECTION_NOT_RATABLE_FILL}
            text={props.text}
            style={props.style}
        >
            {StatusSymbols.SECTION_NOT_RATABLE}
        </AvantBadge>
    )
}
export const AvantBadgeNotStarted = (props: AvantBadgeProps) => {
    return (
        <AvantBadge
            textcolor={StatusColors.SECTION_NOT_STARTED_COLOR}
            fillcolor={StatusColors.SECTION_NOT_STARTED_FILL}
            text={props.text}
            style={props.style}
        >
            {StatusSymbols.SECTION_NOT_STARTED}
        </AvantBadge>
    )
}
export const AvantBadgeSectionInProgress = (props: AvantBadgeProps) => {
    return (
        <AvantBadge
            textcolor={StatusColors.SECTION_IN_PROGRESS_COLOR}
            fillcolor={StatusColors.SECTION_IN_PROGRESS_FILL}
            text={props.text}
            style={props.style}
        >
            {StatusSymbols.SECTION_IN_PROGRESS}
        </AvantBadge>
    )
}

export const AvantBadgeScoringInProgress = (props: AvantBadgeProps) => {
    return (
        <AvantBadge
            textcolor={StatusColors.SCORING_IN_PROGRESS_COLOR}
            fillcolor={StatusColors.SCORING_IN_PROGRESS_FILL}
            text={props.text}
            style={props.style}
        >
            {StatusSymbols.SCORING_IN_PROGRESS}
        </AvantBadge>
    )
}

export const AvantBadgeNotAvailable = (props: AvantBadgeProps) => {
    const classes = useBadgeStyles({textcolor: StatusColors.NOT_AVAILABLE_COLOR, fillcolor: StatusColors.NOT_AVAILABLE_FILL} as StatusButtonProps)
    return (
        <div style={{display: "flex", alignItems: "center", ...props.style}}>
            <span className={`${classes.largeText}`}>{StatusSymbols.NOT_AVAILABLE}</span>
            {props.text && (
                <SmallBodyText style={{marginLeft: theme.spacing(1)}}>{props.text}</SmallBodyText>
            )}
        </div>
    )

}


export const AvantBadgeScoredByEducator = (props: AvantBadgeProps) => {
    return (
        <AvantBadge
            textcolor={StatusColors.SCORED_BY_EDUCATOR_COLOR}
            fillcolor={StatusColors.SCORED_BY_EDUCATOR_FILL}
            text={props.text}
            style={props.style}
        >
            {StatusSymbols.SCORED_BY_EDUCATOR}
        </AvantBadge>
    )
}

export const AvantBadgeEarlyExit = (props: AvantBadgeProps) => (
    <AvantBadge
        textcolor={StatusColors.SECTION_NOT_STARTED_COLOR}
        fillcolor={StatusColors.SECTION_NOT_STARTED_FILL}
        text={props.text}
        style={props.style}
    >
        {StatusSymbols.EARLY_EXIT}
    </AvantBadge>
)

const AvantBadgeButton: React.FC<StatusButtonProps> = ({textcolor, fillcolor, children}) => {
    const classes = useBadgeStyles({textcolor, fillcolor} as StatusButtonProps)
    return (
        <Button className={`${classes.root} ${classes.button}`}>{children}</Button>
    )
}

export const AvantBadgeButtonNotRatable = () => {
    return (
        <AvantBadgeButton
            textcolor={StatusColors.SECTION_NOT_RATABLE_COLOR}
            fillcolor={StatusColors.SECTION_NOT_RATABLE_FILL}
        >
            {StatusSymbols.SECTION_NOT_RATABLE}
        </AvantBadgeButton>
    )
}
export const AvantBadgeButtonNotStarted = () => {
    return (
        <AvantBadgeButton
            textcolor={StatusColors.SECTION_NOT_STARTED_COLOR}
            fillcolor={StatusColors.SECTION_NOT_STARTED_FILL}
        >
            {StatusSymbols.SECTION_NOT_STARTED}
        </AvantBadgeButton>
    )
}
export const AvantBadgeButtonSectionInProgress = () => {
    return (
        <AvantBadgeButton
            textcolor={StatusColors.SECTION_IN_PROGRESS_COLOR}
            fillcolor={StatusColors.SECTION_IN_PROGRESS_FILL}
        >
            {StatusSymbols.SECTION_IN_PROGRESS}
        </AvantBadgeButton>
    )
}
export const AvantBadgeButtonScoringInProgress = () => {
    return (
        <AvantBadgeButton
            textcolor={StatusColors.SCORING_IN_PROGRESS_COLOR}
            fillcolor={StatusColors.SCORING_IN_PROGRESS_FILL}
        >
            {StatusSymbols.SCORING_IN_PROGRESS}
        </AvantBadgeButton>
    )
}
