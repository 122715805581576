import React from "react"
import HelperService from "../../services/HelperService"
import {IItem, InstructionPsTextContent, PsText, TEvent} from "../../types/types"

interface InstructionPassageProps {
    className: string
    itemContent: InstructionPsTextContent
    format: string
    item: IItem
    userAnswer: string | null
    handleSelectText?: (text: string) => void
    testId: string
}

export default class InstructionPassageSelect extends React.Component<InstructionPassageProps, {}> {

    selectText = (e: TEvent, text: string) => {
        if (this.props.handleSelectText) {
            this.props.handleSelectText(text)
        }
    }

    render() {
        const correctAnswers = this.props.item.correctAnswers
        return (
            <div key={this.props.itemContent.id} data-tst-id={this.props.testId} className={this.props.className}>
                {this.props.itemContent.content.map((psContent: PsText, i: number) => {
                    if (psContent.type === "ps-text" && this.props.format === "PS") {
                        const selectText = (e: TEvent) => this.selectText(e, psContent.text)
                        let className = "ps-clickable-text"
                        if (this.props.userAnswer === psContent.text) {
                            className = `${className} ps-selected`
                        }
                        const canonicalText = HelperService.canonicalizePsAnswer(psContent.text)
                        if (correctAnswers && correctAnswers.indexOf(canonicalText) > -1) {
                            className = `${className} correct-answer`
                        }

                        return React.createElement("span", {
                            key: i,
                            onClick: selectText,
                            className,
                            dangerouslySetInnerHTML: {__html: psContent.text}
                        })
                    } else {
                        return React.createElement("span", {
                            key: i,
                            dangerouslySetInnerHTML: {__html: psContent.text}
                        })
                    }
                })}
            </div>
        )
    }
}
