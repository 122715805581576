/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {observer} from "mobx-react"
import React, {useEffect, useRef, useState} from "react"
import {connect} from "react-redux"
import { withRouter,RouteComponentProps} from "react-router-dom";
import {Dispatch} from "redux"

import {authStore} from "../app/common/authentication/AuthStore"
import {RoutePaths} from "../app/routes/Routes"
import {Section} from "../app/section/models/Section"
import {userLogout} from "../redux/app/actions"
import HelperService from "../services/HelperService"
import {State, TSeconds} from "../types/types"
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core"
import {itemContainerStore} from "./ItemContainer"
import {avantHistory} from "./App/App"

interface IStateToProps {
    showAuthUserExpireModal: boolean
    section: Section | null
    timeRemaining: TSeconds
    expires: string | null
}

const mapStateToProps = (state: State): IStateToProps => {
    return {
        showAuthUserExpireModal: state.app.showAuthUserExpireModal,
        section: state.item.currentSection,
        timeRemaining: state.app.authUser.tokenTimeRemaining,
        expires: state.app.authUser.expires
    }
}

interface IDispatchToProps {
    userLogoutDispatch: () => void
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
    return {
        userLogoutDispatch: () => {
            dispatch(userLogout())
        }
    }
}

type TComponentProps = IStateToProps & IDispatchToProps & RouteComponentProps<any>

const TokenExpireModalContainer: React.FC<TComponentProps> = observer((props) => {
    const timerHandleRef = useRef<NodeJS.Timeout | null>(null)

    const ignorePaths: string[] = [
        "/",
        RoutePaths.LOGIN,
        "/reset-password",
        "/create-password",
        "/claim",
        "/register",
        "/register/confirmation",
        "/reports/redirect"
    ]
    const defaultCountDown = 60

    const [currentToken, setToken] = useState<string | null>(null)
    const [showTokenExpireModal, setModalStatus] = useState<boolean>(false)
    const [countDownSeconds, setCountDownSeconds] = useState<number>(defaultCountDown)

    useEffect(() => {
        if (authStore.auth && authStore.auth.token) {
            // If the current token hasn't been set yet OR the auth token has been refreshed
            if (authStore.auth.token !== currentToken) {
                if (shouldIgnorePath(props.location.pathname)) {
                    return
                }

                // Reset the current token
                setToken(authStore.auth.token)

                // Stop the current timer if there is one running
                clearTimeout(timerHandleRef.current!!)

                // Start a new timer
                setTimer(authStore.auth.tokenTimeRemaining, props.location.pathname)

                return
            }
        }

        if (props.showAuthUserExpireModal) {
            // This is the case where the AuthUser token has not changed but
            // PrivateRoute has set the showTokenExpireModal to true
            logout()
        }
    }, [authStore.auth, currentToken, props.location.pathname])

    useEffect(() => {
        if (showTokenExpireModal) {
            // Start the dialog countdown.
            const countDownHandle = setTimeout(() => {
                // Log the user out after 60 seconds
                logout()
            }, defaultCountDown * 1000)

            // Start the visual countdown that displays in the dialog.
            const intervalHandle = setInterval(() => {
                setCountDownSeconds(c => c - 1)
            }, 1000)

            return () => {
                clearInterval(intervalHandle)
                clearTimeout(countDownHandle)
            }
        }

        return () => setCountDownSeconds(defaultCountDown)
    }, [showTokenExpireModal])

    const setTimer = (timeRemaining: TSeconds, path: string) => {
        const timeToModal = timeRemaining - defaultCountDown

        if (timeToModal > 0) {
            // Bring up the modal a minute before the token expires
            timerHandleRef.current = setTimeout(() => {
                if(avantHistory.location.pathname.includes("item")) {
                    // Save currently selected answer or writing response in the database as not complete
                    // so the test taker can resume later with their answer saved.
                    itemContainerStore.submitAnswer(true)
                }

                setModalStatus(true)
            }, (timeToModal * 1000))
        }
    }

    const shouldIgnorePath = (path: string): boolean => {
        if (path === "/login-start-or-continue") {
            return false
        }

        return ignorePaths.indexOf(path) > -1 ||
            path.search("reset-password") > -1 ||
            path.search("create-password") > -1 ||
            path.search("test") > -1 ||
            path.search("login") > -1 ||
            path.search("login-password") > -1
    }

    const logout = (): void => {
        setModalStatus(false)
        clearTimeout(timerHandleRef.current!!)

        HelperService.simpleLogout(false)
    }

    const refresh = (): void => {
        // Refresh the token and reset the modal
        authStore.refreshToken(props.location.pathname)
        setModalStatus(false)
    }

    return (
        <Dialog
            open={showTokenExpireModal}
            maxWidth={"lg"}
        >
            <DialogTitle>
                Are you still there?
            </DialogTitle>
            <DialogContent>
                You will be logged out in {countDownSeconds} seconds due to inactivity.
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => refresh()}
                >
                    Stay logged in
                </Button>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => logout()}
                >
                    Log out
                </Button>
            </DialogActions>
        </Dialog>
    )
})

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TokenExpireModalContainer)
)
