/*
 * ******************************************************
 *  * Copyright (C) 2010-Present Avant Assessment
 *  * All Rights Reserved
 *  ******************************************************
 */

import React, {useEffect} from "react"
import {Dispatch} from "redux"
import {Header, HEADER_HEIGHT} from "../../../components/Header/Header"
import {makeStyles} from "@mui/styles"
import {Button, Theme} from "@mui/material"
import {reportsPadding} from "../../reports/common/components/ReportsPage"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import WCAssessmentButtons from "./SAMLComponents/WCAssessmentButtons"
import {H1Text, H2Text} from "../../../styles/AvantTypography"
import {loadingSpinnerStore} from "../../common/loaders/LoadingSpinnerStore"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import {authStore} from "../../common/authentication/AuthStore"
import {LastPage, LoginProductContentAreaData} from "../../../types/types"
import {CONTENT_AREA_ID_TO_LANGUAGE_NAME} from "../../../util/Constants"
import {productStore} from "../../common/products/ProductStore"
import {addTakeDispatches, TakeDispatches} from "../../../components/App/App"
import {RouteComponentProps, useHistory} from "react-router"
import {connect} from "react-redux"
import SAMLApiService from "../../../services/SAMLApiService"

const mapDispatchToProps = (dispatch: Dispatch): TakeDispatches => {
    return addTakeDispatches({}, dispatch)
}
interface SSOAuthorizedStudentProps extends RouteComponentProps<{}>, TakeDispatches {}

export interface TestInProgressOrCompleted {
    testInProgress: boolean,
    testCompleted: boolean
}

export interface SamlWelcomeCenterAssessmentList {
    customClassMapName: string,
    assessmentLanguageDisplayName: string,
    assessmentContentAreaId: number,
    productPackageCode: string,
    productPackageDisplayName: string,
    assessmentStatus: TestInProgressOrCompleted
}

export interface SamlSSOWelcomeCenterLoginDTO {
    testCode: string,
    id: number,
    allowTest: boolean,
    handwritten: boolean,
    loginexpires?: string,
    contentareaid: number,
    productId: number,
    productName: string
}

export interface SamlSSOWelcomeCenterStartTestResponse {
    groupId: number,              // group id @ class level
    name: string,               // class group name
    estTestingDate?: string,
    productName: string,        // product package
    handwritten: boolean,       // login.handwritten
    status: string,             // status of the test DISTRICT_SETUP_NEED_CLASSROOM_SETUP
    logins: SamlSSOWelcomeCenterLoginDTO,
}

export interface SamlSsoWelcomeCenterStartTestResponseBody {
    error: boolean,
    errorMessage?: string,
    data?: SamlSSOWelcomeCenterStartTestResponse
}

const useStyles = makeStyles((theme: Theme) => ({
    mainSAMLLandingPageStyle: {
        padding: reportsPadding,
        paddingTop: HEADER_HEIGHT,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "90vh",
        textAlign: "center",
    },
    accordionStyles: {
        paddingTop: theme.spacing(2),
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
    },
    redirectButtonContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        paddingTop: theme.spacing(2),
    },
}))

// @ts-ignore
interface SAMLWelcomeCenterContainerState {
    // listOfLanguages: WCAssessmentButtonsData[]
    listOfLanguagesByProductPackage: {[key: string]: SamlWelcomeCenterAssessmentList[]}
}

const SAMLWelcomeCenterContainer: React.FC<SSOAuthorizedStudentProps> = (props) => {
    // const dispatch = mapDispatchToProps
    const classes = useStyles()

    const [samlState, setSamlState] = React.useState<SAMLWelcomeCenterContainerState>({listOfLanguagesByProductPackage: {}})
    const history = useHistory()

    useEffect(() => {
        // TODO: Get available Tests For The SAML IDP
        loadingSpinnerStore.hideLoadingSpinner = false

        SAMLApiService.getSSOWelcomeCenterListSAML().then((resp: {[key: string]: SamlWelcomeCenterAssessmentList[] }) => {

            setSamlState({listOfLanguagesByProductPackage: resp})
            loadingSpinnerStore.hideLoadingSpinner = true
        }).catch((error: any) => {
            console.log(`Error: ${error}`)
            loadingSpinnerStore.hideLoadingSpinner = true
        })

    }, []);

    const assessmentSelected = (selectedContentAreaId: string, prodPackage: string, language: string, customClassMapName: string) => {
        // TODO make API call to enroll the testtaker and redirect to the test
        const contentAreaIdAsInt: number | undefined = parseInt(selectedContentAreaId)

        if (isNaN(contentAreaIdAsInt)) {
            console.error(`Invalid Content Area ID: ${selectedContentAreaId}`)
            // TODO: Figure out how to handle this error case
            return
        } else {
            console.log(`Content Area ID: ${contentAreaIdAsInt}`)
            console.log(`Enroll Test Taker and Redirect to Test`)
            SAMLApiService.rosterOrFetchAssessmentAndRedirect(contentAreaIdAsInt, prodPackage, language, customClassMapName).then(
                (resp: SamlSSOWelcomeCenterStartTestResponse) => {
                    console.log(`Response: ${resp}`)

                    // TODO: Redirect to the test
                    const userName = (authStore.auth && authStore.auth.ssoUserId) ? authStore.auth.ssoUserId : ""
                    const loginProduct: LoginProductContentAreaData = {
                        loginId: resp.logins.id,
                        userType: "S",
                        productId: resp.logins.productId,
                        productName: resp.logins.productName,
                        contentAreaId: resp.logins.contentareaid,
                        contentAreaName: CONTENT_AREA_ID_TO_LANGUAGE_NAME[contentAreaIdAsInt],
                        rostered: true,
                        userName: userName
                    }

                    // Setup state for the report
                    productStore.setLoginProduct(loginProduct)

                    const lastPage: LastPage = {
                        url: "/sso/saml/welcome-center",
                        takeCode: resp.logins.testCode
                    }

                    productStore.driver!!.helper.refreshTest(props, lastPage, loginProduct)
                    // productStore.driver!!.helper.refreshTest({}, lastPage, loginProduct)
                        .then (response =>
                            productStore.driver!!.helper.refreshTestCallback(response, props)
                            // productStore.driver!!.helper.refreshTestCallback(response, {})
                        )

                }
            ).catch((error: any) => {
                console.log(`Error: ${error}`)
            })
        }

    }

    return (
        <>
            <Header />

            <div className={classes.mainSAMLLandingPageStyle}>
                <H1Text>Welcome to the Avant SAML Welcome Center</H1Text>
                <div className={classes.accordionStyles}>
                    {
                        Object.keys(samlState.listOfLanguagesByProductPackage).map((productPackageCode: string, index: number) => {
                            return (
                                <Accordion defaultExpanded={index === 0} key={`${index}-${productPackageCode.split("|||")[0]}`}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <H2Text>{productPackageCode.split("|||")[1]}</H2Text>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {
                                            samlState.listOfLanguagesByProductPackage[productPackageCode].map((data: SamlWelcomeCenterAssessmentList) => {
                                                return (
                                                    <WCAssessmentButtons key={`${index}-${data.assessmentContentAreaId}-${productPackageCode.split("|||")[0]}`} buttonData={{
                                                        assessmentLanguage: data.assessmentLanguageDisplayName,
                                                        selected: false,
                                                        completed: data.assessmentStatus.testCompleted,
                                                        inProgress: data.assessmentStatus.testInProgress,
                                                        id: data.assessmentContentAreaId.toString(),
                                                        onClickHandler: (id: string) => {
                                                            const pp = productPackageCode.split("|||")[0]
                                                            console.log(`Clicked ${id}, pp: ${pp}, contentAreaId: ${data.assessmentContentAreaId}, language: ${data.assessmentLanguageDisplayName}`)
                                                            assessmentSelected(id, pp, data.assessmentLanguageDisplayName, data.customClassMapName)
                                                        }
                                                    }} />
                                                )
                                            })
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                    }
                </div>
                <div className={classes.redirectButtonContainer}>
                    <Button
                        variant={"contained"}
                        onClick={() => history.push("/sso/student")}
                    >
                        Back To Class List
                    </Button>
                </div>
            </div>
        </>
    )

}

export default connect(
    null,
    mapDispatchToProps
)(SAMLWelcomeCenterContainer)
