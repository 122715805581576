import {observer} from "mobx-react"
import React from "react"
import {FormLabel, FormControl, FormGroup} from "react-bootstrap"
import FontAwesome from "react-fontawesome"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"
import {isNullOrUndefined} from "util"
import * as validator from "validator"

import {authStore} from "../../app/common/authentication/AuthStore"
import {loadingSpinnerStore} from "../../app/common/loaders/LoadingSpinnerStore"
import {productStore} from "../../app/common/products/ProductStore"
import {RoutePaths} from "../../app/routes/Routes"
import {postProfileSuccess} from "../../redux/app/actions"
import ApiService from "../../services/ApiService"
import HelperService from "../../services/HelperService"
import {ApiErrorResponse, ApiPanelGraph, LastPage, Profile, State, Take, TestState} from "../../types/types"
import {TakeId} from "../../validation/ValidPrimaryKey"
import {addTakeDispatches} from "../App/App"
import Button from "../Button/Button"
import ProfileProgress, {IProfileProgressCircle} from "../ProfileProgress/ProfileProgress"

interface FormErrors {
    firstName?: string
    lastName?: string
    phone?: string
    email?: string
}

interface Form {
    firstName: string
    lastName: string
    phone: string
    email: string
    selfEvaluationIndex: number
}

interface ProfileStateToProps {
    take: Take
    takeCode: string
    panelGraph: ApiPanelGraph
}

const mapStateToProps = (state: State): ProfileStateToProps => {
    return {
        take: state.app.take!,
        takeCode: state.app.takeCode,
        panelGraph: state.app.panelGraph!
    }
}

interface ProfileDispatchToProps {
    profileSuccessDispatch: (profile: Profile) => void
}

const mapDispatchToProps = (dispatch: Dispatch): ProfileDispatchToProps => {
    const dispatches = {
        profileSuccessDispatch: (profile: Profile) => {
            dispatch(postProfileSuccess(profile))
        }
    }
    return addTakeDispatches(dispatches, dispatch)
}

type TComponentProps = RouteComponentProps<any> & ProfileDispatchToProps & ProfileStateToProps

interface ReadyProfileState {
    formErrors: FormErrors
    formError: string | null
    profile: Form
    take: Take
}

@observer
class ReadyProfile extends React.Component<TComponentProps, ReadyProfileState> {
    state: ReadyProfileState

    constructor(props: TComponentProps) {
        super(props)
        this.state = {
            profile: {
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
                selfEvaluationIndex: 0
            },
            formErrors: {},
            formError: null,
            take: props.take
        }
    }

    componentDidMount() {
        const {
            history
        } = this.props

        document.title = `Avant | Profile`
        HelperService.enableTextSelection()
        const authUser = authStore.auth
        if (!authUser) {
            history.push(RoutePaths.LOGIN)
        }

        const product = productStore.loginProduct
        if (!product) {
            history.push(RoutePaths.LOGIN)
        }

        const driver = productStore.driver!!

        if (this.props.take === null) {
            if (authUser) {
                const lastPage: LastPage | null = HelperService.getLastPageFromLs()
                if (lastPage !== null) {
                    driver.helper
                        .refreshTest(this.props, lastPage, product!!)
                        .then((testState: TestState) => {
                            if (isNullOrUndefined(testState.take)) {
                                throw Error(
                                    "[ProfileFormContainer.componentDidMount] testState.take is null or undefined"
                                )
                            }
                            if (isNullOrUndefined(testState.take.profile)) {
                                throw Error(
                                    "[ProfileFormContainer.componentDidMount] " +
                                    "testState.take.profile is null or undefined"
                                )
                            }
                            testState.take.profile.contentAreaId = this.props.panelGraph.contentAreaId
                            testState.take.profile.userId = authUser.loginId
                                ? authUser.loginId
                                : -1
                            testState.take.profile.takeCode = this.props.takeCode
                            this.setState({take: testState.take})
                        })
                } else {
                    this.props.history.push("/test")
                }
            } else {
                // Don't have a valid authUser
                this.props.history.push("/test")
            }
        } else {
            const lastPage: LastPage = {
                url: "/ready-profile",
                takeCode: this.props.takeCode,
                panelGraphId: this.props.panelGraph.id
            }
            localStorage.setItem("lastPage", JSON.stringify(lastPage))
        }
        loadingSpinnerStore.hideLoadingSpinner = true
    }

    getFirstNameErrors = (values: Form): string | undefined => {
        if (values.firstName.length === 0) {
            return productStore.driver!!.strings.enterYourFirstName
        }
        return undefined
    }

    getLastNameErrors = (values: Form): string | undefined => {
        if (values.lastName.length === 0) {
            return productStore.driver!!.strings.enterYourLastName
        }
        return undefined
    }

    getTelephoneErrors = (values: Form): string | undefined => {
        if (values.phone.length === 0) {
            return productStore.driver!!.strings.enterYourPhoneNumber
        } else if (!validator.isMobilePhone(values.phone, "any")) {
            return productStore.driver!!.strings.enterValidPhoneNumber
        }
        return undefined
    }

    getEmailErrors = (values: Form): string | undefined => {
        if (values.email.length === 0) {
            return productStore.driver!!.strings.enterYourEmail
        } else if (!validator.isEmail(values.email)) {
            return productStore.driver!!.strings.enterValidEmail
        }
        return undefined
    }

    validate = (values: Form): { hasErrors: boolean; formErrors: FormErrors } => {
        const formErrors: FormErrors = {
            firstName: this.getFirstNameErrors(values),
            lastName: this.getLastNameErrors(values),
            phone: this.getTelephoneErrors(values),
            email: this.getEmailErrors(values)
        }

        let hasErrors: boolean = false
        Object.keys(formErrors).forEach((key: string) => {
            const value: string[] = formErrors[key]
            if (value && value.length > 0) {
                hasErrors = true
            }
        })

        return {
            hasErrors,
            formErrors
        }
    }

    handleNext = (event: any) => {
        const {take, profileSuccessDispatch, history} = this.props
        const {profile} = this.state
        const driver = productStore.driver
        event.preventDefault()
        this.setState({formErrors: {}, formError: null})

        const errors = this.validate(this.state.profile)
        if (errors.hasErrors) {
            this.setState({
                formError: "There are errors on the form.",
                formErrors: errors.formErrors
            })
            return
        }

        ApiService
            .postProfile(new TakeId(take.id), profile, driver!!.config.APP_PRODUCT_NAME.toLowerCase())
            .then(() => {
                profileSuccessDispatch(profile)
                history.push("/auto-start-dashboard")
            })
            .catch((error: ApiErrorResponse) => {
                throw Error(`Failed to post Ready profile, ${error.message}`)
            })
    }

    handleFirstNameChange = (event: any) => {
        const profile = {...this.state.profile, firstName: event.target.value}
        this.setState({profile})
    }

    handleLastNameChange = (event: any) => {
        const profile = {...this.state.profile, lastName: event.target.value}
        this.setState({profile})
    }

    handleTelephoneChange = (event: any) => {
        const profile = {...this.state.profile, phone: event.target.value}
        this.setState({profile})
    }

    handleEmailChange = (event: any) => {
        const profile = {...this.state.profile, email: event.target.value}
        this.setState({profile})
    }

    render() {
        const circles: IProfileProgressCircle[] = [
            {
                state: "visited"
            },
            {
                state: "active"
            }
        ]
        return (
            <div className="profile">
                <div className="profile__profile-container">
                    <div className="profile__profile-container__top">
                        <div className="profile__profile-container__top__title">{"Crea tu perfil"}</div>
                        <ProfileProgress circles={circles}/>
                    </div>
                    <div className="profile__profile-container__bottom">
                        <div className="profile__profile-container__form">
                            <form onSubmit={this.handleNext}>
                                <div>
                                    <div className="profile__profile-container__bottom__title">
                                        {"Información del Perfil"}
                                    </div>
                                    <div className="profile__profile-container__bottom__subtitle">
                                        {"* Obligatorio"}
                                    </div>
                                </div>
                                <div style={{width: "620px"}}>
                                    <div style={{display: "flex"}}>
                                        <div
                                            className={
                                                this.state.formErrors.firstName
                                                    ? "profile__profile-container__form--error"
                                                    : ""
                                            }
                                            style={{width: "300px", marginRight: "20px"}}
                                        >
                                            <FormGroup>
                                                <FormLabel>Primer Nombre *</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    value={this.state.profile.firstName}
                                                    placeholder=""
                                                    data-tst-id="first-name"
                                                    onChange={this.handleFirstNameChange}
                                                    readOnly={productStore.loginProduct!!.rostered}
                                                />
                                                {this.state.formErrors.firstName && (
                                                    <div className="profile__profile-container__form--error__message">
                                                        {this.state.formErrors.firstName}
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div
                                            className={
                                                this.state.formErrors.lastName
                                                    ? "profile__profile-container__form--error"
                                                    : ""
                                            }
                                            style={{width: "300px"}}
                                        >
                                            <FormGroup>
                                                <FormLabel>Apellido *</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    value={this.state.profile.lastName}
                                                    placeholder=""
                                                    data-tst-id="last-name"
                                                    onChange={this.handleLastNameChange}
                                                    readOnly={productStore.loginProduct!!.rostered}
                                                />
                                                {this.state.formErrors.lastName && (
                                                    <div className="profile__profile-container__form--error__message">
                                                        {this.state.formErrors.lastName}
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div style={{display: "flex"}}>
                                        <div
                                            className={
                                                this.state.formErrors.phone
                                                    ? "profile__profile-container__form--error"
                                                    : ""
                                            }
                                            style={{width: "222px", marginRight: "20px"}}
                                        >
                                            <FormGroup>
                                                <FormLabel>Telefono No. *</FormLabel>
                                                <FormControl
                                                    type="telephone"
                                                    value={this.state.profile.phone}
                                                    placeholder=""
                                                    data-tst-id="telephone"
                                                    onChange={this.handleTelephoneChange}
                                                />
                                                {this.state.formErrors.phone && (
                                                    <div className="profile__profile-container__form--error__message">
                                                        {this.state.formErrors.phone}
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div
                                            className={
                                                this.state.formErrors.email
                                                    ? "profile__profile-container__form--error"
                                                    : ""
                                            }
                                            style={{width: "377px"}}
                                        >
                                            <FormGroup>
                                                <FormLabel>Correo Electronico *</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    value={this.state.profile.email}
                                                    placeholder=""
                                                    data-tst-id="email"
                                                    onChange={this.handleEmailChange}
                                                />
                                                {this.state.formErrors.email && (
                                                    <div className="profile__profile-container__form--error__message">
                                                        {this.state.formErrors.email}
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    className="avant-button--primary profile__profile-container__form__button"
                                    onClick={this.handleNext}
                                >
                                    <span style={{display: "inline-block", verticalAlign: "middle"}}>TERMINAR</span>
                                    <FontAwesome
                                        className="profile__profile-container__form__button__arrow"
                                        name="chevron-circle-right"
                                    />
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReadyProfile)
