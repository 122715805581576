import * as React from "react"
import {CONTACT_US_URL} from "../../../util/Constants"

export const SSOUnauthorized: React.FC<{}> = (props) => {
    return (
        <div>
            <div className="login__login-container" style={{display:"inline-block"}}>
                <div className="login__login-container__top">
                    <h2 style={{textAlign:"center"}}>Unauthorized</h2>
                    <p>
                        We're sorry, the Avant Assessment application has not been set up for you.  If you believe this is incorrect...
                    </p>
                    <p>
                        <a href={CONTACT_US_URL} target={"_blank"}>Contact Avant Assessment support</a>
                    </p>
                    <p>
                        To learn more about what Avant Assessment has to offer, why not start with our <a href={"https://avantassessment.com/sample-tests"} target={"_blank"}>sample tests</a>?
                    </p>
                </div>
            </div>
        </div>
    )
}
