import React from "react"

import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress"
import * as Sentry from "@sentry/browser"
import HelperService from "../../services/HelperService"
import {AudioReadyState, AudioUtils} from "../../util/AudioUtils"
import Button from "../Button/Button"

interface AudioPlayerProps {
    id: number
    src: string
    audioClassName: string
    text?: string
    buttonClassName?: string
    iconClassName?: string
    maxPlays?: MaxPlays
}

interface MaxPlays {
    maxPlays: number
    takePanelId: number
    itemId: number
}

interface AudioPlayerState {
    buttonIcon: string
    plays: number
    disabled: boolean
    src: string
    isLoaded: boolean
    errorMessage?: string
}

export default class AudioPlayer extends React.Component<AudioPlayerProps, AudioPlayerState> {
    audioNode: HTMLAudioElement | undefined
    private _isMounted: boolean

    constructor(props: AudioPlayerProps) {
        super(props)
        this._isMounted = false
        this.state = {
            buttonIcon: "play_arrow",
            plays: 0,
            disabled: false,
            src: props.src,
            isLoaded: false
        }
    }


    componentDidMount() {
        if (this.props.maxPlays) {
            const key: string = this.getKey()
            const plays: number | null = HelperService.getFromLocalStorage(key)
            let disabled: boolean = false
            if (plays !== null) {
                if (plays >= this.props.maxPlays.maxPlays) {
                    disabled = true
                }
                this.setState({plays, disabled})
            } else {
                this.setState({plays: 0, disabled})
            }
        } else {
            this.setState({disabled: false, plays: 0})
        }
        this._isMounted = true
        this._isMounted && this.loadAudio()
    }

    loadAudio() {
        AudioUtils
            .loadAudio(this.props.src, AudioReadyState.HAVE_ENOUGH_DATA)
            .then(() => this._isMounted && this.setState({isLoaded: true}), ((err: Error) => {
                // TODO: Generic Client Error Handling should be used here?
                Sentry.captureException(new Error(`Could not load audio for item content: ${this.props.id} due to ${err}`))
                this._isMounted && this.setState({"errorMessage": "Could not load Audio File, please check your internet connection."})
            }))
    }

    componentDidUpdate() {
        if (this.props.src !== this.state.src) {
            this.setState({src: this.props.src})
        }
    }

    onClickPlayPause = () => {
        if (!this.audioNode) {
            throw Error("this.audioNode is undefined")
        }
        if (this.state.buttonIcon === "play_arrow") {
            this.audioNode.play()
            this.setState({buttonIcon: "pause"})
        } else {
            this.audioNode.pause()
            this.setState({buttonIcon: "play_arrow"})
        }
    }

    onEndPlay = () => {
        if (!this.state.isLoaded) { return }

        if (this.props.maxPlays) {
            const key: string = this.getKey()
            const plays: number = this.state.plays + 1
            localStorage.setItem(key, JSON.stringify(plays))
            if (this.state.plays >= this.props.maxPlays.maxPlays - 1) {
                this.setState({disabled: true, buttonIcon: "play_arrow"})
            } else {
                this.setState({plays, buttonIcon: "play_arrow"})
            }
        } else {
            this.setState({buttonIcon: "play_arrow"})
        }
    }

    getKey = () => {
        const {takePanelId, itemId} = this.props.maxPlays!
        return `audio-plays-${takePanelId}-${itemId}-${this.props.id}`
    }

    // The use of componentWillUnmount and this._isMounted is for handling a React warning suggesting a memory leak,
    // Solution found here: https://stackoverflow.com/questions/52061476/cancel-all-subscriptions-and-asyncs-in-the-componentwillunmount-method-how
    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const error = (
            <span>{this.state.errorMessage}</span>
        )
        const inProgress = (<CircularProgress variant={"indeterminate"}/>)
        const player = (
            <>
                <Button
                    className={this.props.buttonClassName ? this.props.buttonClassName : ""}
                    onClick={this.onClickPlayPause}
                    disabled={this.state.disabled}
                >
                    <i className={`material-icons ${this.props.iconClassName}`}>{this.state.buttonIcon}</i>{" "}
                    {this.props.text}
                </Button>
                <audio
                    src={this.state.src}
                    onEnded={this.onEndPlay}
                    controls={true}
                    hidden={true}
                    ref={(node: HTMLAudioElement) => (this.audioNode = node)}
                />
            </>
        )

        if (this.state.errorMessage) {
            return error
        } else if (!this.state.isLoaded) {
            return inProgress
        } else {
            return player
        }
    }
}
