import {styled} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";

export const SSOWrapper = styled(Box)({
    "@media print": {
        "tr > * ": {
            padding: "0px 3px !important",
        },
        "& button": {
            display: "none",
        },
        "a[href]::after": {
            content: "none !important",
        },
    }
})
