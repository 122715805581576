import {TakeSearchResult} from "../../../common/take/models/TakeSearchResult"
import {COMPUTER_SCORED_LEVELS, HUMAN_SCORED_LEVELS} from "../../ReportUtils"
import {ApiTakePanelSearchResult} from "../../../common/take/TakeSearchStore"

// Calculates sums and averages across skill and level for a given list of Takes
export class TakeSkillAggregate {

    readonly skillSymbol: string
    readonly skillName: string
    readonly count: number
    readonly levels: string[] = []
    readonly levelToCount: Map<string, number>
    readonly levelToPct: Map<string, number>
    readonly averageInSkill: number


    constructor(skillSymbol: string, skillName: string, takes: TakeSearchResult[], levels: string[]) {
        this.skillSymbol = skillSymbol
        this.skillName = skillName
        this.levelToCount = new Map()
        this.levelToPct = new Map()
        this.levels = levels

        // Initialize the map of Level => counts/percentages
        levels.forEach((level) => {
            this.levelToPct.set(level, 0)
            this.levelToCount.set(level, 0)
        })

        let totalCount = 0
        let totalCountForAverage = 0
        let totalScore = 0
        let averageInSkill = 0
        takes.map((t) => t.takePanels).forEach((takePanels) => {
            // This only works because we're assuming there is only one takepanel per skill per take!
            takePanels.forEach((tp) => {
                const strippedLevel = tp.level!.replace("-", "").replace("+", "")

                if (tp.skill === this.skillSymbol && strippedLevel && this.levelToCount.has(strippedLevel)) {
                    totalCount++

                    const levelCount = this.levelToCount.get(strippedLevel)! + 1
                    this.levelToCount.set(strippedLevel, levelCount)

                    if (!isNaN(Number(strippedLevel))) {
                        totalCountForAverage++
                        totalScore += Number(strippedLevel)
                    }
                }
            })

            averageInSkill = (totalCountForAverage > 0)
                ? totalScore / (totalCountForAverage)
                : 0

            this.levelToCount.forEach((count, level) => {
                this.levelToPct.set(level, (count / totalCount) * 100)
            })
        })

        this.count = totalCount
        this.averageInSkill = Number(parseFloat(averageInSkill.toString()).toFixed(2))
    }
}

export const readingSkillAggregate = (takes: TakeSearchResult[]) =>
    new TakeSkillAggregate("RD", "Reading", takes, COMPUTER_SCORED_LEVELS)

export const listeningSkillAggregate = (takes: TakeSearchResult[]) =>
    new TakeSkillAggregate("LS", "Listening", takes, COMPUTER_SCORED_LEVELS)

export const writingSkillAggregate = (takes: TakeSearchResult[]) =>
    new TakeSkillAggregate("WR", "Writing", takes, HUMAN_SCORED_LEVELS)

export const speakingSkillAggregate = (takes: TakeSearchResult[]) =>
    new TakeSkillAggregate("SP", "Speaking", takes, HUMAN_SCORED_LEVELS)

const receptiveSkillAggregrate = (takes: TakeSearchResult[]) => {
    // We need to change the skill to Receptive for Listening takepanels
    // This is hopefully a temp solution until we can add the SL skills to the database
    takes.forEach((take: TakeSearchResult) => {
        take.takePanels.forEach((tp: ApiTakePanelSearchResult) => {
            tp.skill = tp.skill == "LS" ? "RE" : tp.skill
        })
    })

    return new TakeSkillAggregate("RE", "Receptive", takes, COMPUTER_SCORED_LEVELS)
}

const expressiveSkillAggregrate = (takes: TakeSearchResult[]) => {
    // Swapping speaking to expressive
    takes.forEach((take: TakeSearchResult) => {
        take.takePanels.forEach((tp: ApiTakePanelSearchResult) => {
            tp.skill = tp.skill == "SP" ? "EX" : tp.skill
        })
    })

    return new TakeSkillAggregate("EX", "Expressive", takes, HUMAN_SCORED_LEVELS)
}

export const skillAggregateMap: Map<string, ((takes: TakeSearchResult[]) => TakeSkillAggregate)> = new Map([
    ["Reading", readingSkillAggregate],
    ["Writing", writingSkillAggregate],
    ["Listening", listeningSkillAggregate],
    ["Speaking", speakingSkillAggregate],
    ["Receptive", receptiveSkillAggregrate],
    ["Expressive", expressiveSkillAggregrate],
])

export const CompositeScoreName = "Composite Score"

export interface CompositeScore {
    name: string
    score: number
}


export const compositeScoreAggregate = (takes: TakeSearchResult[]): CompositeScore => {

    let completeCount = 0
    let totalCompositeScore = 0.0
    takes.forEach((take: TakeSearchResult) => {
        if (take.compositeScore && take.compositeScore > 0) {
            completeCount++
            totalCompositeScore += take.compositeScore
        }
    })

    return {name: CompositeScoreName, score: Number((totalCompositeScore / completeCount).toFixed(2))}
}
