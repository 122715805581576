export enum UserType {
    /**
     * Student
     */
    S = "S",
    /**
     * Teacher
     */
    T = "T",
    /**
     * District
     */
    D = "D",
    /**
     * School
     */
    SC = "SC",
    /**
     * Customer
     */
    C = "C",
    /**
     * Location
     */
    L = "L",
    /**
     * Admin
     */
    A = "A",
    /**
     * Proctor
     */
    P = "P",
    /**
     * Invalid
     */
    INVALID = "INVALID",
    /**
     * Password Reset
     */
    PR = "PR",
    /**
     * Reset Login
     */
    R = "R",
    /**
     * Authenticated
     */
    Authenticated = "Authenticated"
}
