import {observer} from "mobx-react"
import React from "react"
import Instructions from "../../../../components/Instructions"
import {authStore} from "../../authentication/AuthStore"
import {AvantCircularProgress} from "../../loaders/AvantCircularProgress"
import {productStore} from "../../products/ProductStore"
import {itemStore} from "../ItemStore"

interface ItemInstructionsViewerProps {
    itemId: number
    takePanelId: number
    responseModal?: boolean
}

@observer
export class ItemInstructionsViewer extends React.Component <ItemInstructionsViewerProps> {

    componentDidMount(): void {
        itemStore.getItem(this.props.itemId)
    }

    componentWillUnmount(): void {
        itemStore.currentItem = undefined
    }

    render() {
        const item = itemStore.currentItem
        if (item == null) {
            return <AvantCircularProgress/>
        }
        const {takePanelId, responseModal} = this.props

        return (
            <Instructions
                takePanelId={takePanelId}
                item={item}
                authUser={authStore.auth!}
                driver={productStore.driver!}
                product={productStore.loginProduct!}
                responseModal={responseModal}
            />
        )
    }

}
