/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import React, {useState} from 'react'
import {AppBar, Tab, Tabs} from "@material-ui/core"
import {CredlyReportRow} from "./CredlyReportTool"
import {TabPanel} from "../DevTools/DevTools"
import MaterialTable, {Column} from "material-table"
import {H2Text} from "../../../styles/AvantTypography"

interface CredlyReportTablesProps {
    reportData: CredlyReportRow[],
}

const detailReportColumns: Column<CredlyReportRow>[] = [
    {
        title: "Test Code",
        field: "testCode",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "Product",
        field: "product",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "Login Name",
        field: "loginName",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "First Name",
        field: "firstName",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "Last Name",
        field: "lastName",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "Email",
        field: "email",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "Language",
        field: "language",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "State",
        field: "state",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "District or Customer",
        field: "districtOrCustomer",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "School",
        field: "school",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "Test Group or Location",
        field: "testGroupOrLocation",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },

    {
        title: "Badge Eligible",
        field: "badgeEligible",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "Badge Name",
        field: "badgeName",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "Badge Date Awarded",
        field: "badgeDateAwarded",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    }
]

const billingReportColumns: Column<CredlyReportRow>[] = [
    {
        title: "Product",
        field: "product",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "Test Code",
        field: "testCode",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "Language",
        field: "language",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "State",
        field: "state",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "District or Customer",
        field: "districtOrCustomer",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "School",
        field: "school",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "Test Group or Location",
        field: "testGroupOrLocation",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "Badges Awarded",
        field: "badgesAwardedCount",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    }

]

const awardingReportColumns: Column<CredlyReportRow>[] = [
    {
        title: "Product",
        field: "product",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "Test Code",
        field: "testCode",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "Language",
        field: "language",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "State",
        field: "state",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "District or Customer",
        field: "districtOrCustomer",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "School",
        field: "school",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "Test Group or Location",
        field: "testGroupOrLocation",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    },
    {
        title: "Badges Eligible",
        field: "badgesEligibleCount",
        cellStyle: {
            whiteSpace: "nowrap",
        },
    }
]

const billingAwardingRowData = (reportData: CredlyReportRow[]): CredlyReportRow[] => {
    const uniqueValueSet = new Set()

    // Filter out duplicate rows
    const billingAwardingRows = reportData.filter((row: CredlyReportRow) => {
        // check if testCode is already in the set
        const isInSet = uniqueValueSet.has(row.testCode)

        // Add the testCode to the set
        uniqueValueSet.add(row.testCode)

        return !isInSet
    })

    // Get the badge counts
    billingAwardingRows.forEach((billingRow: CredlyReportRow) => {
        const badgesEligibleCount = reportData.filter((row: CredlyReportRow) => row.testCode == billingRow.testCode && row.badgeEligible).length
        const badgesAwardedCount= reportData.filter((row: CredlyReportRow) => row.testCode == billingRow.testCode && row.badgeDateAwarded).length

        billingRow.badgesEligibleCount = badgesEligibleCount
        billingRow.badgesAwardedCount = badgesAwardedCount
    })

    return billingAwardingRows
}

export const CredlyReportTables: React.FC<CredlyReportTablesProps> = ({reportData}) => {
    const [reportTab, updateReportTab] = useState<number>(0)

    const onReportTabChange = (event: React.ChangeEvent<{}>, value: number) => {
        updateReportTab(value)
    }

    return (
        <>
            <AppBar position={"static"}>
               <Tabs value={reportTab} onChange={onReportTabChange}>
                   <Tab
                        value={0}
                        label={"Detail"}
                   />
                   <Tab
                       value={1}
                       label={"Billing"}
                   />
                   <Tab
                       value={2}
                       label={"Awarding"}
                   />
               </Tabs>
            </AppBar>
            <TabPanel index={0} value={reportTab}>
                <H2Text>Detail Report</H2Text>
                <MaterialTable
                    columns={detailReportColumns}
                    data={reportData}
                    options={{
                        headerStyle: {
                            whiteSpace: "nowrap",
                        },
                        showTitle: false,
                        padding: "dense",
                        exportButton: true,
                        exportFileName: "CredlyDetailReport"
                    }}
                />
            </TabPanel>
            <TabPanel index={1} value={reportTab}>
                <H2Text>Billing Report</H2Text>
                <MaterialTable
                    columns={billingReportColumns}
                    data={billingAwardingRowData(reportData)}
                    options={{
                        headerStyle: {
                            whiteSpace: "nowrap",
                        },
                        showTitle: false,
                        padding: "dense",
                        exportButton: true,
                        exportFileName: "CredlyBillingReport"
                    }}
                />
            </TabPanel>
            <TabPanel index={2} value={reportTab}>
                <H2Text>Awarding Report</H2Text>
                <MaterialTable
                    columns={awardingReportColumns}
                    data={billingAwardingRowData(reportData)}
                    options={{
                        headerStyle: {
                            whiteSpace: "nowrap",
                        },
                        showTitle: false,
                        padding: "dense",
                        exportButton: true,
                        exportFileName: "CredlyAwardingReport"
                    }}
                />
            </TabPanel>
        </>
    )
}
