import {Button, Grid, Paper, TextField} from "@material-ui/core"
import * as React from "react"
import {ChangeEvent, useState} from "react"
import DevToolsApi from "../../../services/DevToolsApi"
import jwtDecode from "jwt-decode"
import {JwtSubject, JwtToken} from "../../../types/types";
import moment from "moment";

export const Masquarade: React.FC = () => {
    const [userId, setUserId] = useState("")
    const [ssoId, setSsoId] = useState("")
    const [ssoUserId, setSsoUserId] = useState("")
    const [ssoRole, setSsoRole] = useState("")
    const [response, setresponse] = useState("")
    const handleUserIdChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => setUserId(e.target.value)
    const handleSsoIdChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => setSsoId(e.target.value)
    const handleSsoUserIdChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => setSsoUserId(e.target.value)
    const handleSsoRoleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => setSsoRole(e.target.value)

    const handleDo = () => {
        DevToolsApi.masquaradeSSO(userId, ssoId, ssoUserId, ssoRole)
            .then(apiResponse => {
                if (apiResponse) {
                    if (apiResponse.token === null) { return }
                    const decoded: JwtToken = jwtDecode(apiResponse.token) as JwtToken
                    const sub: JwtSubject = JSON.parse(decoded.sub)
                    let expireDate = moment.utc(decoded.exp*1000)
                    let authInfo = '{'
                    authInfo += `\n"token": "${apiResponse.token}",`
                    authInfo += `\n"tokenTimeRemaining": 3600,`
                    authInfo += `\n"expires": "${expireDate.locale("UTC").format("YYYY-MM-DDTH:mm:ss.SSS")}Z",`
                    authInfo += `\n"loginId": ${sub.userId},`
                    authInfo += `\n"userType": "${sub.userType}",`
                    authInfo += `\n"eulaAgreeDate": null,`
                    authInfo += `\n"userName": "${sub.userName}",`
                    authInfo += `\n"userRole": "${sub.userName}",`
                    authInfo += `\n"ssoDisplayName": "a display name",`
                    authInfo += `\n"ssoType": "${sub.ssoType}",`
                    authInfo += `\n"ssoToken": "",`
                    authInfo += `\n"ssoId": "${sub.ssoId}",`
                    authInfo += `\n"ssoUserId": "${sub.ssoUserId}",`
                    authInfo += `\n"userPermissions": "${sub.userPermissions}",`
                    authInfo += `\n"userId": ${sub.userId}`
                    authInfo += '\n}'

                    setresponse(authInfo)
                }
            })
    }
    return (
        <Grid container={true} spacing={3}>
            <Grid item={true} xs={4}>
                <TextField
                    id="user-id"
                    label="UserID (General SSO User) to masq as"
                    onChange={handleUserIdChange}
                    style={{width: "100%"}}
                />
            </Grid>
            <Grid item={true} xs={4}>
                <TextField
                    id="sso-id"
                    label="SSO District ID of the user to masq as"
                    onChange={handleSsoIdChange}
                    style={{width: "100%"}}
                />
            </Grid>
            <Grid item={true} xs={4}>
                <TextField
                    id="sso-user-id"
                    label="SSO User ID of the user to masq as"
                    onChange={handleSsoUserIdChange}
                    style={{width: "100%"}}
                />
            </Grid>
            <Grid item={true} xs={4}>
                <TextField
                    id="sso-role"
                    label="SSO Role"
                    onChange={handleSsoRoleChange}
                    style={{width: "100%"}}
                />
            </Grid>
            <Grid item={true} xs={4}>
                <Button variant="contained" onClick={handleDo}>Create Auth User JSON</Button>
            </Grid>
            <Grid item={true} xs={12}>
                <Paper elevation={3} style={{padding: "5px", margin: "5px"}}>
                    <p>{response}</p>
                </Paper>
            </Grid>
        </Grid>
    )
}
