import ApiService from "./ApiService"

export interface CleverRosterStudentInfo {
    index: number
    student_number: string
    language: string
    errors: string[]
}

export interface CleverRosterTeacherInfo {
    index: number
    teacher_sis_id: string
    access: string
    language: string
    errors: string[]
}


export interface RosterGroupResponse {
    students: CleverRosterStudentInfo[]
}

export interface RosterTeacherResponse {
    teachers: CleverRosterTeacherInfo[]
}

export default class CleverRosterApi {
    static rosterGroup(students: CleverRosterStudentInfo[]): Promise<RosterGroupResponse> {
        const url: string = `${ApiService.API_URL}sso/roster_custom`
        return new Promise((resolve, reject) =>
            ApiService.addInteractionBlockingRequest(
                ApiService.post(url, {students})
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            )
        )
    }

    static rosterTeachers(teachers: CleverRosterTeacherInfo[]): Promise<RosterTeacherResponse> {
        const url: string = `${ApiService.API_URL}sso/roster_custom_teacher`
        return new Promise((resolve, reject) =>
            ApiService.addInteractionBlockingRequest(
                ApiService.post(url, {teachers})
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            )
        )
    }

    static customSectionsAllowed(): Promise<boolean> {
        const url: string = `${ApiService.API_URL}sso/custom_sections_allowed`
        return new Promise((resolve, reject) =>
            ApiService.addInteractionBlockingRequest(
                ApiService.get(url)
                    .then(response => {resolve(response.data)})
                    .catch(error => reject(error))
            )
        )
    }
}
