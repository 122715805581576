import React from "react"

interface IComponentProps {
    inputDevice: MediaDeviceInfo
    isSelected: boolean
}

export default class InputDeviceOption extends React.Component<IComponentProps, {}> {

    render() {
        return (
            <option
                className={"InputDeviceOption"}
                selected={this.props.isSelected}
                value={this.props.inputDevice.deviceId}
            >
                {this.props.inputDevice.label}
            </option>
        )
    }
}
