import {observable} from "mobx"
import {UiMessage, UiMessageType} from "./UiMessage"

export class MessageStore {

    @observable
    open = false

    @observable
    message?: UiMessage

    /**
     * Eventually this should be moved to its own thing.
     */
    @observable
    supportEmail?: string

    setMessage = (message: UiMessage) => {
        this.message = message
        this.open = true
    }

    setDefaultError = () => {
        this.setErrorMessage(`Sorry, an error occurred. Please contact Avant support.`)
    }

    setErrorMessage = (error: string) => {
        this.message = {
            message: error,
            type: UiMessageType.ERROR
        }
        this.open = true
    }

    setCloseMessage = () => {
        this.open = false
    }

    setInfoMessage = (message: string) => {
        this.message = {
            message,
            type: UiMessageType.INFO
        }
        this.open = true
    }

}

export const messageStore = new MessageStore()
