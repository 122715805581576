/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {Chip, Grid, Input, InputLabel, MenuItem, Paper, Select, Theme} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import createStyles from "@material-ui/core/styles/createStyles"
import makeStyles from "@material-ui/core/styles/makeStyles"
import * as React from "react"
import {RefObject, useState} from "react"
import {messageStore} from "../../../../app/common/messages/MessageStore"
import {SSOBulkConfirm} from "../../../../app/sso/districtAdmin/components/SSOBulkConfirm"
import DevToolsApi from "../../../../services/DevToolsApi"
import {H2Text} from "../../../../styles/AvantTypography"
import {theme} from "../../../../styles/MuiThemes"
import {ApiLogin, P360Scheduling} from "../../../../types/types"
import {log} from "../../../../util/Logging"
import MaterialTable from "material-table"

const useBulk360ControlStyles = makeStyles((theme: Theme) =>
    createStyles({
        ScheduleListStyle: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            margin: 2,
        },
        selected: {
            backgroundColor: "turquoise",
            color: "white",
            fontWeight: 600
        },
    })
)


export interface BulkControlsPropsP360 {
    tableRef: RefObject<MaterialTable<ApiLogin>>
    selectedIds: string[],
    schedules: P360Scheduling[]
}

// Below controls the dropdown menu attached ot the multi select
const MenuProps = {
    PaperProps: {
        style: {
            // maxHeight: 48 * 4.5 + 8,
            // maxHeight: 250,
            width: 500,
            // overflow: scroll
        }
    }
}

export const BulkP360Controls: React.FC<BulkControlsPropsP360> = ({selectedIds, tableRef, schedules}) => {

    const classes = useBulk360ControlStyles()
    const [selectedScheduleList, setSelectedScheduleList] = useState<P360Scheduling[]>([])

    const [bulkConfirmation, setBulkConfirmation] = useState(false)
    const handleBulkConfirmationClick = () => { setBulkConfirmation(true) }
    const closeBulkConfirmationCancel = () => { setBulkConfirmation(false) }
    const closeBulkConfirmationProceed = async () => {
        setBulkConfirmation(false)
        bulkAttachSchedulesToExams()
    }

    // This should handle styling of selected items.... It does nto work yet, Likely due to our themes...
    const getStyles = (name: P360Scheduling, scheduleList: P360Scheduling[], theme: Theme) => {
        const isSelected = selectedScheduleList.some(selectedSchedule => selectedSchedule.p360SchedulingId === name.p360SchedulingId);
        return {
            fontWeight: scheduleList.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
            color: isSelected ? 'White' :'inherit',
            backgroundColor: isSelected ? 'grey' : 'inherit',        
        }
    }

    const bulkAttachSchedulesToExams = () => {
        // selectedIds is an array of urls containing login ids
        const loginIds: number[] = selectedIds.map((rowData: any) => {
            const link = rowData._links.self.href
            const split = link.split("/")
            return Number(split.pop())
        })

        const schedule = [...selectedScheduleList]

        if (schedule) {
            DevToolsApi.createP360Exams(loginIds, schedule)
                .then(_ => {
                    messageStore.setInfoMessage(`Updated ${selectedIds.length} rows`)
                    // @ts-ignore
                    if (tableRef.current) { tableRef.current.onQueryChange() }

                    // Clear the selected array on success
                    setSelectedScheduleList([])
                })
        }
    }

    const handleChipDelete = (chipToDelete: P360Scheduling) => () => {
        const curArray = [...selectedScheduleList]
        const arrInd = curArray.indexOf(chipToDelete)
        curArray.splice(arrInd, 1)
        setSelectedScheduleList(curArray)
    }

    const changeHandler = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {

        // The below ts-ignore is so that I dont have to force a type on something that is defined as unknown.... Result of mui component
        // @ts-ignore
        const addElement: P360Scheduling | undefined = schedules.find(element  => element.p360SchedulingId === (e.target.value[e.target.value.length - 1]))
        const addElementIndex: number = addElement != null ? selectedScheduleList.indexOf(addElement) : -1
        log.debug(`Selected Element: ${addElement}`)
        log.debug(`Current list of elements: ${selectedScheduleList}`)
        // TODO Store new element in display array
        const chipSchedule = [...selectedScheduleList]

        // add element not being null means we have a schedule, and addElementIndex === -1 means we dont already have it
        if (addElement != null && addElementIndex === -1) {
            chipSchedule.push(addElement)
        }
        setSelectedScheduleList(chipSchedule)
    }

    // TODO: This is to make the columns in the dropdown show as selected
    // const isSelected = (element: P360Scheduling): boolean => {
    //
    //     // const result: number = selectedScheduleList.indexOf(element)
    //     const elementInArray = selectedScheduleList.filter((sched: P360Scheduling) => {
    //         return sched.scheduleId  === element.scheduleId
    //     })
    //
    //     if (elementInArray.length > 0) {
    //
    //         // @ts-ignore
    //         const result: number = selectedScheduleList.indexOf(elementInArray)
    //         return true
    //     }
    //
    //     return false
    // }
    return (
        <Grid item={true} xs={12}>
            <Paper
                elevation={3}
                className={classes.ScheduleListStyle}
            >
                <H2Text>P360 Create Exam Tool</H2Text>
                <Grid
                    container={true}
                    spacing={3}
                >
                    <Grid item={true} xs={12}>
                        <div className={classes.chips}>
                            {selectedScheduleList.map((value: P360Scheduling, index: number) => (
                                <Chip
                                    key={`${index}-${value.p360SchedulingId}`}
                                    label={value.title} className={classes.chip}
                                    // TODO: Need to add delete chip
                                    onDelete={handleChipDelete(value)}
                                />
                            ))}
                        </div>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <InputLabel>Set Schedules</InputLabel>
                        <Select
                            fullWidth={true}
                            labelId="Set-Schedules-Label"
                            multiple={true}
                            value={selectedScheduleList}
                            onChange={changeHandler}
                            input={<Input id="select-multiple-schedules" />}
                            native={false}
                            renderValue={
                                // This handles how the Items are rendered in the drop down when not expanded
                                (selected) => {
                                    if ((selected as P360Scheduling[]).length === 0) {
                                        return <em>Select Schedules To add...</em>
                                    }
                                    return (selected as P360Scheduling[]).map((schedule: P360Scheduling) => {
                                        return schedule.title
                                    }).join(", ")
                                }
                            }
                            MenuProps={MenuProps}
                        >
                            { schedules.map((schedule: P360Scheduling, index: number) => (
                                    <MenuItem
                                        key={`${index}-${schedule.p360SchedulingId}`}
                                        value={schedule.p360SchedulingId}
                                        style={getStyles(schedule, selectedScheduleList, theme)}
                                        // TODO: this is what needs to be added ot get highlighting in the dropdown
                                        // selected={isSelected(schedule)}
                                        // classes={{selected: classes.selected}}
                                    >
                                        {schedule.title}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid item={true} xs={12} style={{textAlign: "right"}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleBulkConfirmationClick}
                        >
                            Apply to Selected
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            {
                bulkConfirmation && (
                    <SSOBulkConfirm
                        handleOK={closeBulkConfirmationProceed}
                        handleCancel={closeBulkConfirmationCancel}
                        countToChange={selectedIds.length}
                    />
                )
            }
        </Grid>
    )
}
