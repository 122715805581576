import {Box, ClickAwayListener, Fade, Paper, Popper} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import {ArrowDropDown} from "@material-ui/icons"
import {observable} from "mobx"
import {observer} from "mobx-react"
import React from "react"
import {AvantColors} from "../../styles/AvantColors"
import {theme} from "../../styles/MuiThemes"


export enum MenuButtonVariant {
    FLAT,
    OUTLINED
}

interface ReportsMenuIconButtonProps {
    title: React.ReactNode
    variant?: MenuButtonVariant
    style?: React.CSSProperties
    onClick?: () => void
    onMouseEnter?: () => void
}

@observer
export class AvantPopperButton extends React.Component<ReportsMenuIconButtonProps> {

    @observable
    open = false

    buttonRef?: HTMLButtonElement

    render() {
        const popOverId = "popper"
        const {title, style, children, onClick, onMouseEnter} = this.props
        let variant = this.props.variant
        if (variant == null) {
            variant = MenuButtonVariant.FLAT
        }
        return (
            <Box displayPrint={"none"}>
                <Button
                    aria-describedby={popOverId}
                    variant="outlined"
                    onClick={onClick ? onClick : () => this.open = !this.open}
                    onMouseEnter={onMouseEnter}
                    ref={(button: HTMLButtonElement) => this.buttonRef = button}
                    style={{
                        border: variant === MenuButtonVariant.FLAT ? "none" :
                            `1px solid ${this.open ? AvantColors.REPORT_BOX_BORDER_BLUE : AvantColors.REPORT_BOX_BORDER}`,
                        color: this.open ? AvantColors.PRIMARY_BLUE : AvantColors.REPORT_FONT_COLOR_GRAY_1,
                        backgroundColor: variant === MenuButtonVariant.FLAT ?
                            (this.open ? AvantColors.REPORT_BACKGROUND_BLUE : AvantColors.REPORT_BACKGROUND_GRAY_LIGHT) : "",
                        padding: theme.spacing(0.5),
                        margin: theme.spacing(0, 1),
                        height: 36,
                        textTransform: "none",
                        ...style
                    }}
                >
                    {title}
                    {!onClick && (
                        <ArrowDropDown
                            color={this.open ? "primary" : "action"}
                            style={{transform: this.open ? "scaleY(-1)" : "", transition: "transform 350ms"}}
                        />
                    )}
                </Button>
                {this.open && (
                    <ClickAwayListener onClickAway={() => this.open = false}>
                        <Popper
                            id={popOverId}
                            open={this.open}
                            anchorEl={this.buttonRef}
                            transition={true}
                            style={{zIndex: 2}}
                        >
                            {({TransitionProps}) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <Paper>
                                        {children}
                                    </Paper>
                                </Fade>
                            )}
                        </Popper>
                    </ClickAwayListener>
                )}
            </Box>
        )
    }
}
