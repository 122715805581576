import {parse} from "query-string"
// import * as queryString from "querystring"
import React, {useEffect} from "react"
import {loadingSpinnerStore} from "../../app/common/loaders/LoadingSpinnerStore"
import {RoutePaths} from "../../app/routes/Routes"
import ApiService from "../../services/ApiService"
import {theme} from "../../styles/MuiThemes"
import {ApiErrorResponse} from "../../types/types"
import {PasswordChecker} from "../PasswordChecker/PasswordChecker"
import FormGroup from "@material-ui/core/FormGroup"
import TextField from "@material-ui/core/TextField"
import {avantHistory} from "../App/App"
import {Button} from "@material-ui/core"
import {observer} from "mobx-react-lite"
import {MessageView} from "../../app/common/messages/MessageView"
import {messageStore} from "../../app/common/messages/MessageStore"
import {log} from "../../util/Logging"


const PASSWORD_IS_EMPTY: string = "You must enter a password."
const CONFIRM_PASSWORD_IS_EMPTY: string = "You must confirm your password."
const PASSWORDS_DO_NOT_MATCH: string = "Passwords do not match."

export const NewPassword: React.FC = observer(() => {

    const [curError, setCurError] = React.useState<string[]>([])
    const [newPassword, setNewPassword] = React.useState("")
    const [confirmPassword, setConfirmPassword] = React.useState("")
    const [token, setToken] = React.useState("")
    const [submitDisabled, setSubmitDisabled] = React.useState<boolean>(true)

    // TODO: Remove the below, as this is no longer used, and the password validation happens in the password checker component or make it so this code is used
    const getPasswordErrors = (): boolean => {
        let error = curError
        if (newPassword.length === 0) {
            if (!error.includes(PASSWORD_IS_EMPTY)) {
                error.push(PASSWORD_IS_EMPTY)
            }
        } else {
            if (error.includes(PASSWORD_IS_EMPTY)) {
                const position = error.indexOf(PASSWORD_IS_EMPTY)
                error.slice(position, 1)
            }
        }

        if (confirmPassword.length === 0) {
            if (!error.includes(CONFIRM_PASSWORD_IS_EMPTY)) {
                error.push(CONFIRM_PASSWORD_IS_EMPTY)
            }
        } else {
            if (error.includes(CONFIRM_PASSWORD_IS_EMPTY)) {
                const pos = error.indexOf(CONFIRM_PASSWORD_IS_EMPTY)
                error.slice(pos, 1)
            }
        }

        if (newPassword !== confirmPassword) {
            if (!error.includes(PASSWORDS_DO_NOT_MATCH)) {
                error.push(PASSWORDS_DO_NOT_MATCH)
            }
        } else {
            if (error.includes(PASSWORDS_DO_NOT_MATCH)) {
                const pos = error.indexOf(PASSWORDS_DO_NOT_MATCH)
                error.slice(pos, 1)
            }
        }

        let hasError: boolean = false

        if (error.length !== 0) {
            setCurError(error)
            hasError = true
        }
        return hasError
    }

    const wait = () => {
        setTimeout(()=> {
            avantHistory.push(RoutePaths.ADVANCE_LOGIN)
        }, 2000)
    }

    const waitNoRedirect = () => {
        setTimeout(()=> {
            log.debug("Not redirecting, cause that is annoying!")
        }, 2000)
    }

    const handleSubmit = (event: React.MouseEvent< HTMLButtonElement | MouseEvent> | React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (getPasswordErrors()) {
            return
        }

        ApiService.patchUserPassword(token, newPassword).then(
            () => {
                messageStore.setInfoMessage(`You have successfully changed your password!`)
                wait()
                avantHistory.push(RoutePaths.ADVANCE_LOGIN)
            },
            (error: ApiErrorResponse) => {

                const expiredToken = error.response.data.kind

                if (expiredToken === "Expired Token") {
                    messageStore.setErrorMessage("Im sorry, the link has expired. Please try again.")
                    wait()
                    return
                } else if (expiredToken === "Invalid Link") {
                    messageStore.setErrorMessage("Im sorry, the link used was invalid. Please try again")
                    wait()
                    return
                } else if (expiredToken === "Invalid User-Type") {
                    messageStore.setErrorMessage("Im sorry, you are not allowed to change the password for this login")
                    wait()
                    return
                }else if (expiredToken === "Password Reuse") {
                    messageStore.setErrorMessage("Im sorry, you have already used that password")
                    waitNoRedirect()
                    return
                }


                const errors: string[] = error.response.data.errors
                errors.forEach((err: string) => {
                    if (err !== "Oops, there was an error on this form.") {
                        messageStore.setInfoMessage("There was an error changing your password, please contact support.")
                    }
                })
            }
        )
    }

    useEffect(()=> {
        const params = parse(window.location.search)
        setToken(params.token!.toString())
        loadingSpinnerStore.hideLoadingSpinner = true
        if (token === undefined) {
            avantHistory.push(RoutePaths.LOGIN)
        }
        localStorage.clear()
    }, [token])
    const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value)
        submitDisabled
    }

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value)
        submitDisabled
    }

    return (
        <div className="login">
            <div>
                <div className="login__image-container">
                    <img
                        className="login__image-container__image"
                        alt="Avant Logo"
                        src="https://cdn.avantassessment.com/resources/common/img/avant-logo.svg"
                    />
                    <div className="login__image-container__text">Password Reset</div>
                </div>
                <div className="login__login-container">
                    <div className="login__login-container__top">
                        <div className="login__login-container__top__title">Enter a New Password</div>
                        {curError.length !== 0 && (
                            <div className="login__login-container__top__warning">
                                <div className="login__login-container__top__warning__text">{curError}</div>
                            </div>
                        )}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <div
                                className={
                                    curError.includes(PASSWORD_IS_EMPTY)
                                        ? "login__login-container__form-error"
                                        : ""
                                }
                            >
                                <FormGroup>
                                    <TextField
                                        label={"New Password"}
                                        type="password"
                                        value={newPassword}
                                        placeholder={""}
                                        data-tst-id={"new-password"}
                                        onChange={handleNewPasswordChange}
                                        variant={"outlined"}
                                        style={{margin: theme.spacing(2), width: 360}}
                                    />
                                </FormGroup>
                            </div>
                            <div
                                className={
                                    curError.includes(CONFIRM_PASSWORD_IS_EMPTY) ||
                                    curError.includes(PASSWORDS_DO_NOT_MATCH)
                                        ? "login__login-container__form-error"
                                        : ""
                                }
                            >
                                <FormGroup>
                                    <TextField
                                        label={"Confirm Password"}
                                        type={"password"}
                                        value={confirmPassword}
                                        placeholder={""}
                                        data-tst-id={"confirm-password"}
                                        onChange={handleConfirmPasswordChange}
                                        variant={"outlined"}
                                        style={{margin: theme.spacing(2), width: 360}}
                                    />
                                </FormGroup>
                            </div>
                            <PasswordChecker
                                password={newPassword}
                                confirmPassword={confirmPassword}
                                enableSubmit={setSubmitDisabled}
                            />
                            <Button
                                className="login__login-container__button avant-button--primary"
                                onClick={handleSubmit}
                                type={"submit"}
                                style={{marginBottom: theme.spacing(2)}}
                                variant={"contained"}
                                color={"primary"}
                                disabled={submitDisabled}
                            >
                                SUBMIT
                            </Button>
                        </div>
                    </form>
                </div>
            </div>

            <MessageView />

        </div>
    )
})
