import React from "react"

import {ProductDriver} from "../../app/common/products/ProductStore"
import {Section} from "../../app/section/models/Section"
import HelperService from "../../services/HelperService"
import {Answer, IItem, ItemContentTypeEnum, ItemFormatEnum, TLocalAnswer} from "../../types/types"
import Button from "../Button/Button"

interface ItemMenuState {
    localAnswer: TLocalAnswer | null
}

interface ItemMenuProps {
    section: Section
    itemIndex: number
    handleNavigationByIndex: (index: number) => void
    localAnswer: TLocalAnswer | null
    driver: ProductDriver
}

export default class ItemMenuContainer extends React.Component<ItemMenuProps, ItemMenuState> {
    constructor(props: ItemMenuProps) {
        super(props)
        this.state = {
            localAnswer: null
        }
    }

    componentDidUpdate() {
        if (this.state.localAnswer !== this.props.localAnswer) {
            this.setState({localAnswer: this.props.localAnswer})
        }
    }

    openItem = (index: number) => {
        this.props.handleNavigationByIndex(index)
    }

    renderMenuItem = (item: IItem, itemIndex: number) => {
        const section: Section = this.props.section
        const itemId: number = item.id
        const binName: string = item.binName
        const itemName: string = item.name
        const buttonClassName =
            itemIndex === this.props.itemIndex ? "item-menu__button item-menu__button--active" : "item-menu__button"

        const answer: Answer | undefined = section.responses.get(`${itemId}${binName}`)
        const circleClass = "circle-xs " + HelperService.getAnswerStatusClassName(section, answer)

        let menuItemTitle: string = "Question"
        if (section.config.videoOnlyItems && item.format === ItemFormatEnum.BLANK) {
            if (
                (item.itemContent1 !== undefined && item.itemContent1.type === ItemContentTypeEnum.VIDEO) ||
                (item.itemContent2 !== undefined && item.itemContent2.type === ItemContentTypeEnum.VIDEO) ||
                (item.itemContent3 !== undefined && item.itemContent3.type === ItemContentTypeEnum.VIDEO) ||
                (item.itemContent4 !== undefined && item.itemContent4.type === ItemContentTypeEnum.VIDEO)
            ) {
                menuItemTitle = "Video"
            } else if (item.itemContent1 && item.itemContent1.type === ItemContentTypeEnum.PDF) {
                menuItemTitle = "Work Sheet"
            }
        }

        return (
            <div key={"button-"+itemName} id={"button-"+itemName}>
                <Button className={buttonClassName} block={true} onClick={() => this.openItem(itemIndex)}>
                    <span className="item-menu__button__text">{`${menuItemTitle} ${itemIndex + 1}`}</span>
                    <span className={circleClass}/>
                </Button>
            </div>
        )
    }

    render() {
        const items: IItem[] = this.props.section.items
        const indexBreak = items.length > 12 ? Math.ceil(items.length / 2) : items.length
        return (
            <>
                {items.length - 1 >= indexBreak ? (
                    <div className="item-menu__container item-menu__container--columns">
                        <div>
                            {items.map((item: IItem, itemIndex: number) => {
                                if (itemIndex < indexBreak) {
                                    return this.renderMenuItem(item, itemIndex)
                                } else {
                                    return null
                                }
                            })}
                        </div>
                        <div>
                            {items.map((item: IItem, itemIndex: number) => {
                                if (itemIndex >= indexBreak) {
                                    return this.renderMenuItem(item, itemIndex)
                                } else {
                                    return null
                                }
                            })}
                        </div>
                    </div>
                ) : (
                    <div className="item-menu__container">
                        {items.map((item: IItem, itemIndex: number) => {
                            return this.renderMenuItem(item, itemIndex)
                        })}
                    </div>
                )}
            </>
        )
    }
}

