// frontend/src/services/NycDoeApi.ts
import {ApiErrorResponse, NycDoeUrlResponse} from "../types/types";
import ApiService from "./ApiService";
import axios, {AxiosResponse} from "axios";
import {NycDoeOauthResponse, SSONycDoeAuthedData} from "../app/nycdoe/NYCDOEUtils"

export default class NycDoeApi {
    static getNycDoeOAuthOIDCUrl(): Promise<NycDoeUrlResponse> {
        const url: string = `${ApiService.API_URL}getNYCDOESsoLoginLink`
        return new Promise<NycDoeUrlResponse>((resolve, reject): void => {
            ApiService.get(url).then(
                (res: AxiosResponse): void => {
                    console.log("Front End Valid Response /getNYCDOESsoLoginLink")
                    resolve(res.data)
                },
                (err: ApiErrorResponse): void => {
                    console.log("Front End BAD Response /getNYCDOESsoLoginLink")
                    reject(err)
                }
            )
        })
    }

    static getNycDoeOidcUserinfoAndJWT(url: string, nycOauthResponse: NycDoeOauthResponse): Promise<SSONycDoeAuthedData> {
        return axios.post<SSONycDoeAuthedData>(url, nycOauthResponse).then((response: AxiosResponse<SSONycDoeAuthedData>) => {
            return {
                sso: response.data.sso,
                loginId: response.data.loginId
            }
        }).catch(err => {
            throw err
        })
    }

}
