import { AxiosResponse } from "axios";
import { ApiErrorResponse } from "../types/types";
import ApiService from "./ApiService"

export interface PanelGraph {
    panelGraphId: number;
    name: string;
    disabled?: boolean
    id?: number | string
    oldPanelGraphs?: number
}
export interface UpdatePanelGraphs {
    oldPanelGraph: number | undefined;
    newPanelGraph: number;
}

interface PanelGraphsPayload {
    availablePanelGraphs: PanelGraph[];
    usersPanelGraphs: PanelGraph[];
}
 interface AdvanceLanguageResponse {
    message: string
    error: boolean
    errorDescription: string 
}
export default class AdvanceApi {

    static getAdvanceLanguages(email: string): Promise<PanelGraphsPayload | null> {
        const url: string = `${ApiService.API_URL}advance/panelgraphs/${email}`
        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static updateLanguages(UpdatePanelGraphs:UpdatePanelGraphs[] , email:string): Promise<AdvanceLanguageResponse> {
        const url: string = `${ApiService.API_URL}advance/update-language`

        return new Promise((resolve, reject) => {
            ApiService.post(url, {
                panelGraphs: UpdatePanelGraphs,
                email: email,
            }).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err) => {
                    reject(err)
                }
            )
        })
    }

}
