import {observer} from "mobx-react"
import React from "react"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"
import {isNullOrUndefined} from "util"
import {productStore} from "../../app/common/products/ProductStore"
import {Section} from "../../app/section/models/Section"
import {sectionStore} from "../../app/section/SectionStore"
import HelperService from "../../services/HelperService"
import {
    ApiErrorResponse,
    ApiPanelGraph,
    ApiTakePanelStateEnum,
    PanelSkillEnum,
    State,
    Take,
    TestState
} from "../../types/types"
import {PLACE_PRODUCT_ID, SHL_CONTENT_AREA_ID, SUPPORT_EMAIL, WORLDSPEAK_PRODUCT_ID} from "../../util/Constants"
import {PanelId, TakeId} from "../../validation/ValidPrimaryKey"
import {addTakeDispatches, TakeDispatches} from "../App/App"
import Button from "../Button/Button"
import {authStore} from "../../app/common/authentication/AuthStore";
import TestTakerAgreementModal from "./TestTakerAgreement";
import {ErrorText} from "../../styles/AvantTypography";

interface DashboardProps {
    section: Section | null
    take: Take
    takeCode: string
    testState: TestState
    panelGraph: ApiPanelGraph
}

function mapStateToProps(state: State): DashboardProps {
    return {
        section: state.item.currentSection,
        take: state.app.take!,
        takeCode: state.app.takeCode,
        testState: state.app.testState!,
        panelGraph: state.app.panelGraph!
    }
}

// tslint:disable-next-line

function mapDispatchToProps(dispatch: Dispatch): TakeDispatches {
    return addTakeDispatches({}, dispatch)
}

interface AutoStartDashboardProps extends DashboardProps, TakeDispatches, RouteComponentProps {}

interface AutoStartDashboardState {
    error: boolean | string
    loading: boolean
    take: Take
    showModal: boolean
    currentPanelId: number
    currentPanelName: string
    currentTestName: string
    loaded: boolean
    usesTestTakerAgreement: boolean
}

@observer
class AutoStartDashboard extends React.Component<AutoStartDashboardProps, AutoStartDashboardState> {
    state: AutoStartDashboardState

    constructor(props: AutoStartDashboardProps) {
        super(props)
        this.state = {
            error: false,
            loading: true,
            take: props.take,
            showModal: false,
            currentPanelId: -1,
            currentPanelName: "",
            currentTestName: "",
            loaded: false,
            usesTestTakerAgreement: false
        }
    }

    async componentDidMount() {
        HelperService.enableTextSelection()
        await this.getData()
        this.usesTestTakerAgreement()
    }
    async componentDidUpdate() {
        await  authStore.didTheyAgree()
        await this.getData()
    }

    getData = async () => {
        const driver = productStore.driver
        const product = productStore.loginProduct
        const {loaded} = this.state
        if (driver && !loaded) {
            this.setState({loaded: true})
            document.title = `${driver.config.PRODUCT_TITLE} | ${driver.strings.dashboard}`
            let lastPage = HelperService.getLastPageFromLs()
            if (lastPage) {
                try {
                    const testState: TestState = await driver.helper.refreshTest(
                        this.props,
                        lastPage,
                        product!
                    )
                    if (isNullOrUndefined(testState.take)) {
                        throw Error("testState.take is null or undefined")
                    }

                    this.startPanel(testState.take)
                    this.setState({take: testState.take, loading: false})
                } catch (error) {
                    if (this.state.error) {
                        // We already have a specific error saved, so we use that.
                    }
                    else {
                        // Set the general error for display.
                        this.setState({
                            error: driver.strings.formatString(
                                driver.strings.generalError,
                                SUPPORT_EMAIL
                            ) as string
                        })
                    }
                    if (error !== undefined) {
                        // @ts-ignore
                        throw new Error(`Failed to refresh test. ${error.response.data.error} - ${error.response.data.message}`)
                    } else {
                        throw new Error("Failed to refresh test. UNKNOWN ERROR")
                    }

                }
            } else {
                lastPage = {
                    url: "/auto-start-dashboard",
                    takeCode: this.props.takeCode,
                    panelGraphId: this.props.panelGraph.id
                }
                localStorage.setItem("lastPage", JSON.stringify(lastPage))
                this.startPanel(this.props.take)
            }
        }
    }

    startPanel = (take: Take) => {
        const driver = productStore.driver!!
        const skillToTestName: Map<string, string> = new Map([
            [PanelSkillEnum.WRITING, driver.strings.writing],
            [PanelSkillEnum.READING, driver.strings.reading],
            [PanelSkillEnum.LISTENING, driver.strings.listening],
            [PanelSkillEnum.SPEAKING, driver.strings.speaking],
            [PanelSkillEnum.GRAMMAR, driver.strings.grammar],
            [PanelSkillEnum.VOCABULARY, driver.strings.vocabulary],
            [PanelSkillEnum.DICTATION, driver.strings.dictation],
            [PanelSkillEnum.TRANSLATION, driver.strings.translation],
            [PanelSkillEnum.SENTENCE_COMPLETION, driver.strings.sentenceCompletion],
            [PanelSkillEnum.VERB_CONJUGATION, driver.strings.verbConjugation]
        ])

        let currentPanelId: number = -1
        if (!isNullOrUndefined(take.currentPanelId)) {
            currentPanelId = take.currentPanelId
        }
        let currentPanelName: string | undefined = ""
        for (const takePanel of take.takePanels) {
            if (takePanel.panelState === ApiTakePanelStateEnum.STARTED) {
                currentPanelId = takePanel.panel.id
                currentPanelName = skillToTestName.get(takePanel.panel.skill)
                if (currentPanelName === undefined) {
                    throw new Error("TakePanel's panel's skill is undefined.")
                }
                this.setState({currentPanelId, currentPanelName})
                break
            }
        }

        // TODO fix the hacky solution below so that it works like the rest of the products.
        if (take.availablePanels[0] && this.state.currentPanelName === "") {
            const tmpName = skillToTestName.get(take.availablePanels[0].skill)
            currentPanelName = tmpName ? tmpName : ""
            this.setState({currentPanelId, currentPanelName})
        }

        // We expect to have at most one available panel here:
        if (take.availablePanels.length > 1) {
            // More than one available panel indicates that the Take service was unable
            // to filter out panels (probably because of incomplete cando data):
            this.throwErrorTooManyPanels()
        }
        else if (take.availablePanels.length > 0) {
            sectionStore.findCurrentSection(new TakeId(take.id), new PanelId(currentPanelId))
                .then(
                    (section: Section) => {
                        section.config = productStore.driver!!.config.TEST_ENGINE_CONFIG
                        section.config.testName = this.state.currentPanelName
                        this.props.updateSectionDispatch(section)
                        this.redirectToTest()
                    },
                    (err: ApiErrorResponse) => {
                        this.setState({
                            error: driver.strings.formatString(
                                driver.strings.generalError,
                                SUPPORT_EMAIL
                            ) as string
                        })
                        throw new Error(
                            `Failed to get panel. ${err.response.data.error} - ${err.response.data.message}`
                        )
                    }
                )
                .catch((error: Error) => {
                    this.setState({
                        error: driver.strings.formatString(
                            driver.strings.generalError,
                            SUPPORT_EMAIL
                        ) as string
                    })
                    throw error
                })
        }
    }

    logout = () => {
        HelperService.logout(undefined)
    }

    redirectToTest = () => {
        if(!this.state.usesTestTakerAgreement || authStore.agreedToEULA) {
            const url: string = "/item"
            this.props.history.push(url)
        }
    }

    agreeToEULA = () => {
        authStore.agreeToEula()
        this.redirectToTest()

    }

    usesTestTakerAgreement = (): void => {
        const productId = productStore.driver ? productStore.driver.productId.value() : null
        const contentAreaId = productStore.loginProduct ? productStore.loginProduct.contentAreaId.valueOf() : null

        if (productId === WORLDSPEAK_PRODUCT_ID.value()) {
            this.setState({
                currentTestName: "WORLDSPEAK",
                usesTestTakerAgreement: true
            })
        }

        if (productId === PLACE_PRODUCT_ID.value()) {
            const testName = contentAreaId === SHL_CONTENT_AREA_ID.value() ? "PLACE/SHL" : "PLACE"

            this.setState({
                currentTestName: testName,
                usesTestTakerAgreement: true
            })
        }

    }

    // More than one available panel indicates that the Take service was unable
    // to filter out panels (probably because of incomplete cando data):
    throwErrorTooManyPanels = () => {
        const displayErrorMessage = "An error occurred when building your test. Please refresh the page."
        const consoleErrorMessage = `Too many available panels were found for takeid ` + this.state.take.id

        this.setState({
            error: displayErrorMessage,
            loading: false,
        })
        throw new Error(consoleErrorMessage)
    }

    render() {
        const {currentTestName, usesTestTakerAgreement} = this.state
        const driver = productStore.driver

        if (!driver) {
            return null
        }

        if (this.state.error) {
            // Early return with the error message:
            return (
                <div className="center-text">
                    <h2>Error:</h2>
                    <ErrorText>{this.state.error}</ErrorText>
                </div>
            )
        }

        return (
            <div className="dashboard">
                {this.state.take && this.state.take.availablePanels.length > 0 ? (
                    <div className="center-text" style={{fontWeight: "normal"}}>
                        <h2>{driver.strings.loading}</h2>
                        {this.state.loaded && usesTestTakerAgreement &&
                        <TestTakerAgreementModal
                            testName={currentTestName}
                            agree={() => this.agreeToEULA()}
                        />
                        }
                    </div>
                ) : (
                    <>
                        <p className="dashboard__title">{driver.strings.congratulationsFinished}</p>
                        <p className="dashboard__subtitle">{driver.strings.answersSaved}</p>
                        <div style={{textAlign: "center"}}>
                            <Button
                                type="button"
                                className="avant-button--primary dashboard__button"
                                onClick={this.logout}
                            >
                                {driver.strings.logout}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        )

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AutoStartDashboard)
