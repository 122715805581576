import React from "react"
import Dropzone from "react-dropzone"
import {ItemContent} from "../../app/common/item/models/ItemContent"
import ApiService from "../../services/ApiService"

import {ProductDriver} from "../../app/common/products/ProductStore"
import HelperService from "../../services/HelperService"
import {ApiUpdateItemFileResponse, IItem, VideoContent} from "../../types/types"
import {ItemContentId} from "../../validation/ValidPrimaryKey"
import Button from "../Button/Button"
import {default as VideoPlayer, IVideoPlayerSource} from "../VideoPlayer"

interface InstructionVideoProps {
    className: string
    item: IItem
    itemContent: ItemContent
    isAdmin: boolean
    driver: ProductDriver
    handleUpdateItemFile: (itemContentId: number, content: string | VideoContent) => void
}

interface InstructionVideoState {
    editingVideo: boolean
    videoName: string
    video?: File
}

export default class InstructionVideo extends React.Component<InstructionVideoProps, InstructionVideoState> {
    constructor(props: InstructionVideoProps) {
        super(props)
        this.state = {
            editingVideo: false,
            videoName: ""
        }
    }

    editVideo = (videoName: string) => {
        this.setState({editingVideo: true, videoName})
    }

    saveVideo = (itemContentId: number) => {
        const type = "video"
        if (this.state.video) {
            const contentDir = "common"
            ApiService
                .updateItemFile(new ItemContentId(itemContentId), this.state.video, contentDir, type)
                .then((updateItemFileResponse: ApiUpdateItemFileResponse) => {
                    const fileName: string = updateItemFileResponse.fileName
                    const fileUrl = HelperService.getFullMediaUrl(
                        contentDir,
                        type,
                        fileName,
                        this.props.driver.config.MEDIA_BASE_URL
                    )
                    const content: VideoContent = {
                        video: fileUrl,
                        poster: fileUrl
                    }
                    // TODO updateItemDescription

                    this.props.handleUpdateItemFile(itemContentId, content)
                    this.setState({editingVideo: false})
                })
        }
    }

    cancelVideoEdit = () => {
        this.setState({editingVideo: false, video: undefined})
    }

    onDropVideo = (videos: File[]) => {
        this.setState({video: videos[0]})
    }

    render() {
        const className = this.state.editingVideo
            ? `${this.props.className} drop-zone`
            : `${this.props.className} video-wrapper`
        const content: VideoContent = JSON.parse(this.props.itemContent.content as string)
        const sources: IVideoPlayerSource[] = [
            {
                src: content.video,
                type: "video/mp4"
            }
        ]

        return (
            <div key={this.props.itemContent.id} className={className}>
                {this.state.editingVideo ? (
                    <>
                        <input onChange={(event) => this.setState({videoName: event.target.value})} defaultValue={this.state.videoName} />
                        <Dropzone accept="video/mp4" onDrop={this.onDropVideo}>
                            <p className="drop-zone-text">Drop a mp4 here or click to select a mp4 to upload.</p>
                            {this.state.video && <p className="drop-zone-text">{this.state.video.name}</p>}
                        </Dropzone>
                        <Button
                            className="avant-button avant-button--blue-on-white avant-button--small margin-right"
                            onClick={this.cancelVideoEdit}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="avant-button avant-button--primary avant-button--small"
                            onClick={() => this.saveVideo(this.props.itemContent.id)}
                        >
                            Save
                        </Button>
                    </>
                ) : (
                    <>
                        {this.props.isAdmin ? (
                            <div>
                                <Button
                                    className="avant-button avant-button--primary avant-button--small"
                                    onClick={() => this.editVideo(this.props.item.description)}
                                >
                                    Edit
                                </Button>
                                <div className="center-text">
                                    <label>{this.props.item.description}</label>
                                </div>
                                <div className={className}>
                                    <VideoPlayer autoplay={true} controls={true} sources={sources} />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="center-text">
                                    <label>{this.props.item.description}</label>
                                </div>
                                <div className={className}>
                                    <VideoPlayer autoplay={true} controls={true} sources={sources} />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    }
}
