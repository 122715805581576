import {createStyles, withStyles, WithStyles} from "@material-ui/core"
import React from "react"

const styles = createStyles({
    button: {
        width: 170,
        height: 40,
        borderRadius: 4,
        fontWeight: "bold",
        fontSize: 16,
        backgroundColor: "#F9E6E8",
        color: "#D0021B",
        border: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 8,
        paddingRight: 8,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#f4d1d5"
        }
    }
})

type Props = {
    onClick: (event: any) => void
} & WithStyles<typeof styles>

const BeginVoiceRecordingButton = ({classes, onClick}: Props) => (
    <button type="button" className={classes.button} onClick={onClick} data-tst-id="BeginVoiceRecordingButton">
        <img alt="Begin Recording" src="https://cdn.avantassessment.com/resources/icons/icon-record.svg" />
        {"Begin Recording"}
    </button>
)

export default withStyles(styles)(BeginVoiceRecordingButton)
