/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {
    Box,
    // FormControlLabel,
    Grid,
    makeStyles,
    // Switch,
} from "@material-ui/core"
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import * as Sentry from "@sentry/browser"
import * as React from "react"
import { useEffect, useState } from "react"
import ProctorApi from "../../../../services/ProctorApi"
import { H2Text, H5Text, H6Text } from "../../../../styles/AvantTypography"
import { LoginDTO } from "../../../../types/types"
import { log } from "../../../../util/Logging"
import { TimeUtil } from "../../../../util/TimeUtil"
import { GroupInfo } from "../../../common/group/models/GroupInfo"
import { messageStore } from "../../../common/messages/MessageStore"
import { AvantButton } from "../../../generic-components/AvantButton"
import { AvantExpansionPanel } from "../../../generic-components/AvantExpansionPanel"
import { VerificientLogin } from "../../../roster/RosterSelectTake"
import { RosterDialog } from "./rostering/RosterDialog"
import { EmailRemindersDialog } from "./EmailRemindersDialog"
import { ReportGraphFilters } from "../../common/models/ReportGraphFilters"
import { reportsStore } from "../../common/stores/ReportStore"
import { PLACE_PRODUCT_ID, ProctorTypes } from "../../../../util/Constants";
import { UserType } from "../../../common/authentication/models/UserType"
import { productStore } from "../../../common/products/ProductStore"
import { CONTENT_AREA_ID_TO_LANGUAGE_NAME, STAMP_SIGN_LANGUAGE_PRODUCT_ID, APT_PRODUCT_ID } from "../../../../util/Constants"
import { PrintOptions } from "../../common/components/PrintViewContainer"
import { authStore } from "../../../common/authentication/AuthStore"


const useStyles = makeStyles(theme => ({
    label: {
        marginBottom: 4
    }
}))

interface ProctorControlsProps {
    groupInfo?: GroupInfo
    reportQuery?: ReportGraphFilters
}

// The below array comes from the RemoteProctorType found in Common Constants.kt, string must match
// TODO: Make sure the below array doesnt have issues since I changed it to use the enums
const DIY_PROCTOR_TYPES_ARRAY: string[] = [
    // "eduproctor", "avantproctor", "proctor-edu", "avant-proctor-edu"
    ProctorTypes.P360.valueOf(), ProctorTypes.EDUPROCTOR.valueOf(), ProctorTypes.AVANTPROCTOR.valueOf(), ProctorTypes.EDUPROCTOR.valueOf()
]

export interface TestControlsContainerProps extends ProctorControlsProps {
    printOptions?: PrintOptions
}

export const TestControlsContainer = ({ printOptions, groupInfo, reportQuery }: TestControlsContainerProps) => {
    if (printOptions) return <></>

    return (
        <Grid item xs={12}>
            <TestControls groupInfo={groupInfo} reportQuery={reportQuery} />
        </Grid>
    )
}
function buildRegistraionLink(loginId: number | undefined): string {
    if (loginId) {
        const url = new URL(`/avantproctor-registration/${loginId}`, window.location.href)
        return url.href
    } else {
        return "-"
    }
}
export const TestControls: React.FC<ProctorControlsProps> = (props) => {
    const classes = useStyles()
    const [proctoredLogins, setProctoredLogins] = useState<LoginDTO[]>([])
    const [rosterOpen, setRosterOpen] = useState(false)
    const [emailRemindersOpen, setEmailRemindersOpen] = useState(false)
    const [proctorURL, setProctorURL] = useState<string | undefined>(undefined)
    const [selectedLogin, setSelectedLogin] = useState<LoginDTO | undefined>(undefined)

    useEffect(() => {
        const allLoginsInTheGroup = props.groupInfo ? props.groupInfo.loginList : []
        const loggedinUserLanguage = authStore.auth && authStore.auth.userName && authStore.auth.userName.split("-")[2]
        const getProctoredLogins: LoginDTO[] = []
        allLoginsInTheGroup.map((login: LoginDTO) => {
            if (login.proctorType != null && DIY_PROCTOR_TYPES_ARRAY.includes(login.proctorType)) {
                const expirationDate = login.loginExpires ? TimeUtil.formatMilliToMonthDayYearLong(Number(login.loginExpires)) : null
                const passwordExpirationDate = login.passwordExpires ? TimeUtil.formatMilliToMonthDayYearLong(Number(login.passwordExpires)) : null
                // We should only push active test logins
                if (login.id && login.active
                    &&  (login.username && login.username.split("-")[2] === loggedinUserLanguage)
                    && (expirationDate === null || new Date(expirationDate) > new Date()) // login has not expired -- null means forever?
                    && (passwordExpirationDate === null || new Date(passwordExpirationDate) > new Date()) // password has not expired -- null means forever?
                ) {
                    getProctoredLogins.push(login)
                }
            }
        })

        // Client Rostering
        const currentLogin = productStore.loginProduct && productStore.loginProduct
        // Check if the current login has been given the correct permissions
        if (
            currentLogin &&
            // Only focusing on teacher logins for now...
            currentLogin.userType == UserType.T &&
            currentLogin.permissions &&
            currentLogin.permissions.find((permission: string) => permission == 'roster-test-takers')
        ) {
            // Get the testcode
            // The userName exists at this point.
            const testCodeArray = currentLogin.userName!.split("-")
            const testCode = testCodeArray.slice(0, testCodeArray.length - 1).join("-")
            const testCodeLogin = props.groupInfo && props.groupInfo.loginList.find((login: LoginDTO) => login.username == testCode)

            if (testCodeLogin && testCodeLogin.rostered) {
                const loginDTO: LoginDTO = {
                    // Use the testcode loginid for rostering
                    id: testCodeLogin.id,
                    contentAreaId: currentLogin.contentAreaId,
                    username: currentLogin.userName
                }

                getProctoredLogins.push(loginDTO)
            }
        }

        if (getProctoredLogins.length > 0) {
            // sort IN PLACE by username using a string compare method.
            getProctoredLogins.sort((a, b) => {
                const aSortBy = (a.username) ? a.username.toLocaleUpperCase() : ""
                const bSortBy = (b.username) ? b.username.toLocaleUpperCase() : ""
                return aSortBy.localeCompare(bSortBy)
            })
            setProctoredLogins([...getProctoredLogins])
        } else {
            setProctoredLogins([])
        }
    }, [])
    // const lockLabel = (state: boolean | undefined): string => {
    //     if (state) { // allow == true
    //         return"Unlocked"
    //     } else {
    //         return "Locked"
    //     }
    // }
    //
    // const handleToggleLock = async (loginId: number | undefined) => {
    //     if (!loginId) {return}
    //     const currentState = [...proctoredLogins]
    //     const index = currentState.findIndex(login => login.id === loginId)
    //     if (currentState[index].allowTest) {
    //         await ApiService.disallowTest(loginId)
    //         currentState[index].allowTest = false
    //     } else {
    //         await ApiService.allowTest(loginId)
    //         currentState[index].allowTest = true
    //     }
    //     setProctoredLogins([...currentState])
    // }

    // Used to generate and access proctor track proctoring
    // tslint:disable-next-line:max-line-length
    const verificientInstructorLogin = async (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>, loginId: number | undefined) => {
        event.preventDefault()
        if (!loginId) {
            return
        }

        // Below is what to do if avantproctor or eduproctor
        const currentState = [...proctoredLogins]
        const index = currentState.findIndex(login => login.id === loginId)
        if (index > -1) { // found
            const payload: VerificientLogin = {
                username: "",
                password: "",
                loginId,
                takeId: null
            }

            const login = currentState[index]

            log.info(`Login Proctor Type: ${login.proctorType}`)

            if (login.proctorType != null && DIY_PROCTOR_TYPES_ARRAY.includes(login.proctorType)) {
                await ProctorApi.verificientProctorLogin(payload)
                    .then((proctorLoginReturn) => {
                        const redirectUrl = proctorLoginReturn.redirectUrl
                        const studentRosterErrors = proctorLoginReturn.studentRosterErrors
                        currentState[index].allowTest = true
                        setProctoredLogins([...currentState])
                        setProctorURL(redirectUrl)
                        if (studentRosterErrors.length > 0) {
                            const errorMessage = `The following people could not be rostered:\n${studentRosterErrors.join("\n")}`
                            messageStore.setErrorMessage(errorMessage)
                        } else {
                            messageStore.setInfoMessage("Testing Ready for Remote Proctoring")
                        }
                    })
                    .catch((e) => {
                        messageStore.setErrorMessage(`${e.message} - Please contact Avant Support if this error continues.`)
                        Sentry.captureException(`TestControls|verificientInstructorLogin: ${e.message}`)
                    })
            } else if (login.proctorType === DIY_PROCTOR_TYPES_ARRAY[2] || login.proctorType === DIY_PROCTOR_TYPES_ARRAY[3]) {
                // TODO: This is where init and set up teacher proctor JWT and set up session with Proctor EDU
                log.info(`I am in the if for the call to the endpoint`)
            }

        }

    }

    const handleRosterStudents = (login: LoginDTO | undefined) => {
        setSelectedLogin(login)
        setRosterOpen(true)
    }

    const handleRosterClose = () => {
        setRosterOpen(false)
        reportsStore.findReportTableData(props.reportQuery!)
    }

    const handleEmailReminders = (login: LoginDTO | undefined) => {
        setSelectedLogin(login)
        setEmailRemindersOpen(true)
    }

    const handleEmailRemindersClose = () => {
        setEmailRemindersOpen(false)
        reportsStore.findReportTableData(props.reportQuery!)
    }
    const linkCancelOrCopy = (linkStr: string | null) => {
        if (linkStr !== null) {
            navigator.clipboard.writeText(linkStr).then(() => {
                messageStore.setMessage({ message: "Copied....", type: 1 })
            }
            )
        }
    }
    const renderSchedulingLink = (login: LoginDTO) => {
        const { schedulingLink } = login
        if (schedulingLink) {
            return (
                <>
                    <Grid style={{ margin: 5, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} item={true} sm={12} md={3}>
                        <H6Text className={classes.label}>Scheduling Link:</H6Text>
                        <a href={schedulingLink}>{schedulingLink} </a>
                    </Grid>
                    <Tooltip title="Copy link">
                        <FileCopyIcon style={{ color: "#007bff" }}
                            onClick={() => {
                                linkCancelOrCopy(schedulingLink)
                            }}
                        />
                    </Tooltip>
                </>
            )
        } else {
            return (
                <Grid item={true} xs={12}>
                    <H5Text style={{ lineHeight: "58px" }}>Pending Proctor Setup</H5Text>
                </Grid>
            )
        }
    }
    const renderRegistrationLink = (login: LoginDTO) => {
        const RegistrationLink = buildRegistraionLink(login.id)
        if (RegistrationLink) {
            return (
                <>
                    <Grid style={{ margin: 5, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} item={true} sm={12} md={3}>
                        <H6Text className={classes.label}>Self-Register Link:</H6Text>
                        <a href={RegistrationLink}>{RegistrationLink} </a>
                    </Grid>
                    <Tooltip title="Copy link">
                        <FileCopyIcon style={{ color: "#007bff", marginRight: "30px" }}
                            onClick={() => {
                                linkCancelOrCopy(RegistrationLink)
                            }}
                        />
                    </Tooltip>
                </>
            )
        } else {
            return (
                <Grid item={true} xs={12}>
                    <H5Text style={{ lineHeight: "58px" }}>Pending Proctor Setup</H5Text>
                </Grid>
            )
        }
    }

    return (
        <>
            {(proctoredLogins.length > 0) && (
                <>
                    <AvantExpansionPanel title={"Test Controls"} >
                        <div style={{ padding: "20px" }}>

                            {proctoredLogins.map((login, key) => (
                                <Grid container={true} key={login.id} style={{ height: "100%" }} alignItems={"center"} spacing={2}>
                                    <Grid item={true} sm={4} md={3}>
                                        <H2Text>
                                            {login.username}
                                            {(login.contentAreaId && CONTENT_AREA_ID_TO_LANGUAGE_NAME[login.contentAreaId]) ? ` (${CONTENT_AREA_ID_TO_LANGUAGE_NAME[login.contentAreaId]})` : ''}
                                        </H2Text>
                                    </Grid>

                                    {
                                        (login.proctorType === DIY_PROCTOR_TYPES_ARRAY[0] && productStore && productStore.driver && productStore.driver.productId.value() === PLACE_PRODUCT_ID.value())
                                            ?
                                            (!login.anyProctorScheduleIsAttached && login.proctorType === DIY_PROCTOR_TYPES_ARRAY[0] &&
                                                <Grid item={true} sm={4} md={3}>
                                                    <Box
                                                        display={"flex"}
                                                        justifyContent={"center"}
                                                        alignItems={"center"}
                                                    >
                                                        PENDING PROCTOR SETUP
                                                    </Box>
                                                </Grid>
                                            )
                                            :
                                            (login.proctorType !== DIY_PROCTOR_TYPES_ARRAY[0] ||
                                                (productStore && productStore.driver && productStore.driver.productId.value() !== STAMP_SIGN_LANGUAGE_PRODUCT_ID.value() &&
                                                    productStore && productStore.driver && productStore.driver.productId.value() !== APT_PRODUCT_ID.value())) &&
                                            (!login.anyProctorScheduleIsAttached && login.proctorType === DIY_PROCTOR_TYPES_ARRAY[0] &&
                                                <Grid item={true} sm={4} md={3}>
                                                    <Box
                                                        display={"flex"}
                                                        justifyContent={"center"}
                                                        alignItems={"center"}
                                                    >
                                                        PENDING PROCTOR SETUP
                                                    </Box>
                                                </Grid>
                                            )
                                    }
                                    <Grid item={true} sm={login.anyProctorScheduleIsAttached ? 8 : 4} md={login.anyProctorScheduleIsAttached ? 9 : 6} key={login.id}>
                                        <Grid container={true} alignItems={"center"}>
                                            {/*<Grid item={true} xs={3}>*/}
                                            {/*    <H5Text className={classes.label}>TEST ENTRY ACCESS:</H5Text>*/}
                                            {/*    <FormControlLabel*/}
                                            {/*        control={*/}
                                            {/*            <Switch*/}
                                            {/*                size="small"*/}
                                            {/*                checked={login.allowTest}*/}
                                            {/*                onChange={() => handleToggleLock(login.id)}*/}
                                            {/*            />*/}
                                            {/*        }*/}
                                            {/*        label={lockLabel(login.allowTest)}*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}

                                            {proctorURL &&
                                                <Grid item={true} xs={2}>
                                                    <Grid container={true} direction="row">
                                                        <Grid item={true} xs={12}>
                                                            <a href={proctorURL} target="_blank">Open Proctor System</a>
                                                        </Grid>
                                                        <Grid item={true} xs={12}>
                                                            <a
                                                                href="#"
                                                                onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                                                                    verificientInstructorLogin(e, login.id)}
                                                            >
                                                                Reload Students for Proctoring
                                                            </a>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                            {!(login.proctorType === DIY_PROCTOR_TYPES_ARRAY[0] && (
                                                productStore.driver!!.productId.value() === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value() ||
                                                productStore.driver!!.productId.value() === APT_PRODUCT_ID.value() ||
                                                productStore.driver!!.productId.value() === PLACE_PRODUCT_ID.value())) && (

                                                    <Grid item={true} sm={12} md={6} style={{ maxWidth: 350, margin: 5 }}>
                                                        <Grid container={true} alignItems={"center"}>
                                                            <AvantButton
                                                                style={{ marginRight: 10 }}
                                                                onClick={() => handleRosterStudents(login)}
                                                            >
                                                                Roster Test Takers
                                                            </AvantButton>

                                                            {login.schedulingLink && login.anyProctorScheduleIsAttached &&
                                                                <AvantButton
                                                                    style={{ marginRight: 10 }}
                                                                    onClick={() => handleEmailReminders(login)}
                                                                >
                                                                    Email Reminders
                                                                </AvantButton>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            {login.proctorType == DIY_PROCTOR_TYPES_ARRAY[0] && renderRegistrationLink(login)}
                                            {login.anyProctorScheduleIsAttached && login.proctorType == DIY_PROCTOR_TYPES_ARRAY[0] && renderSchedulingLink(login)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </div>
                    </AvantExpansionPanel>
                    <RosterDialog
                        login={selectedLogin}
                        open={rosterOpen}
                        handleRosterClose={handleRosterClose}
                    />
                    <EmailRemindersDialog
                        login={selectedLogin}
                        open={emailRemindersOpen}
                        handleEmailRemindersClose={handleEmailRemindersClose}
                    />
                </>
            )}
        </>
    )
}
