/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import * as Sentry from "@sentry/browser"
import {observer} from "mobx-react"
import React from "react"
import {RouteComponentProps} from "react-router"
import ReactTable from "react-table"
import ApiService from "../../services/ApiService"
import {ApiSampleTestLogins} from "../../types/types"
import {CONTENT_AREA_ID_TO_LANGUAGE_NAME, PRODUCT_ID_TO_NAME} from "../../util/Constants"


type TComponentProps = RouteComponentProps<any>

interface IComponentState {
    error: boolean | string
    apiResponse?: ApiSampleTestLogins[]
}

@observer
export class SampleTestLink extends React.Component<TComponentProps, IComponentState> {

    constructor(props: TComponentProps) {
        super(props)
        this.state = {
            error: false
        }
    }

    componentDidMount() {

        try {
            this.findSampleData()
        } catch (err) {
            Sentry.captureException(err)
            this.setState({error: true})
        }

    }

    findSampleData = async () => {

        const apiResponse = await ApiService.getSampleTestData()
        this.createData(apiResponse)
    }


    createData = (apiResponse: ApiSampleTestLogins[]) => {

        for (const data of apiResponse) {
            data.languageString = CONTENT_AREA_ID_TO_LANGUAGE_NAME[data.contentAreaId]
            data.productString = PRODUCT_ID_TO_NAME[data.productId]

            // TODO: If a new password is added make sure to update it in the API AdminToolsController.kt
            const pass = data.password

            data.url = `https://app.avantassessment.com/sample?username=${data.username}&password=${pass}&loginId=${data.loginId}`

            if (!pass) {
                // Certain sample logins do not have a password of `samplesample` or `samplesample1`.
                // In this case the password comes back as empty.
                data.url = `Incorrect password set for login: ${data.loginId}`
            }
        }

        const completeData = apiResponse.filter((resp) => resp.productId !== 8)

        this.setState({apiResponse: completeData})

    }

    renderSampleTestLinks = () => {
        const columns = [
            {Header: "Product", accessor: "productString", width: 100},
            {Header: "Language", accessor: "languageString", width: 200},
            {Header: "Link", accessor: "url"}
        ]


        const data = this.state.apiResponse

        return (
            <ReactTable
                columns={columns}
                data={data}
                defaultPageSize={50}
                data-tst-id="test-with-links-table"
            />
        )

    }


    render() {
        return this.renderSampleTestLinks()

    }
}

