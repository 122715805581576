import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core"
import Button from "@material-ui/core/Button/Button"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup/FormGroup"
import Grid from "@material-ui/core/Grid/Grid"
import Paper from "@material-ui/core/Paper/Paper"
import Typography from "@material-ui/core/Typography/Typography"
import {observable} from "mobx"
import {observer} from "mobx-react"
import moment from "moment-timezone"
import React from "react"
import P360ApiService, {ScheduleProctorTimeSlotError} from "../../services/P360ApiService"
import {theme} from "../../styles/MuiThemes"
import {P360ScheduleStudentPayload, TFormSubmit} from "../../types/types"
import {SUPPORT_EMAIL} from "../../util/Constants"
import {validateEmail} from "../../util/validators"
import {messageStore} from "../common/messages/MessageStore"
import {RosterState, rosterStore} from "./RosterStore"
import {RosterTextField} from "./RosterTextField"


interface RosterProps {
    loginId: string,
    verifyLoginResults?: string,
    proctorTimeSlots: ScheduleTime[]
}

interface ProctorFormState {
    testTakerData?: P360ScheduleStudentPayload
}

interface InitialFormProps {
    store: Store,
    loginId: string,
    proctorTimeSlots: ScheduleTime[]
    updateTestTakerData: (data: P360ScheduleStudentPayload) => void
}

interface ScheduleTime {
    slotId: number,
    examCalendarId: number,
    time: string,
    totalSeats: number,
    availableSeats: number
    scheduleId: number | undefined
    avantScheduleId: string
}

interface CompletedProps {
    store: Store
    testTakerData?: P360ScheduleStudentPayload
}

interface SlotData {
    slotId?: number,
    examCalendarId?: number,
    estDate: string,
    scheduleId: string
}

interface State {
    slotData: SlotData,
    timezone: string
}

export class Store {
    @observable
    studentEmail = ""
    @observable
    confirmStudentEmail = ""
    @observable
    invalidStudentEmail = false
    @observable
    studentEmailsDontMatch = false

    @observable
    firstName = ""
    @observable
    firstNameHasError = ""
    @observable
    lastName = ""
    @observable
    lastNameHasError = ""

    @observable
    invalidTimezone = false
    @observable
    invalidTimeSlot = false
}

@observer
export class ScheduleProctorForm extends React.Component<RosterProps, ProctorFormState> {
    store = new Store()

    updateTestTakerData = (data: P360ScheduleStudentPayload) => {
        this.setState({
            testTakerData: data
        }, () => { rosterStore.rosterState = RosterState.COMPLETED })
    }

    render() {
        let form =
            <InitialForm
                store={this.store}
                loginId={this.props.loginId}
                proctorTimeSlots={this.props.proctorTimeSlots}
                updateTestTakerData={this.updateTestTakerData}
            />
        if (rosterStore.rosterState === RosterState.IN_PROGRESS) {
            form = <InProgress/>
        } else if (rosterStore.rosterState === RosterState.COMPLETED) {
            form = <CompletedMessage store={this.store} testTakerData={this.state.testTakerData} />
        } else if (rosterStore.rosterState === RosterState.ERROR) {
            form = <ServerErrorMessage message={rosterStore.rosteringError}/>
        }

        return (
            <>
                <LoginErrorMessage verifyLoginResults={this.props.verifyLoginResults}/>
                {form}
            </>
        )
    }
}

const LoginErrorMessage = (props: { verifyLoginResults?: string }): JSX.Element => {
    const {verifyLoginResults} = props
    let Element = <></>

    if (props.verifyLoginResults) {
        Element = (
            <div className="login__login-container__top__warning" style={{width: 780, marginBottom: 32, textAlign: "center"}}>
                <p className="login__login-container__top__warning__text">{verifyLoginResults}</p>
                <p className="login__login-container__top__warning__text">Please contact our Support team by visiting:
                    <a href={SUPPORT_EMAIL}>{`${SUPPORT_EMAIL}`}</a>
                </p>
            </div>

        )
    }

    return Element
}
export const CompletedMessage: React.FC<CompletedProps>  = (props) => {
    const {store, testTakerData} = props
    const scheduleDate = testTakerData ? moment(testTakerData.selectedScheduleDate).format("MMMM, Do YYYY, h:mm A") : null

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", margin: theme.spacing(2), maxWidth: "960px", textAlign: "center"}}>
            <div style={{display: "flex", marginRight: theme.spacing(1), marginTop: theme.spacing(1)}}>
            <Typography>
                You have successfully been registered for an online remotely proctored test on {scheduleDate}. An email will be sent to
                your <strong>{store.studentEmail}</strong> inbox.  Please remember to check your spam or junk email folder if you
                do not find the email in your inbox.
            </Typography>
            </div>
            <div style={{display: "flex", marginRight: theme.spacing(1), marginTop: theme.spacing(1)}}>
                <Typography>
                    If you do not receive one, please contact our support by visiting:
                </Typography>
            </div>
            <div>
                <a href={SUPPORT_EMAIL}>{`${SUPPORT_EMAIL}`}</a>
            </div>
        </div>
    )
}

export const ServerErrorMessage = (props: {message: string}) => {
    return (
        <p style={{maxWidth: 960}}>
            {`A problem occurred while trying to register. ${props.message} If problem persists please contact Avant support at `}
            <a href={SUPPORT_EMAIL}>{`${SUPPORT_EMAIL}`}</a>
        </p>
    )
}

export const RosterCheckbox = (props: { message: React.ReactNode, isChecked: boolean, onToggle: () => void }) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={props.isChecked}
                    onChange={props.onToggle}
                />
            }
            label={props.message}
        />
    )

}

@observer
export class InitialForm extends React.Component <InitialFormProps, State> {

    constructor(props: Readonly<InitialFormProps>) {
        super(props)

        this.state = {
            slotData: {
                slotId: undefined,
                examCalendarId: undefined,
                estDate: "",
                scheduleId: ""
            },
            timezone: moment.tz.guess(),
        }
    }

    updateSlotData = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        const slot = this.props.proctorTimeSlots.find(timeSlot => timeSlot.time === event.target.value)

        if (slot) {
            this.setState({
                slotData: {
                    slotId: slot.slotId,
                    examCalendarId: slot.examCalendarId,
                    estDate: slot.time,
                    scheduleId: slot.avantScheduleId
                }
            })
        }
    }

    setTimezone = (event: any) => {
        this.setState({timezone: event.target.value})
    }


    submitRoster = (event: TFormSubmit) => {
        event.preventDefault()

        const {store, updateTestTakerData} = this.props
        const {slotData} = this.state
        const invalidNameMessage = (whichName: string) => `${whichName} cannot be empty.`

        this.validateEmails()

        if (this.emailsHaveError()) {
            return
        }

        const validFirstName = store.firstName.trim().length !== 0
        if (!validFirstName) {
            store.firstNameHasError = invalidNameMessage("First name")
            return
        }
        const validLastName = store.lastName.trim().length !== 0
        if (!validLastName) {
            store.lastNameHasError = invalidNameMessage("Last name")
            return
        }

        if (this.state.slotData.estDate.length < 1) {
            // TODO: Need to set an error in this case, as it hides the reason for the submit not working
            return
        }

        if (!slotData.slotId || !slotData.examCalendarId) {
            // TODO: Need to set an error in this case, as it hides the reason for the submit not working
            return
        }

        const payload: P360ScheduleStudentPayload = {
            firstName: store.firstName,
            lastName: store.lastName,
            email: store.studentEmail.trim(),
            selectedScheduleDate: slotData.estDate,
            selectedTimezone: this.state.timezone,
            slotId: slotData.slotId,
            examCalendarId: slotData.examCalendarId,
            scheduleId: slotData.scheduleId
        }

        P360ApiService.scheduleProctorTimeslot(this.props.loginId, payload)
            .then((response) => {
                updateTestTakerData(response.data)
            })
            .catch((error: ScheduleProctorTimeSlotError) => {
                const kind: string = error.responseData.kind
                const errorMsg: string = error.responseData.errors[0]

                // Add error highlighting on fields
                if (kind === "Invalid Email") {
                   store.invalidStudentEmail = true
                }

                if (kind === "Bad Date") {
                    store.invalidTimeSlot = true
                }

                if (kind === "Validation") {
                    if (errorMsg.includes("Missing first name")) {
                        store.firstNameHasError = "Missing first name"
                    } else if (errorMsg.includes("Invalid first name")) {
                        store.firstNameHasError = "Invalid first name"
                    }

                    if (errorMsg.includes("Missing last name")) {
                        store.lastNameHasError = "Missing last name"
                    } else if (errorMsg.includes("Invalid last name")) {
                        store.lastNameHasError = "Invalid last name"
                    }

                    if (errorMsg.includes("Missing timezone") || errorMsg.includes("Invalid timezone")) {
                        store.invalidTimezone = true
                    }
                }

                messageStore.setErrorMessage(errorMsg)
            })
    }

    emailsHaveError = () => {
        let isError = false
        if (this.props.store.invalidStudentEmail || this.props.store.studentEmailsDontMatch) {
            isError = true
        }
        return isError
    }

    validateEmails = () => {
        const {store} = this.props

        const trimmedStudentEmail = store.studentEmail.trim()
        const trimmedConfirmStudentEmail = store.confirmStudentEmail.trim()
        store.studentEmailsDontMatch = trimmedStudentEmail !== trimmedConfirmStudentEmail
        store.invalidStudentEmail = !validateEmail(trimmedStudentEmail)
    }

    fixEmailErrors = () => {
        if (this.emailsHaveError()) {
            this.validateEmails()
        }
    }

    shouldRegistrationBeEnabled = () => {
        const {store} = this.props

        const generalCheck =
            // Left commented for the future use
            // store.hasHeadset &&
            // store.hasId &&
            // store.hasWebCam &&
            // store.hasValidComputer &&
            // store.canTakeAlone &&
            // store.reviewedTechCheck &&
            // store.completedSampleTest &&
            // store.reviewedTestTime &&
            // store.reviewedRemoteTestTakerGuide &&
            this.state.slotData.estDate.length > 0 &&
            store.studentEmail.trim().length > 0 &&
            store.confirmStudentEmail.trim().length > 0 &&
            store.firstName.trim().length > 0 &&
            store.lastName.trim().length > 0 &&
            !this.emailsHaveError()

        return generalCheck

    }

    render() {
        const {store} = this.props
        const registrationEnabled = this.shouldRegistrationBeEnabled()
        const timeSlots = this.props.proctorTimeSlots
        const timezones =
            [
                "Europe/Andorra", "Asia/Dubai", "Asia/Kabul", "America/Antigua", "America/Anguilla",
                "Europe/Tirane", "Asia/Yerevan", "Africa/Luanda", "Antarctica/McMurdo", "Antarctica/Casey",
                "Antarctica/Davis", "Antarctica/DumontDUrville", "Antarctica/Mawson", "Antarctica/Palmer",
                "Antarctica/Rothera", "Antarctica/Syowa", "Antarctica/Troll", "Antarctica/Vostok",
                "America/Argentina/Buenos_Aires", "America/Argentina/Cordoba", "America/Argentina/Salta",
                "America/Argentina/Jujuy", "America/Argentina/Tucuman", "America/Argentina/Catamarca",
                "America/Argentina/La_Rioja", "America/Argentina/San_Juan", "America/Argentina/Mendoza",
                "America/Argentina/San_Luis", "America/Argentina/Rio_Gallegos", "America/Argentina/Ushuaia",
                "Pacific/Pago_Pago", "Europe/Vienna", "Australia/Lord_Howe", "Antarctica/Macquarie", "Australia/Hobart",
                "Australia/Currie", "Australia/Melbourne", "Australia/Sydney", "Australia/Broken_Hill",
                "Australia/Brisbane", "Australia/Lindeman", "Australia/Adelaide", "Australia/Darwin",
                "Australia/Perth", "Australia/Eucla", "America/Aruba", "Europe/Mariehamn", "Asia/Baku", "Europe/Sarajevo",
                "America/Barbados", "Asia/Dhaka", "Europe/Brussels", "Africa/Ouagadougou", "Europe/Sofia",
                "Asia/Bahrain", "Africa/Bujumbura", "Africa/Porto-Novo", "America/St_Barthelemy", "Atlantic/Bermuda",
                "Asia/Brunei", "America/La_Paz", "America/Kralendijk", "America/Noronha", "America/Belem",
                "America/Fortaleza", "America/Recife", "America/Araguaina", "America/Maceio", "America/Bahia",
                "America/Sao_Paulo", "America/Campo_Grande", "America/Cuiaba", "America/Santarem", "America/Porto_Velho",
                "America/Boa_Vista", "America/Manaus", "America/Eirunepe", "America/Rio_Branco", "America/Nassau",
                "Asia/Thimphu", "Africa/Gaborone", "Europe/Minsk", "America/Belize", "America/St_Johns",
                "America/Halifax", "America/Glace_Bay", "America/Moncton", "America/Goose_Bay", "America/Blanc-Sablon",
                "America/Toronto", "America/Nipigon", "America/Thunder_Bay", "America/Iqaluit", "America/Pangnirtung",
                "America/Atikokan", "America/Winnipeg", "America/Rainy_River", "America/Resolute", "America/Rankin_Inlet",
                "America/Regina", "America/Swift_Current", "America/Edmonton", "America/Cambridge_Bay", "America/Yellowknife",
                "America/Inuvik", "America/Creston", "America/Dawson_Creek", "America/Fort_Nelson", "America/Vancouver",
                "America/Whitehorse", "America/Dawson", "Indian/Cocos", "Africa/Kinshasa", "Africa/Lubumbashi",
                "Africa/Bangui", "Africa/Brazzaville", "Europe/Zurich", "Africa/Abidjan", "Pacific/Rarotonga",
                "America/Santiago", "America/Punta_Arenas", "Pacific/Easter", "Africa/Douala", "Asia/Shanghai",
                "Asia/Urumqi", "America/Bogota", "America/Costa_Rica", "America/Havana", "Atlantic/Cape_Verde",
                "America/Curacao", "Indian/Christmas", "Asia/Nicosia", "Asia/Famagusta", "Europe/Prague",
                "Europe/Berlin", "Europe/Busingen", "Africa/Djibouti", "Europe/Copenhagen", "America/Dominica",
                "America/Santo_Domingo", "Africa/Algiers", "America/Guayaquil", "Pacific/Galapagos", "Europe/Tallinn",
                "Africa/Cairo", "Africa/El_Aaiun", "Africa/Asmara", "Europe/Madrid", "Africa/Ceuta",
                "Atlantic/Canary", "Africa/Addis_Ababa", "Europe/Helsinki", "Pacific/Fiji", "Atlantic/Stanley",
                "Pacific/Chuuk", "Pacific/Pohnpei", "Pacific/Kosrae", "Atlantic/Faroe", "Europe/Paris",
                "Africa/Libreville", "Europe/London", "America/Grenada", "Asia/Tbilisi", "America/Cayenne",
                "Europe/Guernsey", "Africa/Accra", "Europe/Gibraltar", "America/Godthab", "America/Danmarkshavn",
                "America/Scoresbysund", "America/Thule", "Africa/Banjul", "Africa/Conakry", "America/Guadeloupe",
                "Africa/Malabo", "Europe/Athens", "Atlantic/South_Georgia", "America/Guatemala", "Pacific/Guam",
                "Africa/Bissau", "America/Guyana", "Asia/Hong_Kong", "America/Tegucigalpa", "Europe/Zagreb",
                "America/Port-au-Prince", "Europe/Budapest", "Asia/Jakarta", "Asia/Pontianak", "Asia/Makassar",
                "Asia/Jayapura", "Europe/Dublin", "Asia/Jerusalem", "Europe/Isle_of_Man", "Asia/Kolkata",
                "Indian/Chagos", "Asia/Baghdad", "Asia/Tehran", "Atlantic/Reykjavik", "Europe/Rome",
                "Europe/Jersey", "America/Jamaica", "Asia/Amman", "Asia/Tokyo",
                "Africa/Nairobi", "Asia/Bishkek", "Asia/Phnom_Penh", "Pacific/Tarawa", "Pacific/Enderbury",
                "Pacific/Kiritimati", "Indian/Comoro", "America/St_Kitts", "Asia/Pyongyang", "Asia/Seoul",
                "Asia/Kuwait", "America/Cayman", "Asia/Almaty", "Asia/Qyzylorda", "Asia/Qostanay",
                "Asia/Aqtobe", "Asia/Aqtau", "Asia/Atyrau", "Asia/Oral", "Asia/Vientiane",
                "Asia/Beirut", "America/St_Lucia", "Europe/Vaduz", "Asia/Colombo", "Africa/Monrovia",
                "Africa/Maseru", "Europe/Vilnius", "Europe/Luxembourg", "Europe/Riga", "Africa/Tripoli",
                "Africa/Casablanca", "Europe/Monaco", "Europe/Chisinau", "Europe/Podgorica", "America/Marigot",
                "Indian/Antananarivo", "Pacific/Majuro", "Pacific/Kwajalein", "Europe/Skopje", "Africa/Bamako",
                "Asia/Yangon", "Asia/Ulaanbaatar", "Asia/Hovd", "Asia/Choibalsan", "Asia/Macau",
                "Pacific/Saipan", "America/Martinique", "Africa/Nouakchott", "America/Montserrat", "Europe/Malta",
                "Indian/Mauritius", "Indian/Maldives", "Africa/Blantyre", "America/Mexico_City", "America/Cancun",
                "America/Merida", "America/Monterrey", "America/Matamoros", "America/Mazatlan", "America/Chihuahua",
                "America/Ojinaga", "America/Hermosillo", "America/Tijuana", "America/Bahia_Banderas", "Asia/Kuala_Lumpur",
                "Asia/Kuching", "Africa/Maputo", "Africa/Windhoek", "Pacific/Noumea", "Africa/Niamey",
                "Pacific/Norfolk", "Africa/Lagos", "America/Managua", "Europe/Amsterdam", "Europe/Oslo",
                "Asia/Kathmandu", "Pacific/Nauru", "Pacific/Niue", "Pacific/Auckland", "Pacific/Chatham",
                "Asia/Muscat", "America/Panama", "America/Lima", "Pacific/Tahiti", "Pacific/Marquesas",
                "Pacific/Gambier", "Pacific/Port_Moresby", "Pacific/Bougainville", "Asia/Manila", "Asia/Karachi",
                "Europe/Warsaw", "America/Miquelon", "Pacific/Pitcairn", "America/Puerto_Rico", "Asia/Gaza",
                "Asia/Hebron", "Europe/Lisbon", "Atlantic/Madeira", "Atlantic/Azores", "Pacific/Palau",
                "America/Asuncion", "Asia/Qatar", "Indian/Reunion", "Europe/Bucharest", "Europe/Belgrade",
                "Europe/Kaliningrad", "Europe/Moscow", "Europe/Simferopol", "Europe/Kirov", "Europe/Astrakhan",
                "Europe/Volgograd", "Europe/Saratov", "Europe/Ulyanovsk", "Europe/Samara", "Asia/Yekaterinburg",
                "Asia/Omsk", "Asia/Novosibirsk", "Asia/Barnaul", "Asia/Tomsk", "Asia/Novokuznetsk",
                "Asia/Krasnoyarsk", "Asia/Irkutsk", "Asia/Chita", "Asia/Yakutsk", "Asia/Khandyga",
                "Asia/Vladivostok", "Asia/Ust-Nera", "Asia/Magadan", "Asia/Sakhalin", "Asia/Srednekolymsk",
                "Asia/Kamchatka", "Asia/Anadyr", "Africa/Kigali", "Asia/Riyadh", "Pacific/Guadalcanal",
                "Indian/Mahe", "Africa/Khartoum", "Europe/Stockholm", "Asia/Singapore", "Atlantic/St_Helena",
                "Europe/Ljubljana", "Arctic/Longyearbyen", "Europe/Bratislava", "Africa/Freetown", "Europe/San_Marino",
                "Africa/Dakar", "Africa/Mogadishu", "America/Paramaribo", "Africa/Juba", "Africa/Sao_Tome",
                "America/El_Salvador", "America/Lower_Princes", "Asia/Damascus", "Africa/Mbabane", "America/Grand_Turk",
                "Africa/Ndjamena", "Indian/Kerguelen", "Africa/Lome", "Asia/Bangkok", "Asia/Dushanbe",
                "Pacific/Fakaofo", "Asia/Dili", "Asia/Ashgabat", "Africa/Tunis", "Pacific/Tongatapu",
                "Europe/Istanbul", "America/Port_of_Spain", "Pacific/Funafuti", "Asia/Taipei", "Africa/Dar_es_Salaam",
                "Europe/Kiev", "Europe/Uzhgorod", "Europe/Zaporozhye", "Africa/Kampala", "Pacific/Midway",
                "Pacific/Wake", "America/New_York", "America/Detroit", "America/Kentucky/Louisville", "America/Kentucky/Monticello",
                "America/Indiana/Indianapolis", "America/Indiana/Vincennes", "America/Indiana/Winamac", "America/Indiana/Marengo", "America/Indiana/Petersburg",
                "America/Indiana/Vevay", "America/Chicago", "America/Indiana/Tell_City", "America/Indiana/Knox", "America/Menominee",
                "America/North_Dakota/Center", "America/North_Dakota/New_Salem", "America/North_Dakota/Beulah", "America/Denver", "America/Boise",
                "America/Phoenix", "America/Los_Angeles", "America/Anchorage", "America/Juneau", "America/Sitka",
                "America/Metlakatla", "America/Yakutat", "America/Nome", "America/Adak", "Pacific/Honolulu",
                "America/Montevideo", "Asia/Samarkand", "Asia/Tashkent", "Europe/Vatican", "America/St_Vincent",
                "America/Caracas", "America/Tortola", "America/St_Thomas", "Asia/Ho_Chi_Minh", "Pacific/Efate",
                "Pacific/Wallis", "Pacific/Apia", "Asia/Aden", "Indian/Mayotte", "Africa/Johannesburg",
                "Africa/Lusaka", "Africa/Harare"].sort()

        return (
            <Paper style={{width: 780}}>
                <form
                    style={{display: "flex", flexDirection: "column", alignItems: "center", margin: 16}}
                    onSubmit={this.submitRoster}
                >
                    <FormGroup style={{marginBottom: 16}}>
                        <Grid container={true} spacing={2}>
                            <Grid item={true} xs={12} sm={6}>
                                <RosterTextField
                                    label={"Test Taker Email"}
                                    value={store.studentEmail}
                                    errorMessage={store.studentEmailsDontMatch ? "Please make sure the emails match." : (
                                        store.invalidStudentEmail ? "Email is invalid." : ""
                                    )}
                                    onChange={(event) => {
                                        store.studentEmail = event.target.value
                                        this.fixEmailErrors()
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <RosterTextField
                                    label={"Confirm Test Taker Email"}
                                    value={store.confirmStudentEmail}
                                    errorMessage={store.studentEmailsDontMatch ? "Please make sure the emails match." : (
                                        store.invalidStudentEmail ? "Email is invalid." : ""
                                    )}
                                    onChange={(event) => {
                                        store.confirmStudentEmail = event.target.value
                                        this.fixEmailErrors()
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <RosterTextField
                                    label={"First Name (Same as photo ID)"}
                                    value={store.firstName}
                                    errorMessage={store.firstNameHasError}
                                    onChange={(event) => store.firstName = event.target.value}
                                />
                            </Grid>

                            <Grid item={true} xs={12} sm={6}>
                                <RosterTextField
                                    label={"Last Name (Same as photo ID)"}
                                    value={store.lastName}
                                    errorMessage={store.lastNameHasError}
                                    onChange={(event) => store.lastName = event.target.value}
                                />
                            </Grid>

                            <Grid item={true} xs={12} style={{marginTop: 15}}>
                                <Typography variant={"h4"}>Schedule your test</Typography>
                                <Typography variant={"body1"}>Select a time to take your proctored exam</Typography>
                            </Grid>

                            <Grid item={true} xs={12}>
                                <FormControl variant="outlined" fullWidth={true}>
                                    <InputLabel>Your Timezone</InputLabel>
                                    <Select
                                        labelWidth={97}
                                        value={this.state.timezone}
                                        onChange={this.setTimezone}
                                        error={store.invalidTimezone}
                                    >
                                        {timezones.map((tz: string, index: number) => {
                                            return (
                                                <MenuItem key={`${tz}-${index}`} value={tz}>{tz}</MenuItem>
                                            )}
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>


                            <Grid item={true} xs={12}>
                                <FormControl variant="outlined" fullWidth={true}>
                                    <InputLabel>Test Time Slot</InputLabel>
                                    <Select
                                        labelWidth={91}
                                        value={this.state.slotData.estDate}
                                        onChange={this.updateSlotData}
                                        error={store.invalidTimeSlot}
                                    >
                                        {timeSlots.map((timeSlot: ScheduleTime, index: number) => {
                                            return (
                                                <MenuItem key={`${timeSlot.slotId}-${timeSlot.time}-${index}`} value={timeSlot.time}>
                                                    {`${moment.tz(timeSlot.time, this.state.timezone).format("lll z")} – ${timeSlot.availableSeats} seats available`}
                                                </MenuItem>
                                            )}
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </FormGroup>
                    <Button
                        type={"submit"}
                        variant={"contained"}
                        color={"primary"}
                        size={"large"}
                        disabled={!registrationEnabled}
                    >
                        Schedule
                    </Button>
                </form>
            </Paper>
        )
    }
}

export const InProgress = () => {
    return (
        <>
            <h1>Rostering...</h1>
            <CircularProgress/>
        </>
    )
}
