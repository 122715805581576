import * as React from "react"
import ApiService from "../../../services/ApiService"
import {CONTENT_AREA_ID_TO_LANGUAGE_NAME} from "../../../util/Constants"
import {groupMasterStore} from "../../common/group/GroupMasterStore"
import {messageStore} from "../../common/messages/MessageStore"
import {productStore} from "../../common/products/ProductStore"
import {authStore} from "../../common/authentication/AuthStore"


import {RouteComponentProps} from "react-router-dom"
import {
    LoginProductContentAreaData,
    PACKAGE_TO_FULL_NAME,
    SsoEntity,
    SsoTeacherClass,
    SSOTeacherLoginInfo
} from "../../../types/types"
import {ReportSubPaths} from "../../reports/ReportSubPaths"

import {Button, Icon, Typography} from "@material-ui/core"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import MaterialTable from "material-table"
import moment from "moment"
import {H3Text} from "../../../styles/AvantTypography"
import {TimeUtil} from "../../../util/TimeUtil"
import {AvantExpansionPanel} from "../../generic-components/AvantExpansionPanel"
import {SSOTeacherAssessmentSetup} from "./SSOTeacherAssessmentSetup"
import {SSOTeacherViewRoster} from "./SSOTeachViewRoster"
import {CustomSectionsRosterStudent} from "../districtAdmin/components/CustomSectionRosterStudent";

interface SSOTeacherClassListState {
    classList: SsoTeacherClass[],
    ssoEntity: SsoEntity | null,
    initialized: boolean,
    setupOpen: boolean,
    rosterOpen: boolean,
    loginId: number | null,
    groupId: number,
    package: string,
    packageId: string,
    allowHandwritten: boolean,
    groupName: string | null,
    canNotLoadClasses: boolean,

}

interface TeacherClass {
    groupId: number,
    name: string | null,
    count: number | null,
    estTestDate: string | null,
    productName: string,
    packageId: string,
    handWritten: boolean,
    writingMethod: string,
    testCode: string | null,
    status: string,
    language: string | null
}

export class SSOAuthorizedTeacher extends React.Component<RouteComponentProps, SSOTeacherClassListState, {}> {
    tableRef = React.createRef<MaterialTable<SsoTeacherClass>>()

    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            classList: [],
            ssoEntity: null,
            initialized: false,
            setupOpen: false,
            rosterOpen: false,
            loginId: null,
            groupId: 0,
            package: "",
            packageId: "",
            allowHandwritten: false,
            groupName: "",
            canNotLoadClasses: false
        }
    }

    componentDidMount(): void {
        this.loadClassList()
    }

    loadClassList = (): void => {
        ApiService.getSSOTeacherClassList()
            .then(response => {
                if (response.length) {
                    this.setState({classList: response})
                } else {
                    // try and force Clever to load the data and/or buy us time for the  first sync call to complete
                    ApiService.getSSOUpdateData().then (_ => {
                        // try to load the classes one last time
                        ApiService.getSSOTeacherClassList()
                            .then(response2 => {
                                if (response2.length) {
                                    this.setState({classList: response2, canNotLoadClasses: false})
                                } else {
                                    this.setState({canNotLoadClasses: true})
                                }
                            })
                    }).catch(
                        _ => {
                            this.setState({canNotLoadClasses: true})
                        }
                    )
                }
            })
    }

    popupSettings = (event: React.MouseEvent<HTMLElement>, rowData: TeacherClass): void  => {
        if (rowData.groupId == null) {
            messageStore.setErrorMessage("Test not setup correctly - no group. Contact Support.")
        } else if (rowData.productName == null) {
            messageStore.setErrorMessage("Test not setup correctly - no package. Contact Support.")
        } else {
            this.setState({
                setupOpen: true,
                groupId: rowData.groupId,
                package: rowData.productName,
                packageId: rowData.packageId,
                allowHandwritten: rowData.handWritten,
                groupName: rowData.name,
            })
        }
    }
    closeTestSettings = () => {
        this.setState({setupOpen: false})
        this.loadClassList()
    }

    popupRoster = (event: React.MouseEvent<HTMLElement>, rowData: TeacherClass) => {
        this.setState({rosterOpen: true, groupId: rowData.groupId, groupName: rowData.name})
    }
    closeRoster = () => {
        this.setState({rosterOpen: false})
        this.loadClassList()
    }

    startContinueTest = (event: React.MouseEvent<HTMLElement>, row: TeacherClass): void => {
        const rowData = this.state.classList.find(c => c.groupId === row.groupId) as SsoTeacherClass
        if (rowData.logins == null) { return }
        ApiService.allowTest(rowData.logins.id)
            .then(response => {
                if (response === true) {
                    const newClassList = this.state.classList.slice()
                    for (const c of newClassList) {
                        if (c.groupId === row.groupId) {
                            c.logins.allowTest = true
                        }
                    }
                    this.setState({classList: newClassList})
                    messageStore.setInfoMessage("Test takers can be allowed in the test")
                }
            })
    }

    stopTest = (event: React.MouseEvent<HTMLElement>, row: TeacherClass): void  => {
        const rowData = this.state.classList.find(c => c.groupId === row.groupId) as SsoTeacherClass
        if (rowData.logins == null) { return }
        ApiService.disallowTest(rowData.logins.id)
            .then(response => {
                if (response) {
                    const newClassList = this.state.classList.slice()
                    for (const c of newClassList) {
                        if (c.groupId === row.groupId) {
                            c.logins.allowTest = false
                        }
                    }
                    this.setState({classList: newClassList})
                    messageStore.setInfoMessage("Test takers are not allowed to enter the test")
                }
            })
    }

    viewReports = (event: React.MouseEvent<HTMLElement>, row: TeacherClass): void => {
        const rowData = this.state.classList.find(c => c.groupId === row.groupId) as SsoTeacherClass
        const loginProduct: LoginProductContentAreaData = {
            loginId: rowData.logins.id,
            userType: "T",
            productId: rowData.logins.productId,
            productName: rowData.logins.productName,
            contentAreaId: rowData.logins.contentareaid,
            contentAreaName: CONTENT_AREA_ID_TO_LANGUAGE_NAME[rowData.logins.contentareaid],
            rostered: true,
            userName: rowData.logins.testCode,
        }

        // Setup state for the report
        productStore.setLoginProduct(loginProduct)
        groupMasterStore.selectGroup(rowData.groupId) // particularly need to setup the reports redirect
        this.props.history.push(ReportSubPaths.REPORTS_REDIRECT)
    }

    setStatus(groupId: number): string {
        const rowData = this.state.classList.find(c => c.groupId === groupId) as SsoTeacherClass
        if (rowData === null) {return ""}
        if (rowData.logins === null) {
            switch (rowData.status) {
                case ("NEEDS_DISTRICT_SETUP") :
                    return "Needs district setup."
                case ("DISTRICT_SETUP_NEED_CLASSROOM_SETUP") :
                    return "Needs setup."
                case ("TESTING_READY") :
                    return "Ready to start the test!"
                default:
                    return ""
            }
        } else {
            if (rowData.logins.allowTest === false) {
                return "Testing Paused"
            }
            if (rowData.logins.allowTest === true) {
                return "Testing Ready"
            }
        }
        return ""
    }

    setWritingMethod(logins?: SSOTeacherLoginInfo): string {
        if (logins) {
            if (logins.handwritten) {
                return "Handwritten"
            } else {
                return "Keyboarded"
            }
        } else {
            return ""
        }
    }

    isSetupHidden(groupId: number): boolean {
        const rowData = this.state.classList.find(c => c.groupId === groupId) as SsoTeacherClass
        if (rowData.status === "DISTRICT_SETUP_NEED_CLASSROOM_SETUP" && rowData.logins === null) { return false }
        return true
    }

    isAllowHidden(groupId: number): boolean {
        const rowData = this.state.classList.find(c => c.groupId === groupId) as SsoTeacherClass
        if (rowData.logins !== null && rowData.logins.allowTest === false) { return false }
        return true
    }

    isDisallowHidden(groupId: number): boolean {
        const rowData = this.state.classList.find(c => c.groupId === groupId) as SsoTeacherClass
        if (rowData.logins !== null && rowData.logins.allowTest === true) { return false }
        return true
    }

    isViewReportHidden(groupId: number): boolean {
        const rowData = this.state.classList.find(c => c.groupId === groupId) as SsoTeacherClass
        if (rowData.logins !== null) { return false }
        return true
    }

    openTestSettings = (event: any, rowData: any) => {
        this.setState({setupOpen: true})
    }

    render() {
        const classList = this.state.classList ? this.state.classList : []
        const reportsButton = () => <><Typography><Icon>search</Icon></Typography></>
        const settingsButton = () => <><Typography><Icon>settings</Icon></Typography></>
        const reportsAllow = () => <><Typography><Icon>play_arrow</Icon></Typography></>
        const reportsDisallow = () => <><Typography><Icon>stop</Icon></Typography></>
        const rosterButton = () => <><Typography><Icon>list</Icon></Typography></>
        const basicPadding = {
            padding: 2
        }
        const headerPadding = {
            paddingTop: 8
        }
        const eachListStyle = {
            listStyle: "none",
            paddingLeft: 0
        }
        const classData: TeacherClass[] = []
        for (const c of classList) {
            const data: TeacherClass = {
                groupId: c.groupId,
                name: c.name,
                count: c.count,
                estTestDate: c.estTestingDate ? TimeUtil.formatToUsDate(moment(c.estTestingDate)) : null,
                productName: PACKAGE_TO_FULL_NAME[c.productName],
                packageId: c.productName,
                handWritten: c.handwritten,
                writingMethod: this.setWritingMethod(c.logins),
                testCode: c.logins ? c.logins.testCode : null,
                status: c.status,
                language: c.logins ? CONTENT_AREA_ID_TO_LANGUAGE_NAME[c.logins.contentareaid] : null
            }

            classData.push(data)
        }

        return (
            <>
                {this.state.classList.length === 0 && this.state.canNotLoadClasses === false &&
                (<div>
                        <div className="login__login-container" style={{display: "inline-block"}}>
                            <div className="login__login-container__top">
                                <h2 style={{textAlign: "center"}}>Loading Classes</h2>
                                <p>
                                    We are attempting to load your classes from {authStore && authStore.auth && authStore.auth.ssoType==="ClassLink"?"ClassLink":"Clever"}.
                                </p>
                            </div>
                        </div>
                    </div>
                )
                }
                {this.state.classList.length === 0 && this.state.canNotLoadClasses === true &&
                <div>
                    <div className="login__login-container" style={{display: "inline-block"}}>
                        <div className="login__login-container__top">
                            <h2 style={{textAlign: "center"}}>Loading Classes</h2>
                            <p>
                                We could not find any classes that have tests at this time.  You can refresh the page
                                to try and check for classes again or contact your testing coordinator for help.
                            </p>
                        </div>
                    </div>
                </div>
                }
                {this.state.classList.length > 0 &&
                <div>
                    <AvantExpansionPanel title={"Resources"}>
                        <AccordionDetails>
                            <div
                                style={{
                                    width: "25%",
                                    padding: 5
                                }}
                            >
                                <H3Text
                                    style={headerPadding}
                                >
                                    <b>Resources</b>
                                </H3Text>
                                <div>
                                    Please review the resources below
                                </div>

                                <div>
                                    before setting up the dashboard
                                </div>
                                <ol
                                    style={{listStyle: "none", paddingLeft: 0}}
                                >
                                    <li
                                        style={{padding: 5}}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            href="https://www.youtube.com/watch?v=00k1JDpmJ9c"
                                            target="_blank"
                                        >
                                            Video Tutorial
                                        </Button>
                                    </li>
                                    <li
                                        style={{padding: 5}}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            href="https://avantassessment.com/clever-schools"
                                            target="_blank"
                                        >
                                            Testing Resources
                                        </Button>
                                    </li>
                                </ol>
                            </div>
                            <div
                                style={{
                                    width: "30%",
                                    padding: 5
                                }}
                            >
                                <H3Text

                                    style={headerPadding}
                                >
                                    <b>Actions</b>
                                </H3Text>
                                <ol
                                    style={eachListStyle}
                                >
                                    <li style={basicPadding}><Icon style={{fontSize:"large"}}>list</Icon> View student roster.</li>
                                    <li style={basicPadding}><Icon>settings</Icon> Prepare test by selecting language and testing dates.</li>
                                    <li style={basicPadding}><Icon>play_arrow</Icon> Allow students to enter the test.</li>
                                    <li style={basicPadding}><Icon>stop</Icon> Disallow students to enter the test.</li>
                                    <li style={basicPadding}><Icon>search</Icon> View test results.</li>
                                    <li style={basicPadding}>
                                        <CustomSectionsRosterStudent/>
                                    </li>

                                </ol>
                            </div>
                            <div
                                style={{
                                    width: "50%",
                                    padding: 5
                                }}
                            >
                                <H3Text
                                    style={headerPadding}
                                >
                                    <b>Test Status</b>
                                </H3Text>
                                <ol
                                    style={eachListStyle}
                                >
                                    <li style={basicPadding}><b>Needs District Setup</b> - District needs to select the Avant product to use for testing.</li>
                                    <li style={basicPadding}><b>Needs Setup</b> - The test needs to be prepared by selecting a language and a testing date.</li>
                                    <li style={basicPadding}><b>Testing Paused</b> - Student access to the test is paused and they are not allowed to enter the test.</li>
                                    <li style={basicPadding}><b>Testing Ready</b> - Students are allowed to enter the test.</li>
                                </ol>
                            </div>
                        </AccordionDetails>
                    </AvantExpansionPanel>
                    <div
                        style={{fontSize: 18}}
                    >
                        <b>IMPORTANT: </b>
                        Avant's tests
                        <b> must </b>
                        be proctored by an authorized educator to protect the integrity and security of our tests; the proctor
                        <b> cannot </b>
                        be a parent or guardian.
                    </div>
                    <div
                        style={{fontSize: 18, paddingBottom: 15}}
                    >
                        <b>Make sure to review testing resources prior to test preparation.</b>
                    </div>
                    <MaterialTable
                        tableRef={this.tableRef}
                        title="Class List"
                        columns={[
                            {title: "Testing Group", field: "name"},
                            {title: "# Test Takers", field: "count"},
                            {title: "Est. Testing Date", field: "estTestDate", type: "date"},
                            {title: "Product", field: "productName"},
                            {title: "Language", field: "language"},
                            {title: "Writing Method", field: "writingMethod", cellStyle: {width: "100px"}},
                            {title: "Test Code", field: "testCode"},
                            {title: "Status", field: "status", render: rowData => this.setStatus(rowData.groupId)},
                        ]}
                        options={{
                            thirdSortClick: false,
                            search: false,
                            actionsCellStyle: {
                                backgroundColor: "#fafafa",
                                borderRight: '1px solid #e0e0e0',
                            },
                        }}
                        data={classData}
                        actions={[
                            rowData => ({
                                icon: rosterButton,
                                tooltip: "View Student Roster",
                                onClick: (e: React.MouseEvent<HTMLElement>) => this.popupRoster(e, rowData)
                            }),
                            rowData => ({
                                icon: reportsButton,
                                tooltip: "View Test Results",
                                onClick: (e: React.MouseEvent<HTMLElement>) => this.viewReports(e, rowData),
                                hidden: this.isViewReportHidden(rowData.groupId)
                            }),
                            rowData => ({
                                icon: settingsButton,
                                tooltip: "Prepare test so students can take the test.",
                                onClick: (e: React.MouseEvent<HTMLElement>) => this.popupSettings(e, rowData),
                                hidden: this.isSetupHidden(rowData.groupId)
                            }),
                            rowData => ({
                                icon: reportsAllow,
                                tooltip: "Allow Students to take the test",
                                onClick: (e: React.MouseEvent<HTMLElement>) => this.startContinueTest(e, rowData),
                                hidden: this.isAllowHidden(rowData.groupId)
                            }),
                            rowData => ({
                                icon: reportsDisallow,
                                tooltip: "Disallow Students to take the test",
                                onClick: (e: React.MouseEvent<HTMLElement>) => this.stopTest(e, rowData),
                                hidden: this.isDisallowHidden(rowData.groupId)
                            })
                        ]}
                    />
                    {this.state.setupOpen &&
                        <SSOTeacherAssessmentSetup
                            groupId={this.state.groupId}
                            onClose={this.closeTestSettings}
                            product_package={this.state.package}
                            product_package_id={this.state.packageId}
                            allowHandwritten={this.state.allowHandwritten}
                            nameOfClass={this.state.groupName}
                        />
                    }
                    {this.state.rosterOpen &&
                        <SSOTeacherViewRoster
                            groupId={this.state.groupId}
                            groupName={this.state.groupName}
                            onClose={this.closeRoster}
                        />
                    }
                </div>
                }
            </>
        )
    }
}
