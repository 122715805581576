/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {authStore} from "../common/authentication/AuthStore"
import {productStore} from "../common/products/ProductStore"
import {ReportFilters} from "../reports/common/models/ReportFilters"
import moment from "moment"
import {reportsStore} from "../reports/common/stores/ReportStore"
import {ReportGraphFilters} from "../reports/common/models/ReportGraphFilters"
import {groupMasterStore} from "../common/group/GroupMasterStore"
import {GroupInfo} from "../common/group/models/GroupInfo"
import {PrintOptions} from "../reports/common/components/PrintViewContainer"
import {ReportSummaryGraphs} from "../reports/district-school/models/ReportSummaryGraphs"
import {TableDataResults} from "../reports/class/models/TableDataResults"
import {SkillColumnData} from "./components/testTakerSortableTable/Columns"
import {takeSearchStore} from "../common/take/TakeSearchStore"
import {loadingSpinnerStore} from "../common/loaders/LoadingSpinnerStore"
import {GroupReportRow} from "../reports/district-school/models/GroupReportRow"
import {BlueReportType} from "../reports/common/models/BlueReportType"

export interface ReportProps {
    groupInfo?: GroupInfo,
    printOptions?: PrintOptions,
    filters: ReportFilters,
    query?: ReportGraphFilters,
    graphData?: ReportSummaryGraphs,
    tableData?: TableDataResults,
    adminReportData?: GroupReportRow[],
    isPlace?: boolean,
    skillColumns: SkillColumnData[],
    isMultiLanguage?: boolean,
}

export function getStampReportSearchQuery(filters: ReportFilters): ReportGraphFilters {
    const {
        groupId,
        productId,
        startDate,
        endDate,
        assessmentStart,
        recentActivity,
    } = filters
    const startDateStartOfDay: number = moment(startDate).startOf("day").valueOf()
    const endDateEndOfDay: number = moment(endDate).endOf("day").valueOf()
    reportsStore.searchFilters = {groupId, productId, assessmentStart, recentActivity, startDate, endDate}

    return {
        groupId,
        productId,
        start: startDateStartOfDay,
        end: endDateEndOfDay,
        searchByStartTimes: assessmentStart,
        searchByMostRecentActivityTimes: recentActivity,
        loadAverages: true,
        omitEmptySkillsNotInAssessment: true,
    }
}

export interface DataLoadTracker {
    dataIsLoaded: () => boolean,
}

export function fetchStampFamilyTeacherReportData(filters: ReportFilters): DataLoadTracker {
    loadingSpinnerStore.hideLoadingSpinner = false

    reportsStore.reportTableData = undefined
    reportsStore.reportGraphData = undefined

    const query: ReportGraphFilters = getStampReportSearchQuery(filters)

    reportsStore.findReportGraphs(query)
    reportsStore.findReportTableData(query)
    reportsStore.findDownloadedReportTableData(query)
    reportsStore.resetDownloads()
    groupMasterStore.groupInfo = undefined
    groupMasterStore.findGroupInfo(filters.groupId)

    function dataIsLoaded(): boolean {
        if (reportsStore.reportGraphData && groupMasterStore.groupInfo && reportsStore.reportTableData) {
            loadingSpinnerStore.hideLoadingSpinner = true
            return true
        }
        return false
    }

    return {
        dataIsLoaded,
    }
}

export function fetchStampFamilyDistrictSchoolReportData(filters: ReportFilters, reportType: BlueReportType): DataLoadTracker {
    loadingSpinnerStore.hideLoadingSpinner = false

    reportsStore.reportGraphData = undefined
    reportsStore.groupsWithData = undefined
    reportsStore.districtReportData = undefined
    reportsStore.schoolReportData = undefined

    takeSearchStore.instances = []
    takeSearchStore.downloadComplete = false

    const isPortalSaml = authStore.auth?.ssoType  === "SalesForce" && productStore.loginProduct?.productId !== null && productStore.loginProduct?.productId !== undefined

    if (reportType === BlueReportType.DISTRICT) {
        if (isPortalSaml) {
            reportsStore.findSSODistrictReport(filters.groupId, filters.productId)
        } else {
            reportsStore.findDistrictReportData(filters.groupId)
        }

    }
    if (reportType === BlueReportType.SCHOOL) {
        reportsStore.findSchoolReportData(filters.groupId)
    }

    const query: ReportGraphFilters = getStampReportSearchQuery(filters)

    reportsStore.findReportGraphs(query)
    reportsStore.findGroupsWithData(query)
    reportsStore.resetDownloads()
    groupMasterStore.groupInfo = undefined
    groupMasterStore.findGroupInfo(filters.groupId)

    function dataIsLoaded(): boolean {
        if (reportsStore.reportGraphData && groupMasterStore.groupInfo) {
            loadingSpinnerStore.hideLoadingSpinner = true
            return true
        }

        return false
    }

    return {
        dataIsLoaded,
    }
}
