import {FormControl, FormGroup, Input, InputLabel, MenuItem, Select, TextField} from "@material-ui/core"
import Button from "@material-ui/core/Button/Button"
import Snackbar from "@material-ui/core/Snackbar/Snackbar"
import React from "react"
import ReactTable, {RowInfo} from "react-table"
import ApiService, {UpdateVoiceRecorderResult} from "../../services/ApiService"
import {ApiLogin, MuiEvent, TEvent, VOICE_RECORDER} from "../../types/types"


interface SetTestCodeRecorderState {
    msg: string
    logins: ApiLogin[]
    testCodeSearchString: string
}

export class SetTestCodeRecorder extends React.Component<{}, SetTestCodeRecorderState> {

    constructor(props: {}) {
        super(props)
        this.state = {
            msg: "",
            logins: [],
            testCodeSearchString: ""
        }
    }

    onSearchLoginsByUsername = async (username: string) => {
        const logins: ApiLogin[] = await ApiService.searchLoginByUsernameAndUserType(username, "S")
        this.setState({logins})
    }

    snackBar = () => {

        const {msg} = this.state

        const onClose = () => {
            this.setState({msg: ""})
        }

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                open={!!msg}
                autoHideDuration={6000}
                onClose={onClose}
                data-tst-id='success-snack-bar'
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{msg}</span>}
                action={[
                    <Button key={"undo"} color={"secondary"} size={"small"} onClick={onClose}>
                        Got it!
                    </Button>
                ]}
            />
        )
    }

    testCodeTable = (logins: ApiLogin[]) => {

        const columns = [
            {Header: "Test-Code", accessor: "username", width: 100},
            {
                Header: "Voice Recorder",
                accessor: "voiceRecorder",
                Cell: (testCodeRow: RowInfo) => {
                    const voiceRecorderSelectId = `voice-recorder-select-${testCodeRow.original.id}`
                    return (
                        <>
                            <FormControl>
                                <InputLabel htmlFor={voiceRecorderSelectId}>Language</InputLabel>
                                <Select
                                    placeholder="select"
                                    data-tst-id='voice-recorder-type-select'
                                    onChange={async (e: MuiEvent) => {
                                        const result: UpdateVoiceRecorderResult =
                                            await ApiService.updateLoginVoiceRecorder(testCodeRow.original.id, e.target.value as any)
                                        this.setState({msg: `${result.msg} ${testCodeRow.original.username}`})
                                        this.onSearchLoginsByUsername(this.state.testCodeSearchString)
                                    }}
                                    value={testCodeRow.original.voiceRecorder}
                                    defaultValue={testCodeRow.original.voiceRecorder}
                                    input={<Input name={"voice-recorder-select"} id={voiceRecorderSelectId}/>}
                                >
                                    {
                                        Object.keys(VOICE_RECORDER).map((k) => {
                                            return (
                                                <MenuItem key={`login-voice-recorder-${k}`} value={k}
                                                          id={`voice-recorder-${testCodeRow.original.loginid}-${k}`}
                                                          disabled={VOICE_RECORDER[k] === 'FLASH'}
                                                >
                                                    {VOICE_RECORDER[k]}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </>
                    )
                }
            }
        ]

        return (<ReactTable
            columns={columns}
            data={logins}
            defaultPageSize={50}
        />)
    }

    searchForm = () => {
        const onSearch = () => {
            this.onSearchLoginsByUsername(this.state.testCodeSearchString)
        }

        return (
            <div>
                <FormGroup>
                    <TextField
                        label="Test Code"
                        type="text"
                        value={this.state.testCodeSearchString}
                        placeholder="Search Test-Code"
                        data-tst-id="test-code"
                        onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                            if (e.key === "Enter") {
                                onSearch()
                            }
                        }}
                        onChange={(e: TEvent) => {
                            this.setState({
                                testCodeSearchString: e.target.value
                            })
                        }}
                        autoFocus={true}
                    />
                    <Button
                        onClick={onSearch}
                        data-tst-id='search-test-codes'
                    >Search Test-Codes</Button>
                </FormGroup>
            </div>
        )
    }



    render() {
        return (
            <>
                {this.searchForm()}
                {this.testCodeTable(this.state.logins)}
                {this.snackBar()}
            </>
        )
    }
}

