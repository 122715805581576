import {createStyles, WithStyles, withStyles} from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import {AuthUser} from "../app/common/authentication/models/AuthUser"
import {ItemContent} from "../app/common/item/models/ItemContent"
import {ProductDriver} from "../app/common/products/ProductStore"
import {theme} from "../styles/MuiThemes"
import {
    IItem,
    InstructionPsTextContent,
    ItemContentSectionEnum,
    ItemContentTypeEnum,
    ItemFormatEnum,
    LoginProductContentAreaData,
    VideoContent
} from "../types/types"
import {CONTENT_AREA_ID_ARABIC} from "../util/Constants"
import InstructionAudio from "./InstructionAudio/InstructionAudio"
import InstructionImage from "./InstructionImage/InstructionImage"
import InstructionPassageSelect from "./InstructionPassageSelect/InstructionPassageSelect"
import {InstructionPDF} from "./InstructionPDF/InstructionPDF"
import InstructionText from "./InstructionText/InstructionText"
import InstructionVideo from "./InstructionVideo/InstructionVideo"
import ItemVideo from "./ItemVideo/ItemVideo"
import {contentAreaStore} from "../app/common/contentArea/ContentAreaStore"

const styles = createStyles({
    arabicFontSize: {
        fontSize: 20,
        lineHeight: 1.5
    }
})


interface InstructionsProps extends WithStyles<typeof styles> {
    takePanelId: number
    item: IItem
    isRtlLayout?: boolean
    smallImages?: boolean
    authUser: AuthUser
    product: LoginProductContentAreaData
    driver: ProductDriver
    testName?: string
    handleSelectText?: (text: string) => void
    handleWindowResize?: () => void
    handleEditItemContent?: (itemContentId: number, content: string, itemId?: number) => Promise<void>
    handleUpdateItemFile?: (itemContentId: number, content: string | VideoContent) => void
    responseModal?: boolean
}

interface InstructionsState {
    passageSelectAnswer: string | null
}

class Instructions extends React.Component<InstructionsProps, InstructionsState> {
    constructor(props: InstructionsProps) {
        super(props)
        this.state = {
            passageSelectAnswer: null
        }
    }

    componentDidMount() {
        contentAreaStore.fetchContentAreas()
    }

    setPassageSelectAnswer = (answer: string) => {
        this.setState({
            passageSelectAnswer: answer
        })
    }

    getJSX = (itemContent: ItemContent | InstructionPsTextContent | undefined, key: string): JSX.Element | undefined => {
        const {
            authUser,
            isRtlLayout,
            item,
            driver,
            takePanelId,
            smallImages,
            handleSelectText,
            product,
            classes,
            responseModal
        } = this.props
        const languageId = parseInt(item.languageId, 10)
        let languageName: string | null = null
        if (contentAreaStore.contentAreas.length) {
            languageName = contentAreaStore.convertContentAreaIdToLanguageName(languageId)
        }

        let {
            handleUpdateItemFile,
            handleWindowResize,
            handleEditItemContent,
        } = this.props
        if (handleUpdateItemFile == null) {
            handleUpdateItemFile = () => {
                // no-op
            }
        }
        if (handleWindowResize == null) {
            handleWindowResize = () => {
                // no-op
            }
        }
        if (handleEditItemContent == null) {
            handleEditItemContent = () => {
                return new Promise(() => {
                    // no-op
                })
            }
        }
        const {passageSelectAnswer} = this.state
        const isAdmin: boolean = authUser.userType === "A"
        let element

        if (itemContent !== undefined) {
            let className: string = isRtlLayout ? "direction-rtl" : ""
            if (product.contentAreaId === CONTENT_AREA_ID_ARABIC) {
                className = classNames(className, classes.arabicFontSize)
            }
            let dataTstId: string = ""
            switch (itemContent.type) {
                case ItemContentTypeEnum.VIDEO:
                    element = (
                        <InstructionVideo
                            key={key}
                            className={className}
                            item={item}
                            itemContent={itemContent}
                            isAdmin={isAdmin}
                            driver={driver}
                            handleUpdateItemFile={handleUpdateItemFile}
                        />
                    )
                    break
                case ItemContentTypeEnum.ITEM_VIDEO:
                    element = (
                        <ItemVideo
                            itemContent={itemContent}
                            contentAreaId={product.contentAreaId}
                        />
                    )
                    break
                case ItemContentTypeEnum.PDF:
                    element = (
                        <InstructionPDF
                            key={key}
                            className={className}
                            item={item}
                            itemContent={itemContent}
                            isAdmin={isAdmin}
                            driver={driver}
                            handleUpdateItemFile={handleUpdateItemFile}
                        />
                    )
                    break
                case ItemContentTypeEnum.MP3_SOUND:
                    element = (
                        <InstructionAudio
                            key={key}
                            className={className}
                            item={item}
                            itemContent={itemContent}
                            takePanelId={takePanelId}
                            isAdmin={isAdmin}
                            driver={driver}
                            languageId={languageId}
                            /* There should always be a language name associated with each product. Instead of
                            asserting that the languageName is always present, however, I've chosen to render
                            an empty string here in the case where languageName is left null as it does not make sense
                            to display an error to the user here. */
                            languageName={languageName ? languageName : ''}
                            product={product}
                            handleUpdateItemFile={handleUpdateItemFile}
                        />
                    )
                    break
                case ItemContentTypeEnum.PLAIN_IMAGE:
                    element = (
                        <InstructionImage
                            key={key}
                            className={className}
                            smallImages={smallImages}
                            itemContent={itemContent}
                            isAdmin={isAdmin}
                            driver={driver}
                            languageId={languageId}
                            handleWindowResize={handleWindowResize}
                            handleUpdateItemFile={handleUpdateItemFile}
                        />
                    )
                    break
                case ItemContentTypeEnum.PS_TEXT:
                    className = `${className} pstext-wrapper`
                    if (this.props.item.format === ItemFormatEnum.PASSAGE_SELECT) {
                        dataTstId = "choices-wrapper"
                    }
                    element = (
                        <InstructionPassageSelect
                            key={key}
                            className={className}
                            itemContent={itemContent as InstructionPsTextContent}
                            format={item.format}
                            item={item}
                            userAnswer={passageSelectAnswer}
                            testId={dataTstId}
                            handleSelectText={handleSelectText}
                        />
                    )
                    break
                case ItemContentTypeEnum.RTL_TEXT:
                    className = `${className} rtltext-wrapper direction-rtl`
                    element = (
                        <div key={itemContent.id} className={className}>
                            {React.createElement("span", {dangerouslySetInnerHTML: {__html: itemContent.content}})}
                        </div>
                    )
                    break
                case ItemContentTypeEnum.RTL_PS_TEXT:
                    className = `${className} rtlpstext-wrapper direction-rtl`
                    dataTstId = ""
                    if (this.props.item.format === ItemFormatEnum.PASSAGE_SELECT) {
                        dataTstId = "choices-wrapper"
                    }
                    element = (
                        <InstructionPassageSelect
                            key={key}
                            className={className}
                            itemContent={itemContent as InstructionPsTextContent}
                            format={item.format}
                            item={item}
                            userAnswer={passageSelectAnswer}
                            testId={dataTstId}
                            handleSelectText={handleSelectText}
                        />
                    )
                    break
                case ItemContentTypeEnum.BLANK:
                    break
                case ItemContentTypeEnum.RTL_ADV_RESP:
                    className = `${className} rtltext-wrapper direction-rtl`
                    element = (
                        <InstructionText
                            key={key}
                            className={className}
                            itemContent={itemContent}
                            isAdmin={isAdmin}
                            isRtlAdvance={true}
                            itemId={item.id}
                            handleEditItemContent={handleEditItemContent}
                        />
                    )
                    break
                default:
                    if (itemContent.content !== null && itemContent.content !== undefined) {
                        const content = itemContent.content as string
                        className = content.includes("Prompt:") ? `${className} advance-prompt` : `${className} default`
                        className = content.includes("Response:")
                            ? `${className} advance-response`
                            : `${className} default`
                    }
                    if (item.format === ItemFormatEnum.BLANK &&
                        itemContent.type === ItemContentTypeEnum.TEXT &&
                        itemContent.section === ItemContentSectionEnum.INSTRUCTION
                    ) {
                        className = `${className} instructions--bold`
                    } else if (itemContent.type === ItemContentTypeEnum.TEXT && itemContent.position === 1) {
                        className = `${className} instructions--bold`
                    }

                    element = (
                        <InstructionText
                            key={key}
                            className={className}
                            itemContent={itemContent}
                            isAdmin={isAdmin}
                            isRtlAdvance={false}
                            itemId={item.id}
                            handleEditItemContent={handleEditItemContent}
                        />
                    )
            }

            if (itemContent.type !== ItemContentTypeEnum.PLAIN_IMAGE) {
                element = (
                    <div
                        key={key}
                        className={responseModal ? "" : "instruction-panel__content"}
                        style={{padding: responseModal ? theme.spacing(2) : ""}}
                    >
                        {element}
                    </div>
                )
            }
        }

        return element
    }

    render() {
        const {item} = this.props

        const out: JSX.Element[] = []

        const itemContent1 = this.getJSX(item.itemContent1, "key1")
        if (itemContent1 !== undefined) {
            out.push(itemContent1)
        }

        const itemContent2 = this.getJSX(item.itemContent2, "key2")
        if (itemContent2 !== undefined) {
            out.push(itemContent2)
        }

        const itemContent3 = this.getJSX(item.itemContent3, "key3")
        if (itemContent3 !== undefined) {
            out.push(itemContent3)
        }

        const itemContent4 = this.getJSX(item.itemContent4, "key4")
        if (itemContent4 !== undefined) {
            out.push(itemContent4)
        }

        return out
    }
}

export default withStyles(styles)(Instructions)
