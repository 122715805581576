import React from "react"
import {Col, Row} from "react-bootstrap"
import {ItemContent} from "../../app/common/item/models/ItemContent"
import {ProductDriver} from "../../app/common/products/ProductStore"
import {IItem, TLocalAnswer} from "../../types/types"
import Button from "../Button/Button"

interface IComponentProps {
    item: IItem
    driver: ProductDriver
    handleLocalAnswerChange: (localAnswer: TLocalAnswer) => void
}

// IComponentState is an interface to hold the local state variables (right terminology???)
interface IComponentState {
    answer?: string
    error?: string
}

// This line added the IComponentState replacing the "any" value of {}
export default class YesNo extends React.Component<IComponentProps, IComponentState> {
    constructor(props: IComponentProps) {
        super(props)
        this.state = {} // This adds local state as undefined
    }

    onYesNoChoice = (event: any) => {
        this.props.handleLocalAnswerChange(event.target.value)
        this.setState({answer: event.target.value})
    }

    render() {
        const {driver} = this.props

        if (driver === undefined) {
            return null
        }

        const item: IItem = this.props.item
        let yesId = ""
        let noId = ""
        let yesIsCorrect = false
        let noIsCorrect = false

        item.choices.forEach((choice: ItemContent) => {
            if (choice.description === "Yes") {
                yesId = choice.id.toString()
                yesIsCorrect = item.correctAnswers.indexOf(choice.id.toString()) !== -1
            } else if (choice.description === "No") {
                noId = choice.id.toString()
                noIsCorrect = item.correctAnswers.indexOf(choice.id.toString()) !== -1
            }
        })

        if (yesId === "" || noId === "") {
            this.setState({
                error: `Sorry, an error occurred. Please contact ${this.props.driver.config.SUPPORT_EMAIL}.`
            })
            throw new Error(`Did not find yes or no answer for item ${item.id}`)
        }

        let baseNoClassName = "avant-button--yes-no-white-on-red"
        if (this.state.answer === noId) {
            baseNoClassName = `${baseNoClassName} avant-button--yes-no-white-on-red--answered`
        }

        let baseYesClassName = "avant-button--yes-no-white-on-green"
        if (this.state.answer === yesId) {
            baseYesClassName = `${baseYesClassName} avant-button--yes-no-white-on-green--answered`
        }

        const noClassName = noIsCorrect ? `${baseNoClassName} debug-correct-answer` : baseNoClassName
        const yesClassName = yesIsCorrect ? `${baseYesClassName} debug-correct-answer` : baseYesClassName

        return (
            <div data-tst-id="choices-wrapper" className="choices-wrapper-yes-no">
                <Row>
                    <Col sm={{span: 4, offset: 1}}>
                        <Button
                            onClick={this.onYesNoChoice}
                            value={yesId}
                            block={true}
                            testId="yes-choice"
                            className={yesClassName}
                        >
                            {driver.strings.yes}
                            {yesIsCorrect ? " !!! " : ""}
                        </Button>
                    </Col>
                    <Col sm={{span: 4, offset: 2}}>
                        <Button
                            onClick={this.onYesNoChoice}
                            value={noId}
                            block={true}
                            testId="no-choice"
                            className={noClassName}
                        >
                            {driver.strings.no}
                            {noIsCorrect ? " !!! " : ""}
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }
}
