import React from "react"
import {connect} from "react-redux"
import {Dispatch} from "redux"
import {Section} from "../app/section/models/Section"

import {setShowPanelTimeoutModal} from "../redux/app/actions"
import ApiService from "../services/ApiService"
import HelperService from "../services/HelperService"
import {State, TSeconds} from "../types/types"
import {log} from "../util/Logging"
import {PanelId, TakeId} from "../validation/ValidPrimaryKey"

interface IStateToProps {
    section: Section
    stopTest: boolean
}

const mapStateToProps = (state: State): IStateToProps => {
    return {
        section: state.item.currentSection!,
        stopTest: state.item.stopTest
    }
}

interface IDispatchToProps {
    setShowPanelTimeoutModalDispatch: (showModal: boolean) => void
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
    return {
        setShowPanelTimeoutModalDispatch: (showModal: boolean) => {
            dispatch(setShowPanelTimeoutModal(showModal))
        }
    }
}

interface IOwnProps {
    outOfTimeCallback: () => void
}

type TComponentProps = IStateToProps & IDispatchToProps & IOwnProps

interface IComponentState {
    timeRemainingStr: string
    timeRemaining: TSeconds
}

class PanelTimerContainer extends React.Component<TComponentProps, IComponentState> {
    private timerHandle: any | null = null

    constructor(props: TComponentProps) {
        super(props)
        const timeRemaining = this.props.section.timeRemaining
        if (timeRemaining === null) {
            throw Error(
                "[PanelTimerContainer] Section.timeRemaining is null. section must have a non-null timeRemaining."
            )
        }
        const timeRemainingStr = HelperService.formatTimeRemaining(timeRemaining)

        this.state = {
            timeRemainingStr,
            timeRemaining
        }
        this.updateTimer = this.updateTimer.bind(this)
        this.handleOutOfTime = this.handleOutOfTime.bind(this)
    }

    componentDidMount() {
        this.updateTimer()
        this.timerHandle = setInterval(this.updateTimer, 1000)
    }

    componentDidUpdate() {
        if (this.props.stopTest) {
            clearInterval(this.timerHandle)
        }
        if (this.props.section.timeRemaining !== this.state.timeRemaining) {
            const timeRemaining = this.props.section.timeRemaining
            if (timeRemaining === null) {
                throw Error(
                    "[PanelTimerContainer] Section.timeRemaining is null despite having" +
                    " items to answer. section must have a " +
                    "non-null timeRemaining."
                )
            } else {
                log.debug(HelperService.formatTimeRemaining(timeRemaining))
                this.setState({
                    timeRemaining
                })
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.timerHandle!)
    }

    updateTimer() {
        const timeRemaining = this.state.timeRemaining - 1
        const timeRemainingStr = HelperService.formatTimeRemaining(timeRemaining)
        if (timeRemaining <= 0) {
            clearInterval(this.timerHandle!)
            this.setState({
                timeRemainingStr: "00:00:00",
                timeRemaining: 0
            })
            this.handleOutOfTime()
        } else {
            this.setState({
                timeRemainingStr,
                timeRemaining
            })
        }
    }

    handleOutOfTime() {
        this.props.setShowPanelTimeoutModalDispatch(true)
        ApiService.finishSectionAndPanel(
            new TakeId(this.props.section.takeId),
            new PanelId(this.props.section.panelId)
        )
        this.props.outOfTimeCallback()
    }

    render() {
        const timerClass = this.state.timeRemaining < 60 ? "low-time" : ""
        return (
            <span data-tst-id="time-remaining-label-and-value" className={timerClass}>
                {" "}
                Time Remaining: <span data-tst-id="time-remaining-value">{this.state.timeRemainingStr}</span>
            </span>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PanelTimerContainer)
