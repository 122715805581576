import {IconButton} from "@material-ui/core"
import {styled} from "@material-ui/core/styles"
import {Close} from "@material-ui/icons"
import React, {MouseEventHandler} from "react"
import {AvantColors} from "../../styles/AvantColors"
import {theme} from "../../styles/MuiThemes"

const CloseButton = styled(IconButton)({
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: AvantColors.CLOSE_BUTTON_GRAY,
    "&:hover": {
        color: AvantColors.PRIMARY_BLUE,
        backgroundColor: AvantColors.REPORT_BACKGROUND_BLUE,
    }
})

export const AvantModalCloseIcon: React.FC<{onClick: MouseEventHandler}> = ({onClick}) => (
    <CloseButton
        onClick={onClick}
        size={"small"}
    >
        <Close/>
    </CloseButton>
)