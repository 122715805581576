export enum LocalStorageKey {
    PDF_PAGE_SIZE = "PDF_PAGE_SIZE"
}

export class LocalStorageUtil {

    static setLocalStorage = (key: LocalStorageKey, value: string) => {
        window.localStorage.setItem(key, value)
    }

    static findInLocalStorage = (key: LocalStorageKey) => {
        return window.localStorage.getItem(key)
    }
}
