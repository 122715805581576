export abstract class ValidData<T> {
    protected data: T

    private isValid: boolean = false

    constructor(rawData: T, error?: Error) {
        this.isValid = this.validate(rawData)
        if (!this.isValid) {
            if (error) {
                throw error
            } else {
                throw new Error("Data " + rawData + " is invalid")
            }
        }

        this.data = rawData
    }

    value(): T {
        return this.data
    }

    // Make String interpolation less error prone
    toString(): string {
        return `${this.data}`
    }

    protected abstract validate(rawData: T): boolean
}
