import * as React from "react"
import {theme} from "../../styles/MuiThemes"
import {RouteComponentProps} from "react-router-dom"
import {authStore} from "../common/authentication/AuthStore"
import {Header, HEADER_HEIGHT} from "../../components/Header/Header"
import {SSOWrapper} from "./components/SSOWrapper"
import {REPORTS_MAX_WIDTH, reportsPadding} from "../reports/common/components/ReportsPage"
import {screenUtil} from "../../util/ScreenUtil"
import SSOAuthorizedStudent from "./student/SSOAuthorizedStudent"
import {SSOUnauthorizedStudent} from "./student/SSOUnauthorizedStudent"

export const ssoStudentLandingPage: React.FC<RouteComponentProps> = (props) => {
    console.log("ssoStudentLandingPage LOADED FROM REDIRECT")
    const activeComponent = authStore.isSsoAvantAuthorized ? <SSOAuthorizedStudent {...props}/> : <SSOUnauthorizedStudent/>
    return (
        <>
            <Header/>
            <SSOWrapper
                style={{
                    padding: reportsPadding,
                    paddingTop: HEADER_HEIGHT + theme.spacing(4),
                    display: "flex",
                    flexDirection: "column",
                    alignItems: screenUtil.screenWidth > REPORTS_MAX_WIDTH + reportsPadding * 2 ? "center" : undefined,
                }}
            >
                {activeComponent}
            </SSOWrapper>
        </>
    )
}
