import React from "react"
import {reportsStore} from "../common/stores/ReportStore"
import {AvantPopperMenuItem} from "../../generic-components/AvantPopperMenu"
import {ReportsPrintButton} from "../common/components/ReportsPrintButton"
import {Print} from "@material-ui/icons"
import {Button} from "@material-ui/core"
import {ReportSubPaths} from "../ReportSubPaths"
import {PlaceReportGraphFilters} from "../common/components/AvantNewDatePicker/AvantNewDateRangePickerHelpers"
import {observer} from "mobx-react"

interface PLACEReportPrintMenuProps {
    filters: PlaceReportGraphFilters
    disableMenu: boolean
}

export const PLACEReportPrintMenu: React.FC<PLACEReportPrintMenuProps> = observer(({filters, disableMenu}) => {
    const numSelected = reportsStore.selectedTakeIds.length

    if (disableMenu) {
        return (
            <Button disabled={true}>
                <Print/>
            </Button>
        )
    }

    const menuContents: AvantPopperMenuItem[] = [
        {
            name: "Group Report",
            submenuItems: [
                {
                    name: "List",
                    link: ReportSubPaths.classPrintList(filters)
                }
            ]
        },
        {
            name: "Individual Reports",
            submenuItems: [
                {
                    name: "All",
                    link: ReportSubPaths.individualPrintAll()
                },
                {
                    name: `Selected - ${numSelected}`,
                    link: numSelected > 0 ? ReportSubPaths.individualPrintSelected() : ""
                }
            ]
        }
    ]

    return <ReportsPrintButton contents={menuContents} />
})
