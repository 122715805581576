
import * as React from "react"

export interface IProfileLanguage {
    name: string
    contentAreaId: number
}

export interface IProfileTest {
    name: string
    dbName: string
}

class PlaceProfileConstants extends React.Component {

    // TODO: Fix this, get this list from the API. These are all now available in the API we should make a call to get this list and give it to the front end.
    static languages: IProfileLanguage[] = [
        {name: "", contentAreaId: -1},
        {name: "Japanese", contentAreaId: 1},
        {name: "Spanish", contentAreaId: 2},
        {name: "French", contentAreaId: 3},
        {name: "German", contentAreaId: 4},
        {name: "English", contentAreaId: 5},
        {name: "Chinese - Simplified", contentAreaId: 6},
        {name: "Chinese - Traditional", contentAreaId: 7},
        {name: "Turkish", contentAreaId: 8},
        {name: "Hebrew", contentAreaId: 9},
        {name: "Hindi", contentAreaId: 10},
        {name: "African Languages", contentAreaId: 11},
        {name: "Italian", contentAreaId: 12},
        {name: "Yoruba", contentAreaId: 13},
        // {name: "Iraqi Arabic", contentAreaId: 14},
        {name: "Urdu", contentAreaId: 14},
        // {name: "Persian-Iranian/Farsi", contentAreaId: 15},
        {name: "Korean", contentAreaId: 15},
        //{name: "African Lang", contentAreaId: 16},
        // {name: "Persian-Afghan/Dari", contentAreaId: 16},
        {name: "Persian-Farsi", contentAreaId: 17},
        // {name: "Russian", contentAreaId: 17},
        {name: "Modern Standard Arabic", contentAreaId: 18},
        {name: "Uighur", contentAreaId: 19},
        {name: "Malay", contentAreaId: 20},
        // {name: "Khmer", contentAreaId: 21},
        {name: "Russian", contentAreaId: 21},
        // {name: "Hausa", contentAreaId: 22},
        {name: "Cantonese", contentAreaId: 22},
        {name: "Bengali", contentAreaId: 24},
        {name: "Ibo", contentAreaId: 25},
        {name: "Kazakh", contentAreaId: 26},
        // {name: "Korean", contentAreaId: 27},
        {name: "Greek", contentAreaId: 28},
        {name: "Indonesian", contentAreaId: 29},
        {name: "Thai", contentAreaId: 30},
        {name: "Vietnamese", contentAreaId: 31},
        {name: "Croatian", contentAreaId: 32},
        {name: "Portuguese", contentAreaId: 33},
        {name: "Punjabi", contentAreaId: 34},
        {name: "Tamil", contentAreaId: 35},
        {name: "Somali", contentAreaId: 36},
        // {name: "Swahili", contentAreaId: 37},            // Dupe
        {name: "Bosnian", contentAreaId: 38},
        {name: "Polish", contentAreaId: 39},
        {name: "Serbian", contentAreaId: 40},
        {name: "Algerian", contentAreaId: 41},
        {name: "Javanese", contentAreaId: 42},
        // {name: "Urdu", contentAreaId: 43},
        {name: "Portuguese-Brazilian", contentAreaId: 44},
        {name: "Filipino", contentAreaId: 45},
        {name: "Tagalog", contentAreaId: 46},
        {name: "Serbian-Croatian", contentAreaId: 47},
        {name: "Yup'ik", contentAreaId: 92},
        {name: "Somali Maxaa", contentAreaId: 93},
        {name: "Somali Maay Maay", contentAreaId: 94},
        {name: "OTHER", contentAreaId: 99},
        {name: "Ilocano", contentAreaId: 100},
        {name: "Haitian Creole", contentAreaId: 101},
        {name: "Armenian", contentAreaId: 102},
        {name: "Samoan", contentAreaId: 103},
        {name: "Amharic", contentAreaId: 104},
        {name: "Hmong", contentAreaId: 105},
        {name: "Chin (Hakha)", contentAreaId: 106},
        {name: "Czech", contentAreaId: 107},
        {name: "Telugu", contentAreaId: 108},
        {name: "Marathi", contentAreaId: 109},
        {name: "Marshallese", contentAreaId: 110},
        {name: "Chuukese", contentAreaId: 111},
        {name: "Navajo", contentAreaId: 112},
        {name: "Kannada", contentAreaId: 115},
        {name: "Hawaiian", contentAreaId: 116},
        {name: "Zomi", contentAreaId: 117},
        {name: "American Sign Language", contentAreaId: 120}, //120 to "American Sign Language",
        {name: "Cabo Verdean", contentAreaId: 121},
        {name: "Latin", contentAreaId: 122},
        {name: "Ukrainian", contentAreaId: 123},
        {name: "Chaldean", contentAreaId: 130},
        {name: "Pashto", contentAreaId: 131},
        {name: "Khmer", contentAreaId: 132},
        {name: "Nepali", contentAreaId: 133},
        {name: "Tigrinya", contentAreaId: 134},


      

    ]

    static getLanguageNameById(languageId: number | null) {
        // Find a language that matches the passed ID. We assume here that contentAreaId values are unique.
        const matchingLanguage = PlaceProfileConstants.languages.find(iProfileLanguage => {
            return iProfileLanguage.contentAreaId === languageId
        })

        // Return the language name as a string, or null if nothing was found.
        if (matchingLanguage) {
            return matchingLanguage.name
        }
        else {
            return null
        }
    }

    static zeroToTen: string[] = [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10"
    ]

    static zeroToTwenty: string[] = [
        "",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20"
    ]

    static grades: string[] = [
        "None",
        "K",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "College/University"
    ]

    static tests: IProfileTest[] = [
        {name: "None", dbName: "none"},
        {name: "AP", dbName: "test_ap"},
        {name: "IB", dbName: "test_ib"},
        {name: "SAT II", dbName: "test_satii"},
        {name: "STAMP", dbName: "test_stamp"},
        {name: "OPI", dbName: "test_opi"},
        {name: "WPT", dbName: "test_wpt"},
        {name: "OPIc", dbName: "test_opic"}
    ]

    static testList: string[] = [
        "None",
        "AP",
        "IB",
        "SAT II",
        "STAMP",
        "OPI",
        "WPT",
        "OPIc",
    ]

    static familyMembers: string[] = [
        "Mother",
        "Father",
        "Grandmother",
        "Grandfather",
        "Aunt",
        "Uncle",
        "Other"
    ]

    static radioButonValues: string[] = [
        "Yes",
        "No"
    ]

}

export default (PlaceProfileConstants)
