import * as React from "react"
import {Box} from "@material-ui/core"

export const AvantVerticalDivider = ({style}: {style?: React.CSSProperties}) => (
    <Box display={"flex"} flexDirection={"column"} style={style}>
        <Box
            boxSizing={"border-box"}
            flexGrow={1}
            width={0}
            style={{outline: "1px solid #E8E8E8"}}
        />
    </Box>
)
