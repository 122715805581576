import {observer} from "mobx-react"
import React from "react"
import {loadingSpinnerStore} from "./LoadingSpinnerStore"

@observer
export class LoadingSpinner extends React.Component {

    render() {
        return (!loadingSpinnerStore.stopAll &&
            <div className="loader-pane" hidden={loadingSpinnerStore.hideLoadingSpinner} data-tst-id="loading-spinner">
                <div className="loader"/>
            </div>
        )


    }
}
