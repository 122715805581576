import {observer} from "mobx-react"
import React from "react"
import FontAwesome from "react-fontawesome"
import {loadingSpinnerStore} from "../app/common/loaders/LoadingSpinnerStore"

interface IInheritedProps {
    match: any
    history: any
    location: any
}

type TComponentProps = IInheritedProps

@observer
export class PageNotFound extends React.Component<TComponentProps, {}> {
    componentDidMount() {
        loadingSpinnerStore.hideLoadingSpinner = true
    }

    render() {
        const requestedUrl: string = `The requested URL ${this.props.location.pathname} was not found on this server.`
        return (
            <div className="content-container">
                <div className="page-not-found-content">
                    <div className="page-not-found-message">
                        <FontAwesome className="warning-icon" name="exclamation-triangle"/>
                        <p>
                            <b>404.</b> That's an error.
                        </p>
                        <br/>
                        <p>{requestedUrl}</p>
                        <p>Use your browser's back button to return to the previous page.</p>
                    </div>
                </div>
            </div>
        )
    }
}
