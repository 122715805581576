/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import { Box, Paper } from "@material-ui/core"
import React, { useState } from "react"
import { ItemContent } from "../../app/common/item/models/ItemContent"
import { BodyText } from "../../styles/AvantTypography"
import { AppConfig } from "../../types/types"
import ItemVideoPlayer, { ItemVideoPlayerSource } from "./ItemVideoPlayer"

declare const appConfigs: AppConfig

interface ItemVideoProps {
    itemContent: ItemContent,
    contentAreaId: number,
}

export default function ItemVideo(props: ItemVideoProps) {

    const { itemContent, contentAreaId } = props

    const videoFileName = itemContent.content

    const itemVideoSources: ItemVideoPlayerSource[] = [
             {
                 src: `${appConfigs.MEDIA_BASE_URL}/${contentAreaId}/nonmcmovie/${videoFileName}`,
                 type: "video/mp4"
             }
         ]

    // playCount is used to both dispose the ItemVideoPlayer and render out some text to the screen when
    // the playCount is >= 2
    const [playCount, setPlayCount] = useState(0)

    // ********** Helper Functions **********

    // Helper function to increase playCount is defined here and passed down to ItemVideoPlayer so that the playCount
    // can increase when the video ends.
    function increasePlayCount() {
        setPlayCount(playCount + 1)
    }

    // ********* End Helper Functions *********

    if (playCount >= 2) {
        return (
            <Paper style={{ padding: "20px"}}>
                <BodyText fontWeight="bold" lineHeight="2">
                    You have reached the maximum number of times you may watch the video.
                </BodyText>
                <BodyText>
                    Select an answer and click NEXT to move to the next question.
                </BodyText>
            </Paper>
        )
    } else {
        return (
            <Box
                className="item-video-container"
                display="flex"
                justifyContent="center"
                alignItems="center"
                key={itemContent.id.toString()}
            >
                <ItemVideoPlayer
                    autoplay={false}
                    controls={true}
                    fill={true}
                    aspectRatio={"16:9"}
                    sources={itemVideoSources}
                    playCount={playCount}
                    increasePlayCount={increasePlayCount}
                />
            </Box>
        )
    }
}