import React from "react"
import {TrainingContent} from "../../app/advance/training-content/TrainingContent"
import {AuthUser} from "../../app/common/authentication/models/AuthUser"
import {ItemContent} from "../../app/common/item/models/ItemContent"
import ItemVideoRecorder from "../ItemVideoRecorder/ItemVideoRecorder"

import {ProductDriver} from "../../app/common/products/ProductStore"
import {
    Answer,
    DefaultVoiceRecorder,
    IItem,
    ItemContentTypeEnum,
    ItemFormatEnum,
    LoginProductContentAreaData,
    RecorderEnum,
    RecordWarningEnum,
    TestEngineOptions,
    TLocalAnswer,
    VideoContent,
    VOICE_RECORDER
} from "../../types/types"
import FillInTheBlank from "../FillInTheBlank/FillInTheBlank"
import MultiSelectGroup from "../MultiSelectGroup/MultiSelectGroup"
import PassageSelect from "../PassageSelect/PassageSelect"
import PictureClick from "../PictureClick/PictureClick"
import ResponsePrompt from "../ResponsePrompt/ResponsePrompt"
import SingleSelectGroup from "../SingleSelectGroup/SingleSelectGroup"
import Speaking from "../Speaking/Speaking"
import Writing from "../Writing/Writing"
import YesNo from "../YesNo/YesNo"
import ZoneClick from "../ZoneClick/ZoneClick"

interface ResponsePanelProps {
    item: IItem
    takeId?: number
    panelId?: number
    isRtlLayout: boolean
    authUser: AuthUser
    driver: ProductDriver
    languageName: string
    config: TestEngineOptions
    showChoices: boolean
    isAdmin: boolean
    correctAnswer: ItemContent | null
    answer: TLocalAnswer
    prevAnswer: Answer | null
    fillInTheBlankAnswers: string[]
    correctAnswers: string[]
    recordingWarningSeen: RecordWarningEnum
    recorderSupported: RecorderEnum
    recordingSidsToSave: string
    autoSaving: boolean
    product: LoginProductContentAreaData

    voiceRecorder?: VOICE_RECORDER
    handleUpdateItemFile: (itemContentId: number, content: string | VideoContent) => void
    handleLocalAnswerChange: (localAnswer: TLocalAnswer) => void
    handleEditCorrectAnswer: (newCorrectAnswer: ItemContent, oldCorrectAnswer: ItemContent) => Promise<void>
    setFillInTheBlankAnswers: (fillInTheBlankAnswers: string[]) => void
    setFillInTheBlankCount: (count: number) => void
    setRecorderSupported: (recorder: RecorderEnum) => void
    setRecordingSidsToSave: (recordingSids: string) => void
    setSeenRecordingWarning: (state: RecordWarningEnum) => void
    setResponseContent: (instance: HTMLDivElement) => void
}

export default class ResponsePanel extends React.Component<ResponsePanelProps, {}> {

    hasAudio = (choices: ItemContent[]): boolean => {
        let hasAudio: boolean = false
        choices.forEach((choice: ItemContent) => {
            if (choice.type.includes(ItemContentTypeEnum.TEXT_SEND) || choice.type.includes(ItemContentTypeEnum.SOUND_TEXT)) {
                hasAudio = true
            }
        })
        return hasAudio
    }

    renderChoices = () => {
        // For Advance
        if (this.props.config.sortAnswerChoices) {
            this.props.item.choices.sort((a, b) => {
                if (a.distractorOrder > b.distractorOrder) {
                    return -1
                }
                if (a.distractorOrder < b.distractorOrder) {
                    return 1
                }
                if (a.distractorOrder === b.distractorOrder) {
                    throw new Error("Two items distractorOrders should not equal.")
                }
                return 0
            })
        }

        let isMcPictureItem = false
        if (this.props.item.format === ItemFormatEnum.MULTIPLE_CHOICE) {
            this.props.item.choices.forEach((choice: ItemContent) => {
                if (choice.type === ItemContentTypeEnum.PLAIN_IMAGE) {
                    isMcPictureItem = true
                }
            })
        }

        let out
        switch (this.props.item.format) {
            case ItemFormatEnum.MULTIPLE_CHOICE:
                if (!isMcPictureItem) {
                    out = (
                        <SingleSelectGroup
                            isAdmin={this.props.isAdmin}
                            item={this.props.item}
                            answer={this.props.answer}
                            prevAnswer={this.props.prevAnswer}
                            choices={this.props.item.choices}
                            config={this.props.config}
                            correctAnswers={this.props.correctAnswers}
                            driver={this.props.driver}
                            panelId={this.props.panelId}
                            takeId={this.props.takeId}
                            handleAnswerChange={this.props.handleLocalAnswerChange}
                            handleEditCorrectAnswer={this.props.handleEditCorrectAnswer}
                            hasAudio={this.hasAudio}
                            product={this.props.product}
                        />
                    )
                } else {
                    out = (
                        <PictureClick
                            item={this.props.item}
                            answer={this.props.answer}
                            product={this.props.product}
                            handleAnswerChange={this.props.handleLocalAnswerChange}
                        />
                    )
                }
                break
            case ItemFormatEnum.CHECKBOX:
                out = (
                    <MultiSelectGroup
                        item={this.props.item}
                        choices={this.props.item.choices}
                        config={this.props.config}
                        correctAnswers={this.props.correctAnswers}
                        answer={this.props.answer}
                        prevAnswer={this.props.prevAnswer}
                        handleAnswerChange={this.props.handleLocalAnswerChange}
                        hasAudio={this.hasAudio}
                        product={this.props.product}
                    />
                )
                break
            case ItemFormatEnum.PASSAGE_SELECT:
                out = (
                    <PassageSelect
                        item={this.props.item}
                        isRtlLayout={this.props.isRtlLayout}
                        answer={this.props.answer}
                        product={this.props.product}
                    />
                )
                break
            case ItemFormatEnum.BLANK:
                out = ""
                break
            case ItemFormatEnum.ZONE_CLICK:
                out = (
                    <ZoneClick
                        item={this.props.item}
                        answer={this.props.answer}
                        product={this.props.product}
                        handleAnswerChange={this.props.handleLocalAnswerChange}
                    />
                )
                break
            case ItemFormatEnum.SPEAKING_ORAL:
                out = (
                    <Speaking
                        item={this.props.item}
                        languageName={this.props.languageName}
                        localAnswer={this.props.answer}
                        driver={this.props.driver}
                        recorderSupported={this.props.recorderSupported}
                        recordingWarningSeen={this.props.recordingWarningSeen}
                        recordingSidsToSave={this.props.recordingSidsToSave}
                        autoSaving={this.props.autoSaving}
                        voiceRecorder={this.props.voiceRecorder || DefaultVoiceRecorder}
                        handleAnswerChange={this.props.handleLocalAnswerChange}
                        setSeenRecordingWarning={this.props.setSeenRecordingWarning}
                        setRecorderSupported={this.props.setRecorderSupported}
                        setRecordingSidsToSave={this.props.setRecordingSidsToSave}
                        onOggBlobAvailable={this.props.handleLocalAnswerChange}
                    />
                )
                break
            case ItemFormatEnum.PICTURE_CLICK:
                out = (
                    <PictureClick
                        item={this.props.item}
                        answer={this.props.answer}
                        product={this.props.product}
                        handleAnswerChange={this.props.handleLocalAnswerChange}
                    />
                )
                break
            case ItemFormatEnum.WRITING:
                const prevAnswer = this.props.answer ? (this.props.answer as string) : ""
                out = (
                    <Writing
                        answer={prevAnswer}
                        languageName={this.props.languageName}
                        autoSaving={this.props.autoSaving}
                        contentAreaId={parseInt(this.props.item.languageId, 10)}
                        handleAnswerChange={this.props.handleLocalAnswerChange}
                        product={this.props.product}
                    />
                )
                break
            case ItemFormatEnum.YES_NO:
                out = (
                    <YesNo
                        item={this.props.item}
                        driver={this.props.driver}
                        handleLocalAnswerChange={this.props.handleLocalAnswerChange}
                    />
                )
                break
            case ItemFormatEnum.FILL_IN_THE_BLANK:
                const prevAnswers = this.props.answer ? (this.props.answer as string[]) : []
                out = (
                    <FillInTheBlank
                        item={this.props.item}
                        answers={prevAnswers}
                        handleAnswersChange={this.props.handleLocalAnswerChange}
                        setBlankCount={this.props.setFillInTheBlankCount}
                    />
                )
                break
            case ItemFormatEnum.VIDEO_RECORDING:
                out = (
                    <ItemVideoRecorder
                        handleAnswerChange={this.props.handleLocalAnswerChange}
                       
                    />
                )
                break
            default:
                out = (
                    <div>
                        {`format: ${this.props.item.format} isn't supported currently. item ${this.props.item.name} `}
                    </div>
                )
                // tslint:disable-next-line
                console.error(`DID NOT RECOGNIZE ITEMCHOICE FORMAT: ${this.props.item.format}`)
                break
        }

        return <div className="item-choices-wrapper">{out}</div>
    }

    render() {
        const {
            config,
            item,
            setResponseContent,
            isRtlLayout,
            showChoices,
            answer,
            correctAnswer,
            authUser,
            product,
            driver
        } = this.props

        let submitOnNavigate = config.submitOnNavigate
        if (submitOnNavigate === undefined) {
            submitOnNavigate = true
        }

        if (!submitOnNavigate && item.training === undefined) {
            throw Error("Trying to show training content when there is none.")
        }
        const hasTrainingContent: boolean = !submitOnNavigate && item.training !== undefined

        const isZoneClick = item.format === ItemFormatEnum.ZONE_CLICK

        return (
            <div className="response-panel__content-container" ref={(node: HTMLDivElement) => setResponseContent(node)}>
                <div className="response-panel__content">
                    <ResponsePrompt item={item} isRtlLayout={isRtlLayout} product={product} driver={driver}/>
                </div>

                <div className={!isZoneClick ? "response-panel__content" : "response-panel__zone-click-content"}>
                    {showChoices && this.renderChoices()}
                </div>

                {hasTrainingContent && (
                    <div className="response-panel__content">
                        <TrainingContent
                            item={item}
                            userAnswerId={answer}
                            correctAnswer={correctAnswer}
                            authUser={authUser}
                        />
                    </div>
                )}
            </div>
        )
    }
}
