import React from "react"

type ProfileProgressCircleState = "inactive" | "active" | "visited"

export interface IProfileProgressCircle {
    state: ProfileProgressCircleState
}

interface IComponentProps {
    circles: IProfileProgressCircle[]
}

export default class ProfileProgress extends React.Component<IComponentProps, {}> {
    constructor(props: IComponentProps) {
        super(props)
    }

    render() {
        return (
            <div className="profile-progress">
                <div>
                    {this.props.circles.map((circle: IProfileProgressCircle, index: number) => {
                        let circleClassName = ""
                        let barClassName = ""
                        switch (circle.state) {
                            case "inactive":
                                circleClassName = "profile-progress__circle--inactive"
                                barClassName = "profile-progress__bar--inactive"
                                break
                            case "active":
                                circleClassName = "profile-progress__circle--active"
                                barClassName = "profile-progress__bar--inactive"
                                break
                            case "visited":
                                circleClassName = "profile-progress__circle--visited"
                                barClassName = "profile-progress__bar--active"
                                break
                        }
                        return (
                            <>
                                <div key={Math.random()} className={circleClassName}>
                                    {circle.state === "visited" && (
                                        <div className="check-container">
                                            <i className="material-icons check-container__check">done</i>
                                        </div>
                                    )}
                                </div>
                                {index < this.props.circles.length - 1 && (
                                    <div key={Math.random()} className={barClassName} />
                                )}
                            </>
                        )
                    })}
                </div>
            </div>
        )
    }
}
