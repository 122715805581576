
import {MenuItem} from "@material-ui/core"
import moment, {Moment} from "moment"
import {ReactNode} from "react"
import * as React from "react"
import {log} from "../../../../../util/Logging"
import {GroupMaster} from "../../../../common/group/models/GroupMaster"
import {LanguageList} from "../../../PlaceFamily/PLACEReportPage"
import {GroupData} from "../../../individual/components/IndividualReportHeader"

interface DateAndMoment {
    asDate?: Date,
    asMoment?: Moment,
    valid: boolean
}

export interface PlaceTestInstanceListGroupNames {
    C: string,
    L: string,
    ST: string
}

export interface PlaceTestInstanceListGroupIds {
    C: number,
    L: number,
    ST: number
}

export interface PlaceReportGraphFilters {
    groupId: number
    productId: number
    startDate: number | null
    endDate: number | null

    assessmentStart: boolean
    recentActivity: boolean
}

export interface PlaceSearchFilters {
    selectedLocationId: number,
    // LocationIdList is a list of Group IDs for Location group types
    locationIdList: number[],
    contentAreaId: number,
    firstName?: string,
    lastName?: string,
    loginName?: string,
    dateRangeFilters: PlaceReportGraphFilters
}

export interface TakeProfileJson {
    grade: number | null,
    login: string | null,
    phone: string | null,
    scale: number | null,
    tests: string[],
    years: number | null,
    grades: string[],
    cando_1: number | null,
    cando_2: number | null,
    cando_3: number | null,
    cando_4: number | null,
    cando_5: number | null,
    lastName: number | null,
    yourName: string | null,
    firstLang: string | null,
    firstName: string,
    testTakerId: string | null,
    yearsAbroad: string | null,
    yearsSpoken: string | null,
    candoAverage: number | null,
    monthsAbroad: string | null,
    contentAreaID: number,
    familymembers: string[],
    studiedAbroad: string | null,
    testSheetLevel: string | null,
    testTakerEmail: string | null,
    familyLanguageId: number | null,
    profileCompleted: boolean | null,
    famSpeaksTestLang: string | null,
    foreignLangAtHome: string | null,
    howOftenLangIsSpoke: string | null
}

export interface PlaceTestTakerInstanceReport {
    oppcode: number,
    product_package: string | undefined,
    productid: number,
    username: string,
    contentareaid: number,
    loginid: number,
    takeid: number,
    code: string,
    specialresultscode: string,
    firstname?: string,
    lastname?: string,
    assessment_start_time: number,
    assessment_finish_time?: number,
    levels: Map<string, (string | null)>,
    scaledscore: Map<string, number>,
    skill_count: number,
    levels_raw: Map<string, (string | null)>,
    panelTimes: Map<string, (number | null)>,
    takepanels: Map<string, number>,
    compositescore?: string,
    total_average?: string,
    profile?: TakeProfileJson,
    duration: number | undefined,
    group_data: GroupData[]
}

export class AvantNewDateRangePickerHelpers {

    // This regex matches valid input for the text boxes dates allowing for underscores and slashes
    private static readonly validDateRangeRegEx: RegExp = RegExp("^([0-1_][0-9_]\\/[0-3_][0-9_]\\/[0-9_]{4})$")

    /**
     * This method is to allow a user to manually enter their date range, this will check the input and ensure it is
     * valid. If valid the date range will be updated, else it will return a valid field of false
     * @param curDateRange The US date string "MM/DD/YYYY" that needs to update the range
     */
    static validateTextDateRange = (curDateRange: string): DateAndMoment => {

        const validInput: boolean = AvantNewDateRangePickerHelpers.validDateRangeRegEx.test(curDateRange)

        if (validInput) {
            const month = Number(curDateRange.substr(0, 2).replace("_", ""))
            const day = Number(curDateRange.substr(3, 2).replace("_", ""))
            const year = Number(curDateRange.substr(6, 4).replace("_", ""))

            log.debug(`Month: ${month}`)
            log.debug(`day: ${day}`)
            log.debug(`Year: ${year}`)

            const fixedYear = isNaN(year) ? new Date().getFullYear() : year
            const fixedDay = isNaN(day) ? new Date().getDay() : day
            const fixedMonth = isNaN(month) ? new Date().getMonth() : month

            const newDate = new Date(`${fixedMonth}/${fixedDay}/${fixedYear}`)

            log.debug(`NewDate: ${newDate}`)

            if (newDate) {
                return ({
                    asDate: newDate,
                    asMoment: moment(newDate),
                    valid: true
                })
            } else {
                return ({
                    asDate: undefined,
                    asMoment: undefined,
                    valid: false
                })
            }


        } else {
            return ({
                asDate: undefined,
                asMoment: undefined,
                valid: false
            })
        }

    }

    static makeSelectDropDownOptions = (list: GroupMaster[] | LanguageList[], locationList: boolean): ReactNode[] => {

        const moreThanOne = list.length > 1
        const onlyOne = list.length == 1
        const resultArray = []
        const label = locationList ? "Locations" : "Languages"

        if (moreThanOne) {
            resultArray.push((
                <MenuItem
                    aria-label={`Location Select All ${label}`}
                    value={0}
                    key={0}
                >
                    All {label}
                </MenuItem>
            ))

            list.sort((a1: GroupMaster | LanguageList, a2: GroupMaster | LanguageList) => {
                if (a1.name.toLowerCase() > a2.name.toLowerCase()) {
                    return 1
                }
                if (a1.name.toLowerCase() < a2.name.toLowerCase()) {
                    return -1
                }
                return 0
            }).forEach((item: GroupMaster | LanguageList) => {
                resultArray.push (
                    <MenuItem
                        aria-label={`Location Select ${item.name}`}
                        value={item.id}
                        key={item.id}
                    >
                        {item.name}
                    </MenuItem>
            )
            })
        } else if(onlyOne) {
            resultArray.push(
                <MenuItem
                    aria-label={`Location Select ${list[0].name}`}
                    value={list[0].id}
                    key={list[0].id}
                >
                    {list[0].name}
                </MenuItem>
            )
        }

        return resultArray
    }
}
