import React, {useEffect, useState} from "react"
import {takePanelStore} from "../../../common/take-panel/TakePanelStore"
import {messageStore} from "../../../common/messages/MessageStore"
import {ApiItemResponse, ApiPanel, ItemFormatEnum, PanelLevel} from "../../../../types/types"
import {AvantCircularProgress, CircularProgressSize} from "../../../common/loaders/AvantCircularProgress"
import {Divider, List, ListItem} from "@material-ui/core"
import {theme} from "../../../../styles/MuiThemes"
import {ApiTakePanel} from "../../../common/take-panel/models/ApiTakePanel"
import {LinkText, SmallBodyText} from "../../../../styles/AvantTypography"
import {SidebarResponseModal} from "./SidebarResponseModal"
import {AvantColors} from "../../../../styles/AvantColors"
import {sidebarStore} from "./SideBarStore"

interface SidebarResponsesContainerProps {
    takePanelId: number,
    fullName: string,
}

const SidebarResponsesContainer = (props: SidebarResponsesContainerProps) => {
    const [detailedTakePanel, setDetailedTakePanel] = useState<ApiTakePanel>()
    const [responses, setResponses] = useState<ApiItemResponse[]>([])

    useEffect(() => {
        fetchDetailedTakePanel(props.takePanelId)
            .catch((err) => {
                messageStore.setErrorMessage(err)
            })

        async function fetchDetailedTakePanel(takePanelId: number) {
            const takePanel: ApiTakePanel | undefined = await takePanelStore.getDetailedTakePanel(takePanelId)
            if (takePanel) {
                // Filter responses that aren't from sample items or practice items
                const responses: ApiItemResponse[] = takePanel.responses.filter((response: ApiItemResponse) => {
                    return response.itemFormat !== ItemFormatEnum.BLANK &&
                        !response.itemName.toLowerCase().includes("sample") &&
                        !response.itemName.toLowerCase().includes("practice")
                }).sort((responseA: ApiItemResponse, responseB: ApiItemResponse) => responseA.id - responseB.id)

                for (const response of responses) {
                    if (response.itemFormat === ItemFormatEnum.VIDEO_RECORDING) {
                        response.respondedValue = await sidebarStore.getSecuredAsset(response.respondedValue)
                    }
                }

                setDetailedTakePanel(takePanel)
                setResponses(responses)
            }
        }
    }, [])

   if (!responses.length || detailedTakePanel === undefined) {
       return <AvantCircularProgress size={CircularProgressSize.SMALL} />
   }

    return (
        <List
            dense
            style={{padding: theme.spacing(3)}}
        >
            <Divider style={{marginBottom: theme.spacing(2)}}/>
            {responses.map((response: ApiItemResponse, idx: number) => {
                const score: string = detailedTakePanel.responseLevels ? detailedTakePanel.responseLevels[response.id] : ""
                const benchmark: string = detailedTakePanel.responseBenchmarks ? detailedTakePanel.responseBenchmarks[response.id] : ""

                let indicatorOrReason: string = ""

                if (detailedTakePanel.responseStrengthIndicators) {
                    indicatorOrReason = detailedTakePanel.responseStrengthIndicators[response.id]
                }
                if (score === PanelLevel.PANEL_NOT_RATABLE || score === PanelLevel.PANEL_LEVEL_0) {
                    indicatorOrReason = detailedTakePanel.responseReasonCodes ? detailedTakePanel.responseReasonCodes[response.id] : ""
                }

                return (
                    <SidebarResponse
                        key={response.id}
                        takePanelId={detailedTakePanel.id}
                        response={response}
                        responseNumber={idx+1}
                        fullName={props.fullName}
                        panel={detailedTakePanel.panel}
                        score={score}
                        indicatorOrReason={indicatorOrReason}
                        benchmark={benchmark}
                    />
                )
            })}
        </List>
    )
}

interface SidebarResponseProps {
    takePanelId: number,
    response: ApiItemResponse,
    responseNumber: number,
    fullName: string,
    panel: ApiPanel,
    score?: string,
    indicatorOrReason?: string,
    benchmark?: string,
}

const SidebarResponse = (props: SidebarResponseProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const {response} = props

    let disabled: boolean = false
    if (response.respondedValue === null || (response.respondedValue.length === 0 && response.itemFormat !== ItemFormatEnum.SPEAKING_ORAL)) {
        disabled = true
    }

    return (
        <>
            <ListItem disableGutters={true}>
                <LinkText
                    disabled={disabled}
                    style={{
                        color: disabled ? "#585858" : AvantColors.REPORT_LINK_TEXT_BLUE,
                        cursor: "pointer",
                        paddingRight: theme.spacing(2),
                        whiteSpace: "nowrap",
                    }}
                    onClick={() => setIsOpen(true)}
                >
                    Response {props.responseNumber}
                </LinkText>
                {props.score && <SmallBodyText>Score: {props.score} {props.indicatorOrReason} {props.benchmark}</SmallBodyText>}
            </ListItem>
            <SidebarResponseModal
                close={() => setIsOpen(false)}
                isOpen={isOpen}
                {...props}
            />
        </>
    )
}

export default SidebarResponsesContainer
