import {default as React} from "react"
import {
    Dialog,
    DialogTitle, Grid, IconButton, Typography
} from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import ApiService from "../../../services/ApiService"
import {SSOStudentInClass} from "../../../types/types"
import MaterialTable from "material-table"
import {loadingSpinnerStore} from "../../common/loaders/LoadingSpinnerStore"

const modalStyles ={
    dialogTitle: {
        backgroundColor: '#f3f3f3',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    closeButton: {
        marginRight: '-15px'
    },
    nameOfClassroom: {
        color: "#767676"
    }
};

export interface SSOTeacherViewRosterProps {
    onClose: () => void,
    groupId: number | null
    groupName: string | null
}

interface SSOTeacherViewRosterState {
    list: SSOStudentInClass[] | null
}

export class SSOTeacherViewRoster extends React.Component<SSOTeacherViewRosterProps, SSOTeacherViewRosterState, {}> {

    constructor(props: any) {
        super(props)
        this.state = {
            list: null
        }
    }


    componentDidMount(): void {
        if (this.props.groupId) {
            loadingSpinnerStore.hideLoadingSpinner = false
            ApiService.getGroupRoster(this.props.groupId)
                .then(response => {
                    loadingSpinnerStore.hideLoadingSpinner = true
                    if (response.length) {
                        this.setState({list: response})
                    }
                })
        }
    }

    handleClose = ():void => {
        this.props.onClose()
    }

    render() {
        const classList = this.state.list  ? this.state.list : []
        return (
                <Grid container alignItems="center" spacing={5} justifyContent="center" direction="column" style={{padding: "10px",height:"65%", maxWidth: "400px"}}>
                    <Grid item xs={12}>
                        <Dialog fullWidth={true} maxWidth="sm" onClose={this.handleClose} aria-labelledby="Class Roster" open={true} >
                            <DialogTitle style={modalStyles.dialogTitle} disableTypography id="simple-dialog-title">
                                <div>
                                    <Typography variant="h6">Student List</Typography>
                                    <Typography style={modalStyles.nameOfClassroom}>{this.props.groupName}</Typography>
                                </div>
                                <IconButton style={modalStyles.closeButton} onClick={this.handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <MaterialTable
                                columns={[
                                    { title: "ID", field: "institutionId" },
                                    { title: "First Name", field: "firstName" },
                                    { title: "Last Name", field: "lastName" },
                                ]}
                                options={{
                                    thirdSortClick: false,
                                    showTitle: false,
                                }}
                                data={classList}
                            />

                        </Dialog>
                    </Grid>
                </Grid>
        )
    }
}
