import {findTakeSearchResultGroupNames, TakeSearchResult} from "../app/common/take/models/TakeSearchResult"
import {ApiTakePanelSearchResult} from "../app/common/take/TakeSearchStore"
import {PanelSkillEnum} from "../types/types"
import {REPORTS_MAX_MINUTES_TO_DISPLAY, STAMP_4S_PRODUCT_ID, StatusSymbols} from "./Constants"
import {TimeUtil} from "./TimeUtil"

// TODO: Rename this file or the other occurence of ReportUtil

// todo: This file will likely need extensive changes to account for any new products

export class ReportUtil {
    static takeReportCsvLink = (fileName: string, rows: string[], headerRow?: string) => {

        const csv: string[] = []
        if (headerRow) {
            csv.push(`${headerRow}\n`)
        }
        rows.filter(r => r.length > 0).forEach((r: string) => {
            csv.push(`${r}\n`)
        })
        const blob = new Blob(csv, {type: "text/csv;charset=utf8;"})
        const element = document.createElement("a")
        document.body.appendChild(element)
        element.setAttribute("href", window.URL.createObjectURL(blob))
        element.setAttribute("download", fileName)
        element.style.display = ""
        element.click()
        document.body.removeChild(element)
    }

    static takeReportColumns = (it: TakeSearchResult): { header: string, val?: string }[] => {
        const getColumn = (header: string, val?: string, onlyProductIds?: number[]): { header: string, val?: string } | undefined => {
            if (!onlyProductIds) {
                return {header, val}
            }
            if (onlyProductIds.indexOf(it.productId) !== -1) {
                return {header, val}
            } else {
                return undefined
            }
        }

        const getTimeTakenOnPanel = (tp: ApiTakePanelSearchResult): string => {

            let deltaTime: string | undefined

            if (tp != null && tp.startTime != null && tp.finishTime != null) {
                deltaTime = TimeUtil.deltaTimeInMinutesOrEmptyStr(Number(tp.startTime), Number(tp.finishTime), REPORTS_MAX_MINUTES_TO_DISPLAY)
            } else if (tp != null && tp.startTime != null) {
                deltaTime = undefined
            }
            return deltaTime != null ? deltaTime : ""
        }

        const convertPanelStatus = (status: string | undefined): string | undefined => {
            if (status == null) {
                return status
            } else {
                switch (status) {
                    case (StatusSymbols.TEST_STARTED): {
                        return (StatusSymbols.SECTION_IN_PROGRESS.valueOf())
                    }
                    case (StatusSymbols.TEST_COMPLETE): {
                        return (StatusSymbols.SCORING_IN_PROGRESS.valueOf())
                    }
                    case (StatusSymbols.SECTION_PENDING): {
                        
                        return "Pending"
                    }
                    case (StatusSymbols.SECTION_PRINTABLE): {
                        return "Printabled"
                    }
                    case (StatusSymbols.SECTION_PRINTED): {
                        return "Printed"
                    }
                    default: {
                        return status.replace("-", "")
                    }
                }
            }

        }

        const encodeCommasForCSV = (data: string | undefined) => {
            if (data == null) {
                return data
            } else if (data.search(",")) {
                return "\"" + data + "\""           // Output commas in CSV if there are commas in the strings.
            } else {
                return data
            }
        }


        const rdTakePanel = it.takePanels.filter((tp: ApiTakePanelSearchResult) => {
            return tp.skill === PanelSkillEnum.READING.valueOf()
        })[0]
        const readingDelta = getTimeTakenOnPanel(rdTakePanel)

        const wrTakePanel = it.takePanels.filter((tp: ApiTakePanelSearchResult) => {
            return tp.skill === PanelSkillEnum.WRITING.valueOf()
        })[0]
        const writingDelta = getTimeTakenOnPanel(wrTakePanel)
        const lsTakePanel = it.takePanels.filter((tp: ApiTakePanelSearchResult) => {
            return tp.skill === PanelSkillEnum.LISTENING.valueOf()
        })[0]
        const listeningDelta = getTimeTakenOnPanel(lsTakePanel)
        const spTakePanel = it.takePanels.filter((tp: ApiTakePanelSearchResult) => {
            return tp.skill === PanelSkillEnum.SPEAKING.valueOf() || tp.skill === PanelSkillEnum.SPEAKING_ORAL.valueOf()
        })[0]
        const speakingDelta = getTimeTakenOnPanel(spTakePanel)

        const withGroups = findTakeSearchResultGroupNames(it)

        const rows: ({ header: string, value?: string } | undefined)[] = []

        const assessmentStart = it.startTime != null ? TimeUtil.formatMilliToMonthDayYearHoursMinutesLong(Number(it.startTime)) : ""
        const assessmentFinishTime = it.finishTime != null ? TimeUtil.formatMilliToMonthDayYearHoursMinutesLong(Number(it.finishTime)) : ""

        let duration = ""

        if (it.durationMinutes != null && it.finishTime != null && it.startTime != null) {
            if (it.durationMinutes > REPORTS_MAX_MINUTES_TO_DISPLAY) {
                duration = REPORTS_MAX_MINUTES_TO_DISPLAY + "+ minutes"
            } else {
                duration = it.durationMinutes + " minutes"
            }
        }

        rows.push(getColumn("Test Code", it.testCode))
        rows.push(getColumn("Login Name", encodeCommasForCSV(it.code)))
        rows.push(getColumn("First Name", encodeCommasForCSV(it.firstName)))
        rows.push(getColumn("Last Name", encodeCommasForCSV(it.lastName)))
        rows.push(getColumn("State", encodeCommasForCSV(withGroups.stateName)))
        rows.push(getColumn("District", encodeCommasForCSV(withGroups.districtName)))
        rows.push(getColumn("School", encodeCommasForCSV(withGroups.schoolName)))
        rows.push(getColumn("Testing Group", encodeCommasForCSV(withGroups.classroomName)))
        rows.push(getColumn("Language", it.language))
        rows.push(getColumn("Grade", it.grade))
        rows.push(getColumn("Test Taker ID", encodeCommasForCSV(it.externalId)))


        rows.push(getColumn("Reading Score", convertPanelStatus(it.readingLevel)))
        rows.push(getColumn("Reading Scaled Score", it.readingScaledScore != null && it.readingScaledScore.toString() !== "0" ? it.readingScaledScore : "", [STAMP_4S_PRODUCT_ID.value()]))
        rows.push(getColumn("Reading Length of Time", readingDelta))

        rows.push(getColumn("Writing Score", convertPanelStatus(it.writingLevel)))
        rows.push(getColumn("Writing Length of Time", writingDelta))

        rows.push(getColumn("Listening Score", convertPanelStatus(it.listeningLevel)))
        rows.push(getColumn("Listening Scaled Score", it.listeningScaledScore != null && it.listeningScaledScore.toString() !== "0" ? it.listeningScaledScore : "", [STAMP_4S_PRODUCT_ID.value()]))
        rows.push(getColumn("Listening Length of Time", listeningDelta))

        rows.push(getColumn("Speaking Score", convertPanelStatus(it.speakingLevel), [STAMP_4S_PRODUCT_ID.value()]))
        rows.push(getColumn("Speaking Length of Time", speakingDelta, [STAMP_4S_PRODUCT_ID.value()]))


        rows.push(getColumn("Status", it.testStatus))
        rows.push(getColumn("Composite Score", it.compositeScore ? it.compositeScore.toFixed(2).toString() : "TBD"))
        rows.push(getColumn("Start Time", assessmentStart, [STAMP_4S_PRODUCT_ID.value()]))
        rows.push(getColumn("Test Length", duration, [STAMP_4S_PRODUCT_ID.value()]))
        rows.push(getColumn("Finish Time", assessmentFinishTime, [STAMP_4S_PRODUCT_ID.value()]))
        rows.push(getColumn("Language at Home", encodeCommasForCSV(it.firstLanguage), [STAMP_4S_PRODUCT_ID.value()]))
        rows.push(getColumn("Parents/Grandparents", encodeCommasForCSV(it.familySpeaks), [STAMP_4S_PRODUCT_ID.value()]))
        rows.push(getColumn("Frequency Spoken", encodeCommasForCSV(it.familyMembersOften), [STAMP_4S_PRODUCT_ID.value()]))
        rows.push(getColumn("Years Studied", encodeCommasForCSV(it.yearsOfStudy), [STAMP_4S_PRODUCT_ID.value()]))
        rows.push(getColumn("Lived/Studied Abroad", encodeCommasForCSV(it.studiedAbroad), [STAMP_4S_PRODUCT_ID.value()]))
        rows.push(getColumn("Length of Time",
            `${it.yearsAbroad ? it.yearsAbroad + " years" : ""} ${it.monthsAbroad ? it.monthsAbroad + " months" : ""} `,
            [STAMP_4S_PRODUCT_ID.value()])
        )
        rows.push(getColumn("Test Taker Email", it.testTakerEmail, []))
        rows.push(getColumn("DOB", it.dateOfBirth, []))
        rows.push(getColumn("Mother Speak", it.motherSpeak, []))
        rows.push(getColumn("Father Speak", it.fatherSpeak, []))
        rows.push(getColumn("Grandparents Speak", it.grandparentsSpeak, []))
        rows.push(getColumn("Age Started", it.ageStarted, []))
        rows.push(getColumn("Family Members Live", it.familyMembersLive, []))
        rows.push(getColumn("Studied Other", it.studiedOther, []))
        rows.push(getColumn("Studied Which", it.studiedWhich, []))
        rows.push(getColumn("Immersion", it.immersion, []))
        rows.push(getColumn("Immersion Years", it.immersionYears, []))
        rows.push(getColumn("Plan to Study", it.planToStudy, []))
        rows.push(getColumn("Other First Language", it.otherFirstLanguage, []))
        rows.push(getColumn("How Often Spoken", it.howOftenSpoken, []))
        rows.push(getColumn("Years Studying Or Spoken", it.yearsStudyingOrSpoken, []))
        rows.push(getColumn("Self Evaluation", it.selfEvaluation, []))
        rows.push(getColumn("Form", it.selfEvaluationForm, []))
        rows.push(getColumn("Reading Comprehension Level", it.readingComprehensionLevel, []))
        rows.push(getColumn("Reading Grammar Level", it.readingGrammarLevel, []))
        rows.push(getColumn("Suggested Placement", it.suggestedPlacement, []))
        rows.push(getColumn("Location", withGroups.classroomName, []))
        rows.push(getColumn("Reading Comp. Detail", it.readingCompDetail, []))
        rows.push(getColumn("Reading Grammar Detail", it.readingGrammarDetail, []))
        rows.push(getColumn("Grades Studied", it.gradesStudied, []))
        rows.push(getColumn("Foreign Lang. at Home", it.foreignLangAtHome, []))
        rows.push(getColumn("Proficiency Self-Assessment", it.proficiencySelfAssessment, []))

        rows.push(getColumn("Lexical", it.lexical, []))
        rows.push(getColumn("Lexical %", it.lexicalPct, []))
        rows.push(getColumn("Dictation", it.dictationLevel, []))
        rows.push(getColumn("Dictation %", it.dictationPct, []))
        rows.push(getColumn("Sent. Comp.", it.sentenceCompletionLevel, []))
        rows.push(getColumn("Sent. Comp. %", it.sentCompPct, []))
        rows.push(getColumn("Verb Conj.", it.verbConjugationLevel, []))
        rows.push(getColumn("Verb Conj. %", it.verbConjPct, []))
        rows.push(getColumn("Level", it.level, []))

        rows.push(getColumn("Translation", it.translationLevel, []))
        rows.push(getColumn("Translation %", it.translationPct, []))

        rows.push(getColumn("Tests Taken", it.testsTaken, []))
        rows.push(getColumn("Family Language", it.familyLanguage, []))
        rows.push(getColumn("Family Members", it.familyMembers, []))
        rows.push(getColumn("Other Family Members", it.otherFamilyMembers, []))
        rows.push(getColumn("Language Confidence", it.languageConfidence, []))


        return rows
            .filter((row) => row !== undefined)
            .map((row) => row!!)
    }

}

