import React, {useState} from "react"
import {AppBar, Tab, Tabs} from "@material-ui/core"
import {TabPanel} from "../DevTools/DevTools"
import {Proctor360Setup} from "./Proctor360"
import {P360SchedulingAdmin} from "../DevTools/P360Scheduling";
import {P360StudentSchedulesAdmin} from "../DevTools/P360StudentSchedulesAdmin";
import {P360CreateSchedule} from "../../../app/proctor/P360CreateSchedule/P360CreateSchedule";

const tabProps = (index: number) => {
    return {
        id: `devtool-tab-${index}`,
        "aria-controls": `dev tools  ${index}`,
    }
}

export const AdminProctor: React.FC = () => {
    const [tabIndex, updateTabIndex] = useState(0)

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        updateTabIndex(newValue)
    }

    return (
        <>
            <h1>Proctor Tools</h1>
            <AppBar position="static">
                <Tabs value={tabIndex} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Proctor 360" {...tabProps(0)} />
                    <Tab label="P360 Scheduling" {...tabProps(1)} />
                    <Tab label="P360 Sessions" {...tabProps(2)} />
                    <Tab label="P360 Create Schedule" {...tabProps(3)} />
                </Tabs>
            </AppBar>
            <TabPanel value={tabIndex} index={0}>
                <Proctor360Setup/>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <P360SchedulingAdmin/>
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <P360StudentSchedulesAdmin/>
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
                <P360CreateSchedule existingScheduleData={null} modalClose={() => {}}/>
            </TabPanel>
        </>
    )
}
