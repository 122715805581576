import {FormGroup, IconButton, TextField} from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import makeStyles from "@material-ui/core/styles/makeStyles"
import DeleteIcon from "@material-ui/icons/Delete"
import moment from "moment-timezone/moment-timezone-utils"
import React from "react"

const useP360SlotStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
    }
}))

interface P360ScheduleSlotProps {
    updateParent: (slotIndex: number, field: string, value: any) => void,
    startTime: string | undefined
    endTime: string | undefined
    seats: number
    indexKey: number
    addRemoveSlotIcon: boolean
    deleteSlot: (index: number) => void
    slotError: boolean
}

export const P360ScheduleSlot: React.FC<P360ScheduleSlotProps> = ({updateParent, startTime, endTime, seats, indexKey, addRemoveSlotIcon, deleteSlot, slotError}) => {
    const classes = useP360SlotStyles()

    const handleDelete = () => {
        deleteSlot(indexKey)
    }

    const handleParentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateParent(indexKey, event.target.id, event.target.value)
    }

    /**
     * Converts the UTC time provided by calls to the API into Eugene standard time for display to the admin.
     * Note: The times stored as "standard" times are converted to local DST when necessary by the API when the slot
     * times are built for display on the test taker's scheduling page, so we do not consider DST here.
     * @param {string | undefined} time - The time to be converted.
     */
    const eugeneStandardTimefromUtc = (time: string | undefined) => {
        const inputFormat = "HH:mmZ"
        const outputFormat = "HH:mm"

        const eugeneStandardTime = moment.utc(time, inputFormat).utcOffset(-8).format(outputFormat)

        return eugeneStandardTime == null ? "" : eugeneStandardTime
    }

    return (
        <Grid container={true} justify={"center"} spacing={2}>
                <Grid
                    item={true}
                    xs={3}
                >
                    <TextField
                        error={slotError}
                        fullWidth={true}
                        id="start-time"
                        label="Start Time"
                        variant={"outlined"}
                        type="time"
                        value={eugeneStandardTimefromUtc(startTime)}
                        className={classes.textField}
                        onChange={handleParentChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                    />
                </Grid>
                <Grid
                    item={true}
                    xs={3}
                >
                        <TextField
                            error={slotError}
                            fullWidth={true}
                            id="end-time"
                            label="End Time"
                            variant="outlined"
                            type="time"
                            value={eugeneStandardTimefromUtc(endTime)}
                            className={classes.textField}
                            onChange={handleParentChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                        />
                </Grid>
                <Grid
                    item={true}
                    xs={3}
                >
                        <TextField
                            error={slotError}
                            fullWidth={true}
                            id="seats"
                            label="Seat Count"
                            variant={"outlined"}
                            type="number"
                            value={seats ?  seats : 0}
                            className={classes.textField}
                            onChange={handleParentChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 1,
                            }}
                        />
                </Grid>
                { // This adds the delete row trashcan if there is more than 1 slot
                    addRemoveSlotIcon &&
                        <Grid item={true} xs={2}>
                            <FormGroup row={true} >
                                <IconButton
                                    aria-label="delete"
                                    className={classes.margin}
                                    onClick={handleDelete}
                                >
                                    <DeleteIcon fontSize="large" />
                                </IconButton>
                            </FormGroup>
                        </Grid>
                }
        </Grid>
    )
}
