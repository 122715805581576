import {PanelLevel} from "../../types/types"
import {AvantDateRange} from "../../util/TimeUtil"
import {GroupId} from "../../validation/ValidPrimaryKey"
import {UserType} from "../common/authentication/models/UserType"
import {productStore} from "../common/products/ProductStore"
import {TakeSearchQuery} from "../common/take/models/TakeSearchQuery"
import {reportsAssessmentStartValue, reportsRecentActivityValue} from "./common/components/FilterAndPrintOptions"
import {GenericReportContainerProps} from "./common/models/GenericReportProps"
import {ReportFilters} from "./common/models/ReportFilters"
import moment from "moment"


const LEVEL_SPACER = ""
export const COMPUTER_SCORED_LEVELS = ["10", "9", "8", "7", "6", "5", "4", "3", "2", "1", LEVEL_SPACER]
export const HUMAN_SCORED_LEVELS = ["10", "9", "8", "7", "6", "5", "4", "3", "2", "1", "NR"]
export const MAX_DISPLAYABLE_TEST_DURATION = 999

export class ReportUtils {
    static isValidUserType = (userType?: UserType) => userType != null && [UserType.T, UserType.SC, UserType.D, UserType.C].includes(userType)

    static buildQuery = (props: GenericReportContainerProps): TakeSearchQuery => {
        const {filters} = props
        return {
            searchByTakePanelTimes: false,
            productId: productStore.driver!.productId,
            groupId: new GroupId(filters.groupId),
            fromDate: filters.startDate,
            toDate: filters.endDate != null
                ? filters.endDate.clone().endOf("day")
                : moment().clone().endOf("day")
        }
    }

    static rangeAndValuesToFilters = (productId: number, groupId: number, range: AvantDateRange, selectedValues: string[]) => {
        const linkFilters: Partial<ReportFilters> = {productId, groupId, ...range}
        linkFilters.assessmentStart = selectedValues.includes(reportsAssessmentStartValue)
        linkFilters.recentActivity = selectedValues.includes(reportsRecentActivityValue)
        return linkFilters as ReportFilters
    }

    static isPrinting = (pathname: string) => pathname.includes("print")

    static setWritingStatusForHandwritten = (wrStatus?: string): string | undefined => {

        if (wrStatus === PanelLevel.PANEL_PRINTED) {
            return "Printed"
        } else if (wrStatus === PanelLevel.PANEL_PENDING) {
            return "Pending"
        } else if (wrStatus === PanelLevel.PANEL_PRINTABLE) {
            return "Printable"
        } else {
            return wrStatus
        }

    }
}

