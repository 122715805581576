/*
 * ******************************************************
 *  * Copyright (C) 2010-Present Avant Assessment
 *  * All Rights Reserved
 *  ******************************************************
 */

import {ProctorTypes} from "../../../../util/Constants"
import {AdminLoginTableData} from "../../../../types/rest/LoginTypes"
import ApiService from "../../../../services/ApiService"
import {VerificientLogin} from "../../../../app/roster/RosterSelectTake"
import ProctorApi from "../../../../services/ProctorApi"

export default class LoginTableNewUtilities {

    static replaceToInsertOppCode(match: string, p1: string, offset: number, s: string): string {
        return `vpp-${p1}`
    }
    static replaceToInsertOppCodeForAvantProctor(match: string, p1: string, offset: number, s: string): string {
        return `vpr-${p1}`
    }
    static isProctored(proctorType: string): boolean {
        return proctorType === ProctorTypes.PROCTORTRACK.valueOf()
            || proctorType === ProctorTypes.AVANTPROCTOR.valueOf()
            || proctorType === ProctorTypes.EDUPROCTOR.valueOf()
            || proctorType === ProctorTypes.AP_24_7.valueOf()
            || proctorType === ProctorTypes.P360.valueOf();
    }

    static getEncryptedPassword(loginUUID: string): Promise<string> {
        return ApiService.getEncryptedPassword(loginUUID).then(
            (response) => {
                return response ? response : "NOT FOUND"
            }
        ).catch((error) => {
            return "ERROR: NOT FOUND"
        })
    }

    static isDIY(proctorType: string): boolean {
        if (proctorType === ProctorTypes.AVANTPROCTOR.valueOf()
            || proctorType === ProctorTypes.EDUPROCTOR.valueOf()) {
            return true
        } else {
            return false
        }
    }

    static isProctors(proctorType: string): boolean {
        if (proctorType === ProctorTypes.PROCTORTRACK.valueOf()
            || proctorType === ProctorTypes.EDUPROCTOR.valueOf()
            || proctorType === ProctorTypes.AP_24_7.valueOf()
        ) {
            return true
        } else {
            return false
        }
    }
    static buildSKU(rowData: AdminLoginTableData): string {
        const proctorType: string = rowData.proctorType ? rowData.proctorType : ""
        const testCode: string = rowData.userName
        const rowId: string = rowData.loginid ? rowData.loginid.toString() : ""
        if  (this.isProctored(proctorType) && testCode && rowId) {
            let oppCode:string=""
            if(proctorType=="P360"){
                oppCode = testCode.replace(/^([^-]*).*/, this.replaceToInsertOppCodeForAvantProctor)
                return `${oppCode}-${rowId}`
            }else{
                oppCode = testCode.replace(/^([^-]*).*/, this.replaceToInsertOppCode)
                return `${oppCode}-${rowId}`
            }
        } else {
            return "-"
        }
    }

    static buildRegistraionLink(rowData: AdminLoginTableData): string {
        const proctortype: string | undefined= rowData.proctorType

        if  ((proctortype === ProctorTypes.AVANTPROCTOR.valueOf() || proctortype === ProctorTypes.P360.valueOf())){
            const url = new URL(`/avantproctor-registration/${rowData.loginid}`, window.location.href)
            return url.href
        } else if (proctortype !== undefined && proctortype !== null && LoginTableNewUtilities.isProctors(proctortype) && rowData.loginid) {
            const url = new URL(`/proctor-registration/${rowData.loginid}`, window.location.href)
            return url.href
        } else {
            return "-"
        }
    }

    static buildDIYLink(rowData: AdminLoginTableData): string {
        const proctortype: string | undefined = rowData.proctorType

        if (proctortype !== null && proctortype !== undefined && LoginTableNewUtilities.isDIY(proctortype) && rowData.loginid) {
            const url = new URL(`/proctored/${rowData.loginid}`, window.location.href)
            return url.href
        } else {
            return "-"
        }
    }

    static getVerificientInstructorLink(rowData: AdminLoginTableData) {
        this.getEncryptedPassword(rowData.loginUuid).then(
            (response) => {
                if (response !== null && response !== undefined) {
                    const payload: VerificientLogin = {
                        username: rowData.userName,
                        password: response,
                        loginId: rowData.loginid
                    }

                    ProctorApi.verificientInstructorLogin(payload).then(
                        (redirectUrl) => {
                            window.open(redirectUrl.redirectUrl, "_blank")
                        }
                    )

                }
            }
        )
    }

}
