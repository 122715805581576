import {ClickAwayListener, ListItemIcon, MenuItem, MenuList, Paper, Popper} from "@material-ui/core"
import {ArrowForwardIos} from "@material-ui/icons"
import {observable} from "mobx"
import {observer} from "mobx-react"
import * as React from "react"
import {AvantColors} from "../../styles/AvantColors"
import {BodyText} from "../../styles/AvantTypography"
import {ListItemLink} from "./ListItemLink"
import {AvantCircularProgress, CircularProgressSize} from "../common/loaders/AvantCircularProgress"
import {takeSearchStore} from "../common/take/TakeSearchStore"

/**
 * Should contain either action, link, or submenuItems
 */
export interface AvantPopperMenuItem {
    name: string
    action?: () => void
    link?: string
    submenuItems?: AvantPopperMenuItem[]
    needsLoader?: boolean
}

export interface AvantPopperMenuProps {
    contents: AvantPopperMenuItem[]
}

export class AvantPopperMenu extends React.Component<AvantPopperMenuProps> {

    render() {
        return (
            <MenuList>
                {this.props.contents.map((props) => <PopperMenuItem key={props.name} {...props} />)}
            </MenuList>
        )
    }
}

@observer
class PopperMenuItem extends React.Component<AvantPopperMenuItem> {
    @observable
    open?: boolean

    buttonRef?: HTMLButtonElement

    render() {
        const {submenuItems, action, link, name, needsLoader} = this.props
        const options = [submenuItems, action, link]
        if (options.filter(item => !!item).length > 1) {
            throw new Error("Only one of action, link, or submenu items should be included")
        }
        let mouseEnterHandler, mouseLeaveHandler,clickHandler
        if (submenuItems != null) {
            mouseEnterHandler = () => this.open = true
            mouseLeaveHandler = () => this.open = false
        }
        if (action != null) {
            clickHandler = action
        }
        const menuItemStyles = {
            display: "flex",
            justifyContent: "space-between",
            paddingRight: 0,
            width: 200,
            height: 40,
            color: AvantColors.REPORT_FONT_COLOR_BLACK,
            fontSize: 16
        }
        return (
            <div onMouseLeave={mouseLeaveHandler}>
                {link ?
                    <ListItemLink to={link} style={menuItemStyles}>{name}</ListItemLink> : (
                        <MenuItem
                            button={true}
                            title={name}
                            onClick={clickHandler}
                            onMouseEnter={mouseEnterHandler}
                            disabled={!action && !submenuItems}
                            buttonRef={(button: HTMLButtonElement) => this.buttonRef = button}
                            style={menuItemStyles}
                        >
                            <>
                                <BodyText style={{fontSize: 16}}>
                                    {name}
                                </BodyText>
                                {needsLoader && !takeSearchStore.downloadComplete &&
                                    <ListItemIcon>
                                        <AvantCircularProgress size={CircularProgressSize.SMALL}/>
                                    </ListItemIcon>
                                }
                            </>
                            {submenuItems && <ArrowForwardIos style={{height: 10, fill: AvantColors.REPORT_FONT_COLOR_GRAY_1}}/>}
                        </MenuItem>
                    )}
                {submenuItems && this.open && (
                    <ClickAwayListener onClickAway={() => this.open = false}>
                        <Popper open={this.open} anchorEl={this.buttonRef} placement={"left-start"}>
                            <Paper>
                                <AvantPopperMenu contents={submenuItems}/>
                            </Paper>
                        </Popper>
                    </ClickAwayListener>
                )}
            </div>
        )
    }
}
