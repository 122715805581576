import React, {Dispatch, SetStateAction} from "react"
import {FormGroup, TextField} from "@material-ui/core"
import {TEvent} from "../../types/types"
import Button from "@material-ui/core/Button"
import {theme} from "../../styles/MuiThemes"

type TestCodeSearchFormProps = {
    testCodeSearchString: string,
    setSearchString: Dispatch<SetStateAction<string>>,
    onSearch: () => void
}

export const TestCodeSearchForm: React.FC<TestCodeSearchFormProps> = ({testCodeSearchString, setSearchString, onSearch}) => {

    return (
        <div>
            <FormGroup>
                <div style={{margin: theme.spacing(1)}}>
                    <TextField

                        label={"Test Code"}
                        type={"text"}
                        value={testCodeSearchString}
                        placeholder={"Search Test-Code"}
                        data-tst-id={"test-code"}
                        onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                            if (e.key === "Enter") {
                                onSearch()
                            }
                        }}
                        onChange={(e: TEvent) => {
                            setSearchString(e.target.value)
                        }}
                        autoFocus={true}
                    />
                </div>
                <div style={{margin: theme.spacing(1,1)}}>
                    <Button
                        variant={"outlined"}
                        onClick={onSearch}
                    >Search Test Codes</Button>
                </div>
            </FormGroup>
        </div>
    )
}
