/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {Dialog, DialogContent, DialogTitle, IconButton} from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import {Close} from "@material-ui/icons"
import * as React from "react"
import {AvantColors} from "../../../../../styles/AvantColors"
import {BodyText, LabelText, WritingResponseText, RTLWritingResponseText} from "../../../../../styles/AvantTypography"
import {theme} from "../../../../../styles/MuiThemes"
import {ApiItemResponse, ApiPanel, ItemFormatEnum} from "../../../../../types/types"
import {AudioUtils} from "../../../../../util/AudioUtils"
import {WRITING_RESPONSE_URL, RTL_CONTENT_AREA_IDS} from "../../../../../util/Constants"
import {ItemInstructionsViewer} from "../../../../common/item/components/ItemInstructionsViewer"


interface SidebarResponseModalProps {
    takePanelId: number
    response: ApiItemResponse
    score?: string
    indicatorOrReason?: string
    benchmark?: string
    responseNumber: number
    fullName: string
    panel: ApiPanel
    isOpen: boolean
    close: () => void
}

export class SidebarResponseModal extends React.Component<SidebarResponseModalProps> {

    isHandwritten = (respondedValue: string) => {
        return respondedValue.startsWith("WritingResponses") && respondedValue.endsWith("jpg")
    }

    render() {
        const {response, score, indicatorOrReason, benchmark, responseNumber, fullName, panel, isOpen, close, takePanelId} = this.props
        let title = "Response"

        if (response.itemFormat === ItemFormatEnum.SPEAKING_ORAL) {
            title = `Speaking Response`
        } else if (response.itemFormat === ItemFormatEnum.WRITING) {
            title = `Writing Response`
        }
        title += ` ${responseNumber}`
        return (
            <Dialog open={isOpen} onClose={close} maxWidth={"lg"} fullWidth={true} style={{borderRadius: 6}}>
                <DialogTitle
                    disableTypography={true}
                    style={{
                        backgroundColor: AvantColors.REPORT_YELLOW,
                        display: "flex",
                        alignItems: "center",
                        padding: theme.spacing(2)
                    }}
                >
                    <LabelText style={{marginRight: theme.spacing(1)}}>
                        {title}
                    </LabelText>
                    <BodyText>
                        { score == null && benchmark == null ? "Scoring in Progress" : `Score: ${score} ${indicatorOrReason} ${benchmark}` }
                    </BodyText>
                    <div style={{flexGrow: 1}}/>
                    <BodyText style={{marginRight: theme.spacing(1)}}>
                        {fullName}
                    </BodyText>
                    <IconButton
                        onClick={close}
                        size={"small"}
                    >
                        <Close fontSize={"small"}/>
                    </IconButton>
                </DialogTitle>

                <Grid container={true}>
                    <Grid item={true}
                          xs={12}
                          //This ternary makes this grid item slightly larger for sections other than speaking sections.
                          md={response.itemFormat === ItemFormatEnum.SPEAKING_ORAL ? 6 : 7}
                          lg={6}
                          style={{display: "flex", justifyContent: "center"}}
                    >
                        <div
                            className="item-instructions"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                margin: theme.spacing(6, 6, 3),
                                lineHeight: 1.5,
                                maxWidth: 552
                            }}
                        >
                            <ItemInstructionsViewer
                                itemId={response.itemId}
                                takePanelId={takePanelId}
                                responseModal={true}
                            />
                        </div>
                    </Grid>
                    <Grid
                        item={true}
                        xs={12}
                        //This ternary makes this grid item slightly smaller for sections other than speaking sections.
                        md={response.itemFormat === ItemFormatEnum.SPEAKING_ORAL ? 6 : 5}
                        lg={6}
                        style={{backgroundColor: AvantColors.REPORT_BACKGROUND_GRAY_LIGHT}}
                    >
                        <DialogContent
                            style={{
                                padding: theme.spacing(0, 10),
                                paddingTop: theme.spacing(6),
                                paddingBottom: theme.spacing(6)
                            }}
                        >
                            {response.itemFormat === ItemFormatEnum.SPEAKING_ORAL && (
                                AudioUtils.isStandardAudioLink(response.respondedValue) && (
                                    <audio controls={true}>
                                        <source src={response.respondedValue}/>
                                    </audio>
                                )
                            )}
                            {response.itemFormat === ItemFormatEnum.WRITING && (
                                this.isHandwritten(response.respondedValue) ? (
                                    <img
                                        src={`${WRITING_RESPONSE_URL}${response.respondedValue}`}
                                        alt={"Handwritten image"}
                                        style={{width: "100%", height: "100%"}}
                                    />
                                ) : RTL_CONTENT_AREA_IDS.includes(panel.contentAreaId) ? (
                                    <RTLWritingResponseText>
                                        {response.respondedValue}
                                    </RTLWritingResponseText>
                                ) : (
                                    <WritingResponseText>
                                        {response.respondedValue}
                                    </WritingResponseText>
                                )
                            )}
                        </DialogContent>
                    </Grid>
                </Grid>
            </Dialog>
        )
    }
}
