import {IItem, TLocalAnswer} from "../../types/types"
import {to} from "../../util/elvis"
import {ItemContainerHelper} from "../ItemContainerHelper"

export class WorldSpeakItemContainerHelper extends ItemContainerHelper {
    answeredModalText: string = "You must answer the question to proceed."

    getCbAnswerCountModalText(item: IItem, answer: any[]) {
        const requiredCount: number = to<number>(item.requiredAnswerCount, Error("required count not found"))
        return `You must select ${requiredCount} answers.`
    }

    isValidCbAnswer(answer: any[], item: IItem, disableRequiredAnswers: boolean): boolean {
        if (disableRequiredAnswers) {
            return true
        }
        let isValidAnswer: boolean = true
        if (item.requiredAnswerCount) {
            const actualCount: number = answer.length
            const correctCount: number = item.requiredAnswerCount
            if (actualCount !== correctCount) {
                isValidAnswer = false
            }
        }
        return isValidAnswer
    }

    isAnswered(answer: TLocalAnswer | null, disableRequiredAnswers: boolean): boolean {
        if (disableRequiredAnswers) {
            return true
        }
        let answered: boolean = false
        if (answer !== null) {
            answered = true
        }
        return answered
    }
}
