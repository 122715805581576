import {Button, Grid, Paper, TextField} from "@material-ui/core"
import * as React from "react"
import {ChangeEvent, useState} from "react"
import DevToolsApi from "../../../services/DevToolsApi"

export const ValidateHash: React.FC = () => {
    const [knownText, setKnownText] = useState("")
    const [knownHash, setKnownHash] = useState("")
    const [response, setresponse] = useState("")

    const handleKnownHashChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => setKnownHash(e.target.value)
    const handleKnownTextChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => setKnownText(e.target.value)

    const handleDo = () => {
        DevToolsApi.verifyHash(knownHash, knownText)
            .then(apiResponse => {
                if (apiResponse) { setresponse(apiResponse) }
            })
    }

    return (
        <Grid container={true} spacing={3}>
            <Grid item={true} xs={4}>
                <TextField
                    id="known-text"
                    label="Text"
                    onChange={handleKnownTextChange}
                    style={{width: "100%"}}
                />
            </Grid>
            <Grid item={true} xs={4}>
                <TextField
                    id="given-hash"
                    label="Hash"
                    onChange={handleKnownHashChange}
                    style={{width: "100%"}}
                />
            </Grid>
            <Grid item={true} xs={4}>
                <Button variant="contained" onClick={handleDo}>Validate Hash</Button>
            </Grid>
            <Grid item={true} xs={12}>
                <Paper elevation={3} style={{padding: "5px", margin: "5px"}}>
                    <label>Testing String "{knownText}" against hash of "{knownHash}":</label>
                    <p>{response}</p>
                </Paper>
            </Grid>
        </Grid>
    )
}
