import {IconButton, Snackbar} from "@material-ui/core"
import {blue, red} from "@material-ui/core/colors"
import {Close} from "@material-ui/icons"
import {observer} from "mobx-react"
import * as React from "react"
import {messageStore} from "./MessageStore"
import {UiMessageType} from "./UiMessage"

@observer
export class MessageView extends React.Component<{ onClose?: () => void }, {}> {

    handleClose = (event: React.SyntheticEvent, reason?: string) => {
        if (reason === "clickaway") {
            return
        }

        if (this.props.onClose) {
            this.props.onClose()
        }
        messageStore.open = false
    }

    render() {
        const currentMessage = messageStore.message
        const open = messageStore.open
        const messageString = currentMessage ? currentMessage.message : ""
        const type = currentMessage ? currentMessage.type : UiMessageType.INFO
        let backgroundColor = blue["700"]
        if (type === UiMessageType.ERROR) {
            // @ts-ignore
            backgroundColor = red["700"]
        }

        return (
            <div>
                <Snackbar
                    open={open}
                    onClose={this.handleClose}
                    message={messageString}
                    autoHideDuration={20000}
                    ContentProps={{
                        "style": {backgroundColor}
                    }}
                    action={[
                        <IconButton
                            color="inherit"
                            onClick={this.handleClose}
                            key={"0"}
                        >
                            <Close/>
                        </IconButton>
                    ]}
                />
            </div>
        )
    }
}
