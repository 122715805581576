/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {AxiosResponse} from "axios"
import {ApiErrorResponse, P360Scheduling} from "../types/types"
import ApiService from "./ApiService"
import {P360247CreateExamReturn} from "../components/admin-portal/DevTools/Components/Proctor247CreationTool"
import {AuthUser} from "../app/common/authentication/models/AuthUser";

export default class DevToolsApi {
    static getHash(stringToHash: string | null = null): Promise<string> {
        const url: string = `${ApiService.API_URL}devtools/hashString`
        return new Promise((resolve, reject) => {
            ApiService.post(url, {stringToHash}).then(
                (res: AxiosResponse) => { resolve(res.data) },
                (err: ApiErrorResponse) => { reject(err) }
            )
        })
    }
    static verifyHash(knownHash: string | null = null, knownString: string | null = null): Promise<string> {
        const url: string = `${ApiService.API_URL}devtools/verifyHash`
        return new Promise((resolve, reject) => {
            ApiService.post(url, {knownHash, knownString}).then(
                (res: AxiosResponse) => { resolve(res.data) },
                (err: ApiErrorResponse) => { reject(err) }
            )
        })
    }

    static masquaradeSSO(
        userId: string | null = null,
        ssoId: string | null = null,
        ssoUserId: string | null = null,
        ssoRole: string | null = null
    ): Promise<AuthUser> {
        const url: string = `${ApiService.API_URL}authenticate/masquarade_sso`
        return new Promise((resolve, reject) => {
            ApiService.post(url, {userId, ssoId, ssoUserId ,ssoRole}).then(
                (res: AxiosResponse) => { resolve(res.data.data) },
                (err: ApiErrorResponse) => { reject(err) }
            )
        })
    }

    static getP360ScheduleList(): Promise<P360Scheduling[]> {
        const url = `${ApiService.API_URL}p360/schedule_list`
        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }
    static createP360Exams(selectedIds: number[], schedule: P360Scheduling[]) {
        const url = `${ApiService.API_URL}p360/create_exam`
        const payload = {selectedIds, schedule}

        return new Promise((resolve, reject) => {
            ApiService.post(url, payload, true).then(
                (response: AxiosResponse) => {
                    resolve(response)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static createP360247Exams(selectedIds: number[]): Promise<P360247CreateExamReturn> {
        const url = `${ApiService.API_URL}p360-247/create_exam`
        const payload = { selectedIds }

        return new Promise((resolve, reject) => {
            ApiService.post(url, payload, true).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }
}
