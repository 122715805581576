import React, {Dispatch, SetStateAction} from "react"
import {log} from "../../util/Logging"

export const MIN_PASSAWORD_LENGTH = 20

interface IComponentProps {
    password: string
    confirmPassword: string
    className?: string
    enableSubmit?: Dispatch<SetStateAction<boolean>>
}


export const PasswordChecker = (props: IComponentProps): JSX.Element => {

    let passError = false
    let charTextClass = "password-checker__bad-text"
    let charCircleClass = "password-checker__bad-circle"
    if (props.password.length >= MIN_PASSAWORD_LENGTH) {
        charTextClass = "password-checker__good-text"
        charCircleClass = "password-checker__good-circle"
    } else {
        passError = true
    }

    let lowerCaseLetterTextClass = "password-checker__bad-text"
    let lowerCaseLetterCircleClass = "password-checker__bad-circle"

    let upperCaseLetterTextClass = "password-checker__bad-text"
    let upperCaseLetterCircleClass = "password-checker__bad-circle"

    const lowerLetters = RegExp('[a-z]+')
    const upperLetters = RegExp('[A-Z]+')
    const numbers = RegExp('[0-9]+')
    const specChars = RegExp('[!@#$%^&*()_+={\\[}\\]:\'\<\>.?~|-]+')
    const disallowedChars = RegExp('[\`\;\"\\\\]+')

    if (props.password.length > 0 && lowerLetters.test(props.password)) {
        log.debug("Setting Letters to valid")
        lowerCaseLetterTextClass = "password-checker__good-text"
        lowerCaseLetterCircleClass = "password-checker__good-circle"
    } else {
        passError = true
    }

    if (props.password.length > 0 && upperLetters.test(props.password)) {
        log.debug("Setting Letters to valid")
        upperCaseLetterTextClass = "password-checker__good-text"
        upperCaseLetterCircleClass = "password-checker__good-circle"
    } else {
        passError = true
    }

    let numberTextClass = "password-checker__bad-text"
    let numberCircleClass = "password-checker__bad-circle"

    if(props.password.length > 0 && numbers.test(props.password)) {
        numberTextClass = "password-checker__good-text"
        numberCircleClass = "password-checker__good-circle"
    } else {
        passError = true
    }

    let symbolTextClass = "password-checker__bad-text"
    let symbolCircleClass = "password-checker__bad-circle"

    if(props.password.length > 0 && specChars.test(props.password)) {
        symbolTextClass = "password-checker__good-text"
        symbolCircleClass = "password-checker__good-circle"
    } else {
        passError = true
    }

    let matchTextClass = "password-checker__bad-text"
    let matchCircleClass = "password-checker__bad-circle"
    if (props.password === props.confirmPassword && props.password !== "" && props.confirmPassword !== "") {
        matchTextClass = "password-checker__good-text"
        matchCircleClass = "password-checker__good-circle"
    } else {
        passError = true
    }

    let disallowedCharsText = "password-checker__good-text"
    let disallowedCharsCircle = "password-checker__good-circle"
    if (props.password.length > 0 && disallowedChars.test(props.password)) {
        disallowedCharsText = "password-checker__good-text"
        disallowedCharsCircle = "password-checker__bad-circle-error"
        passError = true
    }

    if (props.enableSubmit) {
        if (passError) {
            props.enableSubmit(true)
        } else {
            // This is backwards because it is built with negative logic
            props.enableSubmit(false)
        }
    }


    return (
        <div className={`password-checker ${props.className}`}>
            <div>
                <div>
                    <div className={charCircleClass}/>
                    <div className={charTextClass}>{MIN_PASSAWORD_LENGTH} characters minimum</div>
                </div>
                <div>
                    <div className={lowerCaseLetterCircleClass}/>
                    <div className={lowerCaseLetterTextClass}>One lower case letter</div>
                </div>
                <div>
                    <div className={upperCaseLetterCircleClass}/>
                    <div className={upperCaseLetterTextClass}>One upper case letter</div>
                </div>
                <div>
                    <div className={numberCircleClass}/>
                    <div className={numberTextClass}>One number</div>
                </div>
                <div>
                    <div className={symbolCircleClass}/>
                    <div className={symbolTextClass}>One Symbol: (!@#$%^&*()-_+={"{"}{"}"}[]:'/{"<"},{">"}.?~|)</div>
                </div>
                <div>
                    <div className={matchCircleClass}/>
                    <div className={matchTextClass}>Passwords Match</div>
                </div>
                <div>
                    <div className={disallowedCharsCircle}/>
                    <div className={disallowedCharsText}>Disallowed Characters: ( ` ; \ " ) </div>
                </div>
            </div>
        </div>
    )
}
