/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {STAMP_CEFR_PRODUCT_ID, STAMP_SIGN_LANGUAGE_PRODUCT_ID} from "../../util/Constants"
import {
    DataLoadTracker,
    fetchStampFamilyDistrictSchoolReportData,
    fetchStampFamilyTeacherReportData,
    ReportProps
} from "./ReportData"
import {ReportFilters} from "../reports/common/models/ReportFilters"
import StampReportHeader from "./components/StampReportHeader"
import TestTakerTableContainer from "./components/testTakerSortableTable/TestTakerTableContainer"
import {SkillColumnData} from "./components/testTakerSortableTable/Columns"
import SummaryCharts from "./components/summaryCharts/SummaryCharts"
import {PanelSkillEnum} from "../../types/types"
import {SchoolLocationList, SchoolLocationListProps} from "./components/SchoolLocationList"
import {BlueReportType} from "../reports/common/models/BlueReportType"
import {TestControls, TestControlsProps} from "./components/TestControls"
import {ReactElement} from "react"

interface ReportStructureProductGroup {
    productId: number,
    district: ReportStructure,
    school: ReportStructure,
    class: ReportStructure,
}

export interface ReportStructure {
    title?: string,
    data?: (filters: ReportFilters, reportType: BlueReportType) => DataLoadTracker,
    Header?: (props: ReportProps) => ReactElement,
    SummaryCharts?: (props: ReportProps) => ReactElement,
    TestControls?: (props: TestControlsProps) => ReactElement,
    TestInstancesList?: (props: ReportProps) => ReactElement | null,
    TestInstancesListColumns?: SkillColumnData[],
    SchoolLocationList?: (props: SchoolLocationListProps) => ReactElement,
}

const STAMPFamilyTeacherReportStructure: ReportStructure = {
    title: "Class Report",
    data: fetchStampFamilyTeacherReportData,
    Header: StampReportHeader,
    SummaryCharts: SummaryCharts,
    TestControls: TestControls,
    TestInstancesList: TestTakerTableContainer,
}

const STAMPFamilyAdminReportStructure: ReportStructure = {
    Header: StampReportHeader,
    SummaryCharts: SummaryCharts,
    data: fetchStampFamilyDistrictSchoolReportData,
    SchoolLocationList: SchoolLocationList,
}

const reportStructures: ReportStructureProductGroup[] = [
    // TODO: uncomment this when we move Stamp4S to the new reports
    // {
    //     productId: STAMP_4S_PRODUCT_ID.value(),
    //     classes: {
    //         ...STAMPFamilyTeacherReportStructure,
    //         TestInstancesListColumns: [
    //             {
    //                 title: "Reading",
    //                 key: "readingScore",
    //                 centerContent: true,
    //                 symbol: PanelSkillEnum.READING,
    //             },
    //             {
    //                 title: "Reading Scaled Score",
    //                 key: "readingScore",
    //                 scaledScoreKey: "readingScaledScore",
    //                 centerContent: true,
    //             },
    //             {
    //                 title: "Writing",
    //                 key: "writingScore",
    //                 centerContent: true,
    //                 symbol: PanelSkillEnum.WRITING,
    //             },
    //             {
    //                 title: "Listening",
    //                 key: "listeningScore",
    //                 centerContent: true,
    //                 symbol: PanelSkillEnum.LISTENING,
    //             },
    //             {
    //                 title: "Listening Scaled Score",
    //                 key: "listeningScore",
    //                 scaledScoreKey: "listeningScaledScore",
    //                 centerContent: true,
    //             },
    //             {
    //                 title: "Speaking",
    //                 key: "speakingScore",
    //                 centerContent: true,
    //                 symbol: PanelSkillEnum.SPEAKING,
    //             },
    //         ],
    //     },
    // },
    {
        productId: STAMP_SIGN_LANGUAGE_PRODUCT_ID.value(),
        district: {
            ...STAMPFamilyAdminReportStructure,
            title: "District Report",
        },
        school: {
            ...STAMPFamilyAdminReportStructure,
            title: "School Report",
        },
        class: {
            ...STAMPFamilyTeacherReportStructure,
            // TODO: Get TestInstancesListColumns from backend
            TestInstancesListColumns: [
                {
                    title: "Receptive",
                    key: "listeningScore",
                    centerContent: true,
                    symbol: PanelSkillEnum.RECEPTIVE,
                },
                {
                    title: "Receptive Scaled Score",
                    key: "listeningScore",
                    scaledScoreKey: "listeningScaledScore",
                    centerContent: true,
                },
                {
                    title: "Expressive",
                    key: "speakingScore",
                    centerContent: true,
                    symbol: PanelSkillEnum.EXPRESSIVE,
                },
            ],
        },
    },
    {
        productId: STAMP_CEFR_PRODUCT_ID.value(),
        district: {
            ...STAMPFamilyAdminReportStructure,
            title: "District Report",
        },
        school: {
            ...STAMPFamilyAdminReportStructure,
            title: "School Report",
        },
        class: {
            ...STAMPFamilyTeacherReportStructure,
            TestInstancesListColumns: [
                {
                    title: "Reading",
                    key: "readingScore",
                    centerContent: true,
                    symbol: PanelSkillEnum.READING,
                },
                {
                    title: "Scaled Reading Score",
                    key: "readingScore",
                    scaledScoreKey: "readingScaledScore",
                    centerContent: true,
                },
                {
                    title: "Writing",
                    key: "writingScore",
                    centerContent: true,
                    symbol: PanelSkillEnum.WRITING,
                },
                {
                    title: "Listening",
                    key: "listeningScore",
                    centerContent: true,
                    symbol: PanelSkillEnum.LISTENING,
                },
                {
                    title: "Scaled Listening Score",
                    key: "listeningScore",
                    scaledScoreKey: "listeningScaledScore",
                    centerContent: true,
                },
                {
                    title: "Speaking",
                    key: "speakingScore",
                    centerContent: true,
                    symbol: PanelSkillEnum.SPEAKING,
                },
            ],
        }
    }
]

export function getReportStructure(productId: number, reportType: BlueReportType) {
    const reportTypeString = [
        "district",
        "school",
        "class",
    ]

    const productGroup = reportStructures.find((struct: ReportStructureProductGroup) => struct.productId === productId)
    if (productGroup) {
        return productGroup[reportTypeString[reportType]]
    }

    return null
}
