import React from "react"
import FontAwesome from "react-fontawesome"
import {SelectOption} from "../../types/types"

interface DropdownProps {
    options: SelectOption[]
    value?: string
    onChange: (selectedOption: any) => void
}

interface DropdownState {
    value?: string | number
    showOptions: boolean
}

export default class Dropdown extends React.Component<DropdownProps, DropdownState> {
    state: DropdownState
    wrapperRef: any

    constructor(props: DropdownProps) {
        super(props)
        this.state = {
            value: props.value,
            showOptions: false
        }
    }
    
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside)
    }

    componentDidUpdate() {
        if (this.props.value !== this.state.value) {
            this.setState({value: this.props.value})
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside)
    }

    handleShowOptions = () => {
        this.setState({showOptions: !this.state.showOptions})
    }

    onChange = (selectedOption: any) => {
        this.props.onChange(selectedOption)
        this.setState({showOptions: false})
    }

    handleClickOutside = (event: any) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({showOptions: false})
        }
    }

    render() {
        let buttonClass = "selector__button selector__button--closed"
        let optionsClass = "selector__options selector__options--closed"
        if (this.state.showOptions) {
            buttonClass = "selector__button selector__button--open"
            optionsClass = "selector__options selector__options--open"
        }
        return (
            <div className="selector" ref={(node) => this.wrapperRef = node}>
                <div className={buttonClass} onClick={this.handleShowOptions}>
                    <FontAwesome className="selector__button__icon" name="caret-down" />
                    <div className="selector__selected-option">{this.state.value}</div>
                </div>
                <div className={optionsClass}>
                    {this.props.options.map((option: SelectOption) => {
                        return (
                            <div
                                key={Math.random()}
                                className="selector__options__option"
                                onClick={() => this.onChange(option.value)}
                            >
                                {option.label}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}
