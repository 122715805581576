import moment from "moment"
import {isNullOrUndefined} from "util"
import * as winston from "winston"

import ApiService from "../../services/ApiService"
import {GenericHelperInterface} from "../../services/GenericHelper"
import {
    ApiErrorResponse,
    ApiPanel,
    ApiPanelGraph,
    ApiTake,
    ApiTakePanelResponse,
    Language,
    LastPage,
    LoginProductContentAreaData,
    Module,
    ModulePanel,
    PanelSkillEnum,
    PanelType,
    Take,
    TestState
} from "../../types/types"
import {elvis} from "../../util/elvis"
import {TakeCode} from "../../validation/TakeCode"
import {PanelGraphId} from "../../validation/ValidPrimaryKey"

export default class AdvanceHelper implements GenericHelperInterface {
    static mapHasKey(map: Map<any, any>, key: any) {
        if (!map.has(key)) {
            throw Error(`Map ${key} does not exist`)
        }
    }

    /**
     * Converts an array of panel graphs to a map from content area id to language.
     * @param {ApiPanelGraph[]} panelGraphs is the array of panel graphs
     * @returns {Map<number, Language>} the languages map
     */
    static panelGraphToLanguages(panelGraphs: ApiPanelGraph[]): Map<number, Language> {
        const languages: Map<number, Language> = new Map<number, Language>()
        for (const panelGraph of panelGraphs) {
            // For each panel graph create a language
            if (!languages.has(panelGraph.contentAreaId)) {
                const language: Language = {
                    id: panelGraph.contentAreaId,
                    panelGraphId: panelGraph.id,
                    modules: [
                        {
                            skill: PanelSkillEnum.MODULE_1,
                            title: "Module 1",
                            subtitle: "Introduction to Advance",
                            panels: [],
                            timeTaken: 0
                        },
                        {
                            skill: PanelSkillEnum.MODULE_2,
                            title: "Module 2",
                            subtitle: "Non-Ratable - Novice Low",
                            panels: [],
                            timeTaken: 0
                        },
                        {
                            skill: PanelSkillEnum.MODULE_3,
                            title: "Module 3",
                            subtitle: "Novice Mid - Novice High",
                            panels: [],
                            timeTaken: 0
                        },
                        {
                            skill: PanelSkillEnum.MODULE_4,
                            title: "Module 4",
                            subtitle: "Int. Low - Int. Mid",
                            panels: [],
                            timeTaken: 0
                        },
                        {
                            skill: PanelSkillEnum.MODULE_5,
                            title: "Module 5",
                            subtitle: "Int. High - Adv. Low & Mid",
                            panels: [],
                            timeTaken: 0
                        },
                        {
                            skill: PanelSkillEnum.MODULE_6,
                            title: "Module 6",
                            subtitle: "Exceptions etc.",
                            panels: [],
                            timeTaken: 0
                        }
                    ],
                    panels: [],
                    practiceTestPanelId: -1,
                    practiceTestTimeTaken: 0,
                    practiceTestScore: 0,
                    certificationTest1PanelId: -1,
                    certificationTest2PanelId: -1,
                    certificationTestDisabled: true,
                    certificationTestCompleted: false,
                    completedCertificationPanels: [],
                    certificationTestTimeTaken: 0,
                    certificationTestScore: 0,
                    certificationTestItemCount: 0,
                    showFirstCertificationTest: true,
                    existingTakeCodesByPanelId: new Map<number, string>()
                }
                languages.set(panelGraph.contentAreaId, language)
            }

            for (const edge of panelGraph.panelGraphEdges) {
                // For each panel graph edge get it's next panel and add the needed data to the language
                const panel: ApiPanel = edge.panelByNextPanelId
                const previousPanel: ApiPanel | null = edge.panelByCurrentPanelId
                let modulePanel: ModulePanel
                switch (panel.skill) {
                    case PanelSkillEnum.MODULE_1:
                        modulePanel = {
                            id: panel.id,
                            previousPanelId: previousPanel ? previousPanel.id : undefined,
                            passed: false,
                            disabled: false,
                            type: PanelType.TrainingOnly,
                            timeTaken: 0,
                            score: 0,
                            itemCount: panel.itemCount
                        }
                        AdvanceHelper.mapHasKey(languages, panelGraph.contentAreaId)
                        languages.get(panelGraph.contentAreaId)!.modules[0].panels.push(modulePanel)
                        break
                    case PanelSkillEnum.MODULE_2:
                        modulePanel = {
                            id: panel.id,
                            previousPanelId: previousPanel ? previousPanel.id : undefined,
                            passed: false,
                            disabled: true,
                            type: panel.friendlyName.includes("Training") ? PanelType.Training : PanelType.Test,
                            timeTaken: 0,
                            score: 0,
                            itemCount: panel.itemCount
                        }
                        AdvanceHelper.mapHasKey(languages, panelGraph.contentAreaId)
                        languages.get(panelGraph.contentAreaId)!.modules[1].panels.push(modulePanel)
                        break
                    case PanelSkillEnum.MODULE_3:
                        modulePanel = {
                            id: panel.id,
                            previousPanelId: previousPanel ? previousPanel.id : undefined,
                            passed: false,
                            disabled: true,
                            type: panel.friendlyName.includes("Training") ? PanelType.Training : PanelType.Test,
                            timeTaken: 0,
                            score: 0,
                            itemCount: panel.itemCount
                        }
                        AdvanceHelper.mapHasKey(languages, panelGraph.contentAreaId)
                        languages.get(panelGraph.contentAreaId)!.modules[2].panels.push(modulePanel)
                        break
                    case PanelSkillEnum.MODULE_4:
                        modulePanel = {
                            id: panel.id,
                            previousPanelId: previousPanel ? previousPanel.id : undefined,
                            passed: false,
                            disabled: true,
                            type: panel.friendlyName.includes("Training") ? PanelType.Training : PanelType.Test,
                            timeTaken: 0,
                            score: 0,
                            itemCount: panel.itemCount
                        }
                        AdvanceHelper.mapHasKey(languages, panelGraph.contentAreaId)
                        languages.get(panelGraph.contentAreaId)!.modules[3].panels.push(modulePanel)
                        break
                    case PanelSkillEnum.MODULE_5:
                        modulePanel = {
                            id: panel.id,
                            previousPanelId: previousPanel ? previousPanel.id : undefined,
                            passed: false,
                            disabled: true,
                            type: panel.friendlyName.includes("Training") ? PanelType.Training : PanelType.Test,
                            timeTaken: 0,
                            score: 0,
                            itemCount: panel.itemCount
                        }
                        AdvanceHelper.mapHasKey(languages, panelGraph.contentAreaId)
                        languages.get(panelGraph.contentAreaId)!.modules[4].panels.push(modulePanel)
                        break
                    case PanelSkillEnum.MODULE_6:
                        modulePanel = {
                            id: panel.id,
                            previousPanelId: previousPanel ? previousPanel.id : undefined,
                            passed: false,
                            disabled: true,
                            type: PanelType.TrainingOnly,
                            timeTaken: 0,
                            score: 0,
                            itemCount: panel.itemCount
                        }
                        AdvanceHelper.mapHasKey(languages, panelGraph.contentAreaId)
                        languages.get(panelGraph.contentAreaId)!.modules[5].panels.push(modulePanel)
                        break
                    case PanelSkillEnum.PRACTICE_TEST:
                        AdvanceHelper.mapHasKey(languages, panelGraph.contentAreaId)
                        languages.get(panelGraph.contentAreaId)!.practiceTestPanelId = panel.id
                        break
                    case PanelSkillEnum.CERTIFICATION_TEST:
                        if (panel.friendlyName.includes("1")) {
                            AdvanceHelper.mapHasKey(languages, panelGraph.contentAreaId)
                            languages.get(panelGraph.contentAreaId)!.certificationTest1PanelId = panel.id
                        } else if (panel.friendlyName.includes("2")) {
                            AdvanceHelper.mapHasKey(languages, panelGraph.contentAreaId)
                            languages.get(panelGraph.contentAreaId)!.certificationTest2PanelId = panel.id
                        }
                        languages.get(panelGraph.contentAreaId)!.certificationTestItemCount = panel.itemCount
                        break
                    default:
                        winston.warn(`Did not find skill on panel id ${panel.id}`)
                        break
                }
            }
        }

        return languages
    }

    unlockPanels(languages: Map<number, Language>, takePanels: ApiTakePanelResponse[]): Map<number, Language> {
        if (takePanels.length > 0) {
            const unlockedPanels = new Set<number>()
            const passedPanels = new Set<number>()
            const existingTakeCodesByPanelId = new Map<number, string>()
            const timeTakenByPanelId = new Map<number, number>()
            const scoreByPanelId = new Map<number, number>()

            takePanels.forEach((takePanel: ApiTakePanelResponse) => {
                const panelId: number = takePanel.panelId
                unlockedPanels.add(panelId)

                // Phase `panel` means started, phase `goodbye` means completed
                if (takePanel.phase === "panel") {
                    existingTakeCodesByPanelId.set(panelId, takePanel.takeCode)
                }
                if (takePanel.level === "P") {
                    passedPanels.add(panelId)
                }

                // Store time taken
                if (timeTakenByPanelId.has(panelId)) {
                    let currentTimeTaken = timeTakenByPanelId.get(panelId)!
                    currentTimeTaken += takePanel.timeTaken
                    timeTakenByPanelId.set(panelId, currentTimeTaken)
                } else {
                    timeTakenByPanelId.set(panelId, takePanel.timeTaken)
                }

                // Store score
                if (scoreByPanelId.has(panelId)) {
                    const currentScore = scoreByPanelId.get(panelId)!
                    if (takePanel.totalPanelScore > currentScore) {
                        scoreByPanelId.set(panelId, takePanel.totalPanelScore)
                    }
                } else {
                    scoreByPanelId.set(panelId, takePanel.totalPanelScore)
                }

                // Store the completed certification take panels
                languages.forEach((language: Language) => {
                    if (
                        (takePanel.panelId === language.certificationTest1PanelId ||
                            takePanel.panelId === language.certificationTest2PanelId) &&
                        takePanel.phase === "goodbye"
                    ) {
                        language.completedCertificationPanels.push(takePanel)
                        if (takePanel.level === "P") {
                            language.certificationTestCompleted = true
                        }
                    }
                })
            })

            languages.forEach((language: Language) => {
                // Certification test 1 time taken
                if (timeTakenByPanelId.has(language.certificationTest1PanelId)) {
                    language.certificationTestTimeTaken += timeTakenByPanelId.get(language.certificationTest1PanelId)!
                }

                // Certification test 2 time taken
                if (timeTakenByPanelId.has(language.certificationTest2PanelId)) {
                    language.certificationTestTimeTaken += timeTakenByPanelId.get(language.certificationTest2PanelId)!
                }

                // Certification test score
                if (scoreByPanelId.has(language.certificationTest1PanelId)) {
                    if (scoreByPanelId.get(language.certificationTest1PanelId)! > language.certificationTestScore) {
                        language.certificationTestScore = scoreByPanelId.get(language.certificationTest1PanelId)!
                    }
                }
                if (scoreByPanelId.has(language.certificationTest2PanelId)) {
                    if (scoreByPanelId.get(language.certificationTest2PanelId)! > language.certificationTestScore) {
                        language.certificationTestScore = scoreByPanelId.get(language.certificationTest2PanelId)!
                    }
                }

                // Practice test time taken
                if (timeTakenByPanelId.has(language.practiceTestPanelId)) {
                    language.practiceTestTimeTaken += timeTakenByPanelId.get(language.practiceTestPanelId)!
                }

                // Practice test score
                if (scoreByPanelId.has(language.practiceTestPanelId)) {
                    language.practiceTestScore = scoreByPanelId.get(language.practiceTestPanelId)!
                }

                language.modules.forEach((module: Module) => {
                    module.panels.sort((a: ModulePanel) => {
                        if (a.type === PanelType.Training) {
                            return -1
                        }

                        if (a.type === PanelType.Test) {
                            return 1
                        }

                        return 0
                    })

                    // Set the next panel ids
                    if (module.panels.length === 2) {
                        module.panels[0].nextPanelId = module.panels[1].id
                    }

                    if (
                        passedPanels.has(language.certificationTest1PanelId) ||
                        passedPanels.has(language.certificationTest2PanelId)
                    ) {
                        language.certificationTestCompleted = true
                    }

                    module.panels.forEach((panel: ModulePanel) => {
                        const previousPanelId: number | undefined = panel.previousPanelId
                        if (!isNullOrUndefined(previousPanelId)) {
                            if (passedPanels.has(previousPanelId)) {
                                panel.disabled = false
                            }
                        }

                        if (passedPanels.has(panel.id)) {
                            panel.passed = true
                        }

                        if (unlockedPanels.has(panel.id)) {
                            panel.disabled = false
                        }

                        if (existingTakeCodesByPanelId.has(panel.id)) {
                            language.existingTakeCodesByPanelId.set(panel.id, existingTakeCodesByPanelId.get(panel.id)!)
                        }

                        // Module score
                        if (scoreByPanelId.has(panel.id)) {
                            panel.score = scoreByPanelId.get(panel.id)!
                        }

                        // Module time taken
                        if (timeTakenByPanelId.has(panel.id)) {
                            panel.timeTaken += timeTakenByPanelId.get(panel.id)!
                        }

                        // Sum time taken from the module panels to the module
                        module.timeTaken += panel.timeTaken

                        language.panels.push(panel)
                    })
                })

                // Store existing certification take codes
                if (existingTakeCodesByPanelId.has(language.certificationTest1PanelId)) {
                    language.existingTakeCodesByPanelId.set(
                        language.certificationTest1PanelId,
                        existingTakeCodesByPanelId.get(language.certificationTest1PanelId)!
                    )
                } else if (existingTakeCodesByPanelId.has(language.certificationTest2PanelId)) {
                    language.existingTakeCodesByPanelId.set(
                        language.certificationTest2PanelId,
                        existingTakeCodesByPanelId.get(language.certificationTest2PanelId)!
                    )
                }

                // Sort the certification panels by finishedTime
                language.completedCertificationPanels.sort((a: ApiTakePanelResponse, b: ApiTakePanelResponse) => {
                    if (a.finishedTime === null || b.finishedTime === null) {
                        throw new Error("completedCertificationPanels has null finished time")
                    }

                    const aFinishedTime: moment.Moment = moment(a.finishedTime)
                    const bFinishedTime: moment.Moment = moment(b.finishedTime)

                    if (aFinishedTime < bFinishedTime) {
                        return -1
                    }
                    if (aFinishedTime > bFinishedTime) {
                        return 1
                    }
                    return 0
                })

                // Check if last completed certification panel is the first available panel
                const numPanels: number = language.completedCertificationPanels.length
                if (numPanels !== 0) {
                    const lastCompletedPanel: ApiTakePanelResponse =
                        language.completedCertificationPanels[numPanels - 1]
                    if (lastCompletedPanel) {
                        if (lastCompletedPanel.panelId === language.certificationTest1PanelId) {
                            language.showFirstCertificationTest = false
                        }
                    }
                }

                // Unlock certification tests
                let passedCount: number = 0
                language.panels.forEach((panel: ModulePanel) => {
                    if (panel.passed) {
                        passedCount++
                    }
                })
                if (passedCount === language.panels.length) {
                    language.certificationTestDisabled = false
                }
            })
        }

        return languages
    }

    refreshTest(
        props: any,
        lastPage: LastPage,
        loginData: LoginProductContentAreaData
    ): Promise<any | ApiErrorResponse> {
        const takeCode: string = elvis<string>(
            lastPage.takeCode,
            new Error("[Helper.refreshTest] lastPage.takeCode is null or undefined")
        )
        if (takeCode === "") {
            throw new Error("[Helper.refreshTest] lastPage.takeCode is an empty string")
        }

        const currentPanelId: number = elvis<number>(
            lastPage.panelId,
            new Error("[Helper.refreshTest] lastPage.panelId is null or undefined")
        )
        const panelGraphId: number = elvis<number>(
            lastPage.panelGraphId,
            new Error("[Helper.refreshTest] lastPage.panelGraphId is null or undefined")
        )

        return new Promise((resolve, reject) => {
            ApiService.fetchTake(new TakeCode(takeCode), new PanelGraphId(panelGraphId)).then(
                (apiTake: ApiTake) => {
                    const take: Take = {
                        id: apiTake.id,
                        currentPanelId,
                        takeCode,
                        availablePanels: [],
                        takePanels: [],
                        created: apiTake.created
                    }
                    props.updateTakeDispatch(take)
                    props.setTakeCodeDispatch(takeCode)
                    resolve(apiTake)
                },
                (err: ApiErrorResponse) => {
                    reject()
                    throw new Error(
                        `Failed call to fetch take. ${err.response.data.error} - ${err.response.data.message}`
                    )
                }
            )
        })
    }

    refreshTestCallback(testState: TestState, props: any): void {
        // Do nothing
    }

}
