/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import React, {useState} from "react"
import CredlyReportSearchForm, {CredlyReportFilters} from "./CredlyReportSearchForm"
import ApiService from "../../../services/ApiService"
import {AxiosResponse} from "axios"
import {loadingSpinnerStore} from "../../../app/common/loaders/LoadingSpinnerStore"
import {messageStore} from "../../../app/common/messages/MessageStore"
import {ApiErrorResponse} from "../../../types/types"
import {CredlyReportTables} from "./CredlyReportTables"
import moment from "moment"

export interface CredlyReportRow {
    loginName: string
    firstName: string
    lastName: string
    email: string
    product: string
    testCode: string
    language: string
    state: string
    districtOrCustomer: string
    school: string
    testGroupOrLocation: string
    badgeEligible: boolean
    badgeName: string
    badgeDateAwarded?: string
    badgesEligibleCount?: number
    badgesAwardedCount?: number
}

export const CredlyReportTool: React.FC = () => {
    const [reportData, updateReportData] = useState<CredlyReportRow[]>([])

    const onSubmit = (filters: CredlyReportFilters) => {
        loadingSpinnerStore.hideLoadingSpinner = false

        ApiService.post(`${ApiService.API_URL}credly/reports`, filters)
            .then((response: AxiosResponse<CredlyReportRow[]>) => {
                loadingSpinnerStore.hideLoadingSpinner = true

                response.data.forEach((row: CredlyReportRow) => {
                    row.badgeDateAwarded = row.badgeDateAwarded ? moment(row.badgeDateAwarded).format("MM/DD/YYYY") : ""
                })

                updateReportData(response.data)
            })
            .catch((error: ApiErrorResponse) => {
                loadingSpinnerStore.hideLoadingSpinner = true
                messageStore.setErrorMessage(error.response.data.message)
            })
    }

    return (
        <>
            <CredlyReportSearchForm onSubmit={onSubmit}/>
            <CredlyReportTables reportData={reportData} />
        </>
    )
}
