import * as Sentry from "@sentry/browser"
import * as React from "react"
import { useEffect, useState} from "react"
import {RouteComponentProps} from "react-router-dom"
import {HEADER_HEIGHT} from "../../../components/Header/Header"
import MessageWall from "../../../components/Login/MessageWall"
import ProctorApi from "../../../services/ProctorApi"
import {theme} from "../../../styles/MuiThemes"
import {log} from "../../../util/Logging"
import {AvantCircularProgress} from "../../common/loaders/AvantCircularProgress"
import {reportsPadding} from "../../reports/common/components/ReportsPage"
import {SSOWrapper} from "../../sso/components/SSOWrapper"
import {DIYLoginError} from "./DIYLoginError"
import DIYStudentLoginEntry from "./DIYStudentLoginEntry"
import {connect} from "react-redux"

interface DIYLoginPageMatch extends RouteComponentProps<{}> {
    loginId: string
}


// This is the student landing page for proctoring
// @ts-ignore // <- becasue we didn't define valiues for route component props
const DIYLoginPage: React.FC<RouteComponentProps<DIYLoginPageMatch>> = (props) => {
    const [isError, setIsError] = useState(false)
    const [displayLogin, setDisplayLogin] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [errorTitle, setErrorTitle] = useState("")

    useEffect(() => {

        const loginData = async () => {
            const loginId = props.match.params.loginId
            await ProctorApi.verificientStudentLoginCheck(loginId)
                .then(result => {
                    if (result.redirectUrl != null) {
                        setErrorTitle(result.redirectUrl)
                        setIsError(false)
                        setDisplayLogin(true)
                    } else {
                        log.info(`DIYLoginPage - Token: ${result.proctorEduToken}`)
                        setErrorTitle(result.proctorEduToken ? result.proctorEduToken : "NoToken")
                        setIsError(false)
                        setDisplayLogin(true)
                    }

                })
                .catch(error => {
                    if (error.response.data) {
                        if (error.response.status === 428) {
                            // Pass through the error for Test Locked
                            setIsError(false)
                            setDisplayLogin(true)
                            return
                        }
                        if (error.response.data.kind) { setErrorTitle(error.response.data.kind) }
                        if (error.response.data.errors && error.response.data.errors.length > 0) { setErrorMessage(error.response.data.errors[0]) }
                        if (error.status_code === 500) {
                            Sentry.captureException(error)
                        }
                        setIsError(true)
                        setDisplayLogin(false)
                    }
                })
        }
        loginData()



        // Validate if the student can continue.
    }, [])

    return (
        <SSOWrapper
            style={{
                padding: reportsPadding,
                paddingTop: HEADER_HEIGHT + theme.spacing(4),
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
        <div>
            <MessageWall/>
            <div className="login__login-container" style={{display: "inline-block"}}>
                <div className="login__login-container__top">
            { isError && (
                <DIYLoginError title={errorTitle} message={errorMessage}/>
            )}
            { !isError && (
                <>
                {displayLogin && (
                    <DIYStudentLoginEntry
                        loginId={props.match.params.loginId}
                        // The below are the route component props so we can redirect
                        history={props.history}
                        match={props.match}
                        location={props.location}
                    />
                )}
                {!displayLogin && (
                    <AvantCircularProgress/>
                )}
                </>
            )}
                </div>
            </div>
        </div>
        </SSOWrapper>
    )
}

// Connect to redux
export default connect(null)(DIYLoginPage)