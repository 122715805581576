// Original JavaScript code by Chirp Internet: www.chirp.com.au
// Please acknowledge use of this code by including this header.
// See https://www.the-art-of-web.com/javascript/creating-sounds/


export enum WAVE_TYPE  {
    "sawtooth" = "sawtooth",
    "sine" = "sine",
    "square" = "square",
    "triangle" = "triangle"
}

export class SoundPlayer {
    audioContext: any
    gainNode: any
    oscillator: any

    constructor(audioContext: any) {
        this.audioContext = audioContext
        this.gainNode = this.audioContext.createGain()
        this.gainNode.connect(this.audioContext.destination)
        this.oscillator = null
    }

    setFrequency = (val: number, when: number = 0.0) => {
        this.oscillator.frequency.setValueAtTime(val, this.audioContext.currentTime + when)
        return this
    }

    setVolume = (val: number, when: number = 0.0) => {
        this.gainNode.gain.exponentialRampToValueAtTime(val, this.audioContext.currentTime + when)
        return this
    }

    setWaveType = (waveType: WAVE_TYPE) => {
        console.log(`setting waveType, waveType`, waveType)
        this.oscillator.type = waveType
        return this
    }

    play = (frequency: number, waveType: WAVE_TYPE, when: number = 0.0, vol: number = 1.0) => {
        this.oscillator = this.audioContext.createOscillator()
        this.oscillator.connect(this.gainNode)
        this.setFrequency(frequency)
        console.log(`play waveType`, waveType)
        if (waveType) {
            this.setWaveType(waveType)
        }
        this.setVolume(1 / 1000)
        if (when) {
            this.setVolume(1 / 1000, when - 0.02)
            this.oscillator.start(when - 0.02)
            this.setVolume(vol, when)
        } else {
            this.oscillator.start()
            this.setVolume(vol, 0.02)
        }
        return this
    }

    stop = (when: number = 0.0) => {
        if (when) {
            this.gainNode.gain.setTargetAtTime(1 / 1000, this.audioContext.currentTime + when - 0.05, 0.02)
            this.oscillator.stop(this.audioContext.currentTime + when)
        } else {
            this.gainNode.gain.setTargetAtTime(1 / 1000, this.audioContext.currentTime, 0.02)
            this.oscillator.stop(this.audioContext.currentTime + 0.05)
        }
        return this
    }

}

