import React from "react"
import InputDeviceOption from "./InputDeviceOption/InputDeviceOption"

interface IComponentProps {
    inputDevices: MediaDeviceInfo[]
    currentInputDevice?: MediaDeviceInfo
}

interface IComponentState {
    currentInputDevice?: MediaDeviceInfo
}

export default class InputDeviceSelection extends React.Component<IComponentProps, IComponentState> {
    constructor(props: IComponentProps) {
        super(props)
        this.state = {currentInputDevice: this.props.currentInputDevice}
    }

    render() {
        const inputDevices = this.props.inputDevices.map((device: MediaDeviceInfo) => {
            let currentDeviceId = ""
            if (this.props.currentInputDevice) {
                currentDeviceId = this.props.currentInputDevice.deviceId
            }
            let isSelected = false
            if (currentDeviceId === device.deviceId) {
                isSelected = true
            }
            const key = "InputDeviceOption-" + device.deviceId
            return <InputDeviceOption inputDevice={device} isSelected={isSelected} key={key} />
        })

        return (
            <div className={"InputDeviceSelection"}>
                <div className={"InputDeviceSelection--title"}>Input Source</div>
                <select className={"InputDeviceSelection__list"}>{inputDevices}</select>
            </div>
        )
    }
}
