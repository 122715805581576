import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import React from "react";
import { Tooltip } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const styles = createStyles({
    button: {
        minWidth: "115px",
        height: 40,
        borderRadius: 4,
        fontWeight: "bold",
        fontSize: "15px !important",
        backgroundColor: "#F9E6E8",
        color: "#D0021B",
        border: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 8,
        paddingRight: 8,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#b2adae"
        }
    },
    greybutton: {
        minWidth: "115px",
        marginRight: "74px",
        marginLeft:"-20px",
        height: 40,
        borderRadius: 4,
        fontWeight: "bold",
        fontSize: "15px !important",
        backgroundColor: "#C0C0C0",
        color: "rgba(97, 97, 97, 0.9)",
        border: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 8,
        paddingRight: 8,
    },
    tooltip: {  
        fontSize: "0.9em",  
    },
});

interface Props extends WithStyles<typeof styles> {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    ReRecordFlag: boolean;
}

const ReRecordingButton: React.FC<Props> = ({ classes, onClick, ReRecordFlag }) => (
    <Tooltip 
        title={"You can only re-record once."} 
        placement="top"
        classes={{ tooltip: classes.tooltip }}  // Applying the tooltip style here
    >
        <button 
            type="button" 
            disabled={ReRecordFlag} 
            className={ReRecordFlag ? classes.greybutton : classes.button} 
            onClick={onClick} 
            data-tst-id="BeginVoiceRecordingButton"
        >
           {/* <img alt="Begin Recording" src="https://cdn.avantassessment.com/resources/icons/icon-record.svg" /> */}
           <FiberManualRecordIcon/>
            {"Re-Record"}
        </button>
    </Tooltip>
);

export default withStyles(styles)(ReRecordingButton);
