import {Button, CircularProgress} from "@material-ui/core"
import {observer} from "mobx-react"
import React from "react"
import ReactQuill from "react-quill"
import {theme} from "../../../styles/MuiThemes"
import {log} from "../../../util/Logging"
import {EditableTrainingContentStore, editableTrainingContentStore} from "./EditableTrainingContentStore"

interface EditableTrainingContentContainerProps {
    itemContentId: number
    itemId: number
}

@observer
export class EditableTrainingContentContainer extends React.Component<EditableTrainingContentContainerProps> {

    componentDidMount(): void {
        editableTrainingContentStore.itemId = this.props.itemId
        editableTrainingContentStore.currentItemTrainingContent = undefined
        editableTrainingContentStore.findItemContent(this.props.itemContentId)
    }

    render() {
        const itemContent = editableTrainingContentStore.currentItemTrainingContent
        if (itemContent == null) {
            return <CircularProgress/>
        }

        return (
            <EditableTrainingContent
                itemContentId={editableTrainingContentStore.itemContentId!}
                content={itemContent}
                itemId={editableTrainingContentStore.itemId!}
                itemEditStore={editableTrainingContentStore}
            />
        )
    }
}

const modules = {
    toolbar: [
        [
            {font: []},
            {header: [1, 2, 3, 4, 5, 6, false]},
            "bold",
            "italic",
            "underline",
            "strike",
            {color: []},
            {background: []},
            "clean"
        ]
    ]
}

interface EditableTrainingContentProps {
    itemContentId: number
    content: string
    itemId: number
    itemEditStore: EditableTrainingContentStore
}

@observer
class EditableTrainingContent extends React.Component<EditableTrainingContentProps> {

    constructor(props: EditableTrainingContentProps) {
        super(props)
        this.props.itemEditStore.editedTrainingContent = props.content
    }

    componentDidMount(): void {
        this.props.itemEditStore.editedTrainingContent = this.props.content
        this.props.itemEditStore.itemContentId = this.props.itemContentId
    }

    componentWillUnmount(): void {
        this.props.itemEditStore.editedTrainingContent = ""
        this.props.itemEditStore.currentItemTrainingContent = undefined
        this.props.itemEditStore.itemContentId = undefined
    }

    // Need to find a way to reference the Quill.Delta object
    handleChange = (value: string, delta: any, source: string) => {
        this.props.itemEditStore.editedTrainingContent = value
        if (source === "user") {
            this.props.itemEditStore.changesMade = true
        }
    }

    render() {
        const {itemEditStore} = this.props
        const noChangesMade = itemEditStore.editedTrainingContent === itemEditStore.currentItemTrainingContent
        log.debug(`No changes made to edit training content: ${noChangesMade}`)

        return (
            <div>
                <ReactQuill
                    theme="snow"
                    modules={modules}
                    value={itemEditStore.editedTrainingContent}
                    onChange={this.handleChange}
                />
                <div style={{display: "flex", marginTop: theme.spacing(2)}}>
                    <div style={{flexGrow: 1}}/>
                    <Button
                        onClick={() => {
                            if (itemEditStore.currentItemTrainingContent) {
                                itemEditStore.editedTrainingContent = itemEditStore.currentItemTrainingContent
                                itemEditStore.changesMade = false
                            }
                        }}
                        style={{marginRight: theme.spacing(2)}}
                        variant={"outlined"}
                        disabled={!itemEditStore.changesMade}
                    >
                        Reset
                    </Button>
                    <Button
                        onClick={itemEditStore.save}
                        color={"primary"}
                        variant={"contained"}
                        disabled={!itemEditStore.changesMade}
                    >
                        Save
                    </Button>
                </div>
            </div>
        )
    }
}
