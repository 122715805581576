import {Box} from "@material-ui/core"
import * as React from "react"
import {theme} from "../../../../styles/MuiThemes"
import {AvantDateRange} from "../../../../util/TimeUtil"
import AvantDateRangePicker from "../../../generic-components/AvantDateRangePicker"
import {ClassReportsPrintMenu} from "../../class/components/ClassReportsPrintMenu"
import {DistrictReportsPrintMenu} from "../../district-school/components/DistrictReportsPrintMenu"
import {SchoolReportsPrintMenu} from "../../district-school/components/SchoolReportsPrintMenu"
import {BlueReportType} from "../models/BlueReportType"
import {ReportFilters} from "../models/ReportFilters"
import {LevelKey} from "./level-key/LevelKey"
import {ReportsDownloadMenu} from "./ReportsDownloadMenu"
import {ReportGraphFilters} from "../models/ReportGraphFilters"
import {LoadingSpinner} from "../../../common/loaders/LoadingSpinner"
import {ReportUtils} from "../../ReportUtils"
import {ReportSubPaths} from "../../ReportSubPaths"

interface FilterAndPrintOptionsProps {
    filters: ReportFilters
    query: ReportGraphFilters,
    reportType: BlueReportType
}

const datePickerStyles = {
    container: {
        marginRight: theme.spacing(2)
    }
}

export const reportsRecentActivityValue = "recentActivity"
export const reportsAssessmentStartValue = "assessmentStart"

export const FilterAndPrintOptions = (props: FilterAndPrintOptionsProps) => {
    const {filters, query, reportType} = props

    if (filters && !filters.productId) {
        return <LoadingSpinner />
    }

    return (
        <Box displayPrint={"none"}>
            <div style={{display: "flex", alignItems: "flex-end"}}>
                <div style={{flexGrow: 1}}/>
                <AvantDateRangePicker
                    style={datePickerStyles}
                    dateRange={filters}
                    createLink={(range: AvantDateRange, selectedValues: string[]) => {
                        const linkFilters = ReportUtils.rangeAndValuesToFilters(filters.productId, filters.groupId, range, selectedValues)
                        if (reportType === BlueReportType.DISTRICT) {
                            return ReportSubPaths.district(linkFilters)
                        }
                        if (reportType === BlueReportType.SCHOOL) {
                            return ReportSubPaths.school(linkFilters)
                        }

                        return ReportSubPaths.class(linkFilters)
                    }}
                    options={[{
                        name: "Recent Activity",
                        value: reportsRecentActivityValue,
                        selected: filters.recentActivity
                    }, {
                        name: "Start Date",
                        value: reportsAssessmentStartValue,
                        selected: filters.assessmentStart
                    }]}
                    showTitle={true}
                />
                <LevelKey/>
                <PrintMenu printMenu={reportType} filters={filters} query={query}/>
                <ReportsDownloadMenu {...query}/>
            </div>
        </Box>
    )
}

interface PrintMenuProps {
    printMenu: BlueReportType
    filters: ReportFilters
    query: ReportGraphFilters
}

const PrintMenu: React.FC<PrintMenuProps> = ({printMenu, filters, query}) => {
    switch (printMenu) {
        case BlueReportType.DISTRICT:
            return <DistrictReportsPrintMenu filters={filters}/>
        case BlueReportType.SCHOOL:
            return <SchoolReportsPrintMenu filters={filters}/>
        case BlueReportType.CLASS:
            return <ClassReportsPrintMenu filters={filters} query={query}/>
        default:
            return null
    }
}
