/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import React, {ChangeEvent, useState} from "react"
import {Box, Button, Grid, Link, Paper} from "@material-ui/core"
import {H2Text} from "../../styles/AvantTypography"
import {theme} from "../../styles/MuiThemes"
import ApiService from "../../services/ApiService"
import MaterialTable from "material-table"
import {messageStore} from "../../app/common/messages/MessageStore"
import axios, {AxiosError} from "axios";

export interface CredlyBadge {
    id: string
    name: string
    level: string
    templateId: string
    productPackage: string
    contentAreaId: string
    badgeImage: string
}

export interface CredlyRow {
    testCode: string
    firstName: string | JSX.Element
    lastName: string | JSX.Element
    loginName: string
    email: string | JSX.Element
    takeUUID: string
    badge: CredlyBadge | null
}

interface CredlyRowErrors {
    firstName: string
    lastName: string
    email: string
}

export interface CredlyPrevalidationResponse {
    rows: CredlyRow[]
    errors: CredlyRowErrors[]
}

export interface CredlyIssueBadgesResponse {
    loginName: string
    badgeName: string | null
    badgeIssued: boolean
    badgeIssueError: string | null
}

export const UploadCredlyFromFile: React.FC = () => {
    const [file, setFile] = useState<File | null>(null)
    const [prevalidationResponse, setResponse] = useState<CredlyRow[]>([])
    const [issueBadgeResults, setResults] = useState<CredlyIssueBadgesResponse[]>([])
    const [hasErrors, setHasErrors] = useState<boolean>(false)

    const uploadAndPreValidate = () => {
        // Early return if a file hasn't been selected
        if (file == null) {
            return
        }

        ApiService.uploadAndPreValidateCredly(file)
            .then((response: CredlyPrevalidationResponse) => {
                const rows = Object.assign(response.rows)

                rows.forEach((row: CredlyRow, idx: number) => {
                    // Check if there are any errors and add them to the row
                    const errors = response.errors[idx]

                    if (errors.firstName != "") {
                        row.firstName = <Box className={"error"}>{errors.firstName}</Box>

                        if (!hasErrors) {
                            setHasErrors(true)
                        }
                    }

                    if (errors.lastName != "") {
                        row.lastName = <Box className={"error"}>{errors.lastName}</Box>

                        if (!hasErrors) {
                            setHasErrors(true)
                        }
                    }

                    if (errors.email != "") {
                        row.email = <Box className={"error"}>{errors.email}</Box>

                        if (!hasErrors) {
                            setHasErrors(true)
                            messageStore.setErrorMessage("Fix all noted errors and reupload the file to issue Credly badges.")
                        }
                    }
                })

                setResponse(rows)
            })
            .catch((error) => {
                const errors = error as Error | AxiosError;
                // TODO: Find a better way to handle this potential non axios error
                if (!axios.isAxiosError(errors)) {
                    error.response && messageStore.setErrorMessage(error.response.data.message)
                } else {
                    error.response && messageStore.setErrorMessage(error.response.data.message)
                }
            })
    }

    const issueBadges = () => {
        const issueBadges = prevalidationResponse.filter((row: CredlyRow) => row.badge && row.badge.templateId != null)
        ApiService.issueCredlyBadges(issueBadges)
            .then((response: CredlyIssueBadgesResponse[]) => {
                setResults(response)
            })
            .catch((error) => {
                const errors = error as Error | AxiosError;
                // TODO: Find a better way to handle this potential non axios error
                if (!axios.isAxiosError(errors)) {
                    error.response && messageStore.setErrorMessage(error.response.data.message)
                } else {
                    error.response && messageStore.setErrorMessage(error.response.data.message)
                }
            })
    }

    return (
        <>
            <h1>Credly Upload Tool</h1>
            <Paper className={"form-group"} style={{padding: theme.spacing(2)}}>
                <Grid container style={{marginBottom: theme.spacing(1)}}>
                    <Grid item sm={12}>
                        <H2Text>Upload your file</H2Text>
                    </Grid>
                    <Grid item sm={12}>
                        Download the template file: <Link href={"https://cdn.avantassessment.com/resources/common/credly-upload-template.csv"}>Credly Template</Link>
                    </Grid>
                    <Grid item sm={6} style={{paddingRight: theme.spacing(1), marginTop: theme.spacing(1)}}>
                        <input
                            type="file"
                            className={"form-control"}
                            name={"file"}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setFile(e.target.files![0])}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            disabled={!file || prevalidationResponse.length > 0}
                            onClick={() => uploadAndPreValidate()}
                        >
                            Upload
                        </Button>
                    </Grid>
                </Grid>
                {(prevalidationResponse.length > 0 && issueBadgeResults.length == 0) &&
                    <>
                        <MaterialTable
                            title={"Validation Results"}
                            columns={[
                                {
                                    title: "Test code",
                                    field: "testCode",
                                    type: "string",
                                },
                                {
                                    title: "First name",
                                    field: "firstName",
                                    type: "string",
                                },
                                {
                                    title: "Last name",
                                    field: "lastName",
                                    type: "string",
                                },
                                {
                                    title: "Login name",
                                    field: "loginName",
                                    type: "string",
                                },
                                {
                                    title: "Email",
                                    field: "email",
                                    type: "string",
                                },
                                {
                                    title: "Earned Badge",
                                    field: "badge",
                                    type: "boolean",
                                    render: (rowData: CredlyRow) => rowData.badge ? rowData.badge.name : <Box className={"error"}>Badge not found</Box>
                                }
                            ]}
                            data={prevalidationResponse}
                            style={{marginBottom: theme.spacing(1)}}
                        />
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            disabled={hasErrors}
                            onClick={() => issueBadges()}
                        >
                            Submit to Credly
                        </Button>
                    </>
                }
                {issueBadgeResults.length > 0 &&
                    <MaterialTable
                        title={`${issueBadgeResults.filter(badge => badge.badgeIssued).length} Badges Issued`}
                        columns={[
                            {
                                title: "Login name",
                                field: "loginName",
                            },
                            {
                                title: "Badge",
                                field: "badgeName",
                                render: (row: CredlyIssueBadgesResponse) => {
                                    return row.badgeIssued
                                        ? row.badgeName
                                        : <Box className="error">Badge: <Box component={"strong"}>{row.badgeName}</Box> has already been issued for this test taker</Box>
                                }
                            }
                        ]}
                        data={issueBadgeResults}
                    />
                }
            </Paper>
        </>
    )
 }
