import LocalizedStrings, {LocalizedStringsMethods} from "react-localization"

export interface LocalStrings extends LocalizedStringsMethods {
    login: string
    logout: string
    stopTest: string
    cancel: string
    startNewTest: string
    continueTest: string
    welcomeToTheTest: string
    welcomeBack: string
    enterLoginName: string
    loginName: string
    setUpYourProfile: string
    createLoginName: string
    loginNameDescription: string
    loginNameExamples: string
    loading: string
    continueWarningStart: string
    continueWarningEnd: string
    next: string
    loginNameExistsPart1: string
    loginNameExistsPart2: string
    loginNameExistsPart3: string
    rosteredLoginNotFound: string
    congratulationsFinished: string
    answersSaved: string
    generalError: string
    dashboard: string
    reading: string
    grammar: string
    writing: string
    listening: string
    speaking: string
    vocabulary: string
    dictation: string
    translation: string
    sentenceCompletion: string
    verbConjugation: string
    chooseBestAnswer: string
    chooseBestTwoAnswers: string
    yes: string
    no: string
    enterYourFirstName: string
    enterYourLastName: string
    enterYourPhoneNumber: string
    enterYourEmail: string
    enterValidPhoneNumber: string
    enterValidEmail: string
    continueTheTestLater: string
    weAreHavingTroubleConnecting: string
    cannotConnectToServer: string
}

export const languages = {
    en: {
        login: "LOGIN",
        logout: "LOGOUT",
        stopTest: "STOP TEST",
        cancel: "CANCEL",
        startNewTest: "START NEW TEST",
        continueTest: "CONTINUE TEST",
        welcomeToTheTest: "Welcome to the {0} Test",
        welcomeBack: "Welcome Back!",
        enterLoginName: "Please enter your login name to continue testing.",
        loginName: "Login Name",
        setUpYourProfile: "Set Up Your Profile",
        createLoginName: "Please Create a Login Name",
        loginNameDescription:
            "Your login name is what will help you return to your test later. " +
            "Your login name needs to be unique from other test takers. Ask your teacher/proctor " +
            "for guidance on what to enter and make sure you can remember it!",
        loginNameExamples: "Examples: Your Student ID or Full Name",
        loading: "Loading...",
        continueWarningStart:
            "That login name is incorrect. Please try again or ask your teacher for help. " +
            "If you need to start a new test,",
        continueWarningEnd: "create an account here.",
        next: "NEXT",
        loginNameExistsPart1: "That login name already exists. Do you want to",
        loginNameExistsPart2: "login",
        loginNameExistsPart3: "to continue a test instead?",
        rosteredLoginNotFound:
            "The login name entered could not be found. Please check that you entered the correct login name.",
        congratulationsFinished: "Congratulations! You have completed the test!",
        answersSaved: "All of your answers have been saved.",
        generalError: "Sorry, an error occurred. Please contact {0}",
        dashboard: "Dashboard",
        reading: "Reading",
        grammar: "Grammar",
        writing: "Writing",
        listening: "Listening",
        speaking: "Speaking",
        vocabulary: "Vocabulary",
        dictation: "Dictation",
        translation: "Translation",
        sentenceCompletion: "Sentence Completion",
        verbConjugation: "Verb Conjugation",
        chooseBestAnswer: "Choose the best answer.",
        chooseBestTwoAnswers: "Choose the two best answers.",
        yes: "Yes",
        no: "No",
        enterYourFirstName: "You must enter your first name.",
        enterYourLastName: "You must enter your last name.",
        enterYourPhoneNumber: "You must enter your telephone number.",
        enterYourEmail: "You must enter your email.",
        enterValidPhoneNumber: "You must enter a valid telephone number.",
        enterValidEmail: "You must enter a valid email.",
        continueTheTestLater: "To continue the test later use your",
        weAreHavingTroubleConnecting: "We are having trouble connecting to the server. Please try again.",
        cannotConnectToServer: "Cannot Connect to Server"
    },
    es: {
        login: "INICIAR SESIÓN",
        logout: "CERRAR SESIÓN",
        stopTest: "PARAR PRUEBA",
        cancel: "CANCELAR",
        startNewTest: "COMIENZE UNA NUEVA PRUEBA",
        continueTest: "CONTINÚE LA PRUEBA",
        welcomeToTheTest: "Bienvenido a la prueba de {0}",
        welcomeBack: "Bienvenido Nuevamente!",
        enterLoginName: "Por favor ingrese su nombre de usuario para continuar la prueba.",
        loginName: "Nombre de usuario",
        setUpYourProfile: "Crea tu perfil",
        createLoginName: "Por favor crea un nombre de usuario",
        loginNameDescription:
            "Su nombre de usuario es lo que le ayudará a regresar a su prueba más tarde. " +
            "Su nombre de usuario debe ser único y diferente al de otras personas que también esten tomando esta " +
            "prueba. Su maestro puede ayudarle con algunas ideas para crear su nombre de usuario y asegúrese de " +
            "que pueda recordarlo!",
        loginNameExamples: "Ejemplos: Su número de identificación de estudiante o su nombre completo.",
        loading: "Cargando...",
        continueWarningStart:
            "El nombre de usuario es incorrecto. Por favor intente de nuevo o pida ayuda a su " +
            "maestro. Si necesita comenzar una nueva prueba,",
        continueWarningEnd: "cree una cuenta aquí.",
        next: "SIGUIENTE",
        loginNameExistsPart1: "Ese nombre de usuario ya existe. Desea",
        loginNameExistsPart2: "iniciar sesión",
        loginNameExistsPart3: "para continuar con la prueba?",
        rosteredLoginNotFound:
            "Esa nombre de usuario no existe. Asegúrese de haber ingresado el nombre de usuario correctamente.",
        congratulationsFinished: "Felicitaciones! Usted ha completado la prueba!",
        answersSaved: "Todas sus respuestas han sido guardadas.",
        generalError: "Lo sentimos, ha ocurrido un error. Por favor contacte {0}",
        dashboard: "Página de inicio",
        reading: "Lectura",
        grammar: "Gramática",
        writing: "Escritura",
        listening: "Escuchando",
        speaking: "Hablando",
        vocabulary: "Vocabulario",
        dictation: "Dictado",
        translation: "Traducción",
        sentenceCompletion: "Completar oraciones",
        verbConjugation: "Conjugación de verbo",
        chooseBestAnswer: "Seleccione la mejor respuesta.",
        chooseBestTwoAnswers: "Selecione las mejores dos respuestas.",
        yes: "Sí",
        no: "No",
        enterYourFirstName: "Ingrese su primer nombre.",
        enterYourLastName: "Ingrese su apellido.",
        enterYourPhoneNumber: "Ingrese su número telefónico.",
        enterYourEmail: "Ingrese su correo electrônico.",
        enterValidPhoneNumber: "Ingrese un nûmero de telêfono vâlido.",
        enterValidEmail: "Ingrese un correo electrônico vâlido.",
        continueTheTestLater: "Para continuar la prueba más tarde, use su",
        weAreHavingTroubleConnecting: "Estamos teniendo problemas para conectarse al servidor. Inténtalo de nuevo.",
        cannotConnectToServer: "No es posible conectar con el servidor"
    }
}

export const localizationStrings: LocalStrings = new LocalizedStrings(languages)
