import {observer} from "mobx-react"
import React from "react"
import {RouteComponentProps} from "react-router"
import {authStore} from "../../../app/common/authentication/AuthStore"
import {loadingSpinnerStore} from "../../../app/common/loaders/LoadingSpinnerStore"
import ApiService from "../../../services/ApiService"
import HelperService from "../../../services/HelperService"
import {SUPPORT_MESSAGE} from "../../../util/Constants"
import Button from "../../Button/Button"


type TComponentProps = RouteComponentProps<any>

interface IComponentState {
    error: boolean | string
}

@observer
export class AdvanceEula extends React.Component<TComponentProps, IComponentState> {
    constructor(props: TComponentProps) {
        super(props)
        this.state = {
            error: false
        }
    }

    renderSummary = (): React.ReactNode => {
        const title = "ADVANCE User Agreement"
        const subtitle =
            "To continue, you will need to agree to an End User License Agreement (EULA). A summary " +
            "of the major points are:"
        const allowTitle = "Educator users as allowed to:"
        const allowPoint1 = "Access Avant ADVANCE for individual education training."
        const allowPoint2 =
            "Digitally display the AVANT ADVANCE Content in a classroom like setting " +
            "for Student group learning sessions within the Customer's organization."
        const requiredTitle = "Educator Users are required to comply with the following:"
        const requiredPoint1 =
            "Educators are NOT allowed to print out, or post screen shots or other " +
            "material contained in AVANT ADVANCE on YouTube or any other streaming video service."
        const requiredPoint2 =
            "User accounts and Key Codes are NOT transferable to any other educator, individual or group."
        const requiredPoint3 = "Do not share your account login with others."
        const instruction = "Read the entire EULA below then agree to continue."

        return (
            <>
                <p className="advance-eula__container__title">{title}</p>
                <p className="advance-eula__container__subtitle">{subtitle}</p>
                <div className="advance-eula__container__summary">
                    <p className="advance-eula__container__summary-title">{allowTitle}</p>
                    <ul className="advance-eula__container__list">
                        <li className="advance-eula__container__summary-text">{allowPoint1}</li>
                        <li className="advance-eula__container__summary-text">{allowPoint2}</li>
                    </ul>
                    <p className="advance-eula__container__summary-title">{requiredTitle}</p>
                    <ul className="advance-eula__container__list">
                        <li className="advance-eula__container__summary-text">{requiredPoint1}</li>
                        <li className="advance-eula__container__summary-text">{requiredPoint2}</li>
                        <li className="advance-eula__container__summary-text">{requiredPoint3}</li>
                    </ul>
                    <p className="advance-eula__container__instruction">{instruction}</p>
                </div>
            </>
        )
    }

    renderEula = (): React.ReactNode => {
        const eulaTitle = "END USER AGREEMENT"
        const eula = [
            `Access to AVANT’s proprietary training system and all training materials, programs, videos, data and 
            other content contained therein (collectively, the “AVANT ADVANCE System”) is provided to you 
            (“User” or “you”), under the terms set forth in this agreement.`,
            `1. Binding Agreement.  Upon clicking on the “AGREE” button, you are entering into a legally 
            binding agreement with Avant Assessment, LLC (“AVANT” or “we” or “us”) as described below 
            regarding usage of the AVANT ADVANCE System.  Read the terms of this agreement carefully BEFORE 
            clicking “AGREE”.  If you do not agree to the terms of this agreement, do not click “AGREE”.`,
            `2. End User Types.  There are two types of AVANT ADVANCE System users (collectively, “Users”): 
            (a) Educators; and (b) Administrative Users.`,
            `3. Educator Usage Rights.`,
            `3.1 “Educators” are users who access the AVANT ADVANCE System for the purpose of viewing and 
            participating in educational training courses and related activities and are employees of, or 
            independent contractors for, a Customer Account.  Educators are granted access through a unique 
            account identifier (e.g. a user account name and password or an encrypted link emailed to you) and a 
            license key code (“Key Code”).`,
            `3.2 All Educator Users are hereby granted a non-exclusive, limited, non-transferrable, 
            non-sublicensable right and license to: (a) access the AVANT ADVANCE System for Educator’s 
            individual education training purposes; and (b) print or copy from the AVANT ADVANCE System a 
            limited sample set of test questions and/or student responses for display in Educator’s classroom 
            for the express purpose of sharing such information with Educator’s students.  The rights granted 
            to Educator hereunder are subject to Educator’s ongoing compliance with the terms and conditions of 
            this Agreement.  For purposes of clarity: (1) Section 3.2(b) does not give Educator the right to 
            post screen shots or other materials contained within the AVANT ADVANCE System on YouTube or any other 
            streaming video service.  Once the Educator access fee is paid, it is not refundable or transferable; 
            and (2) any copies of test questions or student responses used in the classroom, as permitted under 
            Section 3.2(b), must include the following copyright notice in the bottom left corner in no less than 
            12 point font: ©2018 Avant Assessment, LLC.  All Rights Reserved.  Once enrolled, it is the sole 
            responsibility of the Educator to complete the entire training in which he/she is enrolled prior to 
            expiration of his/her authorized access.  User accounts and Key Codes are non-transferable.`,
            `4. Administrative Usage Rights and Restrictions.`,
            `4.1 “Administrative Users” are users with a unique account identifier who are employees of a 
            Customer Account.  A “Customer Account” is a business, agency or other organization having a 
            contract (either written or with digital affirmative assent) with AVANT. `,
            `4.2 Administrative Users are hereby granted a non-exclusive, limited, non-transferrable, non-sublicensable 
            right and license to: (a) distribute Key Codes to Users; (b) use the AVANT ADVANCE System for 
            administrative purposes related to the oversight and management of Educators and lower level 
            Administrative Users; (c) digitally display the AVANT Content in a classroom like setting for 
            Educator group learning sessions within Customer’s organization, but only where a group learning 
            license is purchased; (d) print, copy or otherwise reproduce instructional manuals and other 
            handouts included in the AVANT Content for use solely in connection with Educator group learning 
            sessions within Customer’s organization and only if a group learning license is purchased.  
            The rights granted to Administrative User hereunder are subject to Administrative User’s ongoing 
            compliance with the terms and conditions of this Agreement.  For purposes of clarity: (1) neither 
            Section 4.2(c) nor (d) gives Administrative User the right to post screen shots or other materials 
            contained within the AVANT ADVANCE System on YouTube or any other streaming video service; and (2) 
            any copies of instruction manuals or other handouts used in a group learning session, as permitted 
            under Section 4.2(d), must include the following copyright notice in the bottom left corner in no 
            less than 12 point font: ©2018 Avant Assessment, LLC.  All Rights Reserved.  Administrative User 
            accounts may not be used to view training related content for educational purposes, including without 
            limitation, continuing education.  If an Administrative User desires to access the AVANT ADVANCE 
            System for training purposes, such Administrative User will need to open a separate Educator User 
            account.  All User accounts and Key Codes are non-transferable.`,
            `5. Usage Restrictions.  Except for the limited rights granted above, you shall not modify, copy, 
            distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, 
            transfer, or sell any content obtained through use of the AVANT ADVANCE System. In addition, you 
            shall not: (a) attempt to reverse engineer either the AVANT ADVANCE System or any component thereof; 
            (b) attempt to create a substitute or similar service through the use of, or access to the AVANT 
            ADVANCE System; or (c) remove, alter, or cover any copyright notices or other proprietary rights 
            notices placed or embedded in the AVANT ADVANCE System.`,
            `6. Password Security.  You are entirely responsible for maintaining the confidentiality of your 
            User account information, including your password and account identifier.  Furthermore, you are 
            entirely responsible for any and all activities that occur under your User account.  If you become 
            aware of any unauthorized use of your User account or any other breach of security, you agree to 
            notify AVANT immediately.  AVANT will not be liable for any loss that you may incur as a result of 
            someone else using your password or User account, either with or without your knowledge.  If AVANT 
            or a third party incurs losses due to someone else using your User account or password, you could 
            be held liable for those losses.`,
            `7. AVANT’s Right to Use User Account Data.  You hereby grant to AVANT the non-exclusive, irrevocable, 
            worldwide, perpetual, royalty-free, fully paid, sublicensable and transferrable right and license to: 
            (a) use the information stored in and/or generated within, your User account in connection with providing 
            you access to the AVANT ADVANCE System and performing related services; (b) share such information with 
            any Customer Account with which your User account is associated; (c) retain, reproduce, modify, 
            distribute, use, and disclose test related data for legitimate business purposes, including without 
            limitation, new products and services, research, analytic, statistical, development, or quality control 
            purposes; and (d) use data contained in the Customer Account in any manner consistent with AVANT’s 
            privacy policy (a copy of the privacy policy is available at www.avantassessment.com).`,
            `8. Ownership Rights.  All training programs, content, trademarks, service marks, designs, audio files, 
            videos, documentation and other information available to you through the AVANT ADVANCE System is the 
            property of AVANT and its licensors and is protected by copyright, trademark and other intellectual 
            property laws.  You shall not acquire any rights, title or interest to the AVANT ADVANCE System, content 
            contained therein or any other intellectual property rights by virtue of your use of the AVANT ADVANCE 
            System.`,
            `9. Limited Warranty; Liability Limitations.  EXCEPT AS OTHERWISE EXPRESSLY WARRANTED HEREIN, THE 
            AVANT ADVANCE SYSTEM (AND ALL CONTENT AND DATA THEREIN) ARE PROVIDED "AS IS," AND THIS LIMITED WARRANTY 
            IS IN LIEU OF ALL OTHER WARRANTIES OF AVANT AND AVANT DISCLAIMS ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, 
            INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS OR ADEQUACY FOR ANY 
            PARTICULAR PURPOSE OR USE, QUALITY OR PRODUCTIVENESS, OR CAPACITY, THAT THE OPERATION OF THE AVANT ADVANCE 
            SYSTEM WILL BE ERROR-FREE OR ACCESSIBLE AT ALL TIMES, OR THAT THE DATA WILL BE FREE FROM CORRUPTION OR 
            LOSS.  IN NO EVENT SHALL AVANT (NOR ITS DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS) BE LIABLE FOR INDIRECT, 
            SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES OF ANY KIND OR OTHER ECONOMIC LOSS ARISING FROM 
            OR RELATING TO THIS AGREEMENT OR THE SUBJECT MATTER HEREOF ARISING UNDER OR IN CONNECTION WITH THIS 
            AGREEMENT, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, SHALL BE LIMITED TO AN AMOUNT 
            EQUAL TO THE GREATER OF $50.00 OR THE TOTAL FEES YOU HAVE PAID TO AVANT FOR YOUR USE OF THE AVANT ADVANCE 
            SYSTEM.  THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION AND ELSEWHERE IN THIS AGREEMENT APPLY 
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE.`,
            `10. U.S. Government Restricted Rights.  If you are accessing the AVANT ADVANCE System as an employee 
            of or independent contractor for an agency or instrumentality of the United States, the AVANT ADVANCE 
            System and all related documentation are “Commercial Items,” as that term is defined at 48 CFR §2.101, 
            consisting of “commercial computer software” and “commercial computer software documentation,” as those 
            terms are used in 48 CFR §12.212 or 48 CFR §227.7202, as applicable. Consistent with 48 CFR §12.212 or 48 
            CFR §§227.7202-1–227.7202-4, as applicable, the commercial computer software and commercial computer 
            software documentation are licensed to United States government end users (a) only as commercial items 
            and (b) with only the same rights as are granted to all other end users pursuant to the terms and 
            conditions set forth in this Agreement. Unpublished rights are reserved under the copyright laws of 
            the United States.`,
            `11. Term; Termination.`,
            `11.1 Unless terminated as described below, this Agreement shall remain in effect for as long as you have 
            an active User account.  This Agreement may be terminated by AVANT for any reason and at any time upon 
            notice to you.  This Agreement may be terminated by you for any reason and at any time upon notice to 
            AVANT provided that any such termination shall not be effective until confirmation by AVANT of acceptance 
            of your notice of termination.`,
            `11.2 Upon termination, access to the AVANT ADVANCE System will be turned off by AVANT. Upon termination, 
            each party shall remain liable for any obligations under this Agreement that accrued prior to the 
            effective date of termination.  Upon termination, the following provisions shall remain in full force 
            and effect:  Sections 6. through 12.`,
            `12. Miscellaneous.  This Agreement shall be governed by the laws of the State of Oregon excluding its 
            conflict of law provisions.  This agreement sets forth the entire agreement and understanding between 
            you and AVANT regarding your use of the AVANT ADVANCE System.  In the event of a conflict between the 
            terms of this agreement and the Master Agreement between AVANT and Customer Account, the terms of such 
            Master Agreement shall be controlling.  There shall be no amendments or modifications to this agreement, 
            except by a written document provided to you by AVANT which is affirmatively consented to by you 
            (a document only viewable by you online whether as part of the AVANT ADVANCE System, as part of your 
            individual training program enrollment or re-enrollment process or whether otherwise sent electronically 
            to your User account is an acceptable form of writing).  If any provision of this agreement is found to 
            be invalid, void or otherwise unenforceable, that provision shall be deemed severable and shall not 
            affect the validity and enforceability of any remaining provisions.  Any delay by AVANT in enforcing 
            its rights under this agreement or any waiver as to a particular default or other matter shall not 
            constitute a waiver of AVANT’s rights to the future enforcement of its rights under this agreement. 
            You shall not assign this agreement or any rights or obligations hereunder, by operation of law or 
            otherwise, without prior written approval of AVANT, and any such attempted assignment shall be void.  
            Subject to the foregoing, this agreement shall be binding upon and inure to the benefits of the parties 
            hereto, their successors and permitted assigns.  The parties hereto specifically agree to contract with 
            each other via electronic transmissions and that email communications shall be the primary format for 
            all communications regarding this agreement.  Any notice to be provided to AVANT pursuant hereto shall 
            be delivered to contracts@avantassessment.com.  Any notice to be provided to you shall be by email to 
            the email address AVANT then currently has on file for you, postings within your User account or other 
            reasonable means.  Any such notice shall be considered received when actually sent to recipient’s 
            correct email address or User account, if applicable, by the sender.`,
            `Last Revised:  May, 2018.`
        ]
        return (
            <div className="advance-eula__container__body">
                <p className="advance-eula__container__body-title">{eulaTitle}</p>
                {eula.map((section: string, index: number) => {
                    return (
                        <p key={`eula-section-${index}`} className="advance-eula__container__body-text">
                            {section}
                        </p>
                    )
                })}
            </div>
        )
    }

    handleAgree = () => {
        const authUser = authStore.auth!!
        const loginId = authUser.loginId
        if (!loginId) {
            this.setState({
                error: SUPPORT_MESSAGE
            })
            throw Error("authUser.loginId is null")
        }
        ApiService.getEulaAgreeDate(loginId).then(() => {
            if (authUser.userType === "S" || authUser.userType === "A") {
                this.props.history.push("/advance-dashboard")
            } else if (authUser.userType === "D") {
                this.props.history.push("/advance-report")
            }
        })
    }

    handleDisagree = () => {
        HelperService.logout()
    }

    componentDidMount() {
        HelperService.enableTextSelection()
        loadingSpinnerStore.hideLoadingSpinner = true
    }

    render() {
        return (
            <div className="advance-eula">
                <div className="advance-eula__container">
                    {this.renderSummary()}
                    {this.renderEula()}
                    <div className="advance-eula__container__agree-button">
                        <Button
                            className="avant-button--primary advance-eula__container__button"
                            onClick={this.handleAgree}
                        >
                            I agree, continue to ADVANCE
                        </Button>
                    </div>
                    <div className="advance-eula__container__disagree-button">
                        <Button className="avant-button--link" onClick={this.handleDisagree}>
                            I do not agree. Log me out.
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

