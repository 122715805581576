import {Button, Grid, Paper, TextField} from "@material-ui/core"
import * as React from "react"
import {ChangeEvent, useState} from "react"
import DevToolsApi from "../../../services/DevToolsApi"

export const CreateHash: React.FC = () => {
    const [textToHash, setTestToHash]  = useState("")
    const [createdHash, setCreatedHash]  = useState("")

    const handleHashTextChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => setTestToHash(e.target.value)

    const handleCreateHash = () => {
        DevToolsApi.getHash(textToHash)
            .then(response => {
                if (response) { setCreatedHash(response) }
            })
    }

    return (
        <Grid container={true} spacing={3}>
            <Grid item={true} xs={6}>
                <TextField
                    value={textToHash}
                    id="create-hash"
                    label="Text to hash"
                    onChange={handleHashTextChange}
                    style={{width: "100%"}}
                />
            </Grid>
            <Grid item={true} xs={6}>
                <Button variant="contained" onClick={handleCreateHash}>Create Hash</Button>
            </Grid>
            <Grid item={true} xs={12}>
                <Paper elevation={3} style={{padding: "5px", margin: "5px"}}><label>Resulting Hash for "{textToHash}":</label> <p>{createdHash}</p></Paper>
            </Grid>
        </Grid>
    )

}
