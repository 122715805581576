import React from 'react'
import { createStyles, makeStyles, Theme } from "@material-ui/core"
import { AvantColors } from "../../../../../../styles/AvantColors"
import { OVERALL_DISPLAY_HEIGHT, SKILL_TRIANGLE_ORIGIN_X } from "./SkillTriangleUtils"
import { productStore } from '../../../../../common/products/ProductStore'
import { STAMP_CEFR_PRODUCT_ID } from '../../../../../../util/Constants'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backgroundLines: {
            // Grey Background lines
            stroke: AvantColors.REPORT_FONT_COLOR_GRAY_3,
            strokeWidth: 1
        },
        backgroundRect: {
            fill: "#FAFAFA",
            // fill: "#000000"
        },
        cefrBackgroundRect: {
            fill: "#FFFFFF",
        },
        imageText: {
            fontFamily: "sans-serif",
            fontSize: "24px",
            fill: AvantColors.REPORT_FONT_COLOR_GRAY_3,
        },
    }),
)

const TEXT_X_OFFSET = 10
const TEXT_Y_OFFSET = 30
const HEIGHT_OFFSET = 10
const NOV_STRIATION_TOP_BOARDER = (5 * (OVERALL_DISPLAY_HEIGHT) / 7) + 4.5
const NOV_TEXT_Y_LOCATION = (((OVERALL_DISPLAY_HEIGHT) - NOV_STRIATION_TOP_BOARDER) / 2) + NOV_STRIATION_TOP_BOARDER + HEIGHT_OFFSET + TEXT_Y_OFFSET
const INT_TEXT_Y_LOCATION = ((NOV_STRIATION_TOP_BOARDER) / 2) + HEIGHT_OFFSET + TEXT_Y_OFFSET
const noviceRectangleHeight = OVERALL_DISPLAY_HEIGHT - NOV_STRIATION_TOP_BOARDER + NOV_STRIATION_TOP_BOARDER
const isStampCEFR = productStore.loginProduct ? productStore.loginProduct.productId === STAMP_CEFR_PRODUCT_ID.value() : false
const cefrCLevelRectangleHeight = (OVERALL_DISPLAY_HEIGHT/6) + 2.5
const cefrALevelRectangleHeight = 2.75 * (OVERALL_DISPLAY_HEIGHT/6)

export const TwoStriationSkillTriangleBackground: React.FC = () => {

    const classes = useStyles()

    return (
        <>
            { isStampCEFR &&
                <rect
                    id={"advanced-range-striation-box"}
                    x={SKILL_TRIANGLE_ORIGIN_X}
                    y={55}
                    width={"100%"}
                    height={cefrCLevelRectangleHeight}
                    //className={classes.backgroundRect}
                    // NOTE: I was not sure why this element was included here. Instead of removing it completely, I
                    // am rendering this shape with a white background so that is not visible. We can revert my change
                    // in the future if we need by un-commenting the className above and by removing the one below.
                    className={classes.cefrBackgroundRect}
                />
            }
            <rect
                id={"novice-range-striation-box"}
                x={SKILL_TRIANGLE_ORIGIN_X}
                y={isStampCEFR ? 230 : NOV_STRIATION_TOP_BOARDER}
                width={"100%"}
                height={isStampCEFR ? cefrALevelRectangleHeight : noviceRectangleHeight}
                // We do not want CEFR reports to display the alternating gray and white bands that are displayed on
                // other reports. As such, I am removing the gray fill here if the product id is CEFR.
                className={productStore.loginProduct && productStore.loginProduct.productId === STAMP_CEFR_PRODUCT_ID.value() ?
                    classes.cefrBackgroundRect :
                    classes.backgroundRect
            }
            />
            <text
                id={"intermediate-level-range-text"}
                x={TEXT_X_OFFSET}
                y={INT_TEXT_Y_LOCATION}
                className={classes.imageText}
            >
                {productStore.loginProduct && productStore.loginProduct.productId === STAMP_CEFR_PRODUCT_ID.value() ? "" : "INT"}
            </text>
            <text
                id={"novice-level-range-text"}
                x={TEXT_X_OFFSET}
                y={NOV_TEXT_Y_LOCATION}
                className={classes.imageText}
            >
                {productStore.loginProduct && productStore.loginProduct.productId === STAMP_CEFR_PRODUCT_ID.value() ? "" : "NOV"}
            </text>
            {productStore.loginProduct && productStore.loginProduct.productId === STAMP_CEFR_PRODUCT_ID.value() ?
                (
                <> 
                <line
                    x1={SKILL_TRIANGLE_ORIGIN_X}
                    y1={"60"}
                    x2={"100%"}
                    y2={"60"}
                    className={classes.backgroundLines}
                />
                    <line
                        x1={SKILL_TRIANGLE_ORIGIN_X}
                        y1={"115"}
                        x2={"100%"}
                        y2={"115"}
                        className={classes.backgroundLines}
                    />
                    <line
                        x1={SKILL_TRIANGLE_ORIGIN_X}
                        y1={"175"}
                        x2={"100%"}
                        y2={"175"}
                        className={classes.backgroundLines}
                    />
                    <line
                        x1={SKILL_TRIANGLE_ORIGIN_X}
                        y1={"230"}
                        x2={"100%"}
                        y2={"230"}
                        className={classes.backgroundLines}
                    />
                    <line
                        x1={SKILL_TRIANGLE_ORIGIN_X}
                        y1={"285"}
                        x2={"100%"}
                        y2={"285"}
                        className={classes.backgroundLines}
                    />
                    <line
                        x1={SKILL_TRIANGLE_ORIGIN_X}
                        y1={"340"}
                        x2={"100%"}
                        y2={"340"}
                        className={classes.backgroundLines}
                    />
                    <line
                        x1={SKILL_TRIANGLE_ORIGIN_X}
                        y1={"393"}
                        x2={"100%"}
                        y2={"393"}
                        className={classes.backgroundLines}
                    />
                    </>
                    ) : (
                    <>
                    <line
                        x1={SKILL_TRIANGLE_ORIGIN_X}
                        y1={"55"}
                        x2={"100%"}
                        y2={"55"}
                        className={classes.backgroundLines}
                    />
                        <line
                            id={"novice-level-striation-top-border"}
                            x1={SKILL_TRIANGLE_ORIGIN_X}
                            y1={NOV_STRIATION_TOP_BOARDER}
                            x2={"100%"}
                            y2={NOV_STRIATION_TOP_BOARDER}
                            className={classes.backgroundLines}
                        />
                        <line
                            x1={SKILL_TRIANGLE_ORIGIN_X}
                            y1={"405"}
                            x2={"100%"}
                            y2={"405"}
                            className={classes.backgroundLines}
                        /> 
                        </>
                )}
        </>
    )


}
