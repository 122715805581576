import moment, {Moment} from "moment"
import {log} from "./Logging"

export class TimeUtil {

    private static readonly US_DATE_FORMAT = "MM/DD/YYYY"

    static enoughTimeHasPassed = (curTime: Date, startTime: Date, millisecondsToPass: number) => {
        const timestampTillPassed = startTime.getTime() + millisecondsToPass
        return curTime.getTime() > timestampTillPassed
    }

    static formatMilliToMonthDayYearLong = (timeInMilli: number) => {
        return moment(timeInMilli).format("MMMM DD, YYYY")
    }

    static convertMilliMonthDayYearHourMinuteToMilliMonthDayYear = (timeInMilli: number) => {
        const fullDateTime = moment(timeInMilli).format(TimeUtil.US_DATE_FORMAT)
        return moment(fullDateTime)
    }

    static formatMilliToMonthDayYearHoursMinutesLong = (timeInMilli?: number) => {
        if (timeInMilli == null) {
            return ""
        }
        return moment(timeInMilli).format("MM/DD/YYYY hh:mmA")
    }

    // Moment duration format library looks lame, so manually instead
    static deltaTimeInHoursMins = (startTimeInMilli?: number, endTimeInMilli?: number) => {
        if (TimeUtil.checkForValidStartAndEnd(startTimeInMilli, endTimeInMilli)) {
            const start = moment(startTimeInMilli)
            const end = moment(endTimeInMilli)
            const timeDiff = end.diff(start)
            const duration = moment.duration(timeDiff)
            const hours = Math.trunc(duration.asHours())
            let minutes = duration.minutes().toString()
            if (minutes.length < 2 && hours === 0) {
                minutes = "0" + minutes
            }
            if (hours === 0) {
                return `${minutes} ${minutes === "1" ? "minute" : "minutes"}`
            }
            return `${hours}:${minutes} hour${hours === 1 ? "" : "s"}`
        } else {
            return ""
        }

    }

    static deltaTimeInMinutesOrEmptyStr = (startTimeInMilli?: number, endTimeInMilli?: number, maxMinsToDisplay?: number) => {
        if (TimeUtil.checkForValidStartAndEnd(startTimeInMilli, endTimeInMilli)) {
            const start = moment(startTimeInMilli)
            const end = moment(endTimeInMilli)
            const delta = Math.ceil(moment.duration(end.diff(start)).asMinutes())
            if (maxMinsToDisplay != null) {
                return delta > maxMinsToDisplay ? maxMinsToDisplay.toString() + "+ minutes" : delta.toString() + " minutes"
            } else {
                return delta.toString() + " minutes"
            }

        } else {
            return ""
        }

    }

    private static checkForValidStartAndEnd = (startTimeInMilli?: number, endTimeInMilli?: number) => {
        if (startTimeInMilli == null || endTimeInMilli == null) {
            return false
        }
        if (startTimeInMilli < 1 || endTimeInMilli < 1 || (startTimeInMilli > endTimeInMilli)) {
            log.warn(`Unexpected time sent to delta time: start: ${startTimeInMilli} end: ${endTimeInMilli}`)
            return false
        }
        return true
    }

    static formatToISODate = (date: Moment) => date.format("YYYY-MM-DD")

    static formatMillisToUsDate = (millis?: number) => {
        if (millis == null) {
            return ""
        }
        return moment(millis).format(TimeUtil.US_DATE_FORMAT)
    }
    static formatToUsDate = (date: Moment) => date.format(TimeUtil.US_DATE_FORMAT)

    static usDateStringToMoment = (date: string) => moment(date, TimeUtil.US_DATE_FORMAT)

    static usDateToUtcDate = (date: Date) => {
        return new Date (Date.UTC( date.getFullYear(), date.getMonth(), date.getDate() ))
    }
}

export interface AvantDateRange {
    startDate: Moment
    endDate: Moment
}
