/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import * as Sentry from "@sentry/browser"
import {observer} from "mobx-react"
import React from "react"
import {Alert, Col, Modal, Row} from "react-bootstrap"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"
import {editableTrainingContentStore} from "../app/advance/training-content/EditableTrainingContentStore"
import {authStore} from "../app/common/authentication/AuthStore"
import {UserType} from "../app/common/authentication/models/UserType"
import Item from "../app/common/item/Item"
import {itemStore} from "../app/common/item/ItemStore"
import {ItemContent} from "../app/common/item/models/ItemContent"
import {loadingSpinnerStore} from "../app/common/loaders/LoadingSpinnerStore"
import {productStore} from "../app/common/products/ProductStore"
import {RoutePaths} from "../app/routes/Routes"
import {Section} from "../app/section/models/Section"
import {sectionStore} from "../app/section/SectionStore"
import {PanelOutOfTimeError} from "../errors/PanelOutOfTimeError"
import {
    stopTest,
    updateCorrectAnswer,
    updateItemCorrectAnswer,
    updateItemInstructions,
    updateRecordingWarningSeen,
    updateResponses,
    updateSection,
    updateTestDuration,
    updateTimeRemaining
} from "../redux/item/actions"
import ApiService from "../services/ApiService"
import HelperService from "../services/HelperService"
import {
    Answer,
    AnswerResponse,
    ApiErrorResponse,
    ApiPanelGraph,
    ApiResponsePayload,
    ApiUpdateTimer,
    CbAnswer,
    IItem,
    ItemFormatEnum,
    LastPage,
    LoginProductContentAreaData,
    McAnswer,
    NavButtonOrderEnum,
    NavDirectionEnum,
    PsAnswer,
    RecordWarningEnum,
    RefreshTokenData,
    State,
    Take,
    TestEngineOptions,
    TLocalAnswer,
    TransitionOutEnum,
    TSeconds,
    VideoContent
} from "../types/types"
import {ADMIN_PRODUCT_ID, ADVANCE_PRODUCT_ID, SUPPORT_MESSAGE, WEBRTC_RESPONSE} from "../util/Constants"
import {elvis, to} from "../util/elvis"
import {log, prettyJson} from "../util/Logging"
import {ItemContentId, ItemId, PanelId, TakeId} from "../validation/ValidPrimaryKey"
import {addTakeDispatches, TakeDispatches} from "./App/App"
import Button from "./Button/Button"
import {observable, when} from "mobx"
import {Dialog, DialogActions, DialogContent, Grid} from "@material-ui/core"
import {messageStore} from "../app/common/messages/MessageStore"
import {APP_CONFIG} from "../services/ConfigurationService"
import {TestTakerPermissions} from "./admin-portal/DevTools/LoginTool"

// Opus recorder
declare const recorder: any
const ON_BLUR_KEY = "onBlurCount"

interface StateToProps {
    section: Section | null
    stopTest: boolean
    logoutInTest: boolean
    take: Take | null
    panelGraph: ApiPanelGraph
    testDuration: number
    recordingWarningSeen: RecordWarningEnum
}

const mapStateToProps = (state: State): StateToProps => {
    return {
        section: state.item.currentSection,
        stopTest: state.item.stopTest,
        logoutInTest: state.item.logoutInTest,
        take: state.app.take,
        panelGraph: state.app.panelGraph!,
        testDuration: state.item.testDuration,
        recordingWarningSeen: state.item.recordingWarningSeen
    }
}

interface DispatchToProps extends TakeDispatches {
    dispatchAnswer: (answer: Answer) => void
    updateCurrentCorrectAnswerDispatch: (correctAnswerContent: ItemContent, itemId: number) => void
    updateSectionDispatch: (bin: Section) => void
    updateLastItemIndexDispatch: (itemIndex: number) => void
    updateTimeRemainingDispatch: (timeRemaining: TSeconds | null) => void
    stopTestDispatch: (stopTest: boolean) => void
    updateItemInstructionsDispatch: (itemIndex: number, itemContentId: number, content: string) => void
    updateItemCorrectAnswerDispatch: (
        itemIndex: number,
        newCorrectAnswer: ItemContent,
        oldCorrectAnswer: ItemContent
    ) => void
    updateTestDurationDispatch: (duration: number) => void
    updateRecordingWarningSeenDispatch: (state: RecordWarningEnum) => void
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchToProps => {
    const dispatches = {
        dispatchAnswer: (mcAnswer: McAnswer) => {
            dispatch(updateResponses(mcAnswer))
        },
        updateCurrentCorrectAnswerDispatch: (correctAnswerContent: ItemContent, itemId: number) => {
            dispatch(updateCorrectAnswer(correctAnswerContent, itemId))
        },
        updateSectionDispatch: (section: Section) => {
            dispatch(updateSection(section))
        },
        updateRecordingWarningSeenDispatch: (recordingWarningSeen: RecordWarningEnum) => {
            dispatch(updateRecordingWarningSeen(recordingWarningSeen))
        },
        updateTimeRemainingDispatch: (timeRemaining: TSeconds) => {
            dispatch(updateTimeRemaining(timeRemaining))
        },
        stopTestDispatch: (stop: boolean) => {
            dispatch(stopTest(stop))
        },
        updateItemInstructionsDispatch: (itemIndex: number, itemContentId: number, content: string) => {
            dispatch(updateItemInstructions(itemIndex, itemContentId, content))
        },
        updateItemCorrectAnswerDispatch: (
            itemIndex: number,
            newCorrectAnswer: ItemContent,
            oldCorrectAnswer: ItemContent
        ) => {
            dispatch(updateItemCorrectAnswer(itemIndex, newCorrectAnswer, oldCorrectAnswer))
        },
        updateTestDurationDispatch: (duration: number) => {
            dispatch(updateTestDuration(duration))
        }
    }
    return addTakeDispatches(dispatches, dispatch)
}

interface ItemContainerProps extends StateToProps, DispatchToProps, RouteComponentProps {
}

interface ItemContainerState {
    error: boolean | string
    item: IItem | null
    itemIndex: number
    localAnswer: TLocalAnswer | null
    prevItemIndex?: number
    prevAnswer: Answer | null
    correctAnswer: ItemContent | null
    outOfTime: boolean
    transitionOut: TransitionOutEnum | null
    transitioning: boolean
    transitionDirection: null | NavDirectionEnum
    showCbModal: boolean
    showAnsweredModal: boolean
    showOnBlurModal: boolean
    loggingOut: boolean
    autoSaving: boolean
    showChoices: boolean
    advanceAnswerSubmitted: boolean
    path?: string
    dataLoaded: boolean
}

const initialState = {
    error: false,
    item: null,
    itemIndex: -1,
    localAnswer: null,
    prevAnswer: null,
    correctAnswer: null,
    outOfTime: false,
    transitioning: false,
    transitionOut: null,
    transitionDirection: null,
    showCbModal: false,
    showAnsweredModal: false,
    showOnBlurModal: false,
    loggingOut: false,
    autoSaving: false,
    showChoices: true,
    advanceAnswerSubmitted: false,
    dataLoaded: false
}

class ItemContainerStore {
    @observable
    shouldSubmitAnswer: boolean = false

    submitAnswer = (submit: boolean) => {
        this.shouldSubmitAnswer = submit
    }
}

export const itemContainerStore = new ItemContainerStore()

/**
 * @classdesc The Container component for an Item. This handles dispatching actions.
 */
@observer
class ItemContainer extends React.Component<ItemContainerProps, ItemContainerState> {
    itemInstance: Item | null = null
    pageTransitionDt = 300 // in milliseconds, this needs to match $item-transition-dt in shared.scss
    inWrCoolDown: boolean = false

    constructor(props: ItemContainerProps) {
        super(props)
        this.state = initialState
    }

    unlisten: () => void = () => {
        return
    }

    componentDidMount() {
        const {
            history,
            section,
            take,
            panelGraph,
            match,
            updateTimeRemainingDispatch
        } = this.props
        const authUser = authStore.auth

        if (authUser && authUser.userPermissions && authUser.userPermissions.includes(TestTakerPermissions.TRACK)) {
            // Add the onBlur listener; this will fire if the test taker clicks out of the window or switches to a different tab
            window.addEventListener("blur", this.onBlur)

            // Set the count in local storage if it isn't already there
            if (localStorage.getItem(ON_BLUR_KEY) === null) {
                localStorage.setItem(ON_BLUR_KEY, '0')
            }
        }

        const product = productStore.loginProduct
        // Prevent back button
        this.unlisten = history.listen((location, action) => {
            const {itemIndex, path} = this.state
            const disableBack = section && section.config.disableBack
            if (
                (itemIndex === 0 || disableBack) &&
                path !== undefined &&
                location.pathname !== path &&
                action === "POP"
            ) {
                history.push(path)
            }
        })

        // Prevent manual changes to the URL
        const storedPath = localStorage.getItem("path")
        const locationPath = window.location.pathname
        if (storedPath !== null && locationPath !== "/item" && storedPath !== locationPath) {
            history.push(storedPath)
        }

        HelperService.preventTextSelection()

        if (!section || !take) {
            this.getData()
        } else {
            if (take.takeCode === null) {
                throw Error("[ItemContainer.componentDidMount] this.props.take.takeCode === null")
            }
            if (panelGraph === null) {
                throw Error("[ItemContainer.componentDidMount] this.props.panelGraph === null")
            }

            this.setupSentry(take, panelGraph, product!!)

            // Already have a take, so just act normal
            // This is the case where you enter the ItemContainer for the first time or you come back in
            // from another container, i.e. finish-section

            // Set the itemIndex from the URL, if is not present use the section.lastItemIndex
            let newItemIndex = this.getItemIndexFromUrl(match.params)
            if (newItemIndex === undefined) {
                newItemIndex = this.nextItemIndex(section.lastItemIndex, section.items)
            }
            this.goToItem(newItemIndex, undefined, undefined, false)

            if (section.config.showTimer === true) {
                ApiService.updateTimer(new TakeId(section.takeId), new PanelId(section.panelId)).then(
                    (res: ApiUpdateTimer) => {
                        updateTimeRemainingDispatch(res.timeRemaining)
                    },
                    () => {
                        const msg = `Sorry, an error occurred. Please contact 
                        ${productStore.driver!!.config.SUPPORT_EMAIL}.`
                        this.setState({error: msg})
                        throw Error(`[ItemContainer.componentDidMount] ${msg}`)
                    }
                )
            }

            const lastPage: LastPage = {
                url: "/item",
                takeId: section.takeId,
                panelId: section.panelId,
                takeCode: take.takeCode,
                sectionConfig: section.config,
                lastItemIndex: this.state.itemIndex - 1,
                panelGraphId: panelGraph.id
            }
            this.setState({error: false})
            localStorage.setItem("lastPage", JSON.stringify(lastPage))
        }
        if (this.props.section && this.props.section.config.storeTestDuration) {
            productStore.driver!!.itemContainerHelper.startTimeOnItemTimer(this.props.section)
        }
    }

    componentDidUpdate() {
        const {section, take, match, logoutInTest} = this.props
        if (section && section.items.length > 0) {
            const itemIndex = this.getItemIndexFromUrl(match.params)
            if (itemIndex === undefined) {
                return
            }
            if (itemIndex !== this.state.itemIndex) {
                this.loadItem(section, itemIndex)
            }
        }

        if (this.props.stopTest) {
            this.stopTest()
            this.props.stopTestDispatch(false)
        }

        if (logoutInTest && !this.state.loggingOut) {
            this.setState({loggingOut: true})
            this.logout()
        }

        if (productStore.driver) {
            document.title = `${productStore.driver!!.config.PRODUCT_TITLE} | Test`
        }

        if (!section || !take) {
            this.getData()
        }

        // This is for the TokenExpireModalContainer to submit answers when the modal appears.
        // using when() because of: https://stackoverflow.com/a/46616548
        when(
            () => itemContainerStore.shouldSubmitAnswer && this.state.localAnswer !== null,
            () => {
                this.submitAnswer(false, true).then(() => {})
            }
        )
    }

    onBlur = async () => {
        // Make sure that the warning modal isn't already open
        if (!this.state.showOnBlurModal) {
            try {
                // Track the test takers count in LocalStorage
                let onBlurCount = Number(localStorage.getItem(ON_BLUR_KEY))
                onBlurCount++

                // Log this in the database
                const { section, take } = this.props

                if (section && take) {
                    await ApiService.eventLogOnBlur(take.id, section.takePanelId)
                }

                if (onBlurCount >= APP_CONFIG.CLICK_OUT_OF_TEST_MAX) {
                    if (this.state.localAnswer) {
                        // Check if the user selected an answer and submit it to database
                        await this.submitAnswer(false, true)
                    }

                    HelperService.simpleLogout(false)
                } else {
                    // Update the count in LocalStorage
                    localStorage.setItem(ON_BLUR_KEY, onBlurCount.toString())
                    this.setState({ showOnBlurModal: true })
                }
            } catch(error) {
                messageStore.setDefaultError()
            }
        }
    }

    getData = () => {
        const product = productStore.loginProduct
        const {history} = this.props
        const {dataLoaded} = this.state
        if (productStore.driver && !dataLoaded) {
            this.setState({dataLoaded: true})
            const lastPage: LastPage | null = HelperService.getLastPageFromLs()
            if (lastPage == null || lastPage.takeCode == null) {
                history.push(RoutePaths.LOGIN)
                return
            }
            const {sectionConfig, takeId, panelId, lastItemIndex} = lastPage
            if (sectionConfig == null || takeId == null || panelId == null || lastItemIndex == null) {
                throw new Error(`[ItemContainer.getData] Last page was missing required info: ${prettyJson(lastPage)}`)
            }

            const randomItemOrder: boolean = product ? product.productId === ADVANCE_PRODUCT_ID.value() : false

            // ****   This is the REFRESH PAGE logic. *******
            productStore.driver!!.helper
                .refreshTest(this.props, lastPage, product!!)
                .then(
                    () => {
                        if (sectionConfig.showTimer) {
                            return ApiService.updateTimer(new TakeId(takeId), new PanelId(panelId)).then(
                                (res: ApiUpdateTimer) => {
                                    if (res.timeRemaining <= 0) {
                                        if (sectionConfig.autoContinueToNextSection) {
                                            throw Error("[ItemContainer.getData] You have a timer and autoNextSection is defined, not implemented!")
                                        }
                                        // Has to throw and error to end the promise chain
                                        if (sectionConfig.finishSectionUrl == null) {
                                            throw new Error("[ItemContainer.getData] Nowhere to redirect to on timeout!")
                                        }
                                        throw new PanelOutOfTimeError(sectionConfig.finishSectionUrl)
                                    } else {
                                        return sectionStore.findCurrentSectionWithLastItemIndex(
                                            new TakeId(takeId),
                                            new PanelId(panelId),
                                            lastItemIndex,
                                            randomItemOrder
                                        )
                                    }
                                },
                                () => {
                                    this.setState({
                                        error: SUPPORT_MESSAGE
                                    })
                                    throw Error("[ItemContainer.getData] Update timer failed on page re-fresh")
                                }
                            )
                        } else {
                            return sectionStore.findCurrentSectionWithLastItemIndex(
                                new TakeId(takeId),
                                new PanelId(panelId),
                                lastItemIndex,
                                randomItemOrder
                            )
                        }
                    },
                    () => {
                        this.setState({
                            error: SUPPORT_MESSAGE
                        })
                        throw Error("[ItemContainer.getData] refreshTest failed")
                    }
                )
                .then(
                    (newSection: Section) => {
                        newSection.config = sectionConfig
                        this.props.updateSectionDispatch(newSection)
                        let newItemIndex: number | undefined = this.getItemIndexFromUrl(this.props.match.params)
                        if (newItemIndex === undefined) {
                            newItemIndex = this.nextItemIndex(newSection.lastItemIndex, newSection.items)
                        }
                        if (this.props.take === null) {
                            throw Error("this.props.take === null")
                        }
                        if (this.props.panelGraph === null) {
                            throw Error("this.props.panelGraph === null")
                        }
                        this.setupSentry(this.props.take, this.props.panelGraph, productStore.loginProduct!!)
                        this.goToItem(newItemIndex, undefined, undefined, false)
                    },
                    (err: ApiErrorResponse) => {
                        this.setState({
                            error: SUPPORT_MESSAGE
                        })
                        if (err.response && err.response.data.message.search("Time has run out") > -1) {
                            // Need to check to see if we passed or failed.
                            this.props.history.push(sectionConfig.finishSectionUrl!)
                            throw Error("[ItemContainer.getData] Panel time has run out.")
                        } else {
                            throw Error("[ItemContainer.getData] Unknown server error on current section.")
                        }
                    }
                )
        }
    }

    componentWillUnmount() {
        this.unlisten()
        localStorage.removeItem("path")
        window.removeEventListener("blur", this.onBlur)
    }

    setupSentry = (take: Take, panelGraph: ApiPanelGraph, loginProduct: LoginProductContentAreaData) => {
        Sentry.configureScope((scope: Sentry.Scope) => {
            if (take) {
                scope.setUser({
                    id: take.id.toString(),
                    username: take.takeCode
                })
            }

            scope.setTag("panelGraphId", panelGraph.id.toString())
            scope.setTag("panelGraphName", panelGraph.name)
            scope.setTag("product", loginProduct.productName)
            scope.setTag("language", loginProduct.contentAreaName)
            scope.setTag("loginId", loginProduct.loginId.toString())
        })
    }

    handleEditCorrectAnswer = (newCorrectAnswer: ItemContent, oldCorrectAnswer: ItemContent): Promise<void> => {
        const item: IItem = to<IItem>(this.state.item, new Error("Item is null"))
        return new Promise((resolve, reject) => {
            ApiService.updateCorrectAnswer(new ItemId(item.id), new ItemContentId(newCorrectAnswer.id)).then(
                () => {
                    this.props.updateItemCorrectAnswerDispatch(this.state.itemIndex, newCorrectAnswer, oldCorrectAnswer)
                    resolve()
                },
                (error: ApiErrorResponse) => {
                    this.setState({
                        error: SUPPORT_MESSAGE
                    })
                    throw new Error(`[ItemContainer.handleEditCorrectAnswer] Failed to edit item content. ${error}`)
                    reject()
                }
            )
        })
    }

    handleEditItemContent = (itemContentId: number, content: string, itemId?: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            ApiService.updateItemContent(new ItemContentId(itemContentId), content).then(
                () => {
                    this.props.updateItemInstructionsDispatch(this.state.itemIndex, itemContentId, content)
                    if (itemId) {
                        itemStore.getItemAuditLog(itemId)
                    }
                    resolve()
                },
                (error: ApiErrorResponse) => {
                    this.setState({
                        error: `Could not save changes. Please contact ${productStore.driver!!.config.SUPPORT_EMAIL}.`
                    })
                    throw new Error(`[ItemContainer.handleEditItemContent] Failed to edit item content. ${error}`)
                    reject()
                }
            )
        })
    }

    handleUpdateItemFile = (itemContentId: number, content: string | VideoContent) => {
        let updatedContent = content as string
        if (typeof content !== "string") {
            updatedContent = JSON.stringify(content)
        }
        this.props.updateItemInstructionsDispatch(this.state.itemIndex, itemContentId, updatedContent)
    }

    setRecordingWarningSeen = (state: RecordWarningEnum) => {
        this.props.updateRecordingWarningSeenDispatch(state)
    }

    resetState = () => {
        this.setState(initialState)
    }

    logout = () => {
        const adminOrAdvance: boolean =
            productStore.driver!!.productId.value() === (ADVANCE_PRODUCT_ID.value() || ADMIN_PRODUCT_ID.value())
        if (this.state.localAnswer !== null) {
            this.submitAnswer(false).then(() => {
                HelperService.simpleLogout(adminOrAdvance)
            })
        } else {
            HelperService.simpleLogout(adminOrAdvance)
        }
    }

    updateTimerAndContinueTest = () => {
        const section = this.props.section
        if (section) {
            ApiService.updateTimer(new TakeId(section.takeId), new PanelId(section.panelId)).then(
                (res: ApiUpdateTimer) => {
                    this.props.updateTimeRemainingDispatch(res.timeRemaining)
                    this.props.history.push("/continue-test")
                },
                () => {
                    throw Error("[ItemContainer.updateTimerAndContinueTest] stopTest, update-timer return an error. Could not stop test.")
                }
            )
        }
    }

    stopTest = () => {
        if (this.state.localAnswer !== null) {
            this.submitAnswer(false).then(() => {
                this.updateTimerAndContinueTest()
            })
        } else {
            this.updateTimerAndContinueTest()
        }
    }

    // This get called from the child Item components to update the container state.
    handleLocalAnswerChange = (localAnswer: TLocalAnswer) => {
        const item = this.state.item
        if (item == null) {
            throw new Error("[ItemContainer.handleLocalAnswerChange] item not found on state.")
        }

        // Try to send new OL, WR, or Video Recording answer to the server
        if ((item.format === ItemFormatEnum.SPEAKING_ORAL || item.format === ItemFormatEnum.VIDEO_RECORDING) && localAnswer != null) {
            const isNativeOggOrVideoRecording = localAnswer instanceof Blob
            const isFlashRecording = localAnswer.toString().endsWith(".flv")


            if (isNativeOggOrVideoRecording || isFlashRecording) {
                this.setState({localAnswer})
                return
            }

            const isTwilioRecording = localAnswer.toString().startsWith(WEBRTC_RESPONSE)
            if (isTwilioRecording) {
                this.setState({localAnswer})
                this.setState({autoSaving: true})
                const disableSpinner = true
                const completed = false
                this.submitAnswer(completed, disableSpinner).then(
                    () => {
                        this.setState({autoSaving: false})
                    },
                    () => {
                        log.warn("Failed to autoSave OL response")
                    }
                )
            }
        } else if (item.format === ItemFormatEnum.WRITING && !this.inWrCoolDown && this.state.localAnswer !== null) {
            if (this.state.localAnswer !== localAnswer) {
                this.inWrCoolDown = true
                setTimeout(() => {
                    this.inWrCoolDown = false
                }, productStore.driver!!.config.WR_SAVE_COOLDOWN * 1000)

                this.setState({autoSaving: true})
                const disableSpinner = true
                const completed = false
                this.submitAnswer(completed, disableSpinner).then(
                    () => {
                        this.setState({autoSaving: false})
                    },
                    () => {
                        log.warn("Failed to autoSave WR response")
                    }
                )
            }
        }

        // Update state
        this.setState({localAnswer})
    }

    isValidAnswer = (): boolean => {
        let disableRequiredAnswers =
            productStore.driver!!.config.TEST_ENGINE_CONFIG.disableRequiredAnswers
                ? productStore.driver!!.config.TEST_ENGINE_CONFIG.disableRequiredAnswers
                : false
        disableRequiredAnswers = (disableRequiredAnswers) ? true : false
        let isValidCbAnswer: boolean = true

        if (
            this.state.item &&
            this.state.item.format === ItemFormatEnum.CHECKBOX &&
            this.state.localAnswer &&
            this.state.localAnswer instanceof Array
        ) {
            isValidCbAnswer = productStore.driver!!.itemContainerHelper.isValidCbAnswer(
                this.state.localAnswer,
                this.state.item,
                disableRequiredAnswers
            )
            if (!isValidCbAnswer) {
                this.setState({showCbModal: true})
            }
        }

        let itemFormat = ""
        if (this.state.item) {
            itemFormat = this.state.item.format
        }
        const isAnswered: boolean =
            productStore.driver!!.itemContainerHelper.isAnswered(this.state.localAnswer, disableRequiredAnswers) ||
            itemFormat === "BL"
        if (!isAnswered) {
            this.setState({showAnsweredModal: true})
        }

        return isValidCbAnswer && isAnswered
    }

    /**
     * Gets the next section, dispatches, and navigates back to item or returns to the dashboard if there are no items.
     *
     * @param {number} takeId is the current take id
     * @param {number} panelId is the current panel id
     * @param {string} testName is the test's name
     */
    openNextSection = (takeId: TakeId, panelId: PanelId, testName: string) => {
        if (this.props.section && this.props.section.config.storeTestDuration) {
            this.props.updateTestDurationDispatch(0)
        }

        ApiService.finishSection(takeId, panelId).then(
            () => {
                sectionStore.findCurrentSection(takeId, panelId).then(
                    (section: Section) => {
                        section.config = productStore.driver!!.config.TEST_ENGINE_CONFIG
                        section.config.testName = testName
                        this.props.updateSectionDispatch(section)

                        if (section.items.length > 0) {
                            this.resetState()
                            if (this.props.section === null) {
                                throw new Error("[ItemContainer.openNextSection] this.props.section is null")
                            }
                            this.setState({item: this.props.section.items[0]})
                            this.saveTrainingContentIfAdmin()
                            this.goToItem(0)
                        } else {
                            this.props.history.push(this.props.section!.config!.finishSectionUrl!)
                        }
                    },
                    (error: ApiErrorResponse) => {
                        this.setState({
                            error: `Sorry, an error occurred. Please contact ${productStore.driver!!.config.SUPPORT_EMAIL}.`
                        })
                        throw new Error(`[ItemContainer.openNextSection] Failed to get current section. ${error}`)
                    }
                )
            },
            (error: ApiErrorResponse) => {
                this.setState({
                    error: `Sorry, an error occurred. Please contact ${productStore.driver!!.config.SUPPORT_EMAIL}.`
                })
                throw new Error(`[ItemContainer.openNextSection] Failed to finish section. ${error}`)
            }
        )
    }

    /**
     * Finishes the current section by either navigating to the provided finish section url or automatically continuing
     * to the next section.
     */
    finishSection = (section: Section) => {
        const config = elvis<TestEngineOptions>(
            section.config,
            new Error("[ItemContainer.finishSection]: Section config was undefined or null when it shouldnt be")
        )

        if (config.autoContinueToNextSection === undefined) {
            const finishSectionUrl = elvis<string>(
                config.finishSectionUrl,
                new Error("[ItemContainer.finishSection]:Tried to push a null finishSectionUrl on history")
            )

            this.props.history.push(finishSectionUrl)
        } else {
            const takeId: TakeId = new TakeId(section.takeId)
            const panelId: PanelId = new PanelId(section.panelId)
            const testName: string = elvis<string>(
                section.config.testName,
                new Error(
                    "[ItemContainer.finishSection]:Expected to have a testName " +
                    " in section.config.testName but did not"
                )
            )
            this.openNextSection(takeId, panelId, testName)
        }
    }

    saveTrainingContentIfAdmin = () => {
        // Only save if you're and admin and you actually clicked something
        if (authStore.userType === UserType.A) {
            editableTrainingContentStore.save()
        }
    }

    /**
     * Called by the item menu. Calls goToItem, which handles navigation and answer submission.
     *
     * @param {number} index is the items index
     */
    handleNavigationByIndex = (index: number) => {
        if (this.isValidAnswer()) {
            this.saveTrainingContentIfAdmin()
            this.goToItem(index)
        }
    }

    /**
     * Called be the next and back buttons.
     * Calls this.goToItem() to actually handle the navigation and submit the answer (if appropriate)
     *
     * @param {NavDirectionEnum} direction is the navigation direction
     */
    handleNavigation = (direction: NavDirectionEnum) => {
        const section = elvis<Section>(this.props.section, new Error("Handle navigation has a null section!"))
        const isAdvance: boolean = productStore.loginProduct!!.productId === ADVANCE_PRODUCT_ID.value()

        if (this.isValidAnswer()) {
            let nextItemIndex: number = -1
            if (this.state.transitioning) {
                return
            }
            if (direction === "next") {
                nextItemIndex = this.state.itemIndex + 1
                const itemCount = this.props.section!.items.length

                const lastItem = nextItemIndex >= itemCount
                const isBlankItem = this.props.section!.items[this.state.itemIndex].format === ItemFormatEnum.BLANK
                const isEnd = isAdvance
                    ? lastItem &&
                    // They are now viewing the training, so they can move on
                    (this.state.advanceAnswerSubmitted ||
                        // They are in a training module, so they can move on
                        isBlankItem ||
                        // If they are on the last item, but have not answered it, they can skip it
                        this.state.localAnswer === null)
                    : // Not advance, don't worry about anything other than being on the last item.
                    lastItem

                if (isEnd) {
                    const config = this.props.section!.config
                    // End of bin
                    if (config === undefined) {
                        return
                    } else {
                        const localAnswer = this.state.localAnswer
                        if (localAnswer !== null && !isAdvance) {
                            this.submitAnswer(true).then(() => {
                                this.finishSection(section)
                            })
                        } else if (localAnswer !== null && isAdvance && this.props.section!.config.isCertTest) {
                            this.submitAnswer(true).then(() => {
                                this.finishSection(section)
                            })
                        } else {
                            this.finishSection(section)
                        }
                        return
                    }
                }
            } else if (direction === "back") {
                nextItemIndex = this.state.itemIndex - 1
            }
            this.saveTrainingContentIfAdmin()
            this.goToItem(nextItemIndex!)
        }
    }

    /**
     * Triggers loadItem by changing the URL which triggers componentDidUpdate.
     * It should be used for button and menu navigation, not for initial item loading.
     *
     * It will respect the submitOnNavigate flag and submit and answer when appropriate.
     *
     * @param {number} index is the item's index
     * @param {NavDirectionEnum} direction is the navigation direction
     * @param {NavButtonOrderEnum} buttonOrder is the order in which the buttons are presented
     * @param forceSubmitOnNavigate
     */
    goToItem = (index: number, direction?: NavDirectionEnum, buttonOrder?: NavButtonOrderEnum, forceSubmitOnNavigate?: boolean) => {
        if (this.state.transitioning) {
            return
        }
        index = index < 0 ? 0 : index

        if (this.props.section && this.props.section.config.storeTestDuration) {
            productStore.driver!!.itemContainerHelper.restartTimeOnItemTimer(this.props.section)
        }

        // Send for a token refresh, this has an automatic cool down so it's safe to call it over and over again.
        const refreshTokenData: RefreshTokenData = {
            secondsTillExpiration: productStore.driver!!.config.AUTH_TOKEN_SECONDS_TILL_EXPIRE
        }
        authStore.refreshItemToken(refreshTokenData)

        // Determine if that answer should be submitted based on submitOnNavigate flag
        // const config = this.props.section!.config

        const isAdvance: boolean = productStore.loginProduct!!.productId === ADVANCE_PRODUCT_ID.value()
        const localAnswer = this.state.localAnswer
        const prevAnswer = this.state.prevAnswer
        const advanceAnswerSubmitted = this.state.advanceAnswerSubmitted
        const submitOnNavigate = to<boolean>(this.props.section!.config.submitOnNavigate, false)

        // @TODO Need to figure out a simpler way to express these conditionals. Not sure how though,
        // this currently looks as simple as it can be.
        if ((localAnswer !== null && !isAdvance) || (localAnswer !== null && isAdvance && submitOnNavigate)) {
            // && (config.submitOnNavigate === undefined || config.submitOnNavigate)) {


            // TODO: Do we need to have the submitAnswer logick baked into the item navigation logick? Feels like we
            // should separate at these out because it's caused a weird issue with Writing and Speaking items.

            const completed: boolean = (forceSubmitOnNavigate === undefined) ? submitOnNavigate : forceSubmitOnNavigate
            this.submitAnswer(completed).then(
                () => {
                    direction = direction ? direction : NavDirectionEnum.NEXT
                    buttonOrder = buttonOrder ? buttonOrder : NavButtonOrderEnum.LEFT_TO_RIGHT
                    // Set transition CSS
                    // this.setItemTransitionStateWithDirection(direction, buttonOrder)
                    this.setItemTransitionState(direction)
                    setTimeout(() => {
                        const path = `/item/${index + 1}`
                        this.props.history.push(path)
                        this.setState({
                            transitioning: false,
                            transitionOut: null,
                            advanceAnswerSubmitted: true,
                            path
                        })
                        localStorage.setItem("path", path)
                    }, this.pageTransitionDt)
                },
                () => {
                    this.setState({
                        error: `Sorry, an error occurred. Please contact ${productStore.driver!!.config.SUPPORT_EMAIL}.`
                    })
                }
            )
        } else if (localAnswer !== null && prevAnswer === null && isAdvance && !advanceAnswerSubmitted) {
            this.submitAnswer(true)
                .then(() => {
                    this.setState({advanceAnswerSubmitted: true})
                })
                .catch(() => {
                    this.setState({
                        error: `Sorry, an error occurred. Please contact ${productStore.driver!!.config.SUPPORT_EMAIL}.`
                    })
                })
        } else {
            // Not submitting answer, just change item
            direction = direction ? direction : NavDirectionEnum.NEXT
            buttonOrder = buttonOrder ? buttonOrder : NavButtonOrderEnum.LEFT_TO_RIGHT

            this.setItemTransitionState(direction)
            setTimeout(() => {
                const path = `/item/${index + 1}`
                this.props.history.push(path)
                this.setState({
                    transitioning: false,
                    transitionOut: null,
                    path
                })
                localStorage.setItem("path", path)
            }, this.pageTransitionDt)
        }
    }

    /**
     * You should not need to call this directly. Use goToItem() for navigation with transition or
     * use props.history.push(/item/{itemIndex + 1} if you don't want transitions.
     * @param {Section} section
     * @param {number} index
     */
    loadItem = (section: Section, index?: number) => {
        if (index === undefined) {
            if (section.lastItemIndex >= section.items.length - 1) {
                this.props.history.push(section!.config!.finishSectionUrl!)
                return
            } else {
                index = this.nextItemIndex(section.lastItemIndex, section.items)
            }
        }

        let item = null
        try {
            item = elvis<IItem>(section.items[index], new Error(`There is no item at index ${index}`))
        } catch (e) {
            log.warn(`Could not find item index ${index}. This could be the transition bug.`)
            return
        }

        if (item === null || item === undefined) {
            this.setState({
                error: `Sorry, an error occurred. Please contact ${productStore.driver!!.config.SUPPORT_EMAIL}.`
            })
            new Error("[ItemContainer.loadItem] Item missing.")
        }

        const prevAnswer: Answer | null = section.responses.get(`${item.id}${item.binName}`) || null
        const localAnswer: TLocalAnswer | null =
            prevAnswer && prevAnswer.binName === item.binName ? prevAnswer.answer : null
        let correctAnswer: ItemContent | null = null
        if (prevAnswer !== null && item.training !== undefined) {
            // Shows training content when this is not null in TrainingContent
            correctAnswer = to<ItemContent>(
                section.correctAnswers.get(`${item.id}${item.binName}`),
                new Error("[ItemContainer.loadItem] Correct answer is missing.")
            )
        }

        // If this is not null, then the user has submitted their answer
        // and seen the training portion of the content
        const advanceAnswerSubmitted = prevAnswer !== null

        if (!advanceAnswerSubmitted) {
            // Show the various levels to select from (ADVANCE ONLY)
            this.setState({showChoices: true})
        } else {
            // Show the training associated with that item.
            this.setState({showChoices: false})
        }

        if (this.itemInstance !== null) {
            // Reset the answer on the Item instance. This will trigger this.handleLocalAnswer change.
            this.inWrCoolDown = true
            if (prevAnswer === null) {
                this.handleLocalAnswerChange(null)
            } else {
                this.handleLocalAnswerChange(prevAnswer.answer)
            }
            this.inWrCoolDown = false
        }
        this.setState({
            item,
            prevAnswer,
            localAnswer,
            correctAnswer,
            itemIndex: index,
            prevItemIndex: this.state.itemIndex,
            advanceAnswerSubmitted
        })

        // Save the itemIndex to localStorage
        const lastPage = HelperService.getLastPageFromLs()
        if (lastPage !== null) { lastPage!.lastItemIndex = index - 1 }
        localStorage.setItem("lastPage", JSON.stringify(lastPage))
        window.scrollTo(0, 0)
    }

    // places
    submitAnswer = (completed: boolean, disableSpinner?: boolean): Promise<any> => {
        // Immediately set this back to false to avoid making too many calls in componentDidUpdate()
        itemContainerStore.submitAnswer(false)
        let localAnswer = this.state.localAnswer
        if (localAnswer === null) {
            throw Error("[ItemContainer.submitAnswer] handleSubmit received a null answer.")
        }

        if (this.state.item === null) {
            throw Error("[ItemContainer.submitAnswer] handleSubmit - no item on state.")
        }

        const itemFormat = this.state.item ? this.state.item.format : ""
        return new Promise((resolve, reject) => {
            // Check to see if the answer has changed if not don't submit, just return.
            // If the itemFormat is CB you should always submit because of a bug.
            // TODO fix this so we don't have to always submit CB's answers
            const prevAnswer = this.state.prevAnswer ? this.state.prevAnswer.answer : null
            if (itemFormat.toString() !== "CB" && prevAnswer === this.state.localAnswer && !completed) {
                // TODO: Validate
                resolve(prevAnswer)
                return
            }

            let answer: Answer = {
                answer: null,
                format: this.state.item!.format,
                itemId: this.state.item!.id,
                takeId: new TakeId(this.props.section!.takeId),
                panelId: new PanelId(this.props.section!.panelId),
                index: this.state.itemIndex,
                completed,
                requiredAnswerCount: this.state.item!.requiredAnswerCount,
                binName: this.state.item!.binName
            }

            let payload: ApiResponsePayload | null = null
            if (
                this.state.item!.format === ItemFormatEnum.MULTIPLE_CHOICE ||
                this.state.item!.format === ItemFormatEnum.PICTURE_CLICK ||
                this.state.item!.format === ItemFormatEnum.YES_NO
            ) {
                answer.answer = localAnswer as number
                payload = {
                    answer: localAnswer as number,
                    completed
                }
            } else if (this.state.item!.format === ItemFormatEnum.PASSAGE_SELECT) {
                localAnswer = localAnswer as string
                answer = answer as PsAnswer
                answer.answer = localAnswer
                payload = {
                    answer: localAnswer,
                    completed
                }
            } else if (this.state.item!.format === ItemFormatEnum.CHECKBOX) {
                localAnswer = localAnswer as string[]
                answer = answer as CbAnswer
                answer.answer = localAnswer
                // This is because the checkboxGroup requires the values to be an array of strings
                const itemContentIds = localAnswer.map((id: string) => {
                    return parseInt(id, 10)
                })
                payload = {answer: itemContentIds, completed}
            } else if (this.state.item!.format === ItemFormatEnum.ZONE_CLICK) {
                answer.answer = localAnswer as string
                payload = {
                    answer: localAnswer,
                    completed
                }
            } else if (this.state.item!.format === ItemFormatEnum.WRITING) {
                answer.answer = localAnswer as string
                payload = {
                    answer: localAnswer,
                    completed
                }
            } else if (this.state.item!.format === ItemFormatEnum.SPEAKING_ORAL) {
                answer.answer = localAnswer instanceof Blob ? (localAnswer as Blob) : (localAnswer as string)
                payload = {
                    answer: localAnswer,
                    completed
                }
            } else if (this.state.item!.format === ItemFormatEnum.VIDEO_RECORDING) {
                answer.answer = localAnswer instanceof Blob ? (localAnswer as Blob) : (localAnswer as string)
                payload = {
                    answer: localAnswer,
                    completed
                }
            } else if (this.state.item!.format === ItemFormatEnum.FILL_IN_THE_BLANK) {
                answer.answer = localAnswer as string
                payload = {
                    answer: localAnswer,
                    completed
                }
            } else if (this.state.item!.format === ItemFormatEnum.BLANK) {
                answer.answer = ""
                payload = {
                    answer: localAnswer,
                    completed
                }
            } else {
                throw Error(
                    `[ItemContainer.submitAnswer] item format ${this.state.item!.format} not implemented so no data is sent to the server`
                )
            }
            payload = to<ApiResponsePayload>(payload, new Error("answer payload was null"))
            if (this.state.item!.format === ItemFormatEnum.SPEAKING_ORAL && localAnswer instanceof Blob) {
                if (recorder) {
                    // We must stop() the recorder in order to navigate to the next item. Otherwise
                    // the recorder get's confused that's it's been told to start when the recorder is in a
                    // paused() state.
                    recorder.stop()
                } else {
                    throw new Error("[ItemContainer.submitAnswer] Opus recorder should be defined if the item is speaking and the answer is a Blob")
                }
                // We do not auto-save oral responses on logout, AVANT-1009.
                if (!completed) {
                    // TODO: Validate
                    resolve(completed)
                    return
                }
            }
            this.answerItem(answer, payload, resolve, reject, disableSpinner)
        })
    }

    answerItem = (
        answer: Answer,
        payload: ApiResponsePayload,
        resolve: any,
        reject: any,
        disableSpinner?: boolean
    ) => {
        ApiService.answerItem(answer, payload, disableSpinner).then(
            (response: AnswerResponse) => {
                this.props.updateTimeRemainingDispatch(response.timeRemaining)
                this.props.dispatchAnswer(answer)
                this.props.updateCurrentCorrectAnswerDispatch(response.itemContent, answer.itemId)
                const item: IItem = to<IItem>(this.state.item, new Error("Item is null"))
                if (item.training !== undefined) {
                    this.setState({correctAnswer: response.itemContent, showChoices: false})
                }
                resolve()
            },
            (err: ApiErrorResponse) => {
                Sentry.captureException(`ItemContainer::answerItem: API exception: ${err.response.data.message}`)
                // TODO: Should more detail be communicated to the end user?
                this.setState({
                    error: `Sorry, an error occurred. Please contact ${productStore.driver!!.config.SUPPORT_EMAIL}.`
                })
                throw new Error("[ItemContainer.answerItem] Could not get/display correct answer.")
                reject(err)
            }
        )
    }

    handleOutOfTime = () => {
        this.props.history.push(this.props.section!.config!.finishSectionUrl!)
    }

    nextItemIndex = (currentItemIndex: number, items: IItem[]): number => {
        let nextIdx = currentItemIndex + 1
        if (nextIdx >= items.length || nextIdx < 0) {
            nextIdx = 0
        }
        return nextIdx
    }

    setItemTransitionState = (direction: NavDirectionEnum) => {
        // Set transition CSS
        this.setState({
            transitionDirection: direction,
            transitioning: true,
            transitionOut: TransitionOutEnum.FADE
        })
    }

    setItemChildInstance = (instance: Item) => {
        this.itemInstance = instance
    }

    getItemIndexFromUrl = (params: any): number | undefined => {
        // We received the 1-based position from the URL as a string so
        // try to parse it to an Int then use it to make the 0-based index

        const itemPosition = parseInt(params.itemPosition, 10)
        let itemIndex
        if (!isNaN(itemPosition)) {
            itemIndex = itemPosition - 1
        }
        return itemIndex
    }

    closeAnsweredModal = () => {
        this.setState({showAnsweredModal: false})
    }

    closeCbModal = () => {
        this.setState({showCbModal: false})
    }

    closeOnBlurModal = () => {
        this.setState({ showOnBlurModal: false })
    }

    render() {
        const driver = productStore.driver

        if (!driver) {
            return null
        }

        if (!authStore.auth) {
            return null
        }

        const section = this.props.section
        const item = this.state.item
        let itemWrapperClass =
            this.props.section && this.props.section.config.showItemMenu ? "item-wrapper" : "item-wrapper no-item-menu"

        if (this.state.transitionOut !== null) {
            itemWrapperClass = itemWrapperClass + " transition-out-" + this.state.transitionOut
        }

        const progressInSection: number =
            this.props.section && this.state.item
                ? HelperService.getProgressInSection(this.props.section, this.state.item.id)
                : 0
        const metaDataItems = (section != null) ? section.items.map((i) => {
            return `${i.id}`
        }).join(",") : ""
        const metaNumSections = (section != null) ? section.info.numSections : null
        const metaCurSection = (section != null) ? section.info.currentSection : null
        return (
            <div className="item-container"
                 data-item-ids={metaDataItems}
                 data-num-sections={metaNumSections}
                 data-cur-section={metaCurSection}
            >
                {this.state.error && (
                    <div className="alerts">
                        <Alert variant="danger" onClose={() => this.setState({error: false})} dismissible>
                            {this.state.error}
                        </Alert>
                    </div>
                )}

                {section !== null && item !== null ? (
                    <>
                        <div className={itemWrapperClass}>
                            <Item
                                item={item}
                                panelId={section.panelId}
                                panelGraph={this.props.panelGraph}
                                takeId={section.takeId}
                                takePanelId={section.takePanelId}
                                startTime={section.info.startTime || null}
                                allowedTime={productStore.driver!!.config.TOTAL_TEST_TIME}
                                localAnswer={this.state.localAnswer}
                                prevAnswer={this.state.prevAnswer}
                                correctAnswer={this.state.correctAnswer}
                                handleLocalAnswerChange={this.handleLocalAnswerChange}
                                handleNavigation={this.handleNavigation}
                                handleOutOfTime={this.handleOutOfTime}
                                config={section.config}
                                isFirstItem={this.state.itemIndex === 0}
                                isLastItem={this.state.itemIndex === section.items.length - 1}
                                ref={this.setItemChildInstance}
                                isRtlLayout={productStore.driver!!.config.IS_RTL_LAYOUT}
                                autoSaving={this.state.autoSaving}
                                showChoices={this.state.showChoices}
                                authUser={authStore.auth}
                                handleEditCorrectAnswer={this.handleEditCorrectAnswer}
                                handleEditItemContent={this.handleEditItemContent}
                                handleUpdateItemFile={this.handleUpdateItemFile}
                                driver={productStore.driver!!}
                                recordingWarningSeen={this.props.recordingWarningSeen}
                                setSeenRecordingWarning={this.setRecordingWarningSeen}
                                progressInSection={progressInSection}
                                section={section}
                                itemIndex={this.state.itemIndex}
                                handleNavigationByIndex={this.handleNavigationByIndex}
                                product={productStore.loginProduct!!}
                                hideSpinner={loadingSpinnerStore.hideLoadingSpinner}
                            />
                        </div>
                    </>
                ) : (
                    <div style={{fontWeight: "normal", width: "100%", textAlign: "center"}}>
                        <h2>{driver.strings.loading}</h2>
                    </div>
                )}

                {this.state.item &&
                    this.state.localAnswer &&
                    this.state.item.requiredAnswerCount && (
                        <InvalidNumChoicesModal
                            showModal={this.state.showCbModal}
                            onHide={this.closeCbModal}
                            description={productStore.driver!!.itemContainerHelper.getCbAnswerCountModalText(
                                this.state.item,
                                this.state.localAnswer
                            )}
                            onOkayClick={this.closeCbModal}
                        />
                    )}

                {this.state.item && (
                    <InvalidNumChoicesModal
                        showModal={this.state.showAnsweredModal}
                        onHide={this.closeCbModal}
                        description={productStore.driver!!.itemContainerHelper.answeredModalText}
                        onOkayClick={this.closeAnsweredModal}
                    />
                )}

                <OnBlurWarningModal showModal={this.state.showOnBlurModal} onClick={this.closeOnBlurModal}/>
            </div>
        )
    }
}

class InvalidNumChoicesModal extends React.Component<{
    showModal: boolean,
    onHide: () => void,
    description: React.ReactNode,
    onOkayClick: () => void
}> {
    render() {
        return (
            <Modal size="sm" show={this.props.showModal} onHide={this.props.onHide}>
                <Modal.Header closeButton={false} className="center-text">
                    <Modal.Title>Invalid number of choices</Modal.Title>
                </Modal.Header>
                <Modal.Body className="center-text">
                    <p>{this.props.description}</p>
                    <Row>
                        <Col sm={{span: 6, offset: 3}}>
                            <Button
                                className="avant-button avant-button--primary"
                                onClick={this.props.onOkayClick}
                                block={true}
                            >
                                OK
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }
}

interface OnBlurProps {
    showModal: boolean
    onClick: () => void
}

const OnBlurWarningModal = ({ showModal, onClick }: OnBlurProps) => {
    return (
        <Dialog open={showModal}>
            <DialogContent style={{ textAlign: "center" }}>
                <p>You have clicked outside of the test page. This action is not permitted and has been tracked.</p>
                <strong>Clicking outside of the active test screen may terminate the test.</strong>
            </DialogContent>
            <DialogActions>
                <Grid container justify={"center"}>
                    <Grid item xs={6}>
                        <Button
                            className={"avant-button--primary avant-button--block avant-button--default"}
                            block={true}
                            onClick={onClick}
                        >Close</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemContainer)
