import {OGVLoader, OGVPlayer} from "ogv"
import React from "react"
import { voiceRecorderStore } from "../../../app/common/item/VoiceRecorderStore"
import { messageStore } from "../../../app/common/messages/MessageStore"
import {MINIMUM_VOICE_RECORDING_RECORDING_LENGTH} from "../../../util/Constants"
import Html5VoiceRecorder from "../Html5VoiceRecorder/Html5VoiceRecorder"
import { SoundPlayer, WAVE_TYPE } from "../SoundPlayer"

interface IComponentProps {
    onOggBlobAvailable: (oggBlob: Blob | null) => void
    saveFinalRecordings: boolean
    onFailure: () => void
    onReady: () => void
    maxLengthSeconds: number
}

interface IComponentState {
    recording?: Blob
    startTime?: Date
    isDeleteConfirmShown: boolean
    isInteractive: boolean
    currentLength: number
    currentRecording: Uint8Array[]
    wantToStop: boolean
    volume: number
}


async function asyncResume(audioContext: AudioContext) {
    await audioContext.resume()
}

const VOLUME_INDOCATOR_FFT_SIZE = 256
const VOLUME_INDICATOR_SAMPLE_PERIOD = 10 // Sample period in milliseconds, determines how long between each volume level calculation

//These are the object and global for the opus recorder.  This is needed because they are defined
//in the index.html.
declare const Recorder: any
declare var recorder: any

/*
    For Safari and iOS Safari, AudioContext must be prefixed with "webkit"
    For older browsers or browsers that do not support the Audio Context API
    An alerts message is shown to the user.
*/
const getAudioContext = (): any => {
    // @ts-ignore
    const audioContext = window.AudioContext || window.webkitAudioContext || false
    if (audioContext) {
        return audioContext
    } else {
        // Web Audio API is not supported, alert the user
        messageStore.setInfoMessage(
            "Sorry, it looks like your browser does not support this audio feature (Audio Context API). Please contact your administrator.")
    }
}


export class OpusVoiceRecorder extends React.Component<IComponentProps, IComponentState> {
    private readonly analyser: AnalyserNode
    private readonly audioContext: AudioContext
    private volumeIntervalHandle?: number
    private readonly byteTimeDomainData: Uint8Array
    private readonly floatTimeDomainData: Float32Array
    private soundPlayer: SoundPlayer | null
    private source: MediaStreamAudioSourceNode | null


    constructor(props: IComponentProps) {
        super(props)
        OGVLoader.base = "/ogv"
        voiceRecorderStore.oggPlayer = new OGVPlayer({
            debug: false
        })

        // Check that the device support UserMedia (but don't turn on the mic yet)
        if (!navigator.mediaDevices.getUserMedia) {
            messageStore.setInfoMessage("Unable to start voice recorder. Please contact your administrator.")
        }

        // Volume indicator set-up
        this.audioContext = new(getAudioContext())
        this.analyser = this.audioContext.createAnalyser()
        this.analyser.fftSize = VOLUME_INDOCATOR_FFT_SIZE
        this.byteTimeDomainData = new Uint8Array(this.analyser.frequencyBinCount)
        this.floatTimeDomainData = new Float32Array(this.analyser.frequencyBinCount)
        this.soundPlayer = null
        this.source = null

        navigator.mediaDevices.getUserMedia({audio: true, video: false})
            .then((stream: MediaStream) => {
                const voiceApplication = 2048
                this.source = this.audioContext.createMediaStreamSource(stream)
                recorder = new Recorder({
                    encoderSampleRate: 8000,
                    encoderPath: "/opus-recorder-8.0.3/encoderWorker.min.js",
                    encoderApplication: voiceApplication,
                    streamPages: true,
                    sourceNode: this.source
                })

                if (recorder) {
                    // recorder.config.streamPages = true

                    recorder.onstop = () => {
                        // This data reset MUST happen in the onstop
                        // callback, because otherwise it's possible the
                        // recorder will dump a header page in prematurely
                        // which will corrupt the data.
                        this.setState({
                            recording: undefined,
                            isDeleteConfirmShown: false,
                            currentLength: 0,
                            currentRecording: [],
                            wantToStop: false
                        })

                        // Unanswer the speaking item.
                        this.props.onOggBlobAvailable(null)
                    }

                    recorder.onstart = () => {
                        this.setState({isInteractive: true})
                    }
                    recorder.onresume = () => {
                        this.setState({isInteractive: true})
                    }

                    recorder.ondataavailable = (page: Uint8Array) => {
                        // Add page onto the current recording
                        const currentRecording = this.state.currentRecording
                        let currentLength = this.state.currentLength
                        currentRecording.push(page)
                        currentLength += page.length
                        this.setState({currentRecording, currentLength})

                        // User hit stop earlier, so the page added above will be the last
                        // of the current recording.
                        if (this.state.wantToStop) {
                            recorder.pause()

                            // Create a single Uint8Array of data from the
                            // currently recorded pages.
                            const outputData = new Uint8Array(currentLength)
                            currentRecording.reduce((offset, curPage) => {
                                outputData.set(curPage, offset)
                                return offset + curPage.length
                            }, 0)

                            const recording = new Blob([outputData], {type: "audio/ogg"})
                            this.setState({recording, wantToStop: false, isInteractive: true})
                            this.props.onOggBlobAvailable(recording)
                        }
                    }

                    this.soundPlayer = new SoundPlayer(this.audioContext)
                } else {
                    throw new Error("Expected Opus Recorder to exist!")
                }
            }, _ => {
                messageStore.setInfoMessage("Please allow microphone use in the browser.")
            })

        this.state = {
            startTime: new Date(),
            isDeleteConfirmShown: false,
            isInteractive: true,
            currentLength: 0,
            currentRecording: [],
            wantToStop: false,
            volume: 0
        }
        voiceRecorderStore.playbackState = "not"

    }

    startRecording = () => {
        if (recorder) {
            this.setState({isInteractive: false})
            // If there is a recording available, then
            // we are appending to the recording, so use
            // `resume` to continue adding to it.
            if (this.state.recording) {
                this.startingSound()
                recorder.resume()
            } else {
                // Check to make sure the audioContext is not in suspended state. This can occur because of
                // an Autoplay Policy in Chrome. If the audioContext is initialized before the user interacts
                // with the page it will start in suspended state.
                // See https://developers.google.com/web/updates/2017/09/autoplay-policy-changes#webaudio
                if (this.audioContext.state === "suspended") {
                    asyncResume(this.audioContext)
                }

                recorder.start().then((res: any) => {
                    // we wrap this in the promise callback so the beeps don't start until the
                    // user accepts the browser "allow microphone" modal.
                    this.startingSound()
                })
            }
            this.startVolumeIndicator()
        } else {
            messageStore.setInfoMessage("Please allow microphone use in the browser.")
        }
    }

    stopRecording = () => {
        // Wait till the next page of data drops, then
        // stop. Otherwise we'll lose a page of data.
        // TODO: Need to have a loading spinner or something happen while we wait.
        this.setState({wantToStop: true, isInteractive: false})
        this.stopVolumeIndicator()
        this.stoppingSound()
    }

    startingSound = () => {
        const duration = 0.4 // seconds
        const padding = 0.1 // seconds
        const volume = .01
        if (this.soundPlayer) {
            this.soundPlayer.play(440, WAVE_TYPE.sine, 0.0, volume).stop(duration)
            this.soundPlayer.play(440, WAVE_TYPE.sine, duration + padding, volume).stop(2 * duration + padding)
            this.soundPlayer.play(440, WAVE_TYPE.sine, 2 * (duration + padding), volume).stop(3 * duration + 2 * padding)
            this.soundPlayer.play(880, WAVE_TYPE.sine, 3 * (duration + padding), volume).stop(4 * duration + 3 * padding)
        }
    }

    stoppingSound = () => {
        const duration = 0.4 // seconds
        const padding = 0.1  // seconds
        const volume = .01
        if (this.soundPlayer) {
            this.soundPlayer.play(587, WAVE_TYPE.sine, 0.0, volume).stop(duration)
            this.soundPlayer.play(293, WAVE_TYPE.sine, duration + padding, volume).stop(2 * duration + padding)
        }
    }


    // TODO test playback with 2 minute recording on
    // both OSX and iOS Safari, since it's live encoding
    // it requires non-trivial cpu resources to accomplish this.
    playbackClick = (onPlaybackEnded: () => void) => {
        // TODO: See if this still works and fix at some point
        // @ts-ignore
        voiceRecorderStore.oggPlayer.src = URL.createObjectURL(this.state.recording)

        voiceRecorderStore.oggPlayer.play()
        voiceRecorderStore.oggPlayer.addEventListener("ended", () => {
            voiceRecorderStore.playbackState = "not"
            onPlaybackEnded()
        })
    }

    startVolumeIndicator = () => {
        if (this.source) {
            this.source.connect(this.analyser)
            this.volumeIntervalHandle = window.setInterval(
                this.computeVolumeAndSetState,
                VOLUME_INDICATOR_SAMPLE_PERIOD
            )
        }
    }

    stopVolumeIndicator = () => {
        this.analyser.disconnect()
        if (this.volumeIntervalHandle) {
            clearInterval(this.volumeIntervalHandle)
        }
    }

    computeVolumeAndSetState = () => {
        this.analyser.getByteTimeDomainData(this.byteTimeDomainData)
        for (let i = 0; i < this.byteTimeDomainData.length; i++) {
            this.floatTimeDomainData[i] = (this.byteTimeDomainData[i] - 128) / 128.0
        }

        let total = 0
        this.floatTimeDomainData.forEach(datum => {
            total += Math.abs(datum)
        })
        // RMS of the input stream
        const volume = Math.sqrt(total / VOLUME_INDOCATOR_FFT_SIZE)
        this.setState({volume})
    }

    render() {
        return (
            <div data-tst-id="opus-voice-recorder">
            <Html5VoiceRecorder
                supportsOtherInputs={false}
                availableInputs={[]}
                recordingState={this.state.recording ? "recorded" : "none"}
                startRecording={this.startRecording}
                stopRecording={this.stopRecording}
                saveFinalRecordings={this.props.saveFinalRecordings}
                hasRecordingAvailable={() => {
                    return !!this.state.recording
                }}
                minRecordingLengthMilliseconds={MINIMUM_VOICE_RECORDING_RECORDING_LENGTH}
                resetRecording={() => {
                    recorder.stop()
                }}
                startPlaybackRecording={this.playbackClick}
                addToRecording={() => {
                    this.startRecording()
                }}
                volume={this.state.volume}
                displayVolume={true}
                isInteractable={this.state.isInteractive}
                maxLengthSeconds={this.props.maxLengthSeconds}
            />
            </div>
        )
    }
}
