import {Grid} from "@material-ui/core"
import {observable} from "mobx"
import {observer} from "mobx-react"
import * as React from "react"
import {AvantColors} from "../../../../styles/AvantColors"
import {H1Text, H5Text} from "../../../../styles/AvantTypography"
import {theme} from "../../../../styles/MuiThemes"
import {authStore} from "../../../common/authentication/AuthStore"
import {groupMasterStore} from "../../../common/group/GroupMasterStore"
import {GroupInfo} from "../../../common/group/models/GroupInfo"
import {AvantCircularProgress} from "../../../common/loaders/AvantCircularProgress"
import {productStore} from "../../../common/products/ProductStore"
import {takeSearchStore} from "../../../common/take/TakeSearchStore"
import {AvantExpansionPanel} from "../../../generic-components/AvantExpansionPanel"
import {BreadcrumbHeader} from "../../common/components/Breadcrumbs"
import {FilterAndPrintOptions} from "../../common/components/FilterAndPrintOptions"
import {LevelKeyContent} from "../../common/components/level-key/LevelKeyContent"
import {PrintOptions} from "../../common/components/PrintViewContainer"
import {DateRangeHeaderText} from "../../common/components/report-page/DateRangeHeaderText"
import {GraphHelper} from "../../common/components/report-page/GraphHelper"
import {findFilters, ReportRouteProps} from "../../common/components/ReportsPage"
import {BlueReportType} from "../../common/models/BlueReportType"
import {GenericReportContainerProps} from "../../common/models/GenericReportProps"
import {ReportFilters} from "../../common/models/ReportFilters"
import {reportsStore} from "../../common/stores/ReportStore"
import {ReportSummaryGraphs} from "../models/ReportSummaryGraphs"
import {DistrictTableContainer} from "./district-school-table/DistrictTable"
import {SchoolTableContainer} from "./district-school-table/SchoolTable"
import moment from "moment"
import {ReportGraphFilters} from "../../common/models/ReportGraphFilters"
import {DistributionChart} from "../../common/components/aggregate-charts/DistributionChart"
import LanguageUtils from "../../../../util/LanguageUtils"
import AccordionDetails from "@material-ui/core/AccordionDetails"

export class DistrictReportPage extends React.Component<ReportRouteProps> {

    componentDidMount(): void {
        document.title = `Avant | District Report`
    }

    render() {
        const filters = findFilters(this.props)
        return <DistrictOrSchoolReportContainer filters={filters} district={true}/>
    }
}

export class SchoolReportPage extends React.Component<ReportRouteProps> {

    componentDidMount(): void {
        document.title = `Avant | School Report`
    }

    render() {
        const filters = findFilters(this.props)
        return <DistrictOrSchoolReportContainer filters={filters} district={false}/>
    }
}

interface DistrictOrSchoolReportContainerProps extends GenericReportContainerProps {
    district: boolean
}

@observer
class DistrictOrSchoolReportContainer extends React.Component<DistrictOrSchoolReportContainerProps> {

    constructor(props: DistrictOrSchoolReportContainerProps) {
        super(props)

        // Make sure the first render cycle doesn't have data from the previously loaded report.
        reportsStore.reportTableData = undefined
        reportsStore.reportGraphData = undefined
    }

    componentDidMount(): void {
        // log.debug("component did mount of district/school report container, id " + this.props.filters.groupId)
        this.searchReportData(this.props)
    }

    componentDidUpdate(prevProps: Readonly<DistrictOrSchoolReportContainerProps>, prevState: Readonly<{}>, snapshot?: any) {
        const {filters} = this.props
        if (filters.groupId !== prevProps.filters.groupId
            || !filters.startDate.isSame(prevProps.filters.startDate)
            || !filters.endDate.isSame(prevProps.filters.endDate)
            || filters.assessmentStart !== prevProps.filters.assessmentStart
            || filters.recentActivity !== prevProps.filters.recentActivity
        ) {
            this.searchReportData(this.props)
        }
    }

    getQuery = (props: DistrictOrSchoolReportContainerProps): ReportGraphFilters => {
        const {filters} = props
        const {groupId, productId, startDate, endDate, assessmentStart, recentActivity} = filters

        //make copies so we don't change state
        const startDateStartOfDay = moment(startDate).startOf("day").valueOf()
        const endDateEndOfDay = moment(endDate).endOf("day").valueOf()

        reportsStore.searchFilters = {groupId, productId, assessmentStart, recentActivity, startDate, endDate}

        return {
            groupId,
            productId,
            start: startDateStartOfDay,
            end: endDateEndOfDay,
            searchByStartTimes: assessmentStart,
            searchByMostRecentActivityTimes: recentActivity
        }

    }

    searchReportData = (props: DistrictOrSchoolReportContainerProps) => {
        const {filters, district} = props
        const {groupId} = filters

        takeSearchStore.instances = []
        takeSearchStore.downloadComplete = false

        const productId = productStore.loginProduct?.productId || -1
        if (district) {
            if (authStore.auth !== undefined && authStore.auth !== null &&
                authStore.auth.ssoId !== null &&  authStore.auth.ssoId !== undefined) {
                reportsStore.findSSODistrictReport(groupId, productId)
            } else {
                reportsStore.findDistrictReportData(groupId)
            }
        } else {
            if (authStore.auth !== undefined && authStore.auth !== null &&
                authStore.auth.ssoId !== null &&  authStore.auth.ssoId !== undefined) {
                reportsStore.findSSOSchoolReportData(groupId, productId)
            } else {
                reportsStore.findSchoolReportData(groupId)
            }
        }
        const query = this.getQuery(props)
        reportsStore.findReportGraphs(query)
        reportsStore.findGroupsWithData(query)
        reportsStore.resetDownloads()
        groupMasterStore.groupInfo = undefined
        groupMasterStore.findDistrictReportGroupInfo(groupId)
    }

    render() {
        if (reportsStore.reportGraphData == null || groupMasterStore.groupInfo == null) {
            return <AvantCircularProgress/>
        }

        return (
            <DistrictOrSchoolReportView
                graphData={reportsStore.reportGraphData!}
                groupInfo={groupMasterStore.groupInfo}
                district={this.props.district}
                filters={this.props.filters}
                query={this.getQuery(this.props)}
            />
        )
    }
}

export interface DistrictOrSchoolReportViewProps {
    graphData: ReportSummaryGraphs
    groupInfo: GroupInfo
    district: boolean
    filters: ReportFilters
    query?: ReportGraphFilters
    printOptions?: PrintOptions
}

// TODO This is currently copy pasted from Class reports and not expected to work. I want this to compile, though, and make further changes to this in
// other PRs so changes here won't be swamped by refactoring changes.
@observer
export class DistrictOrSchoolReportView extends React.Component<DistrictOrSchoolReportViewProps> {

    @observable
    sidebarOpen: boolean = false

    render() {
        const {groupInfo, query, district, filters, printOptions, graphData} = this.props
        const {startDate, endDate} = filters

        return (
            <>
                <div style={{marginBottom: theme.spacing(4)}}>
                    {printOptions == null && <BreadcrumbHeader groupInfo={groupInfo} filters={filters}/>}
                    <Grid container={true} alignItems={"flex-end"}>
                        <Grid item={true} xs={12} md={6}>
                            <H1Text>
                                {groupInfo == null ? "" : groupInfo.name}
                            </H1Text>
                        </Grid>
                        {printOptions != null ? (
                            <LevelKeyContent/>
                        ) : (
                            <Grid item={true} xs={12} md={6}>
                                <FilterAndPrintOptions
                                    reportType={district ? BlueReportType.DISTRICT : BlueReportType.SCHOOL}
                                    filters={filters}
                                    query={query!!}
                                />
                            </Grid>
                        )}
                    </Grid>
                </div>
                {printOptions !== PrintOptions.LIST && (
                    <AvantExpansionPanel
                        expanded={printOptions != null ? true : undefined}
                        title={"Summary"}
                    >
                        <AccordionDetails
                            style={{
                                width: "100%",
                                display: "inherit",
                                padding: printOptions != null ? 0 : ""
                            }}
                        >
                            <div style={{display: "flex", alignItems: "center"}}>
                                <DateRangeHeaderText
                                    header={"Distribution of Test Takers Across Levels"}
                                    from={startDate}
                                    to={endDate}
                                />
                                <div style={{flexGrow: 1}}/>
                                <GraphHelper/>
                            </div>
                            {graphData.graphs.map(languageSummaryData => {
                                return (
                                    <div key={languageSummaryData.language} style={{breakInside: "avoid"}}>
                                        <LanguageBar language={languageSummaryData.language}/>
                                        <Grid
                                            container={true}
                                            justify={"space-between"}
                                            style={{width: "100%", marginTop: theme.spacing(2)}}
                                        >
                                            {languageSummaryData.summarySkillGraph.map(skillGraph => {
                                                return <DistributionChart
                                                    skillData={skillGraph}
                                                    productId={filters.productId}
                                                    key={skillGraph.skillName}
                                                />
                                            })}
                                        </Grid>
                                    </div>
                                )
                            })}
                        </AccordionDetails>
                    </AvantExpansionPanel>
                )}

                {printOptions !== PrintOptions.SUMMARY && (
                    <AvantExpansionPanel
                        expanded={printOptions != null ? true : undefined}
                        style={{breakBefore: "page"}}
                        title={district ? "School/Location List" : "Testing Groups"}
                    >
                        <AccordionDetails
                            style={{
                                width: "100%",
                                display: "inherit",
                                padding: theme.spacing(2, 0)
                            }}
                        >
                            {
                                district ? (
                                    <DistrictTableContainer
                                        reportData={reportsStore.districtReportData}
                                        filters={filters}
                                        printing={printOptions != null}
                                    />
                                ) : (
                                    <SchoolTableContainer
                                        reportData={reportsStore.schoolReportData}
                                        filters={filters}
                                        printing={printOptions != null}
                                    />
                                )
                            }
                        </AccordionDetails>
                    </AvantExpansionPanel>
                )}
            </>
        )
    }
}

export const LanguageBar = (props: { language: string }) => {
    const language = LanguageUtils.removeTilde(props.language)

    return (
        <div
            style={{
                height: 36,
                backgroundColor: AvantColors.REPORT_BACKGROUND_GRAY_DARK,
                marginTop: theme.spacing(2),
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 6
            }}
        >
            <H5Text>{language}</H5Text>
        </div>
    )
}
