import * as React from "react"
import {H3Text, H5Text} from "../../../../../styles/AvantTypography"
import {theme} from "../../../../../styles/MuiThemes"
import {Moment} from "../../../../../types/types"
import {TimeUtil} from "../../../../../util/TimeUtil"

export const DateRangeHeaderText = (props: {
    header: string,
    from: Moment,
    to: Moment,
    style?: React.CSSProperties
}) => {
    const fromDate = TimeUtil.formatToUsDate(props.from)
    const toDate = TimeUtil.formatToUsDate(props.to)
    return (
        <div
            style={{
                display: "flex",
                alignItems: "flex-end",
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
                ...props.style
            }}
        >
            <H3Text lineHeight={"24px"} style={{marginRight: theme.spacing(1)}}>
                {props.header}
            </H3Text>
            <H5Text lineHeight={"24px"} textTransform={"none"}>
                for {fromDate} - {toDate}
            </H5Text>
        </div>
    )
}
