/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {Box, Breadcrumbs, Divider, makeStyles} from "@material-ui/core"
import {ChevronRight} from "@material-ui/icons"
import * as React from "react"
import {H5Text} from "../../../../styles/AvantTypography"
import {theme} from "../../../../styles/MuiThemes"
import {GroupInfo} from "../../../common/group/models/GroupInfo"
import {GroupMaster, GroupType} from "../../../common/group/models/GroupMaster"
import {AvantCircularProgress, CircularProgressSize} from "../../../common/loaders/AvantCircularProgress"
import {UnstyledLink} from "../../../generic-components/UnstyledLink"
import {ReportSubPaths} from "../../ReportSubPaths"
import {ReportFilters} from "../models/ReportFilters"
import {AvantColors} from "../../../../styles/AvantColors"
import {productStore} from "../../../common/products/ProductStore"
import {PLACE_PRODUCT_ID} from "../../../../util/Constants"
import {groupMasterStore} from "../../../common/group/GroupMasterStore"
import {UserType} from "../../../common/authentication/models/UserType"
import lodash from "lodash"

interface BreadcrumbHeaderProps {
    groupInfo?: GroupInfo
    pathname?: string
    individual?: string
    filters: ReportFilters
}

export const BreadcrumbHeader = (props: BreadcrumbHeaderProps) => {
    if (props.groupInfo == null) {
        return <AvantCircularProgress size={CircularProgressSize.SMALL}/>
    }
    return (
        <Box style={{marginBottom: theme.spacing(1), width: "100%"}} displayPrint={"none"}>
            <Crumbs {...props}/>
            <Divider style={{margin: theme.spacing(2, 0)}}/>
        </Box>
    )
}

const Crumbs = (props: BreadcrumbHeaderProps) => {
    const {individual, filters} = props
    const groupInfo = props.groupInfo!

    // Iterate over parent groups (except for State) and add them to the breadcrumbs.
    const crumbs: React.ReactNode[] = groupInfo.parentGroups.filter(group => group.groupType !== GroupType.STATE).map((group: GroupMaster) => (
        <Crumb
            key={`${group.id}-${group.name}`}
            text={group.name}
            group={group}
            filters={filters}
            lastCrumb={!individual && groupInfo.id === group.id}
        />
    ))

    // Add the PLACE Customer group.
    if (productStore.loginProduct!!.productId === PLACE_PRODUCT_ID.value()) {
        const {defaultGroup} = groupMasterStore

        // TODO: PLACE Reports use a different type of filter. We may want to update the Breadcrumbs to accept both type of filters.
        crumbs.push(
            <Crumb
                key={`${groupInfo.id}-${groupInfo.name}`}
                text={groupInfo.name}
                group={defaultGroup}
                filters={filters}
                lastCrumb={false}
            />
        )

    }

    // Add the Class group and the test taker to the Breadcrumbs
    if (individual) {
        // Create a new GroupMaster for the Class,
        // groupInfo contains the class data at this point.
        const classGroup: GroupMaster = {
            id: groupInfo.id,
            name: groupInfo.name,
            groupType: GroupType.CLASS,
            defaultGroup: true,
            canView: true,
            testingGroupInfo: []
        }

        // Create a new copy of the filters.
        const classFilters: ReportFilters = lodash.cloneDeep(filters)
        classFilters.groupId = groupInfo.id
        classFilters.productId = productStore.loginProduct!!.productId

        crumbs.push(
            <Crumb
                key={`${groupInfo.id}-${groupInfo.name}`}
                text={groupInfo.name}
                group={classGroup}
                filters={classFilters}
                lastCrumb={false}
            />
        )

        // Add the test taker.
        crumbs.push(<Crumb key={`${individual}`} text={individual} lastCrumb={true}/>)
    }

    return (
        <Breadcrumbs
            separator={<ChevronRight fontSize={"small"}/>}
            style={{fontSize: 14}}
            aria-label={"group breadcrumbs"}
            component={"nav"}
        >
            {crumbs}
        </Breadcrumbs>
    )
}

const useHoverStyle = makeStyles({
    hoverAnimation: {
        transition: "all 0.2s",
        '&:hover': {
            color: AvantColors.avantDarkBlue,
            textDecoration: 'underline',
            transform: "scale(1.15)"
        }
    }
})

const canViewDistrictGroups = [
    GroupType.DISTRICT.toString()
]

const canViewSchoolGroups = [
    ...canViewDistrictGroups,
    GroupType.SCHOOL.toString()
]

const canViewClassGroups = [
    ...canViewSchoolGroups,
    GroupType.CLASS.toString(),
    GroupType.LOCATION.toString(),
    GroupType.CUSTOMER.toString(),
    UserType.T.toString()
]

const Crumb = (props: { text: string, group?: GroupMaster, filters?: ReportFilters, lastCrumb: boolean }) => {
    const {text, group, filters, lastCrumb} = props
    const hoverStyle = useHoverStyle()
    const userType = productStore.loginProduct ? productStore.loginProduct.userType : null
    let canView = false

    let route
    if (group != null && userType != null && filters != null) {
        switch (group.groupType) {
            case GroupType.DISTRICT:
                route = ReportSubPaths.district({...filters, groupId: group.id})
                if (canViewDistrictGroups.includes(userType.toString())) {
                    canView = true
                }
                break
            case GroupType.SCHOOL:
                route = ReportSubPaths.school({...filters, groupId: group.id})
                if (canViewSchoolGroups.includes(userType.toString())) {
                    canView = true
                }
                break
            case GroupType.CLASS:
            case GroupType.LOCATION:
            case GroupType.CUSTOMER:
                route = ReportSubPaths.class({...filters, groupId: group.id})
                if (canViewClassGroups.includes(userType.toString())) {
                    canView = true
                }
                break
            default:
                break
        }
    }
    if (route && group != null && canView && !lastCrumb) {
        return (
            <UnstyledLink to={route}>
                <H5Text
                    className={hoverStyle.hoverAnimation}
                    textTransform={"none"}>
                    {text}
                </H5Text>
            </UnstyledLink>
        )
    }
    return (
        <H5Text textTransform={"none"} fontWeight={lastCrumb ? "bold" : undefined}>
            {text}
        </H5Text>
    )
}
