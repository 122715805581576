import React from "react"
import sanitizeHtml from "sanitize-html"
import {ItemContent} from "../../app/common/item/models/ItemContent"
import {to} from "../../util/elvis"
import Button from "../Button/Button"

interface InstructionTextProps {
    className: string
    itemContent: ItemContent
    isAdmin: boolean
    isRtlAdvance: boolean
    itemId: number
    handleEditItemContent: (itemContentId: number, content: string, itemId: number) => Promise<void>
}

export default class InstructionText extends React.Component<InstructionTextProps, {}> {
    constructor(props: InstructionTextProps) {
        super(props)
    }

    getEditableInstructionText = (itemContentId: number): HTMLElement => {
        const editableId: string = `editable${itemContentId}`
        return to<HTMLElement>(
            document.getElementById(editableId),
            new Error(`Can't find editable instruction text ${editableId}`)
        )
    }

    getNonEditableInstructionText = (itemContentId: number): HTMLElement => {
        const nonEditableId: string = `nonEditable${itemContentId}`
        return to<HTMLElement>(
            document.getElementById(nonEditableId),
            new Error(`Can't find non-editable instruction text ${nonEditableId}`)
        )
    }

    getInstructionTextArea = (itemContentId: number): HTMLTextAreaElement => {
        const textAreaId: string = `textArea${itemContentId}`
        return to<HTMLTextAreaElement>(
            document.getElementById(textAreaId) as HTMLTextAreaElement,
            new Error(`Can't find instruction text textarea ${textAreaId}`)
        )
    }

    saveInstructionText = (itemContentId: number) => {
        const textArea: HTMLTextAreaElement = this.getInstructionTextArea(itemContentId)
        this.props.handleEditItemContent(itemContentId, textArea.value, this.props.itemId).then(() => {
            const editableObj: HTMLElement = this.getEditableInstructionText(itemContentId)
            editableObj.hidden = true
            const nonEditableObj: HTMLElement = this.getNonEditableInstructionText(itemContentId)
            nonEditableObj.hidden = false
        })
    }

    cancelInstructionText = (itemContentId: number) => {
        const editableObj: HTMLElement = this.getEditableInstructionText(itemContentId)
        editableObj.hidden = true
        const nonEditableObj: HTMLElement = this.getNonEditableInstructionText(itemContentId)
        nonEditableObj.hidden = false
    }

    editInstructionText = (itemContentId: number) => {
        const editableObj: HTMLElement = this.getEditableInstructionText(itemContentId)
        editableObj.hidden = false
        const nonEditableObj: HTMLElement = this.getNonEditableInstructionText(itemContentId)
        nonEditableObj.hidden = true
    }
    //
    renderNonEditableInstructionText = (content: string): any => {
        content = sanitizeHtml(content, {
            allowedTags: ["b", "strong", "i", "em", "div", "p", "br"]
        })
        if (content === "null") {
            content = ""
        }
        return React.createElement("p", {dangerouslySetInnerHTML: {__html: content}})
    }

    render() {
        const textAreaId: string = `textArea${this.props.itemContent.id}`
        const editableId: string = `editable${this.props.itemContent.id}`
        const nonEditableId: string = `nonEditable${this.props.itemContent.id}`
        const className: string = this.props.className
        return (
            <div
                key={this.props.itemContent.id.toString()}
                id={this.props.itemContent.id.toString()}
                className={className}
            >
                <div id={editableId} hidden={true}>
                    <textarea id={textAreaId} defaultValue={this.props.itemContent.content as string} />
                    <Button
                        className="avant-button--primary avant-button--small margin-right"
                        onClick={() => this.cancelInstructionText(this.props.itemContent.id)}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="avant-button--primary avant-button--small"
                        onClick={() => this.saveInstructionText(this.props.itemContent.id)}
                    >
                        Save
                    </Button>
                </div>
                {this.props.isRtlAdvance && (
                    <>
                        <b>Response</b>
                        {"\u00a0"}
                        <br />
                    </>
                )}
                {this.props.isAdmin ? (
                    <div id={nonEditableId}>
                        <Button
                            className="avant-button--primary avant-button--small"
                            onClick={() => this.editInstructionText(this.props.itemContent.id)}
                        >
                            Edit
                        </Button>
                        {this.renderNonEditableInstructionText(this.props.itemContent.content as string)}
                    </div>
                ) : (
                    this.renderNonEditableInstructionText(this.props.itemContent.content as string)
                )}
            </div>
        )
    }
}
