import React, {useEffect, useState} from "react"
import {ApiTakePanelSearchResult} from "../../../common/take/TakeSearchStore"
import {PanelBenchmarkConstants, PanelLevel, PanelSkillEnum} from "../../../../types/types"
import {EnumUtil} from "../../../../util/EnumUtil"
import {TimeUtil} from "../../../../util/TimeUtil"
import {PLACE_PRODUCT_ID, REPORTS_MAX_MINUTES_TO_DISPLAY, STAMP_CEFR_PRODUCT_ID} from "../../../../util/Constants"
import {
    AvantBadgeEarlyExit,
    AvantBadgeNotRatable,
    AvantBadgeNotStarted,
    AvantBadgeScoringInProgress,
    AvantBadgeSectionInProgress
} from "../../../generic-components/AvantBadge"
import {sidebarStore} from "./SideBarStore"
import {Accordion, AccordionDetails, createStyles, Divider, makeStyles} from "@material-ui/core"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import {H5Text} from "../../../../styles/AvantTypography"
import {ArrowDropDown} from "@material-ui/icons"
import {AvantColors} from "../../../../styles/AvantColors"
import {SidebarList} from "./SidebarList"
import {SidebarListItem} from "../../../reports/class/components/test-taker-sidebar/SidebarList"
import {TestTakerSidebarProps} from "./TestTakerSidebar"
import {AvantCircularProgress, CircularProgressSize} from "../../../common/loaders/AvantCircularProgress"
import SidebarResponsesContainer from "./SidebarResponses"
import {productStore} from "../../../common/products/ProductStore"

function isGrammerOrReading(skill: string): boolean {
    return skill === PanelSkillEnum.GRAMMAR || skill === PanelSkillEnum.READING
}

function isValidAccuracyNumbers(
    itemsAccurateCount: number | undefined,
    itemsTakenCount: number | undefined,
    percentCorrect: number | undefined
): boolean {
    return (itemsAccurateCount != undefined || itemsAccurateCount === 0) &&
        itemsTakenCount != undefined &&
        (percentCorrect != undefined || percentCorrect === 0)
}

function getPanelStatus(panelLevel?: string) {
    switch(panelLevel) {
        case PanelLevel.PANEL_PENDING:
            return PanelBenchmarkConstants.PANEL_PENDING
        case PanelLevel.PANEL_PRINTABLE:
            return PanelBenchmarkConstants.PANEL_PRINTABLE
        case PanelLevel.PANEL_PRINTED:
            return PanelBenchmarkConstants.PANEL_PRINTED
        case PanelLevel.PANEL_STARTED:
            return <AvantBadgeSectionInProgress/>
        case PanelLevel.PANEL_COMPLETE:
            return <AvantBadgeScoringInProgress/>
        case PanelLevel.PANEL_NOT_STARTED:
            return <AvantBadgeNotStarted/>
        case PanelLevel.PANEL_NOT_RATABLE:
            return <AvantBadgeNotRatable/>
        case PanelLevel.PANEL_EARLY_EXIT:
            return <AvantBadgeEarlyExit/>
        case PanelLevel.PANEL_RATED_BY_EDUCATOR:
            return PanelBenchmarkConstants.PANEL_RATED_BY_EDUCATOR
        default:
            return panelLevel
    }
}

const useExpandedStyles = makeStyles(() =>
    createStyles({
        root: {
            "&$expanded": {
                minHeight: 0
            }
        },
        content: {
            margin: 0,
            "&$expanded": {
                margin: 0
            }
        },
        expanded: {}
    })
)

export interface PanelDisplayData {
    panelDisplayName: string
    skill: string
    panelLevel: React.ReactNode
    scaledScore?: React.ReactNode
    selfEvaluated?: boolean
    accuracyDetails: string
    startDate: string | React.ReactNode
    completedDate: string | React.ReactNode
    totalTime: string
    takePanelId: number
}

const TestTakerSidebarPanels = ({profileInfo, sortedPanels}: TestTakerSidebarProps) => {
    const [panelData, setPanelData] = useState<PanelDisplayData[]>([])
    const fullName: string = `${profileInfo.firstName} ${profileInfo.lastName}`
    const expandedClasses = useExpandedStyles()
    const productId: number | undefined = productStore.loginProduct && productStore.loginProduct.productId

    useEffect(() => {
        if (sortedPanels.length && !panelData.length) {
            const panelData: PanelDisplayData[] = []

            sortedPanels.map((panel: ApiTakePanelSearchResult) => {
                const panelDisplayName: string = String(EnumUtil.getEnumKeyByEnumValue(PanelSkillEnum, panel.skill)).replace(/_/g, ' ')
                const {isHandWritten} = profileInfo
                const totalTime: string | undefined =
                    TimeUtil.deltaTimeInMinutesOrEmptyStr(
                        Number(panel.startTime), Number(panel.finishTime),
                        REPORTS_MAX_MINUTES_TO_DISPLAY
                    )
                let completedDate: string | React.ReactNode = TimeUtil.formatMilliToMonthDayYearLong(Number(panel.finishTime))
                if (!panel.finishTime) {
                    completedDate = <AvantBadgeSectionInProgress/>

                    if (panel.skill === PanelSkillEnum.WRITING && isHandWritten) {
                        completedDate = ""
                    }
                }

                let startDate: string | React.ReactNode = TimeUtil.formatMilliToMonthDayYearHoursMinutesLong(Number(panel.startTime))
                if (!panel.startTime) {
                    startDate = <AvantBadgeNotStarted/>
                }

                let panelLevel: React.ReactNode = productId === STAMP_CEFR_PRODUCT_ID.value() ? panel.benchmark : getPanelStatus(panel.level)
                let scaledScore: React.ReactNode | undefined = panel.scaledScore

                // Prepare accuracy details (Detailed Percentage Score) for display:
                let accuracyDetails: string = ""
                if (isGrammerOrReading(panel.skill) && panel.finishTime && isValidAccuracyNumbers(panel.itemsAccurateCount, panel.itemsTakenCount, panel.percentCorrect)) {
                    accuracyDetails = `${panel.itemsAccurateCount}/${panel.itemsTakenCount} = ${panel.percentCorrect}%`
                }

                if (panelLevel === undefined) {
                    panelLevel = <AvantBadgeNotStarted />
                    scaledScore = undefined
                    completedDate = undefined
                }

                panelData.push({
                    panelDisplayName: panelDisplayName,
                    skill: panel.skill,
                    panelLevel: panelLevel,
                    scaledScore: scaledScore,
                    selfEvaluated: panel.selfEvaluated,
                    accuracyDetails: accuracyDetails,
                    startDate: startDate,
                    completedDate: completedDate,
                    totalTime: totalTime,
                    takePanelId: panel.takePanelId
                })
            })
            setPanelData(panelData)
        }
    },[sortedPanels])

    if (panelData.length === 0) return <AvantCircularProgress size={CircularProgressSize.SMALL} />

    return (
        <>
            {panelData.map((panel: PanelDisplayData) => {
                const {skill, panelDisplayName, panelLevel, scaledScore, accuracyDetails, startDate, completedDate, totalTime, takePanelId} = panel

                return (
                    <Accordion key={skill} style={{margin: 0}} defaultExpanded={sidebarStore.openPanel === skill}>
                        <AccordionSummary
                            classes={expandedClasses}
                            expandIcon={<ArrowDropDown style={{fill: AvantColors.REPORT_FONT_COLOR_GRAY_3}} />}
                        >
                            <H5Text>{panelDisplayName}</H5Text>
                        </AccordionSummary>

                        <AccordionDetails style={{flexDirection: "column", padding: 0}}>
                            <Divider />
                            <SidebarList>
                                <SidebarListItem primary={"Status/Score"} secondary={panelLevel}/>
                                {
                                    (panel.skill === PanelSkillEnum.READING || panel.skill === PanelSkillEnum.LISTENING || panel.skill === PanelSkillEnum.RECEPTIVE) &&
                                    panel.selfEvaluated &&
                                    <SidebarListItem primary={"Scaled Score:"} secondary={scaledScore}/>
                                }
                                {
                                    productId === PLACE_PRODUCT_ID.value() &&
                                        <SidebarListItem primary={"Detailed Percentage Score:"} secondary={accuracyDetails}/>
                                }
                                <SidebarListItem primary={"Section Start Time:"} secondary={startDate}/>
                                <SidebarListItem primary={"Total Time in Section:"} secondary={totalTime}/>
                                <SidebarListItem primary={"Section Completed Date:"} secondary={completedDate}/>
                            </SidebarList>

                            {panel.takePanelId !== 0 && [PanelSkillEnum.WRITING, PanelSkillEnum.SPEAKING, PanelSkillEnum.SPEAKING_ORAL, PanelSkillEnum.EXPRESSIVE].includes(panel.skill as PanelSkillEnum) && (
                                <SidebarResponsesContainer takePanelId={takePanelId} fullName={fullName}/>
                            )}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </>
    )
}

export default TestTakerSidebarPanels
