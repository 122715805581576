import {MenuItem, Select} from "@material-ui/core"
import * as React from "react"
import {PACKAGE_TO_FULL_NAME, SSOLicenseCount, SsoTeacherClass} from "../../../../types/types"
import {ChangeEvent} from "react"

interface SSOBulkActionProps {
    rowData: SsoTeacherClass,
    tableId: number,
    selectedPackage: string | null,
    handleChange: (e:ChangeEvent<{name?:string, value:unknown}>, rowData: any, index: number) => void
    licenses_sold: SSOLicenseCount[] | null

}

export const LicenseSelect: React.FC<SSOBulkActionProps> = (props) => {
    // @ts-ignore
    return (
        <Select id="testingLanguage" value={(props.selectedPackage) ? props.selectedPackage : 0} onChange={(e) => props.handleChange(e, props.rowData, props.tableId)}>
            <MenuItem key={0} value={0}>Select Test Package</MenuItem>)
            { props.licenses_sold && (
                props.licenses_sold
                    .filter(license => license.productPackage !== "hw")
                    .map ((license) => {
                        const displayValue = (license.productPackage && PACKAGE_TO_FULL_NAME[license.productPackage]) ? PACKAGE_TO_FULL_NAME[license.productPackage] : ""
                        return (
                            <MenuItem
                                key={license.productPackage ? license.productPackage : ""}
                                value={license.productPackage ? license.productPackage : ""}
                            >
                                {displayValue}
                            </MenuItem>
                        )
                    })
            )}
        </Select>
    )
}
