/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {Grid} from "@material-ui/core"
import React from "react"
import {
    Bar,
    BarChart,
    Cell,
    LabelProps,
    Rectangle,
    ReferenceArea,
    Text,
    TextProps,
    XAxis,
    YAxis
} from "recharts"
import {AvantColors} from "../../../../../styles/AvantColors"
import {LabelText, MicroText} from "../../../../../styles/AvantTypography"
import {ProductId} from "../../../../../types/types"
import {SummarySkillGraph} from "../../../district-school/models/ReportSummaryGraphs"
import {determineBarFill} from "./BarUtils"
import {STAMP_CEFR_PRODUCT_ID} from "../../../../../util/Constants"
import {Margin} from "recharts/types/util/types"

interface ChartBackgroundProperties {
    start: number
    end: number
    adjustY: boolean
    x: number       // The x coordinate of the top left corner of the rectangle (adjusted visually for each graph)
    y: number       // The y coordinate of the top left corner of the rectangle (adjusted visually for each graph)
    height: number  // The height of the rectangle (adjusted visually for each graph)
}
interface DistributionChartProps {
    skillData: SummarySkillGraph,
    productId: number,
    // greyAreas: { start: number, end: number, adjustY: boolean }[]
    greyAreas: ChartBackgroundProperties[]

}

interface ProductDistributionChartProps {
    skillData: SummarySkillGraph,
    productId: ProductId
}

const isComputerScored = (skillName: string) => ["Reading", "Listening", "Receptive"].indexOf(skillName) !== -1

export const DistributionChart: React.FC<ProductDistributionChartProps> = (props: ProductDistributionChartProps) => {
    const {skillData, productId} = props
    let greyAreas: ChartBackgroundProperties[]

    switch (productId) {
        case ProductId.STAMP4S:
            greyAreas = isComputerScored(skillData.skillName) ? [
                {start: 0, end: 2, adjustY: false, x: 2, y: 3, height: 60},
                {start: 7, end: 9, adjustY: true, x: 2, y: 148, height: 63}
            ] : [
                {start: 1, end: 3, adjustY: false, x: 2, y: 3, height: 44},
                {start: 6, end: 8, adjustY: true, x: 2, y: 141, height: 63}
            ]

            break
        case ProductId.STAMP_CEFR:
            greyAreas = isComputerScored(skillData.skillName) ? [
                {start: 0, end: 2, adjustY: true, x: 2, y: 3, height: 60},
                {start: 7, end: 9, adjustY: true, x: 2, y: 123, height: 73}
            ] : [
                {start: 0, end: 2, adjustY: true, x: 2, y: 3, height: 60},
                {start: 6, end: 8, adjustY: true, x: 2, y: 123, height: 73}
            ]
    
            break
        case ProductId.STAMP_SIGN_LANGUAGE:
            greyAreas = isComputerScored(skillData.skillName) ? [
                {start: 0, end: 2, adjustY: false, x: 2, y: 3, height: 60},
                {start: 7, end: 9, adjustY: true, x: 2, y: 148, height: 63}
            ] : [
                {start: 1, end: 3, adjustY: false, x: 2, y: 3, height: 44},
                {start: 6, end: 8, adjustY: true, x: 2, y: 141, height: 63}
            ]

            break
        case ProductId.STAMP4SE:
            greyAreas = (isComputerScored(skillData.skillName)) ?
                [
                    {start: 5, end: 7, adjustY: false, x: 2, y: 110, height: 63},
                ] : [

                    {start: 1, end: 3, adjustY: false, x: 2, y: 3, height: 44},
                    {start: 6, end: 8, adjustY: true, x: 2, y: 141, height: 63}
                ]

            break
        case ProductId.APT:
            greyAreas = isComputerScored(skillData.skillName) ? [
                {start: 0, end: 2, adjustY: false, x: 2, y: 23, height: 59},
                {start: 7, end: 9, adjustY: true, x: 2, y: 167, height: 59}
            ] : [
                {start: 0, end: 2, adjustY: false, x: 2, y: 23, height: 59},
                {start: 7, end: 9, adjustY: true, x: 2, y: 167, height: 59}
            ]

            break
        default:
            throw new Error(`Unsupported product ${productId} wants a distro chart`)
    }

    return <DistributionChartContent productId={productId} skillData={skillData} greyAreas={greyAreas}/>
}

const DistributionChartContent: React.FC<DistributionChartProps> = (props: DistributionChartProps) => {
    let count = 0
    let data: { level: string, percent: number }[] = []
    let levelCounts: number[] = []

    const {skillData, greyAreas, productId} = props
    const {testTakerQuantity, levels, levelToCount} = skillData
    data = []
    levelCounts = []
    levels.forEach(level => {
        const countForThisLevel = levelToCount.find(it => it.level === level)
        const countForLevel = countForThisLevel == null ? 0 : countForThisLevel.count
        const percent = (countForLevel / testTakerQuantity) * 100
        data.push({level, percent})
        levelCounts.push(countForLevel)
    })

    count = testTakerQuantity

    // console.log("**********************************************************************")
    // console.log(`skillData: ${skillData.skillName}`)
    // console.log(`Grey Area: ${JSON.stringify(greyAreas)}`)
    // console.log("**********************************************************************")

    const elementHeight = 220
    const elementWidth = 240
    const maxBarLength = 135 // This will be used as the "domain" of the x-axis, which will make 100% a little
    // "shorter" so we can fit the count ratios. This is the domain that fit 5 digit counts
    // juuust barely.

    const isCefr: boolean = productId === STAMP_CEFR_PRODUCT_ID.value()

    const margin: Margin = {
        top: 5,
        bottom: 5,
        left: isCefr ? 15 : 10,
        right: 5,
    }

    return (
        <Grid item={true} style={{paddingRight: "30px", paddingLeft: "30px"}}>
            <BarChart
                width={elementWidth}
                height={elementHeight}
                data={data}
                layout={"vertical"}
                margin={margin}
            >

                {greyAreas.map(g => (
                    <ReferenceArea
                        key={`greyarea-${g.start}-${g.end}-${g.adjustY}`}
                        y1={g.start}
                        y2={g.end}
                        fill={AvantColors.REPORT_BACKGROUND_GRAY_LIGHT}
                        fillOpacity={1}
                        ifOverflow={"hidden"}
                        shape={(props: DistReferenceAreaProps) => <DistReferenceArea adjustY={g.adjustY} x={g.x} y={g.y} height={g.height} width={233} fill={AvantColors.REPORT_BACKGROUND_GRAY_LIGHT} />}
                    />
                ))}

                <YAxis
                    tickFormatter={(levelTickLabel: string) => {
                        // Each spacing level needs to be unique, so we only check if it STARTS with the word SPACING
                        if (levelTickLabel.startsWith("SPACING")) {
                            return ""
                        }

                        if (isCefr) {
                            const cefrLabels: string[] = ["PA1(1)", "A1(2)", "A2(3)", "B1(4)", "B2(5)", "C1(6)"]
                            const levelAsNumber: number = Number(levelTickLabel)

                            if (!isNaN(levelAsNumber) && levelAsNumber > 0) {
                                return cefrLabels[levelAsNumber-1]
                            }
                        }

                        return levelTickLabel
                    }}
                    axisLine={false}
                    tickLine={false}
                    dataKey="level"
                    type={"category"}
                    width={22}
                    tickMargin={isCefr ? 0 : 12}
                    tick={{
                        fontSize: "1.2rem",
                        fill: AvantColors.REPORT_FONT_COLOR_GRAY_3,
                        textAnchor: "end",
                    }}
                    minTickGap={0}
                    interval={0} // This is to ensure that all numbers are rendered. For APT specifically, charts would alternate between odd and even numbered labels.
                />

                <XAxis
                    hide={true}
                    type={"number"}
                    domain={[0, maxBarLength]}
                />
                <Bar
                    dataKey="percent"
                    radius={[0, 4, 4, 0]}
                    label={
                        (props: LabelProps & BarPctLabelProps) => (
                            <BarPctLabel
                                {...props}
                                levelCounts={levelCounts}
                                count={count}
                                elementWidth={elementWidth}
                                data={data}
                            />
                        )
                    }
                    isAnimationActive={false}
                >
                    {
                        data.map((entry: { level: string }) => {
                            return (<Cell key={`${entry.level}`} fill={determineBarFill(entry.level)}/>)
                        })
                    }
                </Bar>
            </BarChart>
            <div style={{textAlign: "center"}}>
                <LabelText>
                    {skillData.skillName}
                </LabelText>
                <MicroText>
                    {count} Test Takers
                </MicroText>
            </div>
        </Grid>
    )

}

interface DistReferenceAreaProps {
    adjustY: boolean
    width: number
    height: number
    x: number
    y: number
    fill: string
}

// @ts-ignore
const DistReferenceArea: React.FC<DistReferenceAreaProps> = ({x, y, width, height, adjustY, fill}) => {


    const x1 = 2
    const x2 = width + x - x1

    let adjustedY = y
    if (adjustY) {
        adjustedY -= height / 2
    }

    // console.log("**********************************************************************")
    // console.log("y: " + y)
    // console.log("y1: " + y1)
    // console.log("y2: " + y2)
    // console.log("height: " + height)
    // console.log("width: " + width)
    // console.log(`AdjustedY: ${adjustY}`)
    // console.log(`x1: ${x1}`)
    // console.log(`adjustedY: ${adjustedY}`)
    // console.log(`x2: ${x2}`)
    // console.log(`ViewBox: ${JSON.stringify(viewBox)}`)
    // console.log(`fill: ${fill}`)
    // console.log("**********************************************************************")

    return (
        <>
            <Rectangle x={x1} y={adjustedY} width={x2} height={height} fill={fill} fillOpacity={1}/>
            <line
                x1={x1}
                x2={x2 + x1}
                y1={adjustedY}
                y2={adjustedY}
                stroke={AvantColors.REPORT_REFERENCE_LINE_GRAY}
                strokeWidth={1}
                opacity={0.5}
            />
            <line
                x1={x1}
                x2={x2 + x1}
                y1={adjustedY + height}
                y2={adjustedY + height}
                stroke={AvantColors.REPORT_REFERENCE_LINE_GRAY}
                strokeWidth={1}
                opacity={0.5}
            />
        </>
    )
}

interface BarPctLabelProps {
    value: number
    count: number
    levelCounts: number[]
    index: number
    width: number
    height: number
    x: number
    y: number
    elementWidth: number
    data: { level: string, percent: number }[]
}

const BarPctLabel: React.FC<BarPctLabelProps & LabelProps> = (props) => {
    const xPlacementThreshold = 26
    let xLabelPadding = 4
    let labelAnchor: TextProps["verticalAnchor"] = "start"

    const labelIsInsideBar = props.value >= xPlacementThreshold
    let pctLabelColor = AvantColors.REPORT_FONT_COLOR_GRAY_3

    if (labelIsInsideBar) {
        xLabelPadding = -xLabelPadding
        labelAnchor = "end"

        pctLabelColor = AvantColors.REPORT_FONT_COLOR_WHITE

        // Get the current level.
        let level: string | number = props.data.find((_, idx: number) => idx === props.index)!!.level
        level = Number(level) // The level is a string, so we need to convert it to a number.

        // levels 1-3 need a different inside color
        if (level && (level >= 1 && level <= 3)) {
            pctLabelColor = AvantColors.NOVICE_FILL_TEXT
        }
    }

    const truncatedPct = Math.trunc(props.value)

    const totalsCount = props.count
    const pctCount = props.levelCounts[props.index] || 0

    const pct = (pctCount > 0 && truncatedPct < 1) ? "<1%" : `${truncatedPct}%`

    const fontSize = "1.2rem"

    const ratioString = (totalsCount > 0 && pctCount > 0) ? `${pctCount}/${totalsCount}` : ''
    return (props.value === 0) ? null : (
        <>
            <Text
                x={props.width + props.x + xLabelPadding}
                y={props.y + (props.height / 2)}
                dy={4}
                fill={pctLabelColor}
                textAnchor={labelAnchor}
                style={{fontSize}}
            >
                {`${pct}`}
            </Text>
            <Text
                x={props.elementWidth - 7}
                y={props.y + (props.height / 2)}
                dy={4}
                fill={AvantColors.REPORT_FONT_COLOR_GRAY_3}
                textAnchor={"end"}
                style={{fontSize}}
            >
                {ratioString}
            </Text>
        </>
    )
}
