import {Grid} from "@material-ui/core"
import createStyles from "@material-ui/core/styles/createStyles"
import makeStyles from "@material-ui/core/styles/makeStyles"
import * as React from "react"
import {ItemContent} from "../../app/common/item/models/ItemContent"
import {ProductDriver} from "../../app/common/products/ProductStore"
import HelperService from "../../services/HelperService"
import {IItem, VideoContent} from "../../types/types"

const InstructionPDFStyles = makeStyles(() =>
    createStyles({
        root_InstructionPDF: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
    })
)

interface InstructionPDFProps {
    className: string
    item: IItem
    itemContent: ItemContent
    isAdmin: boolean
    driver: ProductDriver
    handleUpdateItemFile: (itemContentId: number, content: string | VideoContent) => void
}

export const InstructionPDF: React.FC<InstructionPDFProps> = ({className, item, itemContent, isAdmin, driver, handleUpdateItemFile}) => {

    const classes = InstructionPDFStyles()

    const contentDir = "common"
    const url = HelperService.getFullMediaUrl(contentDir, "pdf", itemContent.content!.toString(), driver.config.MEDIA_BASE_URL)

    return (
        <div key={itemContent.id} className={classes.root_InstructionPDF}>
            <Grid
                container={true}
                style={{textAlign: "center"}}
            >
                <Grid
                    item={true}
                    xs={12}
                >
                    <label>{item.description}</label>
                </Grid>
                <Grid
                    item={true}
                    xs={12}
                >
                    <div>
                        <object
                            data={url}
                            type={"application/pdf"}
                            width={600}
                            height={550}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    )

}
