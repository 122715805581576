import axios, {AxiosError, AxiosResponse} from "axios"
import ApiService from "../../../services/ApiService"
import {log} from "../../../util/Logging"
import {GroupMaster} from "../../common/group/models/GroupMaster"
import {PanelSkill} from "../class/components/TestTakerSortableTable"
import {
    PlaceSearchFilters,
    PlaceTestTakerInstanceReport
} from "../common/components/AvantNewDatePicker/AvantNewDateRangePickerHelpers"
import {SetStateAction} from "react"
import store from "../../../redux/store"
import {setNoConnection} from "../../../redux/app/actions"

// Notice the header value is in all lowercase, this is because headers appear to be lower cased.
export const FILE_DOWNLOAD_NAME_HEADER_KEY = "x-avant-csv-file-name"

export default class ApiServicePlace {

    static getChildGroups = async (parentId: number): Promise<GroupMaster[]> => {

        try {
            const childGroups = await axios.get(`${ApiService.API_URL}groupmasters/${parentId}/subgroups`)
            return childGroups.data
        } catch (e) {
            log.error(`Error getting child groups from Group Master Store`)
            return []
        }

    }

    static getTestInstanceList = async (filters: PlaceSearchFilters): Promise<PlaceTestTakerInstanceReport[]> => {

        try {
            const result = await axios.post(`${ApiService.API_URL}new-reports/place`, filters)
            return result.data.data
        } catch (e) {
            const err = e as Error
            log.error("Error in PLace Reports: " + err.message)
            return []
        }
    }

    static getTestInstance = async (takeId: number): Promise<PlaceTestTakerInstanceReport | null | undefined> => {

        try {
            const result = await axios.get(`${ApiService.API_URL}new-reports/place/individual/${takeId}`)
            return result.data
        } catch (e) {
            const err = e as Error
            log.error("Error in PLace Reports: " + err.message)
            return null
        }
    }

    static getAllSkillsForInstancesSearched = async (groupIds: number[]): Promise<PanelSkill[]> => {
        try {
            const result = await axios.get(`${ApiService.API_URL}panels/panelSkills`, {
                // TODO: make this so it supports a list of loginIDs
                params: {
                    groupIds: groupIds.join(",")
                }

            })
            return result.data.data
        } catch (e) {
            const err = e as Error
            log.error("Error in PLace Reports: " + err.message)
            // return {panelGraphPackage: [], panelSkills: []}
            return []
        }
    }

    static getCsv = (setLoading: React.Dispatch<SetStateAction<boolean>>, filters?: PlaceSearchFilters): Promise<AxiosResponse> | undefined => {

        if (filters == null) {
            // this should never happen
            return undefined
        }
        return new Promise<AxiosResponse>(((resolve, reject) => {
            setLoading(true)
            axios.post(`${ApiService.API_URL}download/place-report`, filters).then(

                (res: AxiosResponse) => {

                    const data = res.data

                    // Setup the download link
                    const downloadUrl = window.URL.createObjectURL(new Blob([data], {type: "text/csv;charset=utf8;"}))
                    const link = document.createElement("a")
                    const fileName = res.headers[FILE_DOWNLOAD_NAME_HEADER_KEY] != null ? res.headers[FILE_DOWNLOAD_NAME_HEADER_KEY] : "file.csv"
                    link.href = downloadUrl
                    link.setAttribute("download", fileName) // any other extension
                    document.body.appendChild(link)

                    link.click()
                    link.remove()
                    setLoading(false)
                    resolve(res)
                },
                (err: AxiosError) => {
                    setLoading(false)
                    if (err.response === undefined) {
                        // @ts-ignore
                        store.dispatch(setNoConnection(true))
                    } else {
                        // TODO figure out how to reject correctly
                        reject(err)
                    }
                }
            )
        }))
    }

}
