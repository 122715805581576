import React from "react"

interface IComponentProps {
    maxCapacity: number
    currentCapacity: number
    filledColorCssClass: string
}

export default class VolumeSegment extends React.Component<IComponentProps, {}> {

    render() {
        const color =
            this.props.currentCapacity >= this.props.maxCapacity
                ? this.props.filledColorCssClass
                : "VolumeSegment--color-grey"
        const cssClasses = "VolumeSegment " + color

        return (
            <span>
                <div className={cssClasses} />
            </span>
        )
    }
}
