import {ProductDriver} from "../../app/common/products/ProductStore"
import {ApiLicenseKey, ApiPanelGraph, IAction, Language, LoginProductContentAreaData, ModulePanel, Profile, Take, TestState} from "../../types/types"

export declare interface ISetNoConnectionAction extends IAction {
    result: boolean
}

export interface IUpdateTakeAction extends IAction {
    result: Take
}

export interface IUpdateCurrentPanelGraphIdAction extends IAction {
    result: number
}

export interface IUserLogoutAction extends IAction {
    result: null
}

export interface ISetShowAuthUserExpireModalAction extends IAction {
    result: boolean
}

export interface ISetShowPanelTimeoutModalAction extends IAction {
    result: boolean
}

export interface ISetHideLoadingSpinnerAction extends IAction {
    result: boolean
}

export interface ISetStopAllAction extends IAction {
    result: boolean
}

export interface ISetTestStateAction extends IAction {
    result: TestState
}

export interface IProfileSuccessAction extends IAction {
    result: Profile
}

export interface ISetTakeCodeAction extends IAction {
    result: string
}

export interface IUpdateTakeAction extends IAction {
    result: Take
}

export interface ISetTestStateAction extends IAction {
    result: TestState
}

export interface ISetPanelGraphAction extends IAction {
    result: ApiPanelGraph
}

export interface ISetCurrentPanelAction extends IAction {
    result: number
}

export interface ISetLanguagesAction extends IAction {
    result: {}
}

export interface ISetCurrentPanelIndexAction extends IAction {
    result: number
}

export interface ISetNextPanelIdAction extends IAction {
    result: number | null
}

export interface ISetPanelsAction extends IAction {
    result: ModulePanel[]
}

export interface ISetIsCertificationTestAction extends IAction {
    result: boolean
}

export interface ISetCurrentLanguageIdAction extends IAction {
    result: number
}

export interface IIsTrainingOnlyModuleAction extends IAction {
    result: boolean
}

export interface ISetTakeAction extends IAction {
    result: Take
}

export interface ISetLicenseKey extends IAction {
    result: ApiLicenseKey
}


export interface ISetLoginProductData extends IAction {
    result: LoginProductContentAreaData
}

export interface ISetDriver extends IAction {
    result: ProductDriver
}

export interface ISetAdvanceReportDistrictId extends IAction {
    result: number
}

export interface ISetAdvanceReportLanguage extends IAction {
    result: string
}

export interface ISetAdvanceReportSchool extends IAction {
    result: string
}

export const SET_NO_CONNECTION = "SET_NO_CONNECTION"
export const AUTHENTICATE_SUCCESS = "AUTHENTICATE_SUCCESS"
export const UPDATE_LAST_TOKEN = "UPDATE_LAST_TOKEN"
export const UPDATE_TAKE = "UPDATE_TAKE"
export const UPDATE_CURRENT_PANEL_GRAPH_ID = "UPDATE_CURRENT_PANEL_GRAPH_ID"
export const SET_SHOW_AUTH_USER_EXPIRE_MODAL = "SET_SHOW_AUTH_USER_EXPIRE_MODAL"
export const SET_SHOW_PANEL_TIMEOUT_MODAL = "SET_SHOW_PANEL_TIMEOUT_MODAL"
export const SET_HIDE_LOADING_SPINNER = "SET_HIDE_LOADING_SPINNER"
export const SET_STOP_ALL = "SET_STOP_ALL"
export const SET_TEST_STATE = "SET_TEST_STATE"
export const USER_LOGOUT = "USER_LOGOUT"
export const POST_PROFILE_SUCCESS = "POST_PROFILE_SUCCESS"
export const POST_PROFILE_ERROR = "POST_PROFILE_ERROR"
export const SET_TAKE_CODE = "SET_TAKE_CODE"
export const SET_CURRENT_PANEL_ID = "SET_CURRENT_PANEL_ID"
export const SET_LANGUAGES = "SET_LANGUAGES"
export const SET_CURRENT_PANEL_INDEX = "SET_CURRENT_PANEL_INDEX"
export const SET_NEXT_PANEL_ID = "SET_NEXT_PANEL_ID"
export const SET_PANELS = "SET_PANELS"
export const SET_IS_CERTIFICATION_TEST = "SET_IS_CERTIFICATION_TEST"
export const SET_CURRENT_LANGUAGE_ID = "SET_CURRENT_LANGUAGE_ID"
export const SET_IS_TRAINING_ONLY_MODULE = "SET_IS_TRAINING_ONLY_MODULE"
export const SET_PANEL_GRAPH = "SET_PANEL_GRAPH"
export const SET_TAKE = "SET_TAKE"
export const SET_LICENSE_KEY = "SET_LICENSE_KEY"
export const SET_PRODUCT_CHOICES = "SET_PRODUCT_CHOICES"
export const SET_TAKE_UUID = "SET_TAKE_UUID"
export const SET_LOGOUT_MESSAGE = "SET_LOGOUT_MESSAGE"
export const SET_LOGIN_PRODUCT_DATA = "SET_LOGIN_PRODUCT_DATA"
export const SET_DRIVER = "SET_DRIVER"
export const SET_API_CLIENT = "SET_API_CLIENT"
export const SET_ADVANCE_REPORT_DISTRICT_ID = "SET_ADVANCE_REPORT_DISTRICT_ID"
export const SET_ADVANCE_REPORT_LANGAUGE = "SET_ADVANCE_REPORT_LANGAUGE"
export const SET_ADVANCE_REPORT_SCHOOL = "SET_ADVANCE_REPORT_SCHOOL"

export function setNoConnection(noConnection: boolean): ISetNoConnectionAction {
    return {
        type: SET_NO_CONNECTION,
        result: noConnection
    }
}

export function userLogout(): IUserLogoutAction {
    return {
        type: USER_LOGOUT,
        result: null
    }
}

export function updateTake(take: Take): IUpdateTakeAction {
    return {
        type: UPDATE_TAKE,
        result: take
    }
}

export function updateCurrentPanelGraphId(currentPanelGraphId: number): IUpdateCurrentPanelGraphIdAction {
    return {
        type: UPDATE_CURRENT_PANEL_GRAPH_ID,
        result: currentPanelGraphId
    }
}

export function setShowAuthUserExpireModal(showModal: boolean): ISetShowAuthUserExpireModalAction {
    return {
        type: SET_SHOW_AUTH_USER_EXPIRE_MODAL,
        result: showModal
    }
}

export function setShowPanelTimeoutModal(showModal: boolean): ISetShowPanelTimeoutModalAction {
    return {
        type: SET_SHOW_PANEL_TIMEOUT_MODAL,
        result: showModal
    }
}

export function setHideLoadingSpinner(hideLoadingSpinner: boolean): ISetHideLoadingSpinnerAction {
    return {
        type: SET_HIDE_LOADING_SPINNER,
        result: hideLoadingSpinner
    }
}

export function setStopAll(stopAll: boolean): ISetStopAllAction {
    return {
        type: SET_STOP_ALL,
        result: stopAll
    }
}

export function setTestState(testState: TestState): ISetTestStateAction {
    return {
        type: SET_TEST_STATE,
        result: testState
    }
}

export function postProfileSuccess(profile: Profile): IProfileSuccessAction {
    return {
        type: POST_PROFILE_SUCCESS,
        result: profile
    }
}

export function setTakeCode(takeCode: string): ISetTakeCodeAction {
    const rosterBypassPrefix = "ADD$"
    return {
        type: SET_TAKE_CODE,
        result: takeCode.replace(rosterBypassPrefix, "")
    }
}

export function setPanelGraph(panelGraph: ApiPanelGraph): ISetPanelGraphAction {
    return {
        type: SET_PANEL_GRAPH,
        result: panelGraph
    }
}

export function setCurrentPanelId(currentPanelId: number): ISetCurrentPanelAction {
    return {
        type: SET_CURRENT_PANEL_ID,
        result: currentPanelId
    }
}

export function setLanguages(languages: Map<number, Language>): ISetLanguagesAction {
    return {
        type: SET_LANGUAGES,
        result: languages
    }
}

export function setCurrentPanelIndex(currentPanelIndex: number): ISetCurrentPanelIndexAction {
    return {
        type: SET_CURRENT_PANEL_INDEX,
        result: currentPanelIndex
    }
}

export function setNextPanelId(nextPanelId: number | null): ISetNextPanelIdAction {
    return {
        type: SET_NEXT_PANEL_ID,
        result: nextPanelId
    }
}

export function setPanels(panels: ModulePanel[]): ISetPanelsAction {
    return {
        type: SET_PANELS,
        result: panels
    }
}

export function setIsCertificationTest(isCertificationTest: boolean): ISetIsCertificationTestAction {
    return {
        type: SET_IS_CERTIFICATION_TEST,
        result: isCertificationTest
    }
}

export function setCurrentLanguageId(currentLanguageId: number): ISetCurrentLanguageIdAction {
    return {
        type: SET_CURRENT_LANGUAGE_ID,
        result: currentLanguageId
    }
}

export function setIsTrainingOnlyModule(isTrainingOnlyModule: boolean): IIsTrainingOnlyModuleAction {
    return {
        type: SET_IS_TRAINING_ONLY_MODULE,
        result: isTrainingOnlyModule
    }
}

export function setTake(take: Take): ISetTakeAction {
    return {
        type: SET_TAKE,
        result: take
    }
}

export function setLicenseKey(licenseKey: ApiLicenseKey): ISetLicenseKey {
    return {
        type: SET_LICENSE_KEY,
        result: licenseKey
    }
}

export function setLoginProductData(data: LoginProductContentAreaData): ISetLoginProductData {
    return {
        type: SET_LOGIN_PRODUCT_DATA,
        result: data
    }
}

export function setDriver(driver: ProductDriver): ISetDriver {
    return {
        type: SET_DRIVER,
        result: driver
    }
}

export function setAdvanceReportDistrictId(districtId: number): ISetAdvanceReportDistrictId {
    return {
        type: SET_ADVANCE_REPORT_DISTRICT_ID,
        result: districtId
    }
}

export function setAdvanceReportLanguage(language: string): ISetAdvanceReportLanguage {
    return {
        type: SET_ADVANCE_REPORT_LANGAUGE,
        result: language
    }
}

export function setAdvanceReportSchool(school: string): ISetAdvanceReportSchool {
    return {
        type: SET_ADVANCE_REPORT_SCHOOL,
        result: school
    }
}
