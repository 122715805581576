import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {authStore} from "../common/authentication/AuthStore"
import {productStore} from "../common/products/ProductStore"
import {NYCDOE_SSO_LOGIN_PRODUCT_ID} from "../../util/Constants"
import {LoginProductContentAreaData} from "../../types/types"
import {Redirect} from "react-router"
import {loadingSpinnerStore} from "../common/loaders/LoadingSpinnerStore"
import {prettyJson} from "../../util/Logging"
import {NycDoeOauthResponse} from "./NYCDOEUtils"


// NYCDOE OATUH LOGIN ENDPOINT: https://idpstg.ice.ibmcloud.com/

const SSOLoginPageForNYCDOE: React.FC = observer(() => {

    // Show the loading spinner...
    loadingSpinnerStore.hideLoadingSpinner = false
    const isCancelled = React.useRef(false)
    const [qParams, setQParams] = React.useState<string | undefined>(undefined)
    const [loggedInUser, setLoggedInUser] = React.useState<string>("U")
    const [fetchComplete, setFetchComplete] = React.useState(false)

    const checkAndClearLocalStorage = () => {
        authStore.logout()
    }

    const getQueryParams = async () => {
        if(qParams==null){
            // const vals = window.location.search
            const vals = window.location.hash.slice(1)
            const urlParams = new URLSearchParams(vals);
            const nycDoeOauthResponse: NycDoeOauthResponse = {
                accessToken: urlParams.get('access_token'),
                code: urlParams.get('code') ,
                scope: urlParams.get('scope'),
                grantId: urlParams.get('grant_id'),
                idToken: urlParams.get('id_token'),
                tokenType: urlParams.get('token_type'),
                expiresIn: Number(urlParams.get('expires_in')),
            }
            if(nycDoeOauthResponse.accessToken !== undefined && nycDoeOauthResponse.accessToken !== null) {
                await sendCodeToAPI(nycDoeOauthResponse).then(() => {
                    if (!isCancelled.current) {
                        setQParams("Done")
                    }
                })
            }
        }
    }

    const sendCodeToAPI = async (nycDoeOauthResponse: NycDoeOauthResponse) => {

        checkAndClearLocalStorage()

        console.log("Made it to send code to API")

        const res = await authStore.ssoAuthenticateNYCDOE(nycDoeOauthResponse)
        if (res.loginId === -1) {
            setLoggedInUser("U")
            setFetchComplete(true)
            return "U"
        }

        console.log(prettyJson(res.sso))

        let userT: string = ""
        let role=res.sso.nycDoeRole
        if(role !== null) {
            let productName:string = "Dashboard"

            switch (role) {
                case "STAFF" :
                    userT = "T"
                    productName = "Teacher Dashboard"
                    break;
                case "STUDENT" :
                    userT = "S"
                    productName = "Student Dashboard"
                    break;
                case "administrator" :
                    userT = "D"
                    productName = "Admin Dashboard"
                    break;
                case "school_administrator" :
                    userT = "D"
                    productName = "Admin Dashboard"
                    break;
                default:
                    userT = "U"
                    break;

            }
            setLoggedInUser(userT)

            // console.log(`UserT: ${userT}`)

            if (userT !== 'U') {
                const lpInfo: LoginProductContentAreaData = {
                    loginId: res.loginId,
                    userType: userT,
                    productName: productName,
                    productId: NYCDOE_SSO_LOGIN_PRODUCT_ID.value(),
                    contentAreaId: 0,
                    contentAreaName: "None",
                    rostered: true,
                    userName: authStore.username
                }

                productStore.setLoginProduct(lpInfo)

                setFetchComplete(true)

            } else {
                // TODO this is an error case
            }

        } else {
            setLoggedInUser("U")
        }
        setFetchComplete(true)
        return userT
    }


    useEffect( () => {
        getQueryParams()
        return () => {
            isCancelled.current = true
        }
    }, [])

    if (fetchComplete) {
        loadingSpinnerStore.hideLoadingSpinner = true
        if (loggedInUser === "T") {
            return <Redirect to={"/sso/teacher"} />
        } else if (loggedInUser === "S") {
            return <Redirect to={"/sso/student"} />
        } else if (loggedInUser === "D") {
            return <Redirect to={"/sso/distadmin"} />
        } else {
            return <Redirect to={"/sso/sso_unauthorized"} />
        }
    } else {
        // TODO this is an error case or unneeded
    }

    return <></>
})
export default SSOLoginPageForNYCDOE
