/*
 * ******************************************************
 *  * Copyright (C) 2010-Present Avant Assessment
 *  * All Rights Reserved
 *  ******************************************************
 */

import {
    SamlSSOWelcomeCenterStartTestResponse,
    SamlWelcomeCenterAssessmentList
} from "../app/sso/SAML/SAMLWelcomeCenterContainer"
import axios, {AxiosResponse} from "axios"
import {ApiErrorResponse, LoginProductContentAreaData} from "../types/types"
import ApiService from "./ApiService"

interface AuthenticationPortalData {
    token: string
    reportUrl: string
    loginProductData: LoginProductContentAreaData
}

interface AuthenticationPortalResponse {
    data: AuthenticationPortalData
    message: string
}

export default class SAMLApiService {
    static rosterOrFetchAssessmentAndRedirect(contentAreaId: number, productPackage: string, language: string, customClassMapName: string): Promise<SamlSSOWelcomeCenterStartTestResponse> {
        const url: string = `${ApiService.API_URL}sso/saml/student/welcome-center/roster-custom-student`

        const params = {
            language: language,
            contentAreaId: contentAreaId,
            productPackage: productPackage,
            customClassMapName: customClassMapName,
        }

        return new Promise((resolve, reject) => {
            ApiService.get(url, false,{params}).then(
                (res: AxiosResponse) => {
                    resolve(res.data.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })

    }

    static getSSOWelcomeCenterListSAML(): Promise<{ [key: string]: SamlWelcomeCenterAssessmentList[] }> {
        const url: string = `${ApiService.API_URL}sso/saml/student/welcome-center`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static portalToReportAccess(jwtToken: string, groupId: number, productId: number): Promise<AuthenticationPortalResponse> {
        const url: string = `${ApiService.API_URL}sso-report-access/${groupId}/${productId}`

        axios.defaults.headers.common["X-Avant-Auth-Token"] = jwtToken
        return new Promise((resolve, reject) => {
            ApiService.post(url ).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }
}

