import {observer} from "mobx-react"
import React from "react"
// tslint:disable-next-line
import "react-quill/dist/quill.snow.css"
import FontAwesome from "react-fontawesome"
import {IItem, TLocalAnswer} from "../../../types/types"
import {AuthUser} from "../../common/authentication/models/AuthUser"
import {ItemContent} from "../../common/item/models/ItemContent"
import {EditableTrainingContentContainer} from "./EditableTrainingContent"


export interface TrainingContentProps {
    item: IItem
    userAnswerId: TLocalAnswer
    correctAnswer: ItemContent | null
    authUser: AuthUser
}

/**
 * @classdesc The training content displayed for training items after a user has submitted their response.
 */
@observer
export class TrainingContent extends React.Component<TrainingContentProps> {

    constructor(props: TrainingContentProps) {
        super(props)
        if (props.item.training === undefined) {
            throw Error(`Failed to find training for item ${props.item.id}`)
        }
    }

    render() {
        const {item, userAnswerId, correctAnswer, authUser} = this.props
        const answer: ItemContent | undefined = item.choices.find((choice: ItemContent) => {
            return choice.id === userAnswerId
        })
        const answerContent: string = answer !== undefined ? (answer.content as string) : ""
        const trainingContent = item.training && item.training.content ? item.training.content : ""
        const trainingContentId = (item.training && item.training.id)!

        return (
            <div className="training-content">
                {correctAnswer && (
                    <div className="training-content-answer-comment">
                        <div className="comment">
                            {correctAnswer.id === userAnswerId ? (
                                <h3 className="success-text">
                                    <FontAwesome name="check-circle"/> You are correct!
                                </h3>
                            ) : (
                                <h3 className="danger-text">
                                    <FontAwesome name="times-circle"/> Sorry, you are not correct
                                </h3>
                            )}
                            <p>
                                The correct answer is <strong>{correctAnswer.content}</strong>.<br/>
                                You selected {answerContent}.
                            </p>
                        </div>
                        <div className="comment margin-top">
                            <b>Training</b>
                            <br/>
                            {authUser.userType === "A" ? (
                                <EditableTrainingContentContainer itemContentId={trainingContentId} itemId={item.id}/>
                            ) : (
                                <div dangerouslySetInnerHTML={{__html: trainingContent as string}}/>
                            )}
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
