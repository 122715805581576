import React from "react"
import {Col, Modal, Row} from "react-bootstrap"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"

import {observer} from "mobx-react"
import {loadingSpinnerStore} from "../app/common/loaders/LoadingSpinnerStore"
import {productStore} from "../app/common/products/ProductStore"
import {setNoConnection, setStopAll} from "../redux/app/actions"
import {State} from "../types/types"
import Button from "./Button/Button"
import {withRouter} from "react-router-dom"

interface IStateToProps {
    noConnection?: boolean
}

const mapStateToProps = (state: State): IStateToProps => {
    return {
        noConnection: state.app.noConnection
    }
}

interface IDispatchToProps {
    setNoConnectionDispatch: (noConnection: boolean) => void
    setStopAllDispatch: (stopAll: boolean) => void
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
    return {
        setNoConnectionDispatch: (noConnection: boolean) => {
            dispatch(setNoConnection(noConnection))
        },
        setStopAllDispatch: (stopAll: boolean) => {
            dispatch(setStopAll(stopAll))
        }
    }
}

type TComponentProps = IStateToProps & IDispatchToProps & RouteComponentProps<any>

interface IComponentState {
    showNoConnectionModal: boolean
}

@observer
class NoConnectionModalContainer extends React.Component<TComponentProps, IComponentState> {
    state: Readonly<IComponentState>

    constructor(props: TComponentProps) {
        super(props)
        this.state = {
            showNoConnectionModal: false
        }
    }

    componentDidUpdate() {
        let showNoConnectionModal: boolean = false
        if (this.props.noConnection) {
            showNoConnectionModal = true
            loadingSpinnerStore.stopAll = true
            this.props.setStopAllDispatch(true)
        }
        if (showNoConnectionModal !== this.state.showNoConnectionModal) {
            this.setState({showNoConnectionModal})
        }
    }

    closeNoConnectionModal = () => {
        this.props.setNoConnectionDispatch(false)
        this.props.setStopAllDispatch(false)
        this.setState({
            showNoConnectionModal: false
        })
    }

    render() {
        const driver = productStore.driver!!
        return (
            <Modal size="sm" show={this.state.showNoConnectionModal} onHide={this.closeNoConnectionModal}>
                <Modal.Header closeButton={true} className="center-text">
                    <Modal.Title>{driver.strings.cannotConnectToServer}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="center-text">
                    <p>{driver.strings.weAreHavingTroubleConnecting}</p>
                    <Row>
                        <Col sm={{span: 6, offset: 3}}>
                            <Button
                                className="avant-button--primary avant-button--default"
                                onClick={this.closeNoConnectionModal}
                                block={true}
                            >
                                OK
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NoConnectionModalContainer)
)
