import {/*FormControl,*/
    FormGroup, InputLabel, MenuItem,
    Select, TextField,/* Select,Input, InputLabel, MenuItem, Select, TextField*/
} from "@material-ui/core"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import Button from "@material-ui/core/Button/Button"
import Snackbar from "@material-ui/core/Snackbar/Snackbar"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import ApiService from "../../services/ApiService"
import React, {ChangeEvent} from "react"
import {
    BulkPanelGraphData,
    OrdinalTestCode,
    OrdinalTestCodesForPanelGraph,
    PanelGraphSummary,
    UpdatePanelGraphResultSummary,
    UpdateTestCodePanelGraphRequest,
    BulkUpdateTestCodePanelGraphRequest,
    /*TEvent,*/
    /*PanelGraphData*/
} from "../../types/types"
//import {theme} from "../../styles/MuiThemes";
import cloneDeep from 'lodash/cloneDeep';


interface BulkStampSubsetPanelGraphSetterState {
    step: number,
    msg: string,
    testCodesToUpdate: string,
    bulkPanelGraphData: BulkPanelGraphData,
    updatePanelGraphResultSummaries: UpdatePanelGraphResultSummary[]
}

export class BulkStampSubsetPanelGraphSetter extends React.Component<{}, BulkStampSubsetPanelGraphSetterState> {

    constructor(props: {}) {
        super(props)
        this.state = {
            step: 1,
            msg: "",
            testCodesToUpdate: "",
            bulkPanelGraphData: {error: null, ordinalTestCodesByPanelGraph: null},
            updatePanelGraphResultSummaries: []
        }
    }


    testCodesForm = () => {
        const onTestCodesSubmit = () => {
            this.submitTestCodesToGetData(this.state.testCodesToUpdate)
        }

        return (
            <div hidden={this.state.step !== 1}>

                <h2>Step {this.state.step} of 3: Test Codes</h2>

                <p>This tool is intended to allow you to assign new panel graphs to test codes. You can switch to:</p>
                <ul>
                    <li>STAMP 2S</li>
                    <li>STAMP 2Se</li>
                    <li>STAMP 3S</li>
                    <li>STAMP 3Se</li>
                </ul>
                <p><strong>Enter the test codes below, one per line.</strong> It's expected that you'll copy these test codes right from a CodeGen spreadsheet. Blank lines are okay, and you'll get results back in the same order you enter them here.</p>

                <FormGroup>
                    <TextareaAutosize
                        name="testCodesToUpdate"
                        value={this.state.testCodesToUpdate}
                        rows={10}
                        autoFocus={true}
                        onChange={(event) => this.handleTestCodesToUpdateTextareaOnChange(event)}
                    />
                </FormGroup>

                <Button
                    variant={"outlined"}
                    onClick={onTestCodesSubmit}
                    disabled={this.state.testCodesToUpdate === ""}
                >
                    Update These Test Codes
                </Button>

            </div>
        )
    }

    handleTestCodesToUpdateTextareaOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            testCodesToUpdate: event.target.value
        })
    }

    submitTestCodesToGetData = async (testCodesToUpdate: string) => {
        const apiResponse: BulkPanelGraphData = await ApiService.getBulkPanelGraphData(testCodesToUpdate)

        if (apiResponse.error === null) {
            this.setState(
                {
                    step: 2,
                    bulkPanelGraphData: apiResponse
                }
            )
        }
        else {
            this.setState({
                msg: apiResponse.error,
                bulkPanelGraphData: apiResponse
            })
        }
    }


    detailsForm = () => {
        const onTestCodesUpdate = (event: any) =>
        {
            event.preventDefault()

            this.submitBulkTestCodeUpdateRequest(
                this.buildBulkUpdateTestCodePanelGraphRequest(this.state.bulkPanelGraphData.ordinalTestCodesByPanelGraph)
            )
        }

        return (
            <div
                hidden={this.state.step !== 2}
            >
                <h2>Step {this.state.step} of 3: Details</h2>
                <p>
                    Here are the test codes you entered in the previous step. They've been grouped by their <strong>current panel graph</strong>.
                    You can assign a <strong>new panel graph</strong> to each group of test codes.
                    The selection of panel graph should include any available panel graphs for these products:
                </p>
                <ul>
                    <li>STAMP 2S</li>
                    <li>STAMP 2Se</li>
                    <li>STAMP 3S</li>
                    <li>STAMP 3Se</li>
                </ul>
                <p>
                    You can also enter a <strong>new test code</strong> for each test code.
                    When you select a new panel graph, this tool may <strong>suggest a new test code value</strong>, so be sure to watch that carefully to make sure it's what you want.
                </p>
                <p>
                    When you're ready to finalize your updates, click the button at the bottom.
                </p>
                <form onSubmit={onTestCodesUpdate}>
                    <table style={{width: "auto"}}><tbody>
                        {(this.state.bulkPanelGraphData.ordinalTestCodesByPanelGraph === null) ? null : this.state.bulkPanelGraphData.ordinalTestCodesByPanelGraph.map(ordinalTestCodesForPanelGraph =>
                            this.panelGraphDetails(ordinalTestCodesForPanelGraph)
                        )}
                    </tbody></table>

                    <Button
                        type={"submit"}
                        variant={"outlined"}
//                        onClick={onTestCodesUpdate}
                    >
                        Submit These Updates
                    </Button>
                </form>
            </div>
        )
    }

    isSafeToProcess = (productId:number):boolean  => {
        if (
            productId === 1 ||
            productId === 2 ||
            productId === 4
        ) {
            //Currently this tools works with these products
            return true
        }

        return false

    }

    panelGraphDetails = (ordinalTestCodesForPanelGraph: OrdinalTestCodesForPanelGraph) => {
        if (ordinalTestCodesForPanelGraph.panelGraphData === null) {
            return null
        }

        let h2Color = ""
        let showControls = true
        if (this.isSafeToProcess(ordinalTestCodesForPanelGraph.panelGraphData.currentPanelGraph.productId)) {
            //Currently this tools works with these products
        } else {
            //Does not work with anything else.
            h2Color = "red"
            showControls = false
        }

        const currentPanelGraphId = ordinalTestCodesForPanelGraph.panelGraphData.currentPanelGraph.panelgraphid
        return (
            <React.Fragment key={currentPanelGraphId}>
                <tr>
                    <td colSpan={2} style={{borderTop: "1px solid rgb(205, 205, 205)"}}>
                        <h3 style={{marginBottom: "0", display: "inline-block", color:h2Color }}>{ordinalTestCodesForPanelGraph.panelGraphData.currentPanelGraph.name}</h3>
                    </td>
                    <td style={{borderTop: "1px solid rgb(205, 205, 205)"}}>
                        <ArrowForwardIcon style={{verticalAlign: "middle"}} />
                    </td>
                    <td style={{borderTop: "1px solid rgb(205, 205, 205)"}}>
                        { showControls && (
                            <>
                        <InputLabel shrink>
                            New Panel Graph
                        </InputLabel>
                        <Select
                            id={"newPanelGraphFor-"+currentPanelGraphId}
                            name={"newPanelGraphFor-"+currentPanelGraphId}
                            style={{width: "100%"}}
                            value={ordinalTestCodesForPanelGraph.newPanelGraphId}
                            onChange={(event) => {
                                this.updateNewPanelGraphValue(currentPanelGraphId, String(event.target.value))
                            }}
                        >
                            {ordinalTestCodesForPanelGraph.panelGraphData.panelGraphListInSameContentArea.map(panelGraph =>
                                (panelGraph.panelgraphid !== ordinalTestCodesForPanelGraph.panelGraphData.currentPanelGraph.panelgraphid) ? this.newPanelGraphMenuItem(panelGraph) : null
                            )}
                        </Select>
                        </>
                        )}
                    </td>
                </tr>
                {showControls && ordinalTestCodesForPanelGraph.ordinalTestCodes.map((ordinalTestCode, testCodeIndex) =>
                    this.testCodeRow(ordinalTestCode, currentPanelGraphId)
                )}
            </React.Fragment>
        )
    }

    newPanelGraphMenuItem = (panelGraph: PanelGraphSummary) =>
    {
        if (this.isPanelGraphFilterPass(panelGraph)) {
            return (
                <MenuItem
                    key={panelGraph.panelgraphid}
                    value={panelGraph.panelgraphid}
                >
                    {panelGraph.name} ({this.buildSkillListString(panelGraph.skills)})
                </MenuItem>
            )
        }
        else {
            return null
        }
    }

    isPanelGraphFilterPass = (panelGraph: PanelGraphSummary): boolean =>
    {
        // Return the boolean results of all filters (true if all filters return true, false if any filters return false).
        return (
            this.isStampSubsetPanelGraph(panelGraph)
        )
    }

    testCodeRow = (ordinalTestCode: OrdinalTestCode, currentPanelGraphId: number) => {

        return (
            <React.Fragment key={ordinalTestCode.testCode}>
                <tr>
                    <td>Line {ordinalTestCode.index + 1}:</td>
                    <td>{ordinalTestCode.testCode}</td>
                    <td>
                        <ArrowForwardIcon style={{verticalAlign: "middle"}} />
                    </td>
                    <td>
                        <TextField
                            type="text"
                            id={"newTestCodeFor-"+ordinalTestCode.testCode}
                            name={"newTestCodeFor-"+ordinalTestCode.testCode}
                            style={{width: "100%"}}
                            label="New Test Code"
                            placeholder="New Test Code"
                            title={ordinalTestCode.newTestCode === '' ? 'No new test code entered. '+ordinalTestCode.testCode+' will be used.' : ordinalTestCode.testCode}
                            value={ordinalTestCode.newTestCode}
                            onChange={(event) => {
                                this.updateNewTestCodeValue(currentPanelGraphId, ordinalTestCode.testCode, event.target.value)
                            }}
                        />
                    </td>
                </tr>
            </React.Fragment>
        )
    }

    // This is the onChange function to update the newPanelGraphId value associated with each panel graph deep within the state object:
    // - state.bulkPanelGraphData.ordinalTestCodesByPanelGraph[index].newPanelGraphId
    updateNewPanelGraphValue = (currentPanelGraphId: number, newPanelGraphId: string) =>
    {
        /**
         * Update the bulkPanelGraphData object with the new panel graph.
         */
        const updateWithNewTestCodeValue = (bulkPanelGraphData: BulkPanelGraphData, currentPanelGraphId: number, newPanelGraphId: string) =>
        {
            if (bulkPanelGraphData !== null && bulkPanelGraphData.ordinalTestCodesByPanelGraph !== null) {
                bulkPanelGraphData.ordinalTestCodesByPanelGraph.map(ordinalTestCodeForPanelGraph => {
                    if (
                        ordinalTestCodeForPanelGraph.panelGraphData !== null
                        && ordinalTestCodeForPanelGraph.panelGraphData.currentPanelGraph.panelgraphid === currentPanelGraphId
                    ) {
                        ordinalTestCodeForPanelGraph.newPanelGraphId = newPanelGraphId
                    }
                })
            }

            return bulkPanelGraphData
        }

        // Make a copy of the bulkPanelGraphData state property, so we can manipulate it.
        const stateClone_bulkPanelGraphData = cloneDeep(this.state.bulkPanelGraphData)
        // Update the copy of the bulkPanelGraphData state property with the new panel graph ID value.
        this.setState(
            { bulkPanelGraphData: updateWithNewTestCodeValue(stateClone_bulkPanelGraphData, currentPanelGraphId, newPanelGraphId) },
            () => {this.suggestNewTestCodes(String(currentPanelGraphId), newPanelGraphId)}
        )
    }

    // This is the onChange function to update the newTestCode value associated with each test code deep within the state object:
    // - state.bulkPanelGraphData.ordinalTestCodesByPanelGraph[index].ordinalTestCodes[index].netTestCode
    updateNewTestCodeValue = (currentPanelGraphId: number, currentTestCode: string, newTestCode: string) =>
    {
        /**
         * Update the bulkPanelGraphData object with the new test code.
         */
        const updateWithNewTestCodeValue = (bulkPanelGraphData: BulkPanelGraphData, currentPanelGraphId: number, currentTestCode: string, newTestCode: string) =>
        {
            if (bulkPanelGraphData !== null && bulkPanelGraphData.ordinalTestCodesByPanelGraph !== null) {
                bulkPanelGraphData.ordinalTestCodesByPanelGraph.map(ordinalTestCodesForPanelGraph => {
                    if (
                        ordinalTestCodesForPanelGraph.panelGraphData !== null
                        && ordinalTestCodesForPanelGraph.panelGraphData.currentPanelGraph.panelgraphid === currentPanelGraphId
                    ) {
                        ordinalTestCodesForPanelGraph.ordinalTestCodes.map(ordinalTestCode => {
                            if (ordinalTestCode.testCode === currentTestCode) {
                                ordinalTestCode.newTestCode = newTestCode.trim()
                            }
                        })
                    }
                })
            }

            return bulkPanelGraphData
        }

        // Make a copy of the bulkPanelGraphData state property, so we can manipulate it.
        const stateClone_bulkPanelGraphData = cloneDeep(this.state.bulkPanelGraphData)
        // Update the copy of the bulkPanelGraphData state property with the new test code value.
        this.setState(
            { bulkPanelGraphData: updateWithNewTestCodeValue(stateClone_bulkPanelGraphData, currentPanelGraphId, currentTestCode, newTestCode) }
        )
    }

    submitBulkTestCodeUpdateRequest = async (bulkUpdateTestCodePanelGraphRequest: BulkUpdateTestCodePanelGraphRequest) => {
        const apiResponse: UpdatePanelGraphResultSummary[] = await ApiService.bulkUpdateTestCodePanelGraphs(bulkUpdateTestCodePanelGraphRequest.updateTestCodePanelGraphRequests)

        this.setState(
            {
                step: 3,
                updatePanelGraphResultSummaries: apiResponse
            }
        )
    }

    buildBulkUpdateTestCodePanelGraphRequest = (ordinalTestCodesByPanelGraph: OrdinalTestCodesForPanelGraph[] | null): BulkUpdateTestCodePanelGraphRequest =>
    {
        let updateTestCodePanelGraphRequestList: UpdateTestCodePanelGraphRequest[] = []

        if (ordinalTestCodesByPanelGraph !== null) {
            ordinalTestCodesByPanelGraph.map(ordinalTestCodesForPanelGraph => {
                let newPanelGraph = ordinalTestCodesForPanelGraph.newPanelGraphId
                ordinalTestCodesForPanelGraph.ordinalTestCodes.map(ordinalTestCode => {
                    updateTestCodePanelGraphRequestList.push({
                        index: ordinalTestCode.index,
                        testCodeToUpdate: ordinalTestCode.testCode,
                        newTestCode: ordinalTestCode.newTestCode,
                        newPanelGraph: newPanelGraph
                    })
                })
            })
        }

        return {updateTestCodePanelGraphRequests: updateTestCodePanelGraphRequestList}
    }

    suggestNewTestCodes = (currentPanelGraphId: string, newPanelGraphId: string) =>
    {
        let stateClone_bulkPanelGraphData = cloneDeep(this.state.bulkPanelGraphData)

        if (stateClone_bulkPanelGraphData.ordinalTestCodesByPanelGraph !== null) {
            // Iterate over bulk panel graph data...
            stateClone_bulkPanelGraphData.ordinalTestCodesByPanelGraph.map(ordinalTestCodesForPanelGraph => {
                // When we get to the matching panel graph...
                if (
                    ordinalTestCodesForPanelGraph.panelGraphData !== null
                    && String(ordinalTestCodesForPanelGraph.panelGraphData.currentPanelGraph.panelgraphid) === currentPanelGraphId
                ) {
                    ordinalTestCodesForPanelGraph.panelGraphData.panelGraphListInSameContentArea.map(panelGraphSummary => {
                        if (String(panelGraphSummary.panelgraphid) === newPanelGraphId) {
                            const selectedPanelGraphName = panelGraphSummary.name
                            const selectedPanelGraphSkills = panelGraphSummary.skills

                            ordinalTestCodesForPanelGraph.ordinalTestCodes.map(ordinalTestCode => {
                                ordinalTestCode.newTestCode = this.suggestNewTestCode(ordinalTestCode, selectedPanelGraphName, selectedPanelGraphSkills)
                            })

                        }
                    })

                    this.setState({
                        bulkPanelGraphData: stateClone_bulkPanelGraphData
                    })
                }
            })
        }
    }

    suggestNewTestCode = (ordinalTestCode: OrdinalTestCode, selectedPanelGraphName: string, selectedPanelGraphSkills: string[]) => {
        const replaceablePattern = /-(2|4)(s|e)-/i
        // This is a fallback value: the current new test code.
        let suggestion: string = ordinalTestCode.newTestCode
        if (suggestion === '') {
            suggestion = ordinalTestCode.testCode
        }

        // If there's a known replaceable part in the ordinalTestCode.testCode value...
        if (replaceablePattern.test(ordinalTestCode.testCode)) {

            // Build the first part of the replacement string for the suggestion: product.
            let suggestionFirstPart: string = ''
            if (/2se/i.test(selectedPanelGraphName) || /2e/i.test(selectedPanelGraphName)) {
                suggestionFirstPart = '-2e'
            } else if (/2s/i.test(selectedPanelGraphName)) {
                suggestionFirstPart = '-2s'
            } else if (/3se/i.test(selectedPanelGraphName) || /3e/i.test(selectedPanelGraphName)) {
                suggestionFirstPart = '-3e'
            } else if (/3s/i.test(selectedPanelGraphName)) {
                suggestionFirstPart = '-3s'
            }

            // Build the second part of the replacement string for the suggestion: skills.
            let suggestionSecondPart: string = ''
            if (
                selectedPanelGraphSkills.length === 2
                && selectedPanelGraphSkills.includes('LISTENING')
                && selectedPanelGraphSkills.includes('SPEAKING_SP')
            ) {
                suggestionSecondPart = '-ls'
            } else if (
                selectedPanelGraphSkills.length === 2
                && selectedPanelGraphSkills.includes('READING')
                && selectedPanelGraphSkills.includes('WRITING')
            ) {
                suggestionSecondPart = '-rw'
            } else if (
                selectedPanelGraphSkills.length === 3
                && selectedPanelGraphSkills.includes('READING')
                && selectedPanelGraphSkills.includes('LISTENING')
                && selectedPanelGraphSkills.includes('SPEAKING_SP')
            ) {
                suggestionSecondPart = '-rls'
            } else if (
                selectedPanelGraphSkills.length === 3
                && selectedPanelGraphSkills.includes('READING')
                && selectedPanelGraphSkills.includes('WRITING')
                && selectedPanelGraphSkills.includes('LISTENING')
            ) {
                suggestionSecondPart = '-rwl'
            }

            if (suggestionFirstPart + suggestionSecondPart === '') {
                // We're not making a suggestion, so fall back to the current new test code (presumably user entered).
                suggestion = ordinalTestCode.newTestCode
            }
            else {
                suggestion = suggestion.replace(replaceablePattern, suggestionFirstPart + suggestionSecondPart + '-')
            }

        }
        else {
            // We're not making a suggestion, so fall back to the current new test code (presumably user entered).
            suggestion = ordinalTestCode.newTestCode
        }

        // If the new test code exactly matches the old one, the back end will not replace it even if there's a new
        // panel graph, so we want to reset it to empty string if they match.
        if (suggestion === ordinalTestCode.testCode) {
            suggestion = ''
        }

        return suggestion
    }

    buildSkillListString = (skillList: string[]) =>
    {
        let codedSkillList: string[] = []

        if (skillList.includes('READING')) codedSkillList.push('RD')
        if (skillList.includes('WRITING')) codedSkillList.push('WR')
        if (skillList.includes('LISTENING')) codedSkillList.push('LS')
        if (skillList.includes('SPEAKING_SP')) codedSkillList.push('SP')

        return codedSkillList.join('/')
    }


    results = () => {
        let stateClone_updatePanelGraphResultSummaries = cloneDeep(this.state.updatePanelGraphResultSummaries)

        // Sort the result list by index (which is a stand-in for line number).
        stateClone_updatePanelGraphResultSummaries.sort((a, b) => (a.index > b.index) ? 1 : -1)

        return (
            <div
                hidden={this.state.step !== 3}
            >

                <h2>Step {this.state.step} of 3: Results</h2>

                <p>Here's are the results for what you entered:</p>
                <table style={{width: "auto"}}>
                    <tbody>
                        {(this.state.bulkPanelGraphData.ordinalTestCodesByPanelGraph === null) ? null : this.state.bulkPanelGraphData.ordinalTestCodesByPanelGraph.map(ordinalTestCodesForPanelGraph =>
                            this.panelGraphResults(ordinalTestCodesForPanelGraph)
                        )}
                    </tbody>
                </table>

                <FormGroup>
                    <p>Here's the list of resulting test codes, in the same order that you entered them:</p>
                    <TextareaAutosize
                        name="testCodesAfterUpdate"
                        value={this.buildResultList(stateClone_updatePanelGraphResultSummaries)}
                        rows={10}
                        autoFocus={true}
                    />
                </FormGroup>

            </div>
        )
    }

    panelGraphResults = (ordinalTestCodesForPanelGraph: OrdinalTestCodesForPanelGraph) =>
    {
        if (ordinalTestCodesForPanelGraph.panelGraphData === null) {
            return null
        }

        const currentPanelGraphId = ordinalTestCodesForPanelGraph.panelGraphData.currentPanelGraph.panelgraphid
        return (
            <React.Fragment key={currentPanelGraphId}>
                <tr>
                    <td colSpan={2} style={{borderTop: "1px solid rgb(205, 205, 205)"}}>
                        <h3 style={{marginBottom: "0", display: "inline-block"}}>{ordinalTestCodesForPanelGraph.panelGraphData.currentPanelGraph.name}</h3>
                    </td>
                    <td style={{borderTop: "1px solid rgb(205, 205, 205)"}}>
                        <ArrowForwardIcon style={{verticalAlign: "middle"}} />
                    </td>
                    <td style={{borderTop: "1px solid rgb(205, 205, 205)"}}>
                        <h3 style={{marginBottom: "0", display: "inline-block"}}>{this.newPanelGraphResult(ordinalTestCodesForPanelGraph.newPanelGraphId, ordinalTestCodesForPanelGraph.panelGraphData.panelGraphListInSameContentArea)}</h3>
                    </td>
                </tr>
                {ordinalTestCodesForPanelGraph.ordinalTestCodes.map((ordinalTestCode, testCodeIndex) =>
                    this.testCodeResults(ordinalTestCode, currentPanelGraphId)
                )}
            </React.Fragment>
        )
    }

    newPanelGraphResult = (newPanelGraphId: string, panelGraphListInSameContentArea: PanelGraphSummary[]): string =>
    {
        let panelGraphResult = ''
        panelGraphListInSameContentArea.forEach(function (panelGraph) {
            if (panelGraphResult === '' && String(panelGraph.panelgraphid) === newPanelGraphId) {
                panelGraphResult = panelGraph.name
            }
        })

        return panelGraphResult
    }

    testCodeResults = (ordinalTestCode: OrdinalTestCode, currentPanelGraphId: number) =>
    {
        return (
            <React.Fragment key={ordinalTestCode.testCode}>
                <tr>
                    <td>Line {ordinalTestCode.index + 1}:</td>
                    <td>{ordinalTestCode.testCode}</td>
                    <td>
                        <ArrowForwardIcon style={{verticalAlign: "middle"}} />
                    </td>
                    <td>
                        {ordinalTestCode.newTestCode}
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td colSpan={3}>
                        {this.getResultMessage(ordinalTestCode.testCode)}
                    </td>
                </tr>
            </React.Fragment>
        )
    }

    getResultMessage = (testCode: string): string =>
    {
        let resultMessage: string = ''

        this.state.updatePanelGraphResultSummaries.forEach(function (summary) {
            if (resultMessage === '' && summary.oldTestCode === testCode) {
                resultMessage = summary.updateMessage
            }
        })

        return resultMessage
    }

    buildResultList = (updatePanelGraphResultSummaries: UpdatePanelGraphResultSummary[]): string =>
    {
        let testCodes: string[] = []

        updatePanelGraphResultSummaries.forEach(function (summary) {
            testCodes.push(summary.newTestCode)
        })

        return testCodes.join("\n")
    }


    render()
    {
        return (
            <>
                <h1>Bulk 2s/e &amp; 3s/e Panel Graph Setter</h1>
                {this.testCodesForm()}
                {this.detailsForm()}
                {this.results()}
                {this.snackBar()}
            </>
        )
    }

    snackBar = () =>
    {
        const {msg} = this.state

        const onClose = () => {
            this.setState({msg: ""})
        }

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                open={!!msg}
                autoHideDuration={6000}
                onClose={onClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{msg}</span>}
                action={[
                    <Button key={"undo"} color={"secondary"} size={"small"} onClick={onClose}>
                        Got it!
                    </Button>
                ]}
            />
        )
    }


    // This is a filter function, to tell us if a panel graph is a 2s, 2e, 3e, or 3s version of a STAMP test.
    isStampSubsetPanelGraph = (panelGraph: PanelGraphSummary): boolean =>
    {
        return (
            /2s/i.test(panelGraph.name)
            || /2e/i.test(panelGraph.name)
            || /3s/i.test(panelGraph.name)
            || /3e/i.test(panelGraph.name)
        )
    }


}
