import {Dialog, DialogContent, DialogTitle, IconButton} from "@material-ui/core"
import Box from "@material-ui/core/Box"
import {Close, Help} from "@material-ui/icons"
import {observable} from "mobx"
import {observer} from "mobx-react"
import * as React from "react"
import ApiService from "../../../../../services/ApiService"
import {theme} from "../../../../../styles/MuiThemes"
import { productStore } from "../../../../common/products/ProductStore"
import { STAMP_CEFR_PRODUCT_ID, } from "../../../../../util/Constants"
@observer
export class GraphHelper extends React.Component {
    @observable
    open = false
    isCEFR = productStore.loginProduct && productStore.loginProduct.productId === STAMP_CEFR_PRODUCT_ID.value()
    render() {
        return (
            <Box displayPrint={"none"}>
                <IconButton
                    onClick={() => this.open = true}
                >
                    <Help />
                </IconButton>
                <Dialog
                    open={this.open}
                    onClose={() => this.open = false}
                >
                    <DialogTitle>
                        <div style={{display: "flex"}}>
                            <div style={{flexGrow: 1}}/>
                            <IconButton
                                onClick={() => this.open = false}
                            >
                                <Close/>
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <img
                            style={{ margin: theme.spacing(2), marginTop: 0, maxWidth:"520px"}}
                            src={this.isCEFR ? `${ApiService.CDN_BASE_URL}resources/common/img/CEFR-Overview.png`: `${ApiService.CDN_BASE_URL}resources/common/img/level-distribution-help.png`}
                            alt={this.isCEFR ? "CEFR Overview" : "Level distribution help"}
                        />
                    </DialogContent>
                </Dialog>
            </Box>
        )
    }
}
