/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/
import React, { Component } from "react";
import {Box} from "@mui/material"
// @ts-ignore
import VideoRecorder from "react-video-recorder";
import Actions from "./VideoRenderActions";
import ApiService from "../../services/ApiService";
import { RouteComponentProps, withRouter } from "react-router"
import { TLocalAnswer, } from "../../types/types"
import { messageStore } from "../../app/common/messages/MessageStore";

type TComponentProps = {
    handleAnswerChange: (answer: TLocalAnswer | null) => void
} & RouteComponentProps<any>

interface ItemVideoRecorderState {
    responseMaximumLengthSeconds: number

}

class ItemVideoRecorder extends Component<TComponentProps, ItemVideoRecorderState> {
    constructor(props: TComponentProps) {
        super(props);
        this.state = {
            responseMaximumLengthSeconds: 0,
        };
    }
    componentDidMount() {
        this.getResponseMaximumLengthSeconds()
    }

    getResponseMaximumLengthSeconds = () => {
        ApiService.getResponseMaximumLengthExpressiveSeconds()
            .then(response => {
                if(response.error){
                    messageStore.setErrorMessage(`Error: ${response.errorDescription}`)
                    return;
                }
                // If we got back a number...
                if (response.response && !isNaN(Number(response.response))) {
                    let maxSec = Number(response.response)
                    if (Number.isInteger(maxSec) && maxSec > 0) {
                        this.setState({ 'responseMaximumLengthSeconds': maxSec })
                    }
                }
            }).catch(error => {
            console.error("An unexpected error occurred:", error);
        });
    }

    getTimeLimit = () => {
        return this.state.responseMaximumLengthSeconds * 1000;
    }

    // Any class methods you need can be defined here
    renderSecondsAsMinutesAndSeconds = (seconds: number) => {
        const fullMinutes = Math.floor(seconds / 60)
        const remainderSeconds = seconds % 60

        const sIfPlural = (count: number): string => {
            return (count === 1 ? '' : 's')
        }

        // Initialize an empty string:
        let minutesAndSecondsText: string = ''
        // If we have full minutes, render the minute count with label:
        minutesAndSecondsText += (fullMinutes > 0 ? fullMinutes + ' minute' + sIfPlural(fullMinutes) : '')
        // If we have both minutes and seconds, render "and" between them:
        minutesAndSecondsText += (fullMinutes > 0 && remainderSeconds > 0 ? ' and ' : '')
        // If we have partial minutes, render the second count with label:
        minutesAndSecondsText += (remainderSeconds > 0 ? remainderSeconds + ' second' + sIfPlural(remainderSeconds) : '')

        return minutesAndSecondsText
    }

    render() {
        const { handleAnswerChange } = this.props;
        const { responseMaximumLengthSeconds } = this.state
        return (

            <Box style={{ maxWidth: "100%" }}>
                <>
                    <p className="speaking__prompt">Record your response below.</p>
                    <Box style={{ margin: "30px", height: "400px" }}>

                        {
                            <Box
                                sx={{
                                    '& div': {
                                        overflow: 'visible',
                                    },
                                }}
                            >
                                <VideoRecorder
                                    constraints={{
                                        audio: false,
                                        video: true
                                    }}
                                    timeLimit={this.getTimeLimit()} // 10000 = 10 secs
                                    renderActions={Actions}
                                    mimeType="video/webm;codecs=vp8,opus"
                                    onStartRecording={() => {
                                        console.log("recording started")
                                    }}
                                    onStopRecording={() => {
                                        console.log("recording stopped")
                                    }}
                                    onRecordingComplete={(blob: Blob) => {
                                        console.log("recording complete")
                                        handleAnswerChange(blob)
                                    }}
                                    maxLengthSeconds={this.state.responseMaximumLengthSeconds}
                                />
                                <div style={{ color: "#767676", fontSize: "14px", fontWeight: "bold"}}>You have ONLY ONE chance to re-record your response, if needed. </div>
                            </Box>
                            }
                        
                    </Box>
                    <p className="speaking__remember">Remember to...</p>
                    <div className="writing__remember-point">
                        <div className="writing__remember-point__icon">
                            <i className="material-icons writing__remember-point__icon__check">done</i>
                        </div>
                        {"\u00A0"} use complete sentences
                    </div>
                    <div className="writing__remember-point">
                        <div className="writing__remember-point__icon">
                            <i className="material-icons writing__remember-point__icon__check">done</i>
                        </div>
                        {"\u00A0"} sign as much as you can
                        {
                            responseMaximumLengthSeconds > 0
                                ? ', up to ' + this.renderSecondsAsMinutesAndSeconds(responseMaximumLengthSeconds)
                                : ''
                        }
                    </div>
                    <div className="writing__remember-point">
                        <div className="writing__remember-point__icon">
                            <i className="material-icons writing__remember-point__icon__check">done</i>
                        </div>
                        {"\u00A0"} watch your recording when you are done
                    </div>
                </>
            </Box>
        );
    }
}

export default withRouter(ItemVideoRecorder)
