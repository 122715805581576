import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {authStore} from "../common/authentication/AuthStore"
import {log} from "../../util/Logging"
import {productStore} from "../common/products/ProductStore"
import {CLEVER_SSO_LOGIN_PRODUCT_ID} from "../../util/Constants"
import {LoginProductContentAreaData} from "../../types/types"
import {Redirect} from "react-router"
import {loadingSpinnerStore} from "../common/loaders/LoadingSpinnerStore"

export interface QParamObject {
    key: string,
    value: string
}

export interface SSOAuthResponse {
    success: number,
    cleverData: CleverData,
    auth: CleverAuthData
}

export interface SSOAuthedData {
    sso: SSOAuthResponse,
    loginId: number
}

export interface CleverAuthData {
    token: string,
    eulaAgreeDate: string,
    kind: string
}

export interface CleverData {
    cleverToken: string,
    meData: CleverMeResponse,
    // sectionData: CleverTeacherOrStudentSectionResponse
    // adminData: SchoolAdminResponse
}

export interface CleverApiCallResponseLinks {
    rel?: string,
    uri?: string
}

export interface CleverMeResponse {
    type?: string,
    data?: CleverMeData,
    links?: CleverApiCallResponseLinks[]
}

export interface CleverMeDataCredentials {
    district_username?: string
}

export interface CleverLocation {
    address?: string,
    city?: string,
    lat?: string,
    lon?: string,
    state?: string,
    zip?: string
}

export interface CleverMeDataName {
    first?: string,
    last?: string,
    middle?: string
}

export interface CleverMeData {
    id?: string,
    district?: string,
    type?: string,
    created?: string,
    credentials?: CleverMeDataCredentials,
    dob?: string,
    email?: string,
    gender?: string,
    grade?: string,
    hispanic_ethnicity?: string,
    last_modified?: string,
    location?: CleverLocation,
    name?: CleverMeDataName,
    race?: string,
    school?: string,
    sis_id?: string,
    state_id?: string,
    student_number?: string,
    teacher_number?: string,
    title?: string
}

export interface CleverSectionData {
    course?: string,
    created?: string,
    district?: string,
    grade?: string,
    last_modified?: string,
    name?: string,
    period?: string,
    school?: string,
    section_number?: string,
    sis_id?: string,
    students?: string,
    subject?: string,
    teacher?: string,
    teachers?: string,
    term_id?: string,
    id?: string
}

export interface CleverSectionDataContainer {
    data?: CleverSectionData,
    uri?: string
}

export interface CleverTeacherOrStudentSectionResponse {
    data?: CleverSectionDataContainer[],
    links?: CleverApiCallResponseLinks[]
}

export interface CleverPrincipal {
    email?: string,
    name?: string
}

export interface CleverSchoolData {
    created?: string,
    district?: string,
    ext?: any,
    high_grade?: string,
    id?: string,
    last_modified?: string,
    location?: CleverLocation,
    low_grade?: string,
    mdr_number?: string,
    name?: string,
    nces_id?: string,
    phone?: string,
    principal?: CleverPrincipal,
    school_number?: string,
    sis_id?: string,
    state_id?: string
}

export interface SchoolResult {
    data?: SchoolResult,
    uri?: string
}

export interface SchoolAdminResponse {
    data?: SchoolResult[],
    links?: CleverApiCallResponseLinks[]
}

export const SSOLogin: React.FC = observer(() => {

    // Show the loading spinner...
    loadingSpinnerStore.hideLoadingSpinner = false
    // isCancelled -> Used to prevent react update after unmount.
    // https://stackoverflow.com/questions/56442582/react-hooks-cant-perform-a-react-state-update-on-an-unmounted-component
    const isCancelled = React.useRef(false)
    const [qParams, setQParams] = React.useState<string | undefined>(undefined)
    const [loggedInUser, setLoggedInUser] = React.useState<string>("U")
    const [fetchComplete, setFetchComplete] = React.useState(false)

    const checkAndClearLocalStorage = () => {
        authStore.logout()
    }

    const getQueryParams = async () => {
        if (qParams == null) {
            const vals = window.location.search.substr(1).split("&")
            let qParamObjects: QParamObject[] = []
            vals.forEach((val) => {
                // log.debug(`${val}`)
                const keyAndVal = val.split("=")
                const newQParam = {key: keyAndVal[0], value: decodeURIComponent(keyAndVal[1])}
                qParamObjects.push(newQParam)
            })
            // log.debug(`Val: ${vals}`)
            // log.debug(qParamObjects)
            await sendCodeToAPI(qParamObjects).then(() => {
                if (!isCancelled.current) {
                    setQParams("Done")
                }
            })
        }
    }

    const sendCodeToAPI = async (body: QParamObject[]) => {

        checkAndClearLocalStorage()

        const res = await authStore.ssoAuthenticate(body)
        let userT: string = ""
        let productName = "Dashboard"
        log.debug(`Res: ${res}`)
        if(res != null) {
            // log.debug("I got a res: " + prettyJson(res) )
            if (res.sso.cleverData.meData.type != null) {
                // log.debug("SendCodeTOApi userType = " + res.sso.cleverData.meData.type)
                if (res.sso.cleverData.meData.type === "teacher") {
                    setLoggedInUser("T")
                    userT = "T"
                    productName = "Teacher Dashboard"
                } else if (res.sso.cleverData.meData.type === "student") {
                    setLoggedInUser("S")
                    userT = "S"
                    productName = "Student Dashboard"
                } else if (res.sso.cleverData.meData.type === "district_admin" || res.sso.cleverData.meData.type === "school_admin") {
                    setLoggedInUser("D")
                    userT = "D"
                    productName = "Admin Dashboard"
                } else {
                    setLoggedInUser("U")
                    userT = "U"
                }
            }

            if (userT !== "" && userT !== 'U') {
                const lpInfo: LoginProductContentAreaData = {
                    loginId: res.loginId,
                    userType: userT,
                    productName: productName,
                    productId: CLEVER_SSO_LOGIN_PRODUCT_ID.value(),
                    contentAreaId: 0,
                    contentAreaName: "None",
                    rostered: true,
                    userName: authStore.username
                }

                productStore.setLoginProduct(lpInfo)

                setFetchComplete(true)

            } else {
                // TODO this is an error case
                log.debug("I elsed when trying to set up group data!!!")
            }

        } else {
            // TODO this is an error case
            setLoggedInUser("U")
        }
    }


    useEffect( () => {
        getQueryParams()

        return () => {
            isCancelled.current = true
        }
    }, [qParams])


    if (fetchComplete) {
        loadingSpinnerStore.hideLoadingSpinner = true
        if (loggedInUser === "T") {
            return <Redirect to={"/sso/teacher"} />
        } else if (loggedInUser === "S") {
            return <Redirect to={"/sso/student"} />
        } else if (loggedInUser === "D") {
            return <Redirect to={"/sso/distadmin"} />
        }
    } else {
        // TODO this is an error case
        // log.debug("SSOLoginPage.tsx Auth Store Username: " + authStore.username)
        // log.debug("SSOLoginPage.tsx Product Store product id: " + productStore.loginProduct)
        // log.debug("SSOLoginPage.tsx Auth User Local Storage: " + localStorage.getItem("authUser"))
        // log.debug("SSOLoginPage.tsx Login product local storage: " + localStorage.getItem("loginProduct"))
    }

    return <></>
})
