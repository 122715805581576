import {AxiosResponse} from "axios"
import {observable} from "mobx"
import ApiService from "../../../services/ApiService"
import {ApiErrorResponse} from "../../../types/types"
import {ApiTakePanel} from "./models/ApiTakePanel"
import {TakePanelSkillReport} from "./models/TakePanelSkillReport"

export class TakePanelStore {

    @observable
    detailedTakePanel?: ApiTakePanel

    getDetailedTakePanel(takePanelId: number): Promise<ApiTakePanel | undefined> {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${ApiService.API_URL}takepanels/${takePanelId}`
                const response: AxiosResponse<ApiTakePanel> = await ApiService.get(url)
                const takePanel = response.data
                this.detailedTakePanel = takePanel
                return resolve(takePanel)
            } catch (e) {
                return reject()
            }
        })
    }

    getTakeSkillReport = async (takeId: number): Promise<TakePanelSkillReport[]> => {
        const url: string = `${ApiService.API_URL}reports/takes/${takeId}/skill-report`

        return await ApiService.get(url, true)
            .then((res: AxiosResponse) => {
                return res.data
            })
            .catch((err: ApiErrorResponse) => {
                throw err
            })

    }

    getTakePanelSkillReport = async (id: number): Promise<TakePanelSkillReport> => {

        const url: string = `${ApiService.API_URL}reports/takespanels/${id}/skill-report`

        return await ApiService.get(url, true)
            .then((res: AxiosResponse) => {
                return res.data
            })
            .catch((err: ApiErrorResponse) => {
                throw err
            })
    }
}

export const takePanelStore = new TakePanelStore()
