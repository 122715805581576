import { createTheme as createThemeV4 } from '@material-ui/core/styles';
import { createTheme as createThemeV5 } from '@mui/material/styles';

// Colors for themes typography only. I think these should be independent of the rest.
const colors = {
    black: "#231F20",
    darkGray: "#3B3832",
    gray: "#585858",
    lightGray: "#767676",
    avantRed: "#E74C3C"
}

export const theme = createThemeV4({

    typography: {
        fontSize: 24,
        h1: {
            fontSize: 28,
            color: colors.darkGray,
            fontWeight: "bold",
            lineHeight: "34px"
        },
        h2: {
            fontSize: 22,
            color: colors.black,
            fontWeight: "bold",
            lineHeight: "28px"
        },
        h3: {
            fontSize: 18,
            color: colors.black,
            fontWeight: "bold",
            lineHeight: "24px"
        },
        h4: {
            fontSize: 18,
            color: colors.lightGray,
            fontWeight: "bold",
            lineHeight: "24px"
        },
        h5: {
            fontSize: 14,
            color: colors.lightGray,
            textTransform: "uppercase",
            lineHeight: "18px"
        },

        /**
         * Label
         */
        subtitle1: {
            fontSize: 14,
            color: colors.black,
            fontWeight: "bold",
            lineHeight: "16px"
        },

        /**
         * Small Label
         */
        subtitle2: {
            fontSize: 12,
            color: colors.darkGray,
            fontWeight: "bold",
            lineHeight: "16px"
        },

        /**
         * Micro Label
         */
        caption: {
            fontSize: 12,
            color: colors.lightGray,
            textTransform: "uppercase",
            lineHeight: "14px"
        },

        /**
         * Body
         */
        body1: {
            fontSize: 14,
            color: colors.darkGray,
            lineHeight: "16px"
        },

        /**
         * Small Body
         */
        body2: {
            fontSize: 12,
            color: colors.gray,
            lineHeight: "18px"
        }
    },
    palette: {
        error: {
            main:  colors.avantRed
        }
    },
    overrides: {
        MuiCheckbox: {
            colorPrimary: {
                color: "#CDCDCD",
                "&$checked": {
                    color: "#2B5DD8"
                }
            },
            colorSecondary: {
                color: "#CDCDCD",
                "&$checked": {
                    color: "#1AAF5D",
                    //change the highlight color from pink to greenish-grey
                    "&:hover": {
                        backgroundColor: "rgb(186, 202, 186, 0.20)"
                    }
                },
                "&:hover": {
                    backgroundColor: "rgb(186, 202, 186, 0.20)",
                }
            }
        },
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(0,0,0,0.2)'
            }
        }
    }
})

export const themeV5 = createThemeV5({

    typography: {
        fontSize: 24,
        h1: {
            fontSize: 28,
            color: colors.darkGray,
            fontWeight: "bold",
            lineHeight: "34px"
        },
        h2: {
            fontSize: 22,
            color: colors.black,
            fontWeight: "bold",
            lineHeight: "28px"
        },
        h3: {
            fontSize: 18,
            color: colors.black,
            fontWeight: "bold",
            lineHeight: "24px"
        },
        h4: {
            fontSize: 18,
            color: colors.lightGray,
            fontWeight: "bold",
            lineHeight: "24px"
        },
        h5: {
            fontSize: 14,
            color: colors.lightGray,
            textTransform: "uppercase",
            lineHeight: "18px"
        },

        /**
         * Label
         */
        subtitle1: {
            fontSize: 14,
            color: colors.black,
            fontWeight: "bold",
            lineHeight: "16px"
        },

        /**
         * Small Label
         */
        subtitle2: {
            fontSize: 12,
            color: colors.darkGray,
            fontWeight: "bold",
            lineHeight: "16px"
        },

        /**
         * Micro Label
         */
        caption: {
            fontSize: 12,
            color: colors.lightGray,
            textTransform: "uppercase",
            lineHeight: "14px"
        },

        /**
         * Body
         */
        body1: {
            fontSize: 14,
            color: colors.darkGray,
            lineHeight: "16px"
        },

        /**
         * Small Body
         */
        body2: {
            fontSize: 12,
            color: colors.gray,
            lineHeight: "18px"
        }
    },
    palette: {
        error: {
            main:  colors.avantRed
        }
    },
    // overrides: {
    //     MuiCheckbox: {
    //         colorPrimary: {
    //             color: "#CDCDCD",
    //             "&$checked": {
    //                 color: "#2B5DD8"
    //             }
    //         },
    //         colorSecondary: {
    //             color: "#CDCDCD",
    //             "&$checked": {
    //                 color: "#1AAF5D",
    //                 //change the highlight color from pink to greenish-grey
    //                 "&:hover": {
    //                     backgroundColor: "rgb(186, 202, 186, 0.20)"
    //                 }
    //             },
    //             "&:hover": {
    //                 backgroundColor: "rgb(186, 202, 186, 0.20)",
    //             }
    //         }
    //     },
    //     MuiBackdrop: {
    //         root: {
    //             backgroundColor: 'rgba(0,0,0,0.2)'
    //         }
    //     }
    // }
})

