/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

/**
 * Following upgrade guide for MUI 4 to 5 migration
 * **/

import { createGenerateClassName } from "@material-ui/core"

export const generateClassName = createGenerateClassName({
    // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
    // using MUI classes (e.g. `.MuiButton`) they will lose styles.
    // Make sure to convert them to use `styled()` or `<Box />` first.
    disableGlobal: true,
    // Class names will receive this seed to avoid name collisions.
    seed: 'mui-jss',
})

