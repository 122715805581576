import {Typography} from "@material-ui/core"
import React from "react"
import {Link} from "react-router-dom"
import {Answer, IItem, LoginProductContentAreaData, RecordWarningEnum} from "../../../types/types"
import {PRODUCT_ID_TO_NAME} from "../../../util/Constants"
import {ProductId} from "../../../validation/ValidPrimaryKey"
import {Section} from "../../section/models/Section"
import {AuthUser} from "../authentication/models/AuthUser"
import {UserType} from "../authentication/models/UserType"
import {productStore} from "../products/ProductStore"
import Item from "./Item"
import {ItemContent} from "./models/ItemContent"

interface MockedItemProps {
    item: IItem
    authUser: AuthUser
    embedded: boolean
    productId?: ProductId
}

interface MockedItemState {
    authUser: AuthUser
}

export class MockedItem extends React.Component<MockedItemProps, MockedItemState> {
    constructor(props: MockedItemProps) {
        super(props)

        this.state = {
            authUser: {
                ...this.props.authUser,
                userType: UserType.S
            }
        }
    }

    // TODO not sure what this is, do we even use it?
    viewAsLogin: LoginProductContentAreaData = {
        loginId: 1234,
        userType: UserType.S,
        productId: 2,
        productName: "",
        contentAreaId: parseInt(this.props.item.languageId, 10),
        contentAreaName: "",
        rostered: false
    }

    render() {
        const thisItemDriver = productStore.setDriverWithProductId(this.props.productId!!)
        const emptyLogicks = () => {
            return null
        }
        const emptyPromise = () => {
            return new Promise<void>(emptyLogicks)
        }

        // const rtlLayout = this.props.productId.value() === APT_PRODUCT_ID.value()

        const emptySection: Section = {
            info: {
                numSections: -1,
                currentSection: -1
            },
            items: [],
            timeRemaining: 9999,
            timeUsed: 99,
            lastItemIndex: -1,
            responses: new Map<string, Answer>(),
            correctAnswers: new Map<string, ItemContent>(),
            takeId: -1,
            panelId: -1,
            takePanelId: -1,
            config: {}
        }

        return (
            <div key={`view-item-${this.props.item.id}`}>
                {this.props.embedded && <Typography variant={"h5"}>
                    Name: <Link to={`/products/${thisItemDriver.productId}/items/${this.props.item.id}/admin-view`} target={"_blank"}>
                            {this.props.item.name}
                          </Link> -
                    Format: {this.props.item.format} -
                    Product: {PRODUCT_ID_TO_NAME[this.props.productId!!.value()]} -
                    Level: {this.props.item.level}
                </Typography>}
                <div className="item-container" style={{minHeight: 600}}>
                    <div className={"item-wrapper no-item-menu"}>
                        <Item
                            item={this.props.item}
                            localAnswer={null}
                            prevAnswer={null}
                            correctAnswer={null}
                            config={thisItemDriver.config.TEST_ENGINE_CONFIG}
                            startTime={null}
                            allowedTime={null}
                            isFirstItem={false}
                            isLastItem={false}
                            isRtlLayout={thisItemDriver.config.IS_RTL_LAYOUT}
                            autoSaving={false}
                            showChoices={false}
                            authUser={this.state.authUser}
                            driver={thisItemDriver}
                            recordingWarningSeen={RecordWarningEnum.UNSEEN}
                            progressInSection={1}
                            itemIndex={1}
                            section={emptySection}
                            product={this.viewAsLogin}
                            handleNavigation={emptyLogicks}
                            handleOutOfTime={emptyLogicks}
                            handleLocalAnswerChange={emptyLogicks}
                            handleEditCorrectAnswer={emptyPromise}
                            handleEditItemContent={emptyPromise}
                            handleUpdateItemFile={emptyLogicks}
                            setSeenRecordingWarning={emptyLogicks}
                            handleNavigationByIndex={emptyLogicks}
                            isEmbedded={this.props.embedded}
                            hideSpinner={true}
                        />
                    </div>
                </div>
            </div>
        )
    }

}
