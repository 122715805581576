/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {GroupId} from "../../../validation/ValidPrimaryKey"
import {GroupMaster, GroupType, sortGroupMasterByName} from "../../../app/common/group/models/GroupMaster"
import {groupMasterStore} from "../../../app/common/group/GroupMasterStore"
import React, {useEffect, useState} from "react"
import ApiService from "../../../services/ApiService"
import GroupSelectDropDown from "../../molecules/GroupSelectDropDown"
import axios, {AxiosError, AxiosResponse} from "axios"
import {messageStore} from "../../../app/common/messages/MessageStore"
import {CredlyBadge} from "../UploadCredlyFromFile"
import {FormControl, MenuItem, TextField} from "@material-ui/core"
import {TEvent} from "../../../types/types"

interface DropDownProps {
    updateFilters: (filter: string, value: any) => void
}

interface GroupSelectDropDownProps extends DropDownProps {
    currentlySelectedGroup?: GroupId,
}

interface GroupSelectDropDownState {
    currentlySelectedGroup?: GroupId
    currentState?: GroupId
    currentDistrict?: GroupId
    currentSchool?: GroupId
    currentClassroom?: GroupId
    currentCustomer?: GroupId
    states: GroupMaster[]
    districts: GroupMaster[]
    schools: GroupMaster[]
    customers: GroupMaster[]
}

const defaultGroupSelectDropDownState: GroupSelectDropDownState = {
    currentlySelectedGroup: undefined,
    currentState: undefined,
    currentDistrict: undefined,
    currentSchool: undefined,
    currentClassroom: undefined,
    currentCustomer: undefined,
    states: [],
    districts: [],
    schools: [],
    customers: []
}

const requestGroups = (groupId: GroupId, groupType: GroupType, onFulfilled: (groups: GroupMaster[]) => void) => {
    groupMasterStore.getSubGroupsForGroup(groupId).then((groups: GroupMaster[]) => {
        const filteredByType = groups.filter((group: GroupMaster) => {
            return group.groupType === groupType
        })

        const sortedGroups = sortGroupMasterByName(filteredByType)

        onFulfilled(sortedGroups)
    })
}

export const GroupSelectDropDownContainer: React.FC<GroupSelectDropDownProps> = ({updateFilters, currentlySelectedGroup}) => {
    const [groupSelectState, setState] = useState<GroupSelectDropDownState>(defaultGroupSelectDropDownState)

    useEffect(() => {
        ApiService.getGroupsByType(GroupType.STATE).then((states: GroupMaster[]) => {
            setState((prevState: GroupSelectDropDownState) => ({
                ...prevState,
                states: sortGroupMasterByName(states)
            }))
        })

        if (currentlySelectedGroup === null && currentlySelectedGroup !== groupSelectState.currentlySelectedGroup) {
            setState(defaultGroupSelectDropDownState)
        }
    }, [currentlySelectedGroup])

    return (
        <GroupSelectDropDown
            onSelectChanged={(
                currentlySelectedGroup?: GroupId,
                currentState?: GroupId,
                currentDistrict?: GroupId,
                currentSchool?: GroupId,
                currentClassroom?: GroupId,
                currentCustomer?: GroupId
            ) => {
                setState((prevState: GroupSelectDropDownState) => ({
                    ...prevState,
                    currentlySelectedGroup: currentlySelectedGroup,
                    currentState: currentState,
                    currentDistrict: currentDistrict,
                    currentSchool: currentSchool,
                    currentClassroom: currentClassroom,
                    currentCustomer: currentCustomer,
                }))
                updateFilters("groupId", Number(currentlySelectedGroup))
            }}
            requestDistricts={(stateId: GroupId) => {
                requestGroups(stateId, GroupType.DISTRICT, (districts: GroupMaster[]) => {
                    setState((prevState: GroupSelectDropDownState) => ({
                        ...prevState,
                        districts: districts,
                    }))
                })
            }}
            requestSchools={(districtId: GroupId) => {
                requestGroups(districtId, GroupType.SCHOOL, (schools: GroupMaster[]) => {
                    setState((prevState: GroupSelectDropDownState) => ({
                        ...prevState,
                        schools: schools,
                    }))
                })
            }}
            requestCustomers={(customerId: GroupId) => {
                requestGroups(customerId, GroupType.CUSTOMER, (customers: GroupMaster[]) => {
                    setState((prevState: GroupSelectDropDownState) => ({
                        ...prevState,
                        customers: customers,
                    }))
                })
            }}
            currentSelectedGroup={groupSelectState.currentlySelectedGroup}
            currentState={groupSelectState.currentState}
            currentDistrict={groupSelectState.currentDistrict}
            currentSchool={groupSelectState.currentSchool}
            currentCustomer={groupSelectState.currentCustomer}
            states={groupSelectState.states}
            districts={groupSelectState.districts}
            schools={groupSelectState.schools}
            classrooms={[]}
            customers={groupSelectState.customers}
            locations={[]}
        />
    )
}

interface CredlyBadgeDropDownState {
    badgeTemplates?: CredlyBadge[]
    selectedBadge?: string
}

export const CredlyBadgeDropDown: React.FC<DropDownProps> = ({updateFilters}) => {
    const [credlyBadgeState, setState] = useState<CredlyBadgeDropDownState>({
        badgeTemplates: undefined,
        selectedBadge: "",
    })

    const handleChange = (event: TEvent) => {
        setState((prevState: CredlyBadgeDropDownState) => ({
           ...prevState,
           selectedBadge: event.target.value,
        }))

        updateFilters("badgeId", event.target.value)
    }

    useEffect(() => {
        // @ts-ignore
        ApiService.get(`${ApiService.API_URL}credly/badge_templates`)
            .then((response: AxiosResponse<CredlyBadge[]>)=> {
                setState((prevState: CredlyBadgeDropDownState) => ({
                    ...prevState,
                    badgeTemplates: response.data,
                }))
            })
            .catch((error) => {
                const errors = error as Error | AxiosError;
                // TODO: Find a better way to handle this potential non axios error
                if (!axios.isAxiosError(errors)) {
                    error.response && messageStore.setErrorMessage(error.response.data.message)
                } else {
                    error.response && messageStore.setErrorMessage(error.response.data.message)
                }

            })
    }, [])

    if (credlyBadgeState.badgeTemplates === null) {
        return <></>
    }

    return (
        <FormControl>
            <TextField
                select={true}
                value={credlyBadgeState.selectedBadge}
                helperText={"Badge Template"}
                onChange={handleChange}
            >
                <MenuItem value={""}/>
                {credlyBadgeState.badgeTemplates && credlyBadgeState.badgeTemplates.map((badge: CredlyBadge) => {
                    return (
                        <MenuItem
                            key={badge.id}
                            value={badge.id}
                        >
                            {badge.name}
                        </MenuItem>
                    )
                })}
            </TextField>
        </FormControl>
    )
}
