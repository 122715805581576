import React from "react"
import {
    VOICE_RECORDER_MAX_VOLUME,
    VOICE_RECORDER_VOLUME_PER_SEGMENT,
    VOLUME_STRENGTH_STRONG,
    VOLUME_STRENGTH_THRESHOLD,
    VOLUME_STRENGTH_WEAK
} from "../../../util/Constants"
import {average} from "../../../util/MathUtil"
import VolumeSegment from "../VolumeSegment/VolumeSegment"

interface IComponentProps {
    volume: number
    equalizerVolumeHistoryLength: number
    micStrengthVolumeHistoryLength: number
    minSamplesBeforeTriggerToLowVolumeMessage: number
    onVolumeToLowForToLong: () => void
    onGetHelpClicked: () => void
    getHelpLabel: string
}

interface IComponentState {
    volumeHistory: number[]
}

export default class VoiceVolumeVisualization extends React.Component<IComponentProps, IComponentState> {
    private redIdxThreshold: number = 2
    private yellowIdxThreshold: number = 4
    private maximumVolumeReached: number = 0
    private numVolumeSamples: number = 0
    private minVolumeRequired = VOICE_RECORDER_VOLUME_PER_SEGMENT * VOLUME_STRENGTH_THRESHOLD
    private hasTriggeredVolumeToLowCallback: boolean = false

    constructor(props: IComponentProps) {
        super(props)
        this.state = {volumeHistory: [this.props.volume]}
    }

    componentDidUpdate() {
        const volumeHistory = this.state.volumeHistory
        this.numVolumeSamples = this.numVolumeSamples + 1
        volumeHistory.push(this.props.volume)
        const longestHistory = Math.max(
            this.props.micStrengthVolumeHistoryLength,
            this.props.equalizerVolumeHistoryLength
        )
        if (volumeHistory.length > longestHistory) {
            volumeHistory.shift()
        }
        if (volumeHistory !== this.state.volumeHistory) {
            this.setState({volumeHistory})
        }
        // Record the loudest the tester has recorded
        if (this.props.volume > this.maximumVolumeReached) {
            this.maximumVolumeReached = this.props.volume
        }

        // After a minimum number of samples are received, begin checking if they have
        // recorded a sample above the required threshold. If not, trigger the supplied callback
        // to handle the tester not supplying a strong sample.
        //
        // Keep track of if we have triggered this, otherwise we'll be constantly calling it
        // and cause a crash
        if (!this.hasTriggeredVolumeToLowCallback) {
            if (this.numVolumeSamples > this.props.minSamplesBeforeTriggerToLowVolumeMessage) {
                if (this.maximumVolumeReached <= this.minVolumeRequired) {
                    this.props.onVolumeToLowForToLong()
                    this.hasTriggeredVolumeToLowCallback = true
                }
            }
        }
    }

    render() {
        const segments: JSX.Element[] = []

        let segmentIdx = 0

        // segmentVolume is a measurement
        // of the volume against the currently
        // considered segment position
        let segmentVolume = 0

        // segmentVolume is *increasing* per iteration.
        // This makes it a little more intuitive to determine what color
        // the segment should be.
        const equalizerVolume = average(this.state.volumeHistory.slice(0, this.props.equalizerVolumeHistoryLength))
        while (segmentVolume < VOICE_RECORDER_MAX_VOLUME) {
            segmentVolume = segmentIdx * VOICE_RECORDER_VOLUME_PER_SEGMENT

            // If the segmentVolume is < voiceVolume, that means we're still
            // coloring segments. If the segmentVolume is above voiceVolume, then
            // we are filling in the rest of the segments (their voice was not loud enough
            // to reach the rest of the segments, so fill them grey)
            const cssColor =
                segmentVolume < equalizerVolume
                    ? this.getSegmentColorFromSegmentIdx(segmentIdx)
                    : "VolumeSegment--color-grey"
            segmentIdx++

            segments.push(
                <VolumeSegment
                    key={"volume-segment-" + segmentIdx}
                    maxCapacity={VOICE_RECORDER_VOLUME_PER_SEGMENT}
                    currentCapacity={equalizerVolume}
                    filledColorCssClass={cssColor}
                />
            )
        }

        // Since we "pushed" the elements on, this actually
        // grows in the wrong direction unless we reverse it.
        const orderedSegments = segments.reverse()

        const micStrengthVolume = average(this.state.volumeHistory.slice(0, this.props.micStrengthVolumeHistoryLength))
        // Communicate whether the tester is speaking loud enough.
        const strengthStr =
            micStrengthVolume <= VOICE_RECORDER_VOLUME_PER_SEGMENT * VOLUME_STRENGTH_THRESHOLD
                ? VOLUME_STRENGTH_WEAK
                : VOLUME_STRENGTH_STRONG
        return (
            <div className="VoiceVolumeVisualization">
                <div className="VoiceVolumeVisualization__meta">
                    <div className="VoiceVolumeVisualization__meta--mic">Mic Strength</div>
                    <div className="VoiceVolumeVisualization__meta--status">{strengthStr}</div>
                    <a href="javascript:void(0)" className="VoiceVolumeVisualization__meta--helplink" onClick={this.props.onGetHelpClicked}>
                        {this.props.getHelpLabel}
                    </a>
                </div>
                <div className="VoiceVolumeVisualization__visualization">{orderedSegments}</div>
            </div>
        )
    }

    private getSegmentColorFromSegmentIdx(idx: number): string {
        if (idx < this.redIdxThreshold) {
            return "VolumeSegment--color-red"
        }
        if (idx < this.yellowIdxThreshold) {
            return "VolumeSegment--color-yellow"
        }
        return "VolumeSegment--color-green"
    }
}
