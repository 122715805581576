/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/
import {AxiosResponse} from "axios"
import moment from "moment"

import {ChangeEvent, FormEvent} from "react"
import {Action} from "redux"
import {AuthenticationResponse} from "../app/common/authentication/models/AuthenticationResponse"
import {AuthUser} from "../app/common/authentication/models/AuthUser"
import {UserType} from "../app/common/authentication/models/UserType"
import {ItemContent} from "../app/common/item/models/ItemContent"
import {ProductDriver} from "../app/common/products/ProductStore"
import {ApiTakePanel} from "../app/common/take-panel/models/ApiTakePanel"
import {Section} from "../app/section/models/Section"
import ApiService from "../services/ApiService"
import {PanelId, TakeId} from "../validation/ValidPrimaryKey"
import {IApplicationConfiguration} from "./IApplicationConfiguration"
import {IArtifactConfiguration} from "./IArtifactConfiguration"
import {IDeployableConfiguration} from "./IDeployableConfiguration"
import {IDeveloperConfiguration} from "./IDeveloperConfiguration"
import {IVoiceRecordingConfiguration} from "./IVoiceRecordingConfiguration"
import {GroupMaster} from "../app/common/group/models/GroupMaster"
import {LoginExamData} from "../components/admin-portal/DevTools/LoginTable"

declare global {
    // tslint:disable-next-line
    interface Window {
        logger: {
            warning: (msg: string) => void
        }
    }
}

export type Moment = moment.Moment

/**
 * This is the type of e.currentTarget.value
 * where e is the type React.FormEvent<FormControlProps>
 */
export type TEvent = ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>
export type MuiEvent = ChangeEvent<{ name?: string, value: unknown }>
export type TFormSubmit = FormEvent<HTMLFormElement>
export type TCurrentTargetValue = null | undefined | string | number | string[]

export interface ApiUrlInfo {
    apiUrl: URL
    info: UrlInfo
}

export interface UrlInfo {
    protocol: string
    domain: string
    topLevel: string
    env: string
}

export interface TestEngineOptions {
    showCorrectAnswer?: boolean
    disableBack?: boolean // If undefined or false, allows back navigation
    finishSectionUrl?: string
    submitOnNavigate?: boolean //
    disableReansweringItem?: boolean // If undefined will allow reanswering the item.
    showTimer?: boolean // If undefined or false will not show timer, if true will show timer
    showSectionStatus?: boolean // If undefined or false is will not show section info. If true, show info
    showItemMenu?: boolean
    showItemMenuAnswers?: boolean
    showItemIdInItemMenu?: boolean
    disableItemMenuInteraction?: boolean
    rtlLayout?: boolean
    testName?: string // A friendly test name to display to the user.
    allInstructionsOnSameSide?: boolean
    autoContinueToNextSection?: boolean
    disableRequiredAnswers?: true // This is used on APT for devs to move through the test faster
    sortAnswerChoices?: boolean
    videoOnlyItems?: boolean
    smallImages?: boolean
    hasMoreContent?: boolean
    storeTestDuration?: boolean
    showClaimKeyLink?: boolean
    hideDashboardButton?: boolean
    isCertTest?: boolean
    isPracticeTest?: boolean
}

export interface AnswerResponse {
    timeRemaining: TSeconds | null
    itemContent: ItemContent
}

export interface GenericSelfEvaluation {
    title: string
    choices: Map<number, string[]>
    dbName: string
}

export interface Answer {
    format: ItemFormatEnum
    itemId: number
    index: number
    takeId: TakeId
    panelId: PanelId
    answer: TLocalAnswer
    requiredAnswerCount?: number
    completed: boolean
    binName: string
}

export interface ApiResponsePayload {
    answer: TLocalAnswer
    completed: boolean
}

export interface McAnswer extends Answer {
    answer: number
}

export interface CbAnswer extends Answer {
    answer: string[]
}

export interface PsAnswer extends Answer {
    answer: number
}

export interface LastPage {
    url: string
    takeId?: number
    takeUuid?: string
    panelId?: number
    lastItemIndex?: number
    sectionConfig?: TestEngineOptions
    takeCode?: string | null
    panelGraphId?: number | null
    currentPanelGraphId?: number
    nextPanelId?: number
    panels?: ModulePanel[]
    panelGraph?: ApiPanelGraph
}

export interface RefreshTokenData {
    secondsTillExpiration?: TSeconds
}

export interface GetLoginProductsRequest {
    username: string
}

export interface LoginProductContentAreaData {
    loginId: number
    userType: string
    productId: number
    productName: string
    contentAreaId: number
    contentAreaName: string
    rostered: boolean
    userName?: string,
    proctorType?: string
    multifactorAuthType?: string
    permissions?: string[]
}

export interface OneTimeLoginTake {
    authUser: AuthenticationResponse,
    loginProduct: LoginProductContentAreaData
}

export interface GetLoginProductsResponse {
    products: LoginProductContentAreaData[]
    kind: string
}

export interface SamlJwtData {
    samlStudentId?: string,
    samlStaffId?: string,
    samlUserId?: string,
    samlFacilityCode?: string,
    avantEmployeeId?: string,
    samlServiceName?: string,
    firstName?: string,
    lastName?: string,
    emailAddress?: string,
    userRole?: string,
}

export interface JwtSubject {
    userType: UserType
    userId: number | string
    created: string
    timeRemaining: TSeconds
    userName: string | null
    ssoType: string | null
    ssoToken: string | null
    ssoId: string | null,
    ssoUserId: string | null,
    ssoRole: string | null,
    userPermissions: string[] | null,
    samlSsoJwtData: SamlJwtData
}

// TODO find a better name
export interface IAction extends Action {
    type: string
    result: any
}

// This is the apps representation of the item's response
export interface ItemResponse {
    itemId: number
    answer: number | string | null
}

export interface TakePanel {
    benchmark: string | null
    currentSection: number
    numSections: number
    level: string | null
    panel: Panel
    panelId: number
    panelState: ApiTakePanelStateEnum
    responses: ApiItemResponse[]
    startTime: Date | null
    finishTime: Date | null
    timeRemaining: TSeconds | null
    timeUsed: TSeconds | null
}

export interface Panel {
    id: number
    skill: string
    handwritten: boolean
    friendlyName: string
    name: string
    selfEvaluation: boolean
}

export interface PanelGraphSummary {
    panelgraphid: number,
    name: string,
    contentareaid: number,
    skills: any,
    productId: number
}

export interface PanelGraphWithLanguage {
    panelGraphId: number,
    contentAreaId: number,
    productId: number,
    languageName: string | null
}

export interface SSOStudentTake {
    takeId: number,
    assessmentStartTime: string | null
    assessmentEndTime: string | null
}
export interface SSOStudentInClass {
    firstName: string | null
    lastName: string | null
    institutionId: string | null
    ssoTesttakerId: string | null
    take: SSOStudentTake | null
}

export interface PanelGraphData {
    currentPanelGraph: PanelGraphSummary,
    panelGraphListInSameContentArea: PanelGraphSummary[]
}

export interface BulkPanelGraphData {
    error: string | null,
    ordinalTestCodesByPanelGraph: OrdinalTestCodesForPanelGraph[] | null
}

export interface OrdinalTestCodesForPanelGraph {
    panelGraphData: PanelGraphData,
    newPanelGraphId: string,
    ordinalTestCodes: OrdinalTestCode[]
}

export interface OrdinalTestCode {
    index: number,
    testCode: string,
    newTestCode: string
}

export interface UpdateTestCodePanelGraphRequest {
    index: number,
    testCodeToUpdate: string,
    newTestCode: string,
    newPanelGraph: string
}

export interface BulkUpdateTestCodePanelGraphRequest {
    updateTestCodePanelGraphRequests: UpdateTestCodePanelGraphRequest[]
}

export interface UpdatePanelGraphResultSummary {
    index: number,
    oldTestCode: string,
    newTestCode: string,
    updateMessage: string
}


export enum VOICE_RECORDER {
    TWILIO = "TWILIO",
    NATIVE = "NATIVE"
}

export enum SUPPORTED_VOICE_RECORDER {
    TWILIO = "twilio",
    NATIVE = "opus",
    UNKNOWN = "unknown"
}


export const DefaultVoiceRecorder = VOICE_RECORDER.NATIVE


export interface AnswerUpdate {
    lastItemIndex: number
    responses: Map<string, Answer>
    correctAnswers: Map<string, ItemContent>
}

// TODO
export interface IItem {
    id: number
    name: string
    description: string
    format: ItemFormatEnum
    languageId: string
    choices: ItemContent[]
    psTargets: PassageSelectTarget[]
    error?: ApiErrorResponse
    training?: ItemContent
    order: number
    targetZones?: TargetZone[]
    flvRecordingUrl?: string
    speakingFileName?: string
    requiredAnswerCount?: number
    correctAnswers: string[]
    binName: string,
    level?: number,
    productId?: number

    itemContent1?: ItemContent
    itemContent2?: ItemContent
    itemContent3?: ItemContent
    itemContent4?: ItemContent
    itemContent5?: ItemContent
    itemContent6?: ItemContent
}

export interface InstructionPsTextContent {
    section: ItemContentSectionEnum.INSTRUCTION
    id: number
    itemId: number
    type: ItemContentTypeEnum.RTL_PS_TARGET | ItemContentTypeEnum.PS_TEXT
    content: PsText[]
    position: number | null
    targetZones: TargetZone[]
    distractorOrder: number
    description: string | null
    height?: number
    width?: number
    binName: string
}

export interface PassageSelectTarget {
    section: ItemContentSectionEnum.PS_TARGET
    id: number
    itemId: number
    type: ItemContentTypeEnum
    content: PsText[]
    position: number | null
    targetZones: TargetZone[]
    distractorOrder: number
    description: string | null
}

export interface TargetZone {
    id: number
    kind: string
    position: string
    snaptox: number
    snaptoy: number
    areaminx: number
    areaminy: number
    areamaxx: number
    areamaxy: number
}

export declare interface PsText {
    type: "ps-text" | ""
    text: string
}

export interface JwtToken {
    sub: string
    iss: string
    exp: number
}

export interface VideoContent {
    video: string
    poster: string
}

export interface PDFContent {
    pdf: string
}

export interface GenericImageMap {
    name: string
    areas: ImageMapArea[]
}

export interface ImageMapArea {
    id: string
    shape: string
    coords: number[]
    href?: string
}

export interface Product {
    productId: number
    admin: boolean
    studentLanguageIds: number[]
}

export interface UserForm {
    id: number | null
    firstName: string
    lastName: string
    email: string
    password?: string
    products: Map<number, Product>
}

export interface UserFormResponse {
    id: number
    status: number
}

export interface ApiUserForm {
    id: number | null
    firstName: string
    lastName: string
    email: string
    password?: string
    products: Product[]
}

export interface Choice {
    value: string
    label: string
}

export interface NumberChoice {
    value: number
    label: string
}

export interface SelectOption {
    value: any
    label: any
    testId?: string | undefined
}

export interface UserReportModule {
    name: string
    passed: boolean
    duration: number
    score?: number
    datePassed?: string
}

export interface UserReportLanguage {
    name: string
    duration: number
    modules: Map<string, UserReportModule>
}

export interface UserReportData {
    username: string
    firstName: string
    lastName: string
    languages: Map<number, UserReportLanguage>
    schoolName: string
    schoolId: number
    districtName: string
    districtId: number
    stateName: string
    stateId: number
}

export interface ApiTestData {
    takeId: number
    takeUuid: string
    takeCode: string
    takePanels: ApiTakePanel[]
    availablePanels: ApiPanel[]
    panelGraph: ApiPanelGraph
}

export interface ApiSampleTestLogins {
    username: string,
    loginId: number,
    contentAreaId: number,
    productId: number
    productString?: string,
    languageString?: string,
    password: string
    url?: string
}

export type TLocalAnswer = number | number[] | string | string[] | null | Blob
export type TSeconds = number

export enum RecorderEnum {
    UNKNOWN = "unknown",
    TWILIO = "twilio",
    OPUS = "opus",
}

export enum RecordWarningEnum {
    UNSEEN = "unseen",
    NEED = "need",
    SEEN = "seen",
}

export enum ItemContentSectionEnum {
    INSTRUCTION = "instruction",
    PROMPT = "prompt",
    ANSWER_CHOICE = "answerchoice",
    PS_TARGET = "pstarget",
    DROPZONE = "dropzone",
    TRAINING = "training",
}

export enum ItemContentTypeEnum {
    TEXT = "text",
    PLAIN_IMAGE = "plainimage",
    PDF = "pdf",
    MP3_SOUND = "mp3sound",
    RTL_TEXT_AND_SOUND = "rtltxtsnd",
    RTL_PS_TARGET = "rtlpstgt",
    RTL_TEXT = "rtltext",
    SOUND_TEXT = "soundtext",
    RTL_PS_TEXT = "rtlpstext",
    PS_DASH_TEXT = "ps-text",
    PS_TEXT = "pstext",
    VIDEO = "video",
    ITEM_VIDEO = "nonmcmovie",
    VIDEO_RECORDER = "vidrec",
    ANSWER_COUNT = "answrcount",
    BLANK = "blank",
    RTL_ADV_RESP = "rtladvresp",
    TEXT_SEND = "txtsnd",
    RTL = "rtl",
}

export enum ItemFormatEnum {
    MULTIPLE_CHOICE = "MC",
    CHECKBOX = "CB",
    PASSAGE_SELECT = "PS",
    BLANK = "BL",
    ZONE_CLICK = "ZC",
    SPEAKING_ORAL = "OL",
    PICTURE_CLICK = "PC",
    WRITING = "WR",
    FILL_IN_THE_BLANK = "FB",
    YES_NO = "YN",
    VIDEO_RECORDING = "VR",
    IC = "IC" // don't know what this stands for
}


// The PanelSkillEnum needs to match the PanelSkillOrder for skills to show up in correct order
export enum PanelSkillEnum {
    MODULE_1 = "M1",
    MODULE_2 = "M2",
    MODULE_3 = "M3",
    MODULE_4 = "M4",
    MODULE_5 = "M5",
    MODULE_6 = "M6",
    PRACTICE_TEST = "PT",
    CERTIFICATION_TEST = "CT",
    READING = "RD",
    VOCABULARY = "VO",
    GRAMMAR = "GR",
    DICTATION = "DI",
    TRANSLATION = "TR",
    SENTENCE_COMPLETION = "SC",
    VERB_CONJUGATION = "VC",
    WRITING = "WR",
    LISTENING = "LS",
    SPEAKING = "SP",
    SPEAKING_ORAL = "OL",
    RECEPTIVE = "RE",
    EXPRESSIVE = "EX",
}

export enum PanelSkillFriendlyNameEnum {
    READING = "Reading",
    VOCABULARY = "Vocabulary",
    GRAMMAR = "Grammar",
    DICTATION = "Dictation",
    TRANSLATION = "Translation",
    SENTENCE_COMPLETION = "Sentence Completion",
    VERB_CONJUGATION = "Verb Conjugation",
    // TODO: Add missing skills
    LISTENING = "Listening",
    SPEAKING = "Speaking",
    WRITING = "Writing",
    RECEPTIVE = "Receptive",
    EXPRESSIVE = "Expressive",
}

export const PanelSkillOrder = [
    PanelSkillEnum.READING,
    PanelSkillEnum.VOCABULARY,
    PanelSkillEnum.GRAMMAR,
    PanelSkillEnum.DICTATION,
    PanelSkillEnum.TRANSLATION,
    PanelSkillEnum.SENTENCE_COMPLETION,
    PanelSkillEnum.VERB_CONJUGATION,
    PanelSkillEnum.WRITING,
    PanelSkillEnum.LISTENING,
    PanelSkillEnum.SPEAKING,
    PanelSkillEnum.RECEPTIVE,
    PanelSkillEnum.EXPRESSIVE,
]

export type SortDirection = "asc" | "desc"


export enum NavDirectionEnum {
    NEXT = "next",
    BACK = "back",
}

export enum NavButtonOrderEnum {
    RIGHT_TO_LEFT = "rtl",
    LEFT_TO_RIGHT = "ltr",
}

export type THandleNavigation = (direction: NavDirectionEnum, buttonOrder: NavButtonOrderEnum) => void

export enum ApiTakePanelStateEnum {
    STARTED = "started",
    COMPLETED = "completed",
    SELFEVALUATION = "Self Evaluation"
}

export type TIsoString = string

export enum TransitionOutEnum {
    LEFT_TO_RIGHT = "ltr",
    RIGHT_TO_LEFT = "rtl",
    FADE = "fade",
}

export enum StateNames {
    ALABAMA = "Alabama",
    ALASKA = "Alaska",
    ARIZONA = "Arizona",
    ARKANSAS = "Arkansas",
    CALIFORNIA = "California",
    COLORADO = "Colorado",
    CONNECTICUT = "Connecticut",
    DELAWARE = "Delaware",
    FLORIDA = "Florida",
    GEORGIA = "Georgia",
    HAWAII = "Hawaii",
    IDAHO = "Idaho",
    ILLINOIS = "Illinois",
    INDIANA = "Indiana",
    IOWA = "Iowa",
    KANSAS = "Kansas",
    KENTUCKY = "Kentucky",
    LOUISIANA = "Louisiana",
    MAINE = "Maine",
    MARYLAND = "Maryland",
    MASSACHUSETTS = "Massachusetts",
    MICHIGAN = "Michigan",
    MINNESOTA = "Minnesota",
    MISSISSIPPI = "Mississippi",
    MISSOURI = "Missouri",
    MONTANA = "Montana",
    NEBRASKA = "Nebraska",
    NEVADA = "Nevada",
    NEW_HAMPSHIRE = "New Hampshire",
    NEW_JERSEY = "New Jersey",
    NEW_MEXICO = "New Mexico",
    NEW_YORK = "New York",
    NORTH_CAROLINA = "North Carolina",
    NORTH_DAKOTA = "North Dakota",
    OHIO = "Ohio",
    OKLAHOMA = "Oklahoma",
    OREGON = "Oregon",
    PENNSYLVANIA = "Pennsylvania",
    RHODE_ISLAND = "Rhode Island",
    SOUTH_CAROLINA = "South Carolina",
    SOUTH_DAKOTA = "South Dakota",
    TENNESSEE = "Tennessee",
    TEXAS = "Texas",
    UTAH = "Utah",
    VERMONT = "Vermont",
    VIRGINIA = "Virginia",
    WASHINGTON = "Washington",
    WEST_VIRGINIA = "West Virginia",
    WISCONSIN = "Wisconsin",
    WYOMING = "Wyoming"
}

/*******************************************************
 * Avant Api Response Types
 *******************************************************/

export interface ApiPanelGraph {
    id: number
    name: string
    friendlyName: string
    contentAreaId: number
    productId: number
    panelGraphEdges: ApiPanelGraphEdge[]
    handwritten: boolean
    productPackage?: string
}

export interface ApiPanelGraphEdge {
    panelByCurrentPanelId: ApiPanel | null
    panelByNextPanelId: ApiPanel
    level: string
    kind: string
}

// This is the Panel type in a ApiPanelGraphEdge
export interface ApiPanel {
    id: number
    name: string
    friendlyName: string
    contentAreaId: number
    testEngine: string
    phase: string
    productId: number
    skill: PanelSkillEnum
    kind: string
    handwritten: boolean
    itemCount: number
    selfEvaluation: boolean
}

export type ApiProfile =
    | ApiSiriProfile
    | ApiAptProfile
    | ApiPlaceProfile
    | Stamp4SProfile
    | Stamp4SeProfile
    | JeenieProfile
    | ApiReadyProfile

export interface ApiSiriProfile {
    kind: "ApiSiriProfile"
    demog_gender: string
    demog_education: string
    demog_year: string
    demog_languagenumber: string
    demog_assignedlevel: string
}

export interface ApiAptProfile {
    kind: "ApiAptProfile"
    firstName: string
    lastName: string
    completed: string
}

export interface ApiReadyProfile {
    kind: "ApiReadyProfile"
    firstName: string
    lastName: string
    email: string
    phone: string
    testsheetlevel?: string
    cando_5?: string
    cando_4?: string
    cando_3?: string
    cando_2?: string
    cando_1?: string
    completed: string
}

export interface ApiPlaceProfile {
    kind: "ApiPlaceProfile"
    testsheetlevel?: string
    cando_5?: string
    cando_4?: string
    cando_3?: string
    cando_2?: string
    cando_1?: string
    scale: string
    familymembers_other?: string
    familymembers?: string
    familylanguageid?: string
    foreignlang_athome: string
    test_opic?: string
    test_wpt?: string
    test_opi?: string
    test_stamp?: string
    test_satii?: string
    test_ib?: string
    test_ap?: string
    grades: string
    years: string
    testTakerEmail: string
    testTakerId: string
    yearOfBirth: string
    monthOfBirth: string
    dayOfBirth: string
    lastname: string
    firstname: string
    contentareaid: string
    login: string
    yourname?: string
    completed: string
}

export interface Stamp4SProfile {
    kind: "Stamp4SProfile"
    firstName: string
    lastName: string
    testTakerId: string
    yearOfBirth?: string
    monthOfBirth?: string
    dayOfBirth?: string
    grade?: string
    oldBeginForLan?: string
    firstLan?: string
    firstLan_other?: string
    familyMem?: string[]
    fmAsFirstLan?: boolean
    flSpeakOften?: string
    flLive?: boolean
    studiedFL?: boolean
    forLan?: string[]
    flImmersionSett?: boolean
    flYears?: number
    flAfterSchool?: string
    yourname?: string
    completed: string
}

export interface Stamp4SeProfile {
    kind: "Stamp4SeProfile"
    firstName: string
    lastName: string
    testTakerId: string
    yearOfBirth?: string
    monthOfBirth?: string
    dayOfBirth?: string
    yourname?: string
    completed: string
    grade?: string
}

export interface JeenieProfile {
    kind: "JeenieProfile"
    firstName: string
    lastName: string
    testTakerEmail: string
    testTakerPhone: string
    completed: string
}

export interface TestCodeSummation {
    testCode: string
    contentAreaId: number
    handWritten: boolean
    oppCode: string
    oppNum: string
    proctorType?: string
    stateName?: string
    districtName?: string
    schoolName?: string
    classroomName?: string
    count: number
    testPackage?: string
    
}

export interface ApiTake {
    id: number
    kind: string
    code: string
    created: TIsoString
    takePanels: ApiTakePanel[]
    availablePanels: ApiPanel[]
    profile: ApiProfile | null
    groups: ApiGroup[]
    login?: ApiLogin // Depending on the endpoint used, it may not always return the login object.
    dateBillable?: string
}

export interface ApiGroup {
    groupType: string
    name: string
}

export interface ApiItem {
    id: number
    name: string
    description: string
    format: ItemFormatEnum
    itemContents: ApiItemContent[]
    correctAnswers: string[]
    kind: string
    parent: ApiItem | null
    contentAreaId: string
    order: number | null
    flvRecordingUrl: string | null
    speakingFileName: string | null
    binName: string,
    level?: number,
    productId?: number
}

export interface CurrentSectionData {
    takePanelId: number
    currentSection: number
    numSections: number
    startTime: string | null
    timeRemaining: TSeconds | null
    items: ApiItem[]
    kind: string
    responses: ApiItemResponse[]
    voiceRecorder: VOICE_RECORDER
}

export interface ApiUpdateTimer {
    kind: string
    timeRemaining: TSeconds
    timeStamp: TIsoString
}

export interface ApiFinishSection {
    kind: string
    panelFinished: boolean
    timeRemaining: TSeconds | null
    timeUsed: TSeconds | null
}

export interface ApiItemResponse {
    id: number
    itemId: number
    itemFormat: string
    itemName: string
    binName: string
    completed: boolean
    respondedValue: string
    correctAnswerContentIds: number[]
    respondedContentIds: number[]
    kind: string
    score?: number
    lastTouched: string
    graded: boolean
    flvRecordingId: string
    flvRecordingUrl: string
    flvPlaybackUrl: string
}

export interface ApiErrorResponse extends AxiosResponse {
    response: AxiosResponse

    // TODO we are sometimes getting an API error that looks like this. Find out why there are two versions
    error?: string
    message?: string
    request?: any
}

export interface ApiTargetZone {
    id: number
    kind: string
    position: string
    snaptox: number
    snaptoy: number
    areaminx: number
    areaminy: number
    areamaxx: number
    areamaxy: number
}

export interface ApiItemContent {
    id: number
    itemId: number
    type: ItemContentTypeEnum
    content: string
    position: number | null
    section: ItemContentSectionEnum
    targetZones: ApiTargetZone[]
    distractorOrder: number
    description: string
    height?: number
    width?: number
}

export interface ApiAnswerItemResponse {
    kind: string
    timeRemaining: TSeconds | null
    correctAnswer: ApiItemContent
    response: ApiItemResponse
}

export interface ApiLicenseKey {
    key: string
    productId: number
    languageCount: number
    stateName?: string
    stateId?: number
    districtName?: string
    districtId: number
    schoolName?: string
    schoolId?: number
    oppCode?: string
    languages?: string[]
    expirationDate?: string
    claimedDate?: string
    created?: string
    username?: string
    kind: string
}

export interface ApiLanguage {
    id: number
    name: string
}

export interface ApiProductLanguages {
    productId: number
    languages: ApiLanguage[]
}
export interface TestPackages {
    id: string,
    name: string,
    code: string,
    shortName: string,
    longName: string,
    productId: number,
    sufTab: string
}

export interface ApiUpdateItemFileResponse {
    fileName: string
    kind: string
}

export interface EulaAgreeDateResposne {
    eulaAgreeDate: string
    kind: string
}

export const TWILIO_DEFAULT_INPUT_DEVICE_ID = "default"

export interface Connection {
    volume: (onVolumeInput: (inputVolume: number, outputVolume: number) => void) => void
}

export interface VoiceRecorderAudio {
    availableInputDevices: Map<string, MediaDeviceInfo>
    setInputDevice: (id: string) => void
}

export interface ApiTakePanelResponse {
    panelId: number
    takeCode: string
    level: string
    phase: string
    finishedTime: TIsoString
    timeTaken: number
    totalPanelScore: number
    kind: string
}

export interface VoiceRecorder {
    ready: (device: any) => void
    error: (err: any) => void
    status: () => string
    destroy: () => void
    setup: (token: string, options: any) => void
    disconnectAll: () => void
    connect: (options: any) => Connection | undefined
    audio: VoiceRecorderAudio
}

export interface ApiTwilioPlaybackTemplateResponse {
    template: string
}

export interface ApiTwilioTokenResponse {
    token: string
}

export interface ApiTwilioRecordingSidResponse {
    recordingSid: string
}

export interface UserDataLanguage {
    id: number
    name: string
}

export interface JeeniePanelReport {
    panelId: number
    panelName: string
    completed: boolean
    scored: boolean
    chineseScores: number[]
    englishScores: number[]
    chineseCompositeScore: number
    englishCompositeScore: number
}

export interface JeenieReportData {
    loginName: string
    panelReports: JeeniePanelReport[]
    chineseCompositeScore: number
    englishCompositeScore: number
}

export interface UserDataWithGroupsAndTakePanels {
    loginId: number
    username: string
    firstName?: string
    lastName?: string
    languages: UserDataLanguage[]
    stateName?: string
    stateId?: number
    districtName?: string
    districtId: number
    schoolName?: string
    schoolId?: number
    takePanels?: ApiTakePanel[]
}

export interface UserDataByPage {
    contents: UserDataWithGroupsAndTakePanels[]
    page: number
    size: number
    totalPages: number
}

export interface ApiUpdateTestDuration {
    kind: string
    testDuration: TSeconds
}

export interface ApiLogin {
    id: number
    username: string
    password?: string
    contentAreaId: number
    userType?: string
    active?: boolean
    createDate: Date
    testEngine?: string
    updated: Date
    passwordExpires?: Date
    loginExpires?: Date
    rostered?: boolean
    handwritten?: boolean
    voiceRecorder?: VOICE_RECORDER,
    allowTest?: boolean,
    proctorType?: string
    schedulingLink?: string
    loginUuid?: string
    lasttouched?: Date
    testOpenDate?: Date
    testCloseDate?: Date
    defaultGroup?: GroupMaster
    permissions?: string[]
    loginExamData?: LoginExamData
    encPassword?: string
}

/*******************************************************
 * End Avant Api Response Types
 *******************************************************/

/*******************************************************
 * Avant Api Request Types
 *******************************************************/

export interface LicenseKeysRequest {
    numKeys: number
    productId: number
    expirationDate: string
    oppCode: string
    languageCount: number
    groupId: number
}

export interface UpdateItemContentRequest {
    content: string
}

export interface UpdateCorrectAnswerRequest {
    correctAnswerId: number
}

export interface PutTwilioSQSRequest {
    itemID: number
    panelID: number
    recordingsToSave: string
    recordingsToDelete: string
    takeID: number
}

/*******************************************************
 * End Avant Api Request Types
 *******************************************************/

/*******************************************************
 * Advance Types
 *******************************************************/

// Product is a id id and an array of modules
export interface Language {
    id: number
    panelGraphId: number
    modules: Module[]
    panels: ModulePanel[]
    practiceTestPanelId: number
    practiceTestTimeTaken: number
    practiceTestScore: number
    certificationTest1PanelId: number
    certificationTest2PanelId: number
    certificationTestDisabled: boolean
    certificationTestCompleted: boolean
    completedCertificationPanels: ApiTakePanelResponse[]
    certificationTestTimeTaken: number
    certificationTestScore: number
    certificationTestItemCount: number
    showFirstCertificationTest: boolean
    existingTakeCodesByPanelId: Map<number, string>
}

// Module is a name and an array of panels
export interface Module {
    skill: PanelSkillEnum
    title: string
    subtitle: string
    panels: ModulePanel[]
    timeTaken: number
}

// ModulePanel is a boolean indicating if the panel has been completed and a reference to the next panel
export interface ModulePanel {
    id: number
    previousPanelId?: number
    nextPanelId?: number
    passed: boolean
    disabled: boolean
    type: PanelType
    timeTaken: number
    score: number
    itemCount: number
}

export enum PanelType {
    Training,
    Test,
    Certification,
    TrainingOnly
}

export interface AdvanceLastPage extends LastPage {
    currentPanelGraphId?: number
    nextPanelId?: number
    panelId?: number
    panelGraphId?: number
    panels?: ModulePanel[]
    panelGraph?: ApiPanelGraph
}

export interface TakeInviteAuthenticationRequest {
    inviteCode: string
}

export interface UserGroups {
    districtId?: number
    districtName?: string
    schoolId?: number
    schoolName?: string
    stateId?: number
    stateName?: string
}

/*******************************************************
 * End Advance Types
 *******************************************************/

/*******************************************************
 * Common Types
 *******************************************************/
export interface ItemStore {
    currentSection: Section | null
    logoutInTest: boolean
    recordingWarningSeen: RecordWarningEnum
    stopTest: boolean
    testDuration: number
}

export interface AppStore {
    authUser: AuthUser
    lastToken: string
    hideSpinner: boolean
    currentPanelGraphId: number
    showAuthUserExpireModal: boolean
    noConnection?: boolean
    stopAll: boolean
    panelGraph: ApiPanelGraph | null
    showPanelTimeoutModal?: boolean
    languages: Map<number, Language> | null
    panels: ModulePanel[] | null
    currentPanelId: number | null
    nextPanelId: number | null
    currentProductId: number
    currentPanelIndex: number
    isCertificationTest: boolean
    currentLanguageId: number | null
    isTrainingOnlyModule: boolean
    takeCode: string
    licenseKey: ApiLicenseKey | null
    productChoices: ProductChoice[]

    take: Take | null
    testState: TestState | null
    takeUuid: string | null
    logoutMessage: string | null

    product?: LoginProductContentAreaData
    driver?: ProductDriver
    apiClient?: ApiService

    advanceReportDistrictId?: number
    advanceReportLanguage?: string
    advanceReportSchool?: string
    username?: string // test code or email
}

export interface State {
    app: AppStore
    item: ItemStore
}

export interface Profile {
    firstName?: string
    lastName?: string
    testTakerId?: string
    testTakerEmail?: string
    testTakerPhone?: string
    dateOfBirth?: string
    yearOfBirth?: string
    monthOfBirth?: string
    dayOfBirth?: string
    yearsStudyingOrSpoken?: string
    gradesStudiedIn?: string[]
    testsTaken?: string[][]
    familySpeaksOtherLanguage?: boolean
    familyLanguage?: number
    familyMembers?: string[]
    otherFamilyMember?: string
    languageConfidence?: string
    contentAreaId?: number
    userId?: number
    takeCode?: string
    schoolName?: string
    testGroupName?: string
    gender?: string
    education?: string
    year?: string
    languagenumber?: string
    assignedlevel?: string
    studentId?: string
    grade?: string
    startingAge?: string
    firstLanguage?: string
    otherFirstLanguage?: string
    familySpeaksTestLanguage?: boolean
    howOftenLanguageIsSpoken?: string
    liveWithFamilyMember?: boolean
    studiedAnotherLanguage?: boolean
    languagesStudied?: string[]
    otherLanguageStudied?: string
    immersionStudy?: boolean
    immersionStudyYears?: number
    futureStudy?: string
    phone?: string

    studiedAbroad?: boolean
    monthsAbroad?: string
    yearsAbroad?: string
    email?: string
    completed?: string

    // Self Eval
    selfEvaluationIndex?: number
    cando_5?: string
    cando_4?: string
    cando_3?: string
    cando_2?: string
    cando_1?: string
}

export interface Take {
    id: number
    takeCode: string
    profile?: Profile | null
    currentPanelId: number | null
    availablePanels: Panel[]
    takePanels: TakePanel[]
    panels?: TakePanel[]
    created?: string
    availablePanelId?: number | null
    availablePanelFriendlyName?: string
}

export interface ProductChoice extends Choice {
    languageChoices: Choice[]
}

export enum ProductSubDomainEnum {
    ADVANCE = "advance",
    APT = "apt",
    ADMIN = "admin",
    SIRI = "siri",
    PLACE = "place",
    JEENIE = "jeenie",
    APP = "app",
    STAMP_4S = "stamp4s",
    STAMP4_4SE = "stamp4se",
    STAMP_SIGN_LANGUAGE = "stamp-sign-language",
    WORLD_SPEAK = "worldspeak",
    READY = "ready",
    STAMP_CEFR = "cefr"
}

export enum ProductId {
    STAMP4SE = 1,
    STAMP4S = 2,
    PLACE = 3,
    APT = 4,
    WORLDSPEAK = 5,
    READY = 6,
    ADVANCE = 8,
    JEENIE = 9,
    ADMIN = 10,
    STAMP_CEFR=15,
    STAMP_SIGN_LANGUAGE = 13,
}

export enum TestStatusEnum {
    DASHBOARD = "dashboard",
    CONTINUE_PANEL = "continue-panel",
    FINISH_TEST = "finish-test",
    PROFILE = "profile",
    PROFILE_VIEW = "profile-view",
    SELF_EVALUATION = "self-evaluation",
    TEST = "test",
}

export enum PanelLevel {
    PANEL_LEVEL_0 = "0",
    PANEL_LEVEL_1 = "1",
    PANEL_LEVEL_2 = "2",
    PANEL_LEVEL_3 = "3",
    PANEL_LEVEL_4 = "4",
    PANEL_LEVEL_5 = "5",
    PANEL_LEVEL_6 = "6",
    PANEL_LEVEL_7 = "7",
    PANEL_LEVEL_8 = "8",
    PANEL_LEVEL_9 = "9",
    PANEL_LEVEL_10 = "10",
    PANEL_LEVEL_1_MINUS = "1-",
    PANEL_LEVEL_2_MINUS = "2-",
    PANEL_LEVEL_3_MINUS = "3-",
    PANEL_LEVEL_4_MINUS = "4-",
    PANEL_LEVEL_5_MINUS = "5-",
    PANEL_LEVEL_6_MINUS = "6-",
    PANEL_LEVEL_7_MINUS = "7-",
    PANEL_LEVEL_8_MINUS = "8-",
    PANEL_LEVEL_9_MINUS = "9-",
    PANEL_LEVEL_10_MINUS = "10-",
    PANEL_NOT_RATABLE = "NR",
    PANEL_NOT_STARTED = "NS",
    PANEL_IN_PROGRESS = "IP",
    PANEL_SCORING_IN_PROGRESS = "SP",
    PANEL_PENDING = "PD",
    PANEL_PRINTABLE = "PB",
    PANEL_PRINTED = "PR",
    PANEL_STARTED = "ST",
    PANEL_COMPLETE = "TC",
    PNAEL_LEVEL_ERROR = "ER",
    PANEL_EARLY_EXIT = "EE",
    PANEL_RATED_BY_EDUCATOR = "RBE",
}

export enum PanelBenchmarkConstants {
    PANEL_NOT_STARTED = "Not Started",
    PANEL_IN_PROGRESS = "In Progress",
    PANEL_PRINTED = "Printed",
    PANEL_PRINTABLE = "Printable",
    PANEL_PENDING = "Pending",
    PANEL_TEST_COMPLETE = "Scoring in progress",
    PANEL_NOT_RATABLE = "Not Ratable",
    PANEL_EARLY_EXIT = "Early Exit",
    PANEL_RATED_BY_EDUCATOR = "Rated by Educator"
}


export interface TestState {
    testStatus: TestStatusEnum
    panelId?: number
    take?: Take
    takeId?: number
    testName?: string
    timeRemaining?: TSeconds | null
    timeUsed?: TSeconds | null
    currentSection?: number
    numSections?: number
}

export interface AppConfig
    extends IDeployableConfiguration,
        IArtifactConfiguration,
        IApplicationConfiguration,
        IDeveloperConfiguration,
        IVoiceRecordingConfiguration {
    TEST_CODES: string[]
    LMR_VHR_READING_GUIDE_URL: string
    PUBLIC_DOCUMENTS_BASE_URL: string
    profileChoices: SiriProfileChoices
    TESTING_COORDINATOR_URL: string
    HW_BASE_URL: string
}

/*******************************************************
 * End Common APT and Siri Types
 *******************************************************/

/*******************************************************
 * Siri Types
 *******************************************************/

export interface SiriProfileChoices {
    gender: Choice[]
    education: Choice[]
    year: Choice[]
    languagenumber: Choice[]
    assignedlevel: Choice[]
}

/*******************************************************
 * End Siri Types
 *******************************************************/

export interface UserDataFilters {
    username?: string
    firstName?: string
    lastName?: string
    stateId?: number
    districtId?: number
    schoolId?: number
    ordering?: string
    languageId?: number
    page: number
}

/*******************************************************
 * Voice Recorder Types
 *******************************************************/

export interface AdvanceReport {
    districtName: string
    districtId: number
    schoolReports: AdvanceSchoolReport[]
    languageReports: AdvanceLanguageReport[]
    kind: string
}

export interface AdvanceSchoolReport {
    name: string
    educatorCount: number
    m1Percent: string
    m2Percent: string
    m3Percent: string
    m4Percent: string
    m5Percent: string
    m6Percent: string
    certPercent: string
    languages: AdvanceSchoolLanguageReport[]
}

export interface AdvanceLanguageReport {
    name: string
    educatorCount: number
    m1Percent: string
    m2Percent: string
    m3Percent: string
    m4Percent: string
    m5Percent: string
    m6Percent: string
    certPercent: string
    schools: AdvanceSchoolLanguageReport[]
}

export interface AdvanceSchoolLanguageReport {
    schoolName: string
    languageName: string
    educatorCount: number
    m1Percent: string
    m2Percent: string
    m3Percent: string
    m4Percent: string
    m5Percent: string
    m6Percent: string
    certPercent: string
}

export interface AdvanceEducator {
    loginId: number
    name: string
    username: string
    districtName: string
    school: AdvanceEducatorSchool
    languages: AdvanceEducatorLanguage[]
}

export interface AdvanceEducatorSchool {
    name: string
    groupId: number
}

export enum AdvanceModuleState {
    LOCKED = "LOCKED",
    UNLOCKED = "UNLOCKED",
    IN_PROGRESSS = "INPROGRESS",
    FAILED = "FAILED",
    COMPLETED = "COMPLETED",
}

export interface AdvanceEducatorLanguage {
    name: string
    contentAreaId: number
    totalTimeTaken: string
    modules: AdvanceModules
}


export interface AdvanceModules {
    m1: AdvanceModule
    m2: AdvanceModule
    m3: AdvanceModule
    m4: AdvanceModule
    m5: AdvanceModule
    m6: AdvanceModule
    practice: AdvanceModule
    cert: AdvanceModule
}

export interface AdvanceModule {
    name: string
    status: AdvanceModuleState
    completionDate: string
    timeTaken: string
}

export interface CleverUpdateDataResponse {
    success: number
}


export interface PageResults<T> {
    page: number
    totalPages: number
    size: number
    results: T[]
}

export interface LoginDTO {
    id?: number
    contentAreaId?: number
    active?: boolean
    createDate?: Date
    testEngine?: string
    updated?: Date
    passwordExpires?: Date
    loginExpires?: Date
    rostered?: boolean
    username?: string
    proctorType?: string
    anyProctorScheduleIsAttached?: boolean
    allowTest?: boolean
    schedulingLink?: string
    productId?: number
    userType?: string
}

export interface UserListItemDTO {
    loginId?: number,
    username?: string,
    userType?: string,
    language?: string,
    active?: string,
    testEngine?: string,
    loginExpires?: Date,
    stateName?: string,
    stateId?: number,
    districtName?: string,
    districtId?: number,
    schoolName?: string,
    schoolId?: number,
    className?: string,
    classId?: number,
}

export interface SSOTeacherLoginInfo {
    testCode: string,
    id: number,
    allowTest: boolean,
    handwritten: boolean,
    contentareaid: number,
    productId: number,
    productName: string
}
export interface SsoTeacherClass {
    groupId: number,
    productName: string,
    estTestingDate: string | null,
    product: string | null,
    language: string | null,
    handwritten: boolean,
    loginId: number | null,
    status: string,
    count: number | null,
    countConsumed: number | null,
    schoolSsoId: string | null,
    schoolName: string | null,
    logins: SSOTeacherLoginInfo,
    licenses_sold: SSOLicenseCount[],
    isHandwrittenSold: boolean,
    name: string | null,
    courseId: string | null
}

export interface SsoStudentClass {
    groupId: number,
    productName: string,
    estTestingDate: string | null,
    product: string | null,
    handwritten: boolean,
    loginId: number | null,
    status: string,
    logins: SSOTeacherLoginInfo,
    adHocTestGroup: boolean
}

export interface SSOLicenseCount {
    productPackage: string | null,
    count: number | null
    price: number | null //note to get price with decimals  price/1000
}

export interface SsoClassUpdateWritingMethodSummary {
    groupId: number,
    className: string | null,
    courseId: string | null
    writingMethod: string,
}

export interface SsoEntity {
    id: string,
    ssoId: string,
    name: string,
    created: string | null,
    testing_start: string |null,
    testing_end: string | null,
    term_start: string | null,
    term_end: string | null,
    sso_systems: string | null,
    opportunity_number: string | null
    licenses_sold: SSOLicenseCount[],
    oauth: string,
    groupId: number | null,
    sync_started: string | null,
    sync_requested: string | null,
    sync_completed: string | null,
    counts: Map<string, number> | null,
    sync_process: Map<string, string> | null,
    current_term: string[],
    application_id: string | null,
}

export interface SsoEntityList {
    ssoEntity: SsoEntity
    parentGroupId: number
}

export interface GroupMasterSSOClass {
    id: number,
    name: string,
    groupType: string,
    parentGroupId: number | null,
    ssoGroupId: string,
    sisOtherData: string,
    ssoTeachers: string,
    ssoStudents: string,
    testSettings: string,
    loginId: number,
    username: string,
}

export interface ProductPackage {
    id: string,
    name: string,
    code: string,
    shortName: string,
    longName: string,
    productId: string,
    sufTab: string | null
}

export interface SpecialLoginDTO {
    loginId: number,
    username: string,
    groupMembership: GroupMaster[],
    passwordEncryption?: string,
    contentAreaId?: number,
    active?: boolean,
    createDate?: Date,
    lastTouched?: Date,
    passwordExpires?: Date,
    loginExpires?: Date,
    firstName?: string,
    lastName?: string,
    eulaAgreeDate?: Date,
    permissions?: string,
    allowTest?: boolean,
    multifactorAuthType?: string,
}

export interface AdminPortSpecialLoginsResponse {
message: string,
error: boolean,
errorDescription: string
}



/**
 * @deprecated
 * Use ProductPackageStore instead
 * This is a map to the package short code to a english name
 * TODO: Find a way to get rid of this construct and use the DB Product Package table to get this information
 * TODO: DO NOT USE THIS CONSTRUCT IN ANY NEW CODE. USE THE ProductPackageStore INSTEAD
 */
export const PACKAGE_TO_FULL_NAME = {
    "hw"     : "Handwritten Writing Section",

    "apt"    : "APT",

    "4s"     : "STAMP 4S",
    "3s-rls" : "STAMP 3S Reading/Listening/Speaking",
    "3s-rwl" : "STAMP 3S Reading/Writing/Listening",
    "3s-rws" : "STAMP 3S Reading/Writing/Speaking",
    "3s-wls" : "STAMP 3S Writing/Listening/Speaking",
    "2s-rl"  : "STAMP 2S Reading/Listening",
    "2s-ls"  : "STAMP 2S Listening/Speaking",
    "2s-rw"  : "STAMP 2S Reading/Writing",
    "2s-ws"  : "STAMP 2S Writing/Speaking",
    "2s-rs"  : "STAMP 2S Reading/Speaking",
    "2s-lw"  : "STAMP 2S Listening/Writing",
    "1s-r"   : "STAMP 1S Reading",
    "1s-w"   : "STAMP 1S Writing",
    "1s-l"   : "STAMP 1S Listening",
    "1s-s"   : "STAMP 1S Speaking",

    "4e"     : "STAMP 4Se",
    "3e-rls" : "STAMP 3Se Reading/Listening/Speaking",
    "3e-rwl" : "STAMP 3Se Reading/Writing/Listening",
    "3e-rws" : "STAMP 3Se Reading/Writing/Speaking",
    "3e-wls" : "STAMP 3Se Writing/Listening/Speaking",
    "2e-rl"  : "STAMP 2Se Reading/Listening",
    "2e-ls"  : "STAMP 2Se Listening/Speaking",
    "2e-rw"  : "STAMP 2Se Reading/Writing",
    "2e-ws"  : "STAMP 2Se Writing/Speaking",
    "2e-rs"  : "STAMP 2Se Reading/Speaking",
    "2e-lw"  : "STAMP 2Se Listening/Writing",
    "1e-r"   : "STAMP 1Se Reading",
    "1e-w"   : "STAMP 1Se Writing",
    "1e-l"   : "STAMP 1Se Listening",
    "1e-s"   : "STAMP 1Se Speaking",

    "4m"     : "STAMP Monolingual 4S",
    "3m-rls" : "STAMP Monolingual 3S Reading/Listening/Speaking",
    "3m-rwl" : "STAMP Monolingual 3S Reading/Writing/Listening",
    "3m-rws" : "STAMP Monolingual 3S Reading/Writing/Speaking",
    "3m-wls" : "STAMP Monolingual 3S Writing/Listening/Speaking",
    "2m-rl"  : "STAMP Monolingual 2S Reading/Listening",
    "2m-ls"  : "STAMP Monolingual 2S Listening/Speaking",
    "2m-rw"  : "STAMP Monolingual 2S Reading/Writing",
    "2m-ws"  : "STAMP Monolingual 2S Writing/Speaking",
    "2m-rs"  : "STAMP Monolingual 2S Reading/Speaking",
    "2m-lw"  : "STAMP Monolingual 2S Listening/Writing",
    "1m-r"   : "STAMP Monolingual 1S Reading",
    "1m-w"   : "STAMP Monolingual 1S Writing",
    "1m-l"   : "STAMP Monolingual 1S Listening",
    "1m-s"   : "STAMP Monolingual 1S Speaking",

    "4h"     : "STAMP Hebrew 4S",
    "2h-rl"   : "STAMP Hebrew 2S-Reading/Listening",
    "2h-ls"   : "STAMP Hebrew 2S-Listening/Speaking",
    "2h-rw"   : "STAMP Hebrew 2S-Reading/Writing",
    "2h-ws"   : "STAMP Hebrew 2S-Writing/Speaking",
    "2h-rs"   : "STAMP Hebrew 2S-Reading/Speaking",
    "2h-lw"   : "STAMP Hebrew 2S-Listening/Writing",
    "3h-rws"  : "STAMP Hebrew 3S-Reading/Writing/Speaking",
    "3h-rls"  : "STAMP Hebrew 3S-Reading/Listening/Speaking",
    "3h-rwl"  : "STAMP Hebrew 3S-Reading/Writing/Listening",
    "3h-wls"  : "STAMP Hebrew 3S-Writing/Listening/Speaking",
    "1h-r"    : "STAMP Hebrew 1S-Reading",
    "1h-l"    : "STAMP Hebrew 1S-Listening",
    "1h-w"    : "STAMP Hebrew 1S-Writing",
    "1h-s"    : "STAMP Hebrew 1S-Speaking",
    "4a"      : "APT 4S",
    "2a-rl"   : "APT 2S-Reading/Listening",
    "2a-ls"   : "APT 2S-Listening/Speaking",
    "2a-rw"   : "APT 2S-Reading/Writing",
    "2a-ws"   : "APT 2S-Writing/Speaking",
    "2a-rs"   : "APT 2S-Reading/Speaking",
    "2a-lw"   : "APT 2S-Listening/Writing",
    "3a-rws"  : "APT 3S-Reading/Writing/Speaking",
    "3a-rls"  : "APT 3S-Reading/Listening/Speaking",
    "3a-rwl"  : "APT 3S-Reading/Writing/Listening",
    "3a-wls"  : "APT 3S-Writing/Listening/Speaking",
    "1a-r"    : "APT 1S-Reading",
    "1a-l"    : "APT 1S-Listening",
    "1a-w"    : "APT 1S-Writing",
    "1a-s"    : "APT 1S-Speaking",
    "2w-ws"   : "STAMP WS-Writing/Speaking",
    "3w-rws"  : "STAMP WS-Reading/Writing/Speaking",

    "pl"     : "PLACE",
    "plb"   : "PLACE B",
    "plc"   : "PLACE C",

    "shl"    : "SHL",
    "shl-b"  : "SHL B",
    "shl-c"  : "SHL C",

    "rd"     : "READY",

    "ws"     : "WORLDSPEAK",

    "lat-r": "STAMP Latin Reading",

    "wse": "STAMP WSe",

    "2sl-cx" : "STAMPSL 2S Receptive/Expressive",
    "1sl-c"  : "STAMPSL 1S Receptive",
    "1sl-x"  : "STAMPSL 1S Expressive",
    "1w-s"   : "STAMP WS-Speaking",

    "4p" : "STAMP Medical 4S",
    "sws" : "STAMP WS",
}

export interface TestSettings {
    productLabel: string,
    allowHandwritten: boolean,
    estTestingDate: string | null,
    languageID: number | null,
    seatsAllowed: number | null,
    seatsResearved: number | null,
    seatsUsed: number | null
}

export interface CleverLinkResponse {
    success: number,
    cleverUrl: string
}
export interface ClassLinkResponse {
    success: number,
    classLinkUrl: string
}

export interface NycDoeUrlResponse {
    nycDoeSsoLoginLink: string
}

export interface P360Scheduling {
    p360SchedulingId: string
    p360ScheduleRemoteId: string
    title: string
    scheduleStartDate: Date
    scheduleEndDate: Date
    slots: string[]
    createDate: Date
    lasttouched: Date
    scheduleId: number | undefined
}

export interface P360ScheduleStudentPayload {
    firstName: string,
    lastName: string,
    email: string,
    selectedScheduleDate: string,
    selectedTimezone: string,
    slotId: number,
    examCalendarId: number,
    scheduleId: string
}

export interface P360ExamData {
    examData: string
    testName: string
    testLink: string
    scheduledAt: Date
    email: string
    alternateEmail: string
    createDate: Date
    lasttouched: Date
    schedulingEmailSent: Date
}
