import * as React from "react"
import {HEADER_HEIGHT, Header} from "../../../components/Header/Header"
import {REPORTS_MAX_WIDTH, reportsPadding} from "../../reports/common/components/ReportsPage"
import {screenUtil} from "../../../util/ScreenUtil"
import {SSOWrapper} from "../components/SSOWrapper"
import {theme} from "../../../styles/MuiThemes"
import {SSOUnauthorized} from "./SSOUnauthorized";

/**
 * This page is the sso unauthorized page
 */
export interface SSOUnauthorizedLandingProps {
    turnOffHeader: boolean;
  }
export const SSOUnauthorizedLanding: React.FC<SSOUnauthorizedLandingProps> = (props) => {
    const activeComponent = <SSOUnauthorized {...props}/>
    return (
        <>
            <Header/>
            <SSOWrapper
                style={{
                    padding: reportsPadding,
                    paddingTop: HEADER_HEIGHT + theme.spacing(4),
                    display: "flex",
                    flexDirection: "column",
                    alignItems: screenUtil.screenWidth > REPORTS_MAX_WIDTH + reportsPadding * 2 ? "center" : undefined,
                }}
            >
                {activeComponent}
            </SSOWrapper>
        </>
    )
}
