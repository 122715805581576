import React from "react"
import Dropzone from "react-dropzone"
import {ItemContent} from "../../app/common/item/models/ItemContent"
import {ProductDriver} from "../../app/common/products/ProductStore"
import ApiService from "../../services/ApiService"

import HelperService from "../../services/HelperService"
import {ApiUpdateItemFileResponse, VideoContent} from "../../types/types"
import {ItemContentId} from "../../validation/ValidPrimaryKey"
import Button from "../Button/Button"

interface InstructionImageProps {
    className: string
    image?: File
    smallImages?: boolean
    itemContent: ItemContent
    isAdmin: boolean
    driver: ProductDriver
    languageId: number
    handleWindowResize: () => void
    handleUpdateItemFile: (itemContentId: number, content: string | VideoContent) => void
}

interface InstructionImageState {
    editingImage: boolean
    image?: File
}

export default class InstructionImage extends React.Component<InstructionImageProps, InstructionImageState> {
    constructor(props: InstructionImageProps) {
        super(props)
        this.state = {
            editingImage: false,
            image: props.image
        }
    }

    editImage = () => {
        this.setState({editingImage: true})
    }

    saveImage = (itemContentId: number) => {
        const type = "plainimage"
        const languageId = this.props.languageId.toString()
        if (this.state.image) {
            ApiService
                .updateItemFile(new ItemContentId(itemContentId), this.state.image, languageId, type)
                .then((updateItemFileResponse: ApiUpdateItemFileResponse) => {
                    const fileName: string = updateItemFileResponse.fileName
                    const fileUrl = HelperService.getFullMediaUrl(
                        languageId,
                        type,
                        fileName,
                        this.props.driver.config.MEDIA_BASE_URL
                    )
                    this.props.handleUpdateItemFile(itemContentId, fileUrl)
                    this.setState({editingImage: false})
                })
        }
    }

    cancelImageEdit = () => {
        this.setState({editingImage: false})
    }

    onDropImage = (images: File[]) => {
        this.setState({image: images[0]})
    }

    render() {
        let className = `${this.props.className} plainimage-wrapper`
        if (this.state.editingImage) {
            className = `${this.props.className} drop-zone`
        } else if (this.props.smallImages) {
            className = `${this.props.className} small-image`
        }

        let fileUrl: string = ""
        if (this.state.image) {
            fileUrl = URL.createObjectURL(this.state.image)
        }

        let image = (
            <div key={this.props.itemContent.id} className={className}>
                {this.state.editingImage ? (
                    <div>
                        <Dropzone accept="image/jpeg, image/png" onDrop={this.onDropImage}>
                            <p className="drop-zone-text">Drop an image here or click to select an image to upload.</p>
                            {this.state.image && (
                                <div className="drop-zone-content">
                                    <img
                                        draggable={false}
                                        src={fileUrl}
                                        alt="Drop zone"
                                    />
                                </div>
                            )}
                        </Dropzone>
                        <Button
                            className="avant-button--blue-on-white avant-button--small margin-right"
                            onClick={this.cancelImageEdit}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="avant-button--primary avant-button--small"
                            onClick={() => this.saveImage(this.props.itemContent.id)}
                        >
                            Save
                        </Button>
                    </div>
                ) : (
                    <div>
                        {this.props.isAdmin ? (
                            <div>
                                <Button className="avant-button--primary avant-button--small" onClick={this.editImage}>
                                    Edit
                                </Button>
                                <img
                                    draggable={false}
                                    onLoad={this.props.handleWindowResize}
                                    src={this.props.itemContent.content as string}
                                    alt=""
                                />
                            </div>
                        ) : (
                            <img
                                draggable={false}
                                onLoad={this.props.handleWindowResize}
                                src={this.props.itemContent.content as string}
                                alt=""
                                className="img-responsive"
                                tabIndex={0}
                            />
                        )}
                    </div>
                )}
            </div>
        )

        if (this.props.smallImages) {
            image = <div className="instruction-panel__small-image-container">{image}</div>
        }

        return image
    }
}
