import moment from "moment"
import React from "react"
import {Modal} from "react-bootstrap"

import {AdvanceEducator, AdvanceEducatorLanguage, AdvanceModuleState} from "../../types/types"
import Button from "../Button/Button"

interface AdvanceReportEducatorModalProps {
    educator: AdvanceEducator
    showModal: boolean
    handleModalVisibility: (showModal: boolean) => void
}

export default class AdvanceReportEducatorModal extends React.Component<AdvanceReportEducatorModalProps, {}> {
    constructor(props: AdvanceReportEducatorModalProps) {
        super(props)
    }

    moduleStateToIcon = (state: AdvanceModuleState): React.ReactNode => {
        switch (state) {
            case AdvanceModuleState.LOCKED:
                return (
                    <div className="advance-report-educator__locked">
                        <i className="material-icons advance-report-educator__locked__icon">lock</i>
                        <span className="advance-report-educator__locked__text">Locked</span>
                    </div>
                )
            case AdvanceModuleState.UNLOCKED:
                return (
                    <div className="advance-report-educator__unlocked">
                        <div className="advance-report__container__data__percent__circle--unlocked" />
                        <span className="advance-report-educator__unlocked__text">Unlocked</span>
                    </div>
                )
            case AdvanceModuleState.IN_PROGRESSS:
                return (
                    <div className="advance-report-educator__in-progress">
                        <div className="advance-report__container__data__percent__circle--in-progress" />
                        <span className="advance-report-educator__in-progress__text">In Progress</span>
                    </div>
                )
            case AdvanceModuleState.FAILED:
                return (
                    <div className="advance-report-educator__failed">
                        <div className="advance-report__container__data__percent__circle--failed">
                            <div className="x-container">
                                <i className="material-icons x-container__x x-container__x--alt">close</i>
                            </div>
                        </div>
                        <span className="advance-report-educator__failed__text">Not Passed</span>
                    </div>
                )
            case AdvanceModuleState.COMPLETED:
                return (
                    <div className="advance-report-educator__completed">
                        <div className="advance-report__container__data__percent__circle--completed">
                            <div className="check-container">
                                <i className="material-icons check-container__check">done</i>
                            </div>
                        </div>
                        <span className="advance-report-educator__completed__text">Completed!</span>
                    </div>
                )
            default:
                throw Error(`[Advance Report Educators] Unexpected module state ${state}`)
        }
    }

    handleModalVisibility = () => {
        this.props.handleModalVisibility(false)
    }

    renderLanguage = (language: AdvanceEducatorLanguage) => {
        const iconClass = "material-icons advance-report-educator__language__module__info__time__icon"
        const practiceInfoClass =
            "advance-report-educator__language__module__info " +
            "advance-report-educator__language__module__info--time-only"

        const m1RawCompletionDate = language.modules.m1.completionDate
        const m1CompletionDate =
            m1RawCompletionDate !== null && m1RawCompletionDate !== undefined
                ? moment(m1RawCompletionDate).format("M/D/YYYY")
                : ""
        const m2RawCompletionDate = language.modules.m2.completionDate
        const m2CompletionDate =
            m2RawCompletionDate !== null && m2RawCompletionDate !== undefined
                ? moment(m2RawCompletionDate).format("M/D/YYYY")
                : ""
        const m3RawCompletionDate = language.modules.m3.completionDate
        const m3CompletionDate =
            m3RawCompletionDate !== null && m3RawCompletionDate !== undefined
                ? moment(m3RawCompletionDate).format("M/D/YYYY")
                : ""
        const m4RawCompletionDate = language.modules.m4.completionDate
        const m4CompletionDate =
            m4RawCompletionDate !== null && m4RawCompletionDate !== undefined
                ? moment(m4RawCompletionDate).format("M/D/YYYY")
                : ""
        const m5RawCompletionDate = language.modules.m5.completionDate
        const m5CompletionDate =
            m5RawCompletionDate !== null && m5RawCompletionDate !== undefined
                ? moment(m5RawCompletionDate).format("M/D/YYYY")
                : ""
        const m6RawCompletionDate = language.modules.m6.completionDate
        const m6CompletionDate =
            m6RawCompletionDate !== null && m6RawCompletionDate !== undefined
                ? moment(m6RawCompletionDate).format("M/D/YYYY")
                : ""
        const certRawCompletionDate = language.modules.cert.completionDate
        const certCompletionDate =
            certRawCompletionDate !== null && certRawCompletionDate !== undefined
                ? moment(certRawCompletionDate).format("M/D/YYYY")
                : ""

        return (
            <div key={Math.random()} className="advance-report-educator__language">
                <div className="advance-report-educator__language__info">
                    <div className="advance-report-educator__language__info__name">{language.name}</div>
                    <div className="advance-report-educator__language__info__time">
                        <i className="material-icons advance-report__container__icon">access_time</i>
                        <span className="advance-report-educator__language__info__time__text">
                            Total Active Time: {language.totalTimeTaken}
                        </span>
                    </div>
                </div>
                <div className="advance-report-educator__language__row advance-report-educator__language__row--top">
                    <div className="advance-report-educator__language__module advance-report-educator--margin">
                        <div className="advance-report-educator__language__module__name">
                            {language.modules.m1.name}
                        </div>
                        <div className="advance-report-educator__language__module__info">
                            <div className="advance-report-educator__language__module__info__status">
                                {this.moduleStateToIcon(language.modules.m1.status)}
                            </div>
                            <div className="advance-report-educator__language__module__info__completed">
                                {m1CompletionDate}
                            </div>
                            <div className="advance-report-educator__language__module__info__time">
                                <i className={iconClass}>access_time</i>
                                <div className="advance-report-educator__language__module__info__time__text">
                                    {language.modules.m1.timeTaken}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="advance-report-educator__language__module advance-report-educator--margin">
                        <div className="advance-report-educator__language__module__name">
                            {language.modules.m2.name}
                        </div>
                        <div className="advance-report-educator__language__module__info">
                            <div className="advance-report-educator__language__module__info__status">
                                {this.moduleStateToIcon(language.modules.m2.status)}
                            </div>
                            <div className="advance-report-educator__language__module__info__completed">
                                {m2CompletionDate}
                            </div>
                            <div className="advance-report-educator__language__module__info__time">
                                <i className={iconClass}>access_time</i>
                                <div className="advance-report-educator__language__module__info__time__text">
                                    {language.modules.m2.timeTaken}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="advance-report-educator__language__module advance-report-educator--margin">
                        <div className="advance-report-educator__language__module__name">
                            {language.modules.m3.name}
                        </div>
                        <div className="advance-report-educator__language__module__info">
                            <div className="advance-report-educator__language__module__info__status">
                                {this.moduleStateToIcon(language.modules.m3.status)}
                            </div>
                            <div className="advance-report-educator__language__module__info__completed">
                                {m3CompletionDate}
                            </div>
                            <div className="advance-report-educator__language__module__info__time">
                                <i className={iconClass}>access_time</i>
                                <div className="advance-report-educator__language__module__info__time__text">
                                    {language.modules.m3.timeTaken}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="advance-report-educator__language__module">
                        <div className="advance-report-educator__language__module__name">
                            {language.modules.m4.name}
                        </div>
                        <div className="advance-report-educator__language__module__info">
                            <div className="advance-report-educator__language__module__info__status">
                                {this.moduleStateToIcon(language.modules.m4.status)}
                            </div>
                            <div className="advance-report-educator__language__module__info__completed">
                                {m4CompletionDate}
                            </div>
                            <div className="advance-report-educator__language__module__info__time">
                                <i className={iconClass}>access_time</i>
                                <div className="advance-report-educator__language__module__info__time__text">
                                    {language.modules.m4.timeTaken}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="advance-report-educator__language__row">
                    <div className="advance-report-educator__language__module advance-report-educator--margin">
                        <div className="advance-report-educator__language__module__name">
                            {language.modules.m5.name}
                        </div>
                        <div className="advance-report-educator__language__module__info">
                            <div className="advance-report-educator__language__module__info__status">
                                {this.moduleStateToIcon(language.modules.m5.status)}
                            </div>
                            <div className="advance-report-educator__language__module__info__completed">
                                {m5CompletionDate}
                            </div>
                            <div className="advance-report-educator__language__module__info__time">
                                <i className={iconClass}>access_time</i>
                                <div className="advance-report-educator__language__module__info__time__text">
                                    {language.modules.m5.timeTaken}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="advance-report-educator__language__module advance-report-educator--margin">
                        <div className="advance-report-educator__language__module__name">
                            {language.modules.m6.name}
                        </div>
                        <div className="advance-report-educator__language__module__info">
                            <div className="advance-report-educator__language__module__info__status">
                                {this.moduleStateToIcon(language.modules.m6.status)}
                            </div>
                            <div className="advance-report-educator__language__module__info__completed">
                                {m6CompletionDate}
                            </div>
                            <div className="advance-report-educator__language__module__info__time">
                                <i className={iconClass}>access_time</i>
                                <div className="advance-report-educator__language__module__info__time__text">
                                    {language.modules.m6.timeTaken}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="advance-report-educator__language__module advance-report-educator--margin">
                        <div className="advance-report-educator__language__module__name">
                            {language.modules.cert.name}
                        </div>
                        <div className="advance-report-educator__language__module__info">
                            <div className="advance-report-educator__language__module__info__status">
                                {this.moduleStateToIcon(language.modules.cert.status)}
                            </div>
                            <div className="advance-report-educator__language__module__info__completed">
                                {certCompletionDate}
                            </div>
                            <div className="advance-report-educator__language__module__info__time">
                                <i className={iconClass}>access_time</i>
                                <div className="advance-report-educator__language__module__info__time__text">
                                    {language.modules.cert.timeTaken}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="advance-report-educator__language__module">
                        <div className="advance-report-educator__language__module__name">
                            {language.modules.practice.name}
                        </div>
                        <div className={practiceInfoClass}>
                            <div className="advance-report-educator__language__module__info__time">
                                <i className={iconClass}>access_time</i>
                                <div className="advance-report-educator__language__module__info__time__text">
                                    {language.modules.practice.timeTaken}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const hasEducatorName =
            !this.props.educator.name.includes("null") &&
            this.props.educator.name !== null &&
            this.props.educator.name !== undefined
        return (
            <Modal className="advance-report-educator" show={this.props.showModal} onHide={this.handleModalVisibility}>
                <Button className="advance-report-educator__close-button" onClick={this.handleModalVisibility}>
                    <i className="material-icons advance-report-educator__close-button__icon">close</i>
                </Button>
                <Modal.Body>
                    <div className="advance-report-educator__name">
                        {hasEducatorName ? this.props.educator.name : this.props.educator.username}
                    </div>
                    {hasEducatorName && (
                        <div className="advance-report-educator__username">{this.props.educator.username}</div>
                    )}
                    {this.props.educator.languages.map((language: AdvanceEducatorLanguage) => {
                        return this.renderLanguage(language)
                    })}
                </Modal.Body>
            </Modal>
        )
    }
}
