import React, {ChangeEvent} from "react"
import Snackbar from "@material-ui/core/Snackbar/Snackbar"
import Button from "@material-ui/core/Button/Button"
import {
    FormControlLabel,
    FormHelperText,
    MenuItem,
    Select,
    Switch,
    TextField
} from "@material-ui/core"
import ApiService from "../../services/ApiService"
import Grid from "@material-ui/core/Grid"
import {PRODUCT_ID_TO_NAME} from "../../util/Constants"
import {PanelGraphWithLanguage} from "../../types/types"

interface CreateAssessmentState {
    msg: string,
    testCode: string,
    password: string,
    closeDate: string,
    currentTests: PanelGraphWithLanguage[] | null,
    selectPanelGraph: number,
    useHandwritten: boolean,
    createTeacher: boolean,
}

export class CreateAssessment extends React.Component<{}, CreateAssessmentState> {

    constructor(props: {}) {
        super(props)
        this.state = {
            msg: "",
            testCode: "",
            password: "",
            closeDate: "",
            currentTests:  null,
            selectPanelGraph: 0,
            useHandwritten: false,
            createTeacher: true,
        }
    }

    componentDidMount(): void {
        ApiService.getCurrentPanelGraphs("all")
            .then(response => {
                if (response.length) {
                    this.setState({currentTests: response})
                }
            })
    }

    snackBar = () => {

        const {msg} = this.state

        const onClose = () => {
            this.setState({msg: ""})
        }

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                open={!!msg}
                autoHideDuration={6000}
                onClose={onClose}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{msg}</span>}
                action={[
                    <Button key={"undo"} color={"secondary"} size={"small"} onClick={onClose}>
                        Got it!
                    </Button>
                ]}
            />
        )
    }

    handleLanguageChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
        this.setState({selectPanelGraph: event.target.value as number})
    }
    handleCodeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
        this.setState({testCode: event.target.value as string})
    }
    handlePasswordChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
        this.setState({password: event.target.value as string})
    }
    handleDateChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
        this.setState({closeDate: event.target.value as string})
    }

    toggleTestingAllowed = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({useHandwritten: event.target.checked})
    }
    toggleCreateTeacher = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({createTeacher: event.target.checked})
    }

    handleSave = ():void => {
        if (
            this.state.testCode !== null &&
            this.state.testCode !== "" &&
            this.state.password !== null &&
            this.state.password !== "" &&
            this.state.selectPanelGraph !== null
        ) {
            ApiService.adminCreateNewTest(
                this.state.testCode,
                this.state.password,
                this.state.closeDate,
                this.state.selectPanelGraph,
                this.state.useHandwritten,
                this.state.createTeacher
            )
                .then(response => {
                    if (response.msg.length) {
                        this.setState({msg: response.msg})
                    }
                })
        } else {
            this.setState({msg: "ERROR: Could not create Test code: missing information"})
        }
    }



    render() {
        const testList = this.state.currentTests

        return (
            <>
                <h1>Assessment Creation Tool -- <span style={{color:"red"}}>Testing Use Only</span></h1>
                <p><span style={{color:"red",fontWeight:"bold"}}>DO NOT USING THIS TOOL IN PRODUCTION!</span> This tools create a new test code in the system, but does NOT associate it with any groups.</p>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            id={"newTestCode"}
                            name={"newTestCode"}
                            style={{width: "25%"}}
                            label="New Test Code"
                            placeholder="New Test Code"
                            title='New Test Code'
                            onChange={this.handleCodeChange}
                        />
                    </Grid>
                    <FormHelperText style={{width: "25%"}}>New Test code for the test.</FormHelperText>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            id={"newPassword"}
                            name={"newPassword"}
                            style={{width: "25%"}}
                            label="New Password"
                            placeholder="New Password"
                            title='New Password'
                            onChange={this.handlePasswordChange}
                        />
                        <FormHelperText style={{width: "25%"}}>Password for the Test</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <Select id="testingLanguage" value={this.state.selectPanelGraph} onChange={this.handleLanguageChange}>
                            <MenuItem key={0} value={0}>Select Language for This Test</MenuItem>)
                            {testList && (
                                testList.map ((test: PanelGraphWithLanguage) => {
                                    return (
                                        <MenuItem key={test.panelGraphId} value={test.panelGraphId}>{test.languageName} - {PRODUCT_ID_TO_NAME[test.productId]}</MenuItem>
                                    )
                                })
                            )}
                        </Select>
                        <FormHelperText style={{width: "25%"}}>The language subject of the test.</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="Close Date"
                            label="Close Date"
                            type="date"
                            defaultValue={Date()}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={this.handleDateChange}
                        />
                        <FormHelperText style={{width: "25%"}}>Date when the test can not longer be used.</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.useHandwritten}
                                    onChange={this.toggleTestingAllowed}
                                    value="useHandwritten"
                                    inputProps={{"aria-label": "Use handwritten form for written parts of the test."}}
                                />
                            }
                            label="Use Handwritten"
                        />
                        <FormHelperText style={{width: "25%"}}>Use handwritten form for written parts of the
                            test.</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.createTeacher}
                                    onChange={this.toggleCreateTeacher}
                                    value="createTeacher"
                                    inputProps={{"aria-label": "Create a teacher code as well, with the same password."}}
                                />
                            }
                            label="Create Teacher Code"
                        />
                        <FormHelperText style={{width: "25%"}}>Should a teacher code be created with the same password</FormHelperText>
                    </Grid>

                    <Grid container alignItems="center" spacing={5} justifyContent="center" direction="row" style={{padding: "10px"}}>
                        <Grid item xs={12}>
                            <Button size="small" variant="contained" color="primary" onClick={this.handleSave}>Save</Button>
                        </Grid>
                    </Grid>


                </Grid>
                {this.snackBar()}
            </>
    )}
}
