/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import React, {useEffect, useState} from 'react'
import {
    DataGridPro,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    gridClasses,
    GridColDef, GridRowId,
    GridRowSelectionModel
} from "@mui/x-data-grid-pro"
import {reportsStore} from "../../../reports/common/stores/ReportStore"
import {ReportProps} from "../../ReportData"
import {ReportTakeRow} from "../../../reports/class/models/ReportTakeRow"
import {buildReportColumns, SkillColumnData} from "./Columns"
import {PanelSkillEnum} from "../../../../types/types"
import {sidebarStore} from "../testTakerSidebar/SideBarStore"
import {ProctorTypes} from "../../../../util/Constants"
import testTakerSortableTableStore from "./TestTakerSortableTableStore"
import {takeSearchStore} from "../../../common/take/TakeSearchStore"
import {theme} from "../../../../styles/MuiThemes"
import {styled} from "@mui/material"
import {AvantColors} from "../../../../styles/AvantColors"

const StripedDataGrid = styled(DataGridPro)(() => ({
    border: 'none',
    '& .MuiDataGrid-cell': {
        cursor: "default",
        padding: theme.spacing(0, 1),
        whiteSpace: "nowrap",
    },
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: AvantColors.REPORT_BACKGROUND_GRAY_DARK,
    },
    '& .MuiCheckbox-root.Mui-checked': {
        color: AvantColors.avantBlue,
        '&.MuiCheckbox-indeterminate': {
            color: '#cdcdcd',
        },
    },
}))

export function openSidebarWithId(
    row: ReportTakeRow,
    skill?: PanelSkillEnum,
    selfEval?: boolean,
) {
    return () => {
        sidebarStore.takeId = row.takeId
        sidebarStore.openPanel = skill
        sidebarStore.openSelfEval = selfEval
        sidebarStore.openP360 = row.proctortype === ProctorTypes.P360.valueOf()
        sidebarStore.P360Data = row.examData
        sidebarStore.isPlaceC = row.product_package === "plc"
        testTakerSortableTableStore.selectedRow = row
        takeSearchStore.findSidebarInfoForTake(row.takeId)
    }
}

const updateSelectedIds = (ids: GridRowSelectionModel): void => {
    const selectedIds: number[] = []
    ids.forEach((id: GridRowId) => selectedIds.push(Number(id)))
    reportsStore.selectedTakeIds = selectedIds
}

export const TestTakerSortableTable = (props: {reportProps: ReportProps, isMultiLanguage: boolean}) => {
    const [columns, setColumns] = useState<{left: GridColDef[], right: GridColDef[]}>({left: [], right: []})
    const [rows, setRows] = useState<ReportTakeRow[]>([])
    const {reportProps} = props
    const {printOptions} = reportProps

    useEffect(() => {
        reportsStore.selectedTakeIds = []

        if (reportsStore.reportTableData) {
            const rows = reportsStore.reportTableData.reportTakeRows

            // Filter out scaled columns if there aren't any scores in the list
            if (!rows.some((row: ReportTakeRow) => row.readingScaledScore)) {
                const index: number = reportProps.skillColumns.map((col: SkillColumnData) => col.scaledScoreKey).indexOf("readingScaledScore")
                if (index >= 0) {
                    reportProps.skillColumns.splice(index, 1)
                }
            }
            if (!rows.some((row: ReportTakeRow) => row.listeningScaledScore)) {
                const index: number = reportProps.skillColumns.map((col: SkillColumnData) => col.scaledScoreKey).indexOf("listeningScaledScore")
                if (index >= 0) {
                    reportProps.skillColumns.splice(index, 1)
                }
            }

            const reportColumns = buildReportColumns(reportProps.skillColumns, typeof printOptions !== "undefined")
            setRows(rows)
            setColumns(reportColumns)
        }
    }, [reportsStore.reportTableData])

    return (
        <StripedDataGrid
            columns={[...columns.left, ...columns.right]}
            rows={rows}
            initialState={{
                pinnedColumns: {
                    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "report", "lastName", "firstName"],
                },
                sorting: {
                    sortModel: [{ field: 'lastName', sort: 'desc' }]
                }
            }}
            getRowId={(row) => row.takeId}
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : ''
            }
            onRowSelectionModelChange={updateSelectedIds}
            checkboxSelection
            hideFooter
        />
    )
}
