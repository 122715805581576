import React, {Dispatch} from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import {Dialog} from "@material-ui/core"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import {reportsStore} from "../../../common/stores/ReportStore"
import {getNonPrintablePromptsDialogData} from "../PromptsPrintView"
import {ReportSubPaths} from "../../../ReportSubPaths"
import {ReportFilters} from "../../../common/models/ReportFilters"
import {productStore} from "../../../../common/products/ProductStore"
import moment from "moment"
import {groupMasterStore} from "../../../../common/group/GroupMasterStore"
import {Redirect} from "react-router"

interface PrintPromptsModalProps {
    modalOpen: boolean
    setModalShown: Dispatch<boolean>
    allTesttakers: boolean
}

export const PrintPromptsDialogue: React.FC<PrintPromptsModalProps> = ({modalOpen, setModalShown, allTesttakers}) => {

    const [openModal, setOpenModal] = React.useState(modalOpen)
    const [redirectToReport, setRedirectToReport] = React.useState(false)

    const filters: ReportFilters = reportsStore.searchFilters ? reportsStore.searchFilters : {
        groupId: groupMasterStore.defaultGroup!.id,
        productId: productStore.loginProduct!.productId,
        startDate: moment().subtract(6, "months"),
        endDate: moment(),
        recentActivity: true,
        assessmentStart: false
    }

    const onClose = () => {
        setRedirectToReport(true)
        setOpenModal(false)
        setModalShown(false)
    }

    const handleContinue = () => {
        setModalShown(true)
        setOpenModal(false)
    }

    const handleCancel = () => {
        onClose()
    }

    const dialogData = getNonPrintablePromptsDialogData(allTesttakers)
    const reportTakeRows = reportsStore.reportTableData && reportsStore.reportTableData.reportTakeRows
    const pendingTesttakers = reportTakeRows!.filter(instance => dialogData.takeIdsNotPrintable.includes(instance.takeId))
    const completedTestTakers = reportTakeRows!.filter(instance => dialogData.takeIdsComplete.includes(instance.takeId))
    const notHandwrittenTestTakers = reportTakeRows!.filter(instance => dialogData.takesNotHandwritten.includes(instance.takeId))

    if (redirectToReport) {
        return <Redirect to={ReportSubPaths.class(filters)} />
    }


    return (
        <Dialog
            aria-labelledby={"print-prompts-modal"}
            aria-describedby={"print-prompts-modal-test-takers-not-ready-to-be-printed"}
            open={openModal}
            onClose={onClose}
        >
            <DialogTitle id={"print-prompts-dialog-title"}>
                Students Not Ready for Printing:
                {dialogData.takeIdsNotPrintable.length + dialogData.takesNotHandwritten.length + dialogData.takeIdsComplete.length}
            </DialogTitle>
            <DialogContent>
                {dialogData.takeIdsNotPrintable.length > 0 &&
                <>
                    <DialogContentText id={"print-prompts-list-of-students-not-ready-for-printing"}>
                        The following test taker(s) must complete their reading section prior to starting the writing section of the test.
                    </DialogContentText>
                    <List>
                        {dialogData.takeIdsNotPrintable.length > 0 &&
                        pendingTesttakers.map((tt) => {
                                return (
                                    <ListItem key={tt.takeId}>
                                        {tt.firstName} {tt.lastName} - {tt.resumeKey}
                                    </ListItem>
                                )
                            }
                        )}
                    </List>
                </>
                }
                {dialogData.takeIdsComplete.length > 0 &&
                <>
                        <DialogContentText id={"print-prompts-list-of-students-not-ready-for-printing"}>
                            The following test taker(s) have completed their test or are in progress and would need to be reset for re-printing.
                        </DialogContentText>
                        <List>
                            {completedTestTakers.map((tt) => {
                                    return (
                                        <ListItem key={tt.takeId}>
                                            {tt.firstName} {tt.lastName} - {tt.resumeKey}
                                        </ListItem>
                                    )
                                }
                            )}
                    </List>
                    </>
                }
                {dialogData.takesNotHandwritten.length > 0 &&
                <>
                    <DialogContentText id={"print-prompts-list-of-students-not-ready-for-printing"}>
                        The following test taker(s) are not in a handwritten class and will not be printed.
                    </DialogContentText>
                    <List>
                        {notHandwrittenTestTakers.map((tt) => {
                                return (
                                    <ListItem key={tt.takeId}>
                                        {tt.firstName} {tt.lastName} - {tt.resumeKey}
                                    </ListItem>
                                )
                            }
                        )}
                    </List>
                </>
                }
                <DialogActions>
                    <Button
                        onClick={handleContinue}
                        disabled={dialogData.takeIdsPrintable.length < 1}
                    >
                        Continue
                    </Button>
                    <Button
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}
