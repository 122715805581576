/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import * as React from "react"
import {ReportProps} from "../../ReportData"
import {PrintOptions} from "../../../reports/common/components/PrintViewContainer"
import {AvantExpansionPanel} from "../../../generic-components/AvantExpansionPanel"
import {DateRangeHeaderText} from "../../../reports/common/components/report-page/DateRangeHeaderText"
import {theme} from "../../../../styles/MuiThemes"
import {Divider} from "@material-ui/core"
import {AvantCircularProgress} from "../../../common/loaders/AvantCircularProgress"
import {ReportTakeRow} from "../../../reports/class/models/ReportTakeRow"
import {MULTIPLE_LANGUAGES} from "../../../../util/Constants"
import {observer} from "mobx-react-lite"
import {sidebarStore} from "../testTakerSidebar/SideBarStore"
import TestTakerSidebarContainer from "../testTakerSidebar/TestTakerSidebar"
import {TestTakerSortableTable} from "./TestTakerSortableTable"

export interface HeaderRow {
    id: keyof ReportTakeRow
    label: string
    align?: "left"
    width?: number
}

const TestTakerTableContainer = observer((props: ReportProps) => {
    const {printOptions, filters, tableData, groupInfo} = props
    const {startDate, endDate} = filters
    const isMultiLanguage = groupInfo && groupInfo.language === MULTIPLE_LANGUAGES

    if (printOptions === PrintOptions.SUMMARY || !tableData) return <></>

    return (
        <AvantExpansionPanel
            title={"List"}
            expanded={printOptions != null ? true : undefined}
        >
            <>
                <DateRangeHeaderText
                    header={"Test Instances"}
                    from={startDate}
                    to={endDate}
                    style={{margin: theme.spacing(3)}}
                />
                <Divider />
                {!tableData ? (
                    <AvantCircularProgress />
                ) : (
                    <TestTakerSortableTable
                        reportProps={props}
                        isMultiLanguage={isMultiLanguage || false}
                    />
                )}
                {sidebarStore.open &&
                    <TestTakerSidebarContainer />
                }
            </>
        </AvantExpansionPanel>
    )
})

export default TestTakerTableContainer
