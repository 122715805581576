import React, {ChangeEvent} from 'react'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid, Paper, Table, TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core"
import {reportsStore} from "../reports/common/stores/ReportStore"
import ApiService from "../../services/ApiService"
import Button from "../../components/Button/Button";
import {AvantCircularProgress, CircularProgressSize} from "../common/loaders/AvantCircularProgress"
import {theme} from "../../styles/MuiThemes";
import {H4Text} from "../../styles/AvantTypography";
import {AvantColors} from "../../styles/AvantColors";
import {Alert} from "react-bootstrap";

export interface TestTakerEditData {
    firstName?: string
    lastName?: string
    loginName?: string
    takeId?: number
}

interface TestTakerEditProps {
    isAdmin: boolean
    takeId?: number
    onClose: () => void
    onSuccessfulUpdate?: (saveResponse: TestTakerEditData) => void
}

interface TestTakerEditState {
    uploadInProgress: boolean
    showResponse: boolean
    testTakerEditData: TestTakerEditData
}

class TestTakerEdit extends React.Component<TestTakerEditProps, TestTakerEditState> {
    constructor(props: TestTakerEditProps) {
        super(props)

        this.state = {
            uploadInProgress: false,
            showResponse: false,
            testTakerEditData: {
                firstName: "",
                lastName: "",
                loginName: "",
                takeId: undefined
            }
        }
    }

    componentDidMount() {
        this.getTestTakerData()
    }

    getTestTakerData = () => {
        const takeId = Number(this.props.takeId)
        reportsStore.findIndividualReport(takeId).then(() => {
            const instance = reportsStore.individualReport

            this.setState({
                testTakerEditData: {
                    firstName: instance!.firstName,
                    lastName: instance!.lastName,
                    loginName: instance!.code,
                    takeId
                }
            })
        })
    }

    updateTestTakerData = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget

        this.setState(prevState => ({
            testTakerEditData: {
                ...prevState.testTakerEditData,
                [name]: value.trim()
            }
        }))
    }

    saveTestTakerData = () => {
        const {testTakerEditData} = this.state
        const {isAdmin} = this.props

        this.setState({
            uploadInProgress: true
        })

        if (isAdmin === true) {
            ApiService.clientSupportSaveTestTaker(testTakerEditData)
                .then(response => {
                    this.setState({
                        showResponse: true,
                        uploadInProgress: false,
                    })
            })


        }
        else {
            ApiService.saveTestTaker(testTakerEditData)
                .then(response => {
                    if (this.props.onSuccessfulUpdate !== undefined) {
                        this.props.onSuccessfulUpdate(response as TestTakerEditData)
                    }

                    this.setState({
                        showResponse: true,
                        uploadInProgress: false,
                    })
                }).catch(_ => {
                    this.setState({
                        showResponse: false,
                        uploadInProgress: false,
                    })
            })
        }
    }

    render() {
        const {onClose} = this.props
        const {uploadInProgress, testTakerEditData, showResponse} = this.state
        const renderData = Object.keys(testTakerEditData).filter(data => data !== "takeId")

        return (
            <Dialog open={true} onClose={onClose} maxWidth="md" scroll="paper">
                <DialogTitle disableTypography={true} style={{padding: theme.spacing(2), textAlign: "center"}}>
                    <Grid container={true} justifyContent="center">
                        {uploadInProgress &&
                            <Grid item style={{paddingRight: 10, paddingTop: 1}}>
                                <AvantCircularProgress size={CircularProgressSize.SMALL}/>
                            </Grid>
                        }
                            <Grid item>
                                <H4Text style={{fontWeight: 900, color: AvantColors.REPORT_FONT_COLOR_BLACK}}>Test Taker Edit</H4Text>
                            </Grid>
                    </Grid>
                </DialogTitle>
                <Divider style={{width: "100%", marginBottom: 10}}/>

                <DialogContent>
                    <TableContainer component={Paper} style={{borderRadius: "6px", border: "1px solid #CDCDCD", boxShadow: "none"}}>
                        <Table style={{marginBottom: -1}}>
                        <TableHead style={{backgroundColor: "#F3F3F3"}}>
                            <TableRow>
                                <TableCell align="left">First Name</TableCell>
                                <TableCell align="left">Last Name</TableCell>
                                <TableCell align="left">Login Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {renderData.map((data, idx) => {
                                    return (
                                        <TableCell component="th" scope="row">
                                            <TextField
                                                type="text"
                                                name={data}
                                                placeholder={data}
                                                value={testTakerEditData[data]}
                                                onChange={this.updateTestTakerData}
                                                inputProps={{
                                                    "data-idx": idx,
                                                    "data-lpignore": true,
                                                }}
                                            />
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableBody>
                        </Table>
                    </TableContainer>

                    <div style={{marginBottom: -10, marginTop: 10}}>
                        {showResponse && <Alert style={{borderRadius: 6}} variant={'success'}>Test taker successfully updated!</Alert>}
                    </div>

                    <Grid container={true} justifyContent="center" spacing={2}
                                  style={{marginBottom: 10, marginTop: 25}}>
                                <Grid item={true} xs={3}>
                                    <Button
                                        className="avant-button--primary avant-button--block avant-button--default"
                                        block={true}
                                        disabled={uploadInProgress}
                                        testId="process-students"
                                        onClick={this.saveTestTakerData}
                                    >
                                        SAVE
                                    </Button>
                                </Grid>
                                <Grid item={true} xs={3}>
                                    <Button
                                        className="avant-button--secondary avant-button--block avant-button--default"
                                        block={false}
                                        disabled={uploadInProgress}
                                        testId="cancel-students"
                                        onClick={onClose}
                                    >
                                        CANCEL
                                    </Button>
                                </Grid>
                            </Grid>
                </DialogContent>
            </Dialog>
        )
    }
}

export default TestTakerEdit
