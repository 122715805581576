import React, {useState, useCallback} from "react"
import {
    Box,
    Button,
    Container,
    FormControl,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    TextField,
    SelectChangeEvent,
    Typography
} from "@mui/material"
import {messageStore} from "../../app/common/messages/MessageStore"
import AdvanceApi, {PanelGraph} from "../../services/AdvanceApi"
import {H2Text} from "../../styles/AvantTypography"

const AdvanceReportChangeLanguages: React.FC = () => {
    const [availablePanelGraphs, setAvailablePanelGraphs] = useState<PanelGraph[]>([])
    const [usersPanelGraphs, setUsersPanelGraphs] = useState<PanelGraph[]>([])
    const [email, setEmail] = useState<string>("")
    const [emailError, setEmailError] = useState<string>("")

    const handleLanguageChange = useCallback(
        (newPanelGraph: string, oldPanelGraphs: number, index: number) => {
            const id = parseInt(newPanelGraph);
    
            const updatedAvailablePanelGraphs = availablePanelGraphs.map((item) => {
                let updatedItem = { ...item };
                if (item.id === index) {
                    updatedItem = { ...item, disabled: false, id: index };
                }

                if (item.panelGraphId === id) {
                    updatedItem = { ...item, disabled: true, id: index, oldPanelGraphs };
                }
             
                return updatedItem;
            });
            setAvailablePanelGraphs(updatedAvailablePanelGraphs);
        },
        [availablePanelGraphs]
    );

    const handleEmailChange = (value: string) => {
        setEmail(value)
        setEmailError("")
    }

    const getAdvanceLang = () => {
        if (email) {
            AdvanceApi.getAdvanceLanguages(email)
                .then((languages) => {
                    if (languages?.availablePanelGraphs?.length) {
                        const updatedAvailablePanelGraphs = [
                            ...languages.availablePanelGraphs.map((graph) => ({
                                ...graph,
                                disabled: false,
                                id: ""
                            }))
                        ];
                        setAvailablePanelGraphs(updatedAvailablePanelGraphs);
                        setUsersPanelGraphs(languages.usersPanelGraphs || []);
                    } else {
                        messageStore.setErrorMessage("No panelgraph found with this user");
                        setAvailablePanelGraphs([]);
                        setUsersPanelGraphs([]);
                    }
                })
                .catch((error) => {
                    messageStore.setErrorMessage("An error occurred while fetching languages");
                    setAvailablePanelGraphs([]);
                    setUsersPanelGraphs([]);
                });
        }
    };

    const submitLanguage = () => {
        const payloads = availablePanelGraphs
            .filter((item) => item.disabled && item.panelGraphId !== 0)
            .map((item) => ({
                oldPanelGraph: item.oldPanelGraphs,
                newPanelGraph: item.panelGraphId
            }));
    
        if (email && payloads.length !== 0) {
            AdvanceApi.updateLanguages(payloads, email)
                .then((response) => {
                    if (!response.error) {
                        messageStore.setInfoMessage(response.message);
                        setAvailablePanelGraphs([]);
                        setUsersPanelGraphs([]);
                        setEmail("");
                    } else {
                        messageStore.setErrorMessage(response.errorDescription);
                    }
                    setEmailError("");
                })
                .catch((error) => {
                    messageStore.setErrorMessage("An error occurred while updating languages");
                });
        } else {
            messageStore.setErrorMessage("You need to select at least one panelGraph.");
        }
    };
    

    return (
        <Container sx={{marginTop: 2}}>
            <Box sx={{marginBottom: 2, marginTop: 2}}>
                <H2Text>Advance Users Change Language Tool</H2Text>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <FormControl fullWidth>
                        <FormLabel>Enter Email</FormLabel>
                        <TextField
                            type="text"
                            value={email}
                            onChange={(e) => handleEmailChange(e.target.value)}
                            inputProps={{"data-tst-id": "Email"}}
                        />
                        {emailError && <Typography color="error">{emailError}</Typography>}
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        data-tst-id="apply-advance-report-filters"
                        sx={{float: "left", marginTop: 3, height: "51px"}}
                        onClick={getAdvanceLang}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>
            <br />
            {availablePanelGraphs.length > 0 && (
                <>
                    <Grid container spacing={2} sx={{fontWeight: "bold"}}>
                        <Grid item xs={4}>
                            Assigned PanelGraphs
                        </Grid>
                        <Grid item xs={4}>
                            Available PanelGraphs
                        </Grid>
                    </Grid>
                    <br />
                    {usersPanelGraphs.map((item, index) => (
                        <Grid container spacing={2} key={index} sx={{mb: 1}}>
                            <Grid item xs={4}>
                                {1 + index}: {item.name}
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <Select
                                        onChange={(e: SelectChangeEvent<string>) =>
                                            handleLanguageChange(e.target.value as string, item.panelGraphId, index)
                                        }
                                        inputProps={{"data-tst-id": "languages"}}
                                        sx={{height: "36px"}}
                                    >
                                        {availablePanelGraphs
                                            .filter(
                                                (language) =>
                                                    !usersPanelGraphs.some(
                                                        (userPanel) => userPanel.panelGraphId === language.panelGraphId
                                                    )
                                            )
                                            .map((language) => (
                                                <MenuItem
                                                    key={language.panelGraphId}
                                                    value={language.panelGraphId}
                                                    disabled={language.disabled}
                                                >
                                                    {language.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    ))}
                    <Button
                        variant="contained"
                        color="primary"
                        data-tst-id="apply-advance-report-filters"
                        sx={{float: "right", marginRight: "260px", marginTop: 0, height: "51px"}}
                        onClick={submitLanguage}
                    >
                        Submit
                    </Button>
                    <br />
                    <br />
                </>
            )}
            <br />
        </Container>
    )
}

export default AdvanceReportChangeLanguages
