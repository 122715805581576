import Link, {LinkProps} from "@material-ui/core/Link"
import Typography, {TypographyProps} from "@material-ui/core/Typography"
import * as React from "react"
import {AvantColors} from "./AvantColors"

interface AvantTypographyProps extends Omit<TypographyProps, "variant"> {
    fontWeight?: "bold" | "normal" | 500
    textTransform?: "uppercase" | "none"
    lineHeight?: string
}

const AvantTypography = (props: AvantTypographyProps & TypographyProps) => {
    const {style, fontWeight, textTransform, lineHeight, ...rest} = props
    return (
        <Typography
            style={{
                fontWeight: fontWeight ? fontWeight : undefined,
                textTransform: textTransform ? textTransform : undefined,
                lineHeight: lineHeight ? lineHeight : undefined,
                ...style
            }}
            {...rest}
        />
    )
}

export const H1Text = (props: AvantTypographyProps) => (
    <AvantTypography variant={"h1"} {...props}/>
)
export const H2Text = (props: AvantTypographyProps) => (
    <AvantTypography variant={"h2"} {...props}/>
)
export const H3Text = (props: AvantTypographyProps) => (
    <AvantTypography variant={"h3"} {...props}/>
)
export const H4Text = (props: AvantTypographyProps) => (
    <AvantTypography variant={"h4"} {...props}/>
)
export const H5Text = (props: AvantTypographyProps) => (
    <AvantTypography variant={"h5"} {...props}/>
)
export const H6Text = (props: AvantTypographyProps) => (
    <AvantTypography variant={"h6"} {...props}/>
)

export const LabelText = (props: AvantTypographyProps) => (
    <AvantTypography variant={"subtitle1"} {...props}/>
)
export const SmallLabelText = (props: AvantTypographyProps) => (
    <AvantTypography variant={"subtitle2"} {...props}/>
)
export const MicroLabelText = (props: AvantTypographyProps) => (
    <AvantTypography variant={"caption"} {...props}/>
)
export const BodyText = (props: AvantTypographyProps) => (
    <AvantTypography variant={"body1"} {...props}/>
)

export const WritingResponseText = (props: AvantTypographyProps) => (
    <BodyText style={{whiteSpace: "pre-line"}} {...props}/>
)

export const RTLWritingResponseText = (props: AvantTypographyProps) => (
    <BodyText style={{
        direction: "rtl",
        unicodeBidi: "bidi-override",
        whiteSpace: "pre-line",
        textAlign: "right"
    }} {...props}/>
)

export const SmallBodyText = (props: AvantTypographyProps & {}) => (
    <AvantTypography variant={"body2"} {...props}/>
)
export const MicroText = (props: AvantTypographyProps) => {
    const {style, ...rest} = props
    return <AvantTypography variant={"caption"}
                            style={{fontSize: 10, lineHeight: "10px", textTransform: "none", ...style}} {...rest}/>
}

export const ErrorText = (props: AvantTypographyProps) => {
    const {style, ...rest} = props
    return <AvantTypography style={{color: AvantColors.ERROR_TEXT, ...style}} variant={"body1"} {...rest}/>
}

export const ErrorBody = (props: AvantTypographyProps) => {
    const {style, ...rest} = props
    return (<AvantTypography variant={"body1"} style={{marginBottom:"5px"}} {...rest}/>)
}

export const ErrorTitle = (props: AvantTypographyProps) => {
    const {style, ...rest} = props
    return (
        <AvantTypography
            variant={"h2"}
            style={{
                color: AvantColors.REPORT_FONT_COLOR_GRAY_1,
                fontWeight: "lighter",
                margin: "30px 0 30px 0",
                fontSize: "30px",
                textAlign: "center",
                ...style
            }}
            {...rest}
        />
    )
}


export const LinkText = (props: LinkProps & { disabled?: boolean }) => {
    const {style, disabled, children, ...rest} = props
    if (disabled) {
        return <SmallBodyText children={children} style={{color: "#CDCDCD", ...style}}/>
    }
    return <Link style={{...style}} children={children} {...rest}/>
}
