import React, {FC} from "react"
import {DialogContentText} from "@material-ui/core"
import {AvantColors} from "../../styles/AvantColors"
import {PLACE_PRODUCT_ID} from "../../util/Constants"
import { productStore } from "../../app/common/products/ProductStore"

interface AgreementContentProps {
    testName: string
}
export const AgreementContent: FC<AgreementContentProps> = ({testName}) => (      
    <> 
         { 
          (productStore && productStore.driver && productStore.driver.productId.value() === PLACE_PRODUCT_ID.value()) ?
            <>
        <DialogContentText style={{fontSize: "15px", paddingTop: 10, lineHeight: "1.25", color: AvantColors.REPORT_FONT_COLOR_GRAY_2, margin: 10}}>
            This {testName} test utilizes spontaneous, unrehearsed situations to provide you and your institution accurate information for proper placement in a language program. The purpose of this assessment is to measure what you are able to do with the language without support of any translators, reference material (including taking notes during the speaking section) or assistance of any kind. Therefore, in order to continue with the test, please agree to the following guidelines and policies:
        </DialogContentText>  
         </> :
         <>
        <DialogContentText style={{fontSize: "15px", paddingTop: 10, lineHeight: "1.25", color: AvantColors.REPORT_FONT_COLOR_GRAY_2, margin: 10}}>
            This {testName} test utilizes spontaneous, unrehearsed situations to measure real-world language proficiency scoring at levels Novice through Advanced. The purpose of this assessment is to measure what you are able to do with the language without support of any translators, reference material (including taking notes during the speaking section) or assistance of any kind. Therefore, in order to continue with the test, please agree to the following guidelines and policies:
        </DialogContentText></>}

        <li>I understand that clicking outside of the test page or attempting to open other windows is not allowed and will be tracked. Multiple attempts will log me out of the test.</li>
        <li>I understand that the use of dictionaries, textbooks, cell phones, paper, pens, or other support materials (paper-based or electronic) are not allowed, unless I am completing a handwritten response.</li>
        <li>I will not use Google Translate or any other translation aid.</li>
        <li>I will not write down a speaking/expressive response and read/use it as my recorded response. I understand and accept that this will lower my score for that response, and it may be scored as a ZERO because it involves the use of paper or reference material.</li>
        <li>I will not communicate with anyone other than the proctor during the test or receive any advice or help from anyone during the test.</li>
        <li>I will not use copy-paste, printing, screen capture apps/tools, or messaging tools.</li>
        <li>I will not take or use notes of any kind for any portion of the test, including the speaking/expressive section.</li>
        <li>I will not share information about questions or prompts on the test with anyone.</li>
        <li>I will not use violent, profane or inappropriate language in my responses.</li>
        <li>I understand that violating any of these rules may result in invalidating my test results and agree to these stated guidelines and other guidelines included in the Test Taker Guide.</li>
        </>
)

