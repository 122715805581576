import {createStyles, List, ListItem, makeStyles} from "@material-ui/core"
import {ListItemTextProps} from "@material-ui/core/ListItemText"
import React from "react"
import {AvantColors} from "../../../../styles/AvantColors"
import {SmallBodyText, SmallLabelText} from "../../../../styles/AvantTypography"
import {theme} from "../../../../styles/MuiThemes"

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            "& > li:nth-child(even)": {
                backgroundColor: AvantColors.REPORT_BACKGROUND_GRAY_LIGHT,
            }
        }
    }))

export const SidebarList: React.FC = ({children}) => {
    const classes = useStyles()

    return (
        <List
            dense={true}
            disablePadding={true}
            style={{width: "100%", marginBottom: theme.spacing(3), marginTop: theme.spacing(1)}}
            classes={classes}
            component={"ul"}
        >
            {children}
        </List>
    )
}

export const SidebarListItem: React.FC<ListItemTextProps> = ({primary, secondary}) => {
    if (!secondary && secondary !== 0) {
        return null
    }

    // if the words on either header or subject are more than 20 characters we will add more space to the height of our rows
    const longtext = Math.max(Math.floor(String(primary).length / 20), Math.floor(String(secondary).length / 20))

    return (
        <ListItem
            disableGutters={true}
            style={{justifyContent: "space-between", padding: theme.spacing(0, 3), height: 32 + longtext * 16}}
        >
            <SmallLabelText>{primary}</SmallLabelText>
            {typeof secondary === "string" ? (
                <SmallBodyText align={'right'} style={{maxWidth: '100%', wordWrap: 'break-word'}}>{secondary}</SmallBodyText>
            ) : (<>{secondary}</>)}
        </ListItem>
    )
}
