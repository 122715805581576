/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/
import React from "react"
import {productStore} from "../../app/common/products/ProductStore"
import {
    ApiPanelGraph,
    Panel,
    TakePanel,
    ApiPanelGraphEdge,
    ApiTakePanelStateEnum,
    PanelSkillEnum,
    Take
} from "../../types/types"
import {Section} from "../../app/section/models/Section"
import {STAMP_4SE_PRODUCT_ID, APT_PRODUCT_ID, ProctorTypes} from "../../util/Constants"
import Button from "../Button/Button"
import RenderSkill from "../RenderSkill/RenderSkill"
import RenderStartedSkill from "../RenderStartedSkill/RenderStartedSkill"
import {authStore} from "../../app/common/authentication/AuthStore"
import moment from "moment"
import LanguageUtils from "../../util/LanguageUtils"
import TestTakerAgreementModal from "../Dashboard/TestTakerAgreement"
import PTHandler from "../../services/PTHandler"

async function endPTSession() {
    await PTHandler.endExam()
}
interface Props {
    take: Take
    panelGraph: ApiPanelGraph
    agreeToEULA: () => void
    openModal: (currentPanelId: number, currentTestName: string, selfEvaluation: boolean) => void
    logout: () => void
    currentSection: Section | null
}
const DashboardContainer: React.FC<Props> = ({take, panelGraph, agreeToEULA, openModal, logout, currentSection}) => {
    const skillToTestName: Map<string, string> = new Map([
        [PanelSkillEnum.WRITING, "Writing"],
        [PanelSkillEnum.READING, "Reading"],
        [PanelSkillEnum.LISTENING, "Listening"],
        [PanelSkillEnum.SPEAKING, "Speaking"]
    ])
    const profile = take.profile

    const authUser = authStore && authStore.auth ? authStore.auth : null
    let firstName = ""
    let lastName = ""
    if (profile) {
        if (profile.firstName) {
            firstName = profile.firstName
        }
        if (profile.lastName) {
            lastName = profile.lastName
        }
    }
    const date = moment(take.created).format("MMMM D, YYYY")

    const panelSkills = new Set<string>()
    panelGraph.panelGraphEdges.forEach((edge: ApiPanelGraphEdge) => {
        const panel = edge.panelByNextPanelId
        if (panel.skill === undefined) {
            throw new Error("Panel skill is undefined")
        }
        panelSkills.add(panel.skill)
    })
    const availablePanels = new Map<string, Panel>()
    take.availablePanels.forEach((panel: Panel) => {
        if (panel.skill === undefined) {
            throw new Error("Panel skill is undefined")
        }
        availablePanels.set(panel.skill, panel)
    })

    let startedPanel: Panel | undefined
    let startedTakePanel: TakePanel | undefined
    const completedPanelSkills = new Set<string>()
    take.takePanels.forEach((takePanel: TakePanel) => {
        if (takePanel.panelState === ApiTakePanelStateEnum.STARTED) {
            startedPanel = takePanel.panel
            startedTakePanel = takePanel
        }
        if (takePanel.panelState === ApiTakePanelStateEnum.COMPLETED) {
            completedPanelSkills.add(takePanel.panel.skill)
        }
    })

    let languageName = LanguageUtils.removeTilde(productStore.loginProduct!!.contentAreaName)
    languageName = LanguageUtils.removeMonolingual(languageName)

    const testName = productStore.loginProduct == null ? "Test" : languageName + " test"

    completedPanelSkills.forEach((skill: string) => {
        if (availablePanels.has(skill)) {
            availablePanels.delete(skill)
        }
    })

    const isProctorTrack =
        productStore.loginProduct!.proctorType === ProctorTypes.PROCTORTRACK.valueOf() ||
        productStore.loginProduct!.proctorType === ProctorTypes.AVANTPROCTOR.valueOf() ||
        productStore.loginProduct!.proctorType === ProctorTypes.EDUPROCTOR.valueOf()
    const isSSOLogin = authUser && authUser.ssoId ? true : false
    const isComplete = availablePanels.size === 0

    const is4Se = productStore.driver!!.productId.value() === STAMP_4SE_PRODUCT_ID.value()
    const isAPT = productStore.driver!!.productId.value() === APT_PRODUCT_ID.value()

    // Leading space intentionally placed
    let fullName = ""
    if (firstName || lastName) {
        fullName = ` ${firstName} ${lastName}`
    }

    const renderReading =
        ((startedPanel !== undefined && startedPanel.skill !== PanelSkillEnum.READING) || startedPanel === undefined) &&
        panelSkills.has(PanelSkillEnum.READING)
    const renderWriting =
        ((startedPanel !== undefined && startedPanel.skill !== PanelSkillEnum.WRITING) || startedPanel === undefined) &&
        panelSkills.has(PanelSkillEnum.WRITING)
    const renderListening =
        ((startedPanel !== undefined && startedPanel.skill !== PanelSkillEnum.LISTENING) ||
            startedPanel === undefined) &&
        panelSkills.has(PanelSkillEnum.LISTENING)
    const renderSpeaking =
        ((startedPanel !== undefined && startedPanel.skill !== PanelSkillEnum.SPEAKING) ||
            startedPanel === undefined) &&
        panelSkills.has(PanelSkillEnum.SPEAKING)

    return (
        <>
            {!isComplete ? (
                <>
                    <h3 className="dashboard__title">{`Welcome${fullName}!`}</h3>
                    <p className="dashboard__date">{`${testName} started ${date}`}</p>

                    {!is4Se && !isAPT && <TestTakerAgreementModal testName={"STAMP"} agree={() => agreeToEULA()} />}

                    {!isSSOLogin && (
                        <div className="dashboard__not-you">
                            Not you?
                            {"\u00A0"}
                            <a href="javascript:void(0)" className="dashboard__not-you__link" onClick={logout}>
                                Click to return to login page
                            </a>
                        </div>
                    )}

                    {is4Se && (
                        <h4 className="dashboard__click-animal">Click on the animal the proctor tells you to.</h4>
                    )}
                </>
            ) : (
                <>
                    <p className="dashboard__title">Congratulations! You have completed the test!</p>
                    <p className="dashboard__subtitle">All of your answers have been saved.</p>
                    {isProctorTrack && setTimeout(endPTSession, 5 * 1000) && (
                        <>
                            <p className="dashboard__subtitle">
                                We will attempt to shutdown the proctored session in 30 seconds.
                            </p>
                            <script>console.log("beforeTimeout");setTimeout(endPTSession, 5 * 1000);</script>
                        </>
                    )}
                </>
            )}
            {startedPanel !== undefined && startedTakePanel !== undefined && (
                <RenderStartedSkill
                    startedPanel={startedPanel}
                    startedTakePanel={startedTakePanel}
                    openModal={openModal}
                    panelGraph={panelGraph}
                    currentSection={currentSection}
                    skillToTestName={skillToTestName}
                />
            )}

            <div className={"dashboard__skills"}>
                {renderReading && (
                    <RenderSkill
                        availablePanel={availablePanels}
                        skill={PanelSkillEnum.READING}
                        testName="Reading"
                        takePanel={take.takePanels}
                        completedPanelSkill={completedPanelSkills}
                        openModal={openModal}
                        panelGraph={panelGraph}
                    />
                )}
                {renderWriting && (
                    <RenderSkill
                        availablePanel={availablePanels}
                        skill={PanelSkillEnum.WRITING}
                        testName="Writing"
                        takePanel={take.takePanels}
                        completedPanelSkill={completedPanelSkills}
                        openModal={openModal}
                        panelGraph={panelGraph}
                    />
                )}
                {renderListening && (
                    <RenderSkill
                        availablePanel={availablePanels}
                        skill={PanelSkillEnum.LISTENING}
                        testName="Listening"
                        takePanel={take.takePanels}
                        completedPanelSkill={completedPanelSkills}
                        openModal={openModal}
                        panelGraph={panelGraph}
                    />
                )}
                {renderSpeaking && (
                    <RenderSkill
                        availablePanel={availablePanels}
                        skill={PanelSkillEnum.SPEAKING}
                        testName="Speaking"
                        takePanel={take.takePanels}
                        completedPanelSkill={completedPanelSkills}
                        openModal={openModal}
                        panelGraph={panelGraph}
                    />
                )}
            </div>
            {isComplete && (
                <div>
                    <Button type="button" className="avant-button--primary dashboard__button" onClick={logout}>
                        LOGOUT
                    </Button>
                </div>
            )}
        </>
    )
}

export default DashboardContainer
