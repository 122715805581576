/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import * as React from "react"
import {useEffect, useRef, useState} from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import {Box, Theme} from "@mui/material"
import {findFilters, ReportRouteProps} from "../reports/common/components/ReportsPage"
import {ReportFilters} from "../reports/common/models/ReportFilters"
import {reportsStore} from "../reports/common/stores/ReportStore"
import {getReportStructure, ReportStructure} from "./ReportStructures"
import {DataLoadTracker, ReportProps} from "./ReportData"
import {groupMasterStore} from "../common/group/GroupMasterStore"
import {observer} from "mobx-react-lite"
import {PrintOptions} from "../reports/common/components/PrintViewContainer"
import {BlueReportType} from "../reports/common/models/BlueReportType"

const useReportPageStyle = makeStyles((theme: Theme) => ({
   root: {
      // TODO: Remove the below value when we remove the link to classic reports
      // marginTop: 50,
   },
   titleContainer: {
      paddingBottom: theme.spacing(2),
      display: "flex",
      flexGrow: 1,
   },
   titleControlContainer: {
      display: "flex",
      marginLeft: "auto",
      justifyContent: "flex-start"
   },
   searchComponentContainer: {
      paddingBottom: theme.spacing(2),
   },
   testInstanceListContainerTextOnly: {
      minHeight: 480,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
   },
   printAndDownloadButtons: {
      marginLeft: theme.spacing(2)
   }
}))

const reportTypes = [
    "districts",
    "schools",
    "classes",
]

export function getReportTypeFromURL(): BlueReportType {
    const urlArray = window.location.pathname.split("/")
    const typeIdx = urlArray.findIndex(val => reportTypes.includes(val))
    const type = urlArray[typeIdx]
    return BlueReportType[BlueReportType[reportTypes.indexOf(type)]]
}

const ReportContainer = observer((props: ReportRouteProps) => {
    const [filters, setFilters] = useState<ReportFilters>(findFilters(props))
    const [reportStructure, setReportStructure] = useState<ReportStructure>({})
    const [reportType, setReportType] = useState<BlueReportType>(BlueReportType.CLASS)
    const [result, setResult] = useState<DataLoadTracker>({dataIsLoaded: () => false})
    const prevProps = useRef({filters: filters, url: props.location.pathname})
    const repType = getReportTypeFromURL()

    useEffect(() => {
        if (JSON.stringify(reportStructure) === "{}") {
            getReportData(filters)
        }

        const fils: ReportFilters = findFilters(props)
        if (JSON.stringify(prevProps.current.filters) !== JSON.stringify(fils) || prevProps.current.url !== props.location.pathname) {
            getReportData(fils)
            setFilters(fils)
            prevProps.current.filters = fils
            prevProps.current.url = props.location.pathname
        }

        function getReportData(filters: ReportFilters) {

            const reportStruct = getReportStructure(filters.productId, repType)
            if (reportStruct.data) {
                setResult(reportStruct.data(filters, repType))
            }

            setReportType(repType)
            setReportStructure(reportStruct)
        }
    }, [props, reportStructure])

    const reloadReport = (): void => {
        if (reportStructure.data) {
            setResult(reportStructure.data(filters, repType))
        }
    }

    if (reportStructure && (result && result.dataIsLoaded())) {
        document.title = `Avant | ${reportStructure.title}`
        return <Report reportStructure={reportStructure} filters={filters} reloadReport={reloadReport} reportType={reportType} />
    } else {
        return <></>
    }
})

interface ReportPageProps {
    reportStructure: ReportStructure,
    filters: ReportFilters,
    printOptions?: PrintOptions,
    reloadReport: () => void,
    reportType?: BlueReportType
}
export const Report = observer((props: ReportPageProps) => {
    const {reportStructure, filters, printOptions, reloadReport, reportType} = props
    const classes = useReportPageStyle()
    const {Header, SummaryCharts, TestControls, TestInstancesList, SchoolLocationList} = reportStructure
    const reportProps: ReportProps = {
        groupInfo: groupMasterStore.groupInfo,
        printOptions: printOptions,
        filters: filters,
        graphData: reportsStore.reportGraphData,
        tableData: reportsStore.reportTableData,
        skillColumns: reportStructure.TestInstancesListColumns!,
        // TODO: update this to handle multilanguage teacher reports
        isMultiLanguage: reportType === BlueReportType.DISTRICT || reportType === BlueReportType.SCHOOL
    }

    if (reportsStore.districtReportData) {
        reportProps.adminReportData = reportsStore.districtReportData
    } else {
        reportProps.adminReportData = reportsStore.schoolReportData
    }

    return (
        <Box className={classes.root}>
            {Header && <Header {...reportProps} />}
            {SummaryCharts && <SummaryCharts {...reportProps} />}
            {TestControls && <TestControls reloadReport={reloadReport} {...reportProps} />}
            {TestInstancesList && <TestInstancesList {...reportProps} />}
            {SchoolLocationList && <SchoolLocationList reportType={reportType} {...reportProps} />}
        </Box>
    )
})

export default ReportContainer
