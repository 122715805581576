import {AccordionProps, withStyles} from "@material-ui/core"
import Accordion from "@material-ui/core/Accordion"
import {ExpandMore} from "@material-ui/icons"
import * as React from "react"
import {AvantColors} from "../../styles/AvantColors"
import {H2Text} from "../../styles/AvantTypography"
import AccordionSummary from "@material-ui/core/AccordionSummary"

interface AvantExpansionPanelProps extends AccordionProps {
    title: string
    score?: string
    // children: React.ReactNode
    children: JSX.Element
}

export const AvantExpansionPanel = (props: AvantExpansionPanelProps) => {
    const {title, score, children, ...rest} = props

    const [inputValue, setInputValue] = React.useState(false)

    const handleAccordionChange = () => {
        setInputValue(!inputValue)
    }

    return (
        <AvantExpansionPanelRoot defaultExpanded={true} onChange={handleAccordionChange} {...rest}>
            <AvantExpansionPanelSummary expandIcon={<ExpandMore/>}>
                <H2Text>
                    {title}
                </H2Text>
                {
                    !inputValue &&
                    <H2Text>
                        {score}
                    </H2Text>
                }
            </AvantExpansionPanelSummary>
            {children}
        </AvantExpansionPanelRoot>
    )
}

const borderRadius = 6

const AvantExpansionPanelSummary = withStyles({
    root: {
        borderRadius,
        backgroundColor: AvantColors.REPORT_BACKGROUND_GRAY_DARK,
    },
    expanded: {
        borderRadius: `${borderRadius}px ${borderRadius}px 0px 0px`,
        display: "flex",
        justifyContent: "space-between"
    }

})(AccordionSummary)

const AvantExpansionPanelRoot = withStyles({
    root: {
        boxSizing: "border-box",
        border: `1px solid ${AvantColors.REPORT_BOX_BORDER}`,
        borderRadius,
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
        marginBottom: "10px",
            "&:before": {
                backgroundColor: "transparent"
            }
    },
    expanded: {},
    rounded: {
        borderRadius,
        "&:last-child": {
            borderRadius
        }
    },
})(Accordion)
