import React from "react"
import videojs from "video.js"

import "video.js/dist/video-js.css"

export interface ItemVideoPlayerSource {
    src: string
    type: string
}

interface ItemVideoPlayerProps {
    autoplay: boolean
    controls: boolean
    fill: boolean
    aspectRatio: string
    sources: ItemVideoPlayerSource[]
    playCount: number
    increasePlayCount: () => void
}

export default class ItemVideoPlayer extends React.Component<ItemVideoPlayerProps> {
    player: any
    videoNode: any

    componentDidMount() {
        // instantiate video.js
        // TODO: figure out if this is the way to deal with this after upgrade
        // @ts-ignore
        this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
        })
    }

    componentDidUpdate(prevProps: Readonly<ItemVideoPlayerProps>) {
        if (this.player && this.props.playCount !== prevProps.playCount && this.props.playCount >= 2) {
            this.player.dispose()
        }
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }

    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render() {
        return (
            <div data-vjs-player={true}>
                <video
                    ref={(node) => (this.videoNode = node)}
                    className="video-js vjs-big-play-centered vjs-show-big-play-button-on-pause"
                    onEnded={() => {
                        if (this.player) {
                            this.props.increasePlayCount()
                        }
                    }}
                />
            </div>
        )
    }
}
