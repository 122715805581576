import {CircularProgress} from "@material-ui/core"
import * as React from "react"
import {theme} from "../../../styles/MuiThemes"

export enum CircularProgressSize {
    SMALL = 20,
    MEDIUM = 40,
    LARGE = 80
}

export const AvantCircularProgress = (props: {padding?: number, size?: CircularProgressSize}) => (
    <div style={{width: "100%", display: "flex", justifyContent: "center", padding: props.padding && theme.spacing(props.padding)}}>
        <CircularProgress size={props.size == null ? CircularProgressSize.LARGE : props.size}/>
    </div>
)
