import React from "react"
import {createStyles, makeStyles, Grid} from "@material-ui/core"
import moment from "moment"

const useStyles = makeStyles(() =>
    createStyles({
        section: {
            bottom: 0,
            display: "none",
            justifyContent: "space-evenly",
            left: 0,
            marginTop: "5rem",
            position: "fixed",
            width: "100%",
            ['@media print']: {
                display: "flex"
            }
        }
    })
)

export const ReportPrintFooter = () => {
    const classes = useStyles()

    return (
        <Grid container={true} alignItems={"flex-end"}>
            <div className={classes.section}>
                <div>
                    <a href="www.avantassessment.com" style={{textDecoration: "none"}}>www.avantassessment.com</a>
                </div>
                <div>
                    Copyright &copy; {moment().format("YYYY")} Avant Assessment, LLC. All rights reserved.
                </div>
                <div>
                    Printed on: {moment().format("MMMM D, YYYY")}
                </div>
            </div>
        </Grid>
    )
}

