import * as React from "react"
import {VerificientAvailableLogins} from "../../services/ProctorApi"
import {H3Text, SmallLabelText} from "../../styles/AvantTypography"
import {CONTENT_AREA_ID_TO_LANGUAGE_NAME, SUPPORT_EMAIL} from "../../util/Constants"

import {Button, Grid} from "@material-ui/core"

export interface MultipleTakesProps {
    takes: VerificientAvailableLogins[],
    handleSelectedTake: (takeId: number) => void
}
const MultipleTakes: React.FC<MultipleTakesProps> = (props) => {
    return (
        <Grid container={true} spacing={4} justify={"center"} alignContent={"center"}>
            <Grid item={true} xs={12}>
                <H3Text>Please select the test to setup or start:</H3Text>
            </Grid>
            <Grid item={true} xs={12} >
                {props.takes.map((take, index) => <TakeCard  key={index} index={index} take={take} handleSelectedTake={props.handleSelectedTake}/>)}
            </Grid>
            <Grid item={true} xs={12}>
                <p>If there is a test missing please contact us at
                    &nbsp;<a href={SUPPORT_EMAIL}>{`${SUPPORT_EMAIL}`}</a>
                </p>
            </Grid>
        </Grid>
    )

}
export default MultipleTakes

interface TakeCardProps {
    index: number,
    take: VerificientAvailableLogins,
    handleSelectedTake: (takeId: number) => void
}
const TakeCard: React.FC<TakeCardProps> = (props) => {
    return (
        <Grid container={true} spacing={2} justify={"space-evenly"} alignContent={"center"}>
            <Grid item={true} xs={4}>
                <H3Text>{CONTENT_AREA_ID_TO_LANGUAGE_NAME[props.take.contentAreaId]}</H3Text>
            </Grid>
            <Grid item={true} xs={4}>
                <SmallLabelText>({props.take.username})</SmallLabelText>
            </Grid>
            <Grid item={true} xs={4}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={(_) => {props.handleSelectedTake(props.index)}}
                >
                    Proceed to online proctor
                </Button>
            </Grid>
        </Grid>
    )
}
