import * as React from "react"
import {useEffect, useState} from "react"
import {ErrorBody, ErrorTitle} from "../../../styles/AvantTypography"
import {CONTACT_US_URL} from "../../../util/Constants"

interface DIYLoginErrorProps {
    title: string,
    message: string
}
export const DIYLoginError: React.FC<DIYLoginErrorProps> = (props) => {
    const [message, setMessage] = useState("")
    const [title, setTitle] = useState("")
    useEffect(() => {
        setMessage(props.message)
        setTitle(props.title)
    }, [])
    return (
        <>
            <ErrorTitle>{title}</ErrorTitle>
            <ErrorBody>

                    {message}: If you believe this is incorrect please contact Avant Assessment support.

            </ErrorBody>
            <ErrorBody>
                <a href={CONTACT_US_URL} target={"_blank"}>Contact Avant Assessment support</a>
            </ErrorBody>
        </>
    )
}
