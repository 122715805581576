import {ApiErrorResponse} from "../types/types"
import ApiService from "./ApiService"
import {AxiosResponse} from "axios"

interface SSOSyncReponse {
    message: string
    inProgress: boolean,
    counts: Map<string,number>,
    progress: Map<string,string>

}

export interface CustomStaffInfo {
    id: string
    teacherSisId: string
    firstName: string
    lastName: string
}

export default class SSOApi {
    static sync(district: string | null = null): Promise<SSOSyncReponse> {
        let url: string = `${ApiService.API_URL}sso/district/sync`

        if (district !== null) {
            url += `/${district}`
        }

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => { resolve(res.data) },
                (err: ApiErrorResponse) => { reject(err) }
            )
        })
    }

    static getCustomDistrictAdmins(): Promise<CustomStaffInfo[]> {
        const url: string = `${ApiService.API_URL}sso/get_custom_district_admins`
        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => { resolve(res.data) },
                (err: ApiErrorResponse) => { reject(err) }
            )
        })
    }
    static getCustomTeacherForSection(loginId: number): Promise<CustomStaffInfo[]> {
        const url: string = `${ApiService.API_URL}sso/get_custom_teachers_for_section/${loginId}`
        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => { resolve(res.data) },
                (err: ApiErrorResponse) => { reject(err) }
            )
        })
    }
}
