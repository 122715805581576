import {Divider, Grid} from "@material-ui/core"
import * as React from "react"
import {useEffect, useState} from "react"
import {BodyText, H1Text, H2Text} from "../../../styles/AvantTypography"
import {CONTACT_US_URL} from "../../../util/Constants"

interface DIYWaitingRoomProps {
    refreshDate: Date,
    refreshInternalInSeconds: number,
    onRefresh: () => {}
}
export const DIYWaitingRoom: React.FC<DIYWaitingRoomProps> = props => {
    const [countDown, setCountDown] = useState(props.refreshInternalInSeconds)
    const [lastDate, setLastDate] = useState<Date | undefined>(undefined)


    useEffect(() => {
        const currentCount = countDown - 1
        if (!lastDate || lastDate.getTime() !== props.refreshDate.getTime()) {
            setCountDown(props.refreshInternalInSeconds)
            setLastDate(props.refreshDate)
        }
        if (currentCount >= 0) {
            const timer = setTimeout(() => {
                setCountDown(currentCount)
                if (currentCount < 1) { props.onRefresh() }
            }, 1000)
            // Clear timeout if the component is unmounted
            return () => clearTimeout(timer)
        }
        return

    })

    return (
        <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
                <H1Text style={{marginBottom: "20px"}}>Waiting For Test to Open</H1Text>
            </Grid>
            <Grid item={true} xs={12}>
                <BodyText>
                    Your test is locked by the testing proctor and your are not allowed into the test yet. When the proctor
                    unlocks the test you will be redirected to the next step.
                </BodyText>
            </Grid>
            <Grid item={true} xs={12}><Divider/></Grid>
            <Grid item={true} xs={12}>
                <H2Text>
                     Refreshing in {countDown}
                </H2Text>
            </Grid>
            <Grid item={true} xs={12}><Divider/></Grid>
            <Grid item={true} xs={12}>
                <BodyText>
                    Page will refresh
                    every&nbsp;{props.refreshInternalInSeconds}&nbsp;seconds
                    and last refreshed on {props.refreshDate.toLocaleTimeString()}.
                </BodyText>
            </Grid>
            <Grid item={true} xs={12}><Divider/></Grid>
            <Grid item={true} xs={12}>
                <BodyText>
                    <a href={CONTACT_US_URL} target={"_blank"}>Avant Assessment Support</a>
                </BodyText>
            </Grid>
        </Grid>

    )

}
