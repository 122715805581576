import {observer} from "mobx-react"
import React from "react"
import {Alert} from "react-bootstrap"
import {connect} from "react-redux"
import { withRouter ,RouteComponentProps} from "react-router-dom";
import {Dispatch} from "redux"
import {isNullOrUndefined} from "util"
import * as Uuid from "uuid"
import {authStore} from "../../app/common/authentication/AuthStore"
import {AuthenticationRequest} from "../../app/common/authentication/models/AuthenticationRequest"
import {AuthenticationSampleTestResponse} from "../../app/common/authentication/models/AuthenticationSampleTestResponse"
import {loadingSpinnerStore} from "../../app/common/loaders/LoadingSpinnerStore"
import {productStore} from "../../app/common/products/ProductStore"
import {postProfileSuccess} from "../../redux/app/actions"
import ApiService from "../../services/ApiService"
import {APP_CONFIG} from "../../services/ConfigurationService"
import HelperService from "../../services/HelperService"
import {ApiErrorResponse, ApiPanelGraph, LastPage, Profile, State, Take, TestState} from "../../types/types"
import {PLACE_PRODUCT_ID, READY_PRODUCT_ID, SAMPLE_TEST_KEY, WORLDSPEAK_PRODUCT_ID} from "../../util/Constants"
import {TakeId} from "../../validation/ValidPrimaryKey"
import {addTakeDispatches, TakeDispatches} from "../App/App"

interface StateToProps {
    logoutMessage: string | null
    take: Take | null
    takeCode: string
    panelGraph: ApiPanelGraph
}

const mapStateToProps = (state: State): StateToProps => {
    return {
        logoutMessage: state.app.logoutMessage,
        take: state.app.take as Take,
        takeCode: state.app.takeCode,
        panelGraph: state.app.panelGraph!
    }
}

interface DispatchToProps extends TakeDispatches {
    profileSuccessDispatch: (profile: Profile) => void
}

function mapDispatchToProps(dispatch: Dispatch): DispatchToProps {
    const dispatches = {
        profileSuccessDispatch: (profile: Profile) => {
            dispatch(postProfileSuccess(profile))
        }
    }
    return addTakeDispatches(dispatches, dispatch)
}

interface MatchParams {
    username?: string
    password?: string
    loginId?: string
}

interface SampleLoginProps extends StateToProps, DispatchToProps, RouteComponentProps<MatchParams> {}

interface SampleLoginState {
    hasAllData: boolean
    error: boolean | string
}

@observer
class SampleLogin extends React.Component<SampleLoginProps, SampleLoginState> {
    // testing urls
    // stamp 4s /sample?username=samplelogin-ar&password=samplesample&loginId=36026
    // stamp 4se /sample?username=samplelogin-ar&password=samplesample&loginId=1000030331
    // place /sample?username=samplelogin-gm&password=samplesample&loginId=1000007252

    state: SampleLoginState

    constructor(props: SampleLoginProps) {
        super(props)
        this.state = {
            error: false,
            hasAllData: false
        }
    }

    componentDidMount() {
        const {
            location,
            history,
            profileSuccessDispatch
        } = this.props


        document.title = `Sample Login`
        HelperService.enableTextSelection()
        const params = new URLSearchParams(location.search)
        const hasAllData: boolean = params.has("username") && params.has("password") && params.has("loginId")
        this.setState({hasAllData})

        loadingSpinnerStore.hideLoadingSpinner = true

        if (hasAllData) {
            const request: AuthenticationRequest = {
                username: params.get("username")!,
                password: params.get("password")!,
                totp: "",
                loginId: parseInt(params.get("loginId")!, 10),
                secondsUntilExpiration: APP_CONFIG.AUTH_TOKEN_SECONDS_TILL_EXPIRE
            }
            authStore.authenticateForSampleTest(request).then(
                (result: AuthenticationSampleTestResponse) => {
                    productStore.setLoginProduct(result.loginProduct)
                    const driver = productStore.driver!!

                    const isAutoStart: boolean =
                        result.loginProduct.productId === PLACE_PRODUCT_ID.value() ||
                        result.loginProduct.productId === WORLDSPEAK_PRODUCT_ID.value() ||
                        result.loginProduct.productId === READY_PRODUCT_ID.value()

                    const lastPage: LastPage = {
                        url: isAutoStart ? "/auto-start-dashboard" : "/dashboard",
                        takeCode: Uuid.v4()
                    }
                    localStorage.setItem("lastPage", JSON.stringify(lastPage))
                    driver.helper.refreshTest(this.props, lastPage, result.loginProduct).then(
                        (testState: TestState) => {
                            if (isNullOrUndefined(testState.take)) {
                                throw Error("testState.take is null or undefined")
                            }

                            const profile: Profile = {
                                firstName: "Sample",
                                lastName: "Tester",
                                testTakerId: "sample",
                                testTakerEmail: "sample@sample.com",
                                selfEvaluationIndex: 0,
                                completed: "true"
                            }
                            const productName = driver.config.APP_PRODUCT_NAME.toLowerCase()
                            const takeId = new TakeId(testState.take.id)
                            ApiService.postProfile(takeId, profile, productName).then(
                                () => {
                                    profileSuccessDispatch(profile)
                                    localStorage.setItem(SAMPLE_TEST_KEY, "true")
                                    if (!isAutoStart) {
                                        history.push(lastPage.url)
                                    } else {
                                        history.push("/self-evaluation")
                                    }
                                },
                                (err: ApiErrorResponse) => {
                                    this.setState({
                                        error:
                                            `Sorry, an error occurred. ` +
                                            `Please contact ${driver.config.SUPPORT_EMAIL}.`
                                    })
                                    throw new Error(err.message)
                                }
                            )
                        },
                        (err: ApiErrorResponse) => {
                            const responseData = err.response.data
                            this.setState({
                                error: `Sorry, an error occurred. Please contact ${driver.config.SUPPORT_EMAIL}.`,
                                hasAllData: false
                            })
                            throw new Error(`Failed to refresh test. ${responseData.error} - ${responseData.message}`)
                        }
                    )
                },
                () => {
                    this.setState({
                        error: `Sorry, an error occurred. Please contact ${APP_CONFIG.SUPPORT_EMAIL}.`,
                        hasAllData: false
                    })
                    const error =
                        `Failed to authenticate a sample login. Username: ${request.username}, ` +
                        `Password: ${request.password}, LoginId: ${request.loginId}`
                    throw new Error(error)
                }
            )
        } else {
            this.setState({
                error: `Sorry, an error occurred. Please contact ${APP_CONFIG.SUPPORT_EMAIL}.`,
                hasAllData: false
            })
            throw new Error("Did not get a valid sample test URL")
        }

    }

    render() {
        const {error} = this.state
        return (
            <>
                {error && (
                    <div className="alerts">
                        <Alert variant="danger" onClose={() => this.setState({error: false})}>
                            {error}
                        </Alert>
                    </div>
                )}
            </>
        )
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SampleLogin)
)
