/*
 * ******************************************************
 *  * Copyright (C) 2010-Present Avant Assessment
 *  * All Rights Reserved
 *  ******************************************************
 */

import React, {useEffect, useReducer, useState} from "react"
import {
    Button, Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField, Typography
} from "@mui/material"
import ApiService from "../../../../services/ApiService"
import {AxiosError, AxiosResponse} from "axios"
import Tooltip from "@mui/material/Tooltip"
import FormGroup from "@mui/material/FormGroup"
import {XMLValidator} from "fast-xml-parser"
import {SamlIdpConfigRow} from "./SamlIdpConfigTool"
import { messageStore } from "../../../../app/common/messages/MessageStore"

interface CreateSamlIdpConfigModalProps {
    dialogOpen: boolean,
    closeDialog: (newRow: SamlIdpConfigRow | null) => void
}

interface CreateSamlIdpConfigFormState {
    entityId: string,
    metaDataUrl: string | null,
    metaDataXml: string | null,
    active: boolean,
    ssoId: string,
    errors: {
        entityId: string | null,
        metaDataUrl: string | null,
        metaDataXml: string | null,
        active: string | null,
        ssoId: string | null
    }
}

interface AvailableSSOEntityIdsForSaml {
    samlEntityId: string,
    ssoId: string,
    name: string
}

interface AvailableSsoEntitiesResponse {
    availableSamlSSOEntityIds: AvailableSSOEntityIdsForSaml[],
    error: boolean,
    errorText?: string
}

const initialState: CreateSamlIdpConfigFormState = {
    entityId: "",
    metaDataUrl: "",
    metaDataXml: "",
    active: false,
    ssoId: "",
    errors: {
        entityId: null,
        metaDataUrl: null,
        metaDataXml: null,
        active: null,
        ssoId: null
    }
}

export interface CreateSamlIdpConfigPostBody {
    entityId: string,
    metaDataUrl: string | null,
    metaDataXml: string | null,
    active: boolean,
    ssoId: string,
}

interface CreateSamlSsoIdpResponse {
    newIdpEntity: SamlIdpConfigRow,
    success: boolean,
    errorMessage: string | null
}

const CreateSamlIdpConfigModal: React.FC<CreateSamlIdpConfigModalProps> = ({dialogOpen, closeDialog}) => {

    const [selectedOption, setSelectedOption] = useState<AvailableSSOEntityIdsForSaml>({
        samlEntityId: "",
        ssoId: "",
        name: ""
    })

    const [availableSsoEntities, setAvailableSsoEntities] = useState<AvailableSSOEntityIdsForSaml[]>([])

    const [formState, dispatch] = useReducer(samlIdpConfigFormReducer, initialState)

    useEffect(() => {
        const url = `${ApiService.API_URL}admin/saml-tools/available-sso-entities`
        ApiService.get(url).then(
            (resp: AxiosResponse<AvailableSsoEntitiesResponse>) => {
                const result = resp.data.availableSamlSSOEntityIds
                setAvailableSsoEntities(result)
            },
            (err: AxiosError) => {
                // TODO: Handle the error case
                setAvailableSsoEntities([{samlEntityId: "", ssoId: "", name: ""}])
            }
        )
    }, [dialogOpen])

    const handleSubmit = () => {
        if (validate()) {
            // We have a valid payload for creating an entity
            const url = `${ApiService.API_URL}admin/saml-tools/add-saml-idp`
            const postBody: CreateSamlIdpConfigPostBody = {
                entityId: formState.entityId,
                metaDataUrl: formState.metaDataUrl,
                metaDataXml: formState.metaDataXml,
                active: formState.active,
                ssoId: formState.ssoId
            }
            dispatch({type: "CLEAR_ERRORS"})
            ApiService.post(url, postBody).then(
                (res: AxiosResponse<CreateSamlSsoIdpResponse>) => {
                    const response = res.data
                    if(response.success){
                        messageStore.setInfoMessage("SAML IDP configuration added successfully.");
                        dispatch({type: "CLEAR_STATE"});
                        closeDialog(res.data.newIdpEntity);
                    } else {
                        messageStore.setErrorMessage(`Error: ${response.errorMessage}`);
                        dispatch({type: "SET_ERROR", field: "entityId", error: response.errorMessage});
                    }
                },
                (err: AxiosError) => {
                    messageStore.setErrorMessage(`API Exception: ${err.message}`);
                }
            );
        }
    }

    const validateXml = (xmlInput: string): boolean => {

        try {
            const result =XMLValidator.validate(xmlInput)
            if (result !== true) {
                throw new Error(result.err.msg)
            }
            return result
        } catch (error: any) {
            return false
        }
    }

    const handleSsoEntityChange = (field: keyof CreateSamlIdpConfigFormState) => (data: SelectChangeEvent<string>,) => {
        const selected = availableSsoEntities.find(item => item.ssoId === data.target.value)
        if (selected !== undefined && selected != null) {
            setSelectedOption(selected)
            dispatch({type: "SET_FIELD", field: field, value: data.target.value, error: null })
        }
    }

    const handleClose = () => {
        dispatch({type: "CLEAR_STATE"})
        closeDialog(null)
    }

    const handleChange = (field: keyof CreateSamlIdpConfigFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.type === 'checkbox') {
            dispatch({type: "SET_FIELD", field: field, value: event.target.checked, error: null })
        } else {
            dispatch({type: "SET_FIELD", field: field, value: event.target.value, error: null  })
        }
    }


    const validate = (): boolean => {

        // TODO: There could be more validation rules to add, but I cant think of any at this time
        const validationErrors: CreateSamlIdpConfigFormState["errors"] = {
            entityId: null,
            metaDataUrl: null,
            metaDataXml: null,
            active: null,
            ssoId: null
        }

        let isValid = true
        if (formState.ssoId === null || formState.ssoId === "" || formState.ssoId === undefined) {
            validationErrors.ssoId = "SSO ID Required"
            isValid = false
        }
        if (formState.entityId === null || formState.entityId === "" || formState.entityId === undefined) {

            validationErrors.entityId = "SSO Entity ID Required"
            isValid = false
        }

        if (formState.metaDataXml !== "" && formState.metaDataXml !== undefined && formState.metaDataXml !== null) {
            isValid = validateXml(formState.metaDataXml)
            if (!isValid) {
                validationErrors.metaDataXml = "Invalid XML detected, please check formatting"
            }
        }

        if (!validateOneOfTwoFieldsIsValid(formState.metaDataXml, formState.metaDataUrl)) {
            // Both fields are either valid or invalid
            validationErrors.metaDataXml = `${validationErrors.metaDataXml !== null ? validationErrors.metaDataXml : ""} \n Only XML OR Url should be populated, not both`
            validationErrors.metaDataUrl = `${validationErrors.metaDataUrl !== null ? validationErrors.metaDataUrl : ""} \n Only XML OR Url should be populated, not both`
            isValid = false
        }

        if (!isValid) {
            dispatch({type: "VALIDATOR_ERRORS", errors: validationErrors})
        }

        return isValid
    }

    const validateOneOfTwoFieldsIsValid = (field1: string | null | undefined, field2: string | null | undefined): boolean => {
        const isValid = (field: string | null | undefined) => field !== null && field !== undefined && field !== ""
        return ((isValid(field1) && !isValid(field2)) || (!isValid(field1) && isValid(field2)))
    }

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleClose}
            aria-labelledby="create-saml-idp-config-dialog-title"
            aria-describedby="create-saml-idp-config-dialog-description"
            fullWidth={true}
        >
            <DialogTitle>Create SAML IDP Config</DialogTitle>
            <DialogContent>
                <FormGroup
                    sx={{margin: "8px"}}
                >
                    <Tooltip title={"Name displayed for users at the login service. Must be all lowercase with no spaces"}>
                        <TextField
                            error={formState.errors.entityId !== null}
                            label={"SAML Entity ID"}
                            onChange={handleChange("entityId")}
                            value={formState.entityId}
                            required={true}
                            helperText={formState.errors.entityId}
                        />
                    </Tooltip>
                </FormGroup>
                <FormGroup
                    sx={{margin: "8px"}}
                >
                    <Tooltip title={"Paste the URL for the XML Meta Data"}>
                        <TextField
                            error={formState.errors.metaDataUrl !== null}
                            label={"SAML Meta Data URL"}
                            onChange={handleChange("metaDataUrl")}
                            helperText={formState.errors.metaDataUrl}
                        />
                    </Tooltip>
                </FormGroup>
                <FormGroup
                    sx={{margin: "8px"}}
                >
                    <Tooltip title={"Copy and paste the XML Meta Data File into this field. MUST BE VALID XML"}>
                        <TextField
                            error={formState.errors.metaDataXml !== null}
                            label={"SAML Meta Data XML"}
                            multiline={true}
                            rows={6}
                            inputProps={{
                                spellCheck: false,
                                style: { fontFamily: "monospace", whiteSpace: "pre-wrap" }
                            }}
                            onChange={handleChange("metaDataXml")}
                            helperText={formState.errors.metaDataXml}
                        />
                    </Tooltip>
                </FormGroup>
                <FormGroup
                    sx={{margin: "8px"}}
                >
                    <Select
                        fullWidth={true}
                        value={selectedOption.ssoId}
                        onChange={handleSsoEntityChange("ssoId")}
                        displayEmpty={true}
                        defaultValue={""}
                        error={formState.errors.ssoId !== null}
                    >
                        <MenuItem value="" disabled>Select an Entity</MenuItem>
                        {
                            availableSsoEntities && availableSsoEntities.map((row) => {
                                return (<MenuItem key={`${row.samlEntityId}`} value={row.ssoId}>{row.name}</MenuItem>)
                            })
                        }
                    </Select>
                </FormGroup>
                <FormGroup
                    sx={{margin: "8px", display: "flex", alignItems: "center"}}
                >
                    {/*<FormControlLabel*/}
                    {/*    required={true}*/}
                    {/*    label={formState.active ? "Click to Disable" : "Click to Enable"}*/}
                    {/*    control={<Switch onChange={handleChange("active")} value={formState.active}/>}*/}
                    {/*/>*/}
                    <FormControlLabel
                        // label={formState.active ? "Click to Disable" : "Click to Enable"}
                        label={<Typography variant="body1" style={{ fontSize: 20, fontWeight: "bold" }}>
                            Active
                        </Typography>}
                        style={{fontSize: 28}}
                        control={<Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }} onChange={handleChange("active")} value={formState.active}/>}
                    />
                </FormGroup>
                <FormGroup
                    sx={{margin: "8px"}}
                >
                    <Button
                        type={"submit"}
                        fullWidth={true}
                        variant={"contained"}
                        onClick={handleSubmit}
                    >
                        SUBMIT
                    </Button>
                </FormGroup>
                <FormGroup
                    sx={{margin: "8px"}}
                >
                    <Button
                        fullWidth={true}
                        variant={"contained"}
                        color={"error"}
                        onClick={handleClose}
                    >
                        CANCEL
                    </Button>
                </FormGroup>
            </DialogContent>
        </Dialog>
    )

}

export default CreateSamlIdpConfigModal

type CreateSamlIdpConfigFormActions =  | {
    type: "SET_FIELD",
    field: keyof CreateSamlIdpConfigFormState,
    value: string | boolean,
    error: string | null
} | {
    type: "SET_ERROR",
    field: keyof CreateSamlIdpConfigFormState,
    error: string | null
} | {
    type: "VALIDATOR_ERRORS",
    errors: { [key: string]: string | null}
} | {type: "CLEAR_STATE"} | {type: "CLEAR_ERRORS"}

const samlIdpConfigFormReducer = (state: CreateSamlIdpConfigFormState, action: CreateSamlIdpConfigFormActions) => {
    switch (action.type) {
        case "SET_FIELD":
            /**
             * This case is triggerred when a field changes, this will also set that fields error
             * to null, as errors should be cleared when input is changed. Errors will be re-added
             * upon validation finction call
             * **/
            return {
                ...state,
                [action.field]: action.value,
                errors: {
                    ...state.errors,
                    [action.field]: action.error
                }
            }
        case "VALIDATOR_ERRORS":
            return {
                ...state,
                errors: {...state.errors, ...action.errors}
            }
        case "SET_ERROR":
            /**
             * This case is triggerred when validation fails on submit and will mark filed errors
             * **/
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.field]: action.error
                }
            }
        case "CLEAR_ERRORS":
            /**
             * This case may not be needed and can likely be removed
             * **/
            return {
                ...state,
                errors: initialState.errors
            }
        case "CLEAR_STATE":
            /**
             * This is the case that we have hit cancel and want to clear all state for the component
             * **/
            return initialState
        default:
            return state
    }
}
