import React from "react"
import {Modal} from "react-bootstrap"

import Button from "../Button/Button"

interface IComponentProps {
    index: number
    showModal: boolean
    handleModalVisibility: (index: number, showModal: boolean) => void
}

interface IComponentState {
    moreContent: string
}

export default class AdvanceMoreModal extends React.Component<IComponentProps, IComponentState> {
    private advanceMoreContent = [
        "<b>EXTENDED PARAGRAPH BASE REQUIREMENTS:</b><br/>" +
            "#1 - Ability to create a variety of Complex structures which create a much greater depth of meaning " +
            "and explores more abstract ideas with increased accuracy and finesse.<br/>" +
            "#2 - Native-like language control as well as accurate use of colloquial and idiomatic expressions.<br/>" +
            "#3 - Precise and extensive vocabulary with very few errors.<br/>" +
            "#4 - Language demonstrates cultural awareness.<br/>" +
            "LEVEL INDICATORS (these indicators may or may not be present. However, at the Advanced levels, " +
            "we expect to see more Level Indicators used with accuracy)<br/>" +
            "• All time frames used with accuracy- even in lengthy and detailed responses – and able to switch time " +
            "frames with accuracy.<br/>" +
            "• Can comfortably discuss a variety of ideas with breadth, and a few topics relating to their " +
            "particular interest and special expertise with ease and in greater depth. We expect to see language " +
            "that a well-educated person would produce.<br/>" +
            "• Able to compensate for an imperfect grasp of some forms or for limitations in vocabulary by the " +
            "confident use of communicative strategies, such as paraphrasing, circumlocution, and illustration.<br/>" +
            "• Precise vocabulary and intonation to express meaning and often show great fluency " +
            "and ease of speech.<br/>" +
            "• Can produce language that demonstrates life-experience with the language.",

        "<b>PARAGRAPH BASE REQUIREMENTS:</b><br/>" +
            "#1. Ability to create enough language to show Paragraph Structure with a significant amount of " +
            "accurately used complex structures.<br/>" +
            "#2. Evidence of Advanced language including transition words, phrases and sentences showing " +
            "cohesion with increased control.<br/>" +
            "#3. Must include 3+ Advanced vocabulary words or phrases.<br/>" +
            "LEVEL INDICATORS (these indicators may or may not be present. However, at the Advanced levels, " +
            "we expect to see more Level Indicators used with accuracy)<br/>" +
            "• Variety of connectors used to link concepts, creating a natural flow<br/>" +
            "• Complex sentence use creates depth of meaning and contributes to the overall natural feeling of the " +
            "response<br/>" +
            "• Increasing control of all Time Frames – and ability to switch from one time frame to another is " +
            "evident.<br/>" +
            "• Delivery of language is fluid, with only occasional hesitancy<br/>" +
            "• Language is Advanced, demonstrating increased accuracy with basic language production. However, as " +
            "the student moves into Level 7, the use of complex, advanced vocabulary and conjugation may falter at " +
            "times. Minor errors may appear intermittently within the response at an Advance level.<br/>" +
            "• Errors may occur in use of Idiomatic Phrases or Colloquial Phrases.<br/>" +
            "******** Paragraph EXCEPTIONS to the RULES ********** Going Above and Beyond…<br/>" +
            "1. – If the student has not included Advanced vocabulary words (at least 3 examples), but has a LARGE " +
            "amount of highly accurate language (increased number of complexities demonstrated in a variety of ways) " +
            "which are Native-like and contain only a few errors, they can be scored Level 7.",

        "<b>PRE-PARAGRAPH BASE REQUIREMENTS:</b><br/>" +
            "#1. Evidence of a number of Complex components (at least 4 or 5), all with increasing accuracy " +
            "(at least 60%).<br/>" +
            "#2. There is enough language demonstrating good control of basic syntax and grammar " +
            "(above 70% accuracy) to create a natural flow. However, it does not have as much cohesion, accuracy, " +
            "or vocabulary as expected at the Advanced level.<br/>" +
            "LEVEL INDICATORS (these indicators may or may not be present, but if the prompt encourages such usage, " +
            "this is what might be present within the level)<br/>" +
            "• May use transition words and phrases (However… On the other hand, etc.)<br/>" +
            "• May use other Tenses with good accuracy.<br/>" +
            "• May contain a good amount of language and complexity, but the errors prevent it from being scored " +
            "Paragraph.<br/>" +
            "• Response lacks Advanced Vocabulary that would move the response up to a Paragraph Average score.<br/>" +
            "******** Pre-Paragraphs EXCEPTIONS to the RULES ********** Going Above and Beyond…<br/>" +
            "1. The response does not have enough complexities (more than 2 dependent clauses) to be scored Level 6, " +
            "but DOES demonstrate the ability to use multiple time frames with higher accuracy than what is " +
            "expected, and enough language to show increased connectedness and verify " +
            "that they can sustain control at " +
            "that level.<br/>" +
            "2. The response does not have enough language to show Level 6 Connectedness – just TOO SHORT, but has " +
            "a high percentage of complex language and a native-like flow that would be " +
            "expected at a Level 7 with one " +
            "or two advanced vocabulary. If a student does not give a lot of language they must provide more " +
            "advanced language.",

        "<b>CONNECTED BASE REQUIREMENTS:</b><br/>" +
            "#1. At least 2 evidences of Complexity (usually in the form of Dependent Clauses, but could also use " +
            "other forms of complex structures).<br/>" +
            "#2. There is ENOUGH LANGUAGE to form ‘connectedness’ (or Groupings of Sentences) - sentences cannot " +
            "be easily rearranged.<br/>" +
            "LEVEL INDICATORS (these indicators may or may not be present, but if the prompt encourages such usage, " +
            "this is what might be present within the level)<br/>" +
            "• ‘Groupings of sentences’ may not be evident. However, there may be other language, which helps create " +
            "connectedness and a flow (sentences cannot be moved around without affecting meaning).<br/>" +
            "• Present tense may be used with 60-70% accuracy (except perhaps lower % for irregular verbs).<br/>" +
            "• Use of other time frames/tenses may be included in the response w/only have 40-60% accuracy.<br/>" +
            "• Transition words may or may not be used to aid in creating connectedness " +
            "(use of transition words such " +
            "as first, second, next and finally do not automatically indicate ‘connectedness’ –especially if " +
            "sentences are very simple sentences; “First I eat cereal, second I brush my teeth, finally I walk to " +
            "school”. The use of such transition words would not move a student up to Connectedness.<br/>" +
            "******** Connected EXCEPTIONS to the RULES ********** Going Above and Beyond…<br/>" +
            "#1. The response does NOT have 2 complexities, but DOES demonstrate the ability to use other tenses " +
            "(ie- past tense) with higher accuracy than what is expected, and enough language to verify that they " +
            "can sustain control at that level. There is cohesion found in the response as well.<br/>" +
            "#2. The response has a number of complexities but it is TOO SHORT to show Connectedness. However, the " +
            "response has language that goes above and beyond what is expected at Level 5 (more complex transition " +
            "words and phrases, more descriptive vocabulary words).<br/>" +
            "#3. The response is unusually long with ACCURATE use of a variety of tenses as well as transition " +
            "words and phrases that create Connectedness. However, it may have only one or maybe even NO examples " +
            "of Complexity in the form of Dependent Clauses.",

        "<b>STRINGS BASE REQUIREMENT:</b><br/>" +
            "#1. The response uses at least 2 verbs in 3 or more sentences.<br/>" +
            "#2. There must be 3 instances of ADDED DETAIL showing VARIETY with use of prepositional phrases (there " +
            "should be at least 2 different prepositions used within the 3 phrases– (for example; in the morning, " +
            "in the kitchen, with my friends, and in the park).<br/>" +
            "LEVEL INDICATORS (these indicators may or may not be present, but if the prompt encourages such usage, " +
            "this is what might be present within the level)<br/>" +
            "• Sentences are independent and can be moved around without affecting the overall meaning<br/>" +
            "• Added Detail may be created using a variety of grammatical structures (adverbs, . However, the most " +
            "often used ‘added detail’ seems to be prepositional phrases.<br/>" +
            "• May have only 40-60% accuracy with Present Tense usage (other tenses may or may not be used but " +
            "incorrectly)<br/>" +
            "******** Strings EXCEPTIONS to the RULES ********** Going Above and Beyond…<br/>" +
            "STRINGS Exceptions to the Rules:<br/>" +
            "#1: Has 2-3 simple sentences with 0-2 Prepositional Phrases but also ONE COMPLEX COMPONENT with<br/>" +
            "ACCURACY<br/>" +
            "#2: Has multiple sentences with a number of accurately-used past tense verbs with 0-2 prep phrases.",

        "<b>SIMPLE SENTENCES BASE REQUIREMENT:</b><br/>" +
            "#1. Contains at least two Simple Sentences (or clauses) containing three components (for example: " +
            "subject+verb+object or subject+verb+prepositional phrase) using two different verbs.<br/>" +
            "#2. Clauses must be on topic. May contain many errors.<br/>" +
            "LEVEL INDICATORS (these indicators may or may not be present, " +
            "but if the prompt encourages such usage, this " +
            "is what might be present within the level)<br/>" +
            "• A “sentence” must have 3 components that create a complete clause. (Subj.+Verb+Obj. " +
            "OR Subj.+Verb+Prep Phrase)<br/>" +
            "• Requirement of “2 Simple Sentences” can be fulfilled regardless of " +
            "inaccuracies in spelling, punctuation, " +
            "etc., as long as it is understandable to a sympathetic reader or listener – the sentence MUST MAKE " +
            "SENSE.<br/>" +
            "• Use of compound words (and, but, or) which separate two complete clauses can be scored Simple " +
            "Sentences.<br/>" +
            "<br/>" +
            "(I like cookies and I make pizza.)<br/>" +
            "<br/>" +
            "• A variety of adjectives and some adverbs may also be used at this level – " +
            "but the use of such will not " +
            "justify a Simple Sentence score alone. There must be two simple " +
            "sentences with two different verbs. <br/>" +
            "• If a prepositional phrase is used as the third component of a clause, it cannot also be counted as " +
            "ADDED DETAIL The use of Added Detail gives more information and creates " +
            "the VARIETY that we expect at the " +
            "Strings level. If the Prep Phrase is a 3rd component, it is not adding detail. = " +
            "it is simply completing the sentence.<br/>" +
            "******** Simple Sentences EXCEPTIONS to the RULES ********** Going Above and Beyond…<br/>" +
            "#1. Only one verb used BUT has 3 DIFFERENT Prepositional Phrases with ACCURACY<br/>" +
            "#2. Only one verb used in multiple sentences. However, " +
            "the subject and verb combinations are conjugated<br/>" +
            "correctly for two different categories of grammar (First, Second or Third Person).",

        "<b>PHRASES BASE REQUIREMENT:</b><br/>" +
            "At least two connections to a verb that relate to the prompt " +
            "(may have more than just one) – subject+verb, " +
            "verb+object, or verb+prep phrase, verb+adjective. May contain many errors, but must make sense.<br/>" +
            "LEVEL INDICATORS (these indicators may or may not be present, " +
            "but if the prompt encourages such usage, this " +
            "is what might be present within the level)<br/>" +
            "• May only be able to use the infinitive form of the verb – or may be able to conjugate the verb.<br/>" +
            "• May create ONE sentence combined with multiple phrases<br/>" +
            "• May create one sentence type repeated – with same verb used<br/>" +
            "********NO EXCEPTIONS TO THE RULES**********",

        "<b>WORDS BASE REQUIREMENT:</b><br/>" +
            "At least 2 significant words in target language… " +
            "there is no grammatical connection to a verb throughout response. " +
            "May contain many errors, but words must be understood.<br/>" +
            "********NO EXCEPTIONS TO THE RULES**********",

        "<b>NON-RATABLE BASE REQUIREMENT:</b><br/>" +
            "NON-RATABLE: BLANK<br/>" +
            "• NO AUDIO indicated where play bar usually is located<br/>" +
            "• Cursor doesn’t move when you click on play bar<br/>" +
            "• Response has a buzzing sound but no student voice<br/>" +
            "NON-RATABLE: VIOLENT/PROFANE-<br/>" +
            "Violent-<br/>" +
            "Writing and Speaking: if there is a threat to self, another student or teacher etc. please report " +
            "immediately to Manager and score Non-Ratable. If the student is responding to the prompt ‘creatively’ " +
            "use good judgment as to whether or not to score it. Either way, send ID# to manager if not sure.<br/>" +
            "Vulgarity/Profanity-<br/>" +
            "• WRITING: Student purposefully writes something vulgar or inappropriate.<br/>" +
            "• SPEAKING: Vulgar or inappropriate language is included in response.<br/>" +
            "However – if it is at the beginning or at the end, it " +
            "may be that the student did not realize he was being " +
            "recorded… Please understand that this score is given to those who say inappropriate things as part of " +
            "their response. Score for the target language given, and in your usual note-taking in the comment box, " +
            "you could add that they swore.<br/>" +
            "NON-RATABLE: OTHER<br/>" +
            "All non-violent non-ratable responses should be scored Non-Ratable: " +
            "OTHER when they have one or more of the " +
            "following<br/>" +
            "• Gibberish<br/>" +
            "• Non-Target language<br/>" +
            "• OFF TOPIC: Student translates the prompt, describe the picture or any other example of them creating " +
            "language that is not addressing the prompt. If a response has common statements (How are you? " +
            "My name is ___) that have nothing to do with the prompt, they cannot be considered On Topic and " +
            "therefore not a fulfillment of the Base Requirements.  However, raters must do their best to be " +
            "sympathetic and see if the statements can relate to the prompt somehow."
    ]

    constructor(props: IComponentProps) {
        super(props)
        this.state = {
            moreContent: this.advanceMoreContent[this.props.index]
        }
    }

    componentDidUpdate() {
        const moreContent = this.advanceMoreContent[this.props.index]
        if (this.state.moreContent !== moreContent) {
            this.setState({moreContent})
        }
    }

    handleModalVisibility = () => {
        this.props.handleModalVisibility(this.props.index, false)
    }

    render() {
        return (
            <Modal size="lg" show={this.props.showModal} onHide={this.handleModalVisibility}>
                <Modal.Header className="center-text">
                    <Modal.Title>Level Base Requirements</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{__html: this.state.moreContent}} />
                    <div className="center-text">
                        <Button
                            style={{marginTop: "5px"}}
                            className="avant-button--primary avant-button--default"
                            onClick={this.handleModalVisibility}
                        >
                            OK
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
