import React from "react"
import {Divider, IconButton, Input, InputAdornment, InputLabel, Theme, Tooltip} from "@material-ui/core"
import Save from "@material-ui/icons/Save"
import Edit from "@material-ui/icons/Edit"
import Cancel from "@material-ui/icons/Cancel"
import ContentCopy from "@material-ui/icons/FileCopy"
import FormControl from "@material-ui/core/FormControl"
import makeStyles from "@material-ui/core/styles/makeStyles"
import createStyles from "@material-ui/core/styles/createStyles"
import {useEffect} from "react"
import {messageStore} from "../../app/common/messages/MessageStore"

const useEditableTextInputWithControlsStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
    })
)

export interface EditableTextInputWithControlsToolTips {
    save: string,
    copy: string,
    edit: string,
    cancel: string,
    input: string
}

export interface EditableTextInputWithControlsToolTipsMetaData {
    inputId: string,
    dataTestId: string
    inputLabelHtmlFor: string
}

export interface EditableTextInputWithControlsProps {
    inputValue: string | undefined | null,
    selfLink: string,
    fieldName: string,
    patchMethod: (link: string, data: object, id?: number) => void
    labelText: string | undefined,
    showControlLabel: boolean,
    rowId?: number
    toolTipText: EditableTextInputWithControlsToolTips,
    metaData: EditableTextInputWithControlsToolTipsMetaData
}

/**
 * Description: This is intended to be a reusable component that has some controls builtin into it. The controls built in
 *      allow the user to `edit` or `copy` the field text. If the user selects to edit the field, the controls will change to
 *      `save` or `cancel` controls. If saved, the patch method will be called to update the DB. If cancel is selected
 *      the original value of the string is restored.
 * @param inputValue - The text that can be editable that we want displayed in the text field
 * @param selfLink - The rest link that points to the proper DB object (spring data rest _links from a JPA entity)
 * @param fieldName - The name of the field from the DB that will be edited
 * @param patchMethod - The method that will be used to send a patch request to the API with the updated value
 * @param labelText - The label text you want applied to the control
 * @param showControlLabel - Boolean of weather or not to show the label on the input
 * @param toolTipText - This is an object that holds string for each of the controls tool tips
 * @param metaData - This is an object you can use to set meta data properties on the input
 */
export const EditableTextInputWithControls: React.FC<EditableTextInputWithControlsProps> = (
    {inputValue,selfLink, fieldName, patchMethod, labelText, showControlLabel, rowId, toolTipText, metaData }
    ) => {

    const classes = useEditableTextInputWithControlsStyles()

    const [originalFieldValue, setOriginalFieldValue] = React.useState<string | null | undefined>(inputValue)
    const [inputFieldValue, setInputFieldValue] = React.useState<string | null | undefined>(inputValue)
    const [inputFieldEditable, setInputFieldEditable] = React.useState<boolean>(false)

    useEffect(() => {
        if (inputValue != inputFieldValue) {
            setInputFieldValue(inputValue)
        }
        if (inputValue != null) {
            setOriginalFieldValue(inputValue)
        }
    }, [])

    const linkSaveOrEdit = (linkStr: string | undefined | null) => {
        // Either the Save or Edit adornment button was clicked
        if (linkStr != null) {
            setInputFieldValue(linkStr)
        }

        if (inputFieldEditable) {
            // In this case we are trying to save a change
            setInputFieldEditable(false)
            updateFieldInDatabase()
        } else {
            // This is the case that we have selected the edit icon
            setInputFieldEditable(true)
        }
    }

    const linkCancelOrCopy = (linkStr: string | undefined | null) => {
        // Either the Cancel or Copy adornment button was clicked
        if (linkStr != null) {
            setInputFieldValue(linkStr)
        }
        if (inputFieldEditable) {
            // This is when the cancel button is clicked
            setInputFieldValue(originalFieldValue)
            setInputFieldEditable(false)
        } else {
            // This is when copy button is clicked
            if (inputFieldValue != null || linkStr != null) {
                if (linkStr != null) {
                    navigator.clipboard.writeText(linkStr).then(() => {
                            messageStore.setMessage({message: "Copied....", type: 1})
                        }

                    )
                }
            }
        }
    }

    const updateFieldInDatabase = () => {
        patchMethod(selfLink, {
            [fieldName]: inputFieldValue
        }, rowId)
    }

    const editStudentSchedulingLink = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Updates the text in the input
        setInputFieldValue(event.target.value)
    }

    return (
        <FormControl className={classes.root}>
            {showControlLabel &&
                <InputLabel htmlFor={metaData.inputLabelHtmlFor}>{labelText}</InputLabel>
            }
            <Tooltip title={toolTipText.input}>
                <Input
                    id={metaData.inputId}
                    data-tst-id={metaData.dataTestId}
                    autoComplete={"off"}
                    type={"text"}
                    disabled={!inputFieldEditable}
                    value={inputFieldValue ? inputFieldValue : ""}
                    onChange={editStudentSchedulingLink}
                    endAdornment={
                        <InputAdornment position={"end"}>
                            <IconButton
                                className={classes.iconButton}
                                aria-label={inputFieldEditable
                                    ? "save changes"
                                    : "edit link"
                                }
                                onClick={() => linkSaveOrEdit(inputFieldValue)}
                            >
                                { inputFieldEditable
                                    ? (
                                        <Tooltip title={toolTipText.save}>
                                            <Save fontSize={"small"}/>
                                        </Tooltip>
                                    )
                                    : (
                                        <Tooltip title={toolTipText.edit}>
                                            <Edit fontSize={"small"}/>
                                        </Tooltip>
                                    )
                                }
                            </IconButton>
                            <Divider
                                className={classes.divider}
                                orientation="vertical"
                            />
                            <IconButton
                                color="primary"
                                className={classes.iconButton}
                                aria-label={inputFieldEditable
                                    ? "cancel edit"
                                    : "copy text"
                                }
                                onClick={() => linkCancelOrCopy(inputFieldValue)}
                            >
                                { inputFieldEditable
                                    ? (
                                        <Tooltip title={toolTipText.cancel}>
                                            <Cancel fontSize={"small"}/>
                                        </Tooltip>

                                    )
                                    : (
                                        <Tooltip title={toolTipText.copy}>
                                            <ContentCopy fontSize={"small"}/>
                                        </Tooltip>

                                    )
                                }
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </Tooltip>
        </FormControl>
    )

}
