import {ValidData} from "./ValidData"

export abstract class ValidPrimaryKey extends ValidData<number> {
    constructor(primaryKey: number, keyName: string, error?: Error) {
        if (!error) {
            error = new Error(`${keyName} ${primaryKey} is invalid`)
        }
        super(primaryKey, error)
    }

    // Key is valid if it's greater than 0
    validate(primaryKey: number): boolean {
        return primaryKey > 0
    }
}

// Make a class per key to help avoid mixing them up
// in function calls.
export class TakeId extends ValidPrimaryKey {
    constructor(takeId: number, error?: Error) {
        super(takeId, "TakeId", error)
    }
}

export class PanelId extends ValidPrimaryKey {
    constructor(panelId: number, error?: Error) {
        super(panelId, "PanelId", error)
    }
}

export class UserId extends ValidPrimaryKey {
    constructor(userId: number, error?: Error) {
        super(userId, "UserId", error)
    }
}

export class PanelGraphId extends ValidPrimaryKey {
    constructor(panelGraphId: number, error?: Error) {
        super(panelGraphId, "PanelGraphId", error)
    }
}

export class ItemId extends ValidPrimaryKey {
    constructor(itemId: number, error?: Error) {
        super(itemId, "ItemId", error)
    }
}

export class ProductId extends ValidPrimaryKey {
    constructor(productId: number, error?: Error) {
        super(productId, "ProductId", error)
    }
}

export class GroupId extends ValidPrimaryKey {
    constructor(groupId: number, error?: Error) {
        super(groupId, "GroupId", error)
    }
}

export class ItemContentId extends ValidPrimaryKey {
    constructor(itemContentId: number, error?: Error) {
        super(itemContentId, "ItemContentId", error)
    }
}

export class ContentAreaId extends ValidPrimaryKey {
    constructor(contentAreaId: number, error?: Error) {
        super(contentAreaId, "ContentAreaId", error)
    }
}

export class TakePanelId extends ValidPrimaryKey {
    constructor(takePanelId: number, error?: Error) {
        super(takePanelId, "TakePanelId", error)
    }
}
