/*
* CheckBoxList Class
* Optional Props:
*  - translations: This prop is used to render translated text for World Speak self Evaluation Questions and
*                   is only used for World Speak at this time.
*  - height: this is used to override the default height of a Material UI Checkbox Button. I made this so that we can
*            match the existing heights of production elements. If no value is set, the component will use the default
*            theme definition for this component.
*
* */

import {
    Checkbox,
    createStyles,
    Typography,
    WithStyles,
    withStyles
} from "@material-ui/core"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import { MuiThemeProvider } from "@material-ui/core/styles"
import {observer} from "mobx-react"
import * as React from "react"
import {theme} from "../../styles/MuiThemes"


const styles = createStyles({
    root: {},
    boldText: {
        fontWeight: 600
    },
    translationText: {
        fontWeight: "lighter",
        fontStyle: "italic"
    }
})

interface CheckboxListProps extends WithStyles<typeof styles> {

    changeHandler: ((values: string) => void)
    listItems: string[]
    translations?: string[]
    checkedItems: string[]
    height?: number

}

@observer
class CheckboxList extends React.Component<CheckboxListProps, {}> {


    handleChange = (values: string) => {
        this.props.changeHandler(values)
    }

    render() {

        const {
            classes,
            listItems,
            translations
        } = this.props

        const height = this.props.height ? this.props.height : "100%"

        return (
            <MuiThemeProvider theme={theme}>
                <List className={classes.root}>
                    {listItems.map((item: string, index: number) => {
                        return (
                            <ListItem
                                key={item}
                                style={{
                                    border: "1px solid",
                                    borderColor: "#dddddd",
                                    height,
                                    fontWeight: 600
                                }}
                                onClick={() => this.handleChange(item)}
                                role={undefined}
                                dense={true}
                                button={true}
                            >
                                <Checkbox
                                    checked={this.props.checkedItems.indexOf(item) !== -1}
                                    // tabIndex={-1}
                                    disableRipple={false}
                                    value={item}
                                    color={"secondary"}
                                />
                                <ListItemText data-tst-id="tests-taken">
                                    <Typography variant={"subtitle2"}>
                                        <div className={classes.boldText}>
                                            {item}
                                        </div>
                                        <div className={classes.translationText}>
                                            {
                                                translations &&
                                                translations[index]
                                            }
                                        </div>
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        )
                    })}
                </List>
            </MuiThemeProvider>
        )
    }
}

export default withStyles(styles)(CheckboxList)
