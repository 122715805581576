import {Dialog, DialogTitle, Grid, makeStyles, Paper, Typography} from "@material-ui/core"
import * as React from "react"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        maxWidth: '350px',
        color: theme.palette.text.secondary,
    },
}))

interface SSOBulkConfirmProps {
    handleOK: () => void
    handleCancel: () => void
    countToChange: number
}

export const SSOBulkConfirm: React.FC<SSOBulkConfirmProps> = (props) => {
    const classes = useStyles()

    return (
        <Dialog  onClose={props.handleCancel} aria-labelledby="Class Roster" open={true}>
            <DialogTitle id="simple-dialog-title">
                Bulk Update
            </DialogTitle>

            <Paper className={classes.paper} elevation={3} >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {props.countToChange === 0 && (
                            <Typography>Please select some testing groups to change.</Typography>
                        )}
                        {props.countToChange > 0 && (
                            <Typography>Do you want to bulk change {props.countToChange} testing groups.</Typography>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {props.countToChange > 0 && (
                            <Button variant="contained" color="primary" onClick={props.handleOK}>Proceed</Button>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" color="secondary" onClick={props.handleCancel}>Cancel</Button>
                    </Grid>
                </Grid>
            </Paper>

        </Dialog>
    )
}
