/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import React from "react"
import {Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Theme} from "@mui/material"
import {makeStyles} from "@material-ui/core"
import {AvantColors} from "../../../../styles/AvantColors"
import {BodyText, LabelText, RTLWritingResponseText, WritingResponseText} from "../../../../styles/AvantTypography"
import {theme} from "../../../../styles/MuiThemes"
import {ApiItemResponse, ApiPanel, ItemFormatEnum} from "../../../../types/types"
import {Close} from "@material-ui/icons"
import {ItemInstructionsViewer} from "../../../common/item/components/ItemInstructionsViewer"
import {AudioUtils} from "../../../../util/AudioUtils"
import {RTL_CONTENT_AREA_IDS, WRITING_RESPONSE_URL} from "../../../../util/Constants"
import ItemVideoPlayer, {ItemVideoPlayerSource} from "../../../../components/ItemVideo/ItemVideoPlayer"

const responseModalStyles = makeStyles((theme: Theme) => ({
    title: {
        alignItems: "center",
        backgroundColor: AvantColors.REPORT_YELLOW,
        display: "flex",
        padding: theme.spacing(2),
    },
    itemInstructions: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        lineHeight: 1.5,
        margin: theme.spacing(6, 6, 3),
        maxWidth: 552,
    },
}))

interface SidebarResponseModalProps {
    takePanelId: number,
    response: ApiItemResponse,
    score?: string,
    indicatorOrReason?: string,
    benchmark?: string,
    responseNumber: number,
    fullName: string,
    panel: ApiPanel,
    isOpen: boolean,
    close: () => void,
}

function isHandwritten(respondedValue: string): boolean {
    if (respondedValue.startsWith("WritingResponses") && respondedValue.endsWith("jpg")) {
        return true
    }
    return false
}

function renderItemContent(response: ApiItemResponse, panel: ApiPanel): JSX.Element {
    if (response.itemFormat === ItemFormatEnum.SPEAKING_ORAL && AudioUtils.isStandardAudioLink(response.respondedValue)) {
        return (
            <audio controls={true}>
                <source src={response.respondedValue} />
            </audio>
        )
    } else if (response.itemFormat === ItemFormatEnum.VIDEO_RECORDING) {
        const itemVideoSources: ItemVideoPlayerSource[] = [
            {
                src: response.respondedValue,
                type: "video/mp4",
            },
        ]

        return <ItemVideoPlayer
            autoplay={false}
            controls={true}
            fill={true}
            aspectRatio={"16:9"}
            sources={itemVideoSources}
            playCount={0}
            increasePlayCount={() => {}}
        />
    } else if (response.itemFormat === ItemFormatEnum.WRITING) {
        if (isHandwritten(response.respondedValue)) {
            return (
                <img
                    src={`${WRITING_RESPONSE_URL}${response.respondedValue}`}
                    alt={"Handwritten image"}
                    style={{width: "100%", height: "100%"}}
                />
            )
        } else if (RTL_CONTENT_AREA_IDS.includes(panel.contentAreaId)) {
            return <RTLWritingResponseText>{response.respondedValue}</RTLWritingResponseText>
        } else {
            return <WritingResponseText>{response.respondedValue}</WritingResponseText>
        }
    }

    return <></>
}

export const SidebarResponseModal = (props: SidebarResponseModalProps) => {
    const {
        benchmark,
        close,
        fullName,
        indicatorOrReason,
        isOpen,
        panel,
        response,
        responseNumber,
        score,
        takePanelId,
    } = props

    const classes: Record<"title" | "itemInstructions", string> = responseModalStyles()

    let title: string = "Response"
    if (response.itemFormat === ItemFormatEnum.SPEAKING_ORAL) {
        title = "Speaking Response"
    }
    if (response.itemFormat === ItemFormatEnum.WRITING) {
        title = "Writing Response"
    }
    title += ` ${responseNumber}`

    return (
        <Dialog open={isOpen} maxWidth={"lg"} fullWidth={true} style={{borderRadius: 6}}>
            <DialogTitle
                // disableTypography={true}
                className={classes.title}
            >
                <LabelText style={{marginRight: theme.spacing(1)}}>
                    { title }
                </LabelText>
                <BodyText>
                    { score === null && benchmark === null ?
                        "Scoring in Progress" :
                        `Score: ${score} ${indicatorOrReason} ${benchmark}`
                    }
                </BodyText>
                <Box flexGrow={1}/>
                <BodyText style={{marginRight: theme.spacing(1)}}>
                    {fullName}
                </BodyText>
                <IconButton
                    onClick={close}
                    size={"small"}
                >
                    <Close fontSize={"small"}/>
                </IconButton>
            </DialogTitle>

            <Grid container={true}>
                <Grid
                    item={true}
                    md={response.itemFormat === ItemFormatEnum.SPEAKING_ORAL ? 6 : 7}
                    lg={6}
                    style={{display: "flex", justifyContent: "center" }}
                >
                    <Box className={`item-instructions ${classes.itemInstructions}`}>
                        <ItemInstructionsViewer
                            itemId={response.itemId}
                            takePanelId={takePanelId}
                            responseModal={true}
                        />
                    </Box>
                </Grid>
                <Grid
                    item={true}
                    xs={12}
                    md={response.itemFormat === ItemFormatEnum.SPEAKING_ORAL ? 6 : 5}
                    lg={6}
                    style={{backgroundColor: AvantColors.REPORT_BACKGROUND_GRAY_LIGHT}}
                >
                    <DialogContent
                        style={{padding: theme.spacing(6, 10)}}
                    >
                        {renderItemContent(response, panel)}
                    </DialogContent>
                </Grid>
            </Grid>
        </Dialog>
    )
}
