/*
 * ******************************************************
 *  * Copyright (C) 2010-Present Avant Assessment
 *  * All Rights Reserved
 *  ******************************************************
 */


import React, {useCallback} from "react"
import { GridRenderCellParams, useGridApiContext} from "@mui/x-data-grid-pro"
import {Checkbox} from "@mui/material"
import {FormControlLabel} from "@material-ui/core"
import {PermissionsInterface} from "../LoginTableNew/LoginToolNew"
import ApiService from "../../../../services/ApiService"
import {AxiosResponse} from "axios"
import {PatchLoginRequestBody, UpdateLoginResponse} from "../../../../types/rest/LoginTypes"

/**
 * This component is based on referencing the following 2 links:
 * https://github.com/mui/mui-x/issues/5148
 * https://codesandbox.io/p/sandbox/data-grid-community-forked-w4u9fo?file=%2Fsrc%2FApp.tsx%3A15%2C24
 * **/

export interface RendersSingleClickBooleanPermissionsProps {
    params: GridRenderCellParams,
    propLabel: string,
    index: number,
    permissionEnum: PermissionsInterface
}

export const RendersSingleClickBooleanPermissions: React.FC<RendersSingleClickBooleanPermissionsProps> = (props) => {

    const api = useGridApiContext()

    const { propLabel, index, params, permissionEnum} = props

    const toggleBoolean = useCallback(
        ({ id, field }: GridRenderCellParams) => async (
            event: React.ChangeEvent<HTMLInputElement>
        ) => {
            const row = api.current.getRow(id);

            const updatePermissions = event.target.checked ? [...row.permissions, permissionEnum.permissionEnumValue] : row.permissions.filter((permission: string) => {
                return permission !== permissionEnum.permissionEnumValue
            })

            const data: PatchLoginRequestBody = {
                loginid: params.row.loginid,
                handwritten: null,
                loginexpires: null,
                rostered: null,
                permissions: updatePermissions,
                setP360: null,
                p360SchedulingId: null
            }

            try {
                // make API request
                ApiService.patch(`${ApiService.API_URL}admin-panel-tools/admin-tools/update-login`, data).then((response: AxiosResponse<UpdateLoginResponse>) => {
                    api.current.updateRows([{ ...row, [field]: event.target.checked, permissions: updatePermissions }]);
                })

            } catch (error) {
                // something went wrong, restore the row
                api.current.updateRows([{ ...row }]);
            }
        },
        [api]
    )

    return (
        <FormControlLabel
            key={`SetPermissionSelect-${permissionEnum.permissionEnumValue}-${params.row.loginid}-${index}`}
            id={`SetPermissionSelect-${permissionEnum.permissionEnumValue}-${params.row.loginid}-${index}`}
            control={
                <Checkbox
                    checked={params.row.permissions.indexOf(permissionEnum.permissionEnumValue) > -1} onChange={toggleBoolean(props.params)}
                    // checked={isChecked()} onChange={toggleBoolean(props.params)}
                />
            }
            label={propLabel}
        />
    )



}