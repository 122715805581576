/*
 * ******************************************************
 *  * Copyright (C) 2010-Present Avant Assessment
 *  * All Rights Reserved
 *  ******************************************************
 */

import {Button} from "@mui/material"
import React from "react"
import {CheckCircle, PendingActions} from "@mui/icons-material"

export interface WCAssessmentButtonsData {
    assessmentLanguage: string
    selected: boolean
    inProgress: boolean
    completed: boolean
    id: string
    onClickHandler: (id: string) => void
}

export interface WCAssessmentButtonsProps {
    buttonData: WCAssessmentButtonsData
}
const WCAssessmentButtons: React.FC<WCAssessmentButtonsProps> = (props: WCAssessmentButtonsProps) => {

    // @ts-ignore
    const {assessmentLanguage, selected, completed, inProgress, id, onClickHandler} = props.buttonData

    const borderColor = completed ? "" : "#aaaaaa"
    const textColor = completed ? "" : "black"

    const getEndIcon = () => {
        if (completed && !inProgress) {
            return <CheckCircle
                style={{fontSize: "1em", color: '#0DA83E'}}
            />
        }
        if (inProgress && !completed) {
            return <PendingActions
                style={{fontSize: "1em", color: '#f5d485'}}
            />
        }
        return ""
    }

    return (
        <Button
            style={{
                padding: ".18em .8em",
                margin: ".2em",
                borderWidth: "clamp(2px, .2vw, 4px)",
                borderColor: borderColor,
                color: textColor,
                fontSize: "clamp(16px, 3vw, 46px)",
                borderRadius: "clamp(6px, 1vw, 12px)",
                textTransform: "none",
            }}
            variant="outlined"
            // color="primary"
            onClick={() => onClickHandler(id)}
            disabled={completed}
            // endIcon={completed && !inProgress ?
            //     <CheckCircle
            //     // color={"0DA83E"}
            //     style={{fontSize: "1em", color: '#0DA83E'}}
            // /> : ""}
            endIcon={getEndIcon()}
        >{assessmentLanguage}</Button>
    )
}

export default WCAssessmentButtons
