import {
    Checkbox,
    createStyles,
    makeStyles, MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Theme
} from "@material-ui/core"
import lodash from "lodash"
import React, {ChangeEvent, Dispatch, SetStateAction, useEffect} from "react"
import ApiService from "../../services/ApiService"
import {ApiLogin, SortDirection} from "../../types/types"
import {CONTENT_AREA_ID_TO_LANGUAGE_NAME, ProctorTypeNames, ProctorTypes} from "../../util/Constants"
import {log} from "../../util/Logging"
import {TimeUtil} from "../../util/TimeUtil"
import {messageStore} from "../common/messages/MessageStore"
import {UpdateTestCodesDTO} from "./TestCodeManagement"

interface TestCodeManagementTableHeadRow {
    id: keyof ApiLogin
    label: string
}

interface EnhancedTestCodeManagementTableHeadProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ApiLogin) => void
    order: SortDirection
    orderBy: string
}

const headRow: TestCodeManagementTableHeadRow[] = [
    {id: "username", label: "Test Code"},
    {id: "contentAreaId", label: "Language"},
    {id: "active", label: "Active"},
    {id: "passwordExpires", label: "Password Expiration Date"},
    {id: "loginExpires", label: "Login Expiration Date"},
    {id: "rostered", label: "Rostered"},
    {id: "handwritten", label: "Handwritten"},
    {id: "allowTest", label: "Allow Test"},
    {id: "proctorType", label: "Remote Proctor"},
    // {id: "regLink", label: "Registration Link"},
    // {id: "sku", label: "ECWID SKU"}
]

const EnhancedTestCodeManagementTableHead: React.FC<EnhancedTestCodeManagementTableHeadProps> = ({onRequestSort, order, orderBy}) => {

    const createSortHandler = (property: keyof ApiLogin) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {
                    headRow.map(row => (
                        <TableCell
                            key={row.id}
                            sortDirection={orderBy === row.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                                href={""}
                                style={{fontSize: "1.5rem"}}
                            >
                                {row.label}
                            </TableSortLabel>
                        </TableCell>
                    ))
                }
                <TableCell key={"reglink"} style={{fontSize: "1.5rem"}}>
                    Registration Link
                </TableCell>
                <TableCell key={"sku"} style={{fontSize: "1.5rem"}}>
                    ECWID SKU
                </TableCell>
                <TableCell key={"diylink"} style={{fontSize: "1.5rem"}}>
                    DIY Link
                </TableCell>
                <TableCell key={"schedulinglink"} style={{fontSize: "1.rem"}}>
                    Scheduling Link
                </TableCell>
            </TableRow>
        </TableHead>
    )

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            marginTop: theme.spacing(3),
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        tableWrapper: {
            overflowX: "auto",
        },
    }),
)

interface TestCodeManagementTableProps {
    testCodeData: ApiLogin[]
    setTestCodeData: Dispatch<SetStateAction<ApiLogin[]>>
    updateTestCode: (data: UpdateTestCodesDTO) => Promise<void>
    onSearch: () => void
}
export const TestCodeManagementTable: React.FC<TestCodeManagementTableProps> = (props) => {

    const classes = useStyles()

    const [orderBy, setOrderBy] = React.useState("username")
    const [order, setOrder] = React.useState<SortDirection>("desc")

    useEffect(() => {
        sortRows()
    }, [])

    const handleHandwrittenCodeChange = (inputId: number, inputHandwritten: boolean) => {
        const changeCode: UpdateTestCodesDTO = {loginId: inputId, isHandwritten: inputHandwritten}
        log.debug(`Input ID: ${inputId}`)
        log.debug(`Handwritten: ${inputHandwritten}`)
        props.updateTestCode(changeCode)
    }

    const handleSortRequest = (event: React.MouseEvent<unknown>, property: keyof ApiLogin) => {
        const isDesc = orderBy === property && order === "desc"
        setOrder(isDesc ? "asc" : "desc")
        setOrderBy(property)
        sortRows()
    }

    const updateAllowTest = async (loginId: number, allowTest: boolean) => {
        const newArray = [...props.testCodeData]
        const index: number  = newArray.findIndex(x => x.id === loginId)
        newArray[index] = {...newArray[index], allowTest}

        const updatedRow: ApiLogin = newArray[index]
        try {
            await ApiService.updateAllowTest(loginId, allowTest)
            props.setTestCodeData(newArray)
            messageStore.setInfoMessage(`Test code: ${updatedRow.username} now has handwritten set to: ${allowTest}`)
        } catch (e) {
            messageStore.setErrorMessage(`Test code: ${updatedRow.username} failed to update`)
        }
    }

    const updateRostered = async (loginId: number, rostered: boolean, proctorType?: string) => {
        if (proctorType === ProctorTypes.PROCTORTRACK.valueOf()
            || proctorType === ProctorTypes.AVANTPROCTOR.valueOf()
            || proctorType === ProctorTypes.EDUPROCTOR.valueOf())  {
            return
        }
        const newArray = [...props.testCodeData]
        const index: number  = newArray.findIndex(x => x.id === loginId)
        newArray[index] = {...newArray[index], rostered}

        const updatedRow: ApiLogin = newArray[index]
        try {
            await ApiService.updateRostered(loginId, rostered)
            props.setTestCodeData(newArray)
            messageStore.setInfoMessage(`Test code: ${updatedRow.username} now has rostered set to: ${rostered}`)
        } catch (e) {
            messageStore.setErrorMessage(`Test code: ${updatedRow.username} failed to update`)
        }
    }

    const updateProctorType = async (loginId: number, proctorType: string) => {
        // setup the new data and get the updateRow for the username in the messages
        const newArray = [...props.testCodeData]
        const index: number = newArray.findIndex(x => x.id === loginId)
        newArray[index] = {...newArray[index], proctorType}
        const updatedRow: ApiLogin = newArray[index]

        try {
            await ApiService.updateProctorType(loginId, proctorType)
            props.setTestCodeData(newArray)
            props.onSearch()
            messageStore.setInfoMessage(`Test code: ${updatedRow.username} ${Number(proctorType) == 0
                ? 'does not have a selected proctor type'
                : `now has a proctor type of: ${proctorType}`}`)
        } catch (e) {
            messageStore.setErrorMessage(`Test code: ${updatedRow.username} failed to update`)
        }
    }


    const handleProctorChange = (e: ChangeEvent<{name?: string, value: unknown}>, loginId: number) => {
        updateProctorType(loginId, e.target.value as string)
    }

    const sortRows = () => {
        const sortedData = lodash.orderBy(props.testCodeData, [row => {
            let value = row[orderBy]
            if (typeof value === "string") {
                value = value.toLowerCase()
            } else if (!value) {
                value = 0
            } else if (typeof value === "number") {
                const tmpVal = CONTENT_AREA_ID_TO_LANGUAGE_NAME[value]
                value = tmpVal.toLowerCase()
            }
            return value
        }], [order, "asc"])
        props.setTestCodeData(sortedData)
    }

    const replaceToInsertOppCode = (match: string, p1: string, offset: number, s: string): string => {
        return `vpp-${p1}`
    }

    const buildSKU = (testCode: string, rowId: number): string => {
        const oppCode = testCode.replace(/^([^-]*).*/, replaceToInsertOppCode)
        return `${oppCode}-${rowId}`
    }

    const buildRegistraionLink = (rowId: number): string => {
        const url = new URL(`/proctor-registration/${rowId}`, window.location.href)
        return url.href
    }
    const buildDIYLink = (rowId: number): string => {
        const url = new URL(`/proctored/${rowId}`, window.location.href)
        return url.href
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        aria-labelledby={"Test Code Management Table"}
                        size={"small"}
                    >
                        <EnhancedTestCodeManagementTableHead onRequestSort={handleSortRequest} order={order} orderBy={orderBy}/>
                        <TableBody>
                            {
                                props.testCodeData.map ((row, index) => {
                                    const rostered = row.rostered ? row.rostered : false

                                    return (
                                        <TableRow
                                            hover={true}
                                            key={row.id}
                                        >
                                            <TableCell>
                                                {row.username}
                                            </TableCell>
                                            <TableCell>
                                                {CONTENT_AREA_ID_TO_LANGUAGE_NAME[row.contentAreaId]}
                                            </TableCell>
                                            <TableCell
                                                padding={"checkbox"}
                                            >
                                                <Checkbox
                                                    checked={row.active}
                                                    disabled={true}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {row.passwordExpires ? TimeUtil.formatMilliToMonthDayYearLong(Number(row.passwordExpires)) : "None"}
                                            </TableCell>
                                            <TableCell>
                                                {TimeUtil.formatMilliToMonthDayYearLong(Number(row.loginExpires))}
                                            </TableCell>
                                            <TableCell
                                                padding={"checkbox"}
                                            >
                                                <Checkbox
                                                    checked={rostered}
                                                    disabled={
                                                        row.proctorType === ProctorTypes.PROCTORTRACK.valueOf()
                                                        || row.proctorType === ProctorTypes.DIYPROCTOR.valueOf()
                                                        || row.proctorType === ProctorTypes.P360.valueOf()
                                                        || row.proctorType === ProctorTypes.AP_24_7.valueOf()
                                                    }
                                                    onChange={() => updateRostered(row.id, !row.rostered, row.proctorType)}
                                                />
                                            </TableCell>
                                            <TableCell
                                                padding={"checkbox"}
                                            >
                                                <Checkbox
                                                    data-tst-id={'handwritten-test-' + row.handwritten}
                                                    checked={row.handwritten}
                                                    onChange={() => {handleHandwrittenCodeChange(row.id, !row.handwritten!)}}
                                                />
                                            </TableCell>
                                            <TableCell
                                                padding={"checkbox"}
                                            >
                                                <Checkbox
                                                    checked={row.allowTest}
                                                    onChange={() => {updateAllowTest(row.id, !row.allowTest!)}}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    id="testingLanguage"
                                                    value={(row.proctorType) ? row.proctorType : 0}
                                                    onChange={(e) => handleProctorChange(e, row.id)}
                                                >
                                                    <MenuItem key={0} value={0}>None</MenuItem>
                                                    <MenuItem
                                                        key={1}
                                                        value={ProctorTypes.PROCTORTRACK.valueOf()}
                                                    >
                                                        {ProctorTypeNames.PROCTORTRACK.valueOf()}
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={2}
                                                        value={ProctorTypes.DIYPROCTOR.valueOf()}
                                                    >
                                                        {ProctorTypeNames.DIYPROCTOR.valueOf()}
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={3}
                                                        value={ProctorTypes.P360.valueOf()}
                                                    >
                                                        {ProctorTypeNames.P360.valueOf()}
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={4}
                                                        value={ProctorTypes.AP_24_7.valueOf()}
                                                    >
                                                        {ProctorTypeNames.AP_24_7.valueOf()}
                                                    </MenuItem>
                                                </Select>
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    (row.proctorType === ProctorTypes.PROCTORTRACK.valueOf()
                                                        || row.proctorType === ProctorTypes.AVANTPROCTOR.valueOf()
                                                        || row.proctorType === ProctorTypes.EDUPROCTOR.valueOf()
                                                        || row.proctorType === ProctorTypes.AP_24_7.valueOf()) && (buildRegistraionLink(row.id))
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    (row.proctorType === ProctorTypes.PROCTORTRACK.valueOf()
                                                        || row.proctorType === ProctorTypes.AVANTPROCTOR.valueOf()
                                                        || row.proctorType === ProctorTypes.EDUPROCTOR.valueOf()) && (buildSKU(row.username, row.id))
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    (row.proctorType === ProctorTypes.AVANTPROCTOR.valueOf()
                                                        || row.proctorType === ProctorTypes.EDUPROCTOR.valueOf()) && (buildDIYLink(row.id))
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {row.proctorType === ProctorTypes.P360.valueOf() && row.schedulingLink}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        </div>
    )
}
