import React from "react"
import MessageDropDown from "./MessageDropDown"

interface IComponentProps {
    inputDevices: MediaDeviceInfo[]
}

export default class LowMicVolumeMessage extends React.Component<IComponentProps, {}> {

    render() {
        return (
            <div className="LowMicVolumeMessage">
                <MessageDropDown
                    title={"We're having a hard time hearing you."}
                    message={"Please alert your test proctor of your recording issues."}
                    inputDevices={this.props.inputDevices}
                />
            </div>
        )
    }
}
