import {createStyles, withStyles, WithStyles} from "@material-ui/core"
import React from "react"
import {TEvent} from "../../../../types/types"
import {log} from "../../../../util/Logging"
import {TimeUtil} from "../../../../util/TimeUtil"
import Button from "../../../Button/Button"

const styles = createStyles({
    button: {
        width: 180,
        height: 40,
        borderRadius: 4,
        fontWeight: "bold",
        fontSize: 16,
        backgroundColor: "#D0021B",
        color: "#FFFFFF",
        border: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 8,
        paddingRight: 8,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#b70218"
        }
    }
})

type Props = {
    onClick: (event: TEvent) => void
    minRecordingLengthMilliseconds: number
    startTime?: Date
} & WithStyles<typeof styles>

interface IComponentState {
    stopButtonDisabled: boolean
    startTime: Date
}

class StopRecordingVoiceButton extends React.Component<Props, IComponentState> {
    constructor(props: Props) {
        super(props)
        this.state = {
            stopButtonDisabled: props.minRecordingLengthMilliseconds > 0,
            startTime: this.props.startTime ? this.props.startTime : new Date()
        }

        if (this.props.minRecordingLengthMilliseconds > 0) {
            setTimeout(() => {
                const curTime = new Date()
                if (!TimeUtil.enoughTimeHasPassed(curTime, this.state.startTime, this.props.minRecordingLengthMilliseconds)) {
                    log.error(`Expected to be able to enable the stop button, but logick determining if we can" +
                        " submit recording thinks enough time hasn't passed! " +
                        ${curTime.getTime()} ! > ${this.state.startTime.getTime() +
                    this.props.minRecordingLengthMilliseconds}`)
                }

                this.setState({stopButtonDisabled: false})
            }, this.props.minRecordingLengthMilliseconds)
        }
    }

    onStopRecording = (ev: TEvent) => {
        const canStopRecording = TimeUtil.enoughTimeHasPassed(
            new Date(),
            this.state.startTime,
            this.props.minRecordingLengthMilliseconds
        )
        if (canStopRecording) {
            this.props.onClick(ev)
        }
    }

    render() {
        const {classes} = this.props
        return (
            <Button
                className={classes.button}
                onClick={this.onStopRecording}
                disabled={this.state.stopButtonDisabled}
                testId={"StopRecordingVoiceButton"}
            >
                <img alt="stop" src="https://cdn.avantassessment.com/resources/icons/icon-stop.svg"/>
                {"Stop Recording"}
            </Button>
        )
    }
}

export default withStyles(styles)(StopRecordingVoiceButton)
