import Paper from "@material-ui/core/Paper/Paper"
import React from "react"
import {SUPPORT_EMAIL} from "../../util/Constants"

interface Props {
    errorMessage: string
}
export const ErrorMessage: React.FC<Props> = (props) => {
    return (
        <Paper style={{width: 780, padding: 20}} elevation={3}>
            <p>{props.errorMessage}</p>
            <p>
                {"Please try again later. If problem persists please contact your testing center or Avant Support at "}
                <a href={SUPPORT_EMAIL}>{`${SUPPORT_EMAIL}`}</a>
            </p>
        </Paper>
    )
}
