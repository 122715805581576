import {observer} from "mobx-react"
import React from "react"
import {FormControl, FormGroup, FormLabel} from "react-bootstrap"
import { withRouter } from "react-router-dom";
import {RouteComponentProps} from "react-router-dom"
import {SignOnWithCleverButton} from "../../app/clever/SignOnWithCleverButton"
import {SignOnWithClasslinkButton} from "../../app/classlink/SignOnWithClasslinkButton"
import {authStore} from "../../app/common/authentication/AuthStore"
import {productStore} from "../../app/common/products/ProductStore"
import {VerificientLogin} from "../../app/roster/RosterSelectTake"
import ApiService from "../../services/ApiService"
import ProctorApi, {VerificientAvailableLogins} from "../../services/ProctorApi"
import {
    ApiErrorResponse,
    GetLoginProductsRequest,
    GetLoginProductsResponse,
    LoginProductContentAreaData,
    SelectOption
} from "../../types/types"
import Button from "../Button/Button"
import {LoginFormStore} from "./Login"
import {SignOnWithNYCDOEButton} from "../../app/nycdoe/SignOnWithNYCDOEButton";

interface LoginFormProps extends RouteComponentProps<any> {
    store: LoginFormStore
    testLogin?: boolean
    twoFactorAuth?: boolean
    handleLoginProduct: (loginProduct: LoginProductContentAreaData) => void
    authenticate: (loginProduct: LoginProductContentAreaData) => void
}

@observer
export class LoginFormBase extends React.Component<LoginFormProps> {

    handleLogin = (event: any) => {
        event.preventDefault()

        const {store, handleLoginProduct, authenticate} = this.props

        if (store.displayPasswordOnly) {
            authenticate(productStore.loginProduct!)
            return
        }

        store.error = ""
        store.authenticating = true
        const getLoginProductsRequest: GetLoginProductsRequest = {
            username: store.username.trim()
        }
        ApiService.getLoginProducts(getLoginProductsRequest)
            .then(
            (getLoginProductResponse: GetLoginProductsResponse) => {
                authStore.username = store.username.trim()

                if (getLoginProductResponse.products.length === 1) {
                    handleLoginProduct(getLoginProductResponse.products[0])
                } else if (getLoginProductResponse.products.length >= 1) {
                    const loginOptions: SelectOption[] = []
                    const loginIdToData = new Map<string, LoginProductContentAreaData>()
                    getLoginProductResponse.products.forEach((data: LoginProductContentAreaData) => {
                        if (data.contentAreaName === null || data.contentAreaId === 0) {
                            data.contentAreaName = ""
                        }
                        const value = `${data.loginId}${data.productName}`
                        const loginOption: SelectOption = {
                            label: `${data.productName} ${data.contentAreaName}`,
                            value,
                            testId: `login-product-${data.productId}-${data.contentAreaId}`
                        }
                        loginOptions.push(loginOption)
                        loginIdToData.set(value, data)
                    })
                    const loginId: number = loginOptions[0].value
                    store.loginOptions = loginOptions
                    store.loginId = loginId.toString()
                    store.loginIdToData = loginIdToData
                    store.showProductModal = true
                } else {
                    let error = "No tests are associated with that test code."
                    if (this.props.testLogin) {
                        error = "No tests are associated with the test code and password you " +
                            "entered, please try again."
                    }
                    store.error = error
                }
            }).catch(
                (error: ApiErrorResponse) => {
                   // Check user table
                    const loginData: VerificientLogin = {
                        username: store.username.trim(),
                        password: store.password.trim(),
                    }
                    ProctorApi.loginToVerificient(loginData)
                        .then(
                        (response: VerificientAvailableLogins[]) => {
                            this.props.history.push("/proctor/select-takes", {loginData, takes: [...response]})
                        }).catch ((procterError: ApiErrorResponse) => {
                            store.authenticating = false
                            store.handleError(error)
                        })
            })
    }

    render() {
        const {store, testLogin} = this.props
        const loginButtonClass = "login__login-container__button avant-button--primary"
        return (
            <div
                className={
                    store.error
                        ? "login__login-container__form login__login-container__form-error"
                        : "login__login-container__form"
                }
            >
                <form onSubmit={this.handleLogin}>
                    {store.displayPasswordOnly ? null : (
                        <FormGroup>
                            <FormLabel>Test Code{testLogin ? "" : " or Email"}</FormLabel>
                            <FormControl
                                type="text"
                                value={store.username}
                                placeholder=""
                                data-tst-id="username"
                                onChange={(event: any) => store.username = event.target.value}
                                autoFocus={true}
                            />
                        </FormGroup>
                    )}
                    {testLogin || store.displayPasswordOnly ? (
                        <FormGroup>
                            <FormLabel>Password</FormLabel>
                            <FormControl
                                type={store.displayPasswordOnly ? "password" : "text"}
                                value={store.password}
                                placeholder=""
                                data-tst-id="password"
                                onChange={(event: any) => {store.password = event.target.value}}
                                autoFocus={store.displayPasswordOnly}
                            />
                            {store.displayPasswordOnly && this.props.twoFactorAuth &&
                                <>
                                    <FormLabel>Google Authenticator Password</FormLabel>
                                    <FormControl
                                        type={"password"}
                                        value={store.totp}
                                        placeholder={""}
                                        data-tst-id={"totp"}
                                        data-lpignore={true}
                                        onChange={(event: any) => {store.totp = event.target.value}}
                                    />
                                </>
                            }
                        </FormGroup>
                    ) : null}
                    <Button testId="submit" className={loginButtonClass} onClick={this.handleLogin} type="submit">
                        LOG IN
                    </Button>
                </form>
                <div className="Clever-classlink-button">
                    <SignOnWithCleverButton />
                    <SignOnWithClasslinkButton />
                </div>
                <div>
                    <SignOnWithNYCDOEButton />
                </div>
            </div>
        )
    }
}

export default withRouter(LoginFormBase)
