/*
 * ******************************************************
 *  * Copyright (C) 2010-Present Avant Assessment
 *  * All Rights Reserved
 *  ******************************************************
 */


import {
    Grid,
    Paper
} from "@mui/material"
import * as React from "react"
import {useEffect} from "react"
// import {P360Scheduling} from "../../../../types/types"
// import DevToolsApi from "../../../../services/DevToolsApi"

import {LoginTableNew} from "./LoginTableNew"
import {BulkLoginUpdateControl} from "../BulkUpdateTools/BulkLoginUpdateControl"
import {AdminLoginTableData} from "../../../../types/rest/LoginTypes"

export enum TeacherPermissions {
    EDIT = "edit-test-takers",
    ROSTER = "roster-test-takers",
}

export enum TestTakerPermissions {
    TRACK = "track-test-takers",
}

export interface PermissionsInterface {
    permissionDisplayText: string
    permissionEnumValue: TestTakerPermissions | TeacherPermissions
}

export const TeacherPermissionsObject: PermissionsInterface[] = [
    {
        permissionDisplayText: "Edit Test Takers",
        permissionEnumValue: TeacherPermissions.EDIT,
    },
    {
        permissionDisplayText: "Roster Test Takers",
        permissionEnumValue: TeacherPermissions.ROSTER,
    },
]

export const TestTakerPermissionsObject: PermissionsInterface[] = [
    {
        permissionDisplayText: "Track Test Takers",
        permissionEnumValue: TestTakerPermissions.TRACK,
    },
]

export const LoginToolNew: React.FC = () => {

    /**
     * This variable is used to pass data between the table and the bulk update control. There is likely a better way to do this, but this will work for now
     * Maybe a good place to use the Context API?
     * **/
    const[selectedData, setSelectedData] = React.useState<AdminLoginTableData[]>([])

    useEffect(() => {
        // if (schedules === undefined || schedules.length === 0) {
        //     getScheduleObjects()
        // }
    }, [])

    // @ts-ignore
    const handleSelectionChange = (rows: AdminLoginTableData[]): void => {
        setSelectedData([...rows])
    }

    // const getScheduleObjects = () => {
    //     DevToolsApi.getP360ScheduleList().then((result: P360Scheduling[]) => {
    //         // ********** Alphabetize schedules ***********
    //         const alphabetizedSchedules = result.sort(
    //             (scheduleA, scheduleB) => {
    //                 const scheduleALowerCase = scheduleA.title.toLowerCase()
    //                 const scheduleBLowerCase = scheduleB.title.toLowerCase()
    //                 if (scheduleALowerCase > scheduleBLowerCase) {
    //                     return 1
    //                 }
    //                 if (scheduleALowerCase < scheduleBLowerCase) {
    //                     return -1
    //                 } else {
    //                     return 0
    //                 }
    //             }
    //         )
    //         // ********** End Alphabetize Schedules ***********
    //         setSchedules(alphabetizedSchedules)
    //     })
    // }

    return (
        <Grid container={true} spacing={2} direction="column" id={"LoginToolChangeToolGrid"}>
            <Grid item={true} container={true} xs={12}>
                <Grid item={true} xs={12} id={"LoginToolChangeToolGrid-BulkUpdateControlGrid"}>
                    <BulkLoginUpdateControl selectedTableData={selectedData}/>
                </Grid>
                <Grid item={true} xs={12}  id={"LoginToolChangeToolGrid-LoginTableNewGrid"}>
                    <Paper
                        elevation={3}
                    >
                        <LoginTableNew setSelectedIds={setSelectedData}/>
                    </Paper>
                </Grid>
            </Grid>

        </Grid>
    )
}

