import {Box, createStyles, Typography, WithStyles, withStyles} from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import {LoginProductContentAreaData} from "../../types/types"
import {APT_PRODUCT_ID, CONTENT_AREA_ID_ARABIC, RTL_CONTENT_AREA_IDS} from "../../util/Constants"
import SpecialCharacterKeyboard from "../SpecialCharacterKeyboard/SpecialCharacterKeyboard"
import ApiService from "../../services/ApiService";

const styles = createStyles({
    arabicFontSize: {
        fontSize: 20,
        lineHeight: 1.5
    }
})

type Props = {
    answer: string
    languageName: string
    autoSaving: boolean
    contentAreaId: number
    product: LoginProductContentAreaData
    handleAnswerChange: (answer: any) => void
} & WithStyles<typeof styles>

interface IComponentState {
    savingText: string
    interval?: number
    isSaving: boolean
    showUppercase: boolean
    responseMaximumLengthCharacters: number,
    currentAnswer: string
}

class Writing extends React.Component<Props, IComponentState> {
    state: IComponentState
    textArea: HTMLTextAreaElement | undefined

    constructor(props: Props) {
        super(props)

        this.state = {
            savingText: "Saving...",
            isSaving: false,
            showUppercase: false,
            responseMaximumLengthCharacters: 0,
            currentAnswer: (this.props.answer && this.props.answer !== "") ? this.props.answer : "",
        }
    }

    componentDidMount() {
        if (this.textArea) {
            this.textArea.onpaste = () => false
            this.textArea.oncopy = () => false
            this.textArea.oncut = () => false
            this.textArea.onselect = () => false
            this.textArea.ondragover = () => false // ondragover and ondrop will prevent users from dragging and dropping
            this.textArea.ondrop = () => false //  content into the textarea during writing tests
            this.textArea.onclick = () => {
                this.textArea!.spellcheck = false
            }
        }
        this.getResponseMaximumLengthCharacters()
    }

    componentDidUpdate(prevProps:Props) {
        //We need to make sure if this component is updated if we have the current version of the answer.
        if (this.props.answer !== prevProps.answer) {
            this.setState({currentAnswer:this.props.answer})

        }
        let interval = this.state.interval
        let savingText = this.state.savingText
        if (this.props.autoSaving && !this.state.isSaving) {
            savingText = this.getSavingText(savingText)
            interval = window.setInterval(() => {
                savingText = this.getSavingText(this.state.savingText)
                this.setState({savingText})
            }, 1000)
            this.setState({isSaving: true})
        } else if (!this.props.autoSaving && this.state.isSaving) {
            window.clearInterval(interval)
            interval = undefined
            this.setState({isSaving: false})
        }
        if (savingText !== this.state.savingText || interval !== this.state.interval) {
            this.setState({savingText, interval})
        }

        if(this.textArea) {
            this.textArea.spellcheck = false
        }

    }

    onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const answer = event.target.value
        this.setState({currentAnswer: answer})
        this.props.handleAnswerChange(answer)
    }

    insertCharacter = (character: string) => {
        if (!this.textArea) {
            throw Error("this.textArea is undefined")
        }

        //Where is the current now so we can insert the character at the correct point.
        const firstIndex = this.textArea.selectionStart
        const lastIndex = this.textArea.selectionEnd

        //Create the new string with the inserted character
        const currentVal = this.state.currentAnswer
        let newVal = currentVal.slice(0, firstIndex) + character + currentVal.slice(lastIndex)

        // NOTE: character is actually a string that can contain multiple characters (i.e. latin letter + accent)
        // so it is important than we add the length of the string in the event it is greater than 1 character to
        // prevent stacking of accents or characters in one place in the textfield.
        const newCursorPosition = firstIndex + character.length

        //OK make all of the changes
        this.setState({currentAnswer:newVal},
            () => {
                //once all of the changes are all done and settled THEN update the position
                if (this.textArea) {
                    //For the basic bar cursor the section start and end are equal. (other you get a selected area in the input)
                    this.textArea.setSelectionRange(newCursorPosition,newCursorPosition)
                    this.textArea.focus()
                }
            }
        )

    }

    getSavingText = (savingText: string): string => {
        switch (savingText) {
            case "Saving":
                savingText = "Saving."
                break
            case "Saving.":
                savingText = "Saving.."
                break
            case "Saving..":
                savingText = "Saving..."
                break
            case "Saving...":
                savingText = "Saving"
                break
        }
        return savingText
    }

    getResponseMaximumLengthCharacters = async () => {
        await ApiService.getResponseMaximumLengthWritingCharacters()
            .then(response => {
                // If we got back a number...
                if ( response && !isNaN(Number(response)) ) {
                    let maxChar = Number(response)
                    if (Number.isInteger(maxChar) && maxChar > 0) {
                        this.setState({'responseMaximumLengthCharacters': maxChar})
                    }
                }
            })
    }

    render() {
        const {autoSaving, contentAreaId, languageName, product, classes} = this.props
        const {savingText, responseMaximumLengthCharacters} = this.state

        const isRtl: boolean = RTL_CONTENT_AREA_IDS.indexOf(contentAreaId) > -1
        let writingClass = "writing"
        if (isRtl) {
            writingClass = `${writingClass} writing--rtl`
        }
        let language = languageName
        if (language === "Arabic") {
            language = "Modern Standard Arabic"
        }

        let testAreaClass = "writing__container__text-area"
        if (product.productId === APT_PRODUCT_ID.value() || product.contentAreaId === CONTENT_AREA_ID_ARABIC) {
            testAreaClass = classNames(testAreaClass, classes.arabicFontSize)
        }

        return (
            <div className={writingClass}>
                <p className="writing__prompt">Write your answer below.</p>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    {autoSaving ? (
                        <Box className="writing__saving">
                            <Typography>{savingText}</Typography>
                        </Box>
                    ) : (
                        <Box className="writing__saving" display="flex" flexDirection="row" justifyContent="left" alignItems="center">
                            <Box className="writing__saving__icon">
                                <i className="material-icons writing__saving__icon__check">done</i>
                            </Box>
                            <Box>{"\u00A0"} All changes saved</Box>
                        </Box>
                    )}
                    {
                        responseMaximumLengthCharacters > 0 && this.state.currentAnswer.length > 0
                            ? <Box><Typography>{this.state.currentAnswer.length}/{responseMaximumLengthCharacters} characters</Typography></Box>
                            : null
                    }
                </Box>
                <div className="writing__container">
                    <textarea
                        className={testAreaClass}
                        data-tst-id="text-area"
                        onChange={this.onTextChange}
                        onPaste={undefined}
                        rows={10}
                        value={this.state.currentAnswer}
                        data-enable-grammarly="false"
                        ref={(textArea: HTMLTextAreaElement) => (this.textArea = textArea)}
                        maxLength={responseMaximumLengthCharacters > 0 ? responseMaximumLengthCharacters : undefined}
                    />
                    <SpecialCharacterKeyboard contentAreaId={contentAreaId} insertCharacter={this.insertCharacter}/>
                </div>
                <p className="writing__remember">Remember to...</p>
                <div className="writing__remember-point">
                    <div className="writing__remember-point__icon">
                        <i className="material-icons writing__remember-point__icon__check">done</i>
                    </div>
                    {"\u00A0"} Write in {language}
                </div>
                <div className="writing__remember-point">
                    <div className="writing__remember-point__icon">
                        <i className="material-icons writing__remember-point__icon__check">done</i>
                    </div>
                    {"\u00A0"} Use complete sentences
                </div>
                <div className="writing__remember-point">
                    <div className="writing__remember-point__icon">
                        <i className="material-icons writing__remember-point__icon__check">done</i>
                    </div>
                    {"\u00A0"} Write as much as you can{responseMaximumLengthCharacters > 0 ? <>, up to {responseMaximumLengthCharacters} characters</> : null}
                </div>
                <div className="writing__remember-point">
                    <div className="writing__remember-point__icon">
                        <i className="material-icons writing__remember-point__icon__check">done</i>
                    </div>
                    {"\u00A0"} Organize your thoughts
                </div>
            </div>

        )
    }
}

export default withStyles(styles)(Writing)
