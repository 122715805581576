import React from "react"
import {BodyText} from "../../../../../styles/AvantTypography"
import {theme} from "../../../../../styles/MuiThemes"
import {AvantPopperButton, MenuButtonVariant} from "../../../../generic-components/AvantPopperButton"
import {LevelKeyContent} from "./LevelKeyContent"

interface LevelKeyProps {
    narrowPadding?: boolean
}
export const LevelKey: React.FC<LevelKeyProps> = ({narrowPadding= false}) => (
    <AvantPopperButton
        title={<BodyText>Key</BodyText>}
        variant={MenuButtonVariant.OUTLINED}
        style={narrowPadding ? {marginRight: theme.spacing(1)} : {marginRight: theme.spacing(4)}}
    >
        <LevelKeyContent/>
    </AvantPopperButton>
)
