import React, {useState, useEffect, ChangeEvent} from "react"
import Button from "../Button/Button"
import {createStyles, Dialog, DialogActions, DialogContent, makeStyles} from "@material-ui/core"
import {authStore} from "../../app/common/authentication/AuthStore"
import {productStore} from "../../app/common/products/ProductStore"
import {loadingSpinnerStore} from "../../app/common/loaders/LoadingSpinnerStore"
import {messageStore} from "../../app/common/messages/MessageStore"
import {ApiErrorResponse, LoginProductContentAreaData} from "../../types/types"
import * as HttpStatus from "http-status-codes"

const AdminTwoFactorAuthenticationButton: React.FC = (): JSX.Element => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const closeModal = (): void => setShowModal(false)

    return (
        <>
            <Button
                className={"avant-button--logout avant-button--primary"}
                style={{marginRight: "1rem"}}
                type={"button"}
                onClick={() => setShowModal(true)}
            >
                Enable 2FA
            </Button>

            <AdminTwoFactorAuthenticationModal openModal={showModal} closeModal={closeModal}/>
        </>
    )
}

interface AdminTwoFactorAuthenticationModalProps {
    openModal: boolean
    closeModal: () => void
}

export interface GoogleAuthQRCodeResponse {
    qrCodeDataURI: string
}

export interface TwoFactorAuthResponse {
    authType: string
}

const useStyles = makeStyles(() =>
    createStyles({
        canvas: {
            display: "block",
            margin: "0 auto",
        },
        marginBottom: {
            marginBottom: "1rem",
        }
    })
)

const AdminTwoFactorAuthenticationModal: React.FC<AdminTwoFactorAuthenticationModalProps> = (props: AdminTwoFactorAuthenticationModalProps): JSX.Element => {
    const {openModal, closeModal} = props
    const [totp, setTotp] = useState<string>("")
    const [loginId, setLoginId] = useState<number | null>(null)
    const classes = useStyles()

    useEffect(() => {
        if (loginId == null) {
            productStore.loginProduct && setLoginId(productStore.loginProduct.loginId)
        }

        if (openModal) {
            loadingSpinnerStore.hideLoadingSpinner = false

            authStore.generateGoogleAuthQRCode(loginId)
                .then((response: GoogleAuthQRCodeResponse) => {
                    loadingSpinnerStore.hideLoadingSpinner = true

                    // Draw the QR Code to a canvas
                    const canvas = document.getElementById("QRCodeCanvas") as HTMLCanvasElement
                    const context = canvas.getContext("2d") as CanvasRenderingContext2D

                    // Create an image element for QR code and draw it to the canvas
                    const image = new Image()
                    image.onload = () => {
                        canvas.width = image.width
                        canvas.height = image.height
                        context.drawImage(image, 0, 0)
                    }
                    image.src = response.qrCodeDataURI
                })
                .catch((error: ApiErrorResponse) => {
                    handleError(error)
                })
        }
    }, [loginId, openModal])

    const enableTwoFactorAuth = (): void => {
        // Early return if the password is empty.
        if (!totp) {
            messageStore.setErrorMessage("The password field is empty.")
            return
        }

        const loginProduct: LoginProductContentAreaData | undefined = productStore.loginProduct

        loadingSpinnerStore.hideLoadingSpinner = false

        authStore.enableTwoFactorAuth(loginId, totp)
            .then((response: TwoFactorAuthResponse) => {
                loadingSpinnerStore.hideLoadingSpinner = true

                // Add the auth type to the login.
                if (loginProduct) {
                    loginProduct.multifactorAuthType = response.authType
                    productStore.setLoginProduct(loginProduct)
                }

                messageStore.setInfoMessage("Two Factor Authentication has been enabled.")
            })
            .catch((error: ApiErrorResponse) => {
                handleError(error)
            })

        if (loginProduct && loginProduct.multifactorAuthType != null) {
            closeModal()
        }
    }

    const handleError = (error: ApiErrorResponse): void => {
        loadingSpinnerStore.hideLoadingSpinner = true

        let errorMessage = ""

        switch(error.response.status) {
            case HttpStatus.BAD_REQUEST:
                errorMessage = "Your one-time password is invalid. Please enter a new password from the Google Authenticator App."
                break
            default:
                errorMessage = "There was an unexpected error."
        }

        if (errorMessage) {
            messageStore.setErrorMessage(errorMessage)
        }
    }

    const close = (): void => {
        setLoginId(null)
        setTotp("")
        closeModal()
    }

    return (
        <Dialog
            open={openModal}
            maxWidth={"lg"}
        >
            <DialogContent>
                <div>
                    Scan the QR Code using the Google Authenticator App on your phone.
                </div>
                <canvas id={"QRCodeCanvas"} className={classes.canvas}/>
                <div className={classes.marginBottom}>
                    Enter the password from the Google Authenticator App to enable Two-Factor Authentication
                </div>
                <input
                    type={"string"}
                    className={"form-control"}
                    value={totp}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setTotp(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    className={"avant-button--logout avant-button--primary"}
                    type={"button"}
                    onClick={() => enableTwoFactorAuth()}
                    disabled={!totp}
                >
                    Enable
                </Button>
                <Button
                    className={"avant-button--logout avant-button--secondary"}
                    type={"button"}
                    onClick={() => close()}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AdminTwoFactorAuthenticationButton
