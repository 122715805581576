import {Button, createStyles, Theme} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import React, {useEffect, useState} from "react"
import ClasslinkApi from "../../services/ClasslinkApi"
import SSO_BUTTON_FOR_CLASSLINK from "../../assets/Classlink/classlink.png"


const useClasslinkLoginStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
            textAlign: "center"
        },
        button: {
            padding: 0,
            maxWidth: 200
        },
        image: {
            width: "168px",
            height: "39px"
        }
      
    })
)

interface ClassLink {
    url: string
}

export const SignOnWithClasslinkButton: React.FC = (props) => {
    const loadLink = async () => {
        const link = await ClasslinkApi.getClasslinkId()
        setClasslink({url: link.classLinkUrl})
    }

    const [classlink, setClasslink] = useState<ClassLink>({url: ""})
    useEffect(() => {
        loadLink()
    }, [])

    const classes = useClasslinkLoginStyles()

    const authWithClasslink = () => {
        window.location.href = classlink.url
    }

    return (
        <div className={classes.root}>
            <Button className={classes.button} onClick={authWithClasslink}>
                <img src={SSO_BUTTON_FOR_CLASSLINK} className={classes.image} />
            </Button>
        </div>
    )
}
