import ApiService from "./ApiService"


export interface RosterStudentInfo {
    index: number
    firstName: string
    lastName: string
    loginName: string
    errors: string[]
}
export interface RosterGroupResponse {
    students: RosterStudentInfo[]
}
export default class RosterApi {
    static rosterGroup(loginId: number, students: RosterStudentInfo[]): Promise<RosterGroupResponse> {
        const url: string = `${ApiService.API_URL}rostering/group/${loginId}`
        return new Promise((resolve, reject) =>
            ApiService.addInteractionBlockingRequest(
                ApiService.post(url, {students})
                    .then(response => resolve(response.data))
                    .catch(error => reject(error))
            )
        )
    }
}
