import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core"
import {sortBy} from "lodash"
import {observable} from "mobx"
import {observer} from "mobx-react"
import * as React from "react"
import ReactTable from "react-table"
import {itemStore} from "../../app/common/item/ItemStore"
import {ItemHistoryTableData} from "../../app/common/item/models/ItemHistory"

interface ComponentProps {
    itemId: number
    itemName: string
}

export class ItemHistoryDialogContainer extends React.Component<ComponentProps> {

    componentDidMount(): void {
        itemStore.getItemAuditLog(this.props.itemId)
    }

    render() {
        const itemHistory = itemStore.itemHistory
        if (itemHistory == null) {
            return null
        }

        return (
            <ItemHistoryDialog
                itemId={this.props.itemId}
                itemName={this.props.itemName}
            />
        )
    }
}


@observer
class ItemHistoryDialog extends React.Component<ComponentProps> {

    @observable
    open = false

    renderHTML = (props: { value: string }) =>
        <DialogContentText style={{textOverflow: "inherit", overflow: "hidden"}} dangerouslySetInnerHTML={{__html: props.value}}/>

    // tslint:disable-next-line:member-ordering
    columns = [
        {Header: "Username", accessor: "userName", width: 175, style: {whiteSpace: "unset"}, Cell: this.renderHTML},
        {Header: "Name", accessor: "name", width: 100, style: {whiteSpace: "unset"}, Cell: this.renderHTML},
        {Header: "Content Type", accessor: "contentType", width: 100, style: {whiteSpace: "unset"}, Cell: this.renderHTML},
        {Header: "Before", accessor: "contentBefore", style: {whiteSpace: "unset"}, Cell: this.renderHTML},
        {Header: "After", accessor: "contentAfter", style: {whiteSpace: "unset"}, Cell: this.renderHTML},
        {Header: "Date", accessor: "changeDate", width: 100, style: {whiteSpace: "unset"}, Cell: this.renderHTML}
    ]

    generateData = (): ItemHistoryTableData[] => {
        const data = []
        if (itemStore.itemHistory !== null && itemStore.itemHistory !== undefined) {
            for (const itemHistory of itemStore.itemHistory) {
                data.push(
                    {
                        userName: itemHistory.userName,
                        name: itemHistory.firstName + " " + itemHistory.lastName,
                        contentType: itemHistory.itemHistoryDetails[0].changeType,
                        contentBefore: itemHistory.itemHistoryDetails[0].contentbefore,
                        contentAfter: itemHistory.itemHistoryDetails[0].contentafter,
                        changeDate: itemHistory.itemHistoryDetails[0].lasttouched
                    }
                )
            }
        }

        return sortBy(data, (d: ItemHistoryTableData) => new Date(d.changeDate).getTime())
    }

    render() {

        const data = this.generateData()

        const showPagination = data.length > 20
        const minRows = data.length

        return (
            <span style={{marginLeft: "10px", fontWeight: "bold"}}>

                <Button
                    onClick={() => this.open = true}
                    color={"primary"}
                    variant={"contained"}
                >
                    Item History
                </Button>

                <Dialog
                    open={this.open}
                    onClose={() => this.open = false}
                    fullScreen={true}
                >
                    <DialogTitle>{"Item History: " + this.props.itemName}</DialogTitle>
                    <DialogContent>
                            <ReactTable
                                columns={this.columns}
                                data={data}
                                showPaginationTop={showPagination}
                                showPaginationBottom={showPagination}
                                minRows={minRows}
                                defaultSorted={[{
                                    id   : 'changeDate',
                                    desc : true,
                                }]}
                            />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => this.open = false}
                            color="primary"
                            role={"button"}
                            variant={"contained"}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </span>
        )
    }
}
