/*
 * ******************************************************
 *  * Copyright (C) 2010-Present Avant Assessment
 *  * All Rights Reserved
 *  ******************************************************
 */
import {DataGridPro, GridColDef, GridToolbar} from "@mui/x-data-grid-pro"
import moment from "moment/moment"
import React, {useState} from "react"
import ApiService from "../../../../services/ApiService"
import {SSOEntityReportData, SSOReportQueryParams} from "./SSOReportEntityUtils"

export const SSOReportTools: React.FC = () => {

    const [rows, setRows] = useState<SSOEntityReportData[]>([])

    // TODO: If we start doing server side paging and queries, the below code will be used
    // const [paginationModel, setPaginationModel] = React.useState({
    //     page: 0,
    //     pageSize: 10,
    // })

    const [loading, setLoading] = useState<boolean>(false)

    // @ts-ignore
    const [queryParams, setQueryParams] = React.useState<SSOReportQueryParams>(
        {
            pageData: {
                pageNumber: 0,
                size: 10,
                totalPages: 0,
                totalElements: 0
            },
            sort: "username,asc"
        }
    )

    const columns: GridColDef[] = [
        { headerName: "Avant Id", field: "id", hideable: true},
        { headerName: "Entity Name", field: "name", width: 200},
        { headerName: "Testing Start", field: "testingStart", type: "date",
            valueGetter: (value: {testingStart: Date}) => {
                const momentDate = value.testingStart ? moment(value.testingStart) : ""

                return momentDate !== "" ? momentDate.toDate() : ""
            }
        },
        { headerName: "Testing End", field: "testingEnd", type: "date",
            valueGetter: (value: {testingEnd: Date}) => {
                const momentDate = value.testingEnd ? moment(value.testingEnd) : ""

                return momentDate !== "" ? momentDate.toDate() : ""
            }
        },
        { headerName: "Term Start", field: "termStart", type: "date",
            valueGetter: (value: {termStart: Date | null}) => {
                const momentDate = (value !== null && value.termStart !== null) ? moment(value.termStart) : ""

                return momentDate !== "" ? momentDate.toDate() : ""
            }
        },
        { headerName: "SSO System", field: "ssoSystem" },
        { headerName: "Opp#", field: "opportunityNumber" },
        { headerName: "SSO System's Id", field: "ssoId" },
        { headerName: "Licenses", field: "licensesSold"},
        { headerName: "Parent", field: "parent", type: "number"},
        { headerName: "Sold Count", field: "countOfLicensesSold", type: "number"},
        { headerName: "Teacher Count", field: "teacherCount", type: "number"},
        { headerName: "Student Count", field: "studentCount", type: "number"},
        { headerName: "Last Sync Started", field: "lastSyncStarted", type: "date",
            valueGetter: (value: {lastSyncStarted: Date | null}) => {
                const momentDate = (value !== null && value.lastSyncStarted !== null) ? moment(value.lastSyncStarted) : ""

                return momentDate !== "" ? momentDate.toDate() : ""
            }
        },
        { headerName: "Last Sync Completed", field: "lastSyncCompleted", type: "date",
            valueGetter: (value: {lastSyncCompleted: Date | null}) => {
                const momentDate = (value !== null && value.lastSyncCompleted !== null) ? moment(value.lastSyncCompleted) : ""

                return momentDate !== "" ? momentDate.toDate() : ""
            }
        },
    ]

    const fetchData = (query: SSOReportQueryParams) => {
        setLoading(true)
        ApiService.getSSOEntityReportingData(query).then((data) => {
            setRows(data.tableData)
            setLoading(false)
        })
    }

    // TODO: If we start doing server side paging and queries, the below code will be used
    // const onSetPaginationModel = (newPageData: GridPaginationModel) => {
    //     setPaginationModel(newPageData)
    //     setQueryParams({...queryParams, pageData: {...queryParams.pageData, pageNumber: newPageData.page, size: newPageData.pageSize}})
    //     const curQueryParams: NewQueryParams = {...queryParams, pageData: {...queryParams.pageData, pageNumber: newPageData.page, size: newPageData.pageSize}}
    //     fetchData(curQueryParams)
    // }

    React.useEffect(() => {
        fetchData(queryParams)
    }, [])


    return(
        <div>
            <DataGridPro
                columns={columns}
                rows={rows ? rows : []}
                getRowId={(row) => row.id}
                slots={{ toolbar: GridToolbar }}
                loading={loading}
                autoHeight={true}  // This sets the height, so you will see initial loading spinner
                pagination={true}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false
                        }
                    },
                    pagination: {
                    },
                    filter: {
                    }
                }}
            />
        </div>
    )

}

