import { createStyles, withStyles, WithStyles } from "@material-ui/core"
import React from "react"
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
const styles = createStyles({
    button: {
        width: 170,
        height: 40,
        borderRadius: 4,
        fontWeight: "bold",
        fontSize: 16,
        backgroundColor: "#D0021B",
        color: "white",
        border: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 8,
        paddingRight: 8,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#f4d1d5"
        }
    },
    Erasebutton: {
        width: 205,
        height: 40,
        borderRadius: 4,
        fontWeight: "bold",
        fontSize: 16,
        backgroundColor: "#D0021B",
        color: "white",
        border: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 8,
        paddingRight: 8,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#f4d1d5"
        }
    },

})

type Props = {
    onClick: (event: any) => void
    label: string
    check?: boolean
} & WithStyles<typeof styles>

const VideoRecordingButtons = ({ classes, onClick, label, check }: Props) => {
    return (
        <>
            {check !== true ? (<button type="button" className={label === "Erase and Re-Record" ? classes.Erasebutton : classes.button} onClick={onClick} data-tst-id="BeginVoiceRecordingButton">
                <FiberManualRecordIcon />
                {label}
            </button>
            )
                :
                (<></>)
            }
        </>
    )
}
export default withStyles(styles)(VideoRecordingButtons)


