import * as lodash from "lodash"
import React from "react"
import {Alert, Col, ListGroup, ListGroupItem, Row} from "react-bootstrap"
import FontAwesome from "react-fontawesome"
import {UserDataFilters, UserReportData, UserReportLanguage, UserReportModule} from "../../types/types"
import Button from "../Button/Button"
import {Paper} from "@material-ui/core"

interface AdvanceReportListProps {
    users: UserReportData[]
    page: number
    totalPages: number
    getUserData: (filters: UserDataFilters) => Promise<UserReportData[]>
    getUserDataFull: (filters: UserDataFilters) => Promise<UserReportData[]>
    setHasUserData: (hasUserData: boolean) => void
    filters: UserDataFilters
}

interface AdvanceReportListState {
    error: boolean | string
}

interface CertTestHighScore {
    score?: number
    date?: string
}

const SIXTY_SECONDS = 60

export default class AdvanceReportList extends React.Component<AdvanceReportListProps, AdvanceReportListState> {

    constructor(props: AdvanceReportListProps) {
        super(props)
        this.state = {
            error: false
        }
    }

    download = () => {
        let text: string =
            "First Name, Last Name, Username, Language, Total time, Module 1 Status, Module 1 Time, " +
            "Module 2 Status, Module 2 Time, Module 3 Status, Module 3 Time, Module 4 Status, Module 4 Time, " +
            "Module 5 Status, Module 5 Time, Module 6 Status, Module 6 Time, Practice Test Time, " +
            "Certification Test Score, Certification Test Time\n"
        this.getUserData(this.props.page, this.props.getUserData).then((users: UserReportData[]) => {
            users.forEach((user: UserReportData) => {
                // Create CSV
                text += `${user.firstName}, `
                text += `${user.lastName}, `
                text += `${user.username}, `
                user.languages.forEach((language: UserReportLanguage) => {
                    text += `${language.name}, ${Math.floor(language.duration / SIXTY_SECONDS)} min, `
                    language.modules.forEach((module: UserReportModule) => {
                        if (module.name.includes("Module")) {
                            text += `${module.passed ? "Complete" : module.duration > 0 ? "Started" : "Not started"}, `
                            text += `${Math.floor(module.duration / SIXTY_SECONDS)} min, `
                        } else if (module.name.includes("Practice")) {
                            text += `${Math.floor(module.duration / SIXTY_SECONDS)} min, `
                        } else {
                            text += `${
                                module.duration > 0 && module.score
                                    ? `${module.score}%, `
                                    : "Not started"
                            }, `
                            text += `${Math.floor(module.duration / SIXTY_SECONDS)} min`
                        }
                    })
                })
                text += "\n"
            })

            // Create hidden link
            const blob = new Blob([text], {type: "text/csv;charset=utf8;"})
            const element = document.createElement("a")
            document.body.appendChild(element)
            element.setAttribute("href", window.URL.createObjectURL(blob))
            element.setAttribute("download", "advance_report.csv")
            element.click()
            document.body.removeChild(element)
        })
    }

    getUserData = (
        page: number,
        getUserData: (filters: UserDataFilters) => Promise<UserReportData[]>
    ): Promise<UserReportData[]> => {
        const filters: UserDataFilters = Object.assign({}, this.props.filters)
        filters.page = page
        return getUserData(filters)
    }

    getPreviousPage = () => {
        this.getUserData(this.props.filters.page - 1, this.props.getUserData)
    }

    getNextPage = () => {
        this.getUserData(this.props.filters.page + 1, this.props.getUserData)
    }

    getCertTestHighScore = (modules: IterableIterator<UserReportModule>): CertTestHighScore => {

        const modulesArray: UserReportModule[] = Array.from(modules)

        const highScoresArray: UserReportModule[] = lodash.filter(modulesArray, (mod: UserReportModule) => {
            return mod.passed && mod.name.includes("Certification")
        })

        const highScore = lodash.maxBy(highScoresArray, (mod: UserReportModule) => {
            return mod.score
        })

        return {
            score: highScore ? highScore.score : undefined,
            date: highScore ? highScore.datePassed : undefined
        }

    }

    renderUsers = (users: UserReportData[]): React.ReactNode => {
        return (
            <>
                {users.map((user: UserReportData) => {
                    return (
                            <ListGroupItem key={user.username}>
                                {user.languages && user.languages.size > 0 && (
                                    <>
                                        <Row>
                                            <Col sm={6}>
                                                <label>
                                                    {user.firstName} {user.lastName}
                                                </label>
                                                <p>{user.username}</p>
                                            </Col>
                                            <Col sm={6}>
                                                <label>Groups</label>
                                                <p>
                                                    {user.stateName}{user.districtName ? `, ${user.districtName}`: ""}{user.schoolName ? `, ${user.schoolName}`: ""}
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <ListGroup>
                                                    <AdvanceReportListLanguages
                                                        languages={user.languages.values()}
                                                        getHighScores={this.getCertTestHighScore}
                                                    />
                                                </ListGroup>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </ListGroupItem>
                    )
                })}
            </>
        )
    }

    renderControls = (page: number, totalPages: number): React.ReactNode => {
        return (
            <ListGroupItem
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}
            >
                <div>
                    <Button
                        className="avant-button avant-button--primary"
                        onClick={() => this.props.setHasUserData(false)}
                    >
                        <FontAwesome name={"chevron-left"}/> Update Filters
                    </Button>
                </div>
                <div>
                    <Button className="avant-button avant-button--primary" onClick={this.download}>
                        Download Data
                    </Button>
                </div>
                <div>
                    <div>
                        <label style={{paddingRight: "10px"}}>
                            Page {page + 1} of {totalPages}
                        </label>
                        <Button
                            className="avant-button avant-button--primary"
                            onClick={this.getPreviousPage}
                            disabled={page === 0}
                        >
                            <FontAwesome name={"chevron-left"}/>
                        </Button>
                        <Button
                            className="avant-button avant-button--primary"
                            onClick={this.getNextPage}
                            disabled={page + 1 === totalPages}
                        >
                            <FontAwesome name={"chevron-right"}/>
                        </Button>
                    </div>
                </div>
            </ListGroupItem>
        )
    }

    renderErrors = (error: boolean | string): React.ReactNode => {
        return (
            <div className="alerts">
                <Alert variant="danger" onClose={() => this.setState({error: false})}>
                    {error}
                </Alert>
            </div>
        )
    }

    render() {
        const {users, page, totalPages} = this.props
        const {error} = this.state
        return (
            <>
                {error && this.renderErrors(error)}
                <Row>
                    <Col sm={{span: 10, offset: 1}}>
                        <Paper>
                            {this.renderControls(page, totalPages)}
                            {this.renderUsers(users)}
                            {this.renderControls(page, totalPages)}
                        </Paper>
                    </Col>
                </Row>
            </>
        )
    }
}

const AdvanceReportLanguageDetails = (props: { modules: IterableIterator<UserReportModule>, certTestHigh: CertTestHighScore }) => {
    const {modules, certTestHigh} = props

    return (
        <>
            {
                Array.from(modules).map((module: UserReportModule) => {
                        return (
                            <Col key={module.name} sm={2}>
                                <p>
                                    <b>{module.name}</b>
                                </p>
                                {
                                    module.name.includes("Module") && (
                                        <p>
                                            {module.passed
                                                ? "Completed"
                                                : module.duration > 0
                                                    ? "Started"
                                                    : "Not started"}
                                        </p>
                                    )
                                }
                                {
                                    module.name.includes("Certification") &&
                                    (
                                        <>
                                            <p>
                                                {
                                                    (certTestHigh && certTestHigh.date)
                                                        ? `Completed ${certTestHigh.date}`
                                                        : "No passing Certification test"
                                                }
                                            </p>

                                            <p>
                                                {
                                                    (certTestHigh && certTestHigh.score)
                                                        ? (<b>Score: {certTestHigh.score}%</b>)
                                                        : ("")
                                                }
                                            </p>
                                        </>
                                    )

                                }

                                <p>{Math.floor(module.duration / SIXTY_SECONDS)} min</p>
                            </Col>
                        )
                    }
                )
            }
        </>
    )
}

const AdvanceReportListLanguages = (
    props: { languages: IterableIterator<UserReportLanguage>, getHighScores: (modules: IterableIterator<UserReportModule>) => CertTestHighScore }
) => (
    <>
        {
            Array.from(props.languages).map((language: UserReportLanguage) => {

                return (
                    <ListGroupItem key={language.name}>
                        <label>{language.name}</label>
                        <br/>
                        <p>{Math.floor(language.duration / SIXTY_SECONDS)} total min</p>
                        <Row>
                            <AdvanceReportLanguageDetails
                                modules={language.modules.values()}
                                certTestHigh={props.getHighScores(language.modules.values())}
                            />
                        </Row>
                    </ListGroupItem>
                )
            })
        }
    </>
)

