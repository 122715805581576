import {observable} from "mobx"


export class LoadingSpinnerStore {

    @observable
    hideLoadingSpinner = true

    @observable
    stopAll = false

}

export const loadingSpinnerStore = new LoadingSpinnerStore()
