// import {makeStyles} from "@material-ui/core"
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import * as Sentry from "@sentry/browser"
import * as React from "react"
import {useState} from "react"
import {FormControl, FormGroup, FormLabel} from "react-bootstrap"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"
import {addTakeDispatches, TakeDispatches} from "../../../components/App/App"
import Button from "../../../components/Button/Button"
import ProctorApi from "../../../services/ProctorApi"
import {LastPage} from "../../../types/types"
import {authStore} from "../../common/authentication/AuthStore"
import {AuthUser} from "../../common/authentication/models/AuthUser"
import {productStore} from "../../common/products/ProductStore"
import {DIYLoginError} from "./DIYLoginError"
import {DIYWaitingRoom} from "./DIYWaitingRoom"

const useStyles = makeStyles((theme: Theme) => createStyles({
    loginError: {
        color: theme.palette.error.main,
        fontSize: theme.typography.h3.fontSize,
        fontWeight:Number(theme.typography.fontWeightBold), 
        padding: theme.spacing(1)
    }
}))


const mapDispatchToProps = (dispatch: Dispatch): TakeDispatches => {
    return addTakeDispatches({}, dispatch)
}

interface DIYStudentLoginEntryProps extends RouteComponentProps<{}>, TakeDispatches {
    loginId: string,
}

// declare const Supervisor: any
// declare let supervisor: any

const DIYStudentLoginEntry: React.FC<DIYStudentLoginEntryProps> = (props) => {
    const classes = useStyles()
    // State information
    const [displayState, setDisplayState] = useState("login")
    const [badLogin, setBadLogin] = useState(false)

    // Displayed Variables
    const [loginName, setLoginName] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [errorTitle, setErrorTitle] = useState("")
    const [refreshDate, setRefreshDate] = useState<Date>(new Date())

    const handleSubmit = (event: any) => {
        event.preventDefault()
        handleLogin()
    }

    const handleLogin = async () => {
        await ProctorApi.verificientStudentLogin(props.loginId, loginName)
            .then(async result => {
                if (result.proctorEduToken != null) {
                    setBadLogin(false)
                    const parts = result.proctorEduToken.split("|")
                    const takeId = parseInt(parts[0], 10)
                    const ott = parts[1]

                    const response = await authStore.oneTimeLoginTake2(takeId, ott)
                    const loginProduct = response.loginProduct
                    const authUserRaw = response.authUser
                    if (!authStore.auth) {
                        // this is here to remove any possibility of a race condition
                        // we where having a issue with authStore.auth being null in this function
                        authStore.convertTokenToAuthUserAndSet(authUserRaw)
                    }
                    const authUser: AuthUser | undefined = authStore.auth

                    if (authUser && loginProduct) {
                        Sentry.configureScope((scope) => {
                            // TODO update login.tsx to match so we have the same information across the board

                            scope.setTag("id", takeId.toString()) // allow sentry to collect user data

                            scope.setTag("proctored", "true")

                            scope.setTag("loginId", authUser.loginId ? authUser.loginId.toString() : "MISSING")
                            scope.setTag("userId", authUser.userId ? authUser.userId : "MISSING")
                            scope.setTag("userType", authUser.userType ? authUser.userType : "MISSING")

                            scope.setTag("takeId", takeId.toString())

                            const username = authUser.userName ? authUser.userName : "MISSING"
                            if (username) {
                                // Standard opp-code: 12345-4s-sp-1
                                const usernamePieces = username.split("-")
                                const isProbablyOppCode = usernamePieces.length === 3
                                if (isProbablyOppCode) {
                                    scope.setTag("oppCode", usernamePieces[0])
                                }
                            }

                        })
                        productStore.setLoginProduct(loginProduct)

                        const lastPage: LastPage = {
                            url: "/profile-login-name",
                            takeCode: loginProduct.userName
                        }

                        const testState = await productStore.driver!.helper.refreshTest(props, lastPage, loginProduct)
                        // the next step changes the react state to a different page.
                        await productStore.driver!.helper.refreshTestCallback(testState, props)
                    }
                } else if (result.redirectUrl != null) {
                    setBadLogin(false)
                    window.location.href = result.redirectUrl
                }
            })
            .catch(error => {
                Sentry.captureMessage("Error logging proctored test taker in.")
                Sentry.captureException(error)
                if (error.response.status === 401) {
                    setDisplayState("login")
                    setBadLogin(true)
                    return
                }
                if (error.response.status === 428) {
                    setDisplayState("waiting")
                    setBadLogin(false)
                    setRefreshDate(new Date())
                    return
                }

                if (error.response.data && error.response.data.kind) {
                    setErrorTitle(error.response.data.kind)
                } else {
                    setErrorTitle("Internal Error")
                }
                if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                    setErrorMessage(error.response.data.errors[0])
                } else {
                    setErrorMessage("There was an error in our system.")
                }
                if (error.response.status === 500) {
                    Sentry.captureException(error)
                }
                setDisplayState("error")
                setBadLogin(false)
            })
    }

    const handleLoginNameChange = (event: any) => setLoginName(event.target.value)
    const loginButtonClass = "login__login-container__button avant-button--primary"

    return (
        <>
            {(displayState === "error") && (<DIYLoginError title={errorTitle} message={errorMessage}/>)}
            {(displayState === "waiting") && (
                <DIYWaitingRoom onRefresh={handleLogin} refreshDate={refreshDate} refreshInternalInSeconds={30}/>
            )}
            {(displayState === "login") && (
                <>
                <div className="login">
                    <div className="login__image-container" style={{marginTop: -75}}>
                        <img
                            className="login__image-container__image"
                            alt="Avant Logo"
                            src="https://cdn.avantassessment.com/resources/common/img/avant-logo.svg"
                        />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <FormGroup>
                            <FormLabel>Please Enter Your Login Name</FormLabel>
                            <FormControl
                                type="text"
                                value={loginName}
                                placeholder=""
                                data-tst-id="login-name"
                                onChange={handleLoginNameChange}
                            />
                            { badLogin && (
                                <div className={classes.loginError}>Invalid Login Name</div>
                            )}
                        </FormGroup>
                    </form>
                </div>
                <Button testId="submit" className={loginButtonClass} onClick={handleSubmit} type="submit">
                LOG IN
                </Button>
                </>
            )}
        </>
    )
}

export default connect(
    null,
    mapDispatchToProps
)(DIYStudentLoginEntry)

