 // import {AxiosResponse} from "axios"
 import {observable} from "mobx"

 export class PlaceProfileStore {

    // These are for the tests Taken Selections
     @observable
     testsTaken: string[] = [] // This is the human readable name
     @observable
     testsTakenDBName: string[] = [] // This is the name to store in teh DB

     // These are for the Family members checkboxes
     @observable
     familyMembersSelected: string[] = []
     @observable
     otherFamilyMembersSelected: string = ""
     @observable
     radioButtonValue: string[] = ["none"]

     @observable
     gradesStudied: string[] = []

 }

 export const placeProfileStore = new PlaceProfileStore()
