import {observer} from "mobx-react"
import React from "react"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Redirect,Route,withRouter} from "react-router-dom"
import {Dispatch} from "redux"
import {Header} from "../../components/Header/Header"

import {setShowAuthUserExpireModal} from "../../redux/app/actions"
import {authStore} from "../common/authentication/AuthStore"
import {productStore} from "../common/products/ProductStore"
import {RoutePaths} from "./Routes"

interface DispatchToProps {
    showAuthUserExpireModalDispatch: (showModal: boolean) => void
}

interface HeaderProps {
    turnOffHeader?: boolean
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchToProps => {
    return {
        showAuthUserExpireModalDispatch: (showModal: boolean) => {
            dispatch(setShowAuthUserExpireModal(showModal))
        }
    }
}

interface PrivateRouteProps extends DispatchToProps, RouteComponentProps, HeaderProps {}

@observer
class PrivateRoute extends React.Component<PrivateRouteProps> {
    private ignorePaths: string[] = ["/", RoutePaths.LOGIN]

    render() {
        const authUser = authStore.auth
        const driver = productStore.driver
        const product = productStore.loginProduct

        const pathName = this.props.location.pathname
        if (authUser && this.ignorePaths.indexOf(pathName) > -1) {
            this.props.showAuthUserExpireModalDispatch(true)
        }

        if (authUser == null || driver == null || product == null) {
            return <Redirect to={{pathname: RoutePaths.LOGIN}}/>
        }

        return (
            <>
                {this.props.turnOffHeader ?
                    (
                        <Route {...this.props} />
                    ) : (
                        <>
                            <Header/>
                            <Route {...this.props} />
                        </>
                    )
                }
            </>
        )
    }
}

export default withRouter(
    connect<{}, DispatchToProps, any>(
        null,
        mapDispatchToProps
    )(PrivateRoute)
)
