import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core"
import {styled} from "@material-ui/core/styles"
import {observer} from "mobx-react"
import React from "react"
import {AvantColors} from "../../../../../styles/AvantColors"
import {BodyText} from "../../../../../styles/AvantTypography"
import {log} from "../../../../../util/Logging"
import {TimeUtil} from "../../../../../util/TimeUtil"
import {AvantCircularProgress} from "../../../../common/loaders/AvantCircularProgress"
import {takeSearchStore} from "../../../../common/take/TakeSearchStore"
import {ReportFilters} from "../../../common/models/ReportFilters"
import {reportsStore} from "../../../common/stores/ReportStore"
import {ReportSubPaths} from "../../../ReportSubPaths"
import {SchoolInfoRow} from "../../models/SchoolInfoRow"
import {DistrictOrSchoolTableCellLink, DistrictTableHeaderCell} from "./DistrictOrSchoolTableCommon"
import {districtTableStore} from "./DistrictOrSchoolTableStore"

interface DistrictTableContainerProps {
    reportData?: SchoolInfoRow[]
    filters: ReportFilters
    printing?: boolean
}

@observer
export class DistrictTableContainer extends React.Component<DistrictTableContainerProps> {
    render() {
        const {reportData, filters, printing} = this.props
        if (reportData == null) {
            return <AvantCircularProgress/>
        }
        return <DistrictTable reportData={reportData} filters={filters} printing={printing}/>
    }
}

interface DistrictTableProps {
    reportData: SchoolInfoRow[]
    filters: ReportFilters
    printing?: boolean
}

// Depends on instances already being fully loaded
@observer
class DistrictTable extends React.Component<DistrictTableProps> {

    componentDidMount(): void {
        districtTableStore.rows = this.props.reportData
        districtTableStore.rows.forEach(row => row.dataAvailable = takeSearchStore.groupIdsWithData.has(row.id))
        districtTableStore.resort()
    }

    render() {
        const {filters, printing} = this.props
        log.debug("Render district table")
        const groupsWithData = reportsStore.groupsWithData
        return (
            <Table>
                <TableHead>
                    <DistrictTableRow>
                        <DistrictTableHeaderCell title={"School/Location"} property={"name"}/>
                        <DistrictTableHeaderCell title={"Last Testing Date"} property={"lastTestingDate"}/>
                        <DistrictTableHeaderCell title={"Data Available in Date Range"} property={"dataAvailable"}/>
                    </DistrictTableRow>
                </TableHead>
                <TableBody>
                    {districtTableStore.rows.map((row) => {
                        let hasData = false
                        let hasDataYesNo
                        if (groupsWithData != null) {
                            hasData = !!groupsWithData.includes(row.id)
                            if (hasData) {
                                hasDataYesNo = "Yes"
                            } else {
                                hasDataYesNo = "No"
                            }
                        }
                        return (
                            <DistrictTableRow key={row.id}>
                                <DistrictOrSchoolTableCellLink
                                    contents={row.name}
                                    link={ReportSubPaths.school({...filters, groupId: row.id})}
                                    enabled={hasData}
                                    printing={printing}
                                />
                                <DistrictSecondaryCell enabled={hasData}>{TimeUtil.formatMillisToUsDate(row.lastTestingDate)}</DistrictSecondaryCell>
                                <DistrictSecondaryCell enabled={hasData}>{hasDataYesNo}</DistrictSecondaryCell>
                            </DistrictTableRow>
                        )
                    })}
                </TableBody>
            </Table>
        )
    }
}

const DistrictTableRow = styled(TableRow)({
    display: "grid",
    gridTemplateColumns: "4fr 2fr 3fr",
    "& td": {
        height: "45px",
        display: "flex",
        alignItems: "center",
    }
})

interface DistrictSecondaryCellProps {
    enabled: boolean
}

const DistrictSecondaryCell: React.FC<DistrictSecondaryCellProps> = ({children, enabled}) => (
    <TableCell
        style={{
            borderLeft: `1px solid ${AvantColors.REPORT_BACKGROUND_GRAY_DARK}`,
        }}
    >
        <BodyText
            style={{
                color: AvantColors.REPORT_FONT_COLOR_GRAY_3,
                opacity: enabled ? 1 : 0.75
            }}
        >
            {children}
        </BodyText>
    </TableCell>
)
