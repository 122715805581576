import {useEffect} from "react"
import * as React from "react"
import ApiService from "../../../../services/ApiService"
import {WritingPromptProps} from "../components/WritingPrompts";

export const useHandWrittenPrompts = (takeIds: number[]) => {
    const [promptData, setPromptDat] = React.useState<WritingPromptProps[]>()
    const [loading, setLoading] = React.useState(true)

    const fetchPrompts = async () => {
        const response = await ApiService.post(`${ApiService.API_URL}items/handwritten`, {takeIds})
        setPromptDat(response.data)
        setLoading(false)
    }

    useEffect(() => {
        fetchPrompts()
    }, [])

    return {loading, promptData}
}
