/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import * as React from "react"
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Box,
    createStyles,
    Drawer,
    IconButton,
    makeStyles,
    Toolbar
} from "@material-ui/core"
import {sidebarStore} from "./SideBarStore"
import {TakeSearchResult} from "../../../common/take/models/TakeSearchResult"
import {H3Text, H5Text} from "../../../../styles/AvantTypography"
import {ArrowDropDown, Close} from "@material-ui/icons"
import {AvantColors} from "../../../../styles/AvantColors"
import {useEffect, useState} from "react"
import {cloneDeep} from "lodash"
import {ApiTakePanelSearchResult, takeSearchStore} from "../../../common/take/TakeSearchStore"
import LanguageUtils from "../../../../util/LanguageUtils"
import {PanelSkillEnum, PanelSkillOrder} from "../../../../types/types"
import {takePanelStore} from "../../../common/take-panel/TakePanelStore"
import {TakePanelSkillReport} from "../../../common/take-panel/models/TakePanelSkillReport"
import {observer} from "mobx-react-lite"
import ProfileInfo from "./ProfileInfo"
import TestTakerSidebarPanels from "./TestTakerSidebarPanels"

const useDrawerStyles = makeStyles(() =>
    createStyles({
        paper: {
            width: 320,
        }
    })
)

const useExpandedStyles = makeStyles(() => {
    createStyles({
        root: {
            "&$expanded": {
                minHeight: 0,
            },
        },
        content: {
            margin: 0,
            "&$expanded": {
                margin: 0,
            },
        },
        expanded: {},
    })
})

const TestTakerSidebarContainer = observer(() => {
    const [profileInfo, setProfileInfo] = useState<TakeSearchResult>()
    const [sortedPanels, setSortedPanels] = useState<ApiTakePanelSearchResult[]>([])

    useEffect(() => {
        if (takeSearchStore.sideBarInfo && !profileInfo) {
            setProfileInfo(cloneDeep<TakeSearchResult>(takeSearchStore.sideBarInfo))
        }

        if (profileInfo && sortedPanels.length === 0) {
            profileInfo.language = LanguageUtils.removeTilde(profileInfo.language)
            const toSort = [...profileInfo.takePanels]

            takePanelStore.getTakeSkillReport(profileInfo.takeId)
                .then((skills: TakePanelSkillReport[]) => {
                    // We compare the # of takepanels the student has (we only have in-progress or completed) to the total # of skills in the take,
                    // If it's less than we find which ones are missing and create blank takepanels (which will show as not started)
                    if (skills && toSort.length < Object.keys(skills).length) {
                        Object.values(skills).forEach(skill => {
                            if (!toSort.find(panel => panel.skill === skill.skillSymbol)) {
                                toSort.push({skill: skill.skillSymbol, takePanelId: 0, panelId: 0, panelName: "", startTime: "", scaledScore: 0})
                            }
                        })
                    }
                    const panels = toSort.sort((a, b) => PanelSkillOrder.indexOf(a.skill as PanelSkillEnum) - PanelSkillOrder.indexOf(b.skill as PanelSkillEnum))
                    setSortedPanels(panels)
                })
        }
    }, [takeSearchStore.sideBarInfo, profileInfo])

    if (!profileInfo || !sortedPanels) return <></>

    return <TestTakerSidebar profileInfo={profileInfo} sortedPanels={sortedPanels}/>
})

export interface TestTakerSidebarProps {
    profileInfo: TakeSearchResult
    sortedPanels: ApiTakePanelSearchResult[]
}

const TestTakerSidebar = ({profileInfo, sortedPanels}: TestTakerSidebarProps) => {
    const drawerStyles = useDrawerStyles()
    const expandedStyles = useExpandedStyles()
    const {firstName, lastName} = profileInfo
    const fullName = `${firstName} ${lastName}`

    return (
        <Drawer
            open={sidebarStore.open}
            onClose={() => sidebarStore.takeId = undefined}
            classes={drawerStyles}
            anchor={"right"}
        >
            <Toolbar>
                <H3Text>{fullName}</H3Text>
                <Box flexGrow={1} />
                <IconButton onClick={() => sidebarStore.takeId = undefined}>
                    <Close fontSize={"small"} />
                </IconButton>
            </Toolbar>

            <Accordion
                defaultExpanded={!sidebarStore.openPanel && !sidebarStore.openSelfEval && !sidebarStore.openP360}
                style={{margin: 0}}
            >
                <AccordionSummary
                    classes={expandedStyles}
                    style={{margin: "initial"}}
                    expandIcon={<ArrowDropDown style={{fill: AvantColors.REPORT_FONT_COLOR_GRAY_3}} />}
                >
                   <H5Text>Profile</H5Text>
                </AccordionSummary>
                <AccordionDetails style={{padding: 0}}>
                    <ProfileInfo profileInfo={profileInfo} />
                </AccordionDetails>
            </Accordion>

            <TestTakerSidebarPanels profileInfo={profileInfo} sortedPanels={sortedPanels}/>
        </Drawer>
    )
}

export default TestTakerSidebarContainer
