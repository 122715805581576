import {Box, Dialog, DialogContent, DialogTitle, Divider, Grid, Link, Tooltip} from "@material-ui/core"
import ContactSupportIcon from "@material-ui/icons/ContactSupport"
import {observer} from "mobx-react"
import React from "react"
import {connect} from "react-redux"
import {matchPath, RouteComponentProps, withRouter} from "react-router-dom"
import {authStore} from "../../app/common/authentication/AuthStore"
import {UserType} from "../../app/common/authentication/models/UserType"
import {productStore} from "../../app/common/products/ProductStore"
import {ReportSubPaths} from "../../app/reports/ReportSubPaths"
import {Section} from "../../app/section/models/Section"
import HelperService from "../../services/HelperService"
import {AvantColors} from "../../styles/AvantColors"
import {BodyText, H4Text} from "../../styles/AvantTypography"
import {theme} from "../../styles/MuiThemes"
import {LoginProductContentAreaData, ProductId, State, Take} from "../../types/types"
import {
    ADMIN_PRODUCT_ID,
    ADVANCE_PRODUCT_ID,
    CLEVER_SSO_LOGIN_PRODUCT_ID,
    CLASSLINK_SSO_LOGIN_PRODUCT_ID,
    MultifactorAuthTypes,
    PLACE_PRODUCT_ID,
    READY_PRODUCT_ID,
    WORLDSPEAK_PRODUCT_ID,
    STAMP_CEFR_PRODUCT_ID,
    ProctorTypes,
} from "../../util/Constants"
import {avantHistory} from "../App/App"
import Button from "../Button/Button"
import {AuthUser} from "../../app/common/authentication/models/AuthUser"
import AdminTwoFactorAuthenticationButton from "../admin-portal/AdminTwoFactorAuthentication"

// tslint:disable-next-line:no-any
declare const window: any

interface NavStateToProps {
    section: Section | null
    take: Take | null
    noConnection?: boolean
}

const mapStateToProps = (state: State): NavStateToProps => {
    return {
        section: state.item.currentSection,
        take: state.app.take,
    }
}

type TComponentProps = NavStateToProps & RouteComponentProps

interface NavState {
    showLogoutModal: boolean
    take: Take | null
}

@observer
class Navigation extends React.Component<TComponentProps, NavState> {
    constructor(props: TComponentProps) {
        super(props)
        this.state = {
            showLogoutModal: false,
            take: null
        }
    }

    componentDidUpdate() {
        if (this.props.take !== this.state.take) {
            this.setState({take: this.props.take})
        }
    }

    logout = () => {
        this.closeLogoutModal()
        const section = this.props.section ? this.props.section : undefined
        if (section && section.config.storeTestDuration) {
            productStore.driver!!.itemContainerHelper.stopTimeOnItemTimer(section)
        }
        if (productStore.loginProduct!.proctorType === ProctorTypes.P360.valueOf()) {
            this.props.history.push({
                pathname: '/close-session',
                state: { detail: section }
            });
        } else {
            HelperService.logout(section)
        }
    }

    closeLogoutModal = () => {
        this.setState({showLogoutModal: false})
    }

    openLogoutModal = () => {
        this.setState({showLogoutModal: true})
    }

    goToDashboard = () => {
        const section = this.props.section ? this.props.section : undefined
        if (section && section.config.storeTestDuration) {
            productStore.driver!!.itemContainerHelper.stopTimeOnItemTimer(section)
        }
        const productId: number = productStore.loginProduct!!.productId
        switch (productId) {
            case ADVANCE_PRODUCT_ID.value():
                this.props.history.push("/advance-dashboard")
                break
            case PLACE_PRODUCT_ID.value():
            case READY_PRODUCT_ID.value():
            case WORLDSPEAK_PRODUCT_ID.value():
                this.props.history.push("/auto-start-dashboard")
                break
            case ADMIN_PRODUCT_ID.value():
                this.props.history.push("/admin-dashboard")
                break
            default:
                this.props.history.push("/dashboard")
                break
        }
    }

    getUserName = () => {
        const localStorageProduct = localStorage.getItem("loginProduct")
        let dataBlock: LoginProductContentAreaData | null = null
        let userName: string
        if (localStorageProduct != null) {
            dataBlock = JSON.parse(localStorageProduct)
        }
        userName = dataBlock != null && dataBlock.userName != null ? dataBlock.userName : "Name Not Found"
        return authStore.username !== "" && authStore.username == null ? userName : authStore.username
    }

    getSSOUserName = (authUser?: AuthUser) => {
        if (authUser && authUser.ssoDisplayName) {
            return authUser.ssoDisplayName
        }
        else {
            return this.getUserName()
        }
    }

    render() {

        const {history, location} = this.props
        const authUser = authStore.auth
        const driver = productStore.driver
        if (!driver) {
            return null
        }
        const takCodeLabel = driver.config.TAKE_CODE_LABEL || ""

        let isSSOTeacher = false

        let isSSODistrictAdmin = false

        if (authUser && authUser.userType === UserType.T && authUser.ssoId) {
            isSSOTeacher = true
        }

        if (((authUser && authUser.userType === UserType.C) || (authUser && authUser.userType === UserType.D)) && authUser.ssoId) {
            isSSODistrictAdmin = true
        }

        const isAdvanceReportDashboard: boolean = window.location.pathname === "/advance-report"
        const isAdvanceReportEducators: boolean = window.location.pathname === "/advance-report-educators"
        const isAdvanceReport: boolean = isAdvanceReportDashboard || isAdvanceReportEducators
        const isAdminDashBoard: boolean = window.location.pathname.includes("admin-dashboard")

        const displayName = (isSSOTeacher || isSSODistrictAdmin) ? this.getSSOUserName(authUser) : this.getUserName()

        // BlueReport specific links bool
        const blueReportRoute = matchPath(location.pathname, {
            path: ReportSubPaths.REPORTS_BASE,
        })
        const product = productStore.loginProduct
        let showRubric = false
        let showPowerUp = false
        let rubricUrl = undefined

        switch (product && product.productId) {
            case ProductId.STAMP4S:
                rubricUrl = "https://avantassessment.com/stamp-4s-benchmarks-rubric-guide"
                showRubric = true
                showPowerUp = true
                break
            case ProductId.STAMP4SE:
                rubricUrl = "https://avantassessment.com/stamp-4se-benchmarks-rubric-guide"
                showRubric = true
                showPowerUp = true
                break
            case ProductId.APT:
                rubricUrl = "https://avantassessment.com/apt/proficiency-levels-rubric-guide"
                showRubric = true
                showPowerUp = true
                break
            case ProductId.PLACE:
                rubricUrl = "https://avantassessment.com/place-scoring-rubric"
                showRubric = true
                showPowerUp = false
                break
            case ProductId.WORLDSPEAK:
                rubricUrl = "https://avantassessment.com/stamp-4s-benchmarks-rubric-guide"
                showRubric = true
                showPowerUp = false
                break
            default:
                break
        }

        if (displayName.includes("-sh-")) {
            rubricUrl = "https://avantassessment.com/shl-scoring-rubric-guide"
        }

        const showLogout: boolean =
            driver.productId.value() === ADVANCE_PRODUCT_ID.value() ||
            driver.productId.value() === ADMIN_PRODUCT_ID.value() ||
            driver.productId.value() === CLEVER_SSO_LOGIN_PRODUCT_ID.value() ||
            driver.productId.value() === CLASSLINK_SSO_LOGIN_PRODUCT_ID.value() ||
            avantHistory.location.pathname.includes(ReportSubPaths.REPORTS_BASE)

        let advanceReportDashboardClass = "avant-button--link avant-button--link--dashboard"
        if (isAdvanceReportDashboard) {
            advanceReportDashboardClass = "avant-button--link avant-button--link--dashboard avant-button--bold"
        }
        let advanceReportEducatorsClass = "avant-button--link avant-button--link--dashboard"
        if (isAdvanceReportEducators) {
            advanceReportEducatorsClass = "avant-button--link avant-button--link--dashboard avant-button--bold"
        }

        const logoutButtonText = showLogout ? driver.strings.logout : driver.strings.stopTest
        const multifactorAuthType = (product && product.multifactorAuthType) ? product.multifactorAuthType : ""

        return (
            <Box display={"flex"} alignItems={"center"} displayPrint={"none"}>
                {authUser && authUser.token && (
                    <div>
                        {authUser.userType !== UserType.D &&
                        !history.location.pathname.includes("dashboard") &&
                        !history.location.pathname.includes("profile") &&
                        !history.location.pathname.includes("profile-view") &&
                        !history.location.pathname.includes("eula") &&
                        !driver.config.TEST_ENGINE_CONFIG.hideDashboardButton && (
                            <Button
                                className="avant-button--link avant-button--link--dashboard"
                                testId="dashboard-button"
                                onClick={this.goToDashboard}
                                type="button"
                            >
                                Dashboard
                            </Button>
                        )}

                        {isAdvanceReport && (
                            <>
                                <Button
                                    className={advanceReportDashboardClass}
                                    onClick={() => history.push("/advance-report")}
                                >
                                    Dashboard
                                </Button>
                                <Button
                                    className={advanceReportEducatorsClass}
                                    onClick={() => history.push("/advance-report-educators")}
                                >
                                    Educators
                                </Button>
                            </>
                        )}
                        {driver.productId.value() === ADVANCE_PRODUCT_ID.value() && (
                            <Box component="div" display="inline" mr={3}>
                                <Tooltip title={"Email support"}>
                                    <Link
                                        href={"mailto:support+advance@avantassessment.com?subject=Avant%20ADVANCE%20"+authUser.userType+"%20"+authUser.userName}
                                        style={{color: AvantColors.avantBlue}}
                                    >
                                        <ContactSupportIcon fontSize={"large"} />
                                    </Link>
                                </Tooltip>
                            </Box>
                        )}
                        {blueReportRoute && (
                            <>
                                <BodyText style={{textAlign: "right", marginTop: theme.spacing(0.5), marginBottom: theme.spacing(0.5), marginRight: "1rem"}}>Current User: {displayName} </BodyText>
                                {showRubric && (
                                    <>
                                        {isSSOTeacher && (
                                                <Button
                                                    className={advanceReportEducatorsClass}
                                                    onClick={() => history.push("/sso/teacher")}
                                                >
                                                    Admin
                                                </Button>

                                        )}

                                        <Link
                                            component={"a"}
                                            href={rubricUrl}
                                            target={"_blank"}
                                            style={{marginRight: "6rem", color: "#2B5DD8"}}
                                        >
                                            Rubrics
                                        </Link>
                                        { showPowerUp &&
                                            <Link
                                                component={"a"}
                                                href={"https://avantassessment.com/stamp/power-up-guide"}
                                                target={"_blank"}
                                                style={{marginRight: "6rem", color: "#2B5DD8"}}
                                            >
                                                Power Up
                                            </Link>
                                        }

                                    </>
                                )}

                                {
                                    driver.productId.value() !== STAMP_CEFR_PRODUCT_ID.value() &&
                                        <Link
                                            component={"a"}
                                            href={"https://avantassessment.com/guides"}
                                            target={"_blank"}
                                            style={{marginRight: "6rem", color: "#2B5DD8"}}
                                        >
                                            User Guides
                                        </Link>
                                }
                            </>
                        )}
                        {isAdminDashBoard && multifactorAuthType != MultifactorAuthTypes.TFA && <AdminTwoFactorAuthenticationButton/>}
                        {(location.pathname !== '/close-session') ? (
                                <Button
                                    testId="logout-button"
                                    className="avant-button--logout avant-button--secondary"
                                    onClick={this.openLogoutModal}
                                    type="button"
                                    style={{ float: "right" }}
                                >
                                    {logoutButtonText}
                                </Button>
                        )
                            : ""
                        }
                    </div>
                )}

                <Dialog
                    open={this.state.showLogoutModal}
                    onClose={this.closeLogoutModal}
                    PaperProps={{style: {padding: theme.spacing(2)}}}
                >
                    <DialogTitle disableTypography={true} style={{textAlign: "center", padding: theme.spacing(2)}}>
                        <H4Text style={{fontWeight: 900, color: AvantColors.REPORT_FONT_COLOR_BLACK}}>{`${driver.strings.logout}?`}</H4Text>
                    </DialogTitle>
                    <Divider style={{width: "100%"}}/>
                    <DialogContent style={{textAlign: "center", overflowY: "initial"}}>
                        {!showLogout && this.state.take && this.state.take.takeCode && (
                            <>
                                <p>{`${driver.strings.continueTheTestLater} ${takCodeLabel.toLocaleLowerCase()}:`}</p>
                                <p>{this.state.take.takeCode}</p>
                            </>
                        )}
                        <Grid container={true} spacing={3}>
                            <Grid item={true} xs={6}>
                                <Button
                                    className="avant-button--primary avant-button--block avant-button--default"
                                    onClick={this.closeLogoutModal}
                                    type="button"
                                >
                                    {driver.strings.cancel}
                                </Button>
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Button
                                    className="avant-button--primary avant-button--block avant-button--default"
                                    testId="modal-logout-button"
                                    onClick={this.logout}
                                    type="button"
                                >
                                    {driver.strings.logout}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </Box>
        )
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(Navigation)
)
