/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import * as React from "react"
import {useEffect, useState} from "react"

import {Button, Grid, Paper, Theme, Tooltip} from "@material-ui/core"
import createStyles from "@material-ui/core/styles/createStyles"
import makeStyles from "@material-ui/core/styles/makeStyles"
import PersonAdd from "@material-ui/icons/PersonAdd"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import StopIcon from "@material-ui/icons/Stop"
import CleverRosterApi from "../../../../services/CleverRosterApi"
import {CleverAdhocRosterDialog} from "./rostering/CleverAdhocRosterDialog"
import {CleverAdhocTeacherRosterDialog} from "./rostering/CleverAdhocTeacherRosterDialog"
import {CustomAdminView} from "./dialogs/CustomAdminsView"

const CleverCustomSectionsStyles = makeStyles((theme: Theme) =>
    createStyles({
        root_ProctorTrackRegistrationTool: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        root: {
            flexGrow: 1
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "center"
        }
    })
)

interface CleverCustomSectionsProps {
    handleStartGroup: () => void
    handleStopGroup: () => void
    handleCloseRoster: () => void
}

export const CleverCustomSections: React.FC<CleverCustomSectionsProps> = (props) => {
    const classes = CleverCustomSectionsStyles()
    const [rosterOpen, setRosterOpen] = useState(false)
    const [customAdminOpen, setCustomAdminOpen] = useState(false)
    const [rosterTeacherOpen, setRosterTeacherOpen] = useState(false)
    const [canView, setCanView] = useState(false)

    useEffect(() => {
            CleverRosterApi.customSectionsAllowed()
                .then((response) => {
                    if (response === true) {setCanView(true)} else {setCanView(false)}
                    })
    }, []
    )

    const handleRosterStudents = () => {
        setRosterOpen(true)
    }
    const handleRosterTeachers = () => {
        setRosterTeacherOpen(true)
    }
    const handleRosterClose = () => {
        setRosterOpen(false)
        setRosterTeacherOpen(false)
        props.handleCloseRoster()

    }

    const handleCustomAdminViewClose = () => {
        setCustomAdminOpen(false)
    }
    const handleCustomAdminViewOpen = () => {
        setCustomAdminOpen(true)
    }

    return (
        <>
        {canView && (
            <>
                <Paper elevation={3} className={classes.paper}>
                    <Grid container={true} spacing={2}>
                        <Grid item={true} xs={1} style={{textAlign: "center"}}/>
                        <Grid item={true} xs={4} style={{textAlign: "center"}}>
                            <Tooltip title={"Disallow all students to enter the test."}>
                            <Button

                                variant="contained"
                                color="primary"
                                startIcon={<StopIcon />}
                                fullWidth={true}
                                onClick={props.handleStopGroup}
                            >
                                Lock all tests
                            </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item={true} xs={2} style={{textAlign: "center"}}/>

                        <Grid item={true} xs={4} style={{textAlign: "center"}}>
                            <Tooltip title={"Allow all students to enter the test."}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<PlayArrowIcon />}
                                fullWidth={true}
                                onClick={props.handleStartGroup}
                            >
                                Unlock all tests
                            </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item={true} xs={1} style={{textAlign: "center"}}/>

                    </Grid>
                </Paper>
                <Paper elevation={3} className={classes.paper}>
                    <Grid container={true} spacing={2}>
                        <Grid item={true} xs={1} style={{textAlign: "center"}}/>
                        <Grid item={true} xs={5} style={{textAlign: "center"}}>
                            <Tooltip title={"Roster Student to Custom Sections"}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                                startIcon={<PersonAdd />}
                                onClick={handleRosterStudents}
                            >
                                Roster Custom Sections
                            </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item={true} xs={5} style={{textAlign: "center"}}>
                            <Tooltip title={"Assign Staff to Custom Sections"}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                                startIcon={<PersonAdd />}
                                onClick={handleRosterTeachers}
                            >
                                Assign Teachers & Staff
                            </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item={true} xs={1} style={{textAlign: "center"}}/>

                        <Grid item={true} xs={1} style={{textAlign: "center"}}/>
                        <Grid item={true} xs={10} style={{textAlign: "center"}}>
                            <Tooltip title={"View Custom Admins"}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                    onClick={handleCustomAdminViewOpen}
                                >
                                    View Custom Admins
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item={true} xs={1} style={{textAlign: "center"}}/>
                    </Grid>
                </Paper>
                <CleverAdhocRosterDialog
                    open={rosterOpen}
                    handleRosterClose={handleRosterClose}
                />
                <CleverAdhocTeacherRosterDialog
                    open={rosterTeacherOpen}
                    handleRosterClose={handleRosterClose}
                />
                <CustomAdminView
                    open={customAdminOpen}
                    onClose={handleCustomAdminViewClose}
                />
            </>
        )}
        </>
    )
}
