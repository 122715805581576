import * as React from "react"
import {PrintOptions, PrintViewContainer} from "../../common/components/PrintViewContainer"
import {findFilters, ReportRouteProps} from "../../common/components/ReportsPage"
import {BlueReportType} from "../../common/models/BlueReportType"
import {PLACE_PRODUCT_ID} from "../../../../util/Constants"

export const ClassPrintAll: React.FC<ReportRouteProps> = (props) => {
    const filters = findFilters(props)
    return <PrintViewContainer filters={filters} printOptions={PrintOptions.ALL} report={BlueReportType.CLASS}/>
}

export const ClassPrintSummary: React.FC<ReportRouteProps> = (props) => {
    const filters = findFilters(props)
    return <PrintViewContainer filters={filters} printOptions={PrintOptions.SUMMARY} report={BlueReportType.CLASS}/>
}

export const ClassPrintList: React.FC<ReportRouteProps> = (props) => {
    const filters = findFilters(props)
    const reportType = filters.productId === PLACE_PRODUCT_ID.value() ? BlueReportType.PLACE : BlueReportType.CLASS
    return <PrintViewContainer filters={filters} printOptions={PrintOptions.LIST} report={reportType}/>
}
