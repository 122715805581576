import {isNullOrUndefined} from "util"

export function shuffle(a: any[]) {
    let i = a.length
    while (i) {
        const j = Math.floor(Math.random() * i)
        const t = a[--i]
        a[i] = a[j]
        a[j] = t
    }
}

export function isEmpty(a: any[]) {
    return a.length < 1
}

export function isNotEmpty(a: any[]) {
    return !isEmpty(a)
}

export function range(start: number, end?: number) {
    if (!isNullOrUndefined(end) && (end < start || start < 0 || end < 0)) {
        return []
    } else if (!isNullOrUndefined(end)) {
        return Array.from({length: end - start}, (value, key) => key + start)
    } else {
        return Array.from({length: start}, (value, key) => key)
    }
}
