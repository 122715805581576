import {observable} from "mobx"
import { TVoicePlaybackState } from '../../../components/voice-recording/Html5VoiceRecorder/Html5VoiceRecorder'

export class VoiceRecorderStore {

    // This is the Twilio player handle
    @observable
    audioHandle?: HTMLAudioElement

    // This is the Opus player handle
    @observable
    oggPlayer?: any

    @observable
    playbackState: TVoicePlaybackState = "not"

    stopPlayBack = () => {
        if (this.audioHandle) {
            this.audioHandle.pause()
            this.audioHandle.remove()
            this.audioHandle = undefined
        }
        if (this.oggPlayer) {
            this.oggPlayer.pause()
            try {
                this.oggPlayer.fastSeek(0)
            } catch (err) {
                const typedErr = err as Error
                const msg = typedErr.toString()
                if (msg.includes("non-seekable stream")) {
                    // In Firefox the audio stream is un-seekable for some reason. This does not appear to
                    // affect playback or recording so just throwing a warning here.
                    console.warn(`This is an error that occurs in Firefox and can most likely be ignored: ${msg}`)
                }
            }
        }
    }
}

export const voiceRecorderStore = new VoiceRecorderStore()
