import axios, {AxiosRequestConfig} from "axios"


//This baseUrl is the same for all environments --- it really points to 127.0.0.1
// you can not test this through our VPN because it filters out any localhost DNS responses
const baseUrl = "https://app.verificient.com:54545"
const closeUrl = baseUrl + "/proxy_server/app/close_proctoring"
const checkUrl = baseUrl + "/proxy_server/app/proctoring_started/"

// NOTE: make sure we don't send any header like X-Avant-Auth-Token to a service that does not need
// it.  It can cause a CORS error as the receiving server is most like NOT setup to recieve that header.

export default class PTHandler {
    /**
     * Returns PT Token if PT is running
     */
    static isAppRunning(): Promise<boolean> {
        return new Promise((resolve) => {
            // const config: AxiosRequestConfig = {headers: null}  // this is to remove the default Auth header
            // TODO: Investigate a way to get rid of the weird header
            const config: AxiosRequestConfig = {headers: undefined} // this is to remove the default Auth header
            axios.get(checkUrl, config)
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        resolve(true)
                    }
                    resolve(false)
                })
                .catch(_ => {
                    resolve(false)
                })
        })

    }

    /**
     * Returns true if proctoring has started
     */
    static isProctored(): Promise<boolean> {
        return new Promise((resolve) => {
            // TODO: Investigate a way to get rid of the weird header
            const config: AxiosRequestConfig = {headers: undefined} // this is to remove the default Auth header
            axios.get(checkUrl, config)
                .then(response => {
                    // @ts-ignore -- because we don't have a format structure for the response
                    if (response.status >= 200 && response.status < 300 && response.proctoring) {
                        resolve(true)
                    }
                    resolve(false)
                })
                .catch(_ => {
                    resolve(false)
                })
        })
    }

    /**
     * Check if PT is running or not with multiple attempts
     */
    static isProctoredMultipleAttempts(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const maxFailedAttemptCount = 5
            let attemptCount = 0
            const attemptConnection = () => {
                PTHandler.isProctored()
                    .then(started => {
                        attemptCount++
                        if (started) {
                            resolve(true)
                        } else if (attemptCount < maxFailedAttemptCount) {
                            setTimeout(attemptConnection, 30 * 1000)  // try again
                        } else {
                            reject(Error("Failed to check if proctoring has started.")) // failed
                        }
                    })
            }

            attemptConnection()
        })
    }

    /**
     * Request PT to close. PT will upload data before closing itself.
     */
    static endExam(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            // TODO: Investigate a way to get rid of the weird header
            const config: AxiosRequestConfig = {headers: undefined} // this is to remove the default Auth header
            axios.get(closeUrl, config)
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        resolve(true)
                    } else {
                        reject(Error("Failed to close the ProctorTrack App."))
                    }
                })
                .catch(_ => {
                    reject(Error("Failed to close the ProctorTrack App."))
                })
        })

    }
}

