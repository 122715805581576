import axios from "axios"
import {shuffle} from "shuffle-seed"
import {authStore} from "../app/common/authentication/AuthStore"
import {ItemContent} from "../app/common/item/models/ItemContent"
import {RoutePaths} from "../app/routes/Routes"
import {Section} from "../app/section/models/Section"
import {SectionInfo} from "../app/section/models/SectionInfo"
import {avantHistory} from "../components/App/App"
import {CircleStatus} from "../components/CircleStatus"
import {userLogout} from "../redux/app/actions"
import {logoutInTest} from "../redux/item/actions"
import store from "../redux/store"
import {
    Answer,
    AnswerUpdate,
    ApiItem,
    ApiItemContent,
    ApiItemResponse,
    ApiUserForm,
    CurrentSectionData,
    IItem,
    InstructionPsTextContent,
    ItemContentSectionEnum,
    ItemContentTypeEnum,
    ItemFormatEnum,
    LastPage,
    PassageSelectTarget,
    Product,
    PsText,
    PutTwilioSQSRequest,
    TargetZone,
    TLocalAnswer,
    TSeconds,
    UserForm,
    VideoContent
} from "../types/types"
import {SAMPLE_TEST_KEY, WEBRTC_RESPONSE} from "../util/Constants"
import {EnvUtils} from "../util/EnvUtils"
import {log} from "../util/Logging"
import {PanelId, TakeId} from "../validation/ValidPrimaryKey"
import ApiService from "./ApiService"

interface IWindow {
    sidebar: any
    onhashchange: any
    location: any
}

declare const window: IWindow

export default class HelperService {
    static getUrlParameter(name: string): string | null {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
        const regex = new RegExp("[\\?&]" + name + "=([^&#]*)")
        const results = regex.exec(window.location.search)
        return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, " "))
    }

    static preventTextSelection() {
        if (!EnvUtils.isDev()) {
            // Leave right clicks on for dev environments
            document.body.oncontextmenu = () => false
            document.onselect = () => false
        }
    }

    static enableTextSelection() {
        document.body.oncontextmenu = () => true
        document.onselect = () => true
    }

    static logout(section?: Section) {
        const currentUrl = avantHistory.location.pathname
        const adminOrAdvance = currentUrl.includes("advance") || currentUrl.includes("admin") || currentUrl.includes("reports")
        if (currentUrl.includes("item")) {
            if (!section) {
                this.simpleLogout(adminOrAdvance)
                return
            }
            if (section.config.showTimer === true) {
                ApiService.updateTimer(new TakeId(section.takeId), new PanelId(section.panelId))
            }
            // TODO: Find way to NOT ts-ignore this
            // @ts-ignore
            store.dispatch(logoutInTest(true)) // <-- this calls simpleLogout after it submits answer.
        } else if (currentUrl.includes("finish-section")) {
            if (!section) {
                throw new Error("[AvantHelp.logout] Logging out while in FinishSectionContainer but not section found.")
            }
            if (section.config.showTimer === true) {
                ApiService.updateTimer(new TakeId(section.takeId), new PanelId(section.panelId))
            }
            this.simpleLogout(adminOrAdvance)
        } else {
            this.simpleLogout(adminOrAdvance)
        }
    }

    static simpleLogout(adminOrAdvance: boolean) {
        // Turn off the onhashchange event to prevent infinite redirect loops.
        log.debug("Execute simple logout")
        window.onhashchange = () => {
            /* placeholder */
        }
        axios.defaults.headers.common["X-Avant-Auth-Token"] = ""
        const isSampleTest = localStorage.getItem(SAMPLE_TEST_KEY)
        if (isSampleTest) {
            localStorage.removeItem(SAMPLE_TEST_KEY)
            avantHistory.replace("https://www.update.avantassessment.com/sample-tests/")
            window.location.href="https://www.update.avantassessment.com/sample-tests/"
        } else {
            log.info("In logout admin or advance is " + adminOrAdvance)
            avantHistory.push(adminOrAdvance ? RoutePaths.LOGIN : RoutePaths.TEST)
        }
        this.clearLocalStorage()
        // TODO: Find way to NOT ts-ignore this
        // @ts-ignore
        store.dispatch(userLogout())
        authStore.logout()
    }

    static getProgressInSection(section: Section, itemId: number | null, isDashboard: boolean = false): number {
        if (itemId == null) {
            return 0
        }

        const currentSectionItemCount = section.items.length
        const currentItem = section.items.findIndex((obj: IItem) => obj.id === itemId)
        // The item coming from the dashboard is 1 behind of the current item in the test
        const completedItems = isDashboard ? currentItem + 1 : currentItem
        return (completedItems / currentSectionItemCount) * 100
    }

    // TODO fix this
    static getFullMediaUrl(contentAreaId: string, contentType: string, content: string, baseMediaUrl: string): string {
        let out = content
        // @TODO: if out is null/empty then that's an error. We need to report
        // it to the error logger somehow in the future.
        if (out !== null && !out.startsWith("http")) {
            out = `${baseMediaUrl}/${contentAreaId}/${contentType}/${content}`
        }
        return out
    }

    static formatTimeRemaining(timeRemaining: TSeconds): string {
        return new Date(timeRemaining * 1000).toISOString().substr(11, 8)
    }

    static getFromLocalStorage<T>(key: string): T | null {
        // Check for last page
        let out: any | null = null
        const raw = localStorage.getItem(key)
        if (raw) {
            try {
                out = JSON.parse(raw)
            } catch (e) {
                log.warn(`Could not parse ${key} JSON`)
            }
        }
        return out
    }

    static canonicalizePsAnswer(passageAnswer: string): string {
        return passageAnswer.toLowerCase().trim()
    }


    static getLastPageFromLs(): LastPage | null {
        return this.getFromLocalStorage("lastPage")
    }

    static getAnswerStatusClassName(section: Section, answer?: Answer): CircleStatus {
        let className = CircleStatus.NO
        if (answer !== undefined) {
            const localAnswer = answer.answer
            if (localAnswer !== null) {
                if (answer.requiredAnswerCount === undefined) {
                    if (section.config.showItemMenuAnswers) {
                        const correctAnswer: ItemContent | undefined = section.correctAnswers.get(
                            `${answer.itemId}${answer.binName}`
                        )
                        className = CircleStatus.INCORRECT
                        if (correctAnswer !== undefined) {
                            // throw new Error("Could not find correct answer.")
                            const correctAnswerId: number = correctAnswer.id
                            if (localAnswer === correctAnswerId) {
                                className = CircleStatus.CORRECT
                            }
                        }
                    } else {
                        if (Array.isArray(localAnswer)) {
                            if (localAnswer.length > 0) {
                                className = CircleStatus.YES
                            }
                        } else {
                            className = CircleStatus.YES
                        }
                    }
                } else {
                    // There is a required and count, so we assume the localAnswer is an array
                    if (!Array.isArray(localAnswer)) {
                        throw Error(`Expecting localAnswer to ab an array but it was not, localAnswer: ${localAnswer}`)
                    }
                    const answers = localAnswer as string[]
                    const numAnswers = answers.length
                    if (numAnswers !== 0) {
                        className = numAnswers < answer.requiredAnswerCount ? CircleStatus.PARTIAL : CircleStatus.YES
                    }
                }
            }
        }
        return className
    }

    static getRequiredAnswerCount(apiItem: ApiItem): number | undefined {
        let requiredAnswerCount: number | undefined
        apiItem.itemContents.forEach((itemContent: ApiItemContent) => {
            if (itemContent.type === ItemContentTypeEnum.ANSWER_COUNT) {
                requiredAnswerCount = Number(itemContent.content)
            }
        })
        return requiredAnswerCount
    }

    static responsesToAnswers(
        apiItemResponses: ApiItemResponse[],
        items: IItem[],
        takeId: number,
        panelId: number
    ): AnswerUpdate {
        const responses = new Map<string, Answer>()
        const correctAnswers = new Map<string, ItemContent>()
        let lastItemIndex = -1
        apiItemResponses.forEach((apiItemResponse: ApiItemResponse) => {
            const itemIndex = items.findIndex((item) => {
                return item.id === apiItemResponse.itemId
            })
            if (itemIndex > -1) {
                if (apiItemResponse.completed) {
                    lastItemIndex = Math.max(lastItemIndex, itemIndex)
                }
                let localAnswer: TLocalAnswer = null
                let correctAnswerId: TLocalAnswer
                const item = items[itemIndex]

                switch (item.format) {
                    case ItemFormatEnum.MULTIPLE_CHOICE:
                        localAnswer = apiItemResponse.respondedContentIds
                            ? apiItemResponse.respondedContentIds[0]
                            : null
                        correctAnswerId = apiItemResponse.correctAnswerContentIds
                            ? apiItemResponse.correctAnswerContentIds[0]
                            : null
                        break
                    case ItemFormatEnum.CHECKBOX:
                        if (apiItemResponse.respondedContentIds.length > 0) {
                            localAnswer = apiItemResponse.respondedContentIds.map((id: number) => {
                                return id + ""
                            })
                        }
                        correctAnswerId = apiItemResponse.correctAnswerContentIds
                            ? apiItemResponse.correctAnswerContentIds
                            : null
                        break
                    case ItemFormatEnum.PASSAGE_SELECT:
                        localAnswer = apiItemResponse.respondedValue
                        break
                    case ItemFormatEnum.WRITING:
                    case ItemFormatEnum.SPEAKING_ORAL:
                        localAnswer = apiItemResponse.respondedValue
                        break
                    default:
                        // TODO This is for other questions types
                        localAnswer = ""
                }

                const correctAnswer: ItemContent | undefined = item.choices.find((itemContent: ItemContent) => {
                    return itemContent.id === correctAnswerId
                })
                const key = `${item.id}${apiItemResponse.binName}`
                if (correctAnswer !== undefined) {
                    correctAnswers.set(key, correctAnswer)
                }
                const answer: Answer = {
                    format: item.format,
                    itemId: item.id,
                    answer: localAnswer,
                    index: itemIndex,
                    takeId: new TakeId(takeId),
                    panelId: new PanelId(panelId),
                    completed: apiItemResponse.completed,
                    requiredAnswerCount: item.requiredAnswerCount,
                    binName: apiItemResponse.binName
                }
                responses.set(key, answer)
            }
        })
        return {
            lastItemIndex,
            responses,
            correctAnswers
        }
    }

    static processSection(
        apiSection: CurrentSectionData,
        takeId: number,
        panelId: number,
        randomItemOrder: boolean,
        baseMediaUrl: string
    ): Section {
        let items: IItem[] = apiSection.items.map((item: ApiItem) => {
            return HelperService.parseItem(item, baseMediaUrl)
        })
        if (randomItemOrder) {
            items = shuffle(items, takeId)
        } else {
            items.sort((a, b) => {
                if (a.order < b.order) {
                    return -1
                }
                if (a.order > b.order) {
                    return 1
                }
                // a must be equal to b
                return 0
            })
        }

        const res: AnswerUpdate = this.responsesToAnswers(apiSection.responses, items, takeId, panelId)

        const info: SectionInfo = {
            numSections: apiSection.numSections,
            currentSection: apiSection.currentSection,
            voiceRecorder: apiSection.voiceRecorder
        }

        if (apiSection.startTime !== null) {
            try {
                info.startTime = new Date(apiSection.startTime)
            } catch (e) {
                throw new SyntaxError(`Could not parse start time string: ${apiSection.startTime}`)
            }
        }

        return {
            info,
            items,
            timeRemaining: apiSection.timeRemaining,
            timeUsed: null,
            lastItemIndex: res.lastItemIndex,
            responses: res.responses,
            correctAnswers: res.correctAnswers,
            takeId,
            panelId,
            takePanelId: apiSection.takePanelId,
            config: {}
        }
    }

    static parseItem(apiItem: ApiItem, baseMediaUrl: string): IItem {
        const itemContent1: ItemContent | InstructionPsTextContent | undefined = this.getItemContentAtPosition(
            apiItem,
            1,
            baseMediaUrl
        )
        const itemContent2: ItemContent | InstructionPsTextContent | undefined = this.getItemContentAtPosition(
            apiItem,
            2,
            baseMediaUrl
        )
        const itemContent3: ItemContent | InstructionPsTextContent | undefined = this.getItemContentAtPosition(
            apiItem,
            3,
            baseMediaUrl
        )
        const itemContent4: ItemContent | InstructionPsTextContent | undefined = this.getItemContentAtPosition(
            apiItem,
            4,
            baseMediaUrl
        )
        const itemContent5: ItemContent | InstructionPsTextContent | undefined = this.getItemContentAtPosition(
            apiItem,
            5,
            baseMediaUrl
        )
        const itemContent6: ItemContent | InstructionPsTextContent | undefined = this.getItemContentAtPosition(
            apiItem,
            6,
            baseMediaUrl
        )
        const choices: ItemContent[] = this.makeChoices(apiItem, baseMediaUrl)
        const psTargets: PassageSelectTarget[] = this.makePsTargets(apiItem)
        const training: ItemContent = this.makeTraining(apiItem)
        const targetZones: TargetZone[] = this.makeTargetZones(apiItem)
        const flvRecordingUrl: string = apiItem.flvRecordingUrl ? apiItem.flvRecordingUrl : ""
        const speakingFileName: string = apiItem.speakingFileName ? apiItem.speakingFileName : ""
        const requiredAnswerCount: number | undefined = this.getRequiredAnswerCount(apiItem)

        let order = 0
        if (apiItem.order !== null) {
            order = apiItem.order
        }
        const correctAnswer = apiItem.correctAnswers

        return {
            id: apiItem.id,
            name: apiItem.name,
            description: apiItem.description,
            format: apiItem.format,
            languageId: apiItem.contentAreaId,
            choices,
            psTargets,
            training,
            order,
            targetZones,
            flvRecordingUrl,
            speakingFileName,
            requiredAnswerCount,
            correctAnswers: correctAnswer,
            binName: apiItem.binName,
            level: apiItem.level,
            productId: apiItem.productId,
            itemContent1,
            itemContent2,
            itemContent3,
            itemContent4,
            itemContent5,
            itemContent6
        }
    }

    static getItemContentAtPosition = (
        apiItem: ApiItem,
        position: number,
        baseMediaUrl: string
    ): ItemContent | InstructionPsTextContent | undefined => {
        let apiItemContent = apiItem.itemContents.find((content: ApiItemContent) => {
            return content.position === position
        })

        if (apiItemContent === undefined && apiItem.parent !== null) {
            apiItemContent = apiItem.parent.itemContents.find((content: ApiItemContent) => {
                return content.position === position
            })
        }

        let itemContent: ItemContent | undefined
        if (apiItemContent !== undefined) {
            itemContent = {
                ...apiItemContent,
                binName: apiItem.binName
            }

            switch (itemContent.type) {
                case ItemContentTypeEnum.PLAIN_IMAGE:
                    itemContent.content = HelperService.getFullMediaUrl(
                        apiItem.contentAreaId,
                        itemContent.type,
                        itemContent.content as string,
                        baseMediaUrl
                    )
                    break
                case ItemContentTypeEnum.MP3_SOUND:
                    itemContent.content = HelperService.getFullMediaUrl(
                        apiItem.contentAreaId,
                        itemContent.type,
                        itemContent.content as string,
                        baseMediaUrl
                    )
                    break
                case ItemContentTypeEnum.RTL_PS_TEXT:
                    itemContent.content = HelperService.psTextToList(itemContent.content as string)
                    itemContent = itemContent as InstructionPsTextContent
                    break
                case ItemContentTypeEnum.PS_TEXT:
                    itemContent.content = HelperService.psTextToList(itemContent.content as string)
                    itemContent = itemContent as InstructionPsTextContent
                    break
                case ItemContentTypeEnum.VIDEO:
                    const content: VideoContent = JSON.parse(itemContent.content as string)
                    const video: string = HelperService.getFullMediaUrl(
                        "common",
                        itemContent.type,
                        content.video,
                        baseMediaUrl
                    )
                    const poster: string = HelperService.getFullMediaUrl(
                        "common",
                        itemContent.type,
                        content.poster,
                        baseMediaUrl
                    )
                    const newContent: VideoContent = {
                        video,
                        poster
                    }
                    itemContent.content = JSON.stringify(newContent)
                    break
                default:
                    break
            }
        }

        return itemContent
    }

    static makeTraining(rawItemJson: any): ItemContent {
        return rawItemJson.itemContents.find((obj: any) => obj.section === ItemContentSectionEnum.TRAINING)
    }

    static makePsTargets(rawItemJson: ApiItem): PassageSelectTarget[] {
        const out: PassageSelectTarget[] = []
        // Do not sort by position here because it appears to be blank.
        rawItemJson.itemContents.forEach((obj: any) => {
            if (obj.section === ItemContentSectionEnum.PS_TARGET) {
                obj.content = this.psTextToList(obj.content)
                out.push(obj)
            }
        })
        return out
    }

    static processItemContentToChoices(
        apiItemContent: ApiItemContent,
        contentAreaId: string,
        baseMediaUrl: string,
        binName: string
    ): ItemContent | null {
        let itemContent: ItemContent | null = {...apiItemContent, binName}
        if (itemContent && (itemContent.section === ItemContentSectionEnum.ANSWER_CHOICE || itemContent.section === ItemContentSectionEnum.DROPZONE)) {
            if (
                itemContent.type === ItemContentTypeEnum.PLAIN_IMAGE ||
                itemContent.type === ItemContentTypeEnum.RTL_PS_TARGET ||
                itemContent.type === ItemContentTypeEnum.SOUND_TEXT ||
                itemContent.type === ItemContentTypeEnum.MP3_SOUND ||
                itemContent.type === ItemContentTypeEnum.RTL_TEXT_AND_SOUND
            ) {
                itemContent.content = this.getFullMediaUrl(
                    contentAreaId,
                    itemContent.type,
                    itemContent.content as string,
                    baseMediaUrl
                )
            }
        } else {
            itemContent = null
        }
        return itemContent
    }

    static makeChoices(rawItemJson: ApiItem, baseMediaUrl: string): ItemContent[] {
        const choices: ItemContent[] = []
        // Do not sort by position here because it appears to be blank.
        rawItemJson.itemContents.forEach((obj: ApiItemContent) => {
            const result = this.processItemContentToChoices(
                obj,
                rawItemJson.contentAreaId,
                baseMediaUrl,
                rawItemJson.binName
            )
            if (result) {
                choices.push(result)
            }
        })
        return choices
    }

    static makeTargetZones(rawItemJson: ApiItem): TargetZone[] {
        let targetZones: TargetZone[] = []
        rawItemJson.itemContents.forEach((itemContent: ApiItemContent) => {
            if (itemContent.section === ItemContentSectionEnum.DROPZONE) {
                targetZones = itemContent.targetZones
            }
        })
        return targetZones
    }

    static psTextToList(text: string): PsText[] {
        const out: PsText[] = []
        let newText: string = text.replace(new RegExp("<p>", "g"), "<br/><br/>")

        // Remove wrapping quotes
        let regExp = new RegExp("\\\"\\s?\\[(.*?)\\]\\s?\\\"", "g")
        newText = newText.replace(regExp, "[$1]")

        // Remove any HTML tags wrapping the square brackets.
        // See http://haacked.com/archive/2004/10/25/usingregularexpressionstomatchhtml.aspx/
        const openTagRegExp = "<\\w+\\s*[^>]*>"
        const closeTagRegExp = "</\\w+\\s*[\\^>]*>"
        regExp = new RegExp(openTagRegExp + "[\\s|\\\"]*\\[(.*?)\\][\\\"|\\.|\\s]*" + closeTagRegExp, "g")
        newText = newText.replace(regExp, "[$1]")

        // Split on anything that matches [blah blah blah] but keep the [blah blah blah] in the array.
        // The ( ) in the regex keeps the token whole with brackets so we can test against those later.
        const sections = newText.split(/(\[.*?])/g)
        sections.forEach((section: string) => {
            if (section.startsWith("[")) {
                // Remove the empty brackets
                const clickableText = section.replace(/[\[\]]+/g, "")
                out.push({
                    text: clickableText,
                    type: "ps-text"
                })
            } else {
                out.push({
                    text: section,
                    type: ""
                })
            }
        })
        return out
    }

    static clearLocalStorage() {
        // Get audio plays from local storage
        const audioPlays = new Map<string, string>()
        for (const key in localStorage) {
            if (key.includes("audio-plays")) {
                audioPlays.set(key, localStorage.getItem(key)!)
            }
        }

        localStorage.clear()

        // Put audio plays back in local storage
        audioPlays.forEach((value: string, key: string, map: Map<string, string>) => {
            localStorage.setItem(key, value)
        })
    }

    static userFormToApiUserForm(userForm: UserForm): ApiUserForm {
        const products: Product[] = []
        userForm.products.forEach((product) => {
            products.push(product)
        })
        return {
            ...userForm,
            products
        }
    }

    static apiUserFormToUserForm(apiUserForm: ApiUserForm): UserForm {
        const products = new Map<number, Product>()
        apiUserForm.products.forEach((product) => {
            products.set(product.productId, product)
        })
        return {
            ...apiUserForm,
            products
        }
    }

    static parseWebRTCResponse(response: string, parsed: PutTwilioSQSRequest) {
        if (response.startsWith(WEBRTC_RESPONSE)) {
            const recordingParts = response.split("_")

            if (recordingParts.length > 0) {
                if (recordingParts[1] != null) {
                    parsed.recordingsToSave = recordingParts[1]
                }
            }

            if (recordingParts.length > 1) {
                if (recordingParts[2] != null) {
                    parsed.recordingsToDelete = recordingParts[2]
                }
            }
        }
    }
}
