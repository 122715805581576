import {Canvas, Document, Font, Image, Page, PDFViewer, Text, View} from "@react-pdf/renderer"
import moment from "moment"
import * as React from "react"
import {useEffect, useState} from "react"
import ApiService from "../../../../services/ApiService"
import {AvantColors} from "../../../../styles/AvantColors"
import {TimeUtil} from "../../../../util/TimeUtil"
import {PDFSize} from "./PromptsPrintView"
import {ProductId} from "../../../../types/types"

export interface WritingPromptProps {
    firstName: string
    lastName: string
    schoolName: string
    className: string
    loginName: string
    qrString: string
    qrCode?: string
    qrData: string
    prompt: string
    imgUrl: string,
    productId: ProductId,
    size?: PDFSize
    spaceBetweenLines: number
}

export const WritingPrompts: React.FC<{ prompts: WritingPromptProps[], size: PDFSize, product: ProductId }> = ({prompts, size, product}) => {
    if (product === ProductId.STAMP4SE) {
        return (<Stamp4SeWritingPrompts prompts={prompts} size={size}/>)
    } else {
        return (<Stamp4SAPTWritingPrompts prompts={prompts} size={size}/>)
    }
}

const Stamp4SeWritingPrompts: React.FC<{ prompts: WritingPromptProps[], size: PDFSize }> = ({prompts, size}) => {
    return (<WritingPromptsImplementation prompts={prompts} size={size} spaceBetweenLines={28}/>)
}

const Stamp4SAPTWritingPrompts: React.FC<{ prompts: WritingPromptProps[], size: PDFSize }> = ({prompts, size}) => {
    return (<WritingPromptsImplementation prompts={prompts} size={size} spaceBetweenLines={24}/>)
}

const WritingPromptsImplementation: React.FC<{ prompts: WritingPromptProps[], size: PDFSize, spaceBetweenLines: number }> = ({prompts, size, spaceBetweenLines}) => {

    // TODO: This wait code should be deleted when this issue for react-pdf
    // https://github.com/diegomura/react-pdf/issues/420
    // is resolved.
    //
    // Waiting a little bit allows reacts renders to settle
    // enough that we won't see blank pages. It's difficult to
    // be 100% certain, but this approach appears to have been
    // successful for others:
    // https://github.com/diegomura/react-pdf/issues/420#issuecomment-471244580
    //
    // Ideally, this will go away once the overall issue is resolved.
    const [show, setShown] = useState(false)
    useEffect(() => {
        const waitSoPDFRenderingIsntInterrupted = 1000
        setTimeout(() => {
            setShown(true)
        }, waitSoPDFRenderingIsntInterrupted)
    }, [])

    if (!show) {
        return (<span/>)
    }

    return (
        <PDFViewer height={"85%"} width={"100%"}>
            <Document title={`${prompts[0].className} - ${TimeUtil.formatToISODate(moment())}`}>
                {prompts.map((prompt) =>
                    <Doc
                        {...prompt}
                        size={size}
                        key={prompt.qrString}
                        spaceBetweenLines={spaceBetweenLines}
                    />)}
            </Document>
        </PDFViewer>
    )
}

// react-pdf does some funny things with right to left text. Here we make sure it's formatted correctly.
const createRTLLines = (prompt: string, limit: number) => {
    const promptArray: string[] = prompt.split(" ")
    let rtlLines: string[] = []

    for (let i:number = 0; i < promptArray.length; i += limit) {
        const arraySlice: string[] = promptArray.slice(i, i + limit)
        const newLine: string = arraySlice.join(" ")

        rtlLines.push(newLine)
    }
    return rtlLines
}

const Doc: React.FC<WritingPromptProps> = (
    {
        firstName,
        lastName,
        schoolName,
        className,
        loginName,
        qrString,
        qrData,
        prompt,
        imgUrl,
        productId,
        size,
        spaceBetweenLines
    }) => {

    const width = 576
    const height = 763

    // TODO: Add more languages
    let rtlLines: string[] = []
    let customFontURL: string
    const isRTL: boolean = productId === ProductId.APT

    switch(productId) {
        case ProductId.APT:
            customFontURL = `${ApiService.CDN_BASE_URL}resources/tradbdo.ttf`
            break

        default:
            customFontURL = ""
    }

    rtlLines = createRTLLines(prompt, 10)

    Font.register({
        family: "CustomFont",
        src: customFontURL
    })

    return (
        <Page size={size} style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: AvantColors.REPORT_FONT_COLOR_GRAY_2
        }}>
            <View style={{width, height}}>
                <View style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%",
                    marginBottom: 4,
                    lineHeight: 1.6
                }}>
                    <Text
                        style={{
                            fontSize: 20,
                            fontWeight:'bold',
                            marginRight: "auto",
                            color: AvantColors.REPORT_FONT_COLOR_BLACK,
                            fontFamily: "Helvetica-Bold"
                        }}
                    >
                        {firstName} {lastName}
                    </Text>
                    <View style={{
                        fontSize: 10,
                        fontFamily: "Helvetica-Bold",
                        textAlign: "right",
                        marginRight: 10
                        }}
                        >
                        <Text>{schoolName} - {className}</Text>
                        <Text>
                            Login Name:
                            <Text style={{fontFamily: "Helvetica"}}> {loginName} </Text>
                            - {TimeUtil.formatMilliToMonthDayYearHoursMinutesLong(moment.now())}
                        </Text>
                    </View>
                    <Image src={qrData} style={{width: 29, height: 29}}/>
                </View>
                <Canvas
                    paint={(painter => painter.moveTo(0, 0).lineTo(width, 0).stroke(AvantColors.REPORT_FONT_COLOR_GRAY_2))}
                    style={{width: "100%", height: 1, marginBottom: 16}}
                />
                <View style={{flexDirection: "row", marginBottom: 16, height: 128}}>
                    {
                        isRTL ?
                            <>
                                <Image src={`${ApiService.MEDIA_URL}${imgUrl}`} style={{height: 90}}/>
                                <View style={{flex: 1}}>
                                    {
                                        rtlLines.map((prompt, idx) => (
                                            <Text style={{fontSize: 16.5, lineHeight: 1.6, marginRight: 16, fontFamily: "CustomFont", textAlign:"right"}} key={idx}>
                                                {prompt}
                                            </Text>
                                        ))
                                    }
                                </View>
                            </>
                        :
                            <>
                                <Text style={{fontSize: 11, lineHeight: 1.6, marginRight: 16, flex: 1}}>
                                    {prompt}
                                </Text>
                                <Image src={`${ApiService.MEDIA_URL}${imgUrl}`} style={{height: 90}}/>
                            </>
                    }
                </View>
                <View>
                    {/*
                    Greg got super clever here drawing the lines on handwritten prompt. Instead of looping
                    over some n number of lines and draw them horizontally, draw ONE line VERTICALLY but make
                    it a DASHED line and make it SUPER FAT. Then each dash becomes a line! Spacing them
                    becomes super easy to because now you're just defining the space between the dashes!
                    */}
                    <Canvas
                        paint={(painter) => painter
                            .moveTo(width / 2, 0)
                            .lineTo(width / 2, 482)
                            .lineWidth(width).dash(1, {space: spaceBetweenLines})
                            .stroke(AvantColors.REPORT_FONT_COLOR_GRAY_2)
                        }
                        style={{width, height: 482, marginVertical: 5}}
                    />

                </View>
                <View style={{flexDirection: "row"}}>

                    <Image src={qrData} style={{width: 80, height: 80, marginRight: 11}}/>
                    <View style={{flexGrow: 1}}>
                        <Text
                            style={{
                                fontFamily: "Helvetica-Bold",
                                fontSize: 13,
                                color: AvantColors.REPORT_FONT_COLOR_BLACK, marginBottom: 7,
                                alignSelf: "center"
                            }}
                        >
                            DO NOT WRITE BELOW THIS LINE
                        </Text>
                        <View style={{flexDirection: "row"}}>
                            <View style={{fontSize: 10, flexGrow: 1}}>
                                <Text style={{fontFamily: "Helvetica-Bold"}}>{qrString}</Text>
                                <Text>Responses are limited to one page for each prompt.</Text>
                                <Text>Do not write on the back of this page or on additional pages.</Text>
                                <Text>Copyright © {new Date().getUTCFullYear()} Avant Assessment, LLC. All rights
                                    reserved.</Text>
                            </View>
                            <Image src={`${ApiService.CDN_BASE_URL}resources/common/img/avant-logo.png`}
                                   style={{width: 88, height: 45, alignSelf: "flex-end", marginRight: 10}}/>
                        </View>
                    </View>
                    <Image src={qrData} style={{width: 80, height: 80, marginLeft: "auto"}}/>
                </View>
            </View>
        </Page>
    )
}
