import {IItem, TLocalAnswer} from "../../types/types"
import {ItemContainerHelper} from "../ItemContainerHelper"

export class AdvanceItemContainerHelper extends ItemContainerHelper {
    answeredModalText: string = "You must answer the question to proceed."

    getCbAnswerCountModalText(item: IItem): string {
        return ""
    }

    isValidCbAnswer(answer: any[], item: IItem, disableRequiredAnswers: boolean): boolean {
        return true
    }

    isAnswered(answer: TLocalAnswer | null, disableRequiredAnswers: boolean): boolean {
        if (disableRequiredAnswers) {
            return true
        }
        let answered: boolean = false
        if (answer !== null) {
            answered = true
        }
        return answered
    }
}
