import Button from "@material-ui/core/Button/Button"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup/FormGroup"
import Grid from "@material-ui/core/Grid/Grid"
import Paper from "@material-ui/core/Paper/Paper"
import Typography from "@material-ui/core/Typography/Typography"
import { observable } from "mobx"
import { observer } from "mobx-react"
import React from "react"
import { theme } from "../../styles/MuiThemes"
import { TEvent, TFormSubmit } from "../../types/types"
import { SUPPORT_EMAIL } from "../../util/Constants"
import { validateEmail } from "../../util/validators"
import { RosterState, rosterStore } from "./RosterStore"
import { RosterTextField } from "./RosterTextField"

interface RosterProps {
    loginId: number,
    isMiddlebury: string,
    verifyLoginResults?: string
}
interface InitialFormProps {
    store: Store,
    loginId: number,
    isMiddlebury: string
}
interface CompletedProps {
    store: Store
}
export class Store {
    @observable
    parentEmail = ""
    @observable
    confirmParentEmail = ""
    @observable
    invalidParentEmail = false
    @observable
    parentEmailsDontMatch = false

    @observable
    studentEmail = ""
    @observable
    confirmStudentEmail = ""
    @observable
    invalidStudentEmail = false
    @observable
    studentEmailsDontMatch = false

    @observable
    firstName = ""
    @observable
    firstNameHasError = ""
    @observable
    lastName = ""
    @observable
    lastNameHasError = ""

    @observable
    hasHeadset = false
    @observable
    hasValidComputer = false
    @observable
    reviewedTestTime = false
    @observable
    hasWebCam = false
    @observable
    hasId = false
    @observable
    canTakeAlone = false
    @observable
    reviewedTechCheck = false
    @observable
    reviewedRemoteTestTakerGuide = false
    @observable
    completedSampleTest = false
}
@observer
export class RosterForm extends React.Component<RosterProps, {}> {

    store = new Store()
    render() {
        let form = <InitialForm store={this.store} loginId={this.props.loginId} isMiddlebury={this.props.isMiddlebury} />
        if (rosterStore.rosterState === RosterState.IN_PROGRESS) {
            form = <RosterInProgress />
        } else if (rosterStore.rosterState === RosterState.COMPLETED) {
            form = <CompletedMessage store={this.store} />
        } else if (rosterStore.rosterState === RosterState.ERROR) {
            form = <ServerErrorMessage message={rosterStore.rosteringError} />
        }
        return (
            <>
                <LoginErrorMessage verifyLoginResults={this.props.verifyLoginResults} />
                {form}
            </>
        )
    }
}

const LoginErrorMessage = (props: { verifyLoginResults?: string }): JSX.Element => {
    const { verifyLoginResults } = props
    let Element = <></>

    if (props.verifyLoginResults) {
        Element = (
            <div className="login__login-container__top__warning" style={{ width: 780, marginBottom: 32, textAlign: "center" }}>
                <p className="login__login-container__top__warning__text">{verifyLoginResults}</p>
                <p className="login__login-container__top__warning__text">Please contact our Support team by visiting:
                    <a href={SUPPORT_EMAIL}>{`${SUPPORT_EMAIL}`}</a></p>
            </div>

        )
    }

    return Element
}

export const CompletedMessage: React.FC<CompletedProps> = (props) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: theme.spacing(2), maxWidth: "960px", textAlign: "center" }}>
            <div style={{ display: "flex", marginRight: theme.spacing(1), marginTop: theme.spacing(1) }}>
                <Typography>
                    You have successfully been registered for an online remotely proctored test.  An email will be sent to
                    your <strong>{props.store.studentEmail}</strong> inbox.  Please remember to check your spam or junk email folder if you
                    do not find the email in your inbox.
                </Typography>
            </div>
            <div style={{ display: "flex", marginRight: theme.spacing(1), marginTop: theme.spacing(1) }}>
                <Typography>
                    If you do not receive one, please contact our support by visiting:
                </Typography>
            </div>
            <div>
                <a href={SUPPORT_EMAIL}>{`${SUPPORT_EMAIL}`}</a>
            </div>
        </div>
    )
}

export const ServerErrorMessage = (props: { message: string }) => {
    return (
        <p style={{ maxWidth: 960 }}>
            {`A problem occurred while trying to register. ${props.message} If problem persists please contact Avant support at `}
            <a href={SUPPORT_EMAIL}>{`${SUPPORT_EMAIL}`}</a>
        </p>
    )
}

export const RosterCheckbox = (props: { message: React.ReactNode, isChecked: boolean, onToggle: () => void }) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={props.isChecked}
                    onChange={props.onToggle}
                />
            }
            label={props.message}
        />
    )

}

@observer
export class InitialForm extends React.Component<InitialFormProps, {}> {
    private readonly isMiddlebury: boolean = false

    constructor(props: Readonly<InitialFormProps>) {
        super(props)
        this.isMiddlebury = (this.props.isMiddlebury === "true")
    }


    submitRoster = (event: TFormSubmit) => {
        event.preventDefault()

        const { store, loginId } = this.props
        const invalidNameMessage = (whichName: string) => `${whichName} cannot be empty.`

        this.validateEmails()

        if (this.emailsHaveError()) {
            return
        }

        const validFirstName = store.firstName.trim().length !== 0
        if (!validFirstName) {
            store.firstNameHasError = invalidNameMessage("First name")
            return
        }
        const validLastName = store.lastName.trim().length !== 0
        if (!validLastName) {
            store.lastNameHasError = invalidNameMessage("Last name")
            return
        }

        rosterStore.rosterState = RosterState.IN_PROGRESS

        if (this.isMiddlebury) {
            rosterStore.roster(
                loginId,
                store.parentEmail.trim(),
                store.studentEmail.trim(),
                store.firstName.trim(),
                store.lastName.trim()
            )
        } else {
            rosterStore.rosterWithExamity(
                loginId,
                store.studentEmail.trim(),
                store.firstName.trim(),
                store.lastName.trim()
            )
        }
    }

    emailsHaveError = () => {
        let isError = false
        if (this.props.store.invalidStudentEmail || this.props.store.studentEmailsDontMatch) {
            isError = true
        }
        if (this.isMiddlebury && (this.props.store.invalidParentEmail || this.props.store.parentEmailsDontMatch)) {
            isError = true
        }
        return isError
    }

    validateEmails = () => {
        const { store } = this.props
        if (this.isMiddlebury) {
            const trimmedParentEmail = store.parentEmail.trim()
            const trimmedConfirmParentEmail = store.confirmParentEmail.trim()
            store.parentEmailsDontMatch = trimmedParentEmail !== trimmedConfirmParentEmail
            store.invalidParentEmail = !validateEmail(trimmedParentEmail)
        } else {
            store.parentEmailsDontMatch = false
            store.invalidParentEmail = false
        }

        const trimmedStudentEmail = store.studentEmail.trim()
        const trimmedConfirmStudentEmail = store.confirmStudentEmail.trim()
        store.studentEmailsDontMatch = trimmedStudentEmail !== trimmedConfirmStudentEmail
        store.invalidStudentEmail = !validateEmail(trimmedStudentEmail)
    }

    fixEmailErrors = () => {
        if (this.emailsHaveError()) {
            this.validateEmails()
        }
    }

    shouldRegistrationBeEnabled = () => {
        const { store } = this.props

        const generalCheck = store.hasHeadset &&
            store.hasId &&
            store.hasWebCam &&
            store.hasValidComputer &&
            store.canTakeAlone &&
            store.completedSampleTest &&
            store.reviewedTestTime &&
            store.reviewedRemoteTestTakerGuide &&
            store.studentEmail.trim().length > 0 &&
            store.confirmStudentEmail.trim().length > 0 &&
            store.firstName.trim().length > 0 &&
            store.lastName.trim().length > 0 &&
            !this.emailsHaveError()

        if (this.isMiddlebury) {
            return generalCheck &&
                store.parentEmail.trim().length > 0 &&
                store.confirmParentEmail.trim().length > 0
        } else {
            return generalCheck
        }
    }

    render() {
        const { store } = this.props
        const registrationEnabled = this.shouldRegistrationBeEnabled()
        return (
            <Paper style={{ width: 780 }}>
                <form
                    style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: 16 }}
                    onSubmit={this.submitRoster}
                >
                    <FormGroup style={{ marginBottom: 16 }}>
                        <Grid container={true} spacing={2}>
                            {this.isMiddlebury && (
                                <>
                                    <Grid item={true} xs={12} sm={6}>
                                        <RosterTextField
                                            label={"Parent Email"}
                                            value={store.parentEmail}
                                            errorMessage={store.parentEmailsDontMatch ? "Please make sure the emails match." : (
                                                store.invalidParentEmail ? "Email is invalid." : ""
                                            )}
                                            onChange={(event) => {
                                                store.parentEmail = event.target.value
                                                this.fixEmailErrors()
                                            }}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6}>
                                        <RosterTextField
                                            label={"Confirm Parent Email"}
                                            value={store.confirmParentEmail}
                                            onChange={(event: TEvent) => {
                                                store.confirmParentEmail = event.target.value
                                                this.fixEmailErrors()
                                            }}
                                            errorMessage={store.parentEmailsDontMatch ? "Please make sure the emails match." : (
                                                store.invalidParentEmail ? "Email is invalid." : ""
                                            )}
                                        />
                                    </Grid>
                                </>
                            )}

                            <Grid item={true} xs={12} sm={6}>
                                <RosterTextField
                                    label={"Test Taker Email"}
                                    value={store.studentEmail}
                                    errorMessage={store.studentEmailsDontMatch ? "Please make sure the emails match." : (
                                        store.invalidStudentEmail ? "Email is invalid." : ""
                                    )}
                                    onChange={(event) => {
                                        store.studentEmail = event.target.value
                                        this.fixEmailErrors()
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <RosterTextField
                                    label={"Confirm Test Taker Email"}
                                    value={store.confirmStudentEmail}
                                    errorMessage={store.studentEmailsDontMatch ? "Please make sure the emails match." : (
                                        store.invalidStudentEmail ? "Email is invalid." : ""
                                    )}
                                    onChange={(event) => {
                                        store.confirmStudentEmail = event.target.value
                                        this.fixEmailErrors()
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <RosterTextField
                                    label={"First Name (Same as photo ID)"}
                                    value={store.firstName}
                                    errorMessage={store.firstNameHasError}
                                    onChange={(event) => store.firstName = event.target.value}
                                />
                            </Grid>

                            <Grid item={true} xs={12} sm={6}>
                                <RosterTextField
                                    label={"Last Name (Same as photo ID)"}
                                    value={store.lastName}
                                    errorMessage={store.lastNameHasError}
                                    onChange={(event) => store.lastName = event.target.value}
                                />
                            </Grid>
                                <Grid>
                                    <Grid item={true} xs={12}>
                                        <RosterCheckbox
                                            message={"I have a Mac (OS X) or PC (Windows) computer for my test. NOTE: Chromebooks are NOT supported."}
                                            isChecked={store.hasValidComputer}
                                            onToggle={() => store.hasValidComputer = !store.hasValidComputer}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={12}>
                                        <RosterCheckbox
                                            message={"I have speakers and a microphone for my proctored assessment."}
                                            isChecked={store.hasHeadset}
                                            onToggle={() => store.hasHeadset = !store.hasHeadset}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={12}>
                                        <RosterCheckbox
                                            message={"I have a web camera for my proctored assessment."}
                                            isChecked={store.hasWebCam}
                                            onToggle={() => store.hasWebCam = !store.hasWebCam}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={12}>
                                        <RosterCheckbox
                                            message={"I have a form of photo I.D. to show my proctor before starting the assessment."}
                                            isChecked={store.hasId}
                                            onToggle={() => store.hasId = !store.hasId}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={12}>
                                        <RosterCheckbox
                                            message={"I understand that my testing time only begins once I start the actual test."}
                                            isChecked={store.reviewedTestTime}
                                            onToggle={() => store.reviewedTestTime = !store.reviewedTestTime}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={12}>
                                        <RosterCheckbox
                                            message={"I have reviewed Avant Assessment’s Remote Test Taker Guide."}
                                            isChecked={store.reviewedRemoteTestTakerGuide}
                                            onToggle={() => store.reviewedRemoteTestTakerGuide = !store.reviewedRemoteTestTakerGuide}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={12}>
                                        <RosterCheckbox
                                            message={"I have reviewed the Technology Guide for Avant’s Remote Proctoring Solution."}
                                            isChecked={store.completedSampleTest}
                                            onToggle={() => store.completedSampleTest = !store.completedSampleTest}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={12}>
                                        <RosterCheckbox
                                            message={"I will take the assessment alone in a room."}
                                            isChecked={store.canTakeAlone}
                                            onToggle={() => store.canTakeAlone = !store.canTakeAlone}
                                        />
                                    </Grid> 
                                    </Grid> 
                        </Grid>
                    </FormGroup>
                    <Button
                        type={"submit"}
                        variant={"contained"}
                        color={"primary"}
                        size={"large"}
                        disabled={!registrationEnabled}
                    >
                        Register
                    </Button>
                </form>
            </Paper>
        )
    }
}


export const RosterInProgress = () => {
    return (
        <>
            <h1>Rostering...</h1>
            <CircularProgress/>
        </>
    )
}