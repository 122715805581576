import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { H1Text } from '../../styles/AvantTypography';
import { Header } from "../../components/Header/Header"
import { useHistory } from "react-router"
import { Take, State } from "../../types/types"
import { connect } from 'react-redux';
import { Section } from '../../app/section/models/Section';
import ApiService, { ResponseData } from '../../services/ApiService';
import HelperService from '../../services/HelperService';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  textAlign: 'center',
  fontFamily: 'Arial, sans-serif',
});

const Head = styled(Box)(({ theme }) => ({
  backgroundColor: '#d3ffd8',
  width: '37%',
  marginBottom: '20px',
  fontSize: '30px',
  border: '1px solid #49f770',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const Paragraph = styled('p')({
  color: '#1a1919',
  fontSize: 'medium',
});

const TextLine = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  textAlign: 'left',
  marginRight: "40px"
});

interface StateToProps {
  take: Take
}

function mapStateToProps(state: State): StateToProps {
  return {
    take: state.app.take!,
  }
}
interface LocationState {
  detail: Section;
}
const Logout: React.FC<StateToProps> = (props) => {
  const history = useHistory<LocationState>();
  const [apiResponse, setApiResponse] = useState<ResponseData | null>(null);

  const getResponseByeBye = () => {
    ApiService.getByeByePage()
      .then(response => {
        if (response.error) {
          setApiResponse({ error: true, errorDescription: response.errorDescription, response: null });
        } else {
          setApiResponse(response); 
        }
      })
      .catch(error => {
        console.error("Error:", error);
        setApiResponse({ error: true, errorDescription: error.message, response: null });
      });
  }
  useEffect(() => {
    if (props.take === null) {
      HelperService.logout(history?.location?.state?.detail)
    }
    else {
      getResponseByeBye()
    }
  }, []);
  return (
    <>
      <Header />
      {props.take && props.take.availablePanels?.length > 0 ? (
        <Container style={{ marginTop: "-60px" }}>
          <Head component="div">
            <CheckCircleIcon style={{ color: '#04a12b', fontSize: '40px' }} />
            <div dangerouslySetInnerHTML={{ __html: apiResponse && apiResponse.response && apiResponse.response['inprogressheader'] ? apiResponse.response['inprogressheader'] : '' }} />
          </Head>
          <H1Text style={{ marginRight: '196px' }}> How To Resume Your Test</H1Text>
          <Paragraph>
            <TextLine style={{ marginRight: "67px" }}>
              <div dangerouslySetInnerHTML={{ __html: apiResponse && apiResponse.response && apiResponse.response['inprogresscontent'] ? apiResponse.response['inprogresscontent'] : '' }} />
            </TextLine>
            
          </Paragraph>
        </Container>
      ) : (
        <Container style={{ marginTop: "-60px" }}>
          <Head component="div">
            <CheckCircleIcon style={{ color: '#04a12b', fontSize: '40px' }} />
            <div dangerouslySetInnerHTML={{ __html: apiResponse && apiResponse.response && apiResponse.response['completedheader'] ? apiResponse.response['completedheader'] : '' }} />
          </Head>
          <H1Text style={{ marginRight: '140px' }}>Close Your Proctoring Session</H1Text>
          <Paragraph>
            <TextLine style={{ marginRight: "6px" }}>
              <div dangerouslySetInnerHTML={{ __html: apiResponse && apiResponse.response && apiResponse.response['completedcontent'] ? apiResponse.response['completedcontent'] : '' }} />

            </TextLine>
          </Paragraph>
        </Container>
      )}
    </>
  );
}
export default connect(mapStateToProps)(Logout)