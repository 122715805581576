import { Box, createStyles, Typography, withStyles, WithStyles } from "@material-ui/core"
import { observer } from 'mobx-react'
import React from "react"
import { voiceRecorderStore } from '../../../app/common/item/VoiceRecorderStore'
import { AvantColors } from "../../../styles/AvantColors"
import { TEvent, TWILIO_DEFAULT_INPUT_DEVICE_ID } from "../../../types/types"
import { isEmpty } from "../../../util/ArrayUtil"
import { SAMPLES_TILL_VOLUME_CHECK, VOLUME_HISTORY_EQUALIZER, VOLUME_HISTORY_MIC_STRENGTH } from "../../../util/Constants"
import AddToRecordingIcon from "../AddToRecordingIcon/AddToRecordingIcon"
import DeleteConfirmationOverlay from "../DeleteConfirmationOverlay/DeleteConfirmationOverlay"

import GetHelpMessage from "../MessageDropDown/GetHelpMessage"
import LowMicVolumeMessage from "../MessageDropDown/LowMicVolumeMessage"
import NoMicErrorMessage from "../MessageDropDown/NoMicErrorMessage"
import BeginVoiceRecordingButton from "../VoiceRecorderButton/BeginVoiceRecordingButton/BeginVoiceRecordingButton"
import StartListeningButton from "../VoiceRecorderButton/StartListeningButton/StartListeningButton"
import StopListeningButton from "../VoiceRecorderButton/StopListeningButton/StopListeningButton"
import StopRecordingVoiceButton from "../VoiceRecorderButton/StopRecordingVoiceButton/StopRecordingVoiceButton"
import VoiceVolumeVisualization from "../VoiceVolumeVisualization/VoiceVolumeVisualization"
import ReRecordButton from "../VoiceRecorderButton/ReRecordingButton/ReRecordButton"

const styles = createStyles({
    recorder: {
        maxWidth: 509,
        display: "flex",
        flexDirection: "column"
    },
    recorderSmall: {
        width: 350
    },
    controls: {
        width: "100%",
        borderRadius: "4px",
        textAlign: "left",
        display: "flex",
        paddingLeft: 16,
        paddingRight: 8,
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: "10px",
        paddingBottom: "19px",
        flexWrap: "wrap"
    },
    button: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    icons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"

    },
    volume: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "116px"
    },
    volumeHidden: {
        display: "none"
    },
    processing: {
        color: AvantColors.avantDarkGrey,
        fontSize: 30,
        lineHeight: 2.7,
        marginLeft: 20
    },
    cancelDelete: {
        fontSize: "1.2em",
        color: AvantColors.voiceRecorderButtonOrange
    },
    confirmDelete: {
        fontSize: "1.2em",
        color: "white",
        backgroundColor: AvantColors.voiceRecorderButtonOrange
    },
    deleteMessageHeader: {
        fontSize: "1.3em"
    },
    text: {
        fontWeight: "bold",
        lineHeight:"1.5",
        color: "#767676",
        fontSize: "14px",
        padding:"0 10px"
    },
    overlay: {
        width: "100%"
    },
    overlap:{
        backgroundColor:"white",
        border:"1px solid #E8F2F8",
        borderRadius:"4px",
        padding:"5px 0"
    },
    recorderIcon:{
        display:"flex",
        gap:"10px"
    },
    addIcon : {
        display:"flex",
        gap:"10px",
        marginBottom:"7px"

    }


})
export type TVoiceRecordingState = "none" | "recording" | "recorded"
export type TVoicePlaybackState = "not" | "playing"

interface IComponentProps {
    supportsOtherInputs: boolean
    availableInputs: MediaDeviceInfo[]
    recordingState: TVoiceRecordingState
    startRecording: () => void
    stopRecording: () => void
    saveFinalRecordings: boolean
    hasRecordingAvailable: () => boolean
    minRecordingLengthMilliseconds: number
    resetRecording: () => void
    startPlaybackRecording: (onPlaybackEnded: () => void) => void
    addToRecording: () => void
    isInteractable: boolean
    volume: number
    displayVolume: boolean
    maxLengthSeconds: number
}

type TComponentProps = IComponentProps & WithStyles<typeof styles>

interface IComponentState {
    recordingState: TVoiceRecordingState
    inputDeviceId: string
    isDeleteConfirmShown: boolean
    isShowingLowVolumeMessage: boolean
    isGetHelpShown: boolean
    startTime?: Date
    volume: number
    recordingLengthMilliseconds: number
    recordingLastStartTime?: number
    recordingLiveTimerMilliseconds: number
    AddRecord: boolean
    ReRecord: boolean
}


@observer
class Html5VoiceRecorder extends React.Component<TComponentProps, IComponentState> {
    constructor(props: TComponentProps) {
        super(props)

        this.state = {
            recordingState: props.recordingState,
            volume: props.volume,
            inputDeviceId: TWILIO_DEFAULT_INPUT_DEVICE_ID,
            isDeleteConfirmShown: false,
            isShowingLowVolumeMessage: false,
            isGetHelpShown: false,
            startTime: new Date(),
            recordingLengthMilliseconds: 0,
            recordingLastStartTime: undefined,
            recordingLiveTimerMilliseconds: 0,
            AddRecord: false,
            ReRecord: false
        }
    }

    componentDidUpdate(prevProps: IComponentProps) {
        if (this.props.volume !== prevProps.volume) {
            this.setState({
                volume: this.props.volume
            });
        }
    }

    nowInMilliseconds = () => (new Date()).getTime()

    getInProgressRecordingLength = () => {
        // Calculate the time since we last started (or resumed) recording:
        const recordingLengthLatestMilliseconds = this.state.recordingLastStartTime === undefined ? 0 : this.nowInMilliseconds() - this.state.recordingLastStartTime

        // Add the latest recording time to any previous recording time:
        return (this.state.recordingLengthMilliseconds + recordingLengthLatestMilliseconds)
    }

    progressLiveTimer = () => {
        if (this.state.recordingState === 'recording') {
            this.setState({ recordingLiveTimerMilliseconds: this.getInProgressRecordingLength() })
            setTimeout(this.progressLiveTimer, 1000)
        }
    }

    stopRecording = () => {
        // Calculate the length of the in-progress recording:
        const recordingLengthMilliseconds = this.getInProgressRecordingLength()

        this.props.stopRecording()
        this.setState({
            recordingState: "recorded",
            volume: 0,
            recordingLengthMilliseconds: recordingLengthMilliseconds,
            recordingLiveTimerMilliseconds: recordingLengthMilliseconds,
        })
    }

    stopRecordingIfTooLong = () => {
        // If we're recording...
        if (this.state.recordingState === "recording") {
            // If the in-progress recording length exceeds the maximum length...
            if (this.getInProgressRecordingLength() > this.props.maxLengthSeconds * 1000) {
                // Stop the recording:
                this.stopRecording()
            }
        }
    }

    onStartRecording = (event: TEvent) => {
        event.preventDefault()
        this.setState(
            { recordingState: "recording", recordingLastStartTime: this.nowInMilliseconds() },
            () => {
                // Start the display timer:
                this.progressLiveTimer()
            }
        )
        this.props.startRecording()

        // If we have a max length value...
        if (this.props.maxLengthSeconds > 0) {
            // Allow the max time to pass, then stop the recording if it's too long:
            setTimeout(this.stopRecordingIfTooLong, this.props.maxLengthSeconds * 1000)
        }
    }

    onStopRecording = (event: TEvent) => {
        event.preventDefault()
        this.stopRecording()
    }

    onStartPlayback = (event: TEvent) => {
        event.preventDefault()
        this.props.startPlaybackRecording(() => {
            voiceRecorderStore.playbackState = "not"
        })
        voiceRecorderStore.playbackState = "playing"
    }

    onStopPlayback = (event: TEvent) => {
        event.preventDefault()
        voiceRecorderStore.stopPlayBack()
        voiceRecorderStore.playbackState = "not"
    }

    onAddToRecording = (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
        event.preventDefault()
        this.props.addToRecording()
        this.setState(
            { recordingState: "recording", recordingLastStartTime: this.nowInMilliseconds(), AddRecord: true },
            () => {
                // Start the display timer:
                this.progressLiveTimer()
            }
        )

        voiceRecorderStore.playbackState = "not"

        // If we have a max length value...
        if (this.props.maxLengthSeconds > 0) {
            // Allow the remaining portion of the max time to pass, then stop the recording if it's too long:
            // (If the remaining time is negative -- which shouldn't happen -- setTimeout should enforce a minimum of 0.)
            setTimeout(this.stopRecordingIfTooLong, this.props.maxLengthSeconds * 1000 - this.state.recordingLengthMilliseconds)
        }
    }

    onShowDeleteConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        this.setState({ isDeleteConfirmShown: true })
    }

    onHideDeleteConfirm = (event: TEvent) => {
        event.preventDefault()
        this.setState({ isDeleteConfirmShown: false })
    }

    onResetRecording = (event: TEvent) => {
        event.preventDefault()
        this.props.resetRecording()
        this.setState({
            ReRecord: true,
            AddRecord: false,
            recordingState: "none",
            isDeleteConfirmShown: false,
            recordingLengthMilliseconds: 0,
            recordingLastStartTime: undefined,
            recordingLiveTimerMilliseconds: 0,
        })
        voiceRecorderStore.playbackState = "not"
    }

    render() {
        const {
            supportsOtherInputs,
            availableInputs,
            saveFinalRecordings,
            hasRecordingAvailable,
            minRecordingLengthMilliseconds,
            isInteractable,
            displayVolume,
            classes
        } = this.props

        const {
            recordingState,
            isDeleteConfirmShown,
            isShowingLowVolumeMessage,
            isGetHelpShown,
            volume,
            startTime
        } = this.state

        // When we have a maximum response length (0 = no maximum), we only allow adding to the recording if the recording length is under the maximum:
        const isSettingExistsForMaximumLength: boolean = this.props.maxLengthSeconds > 0
        const isRecordingUnderMaximumLength: boolean = this.state.recordingLengthMilliseconds < this.props.maxLengthSeconds * 1000
        const isAllowedAddToRecording: boolean = (!isSettingExistsForMaximumLength || isRecordingUnderMaximumLength)

        let addToRecordingJsx = <span />
        let btn = <BeginVoiceRecordingButton onClick={this.onStartRecording} />
        if (recordingState === "recording") {
            btn = (
                <StopRecordingVoiceButton
                    onClick={this.onStopRecording}
                    minRecordingLengthMilliseconds={minRecordingLengthMilliseconds}
                    startTime={startTime}
                />
            )
        } else if (recordingState === "recorded" && voiceRecorderStore.playbackState === "not" && hasRecordingAvailable()) {
            btn = <StartListeningButton onClick={this.onStartPlayback} recordingDuration={120} />

            if (saveFinalRecordings && hasRecordingAvailable()) {
                addToRecordingJsx = (
                    <div className={classes.recorderIcon}>
                        {isAllowedAddToRecording && <div><AddToRecordingIcon onClick={this.onAddToRecording} addButtonFlag={this.state.AddRecord} /></div>}
                        <div><ReRecordButton onClick={this.onShowDeleteConfirm} ReRecordFlag={this.state.ReRecord} /></div>
                    </div>
                )
            }
        } else if (recordingState === "recorded" && voiceRecorderStore.playbackState === "playing") {
            btn = <StopListeningButton onClick={this.onStopPlayback} recordingDuration={120} />
        }

        const noMicMessage = isEmpty(availableInputs) && supportsOtherInputs ? <NoMicErrorMessage /> : ""

        const lowVolumeMessage = isShowingLowVolumeMessage ? <LowMicVolumeMessage inputDevices={availableInputs} /> : ""

        const getHelpMessageDropDown = isGetHelpShown ? <GetHelpMessage inputDevices={availableInputs} /> : ""
        const getHelpLabel = isGetHelpShown ? "Hide Help" : "Get Help"

        const recorderClassName = displayVolume ? classes.recorder : classes.recorderSmall

        const volumeControlClassName = displayVolume ? classes.volume : `${classes.volume} ${classes.volumeHidden}`

        const secondsToMinutesColonSeconds = (seconds: number): string => {
            const minutes: number = Math.floor(seconds / 60)
            const secondsRemainder: number = seconds % 60

            return minutes + ':' + (secondsRemainder < 10 ? '0' : '') + secondsRemainder
        }

        const millisecondsToMinutesColonSeconds = (milliseconds: number): string => secondsToMinutesColonSeconds(Math.floor(milliseconds / 1000))

        const controls = isDeleteConfirmShown ? (

            <div className={classes.overlay}>
                <DeleteConfirmationOverlay onCancel={this.onHideDeleteConfirm} onConfirm={this.onResetRecording} />
            </div>
        ) : (
            <>
            <div className={classes.addIcon}>
                <div className={classes.button}>{btn}</div>
                <div className={classes.icons}>{addToRecordingJsx}</div>
            </div>
                <div className={volumeControlClassName}>
                    <VoiceVolumeVisualization
                        volume={volume}
                        micStrengthVolumeHistoryLength={VOLUME_HISTORY_MIC_STRENGTH}
                        equalizerVolumeHistoryLength={VOLUME_HISTORY_EQUALIZER}
                        minSamplesBeforeTriggerToLowVolumeMessage={SAMPLES_TILL_VOLUME_CHECK}
                        onGetHelpClicked={() => {
                            this.setState({ isGetHelpShown: !isGetHelpShown })
                        }}
                        onVolumeToLowForToLong={() => {
                            this.setState({ isShowingLowVolumeMessage: true })
                        }}
                        getHelpLabel={getHelpLabel}
                    />
                </div>
            </>
        )

        const html = isInteractable ? (
            <>
                {this.props.maxLengthSeconds > 0 &&
                    <Box textAlign="right">
                        <Typography>
                            {millisecondsToMinutesColonSeconds(this.state.recordingLiveTimerMilliseconds)
                                + ' / '
                                + secondsToMinutesColonSeconds(this.props.maxLengthSeconds)}
                        </Typography>
                    </Box>
                }

                <div className={classes.overlap}>
                <div className={classes.controls}>
                    {controls}
                </div>
                <div className={classes.text}>You have ONLY ONE chance to re-record your response, if needed.<br/> You can add to your response ONLY ONE time, if needed. </div>
                </div>
                
                <div className={"Html5VoiceRecorder__helpMessage"}>
                    {noMicMessage}
                    {lowVolumeMessage}
                    {getHelpMessageDropDown}
                </div>
            </>
        ) : (
            <div className={classes.controls}>
                <div className={classes.processing}>
                    <i className="fa fa-cog fa-spin fa-3x fa-fw" />
                </div>
            </div>
        )
        return <div className={recorderClassName}>
            {html}
        </div>
    }
}

export default withStyles(styles)(Html5VoiceRecorder)
