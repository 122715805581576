/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/
import React from "react"
import {productStore} from "../../app/common/products/ProductStore"
import {
    ApiPanelGraph,
    ApiTakePanelStateEnum,
    Panel,
    PanelSkillEnum,
    TakePanel,
} from "../../types/types"
import {
    STAMP_4S_PRODUCT_ID,
    STAMP_4SE_PRODUCT_ID,
    STAMP_SIGN_LANGUAGE_PRODUCT_ID,
    RECEPTIVE,
    LISTENING,
    EXPRESSIVE,
    NOELLA_IMAGES,
    IMAGES_BASE_URL,
    CHECKMARK,
    SELFEVALUATION
} from "../../util/Constants"
import { H4Text } from "../../styles/AvantTypography"


interface Props{
availablePanel: Map<string, Panel>
skill: string
testName: string
takePanel: TakePanel[]
completedPanelSkill: Set<string>
openModal: (currentPanelId: number, currentTestName: string, selfEvaluation: boolean) => void,
panelGraph:ApiPanelGraph
}
const RenderSkill: React.FC <Props>= ({availablePanel,skill,testName,takePanel,completedPanelSkill,openModal,panelGraph})=> {
    const inProgressSkillList = takePanel
    .filter((p) => p.panelState === ApiTakePanelStateEnum.STARTED)
    .map((p) => p.panel.skill)

if (inProgressSkillList.length > 1) {
    throw new Error(`There should never be more than 1 available panel at a time!`)
}
const inProgressSkill = inProgressSkillList.length > 0 ? inProgressSkillList[0] : null
const isHandwritten = panelGraph.handwritten && skill === PanelSkillEnum.WRITING
const panel = availablePanel.get(skill)
const is4se = productStore.driver!!.productId.value() === STAMP_4SE_PRODUCT_ID.value()
const isEnabled =
    (inProgressSkill // Are we in-progress?
        ? panel && panel.skill === inProgressSkill // Yup, only the skill that is in-progress is enabled
        : !!panel) && // Otherwise we haven't started a skill, only the ones that are in the available panels are enabled
    !isHandwritten // BUT if the panel is handwritten, it is disabled.
const disabledClass = isEnabled ? "" : "dashboard__skills__skill-container--disabled"
const handwrittenClass = isHandwritten ? "dashboard__skills__skill-container--handwritten" : ""
const seBackground = is4se ? "dashboard__skills__skill-container--4se" : ""
const seDisabledBackground = is4se ? "dashboard__skills__skill-container__completed-skill--4se" : ""
const containerClass = `dashboard__skills__skill-container ${disabledClass} ${handwrittenClass} ${seBackground}`
const completedSkill = `dashboard__skills__skill-container__completed-skill ${seDisabledBackground}`
const Panels = takePanel.find((tp: TakePanel) => {
    return tp.panel.skill === skill
})
const inProgressSelfEvaluated = inProgressSkill ? takePanel.find(take => { return take.panel.skill === PanelSkillEnum.READING }) : ""

const isComplete = (Panels && Panels.panelState === ApiTakePanelStateEnum.COMPLETED) || false
const isSelfEvaluated =((panel && panel.selfEvaluation) || (Panels && Panels.benchmark && Panels.benchmark.split("-")[0].trim() === ApiTakePanelStateEnum.SELFEVALUATION) || inProgressSelfEvaluated && inProgressSelfEvaluated.panel && inProgressSelfEvaluated.panel.selfEvaluation) || false
let imageUrl = `${IMAGES_BASE_URL}${testName}.svg`
let imageClass = "dashboard__skills__skill-container__skill"
if (productStore.driver!!.productId.value() === STAMP_4SE_PRODUCT_ID.value()) {
    imageUrl = `${NOELLA_IMAGES}${testName.toLowerCase()}_small.jpg`
    imageClass = `${imageClass} dashboard__skills__skill-container__skill--4se`
}


if (testName === "Listening" && panelGraph.productId === STAMP_4S_PRODUCT_ID.value()) {
    imageUrl = isSelfEvaluated ? SELFEVALUATION : LISTENING 
}
else if (testName === "Reading" && panelGraph.productId === STAMP_4S_PRODUCT_ID.value()) {
    imageUrl = isSelfEvaluated ? SELFEVALUATION : `${IMAGES_BASE_URL}${testName}.svg`
}
else if (testName === "Listening" && panelGraph.productId === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()) {
    imageUrl = isSelfEvaluated ? SELFEVALUATION : RECEPTIVE
} else if (testName === "Speaking" && panelGraph.productId === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()) {
    imageUrl = isSelfEvaluated ? SELFEVALUATION : EXPRESSIVE
 }else{}


const mustCompleteExamText = (testSection: string, currentProductId: number) => {
    if (testSection === "Speaking" && currentProductId !== STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()) {
        return `Listening must be completed before ${testSection}`
    } else if (testSection === "Speaking" && currentProductId === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()) {
        return `Receptive must be completed before Expressive`
    } else {
        return `Reading must be completed before ${testSection}`
    }
}

return (
  <div
        data-tst-id={`skill-${skill}`}
        data-panel-id={`panel.id`}
        className={containerClass}
        style={{width: "min-content", whiteSpace: "normal", overflowWrap: "break-word", wordWrap: "break-word"}}
        onClick={() => {
            if (isEnabled && panel) {
                openModal(panel.id, testName, panel.selfEvaluation)
            }
        }}
        onKeyPress={() => {
            if (isEnabled && panel) {
                openModal(panel.id, testName, panel.selfEvaluation)
            }
        }}
    >
        <div className={imageClass} tabIndex={0}>
            {!isHandwritten ? (
                <img src={imageUrl} alt="" />
            ) : (
                <div className="dashboard__skills__skill-container__skill__handwritten">
                    Ask Your Teacher/Proctor for the Writing Prompts
                </div>
            )}
        </div>
        {isComplete && is4se && (
            <div className={completedSkill}>
                <div className="dashboard__skills__skill-container__completed-skill__circle">
                    <i
                        className={
                            "material-icons " +
                            "dashboard__skills__skill-container__completed-skill__circle__4se_check"
                        }
                    >
                        done
                    </i>
                </div>
            </div>
        )}

        {isComplete && !is4se && (
            <div className={completedSkill}>
                <img
                    src={CHECKMARK}
                    className="dashboard__skills__skill-container__completed-skill__check"
                />
            </div>
        )}
        {testName === "Listening" && panelGraph.productId === STAMP_4S_PRODUCT_ID.value() ? (
            <>
                <H4Text className="dashboard__skills__skill-container__title" > { isSelfEvaluated ? 'Listening Self Evaluation' :'Listening'}</H4Text>
            </>
        ):testName === "Reading" && panelGraph.productId === STAMP_4S_PRODUCT_ID.value() ? (
            <>
                <H4Text className="dashboard__skills__skill-container__title"> { isSelfEvaluated ? 'Reading Self Evaluation' :'Reading'}</H4Text>
            </>
        ) : testName === "Listening" && panelGraph.productId === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value() ? (
            <>
                <H4Text className="dashboard__skills__skill-container__title"> { isSelfEvaluated ? 'Receptive Self Evaluation' :'Receptive'}</H4Text>
            </>
        ) : testName === "Speaking" && panelGraph.productId === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value() ? (
            <>
                <H4Text className="dashboard__skills__skill-container__title"> { isSelfEvaluated ? 'Expressive Self Evaluation' :'Expressive'}</H4Text>
            </>
        ) : (
            <H4Text className="dashboard__skills__skill-container__title">{testName}</H4Text>
        )}
        {!isEnabled &&
            !is4se &&
            !isComplete &&
            (testName === "Speaking" || testName === "Writing") &&
            //Ensure that when a test is in progress, the message only appears for tests that still haven't had their prerequisite met
            !completedPanelSkill.has(
                skill === PanelSkillEnum.WRITING ? PanelSkillEnum.READING : PanelSkillEnum.LISTENING
            ) && (
                <p className="dashboard__skills__skill-container__completed-progress__text">
                    {mustCompleteExamText(testName, panelGraph.productId)}
                </p>
            )}
        {isComplete && (
            <div className="dashboard__skills__skill-container__completed-progress">
                <div className="dashboard__skills__skill-container__completed-progress__progress" />
                <p className="dashboard__skills__skill-container__completed-progress__text__opaque">
                    100% Completed
                </p>
            </div>
        )}
    </div>
  
)
    
}

export default RenderSkill;

