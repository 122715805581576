/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import { createStyles, Theme } from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { AvantColors, REPORT_HASH_COLORS, REPORT_HASH_FILL_COLORS } from "../../../../../../styles/AvantColors"
import { ThreeStriationSkillTriangleBackground } from "./ThreeStriationSkillTriangleBackground"
import { TwoStriationSkillTriangleBackground } from "./TwoStriationSkillTriangleBackground"
import SkillTriangleUtils from "./SkillTriangleUtils"
import { HashPattern } from "../HashPattern"
import { productStore } from "../../../../../common/products/ProductStore"
import { PanelSkillFriendlyNameEnum, ProductId } from "../../../../../../types/types"
import { FourStriationSkillTriangleBackground } from "./FourStriationSkillTriangleBackground";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        svg_path: {
            fill: "#FFF",
            stroke: "#FFF"
        },
        svg_outline: {
            fill: "none",
            stroke: "#046400",
            strokeWidth: 4
        },
        triangle_Container: {
            width: "100%",
            border: "1px solid white",
            background: AvantColors.REPORT_FONT_COLOR_WHITE
        },
        triangleYellowSection: {
            fill: "#FFE07D",
            stroke: "#FFE07D",
            strokeWidth: 2,
            strokeLinejoin: "round"
        },
        triangleGreenSection: {
            fill: AvantColors.INTERMEDIATE_FILL,
            stroke: AvantColors.INTERMEDIATE_FILL,
            strokeWidth: 2,
            strokeLinejoin: "round"
        },
        triangleBlueSection: {
            fill: AvantColors.ADVANCED_FILL,
            stroke: AvantColors.ADVANCED_FILL,
            strokeWidth: 2,
            strokeLinejoin: "round"
        },
        trianglePurpleSection: {
            fill: AvantColors.SUPERIOR_FILL,
            stroke: AvantColors.SUPERIOR_FILL,
            strokeWidth: 2,
            strokeLinejoin: "round"
        },

        triangleGradientSection: {
            strokeWidth: 2,
            strokeLinejoin: "round"
        },
        triangleGradientGraySection: {
            stroke: AvantColors.avantLightGrey
        },
        triangleGradientYellowSection: {
            stroke: REPORT_HASH_COLORS.YELLOW,
        },
        triangleGradientGreenSection: {
            stroke: REPORT_HASH_COLORS.GREEN,
        },
        triangleGradientBlueSection: {
            stroke: REPORT_HASH_COLORS.BLUE,
        },
        triangleGradientPurpleSection: {
            stroke: REPORT_HASH_COLORS.PURPLE,
        },
        /* EEE9F6*/

        triangleGradientTransparent: {
            stroke: "transparent"
        },
        triangleOutline: {
            fill: "transparent",
            stroke: AvantColors.REPORT_FONT_COLOR_GRAY_3,
            strokeWidth: 1,
            strokeLinejoin: "round"
        },
        imageText: {
            fontFamily: "sans-serif",
            fontSize: "24px",
            fill: AvantColors.REPORT_FONT_COLOR_GRAY_3
        },
        inTriangleLevelLinesBlue: {
            // Dark blue highlight
            stroke: "#0035A6",
            strokeWidth: 1
        },
        inTriangleLevelLinesGreen: {
            // Dark Green Highlight
            stroke: "#007E31",
            strokeWidth: 1
        },
        backgroundLines: {
            // Grey Background lines
            stroke: AvantColors.REPORT_FONT_COLOR_GRAY_3,
            strokeWidth: 1
        },
        backgroundRect: {
            fill: "#FAFAFA"
        }

    })
)

type Stamp4SESkillTriangleProps = {
    numberOfLevels: number
    avantLevel: number
    avantMaxLevel: number
    arrayOfLevels: string[]
    skill: string
}

export interface Coords {
    txt: string
    x: number
    y: number
}

export const StampSkillTriangle: React.FC<Stamp4SESkillTriangleProps> = ({ avantMaxLevel, avantLevel, numberOfLevels, arrayOfLevels, skill }) => {
    const grayHashId = "stamp4sHashPatternGray"
    const yellowHashId = "stamp4SHashPatternYellow"
    const greenHashId = "stamp4SHashPatternGreen"
    const blueHashId = "stamp4SHashPatternBlue"
    const purpleHashId = "stamp4SHashPattenPurple"

    const cefrLevelArray = (length: number) => {
        const levels = ["PA1", "A1", "A2", "B1", "B2", "C1"];
        return levels.slice(0, length);
    };

    const isStamp4Se: boolean = productStore.loginProduct!.productId === ProductId.STAMP4SE
    const isStampCefr: boolean = productStore.loginProduct!.productId === ProductId.STAMP_CEFR
    const isComputerGraded: boolean = skill === PanelSkillFriendlyNameEnum.READING.valueOf() || skill === PanelSkillFriendlyNameEnum.LISTENING.valueOf()

    const getFill = (maxLevel: number): string => {
        if (avantLevel === 0 && maxLevel === 1) {
            return `url(#${grayHashId})`
        } if (isStampCefr && maxLevel === 6) {
            return `url(#${blueHashId}`
        } else if (maxLevel > 0 && maxLevel <= 3 && avantLevel !== 0) {
            return `url(#${yellowHashId})`
        } else if (maxLevel > 3 && maxLevel <= 6 && avantLevel !== 0) {
            return `url(#${greenHashId})`
        } else if (maxLevel > 6 && maxLevel <= 9 && avantLevel !== 0) {
            return `url(#${blueHashId})`
        } else if (maxLevel > 9 && avantLevel !== 0) {
            return `url(#${purpleHashId})`
        } else {
            return "transparent"
        }
    }

    const classes = useStyles()

    const getClassForLevelTriangle = () => {
        if (isStamp4Se && isComputerGraded && avantLevel > 3) {
            return classes.triangleGreenSection
        } else if (avantLevel > 0 && avantLevel <= 3) {
            return classes.triangleYellowSection
        } else if (isStampCefr && avantLevel <= 3) {
            return classes.triangleYellowSection
        } else if (isStampCefr && avantLevel > 3 && avantLevel <= 5) {
            return classes.triangleGreenSection
        } else if (isStampCefr && avantLevel === 6) {
            return classes.triangleBlueSection
        } else if (avantLevel > 3 && avantLevel <= 6) {
            return classes.triangleGreenSection
        } else if (avantLevel > 6 && avantLevel <= 9) {
            return classes.triangleBlueSection
        } else if (avantLevel > 9) {
            return classes.trianglePurpleSection
        } else {
            return ""
        }
    }

    const getClassForMaxLevelTriangle = () => {
        if (avantLevel === 0 && avantMaxLevel === 0) {
            return ""
        } else if (avantLevel === 0 && avantMaxLevel === 1) {
            return classes.triangleGradientSection + " " + classes.triangleGradientGraySection
        } else if (avantMaxLevel > 0 && avantMaxLevel <= 3) {
            return classes.triangleGradientSection + " " + classes.triangleGradientYellowSection
        } else if (avantMaxLevel > 3 && avantMaxLevel <= 6) {
            return classes.triangleGradientSection + " " + classes.triangleGradientGreenSection
        } else if (isStampCefr && avantMaxLevel === 6) {
            return classes.triangleGradientSection + " " + classes.triangleGradientBlueSection
        } else if (avantMaxLevel > 6 && avantMaxLevel <= 9) {
            return classes.triangleGradientSection + " " + classes.triangleGradientBlueSection
        } else if (avantMaxLevel > 9) {
            return classes.triangleGradientSection + " " + classes.triangleGradientPurpleSection
        } else {
            return ""
        }

    }

    const inTriangleLevelLinesClasses =classNames({
        [classes.inTriangleLevelLinesBlue]: avantLevel > 6,
        [classes.inTriangleLevelLinesGreen]: avantLevel > 3 && avantLevel <= 6,
        [classes.backgroundLines]: !(avantLevel > 6) && !(avantLevel > 3 && avantLevel <= 6)
    })

    return (
        <svg viewBox={"0 54 625 354"} height={"175px"}>
            <defs>
                {/* The pattern is the stripes for the SVG*/}
                <HashPattern
                    id={grayHashId}
                    stroke={AvantColors.avantDarkGrey}
                    fillColor={AvantColors.REPORT_BACKGROUND_GRAY_DARK}
                />
                <HashPattern
                    id={yellowHashId}
                    stroke={REPORT_HASH_COLORS.YELLOW}
                    fillColor={REPORT_HASH_FILL_COLORS.YELLOW}
                />
                <HashPattern
                    id={greenHashId}
                    stroke={REPORT_HASH_COLORS.GREEN}
                    fillColor={REPORT_HASH_FILL_COLORS.GREEN}
                />
                <HashPattern
                    id={blueHashId}
                    stroke={REPORT_HASH_COLORS.BLUE}
                    fillColor={REPORT_HASH_FILL_COLORS.BLUE}
                />
                <HashPattern
                    id={purpleHashId}
                    stroke={REPORT_HASH_COLORS.PURPLE}
                    fillColor={REPORT_HASH_FILL_COLORS.PURPLE}
                />
            </defs>

            {(arrayOfLevels.length <= 7) && (<TwoStriationSkillTriangleBackground/>)}
            {(arrayOfLevels.length > 7) && (arrayOfLevels.length <=9) && (<ThreeStriationSkillTriangleBackground levelCount={numberOfLevels}/>)}
            {(arrayOfLevels.length > 9 ) && (<FourStriationSkillTriangleBackground levelCount={numberOfLevels}/>)}

            {/* High Score Triangle */}
            <path
                d={SkillTriangleUtils.getPathPointsInset(SkillTriangleUtils.createScale(avantMaxLevel,arrayOfLevels.length),1)}
                id={"highScore"}
                fill={getFill(avantMaxLevel)}
                strokeLinejoin={"round"}
                strokeOpacity={0}
                className={getClassForMaxLevelTriangle()}
                transform={isStampCefr ? "translate(270 400)" : "translate(300 400)"}
            />

            {/* This is the outline Triangle */}
            <path
                d={SkillTriangleUtils.getPathPoints(1)}
                className={classes.triangleOutline}
                id={"outlineTriangle"}
                strokeLinejoin={"round"}
                transform={isStampCefr ? "translate(270 400)" : "translate(300 400)"}
            />

            {/* Actual score triangle, top layer */}
            <path
                d={SkillTriangleUtils.getPathPointsInset(SkillTriangleUtils.createScale(avantLevel, arrayOfLevels.length), 1)}
                id={"scoreTriangle"}
                strokeLinejoin={"round"}
                strokeOpacity={0}
                className={getClassForLevelTriangle()}
                transform={isStampCefr ? "translate(270 400)" : "translate(300 400)"}
            />
            {/* {// This fills in the numbers for the levels */}
            {(isStampCefr) ? (
                SkillTriangleUtils.getLevelNumbers(cefrLevelArray(numberOfLevels)).map((coord: Coords, index: number) => {
                    let x = coord.x
                    //check to see if we have a 6+ which aligns correctly and 10 which has to be fixed.
                    if (coord.txt && coord.txt.length > 1 && !isNaN(Number(coord.txt))) {
                        x = x - 14
                    }
                    let triangleLabelYOffset = 30;
                    if (arrayOfLevels.length > 9) {
                        //fix so that the number are not so close to the lines for the level 10 background.
                        triangleLabelYOffset = 24;
                    }
                    return (
                        <text
                            key={`${index}-${coord.txt}`}
                            x={x - 20}
                            y={coord.y + triangleLabelYOffset}
                            className={classes.imageText}
                            transform={"translate(250 400)"}
                        >
                            {coord.txt}
                        </text>
                    )
                })
            ) : (
                SkillTriangleUtils.getLevelNumbers(arrayOfLevels).map((coord: Coords, index: number) => {
                    let x = coord.x
                    //check to see if we have a 6+ which aligns correctly and 10 which has to be fixed.
                    if (coord.txt && coord.txt.length > 1 && !isNaN(Number(coord.txt))) {
                        x = x - 14
                    }
                    let triangleLabelYOffset = 30;
                    if (arrayOfLevels.length > 9) {
                        //fix so that the number are not so close to the lines for the level 10 background.
                        triangleLabelYOffset = 24;
                    }
                    return (
                        <text
                            key={`${index}-${coord.txt}`}
                            x={x - 20}

                            y={coord.y + triangleLabelYOffset}
                            className={classes.imageText}
                            transform={"translate(300 400)"}
                        >
                            {coord.txt}
                        </text>
                    )
                })
            )
            }
            {/* These are the line inside the triangles it should only be redraw when it is covered by a triangle*/}
            {(arrayOfLevels.length > 9 && (avantLevel > 9 || avantMaxLevel > 9)) && (
                <path
                    d={SkillTriangleUtils.getTriangleStriationPoints(SkillTriangleUtils.createScale(9, arrayOfLevels.length))}
                    transform={"translate(300 400)"}
                    className={inTriangleLevelLinesClasses}
                />)}
            {(arrayOfLevels.length > 6 && (avantLevel > 6 || avantMaxLevel > 6)) && (
                <path
                    d={SkillTriangleUtils.getTriangleStriationPoints(SkillTriangleUtils.createScale(6, arrayOfLevels.length))}
                    transform={"translate(300 400)"}
                    className={inTriangleLevelLinesClasses}
                />)}
            <path
                d={SkillTriangleUtils.getTriangleStriationPoints(SkillTriangleUtils.createScale(isStampCefr ? 0 : 3, arrayOfLevels.length))}
                transform={isStampCefr ? "translate(270 425)" : "translate(300 400)"}
                className={inTriangleLevelLinesClasses}
            />
            Sorry, your browser does not support inline SVG.
        </svg>
    );
}
