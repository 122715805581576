import {observable} from "mobx"
import ApiService from "../../services/ApiService"
import {AxiosResponse} from "axios"

export enum RosterState {
    NOT,
    IN_PROGRESS,
    COMPLETED,
    ERROR
}

const PROCTOR_TYPE = "examity"

export class RosterStore {

    @observable
    rosterState = RosterState.NOT

    @observable
    rosteringError = ""

    @observable
    redirectUrl?: string

    roster(loginId: number, parentEmail: string, studentEmail: string, firstName: string, lastName: string) {
        this.rosterState = RosterState.IN_PROGRESS
        const url: string = `${ApiService.API_URL}roster/${loginId}`
        ApiService.addInteractionBlockingRequest(ApiService.post(url, {
            parentEmail,
            studentEmail,
            firstName,
            lastName,
            ndaAgreement: false,
            proctorType: PROCTOR_TYPE
        })).then(
            () => {
                this.rosterState = RosterState.COMPLETED
            },
            (err) => {
                this.rosterState = RosterState.ERROR
                if (err.response.data.errors[0]) {
                    this.rosteringError = err.response.data.errors[0]
                }
            }
        )
    }

    rosterWithExamity(loginId: number, email: string, firstName: string, lastName: string) {
        this.rosterState = RosterState.IN_PROGRESS
        const url: string = `${ApiService.API_URL}examity_registration/${loginId}`

        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.post(url, {
                email,
                firstName,
                lastName
            })).then(
                (response: AxiosResponse) => {
                    this.rosterState = RosterState.COMPLETED
                    resolve(response.data.message)
                },
                (err) => {
                    this.rosterState = RosterState.ERROR
                    if (err.response.data.errors[0]) {
                        this.rosteringError = err.response.data.errors[0]
                    }
                    reject(err)
                }
            )
        })
    }
}

export const rosterStore = new RosterStore()
