import {observer} from "mobx-react"
import * as React from "react"
import {groupMasterStore} from "../../../common/group/GroupMasterStore"
import {AvantCircularProgress, CircularProgressSize} from "../../../common/loaders/AvantCircularProgress"
import {AvantPopperMenuItem} from "../../../generic-components/AvantPopperMenu"
import {ReportsPrintButton} from "../../common/components/ReportsPrintButton"
import {ReportFilters} from "../../common/models/ReportFilters"
import {ReportSubPaths} from "../../ReportSubPaths"


export const DistrictReportsPrintMenu: React.FC<{ filters: ReportFilters }> = observer(({filters}) => {

    const groupInfo = groupMasterStore.groupInfo
    if (groupInfo == null) {
        return <AvantCircularProgress size={CircularProgressSize.SMALL}/>
    }

    const menuContents: AvantPopperMenuItem[] = [
        {
            name: "Summary & Locations",
            link: ReportSubPaths.districtPrintAll(filters)
        },
        {
            name: "Summary Only",
            link: ReportSubPaths.districtPrintSummary(filters)
        },
        {
            name: "Locations Only",
            link: ReportSubPaths.districtPrintLocations(filters)
        }
    ]

    return <ReportsPrintButton contents={menuContents} />
})
