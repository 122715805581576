import {isEmpty} from "./ArrayUtil"

export function average(a: number[]) {
    if (isEmpty(a)) {
        throw new Error("Cannot calculate average of empty array")
    }

    let sum = 0
    for (const val of a) {
        sum = sum + val
    }
    return sum / a.length
}
