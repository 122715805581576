import {orderBy} from "lodash"
import {observable} from "mobx"
import {SortDirection} from "../../../../../types/types"
import {ClassInfoRow} from "../../models/ClassInfoRow"
import {GroupReportRow, GroupSortName} from "../../models/GroupReportRow"
import {SchoolInfoRow} from "../../models/SchoolInfoRow"
import {reportsStore} from "../../../common/stores/ReportStore"
import {TimeUtil} from "../../../../../util/TimeUtil"

export class DistrictOrSchoolTableStore<T extends GroupReportRow> {

    @observable
    activeTableSort: GroupSortName = "name"
    @observable
    tableSortDir: SortDirection = "desc"
    @observable
    rows: T[] = []

    resort = () => {
        const groupsWithData = reportsStore.groupsWithData
        let sortDir: SortDirection = "asc"
        if (this.activeTableSort === "dataAvailable") {
            if (this.tableSortDir === "desc") {
                sortDir = "desc"
            }
        } else {
            if (this.tableSortDir === "asc") {
                sortDir = "desc"
            }
        }
        this.rows = orderBy(this.rows.slice(), [row => {
            let value = row[this.activeTableSort]
            if (typeof value === "string") {
                value = value.toLowerCase()
            } else if (typeof value === "boolean") {
                if (groupsWithData != null) {
                    if (groupsWithData.includes(row.id)) {
                        value = true
                    } else {
                        value = false
                    }
                }

            } else if (typeof value === "number") {
                // Since we only show Month Day Year, we need to compare only those parts of the date values
                value = TimeUtil.convertMilliMonthDayYearHourMinuteToMilliMonthDayYear(value)
            }
            return value
        }, row => row.name.toLowerCase()], [sortDir, "asc"])
    }
}

export const districtTableStore = new DistrictOrSchoolTableStore<SchoolInfoRow>()
export const schoolTableStore = new DistrictOrSchoolTableStore<ClassInfoRow>()
