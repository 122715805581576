import React from 'react'
import {createStyles, makeStyles, Theme} from "@material-ui/core"
import { AvantColors } from "../../../../../../styles/AvantColors"
import SkillTriangleUtils, { SKILL_TRIANGLE_ORIGIN_X} from "./SkillTriangleUtils"
//OVERALL_DISPLAY_HEIGHT

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backgroundLines: {
            // Grey Background lines
            stroke: AvantColors.REPORT_FONT_COLOR_GRAY_3,
            strokeWidth: 1
        },
        backgroundRect: {
            fill: "#FAFAFA",
        },
        imageText: {
            fontFamily: "sans-serif",
            fontSize: "24px",
            fill: AvantColors.REPORT_FONT_COLOR_GRAY_3,
        },
    }),
)

interface FourStriationSkillTriangleBackgroundProps {
    levelCount: number
}

const OFFSET_FROM_PYRAMID = 8
/**
 * This reverse the level because this grows in the different direction from the triangle
 * @param level
 * @param levelCount
 */
function getYOffset(level: number,levelCount:number): number {
    const levelRatio = 1/levelCount
    return Math.abs(SkillTriangleUtils.getStriationY((2+levelCount-level)*levelRatio))-OFFSET_FROM_PYRAMID
}

export const FourStriationSkillTriangleBackground: React.FC<FourStriationSkillTriangleBackgroundProps> = ({levelCount}) => {

    const classes = useStyles()
    const TEXT_Y_OFFSET = 30

    return (
        <>
            <rect
                id="advanced-range-striation-box"
                x={SKILL_TRIANGLE_ORIGIN_X}
                y={getYOffset(9,levelCount)}
                width="100%"
                height={getYOffset(6,levelCount) - getYOffset(9,levelCount)}
                className={classes.backgroundRect}
            />
            <rect
                id="novice-range-striation-box"
                x={SKILL_TRIANGLE_ORIGIN_X}
                y={getYOffset(3,levelCount)}
                height={getYOffset(3,levelCount)}
                width="100%"
                className={classes.backgroundRect}

            />

            <text
            id="superior-level-area-text"
            x={10}
            y={getYOffset(10,levelCount) + TEXT_Y_OFFSET - 6}
            className={classes.imageText}
            >
                SUP
            </text>

            <text
                id="advanced-level-area-text"
                x={10}
                y={getYOffset(8,levelCount) + TEXT_Y_OFFSET}
                className={classes.imageText}
            >
                ADV
            </text>
            <text
                id="intermediate-level-area-text"
                x={10}
                y={getYOffset(5,levelCount) + TEXT_Y_OFFSET}
                className={classes.imageText}
            >
                INT
            </text>
            <text
                id="novice-level-area-text"
                x={10}
                y={getYOffset(2,levelCount) + TEXT_Y_OFFSET}
                className={classes.imageText}
            >
                NOV
            </text>
            <line
                id={"advances-level-striation-top-border-line"}
                x1={SKILL_TRIANGLE_ORIGIN_X}
                y1={"55"}
                x2={"100%"}
                y2={"55"}
                className={classes.backgroundLines}
            />
            <line
                id={"level-striation-at-nine"}
                x1={SKILL_TRIANGLE_ORIGIN_X}
                y1={getYOffset(9,levelCount)}
                x2={"100%"}
                y2={getYOffset(9,levelCount)}
                className={classes.backgroundLines}
            />
            <line
                id={"level-striation-at-six"}
                x1={SKILL_TRIANGLE_ORIGIN_X}
                y1={getYOffset(6,levelCount)}
                x2={"100%"}
                y2={getYOffset(6,levelCount)}
                className={classes.backgroundLines}
            />
            <line
                id={"level-striation-at-three"}
                x1={SKILL_TRIANGLE_ORIGIN_X}
                y1={getYOffset(3,levelCount)}
                x2={"100%"}
                y2={getYOffset(3,levelCount)}
                className={classes.backgroundLines}
            />
            <line
                id={"novice-level-striation-bottom-border-line"}
                x1={SKILL_TRIANGLE_ORIGIN_X}
                y1={"405"}
                x2={"100%"}
                y2={"405"}
                className={classes.backgroundLines}
            />


        </>
    )


}
