import {observer} from "mobx-react"
import * as React from "react"
import {ReactInstance, useRef, useState} from "react"
import ReactToPrint from "react-to-print"
import {Brand} from "../../../../components/Brand/Brand"
import {theme} from "../../../../styles/MuiThemes"
import {groupMasterStore} from "../../../common/group/GroupMasterStore"
import {AvantCircularProgress, CircularProgressSize} from "../../../common/loaders/AvantCircularProgress"
import {productStore} from "../../../common/products/ProductStore"
import {takeSearchStore} from "../../../common/take/TakeSearchStore"
import {EmptyComponent} from "../../../generic-components/EmptyComponent"
import {ClassReportView} from "../../class/components/ClassReportPage"
import {DistrictOrSchoolReportView} from "../../district-school/components/DistrictOrSchoolReportPage"
import {BlueReportType} from "../models/BlueReportType"
import {GenericReportContainerProps} from "../models/GenericReportProps"
import {reportsStore} from "../stores/ReportStore"
import {RedirectToReport} from "./FindReportIdOrRedirect"
import {PrintButtonsContainer, PrintViewButton} from "./PrintViewButton"
import {PLACEReportPage, placeReportStore} from "../../PlaceFamily/PLACEReportPage"
import {PLACE_PRODUCT_ID} from "../../../../util/Constants"

export enum PrintOptions {
    ALL,
    SUMMARY,
    LIST
}

interface PrintViewProps extends GenericReportContainerProps {
    printOptions: PrintOptions
    report: BlueReportType
}


export const PrintViewContainer: React.FC<PrintViewProps> = observer((props) => {
    const {filters, printOptions, report} = props

    const ele: ReactInstance = new EmptyComponent({})

    // tslint:disable-next-line:no-any
    const componentRef = useRef<any>(ele)
    const [cancel, setCancel] = useState(false)

    if (filters.productId === PLACE_PRODUCT_ID.value()) {
        if (placeReportStore.ReportList.length === 0) {
            return <RedirectToReport/>
        }
    } else if (takeSearchStore.instances.length === 0 && reportsStore.reportGraphData == null) {
        return <RedirectToReport/>
    }

    if (cancel) {
        return <RedirectToReport reportFilters={filters} blueReportType={BlueReportType[report]}/>
    }
    const product = productStore.loginProduct
    const productName = (product) ? product.productName : ""

    const groupInfo = groupMasterStore.groupInfo
    if (groupInfo == null) {
        return <AvantCircularProgress size={CircularProgressSize.SMALL}/>
    }
    return (
        <>
            <PrintButtonsContainer>
                <PrintViewButton
                    onClick={() => setCancel(true)}
                >
                    Cancel
                </PrintViewButton>
                <ReactToPrint
                    trigger={() => <PrintViewButton>Print</PrintViewButton>}
                    content={() => componentRef.current ? componentRef.current : ele}
                    pageStyle={""}
                />
            </PrintButtonsContainer>

            <div ref={componentRef}>
                <div
                    style={{
                        position: "absolute",
                        top: theme.spacing(2),
                        right: theme.spacing(2)
                    }}
                >
                    <Brand appName={productName}/>
                </div>
                {(report === BlueReportType.DISTRICT || report === BlueReportType.SCHOOL) && (
                    <DistrictOrSchoolReportView
                        graphData={reportsStore.reportGraphData!}
                        groupInfo={groupInfo}
                        filters={filters}
                        district={report === BlueReportType.DISTRICT}
                        printOptions={printOptions}
                    />
                )}
                {report === BlueReportType.CLASS && (
                    <ClassReportView
                        tableData={reportsStore.reportTableData}
                        graphData={reportsStore.reportGraphData!}
                        groupInfo={groupInfo}
                        filters={filters}
                        printOptions={printOptions}
                    />
                )}
                {report === BlueReportType.PLACE && <PLACEReportPage printOptions={printOptions}/>}
            </div>

        </>
    )
})
