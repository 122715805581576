import {Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import FormGroup from "@material-ui/core/FormGroup"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import React from "react"
import {RouteComponentProps} from "react-router"
import {SearchItemResults} from "../../components/admin-portal/AdminHelper"
import ItemFormatSelectDropDown from "../../components/form-components/ItemFormatSelectDropDown/ItemFormatSelectDropDown"
import LanguageSelectDropDown from "../../components/form-components/LanguageSelectionDropDown/LanguageSelectDropDown"
import LevelSelectDropdown from "../../components/form-components/LevelSelectDropDown/LevelSelectDropDown"
import ProductSelectDropDown from "../../components/form-components/ProductSelectDropdown/ProductSelectDropDown"
import SkillSelectDropdown from "../../components/form-components/SkillSelectDropdown/SkillSelectDropdown"
import {ConfigurationService} from "../../services/ConfigurationService"
import {GenericHelper} from "../../services/GenericHelper"
import {theme} from "../../styles/MuiThemes"
import {ApiErrorResponse, IItem, ItemFormatEnum, TEvent, TFormSubmit} from "../../types/types"
import {ContentAreaId, ProductId} from "../../validation/ValidPrimaryKey"
import {authStore} from "../common/authentication/AuthStore"
import {AuthUser} from "../common/authentication/models/AuthUser"
import {MockedItem} from "../common/item/MockedItem"
import {AvantCircularProgress} from "../common/loaders/AvantCircularProgress"
import {messageStore} from "../common/messages/MessageStore"
import {MessageView} from "../common/messages/MessageView"
import {productStore} from "../common/products/ProductStore"

export class SearchItemsPage extends React.Component<RouteComponentProps, {}> {
    render() {
        if (!authStore.auth || !productStore.driver) {
            return <AvantCircularProgress/>
        }

        // Make sure we don't show that item menu.
        const conf = productStore.driver!!.config.TEST_ENGINE_CONFIG
        conf.showItemMenu = false
        return (
            <SearchItems
                authUser={authStore.auth!!}
            />
        )
    }
}

interface SearchItemsProps {
    authUser: AuthUser
}

interface SearchItemsState {
    items: IItem[]
    itemNameLike?: string
    itemsInContentAreaId?: ContentAreaId
    itemsInProductId?: ProductId
    itemsInPanelGraphName?: string
    itemsInPanelName?: string
    itemsOfFormat?: ItemFormatEnum
    itemsInSkill?: string,
    itemsInLevel?: string
    itemCount?: number
    currentPage: number
    pageSize: number
    filtersUpdated: boolean
}

class SearchItems extends React.Component<SearchItemsProps, SearchItemsState> {
    constructor(props: SearchItemsProps) {
        super(props)

        this.state = {
            items: [],
            itemNameLike: "",
            itemsInPanelGraphName: "",
            itemsInPanelName: "",
            currentPage: 0,
            pageSize: 50,
            filtersUpdated: false
        }
    }

    onSearch = (e: TFormSubmit) => {
        e.preventDefault()

        if (!this.state.itemsInProductId) {
            messageStore.setErrorMessage("Please choose a product")
            return
        }

        // We want to check if the filters have updated since the last search,
        // if so we set the current page to 1 (the page number is used to calculate the offset in the select query)
        if (this.state.filtersUpdated) {
            this.setState({currentPage: 0})
        }

        GenericHelper.adminHelper
            .searchItems(
                ConfigurationService.adminConfig.MEDIA_BASE_URL,
                this.state.currentPage,
                this.state.pageSize,
                this.state.itemsInProductId,
                this.state.itemsInContentAreaId,
                this.state.itemsInPanelGraphName,
                this.state.itemsInPanelName,
                this.state.itemsOfFormat,
                this.state.itemNameLike,
                this.state.itemsInSkill,
                this.state.itemsInLevel
            )
            .then(
                (results: SearchItemResults) => {
                    this.setState({
                        items: results.items,
                        itemCount: results.count,
                        filtersUpdated: false
                    })
                },
                (err: ApiErrorResponse) => {
                    throw Error(`Failed to get single item section by id. ${err}`)
                }
            )
    }

    handleChangePage = (e: any, page: number) => {
        this.setState({
            currentPage: page
        }, () => this.onSearch(e))
    }

    handleChangeRowsPerPage = (e: any) => {
        this.setState({
            pageSize: Number(e.target.value),
            currentPage: 0
        }, () => this.onSearch(e))
    }

    clearFilters = () => {
        this.setState({
            itemsInProductId: undefined,
            itemsInContentAreaId: undefined,
            itemsInPanelGraphName: "",
            itemsInPanelName: "",
            itemsOfFormat: undefined,
            itemNameLike: "",
            itemsInSkill: undefined,
            itemsInLevel: undefined
        })
    }

    renderControls = () => {
        const {itemCount, currentPage, pageSize} = this.state

        return (
            <TableHead>
                <TableRow>
                    <TablePagination
                        page={!itemCount ? 0 : currentPage}
                        count={!itemCount ? 0 : itemCount}
                        rowsPerPage={!pageSize ? 50 : pageSize}
                        rowsPerPageOptions={[25, 50, 75, 100]}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </TableRow>
            </TableHead>
        )
    }

    renderItems = () => {
        const { items } = this.state
        return (
            <TableBody>
                {items.map((item) => {
                    return (
                        <TableRow key={`mocked-item-${item.id}`}>
                            <TableCell>
                                <MockedItem
                                    authUser={this.props.authUser}
                                    item={item}
                                    embedded={true}
                                    productId={new ProductId(item.productId!!)}
                                />
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        )
    }

    render() {
        const formIsDisabled = this.state.itemsInProductId === undefined
        return (
            <>
                <div>
                    <MessageView/>
                    <Paper style={{width: 800, padding: theme.spacing(2)}}>
                        <form onSubmit={this.onSearch}>
                            <FormGroup>
                                <Grid container={true} spacing={2}>
                                    <Grid item={true} xs={12} sm={4}
                                          data-tst-id="product-select">
                                        <ProductSelectDropDown
                                            onProductChanged={(productId?: ProductId) =>
                                                this.setState(prevState => ({
                                                    itemsInProductId: productId,
                                                    filtersUpdated: !prevState.filtersUpdated && true
                                                }))
                                            }
                                            currentProduct={this.state.itemsInProductId}
                                            required={true}
                                            isContentViewer={true}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={12} sm={4}
                                          data-tst-id="language-select">
                                        <LanguageSelectDropDown
                                            onLanguageChanged={(contentAreaId) =>
                                                this.setState(prevState => ({
                                                    itemsInContentAreaId: contentAreaId,
                                                    filtersUpdated: !prevState.filtersUpdated && true
                                                }))
                                            }
                                            currentLanguage={this.state.itemsInContentAreaId}
                                            disabled={formIsDisabled}
                                        />

                                    </Grid>
                                    <Grid item={true} xs={12} sm={4}
                                          data-tst-id="item-format-select">
                                        <ItemFormatSelectDropDown
                                            onItemFormatChanged={(format) =>
                                                this.setState(prevState => ({
                                                    itemsOfFormat: format,
                                                    filtersUpdated: !prevState.filtersUpdated && true
                                                }))
                                            }
                                            currentItemFormat={this.state.itemsOfFormat}
                                            disabled={formIsDisabled}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={12} sm={4}
                                          data-tst-id="skill-select">
                                        <SkillSelectDropdown
                                            onSkillChanged={(skill) =>
                                                this.setState(prevState => ({
                                                    itemsInSkill: skill,
                                                    filtersUpdated: !prevState.filtersUpdated && true
                                                }))
                                            }
                                            currentSkill={this.state.itemsInSkill}
                                            disabled={formIsDisabled}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={12} sm={4}
                                          data-tst-id="level-select">
                                        <LevelSelectDropdown
                                            onLevelChanged={(level) =>
                                                this.setState(prevState => ({
                                                    itemsInLevel: level,
                                                    filtersUpdated: !prevState.filtersUpdated && true
                                                }))
                                            }
                                            currentLevel={this.state.itemsInLevel}
                                            disabled={formIsDisabled}
                                        />
                                    </Grid>

                                    <Grid item={true} xs={12}>
                                        <Typography>
                                            Text searches, use % to do wildcard search
                                        </Typography>
                                    </Grid>


                                    <Grid item={true} xs={12}>
                                        <TextField
                                            id={"item-name-id"}
                                            label={"Item Name"}
                                            value={this.state.itemNameLike}
                                            data-tst-id="item-name"
                                            onChange={(e: TEvent) => this.setState({itemNameLike: e.target.value})}
                                            margin={"normal"}
                                            fullWidth={true}
                                            disabled={formIsDisabled}
                                        />

                                    </Grid>
                                    <Grid item={true} xs={12}>
                                        <TextField
                                            id="panel-graph-name-id"
                                            value={this.state.itemsInPanelGraphName}
                                            label="Panel Graph Name"
                                            data-tst-id="panelgraph-name"
                                            onChange={(e: TEvent) => this.setState({itemsInPanelGraphName: e.target.value})}
                                            margin={"normal"}
                                            fullWidth={true}
                                            disabled={formIsDisabled}
                                        />

                                    </Grid>

                                    <Grid item={true} xs={12}>
                                        <TextField
                                            id="panel-name-id"
                                            value={this.state.itemsInPanelName}
                                            label="Panel Name"
                                            data-tst-id="panel-name"
                                            onChange={(e: TEvent) => this.setState({itemsInPanelName: e.target.value})}
                                            margin={"normal"}
                                            fullWidth={true}
                                            disabled={formIsDisabled}
                                        />

                                    </Grid>

                                    <Grid item={true} xs={8} style={{textAlign: "left"}}>
                                        {
                                            (this.state.itemCount || this.state.itemCount === 0) ?
                                            <Typography variant={"h4"}>Results: {this.state.itemCount}</Typography> :
                                            null
                                        }
                                    </Grid>

                                    <Grid item={true} xs={2}>
                                        <Button type={"button"} variant={"contained"} color={"default"} onClick={() => this.clearFilters()}>
                                            Clear
                                        </Button>
                                    </Grid>

                                    <Grid item={true} xs={2}>
                                        <Button type={"submit"} variant={"contained"} color={"primary"}>
                                            Search
                                        </Button>
                                    </Grid>

                                </Grid>
                            </FormGroup>
                        </form>
                    </Paper>
                </div>
                <Table data-tst-id='results-table'>
                    <colgroup>
                        <col width="100%"/>
                    </colgroup>
                    {this.renderControls()}
                    {this.renderItems()}
                </Table>
            </>
        )
    }
}
