import {IItem, TLocalAnswer} from "../../types/types"
import {ItemContainerHelper} from "../ItemContainerHelper"

export class AdminItemContainerHelper extends ItemContainerHelper {
    answeredModalText: string = ""

    isAnswered(answer: TLocalAnswer | any): boolean {
        return true
    }

    getCbAnswerCountModalText(item: IItem): string {
        return ""
    }

    isValidCbAnswer(answer: any[], item: IItem): boolean {
        return true
    }
}
