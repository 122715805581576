import * as React from "react"
import {ReactInstance, useRef, useState} from "react"
import ReactToPrint from "react-to-print"
import {EmptyComponent} from "../../../generic-components/EmptyComponent"
import {RedirectToReport} from "../../common/components/FindReportIdOrRedirect"
import {ReportPrintBrand} from "../../common/components/ReportPrintBrand"
import {IndividualReportsContainer} from "./IndividualReportsPage"
import {reportsStore} from "../../common/stores/ReportStore"
import {ReportPrintFooter} from "../../common/components/ReportPrintFooter"
import {PrintButtonsContainer, PrintViewButton} from "../../common/components/PrintViewButton"
import {observer} from "mobx-react"
import {productStore} from "../../../common/products/ProductStore"
import {PLACE_PRODUCT_ID} from "../../../../util/Constants"
import {BlueReportType} from "../../common/models/BlueReportType"
import {PLACEIndividualReportDetails} from "../../PlaceFamily/PLACEIndividualReportPage"
import {findPLACEIndividualReport, placeReportStore} from "../../PlaceFamily/PLACEReportPage"
import {groupMasterStore} from "../../../common/group/GroupMasterStore";


interface IndividualReportPrintViewProps {
    studentIds: number[]
}

const IndividualReportPrintView: React.FC<IndividualReportPrintViewProps> = observer(({studentIds}) => {
    if (studentIds.length === 0) {
        return <RedirectToReport/>
    }

    let reportType = BlueReportType.CLASS.toString()

    if (productStore.loginProduct && productStore.loginProduct.productId === PLACE_PRODUCT_ID.value()) {
       reportType = BlueReportType.PLACE.toString()
    }

    const ele: ReactInstance = new EmptyComponent({})
    // tslint:disable-next-line:no-any
    const componentRef = useRef<any>(ele)

    const [cancel, setCancel] = useState(false)
    if (cancel) {
        return <RedirectToReport reportFilters={reportsStore.searchFilters} blueReportType={reportType}/>
    }

    return (
        <>
            <PrintButtonsContainer>
                <PrintViewButton
                    onClick={() => setCancel(true)}
                >
                    Cancel
                </PrintViewButton>
                <ReactToPrint
                    trigger={() => <PrintViewButton>Print</PrintViewButton>}
                    content={() => componentRef.current ? componentRef.current : ele}
                    pageStyle={"@media print {margin: 0.5in}"}
                />
            </PrintButtonsContainer>
            <div ref={componentRef} className="report_wrapper">
                <ReportPrintBrand/>
                {studentIds.map((id) => {
                    const reportData = JSON.parse(findPLACEIndividualReport(id))

                    return (
                        <div
                            key={id}
                            style={{
                                breakBefore: "page"
                            }}
                        >
                            {
                                reportType === BlueReportType.CLASS.toString() &&
                                <IndividualReportsContainer takeId={id} groupInfo={groupMasterStore.groupInfo} print={true} includeBrand={false}/>
                            }
                            {
                                reportType === BlueReportType.PLACE.toString() &&
                                    <PLACEIndividualReportDetails takeId={id} isLoading={false} reportData={reportData} print={true} includeBrand={false}/>
                            }

                            <ReportPrintFooter/>
                        </div>
                    )
                })}
            </div>
        </>
    )
})

export const IndividualPrintAll: React.FC = () => {
    const studentIds = productStore.loginProduct!!.productId === PLACE_PRODUCT_ID.value() ?
        placeReportStore.ReportList.map((report) => report.takeid) :
        (reportsStore.instancesData || []).map((take) => take.takeId)

    return <IndividualReportPrintView studentIds={studentIds}/>
}

export const IndividualPrintSelected: React.FC = () => (
    <IndividualReportPrintView studentIds={reportsStore.selectedTakeIds}/>
)
