import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core"
import * as React from "react"
import {useState} from "react"
import {theme} from "../../../../styles/MuiThemes"
import {LocalStorageKey, LocalStorageUtil} from "../../../../util/LocalStorageUtil"
import {AvantCircularProgress} from "../../../common/loaders/AvantCircularProgress"
import {RedirectToReport} from "../../common/components/FindReportIdOrRedirect"
import {useHandWrittenPrompts} from "../hooks/useHandWrittenPrompts"
import {WritingPrompts} from "./WritingPrompts"
import {reportsStore} from "../../common/stores/ReportStore"
import {productStore} from "../../../common/products/ProductStore"
import {PanelLevel, ProductId} from "../../../../types/types"
import {PrintPromptsDialogue} from "./PrintPromptsDialogue/PrintPromptsDialogue"
import {PrintButtonsContainer, PrintViewButton} from "../../common/components/PrintViewButton"

export enum PDFSize {
    A4 = "A4",
    LETTER = "LETTER"
}

export const nonPrintablePaths = [
    PanelLevel.PANEL_PENDING.valueOf(),
    PanelLevel.PNAEL_LEVEL_ERROR.valueOf(),
    PanelLevel.PANEL_SCORING_IN_PROGRESS.valueOf(),
    PanelLevel.PANEL_IN_PROGRESS.valueOf(),
    PanelLevel.PANEL_STARTED.valueOf(),
    PanelLevel.PANEL_NOT_STARTED.valueOf()
]

export const completedTestValues = [
    PanelLevel.PANEL_SCORING_IN_PROGRESS.valueOf(),
    PanelLevel.PANEL_IN_PROGRESS.valueOf(),
    PanelLevel.PANEL_STARTED.valueOf(),
    PanelLevel.PANEL_COMPLETE.valueOf(),
    PanelLevel.PNAEL_LEVEL_ERROR.valueOf(),
    PanelLevel.PANEL_LEVEL_1.valueOf(),
    PanelLevel.PANEL_LEVEL_2.valueOf(),
    PanelLevel.PANEL_LEVEL_3.valueOf(),
    PanelLevel.PANEL_LEVEL_4.valueOf(),
    PanelLevel.PANEL_LEVEL_5.valueOf(),
    PanelLevel.PANEL_LEVEL_6.valueOf(),
    PanelLevel.PANEL_LEVEL_7.valueOf(),
    PanelLevel.PANEL_LEVEL_8.valueOf(),
    PanelLevel.PANEL_LEVEL_9.valueOf(),
    PanelLevel.PANEL_LEVEL_10.valueOf(),
    PanelLevel.PANEL_LEVEL_1_MINUS.valueOf(),
    PanelLevel.PANEL_LEVEL_2_MINUS.valueOf(),
    PanelLevel.PANEL_LEVEL_3_MINUS.valueOf(),
    PanelLevel.PANEL_LEVEL_4_MINUS.valueOf(),
    PanelLevel.PANEL_LEVEL_5_MINUS.valueOf(),
    PanelLevel.PANEL_LEVEL_6_MINUS.valueOf(),
    PanelLevel.PANEL_LEVEL_7_MINUS.valueOf(),
    PanelLevel.PANEL_LEVEL_8_MINUS.valueOf(),
    PanelLevel.PANEL_LEVEL_9_MINUS.valueOf(),
    PanelLevel.PANEL_LEVEL_10_MINUS.valueOf()
]

export const PromptsPrintView: React.FC<{ takeIds: number[], productId: ProductId }> = ({takeIds, productId}) => {
    const initialSize = LocalStorageUtil.findInLocalStorage(LocalStorageKey.PDF_PAGE_SIZE) as PDFSize || PDFSize.LETTER
    const [size, setSize] = useState<PDFSize>(initialSize)
    const {loading, promptData} = useHandWrittenPrompts(takeIds)
    const [cancel, setCancel] = useState(false)

    const handleChange = (event: React.ChangeEvent<unknown>) => {
        const pageSize = (event.target as HTMLInputElement).value as unknown as PDFSize
        setSize(pageSize)
        LocalStorageUtil.setLocalStorage(LocalStorageKey.PDF_PAGE_SIZE, pageSize)
    }

    if (reportsStore.reportTableData && reportsStore.reportTableData.reportTakeRows.length === 0) {
        return <RedirectToReport/>
    }

    if (cancel) {
        return <RedirectToReport reportFilters={reportsStore.searchFilters} blueReportType={"CLASS"}/>
    }

    if (loading) {
        return <AvantCircularProgress/>
    }

    return (
        <div style={{height: "100vh", minWidth: "1024px"}}>
            <PrintButtonsContainer>
                <PrintViewButton onClick={() => setCancel(true)}>Cancel</PrintViewButton>
            </PrintButtonsContainer>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 96
            }}>
                <FormControl component="fieldset" margin={"none"} style={{width: 184, margin: theme.spacing(3, 3, 1)}}>
                    <FormLabel component="legend" style={{marginBottom: theme.spacing(1)}}>Page Size</FormLabel>
                    <RadioGroup
                        aria-label="pageSize"
                        name="pageSize"
                        value={size}
                        onChange={handleChange}
                        style={{display: "flex", flexDirection: "row"}}
                    >
                        <FormControlLabel value={PDFSize.LETTER} control={<Radio/>} label={PDFSize.LETTER}/>
                        <FormControlLabel value={PDFSize.A4} control={<Radio/>} label={PDFSize.A4}/>
                    </RadioGroup>
                </FormControl>
            </div>
            <WritingPrompts prompts={promptData ? promptData : []} size={size} product={productId}/>
        </div>
    )
}

export const PromptsPrintAll: React.FC = () => {

    const [modalShown, setModalShown] = React.useState(false)

    const printingModalData = getNonPrintablePromptsDialogData(true)

    const productId = productStore.loginProduct!.productId
    const productDependsOnReading = productDependantOnReading(productId)

    if ((productDependsOnReading) && !modalShown &&
        ((printingModalData.takeIdsPrintable.length !== reportsStore.reportTableData!.reportTakeRows.length)
            || (printingModalData.takeIdsNotPrintable.length !== 0)
            || (printingModalData.takeIdsComplete.length !== 0)
            || (printingModalData.takesNotHandwritten))
    ) {
        return <PrintPromptsDialogue modalOpen={true} setModalShown={setModalShown} allTesttakers={true}/>
    }

    if (!productDependsOnReading && !modalShown
        && (printingModalData.takeIdsComplete.length !== 0
            || printingModalData.takesNotHandwritten.length > 0)
    ) {
        return <PrintPromptsDialogue modalOpen={true} setModalShown={setModalShown} allTesttakers={true}/>
    }

    return <PromptsPrintView takeIds={printingModalData.takeIdsPrintable} productId={productId}/>
}


export const PromptsPrintSelected = () => {
    const [modalShown, setModalShown] = React.useState(false)

    const printingModalData = getNonPrintablePromptsDialogData(false)
    const productId = productStore.loginProduct!.productId
    const productDependsOnReading = productDependantOnReading(productId)
    const testInstanceTableLength = reportsStore.selectedTakeIds!.length

    if (productDependsOnReading) {
        if (!modalShown &&
            (printingModalData.takeIdsPrintable.length !== testInstanceTableLength
                || printingModalData.takeIdsComplete.length > 0
                || printingModalData.takesNotHandwritten.length > 0
            )

        ) {
            return <PrintPromptsDialogue modalOpen={true} setModalShown={setModalShown} allTesttakers={false}/>
        }
    } else {
        if (!modalShown && (printingModalData.takeIdsComplete.length > 0 || printingModalData.takesNotHandwritten.length > 0)) {
            return <PrintPromptsDialogue modalOpen={true} setModalShown={setModalShown} allTesttakers={false}/>
        }
    }

    return <PromptsPrintView takeIds={printingModalData.takeIdsPrintable} productId={productId}/>

}

interface PromtsPrintStatus {
    takeIdsPrintable: number[]
    takeIdsComplete: number[]
    takeIdsNotPrintable: number[]
    takesNotHandwritten: number []
}

export const getNonPrintablePromptsDialogData = (printAll: boolean) : PromtsPrintStatus => {

    let takesThatNeedToCpmpleteReading: number[] = []
    let writngCompletedTakes: number[] = []
    let printableTakes: number[] = []

    if (reportsStore.reportTableData == null || productStore.loginProduct == null) {
        return {takeIdsPrintable: printableTakes, takeIdsComplete: writngCompletedTakes, takeIdsNotPrintable: takesThatNeedToCpmpleteReading, takesNotHandwritten: []}
    }

    const productDependsOnWriting = productDependantOnReading(productStore.loginProduct.productId)

    const testtakers = printAll
        ? reportsStore.reportTableData.reportTakeRows
        : reportsStore.reportTableData.reportTakeRows
            .filter(instance => reportsStore.selectedTakeIds.includes(instance.takeId))

    const nonHandwrittenTestTakers = testtakers.filter(instance => !instance.handwritten).map(instance => instance.takeId)

    writngCompletedTakes = testtakers
        .filter(instance => completedTestValues.includes(instance.writingScore!)).filter(instance => instance.handwritten)
        .map(instance => instance.takeId)

    if (productDependsOnWriting) {
        // Reading is complete and WR is Printable or Printed
        printableTakes = testtakers
            .filter(instance =>
                instance.handwritten &&
                !nonPrintablePaths.includes(instance.readingScore!) &&
                !completedTestValues.includes(instance.writingScore!)
            )
            .map(instance => instance.takeId)
        // if reading is not done not printable
        takesThatNeedToCpmpleteReading = testtakers
            .filter( instance => nonPrintablePaths.includes(instance.readingScore!) && instance.handwritten)
            .map (instance => instance.takeId)
    } else {
        printableTakes = testtakers
            .filter(instance => !completedTestValues.includes(instance.writingScore!) && instance.handwritten)
            .map(instance => instance.takeId)

    }

    return {
        takeIdsPrintable: printableTakes,
        takeIdsComplete: writngCompletedTakes,
        takeIdsNotPrintable: takesThatNeedToCpmpleteReading,
        takesNotHandwritten: nonHandwrittenTestTakers
    }

}

const productDependantOnReading = (productId: number): boolean => {
    return productId !== ProductId.STAMP4SE.valueOf()
}
