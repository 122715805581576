import React from "react"
import Button from "../../../Button/Button"

interface IComponentProps {
    onClick: (event: any) => void
    recordingDuration: number
}

export default class StartListeningButton extends React.Component<IComponentProps, {}> {
    constructor(props: IComponentProps) {
        super(props)

        this.state = {}
    }

    render() {
        return (
            <Button className={"StartListeningButton"}
                    onClick={this.props.onClick}
            >
                <div className={"StartListeningButton__icon"} />
                <span className={"StartListeningButton__text"}
                      data-tst-id='start-listening-button'
                >Listen</span>
            </Button>
        )
    }
}
