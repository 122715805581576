import {GetApp} from "@material-ui/icons"
import {observer} from "mobx-react"
import * as React from "react"
import {ReportUtil} from "../../../../util/ReportUtil"
import {AvantPopperButton} from "../../../generic-components/AvantPopperButton"
import {AvantPopperMenu} from "../../../generic-components/AvantPopperMenu"
import {ReportGraphFilters} from "../models/ReportGraphFilters"
import {reportsStore} from "../stores/ReportStore"


export const ReportsDownloadMenu = observer((props: ReportGraphFilters) => {

    const contents = (!reportsStore.csvDownloadInProgress && reportsStore.csvDownload !== undefined) ?
        [
            {
                name: "Test Taker Data",
                action: () => {
                    if (reportsStore.csvDownload) {
                        ReportUtil.takeReportCsvLink(
                            "TestInstanceReport.csv",
                            reportsStore.csvDownload || []
                        )
                    }
                }
            }
        ]
        :
        [
            {
                name: "In Progress...", action: undefined,
                needsLoader: true
            }
        ]

    return (
        <AvantPopperButton
            onMouseEnter={() => {
                if (!reportsStore.csvDownload && !reportsStore.csvDownloadInProgress) {
                    reportsStore.startCsvDownload(props)
                }
            }}
            title={<GetApp/>}
        >
            <AvantPopperMenu contents={contents}/>
        </AvantPopperButton>
    )
})
