import * as winston from "winston"
import {EnvUtils} from "./EnvUtils"

export const log = winston.createLogger({
    level: EnvUtils.isDev() ? "debug" : "info",
    transports: [
        new winston.transports.Console({
            format: winston.format.simple()
        })
    ]
})

// tslint:disable-next-line
export const prettyJson = (write: any): string => JSON.stringify(write, null, 2)
