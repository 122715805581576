import {combineReducers} from "redux"

import {AppStore, IAction, Take} from "../../types/types"
import {to} from "../../util/elvis"
import {item} from "../item/reducers"
import {
    AUTHENTICATE_SUCCESS,
    POST_PROFILE_ERROR,
    POST_PROFILE_SUCCESS,
    SET_ADVANCE_REPORT_DISTRICT_ID,
    SET_ADVANCE_REPORT_LANGAUGE,
    SET_ADVANCE_REPORT_SCHOOL,
    SET_API_CLIENT,
    SET_CURRENT_LANGUAGE_ID,
    SET_CURRENT_PANEL_ID,
    SET_CURRENT_PANEL_INDEX,
    SET_DRIVER,
    SET_HIDE_LOADING_SPINNER,
    SET_IS_CERTIFICATION_TEST,
    SET_IS_TRAINING_ONLY_MODULE,
    SET_LANGUAGES,
    SET_LICENSE_KEY,
    SET_LOGIN_PRODUCT_DATA,
    SET_LOGOUT_MESSAGE,
    SET_NEXT_PANEL_ID,
    SET_NO_CONNECTION,
    SET_PANEL_GRAPH,
    SET_PANELS,
    SET_PRODUCT_CHOICES,
    SET_SHOW_AUTH_USER_EXPIRE_MODAL,
    SET_SHOW_PANEL_TIMEOUT_MODAL,
    SET_STOP_ALL,
    SET_TAKE,
    SET_TAKE_CODE,
    SET_TAKE_UUID,
    SET_TEST_STATE,
    UPDATE_CURRENT_PANEL_GRAPH_ID,
    UPDATE_LAST_TOKEN,
    UPDATE_TAKE,
    USER_LOGOUT
} from "./actions"

export const appInitialStore: AppStore = {
    hideSpinner: false,
    authUser: {
        token: null,
        tokenTimeRemaining: 0,
        loginId: null,
        userId: null,
        userType: undefined,
        expires: null,
        eulaAgreeDate: null,
        userName: null,
        ssoType: null,
        ssoToken: null,
        ssoId: null,
        ssoUserId: null,
        userPermissions: null,
        ssoDisplayName: null,
    },
    lastToken: "",
    take: null,
    stopAll: false,
    panelGraph: {
        id: -1,
        name: "",
        friendlyName: "",
        contentAreaId: -1,
        productId: -1,
        panelGraphEdges: [],
        handwritten: false
    },
    testState: null,
    takeCode: "",
    languages: null,
    panels: null,
    currentPanelGraphId: -1,
    currentPanelId: 0,
    nextPanelId: 0,
    currentProductId: 0,
    currentPanelIndex: -1,
    isCertificationTest: false,
    currentLanguageId: null,
    isTrainingOnlyModule: false,
    showAuthUserExpireModal: false,
    showPanelTimeoutModal: false,
    licenseKey: null,
    productChoices: [],
    takeUuid: null,
    logoutMessage: null
}

export const app = (state: AppStore = appInitialStore, action: IAction) => {
    let take = state.take
    let newTake = action.result
    switch (action.type) {
        case SET_NO_CONNECTION:
            return {
                ...state,
                noConnection: action.result
            }
        case AUTHENTICATE_SUCCESS:
            const lastToken = to<string>(state.authUser.token, "")
            return {
                ...state,
                lastToken,
                authUser: action.result
            }
        case UPDATE_LAST_TOKEN:
            return {
                ...state,
                lastToken: action.result
            }
        case USER_LOGOUT:
            return appInitialStore

        case SET_SHOW_PANEL_TIMEOUT_MODAL:
            return {
                ...state,
                showPanelTimeoutModal: action.result
            }
        case SET_HIDE_LOADING_SPINNER:
            return {
                ...state,
                hideSpinner: action.result
            }
        case SET_STOP_ALL:
            return {
                ...state,
                hideSpinner: true,
                stopAll: action.result
            }
        case UPDATE_TAKE:
            return {
                ...state,
                authUser: {...state.authUser},
                take: newTake
            }
        case SET_TAKE:
            return {
                ...state,
                take: action.result
            }
        case SET_LANGUAGES:
            return {
                ...state,
                languages: action.result
            }
        case SET_CURRENT_PANEL_ID:
            return {
                ...state,
                currentPanelId: action.result
            }
        case SET_CURRENT_PANEL_INDEX:
            return {
                ...state,
                currentPanelIndex: action.result
            }
        case SET_NEXT_PANEL_ID:
            return {
                ...state,
                nextPanelId: action.result
            }
        case UPDATE_CURRENT_PANEL_GRAPH_ID:
            return {
                ...state,
                currentPanelGraphId: action.result
            }
        case SET_PANELS:
            return {
                ...state,
                panels: action.result
            }
        case SET_IS_CERTIFICATION_TEST:
            return {
                ...state,
                isCertificationTest: action.result
            }
        case SET_CURRENT_LANGUAGE_ID:
            return {
                ...state,
                currentLanguageId: action.result
            }
        case SET_IS_TRAINING_ONLY_MODULE:
            return {
                ...state,
                isTrainingOnlyModule: action.result
            }
        case SET_SHOW_AUTH_USER_EXPIRE_MODAL:
            return {
                ...state,
                showAuthUserExpireModal: action.result
            }
        case SET_TAKE_CODE:
            return {
                ...state,
                takeCode: action.result
            }
        case SET_PANEL_GRAPH:
            return {
                ...state,
                panelGraph: action.result
            }
        case POST_PROFILE_SUCCESS:
            take = to<Take>(state.take, new Error("Tried to update a profile before a take was present on the store."))
            newTake = {
                ...take,
                profile: action.result
            }
            return {
                ...state,
                take: newTake
            }
        case POST_PROFILE_ERROR:
            return {
                ...state,
                hideSpinner: true
            }
        case SET_TEST_STATE:
            return {
                ...state,
                testState: action.result
            }
        case SET_LICENSE_KEY:
            return {
                ...state,
                licenseKey: action.result
            }
        case SET_PRODUCT_CHOICES:
            return {
                ...state,
                productChoices: action.result
            }
        case SET_TAKE_UUID:
            return {
                ...state,
                takeUuid: action.result
            }
        case SET_LOGOUT_MESSAGE:
            return {
                ...state,
                logoutMessage: action.result
            }
        case SET_LOGIN_PRODUCT_DATA:
            return {
                ...state,
                product: action.result
            }
        case SET_DRIVER:
            return {
                ...state,
                driver: action.result
            }
        case SET_API_CLIENT:
            return {
                ...state,
                apiClient: action.result
            }
        case SET_ADVANCE_REPORT_DISTRICT_ID:
            return {
                ...state,
                advanceReportDistrictId: action.result
            }
        case SET_ADVANCE_REPORT_LANGAUGE:
            return {
                ...state,
                advanceReportLanguage: action.result
            }
        case SET_ADVANCE_REPORT_SCHOOL:
            return {
                ...state,
                advanceReportSchool: action.result
            }
        default:
            return state
    }
}

// The keys of the reducer object become keys on the state.
// i.e. the app state will have state.app and state.
const reducers = {
    app,
    item
} as any

export const combinedReducer = combineReducers(reducers)
