// import * as Sentry from "@sentry/browser"
import React from "react"

export class TwilioVoiceRecorder extends React.Component {
    render() {
        return (<div>TwilioVoiceRecorder</div>)
    }
}
// // noinspection SpellCheckingInspection
// import * as Twilio from 'twilio-client';
//
// import {isNullOrUndefined} from "util"
// import { voiceRecorderStore } from '../../../app/common/item/VoiceRecorderStore'
// import ApiService from "../../../services/ApiService"
// import {
//     ApiTwilioPlaybackTemplateResponse,
//     ApiTwilioRecordingSidResponse,
//     ApiTwilioTokenResponse,
//     TWILIO_DEFAULT_INPUT_DEVICE_ID
// } from "../../../types/types"
// import {AudioReadyState, AudioUtils} from "../../../util/AudioUtils"
// import Html5VoiceRecorder from "../Html5VoiceRecorder/Html5VoiceRecorder"
//
// interface IComponentProps {
//     saveFinalRecordings: boolean
//     setRecordingSidsToSave: (recordingSids: string) => void
//     recordingSids: string
//     minRecordingLengthMilliseconds: number
//     onReady: () => void
//     onFailure: (err: Error) => void
// }
//
// interface IComponentState {
//     recordingSids: string
//     callSid: string
//     currentVolume: number
//     inputDeviceId: string
//     isDeleteConfirmShown: boolean
//     isShowingLowVolumeMessage: boolean
//     isGetHelpShown: boolean
//     playbackTemplate?: string
//     startTime?: Date
//     deviceSetup: boolean,
//     canPlayAudio: boolean,
//     hasSocketConnection: boolean
// }
//
// export class TwilioVoiceRecorder extends React.Component<IComponentProps, IComponentState> {
//     private fallbackTimer ?: ReturnType<typeof setTimeout>
//
//     constructor(props: IComponentProps) {
//         super(props)
//
//         this.state = {
//             recordingSids: this.props.recordingSids,
//             callSid: "",
//             currentVolume: 0,
//             inputDeviceId: TWILIO_DEFAULT_INPUT_DEVICE_ID,
//             isDeleteConfirmShown: false,
//             isShowingLowVolumeMessage: false,
//             isGetHelpShown: false,
//             startTime: new Date(),
//             deviceSetup: false,
//             canPlayAudio: false,
//             hasSocketConnection: false
//         }
//
//         voiceRecorderStore.playbackState = "not"
//     }
//
//     fallback = (err: Event | Error | undefined = undefined) => {
//         if (err) {
//             Sentry.captureException(err)
//         }
//         this.props.onFailure(err as any)
//     }
//
//     testAudio = () => {
//         return AudioUtils
//             .loadAudio(
//                 [
//                     "https://api.twilio.com/2010-04-01/Accounts/",
//                     "AC9ba7cf5aba53694808695893fbecf1d8/Recordings/RE885c093d07556cc121b706e701a51632.json",
//                     "%20-u%20AC9ba7cf5aba53694808695893fbecf1d8:87999d94a1c852c0a1f6db381addc1c2"
//                 ].join(""), AudioReadyState.HAVE_METADATA
//             )
//             .then(() => {
//                 this.setState({canPlayAudio: true})
//             }, () => {
//                 throw new Error("Could not connect to ApiService.twilio.com")
//             })
//     }
//
//
//
//     componentDidMount() {
//         const failAfterMilli: number = 8000
//
//         this.fallbackTimer = setTimeout(() => {
//             if (!this.isReady()) {
//                 this.fallback(new Error(`Failed to load Twilio Recorder after ${failAfterMilli}`))
//             }
//
//         }, failAfterMilli)
//
//         if (this.state.deviceSetup) {
//             this.connect()
//
//             Twilio.Device.ready(() => {
//                 this.props.onReady()
//             })
//
//             Twilio.Device.onblocked((ev: Event) => {
//                 this.fallback(ev)
//             })
//
//             Twilio.Device.error((error: any) => {
//                 this.fallback(error)
//             })
//         }
//
//         this.setup()
//     }
//
//     clearFallback():void {
//         if (this.fallbackTimer) clearTimeout(this.fallbackTimer)
//     }
//
//     componentWillUnmount(): void {
//         this.clearFallback()
//         //TODO we should clear ALL of the unresolved promises. (ie: getTwillioToken and getTwilioPlaybackTemplate)
//         // we have to rewrite them to be like https://medium.com/@rajeshnaroth/writing-a-react-hook-to-cancel-promises-when-a-component-unmounts-526efabf251f
//     }
//
//     setup = () => {
//         ApiService.getTwilioToken().then((res: ApiTwilioTokenResponse) => {
//             const twilioTokenObj = res
//
//             try {
//                 if (twilioTokenObj === null || twilioTokenObj === undefined) {
//                     this.props.onFailure(Error(`The Twilio.Device token object received from the API is null or undefined.`))
//                     return
//                 }
//
//                 //
//                 // We need this to disconnect any previous Twilio.Device sessions, but if there weren't any,
//                 // Twilio.Device.Device.status() will throw an exception that we can ignore.
//                 //
//
//                 try {
//                     if (Twilio.Device.status() === "ready") {
//                         Twilio.Device.destroy()
//                     }
//                 } catch (err) {
//                     // noop
//                 }
//
//
//                 //Twilio.Device.setup(twilioTokenObj.token, {debug: true})
//                 Twilio.Device.setup(twilioTokenObj.token, { codecPreferences: ["opus", "pcmu"], maxAverageBitrate: 16000, debug: false})
//                 this.setState({deviceSetup: true})
//
//                 console.debug("Success Twilio.Device.setup") // we we can see this in sentry playback
//             } catch (err) {
//                 // TODO: See if this still works and fix at some point
//                 // @ts-ignore
//                 this.fallback(err)
//                 return
//             }
//         })
//
//         ApiService.getTwilioPlaybackTemplate().then((res: ApiTwilioPlaybackTemplateResponse) => {
//             const twilioPlaybackTemplateObj = res
//             if (isNullOrUndefined(twilioPlaybackTemplateObj)) {
//                 throw new Error(`The Twilio.Device playback template is null or undefined.`)
//             }
//             this.setState({playbackTemplate: twilioPlaybackTemplateObj.template})
//
//             console.debug("Success playbackTemplate setup") // we we can see this in sentry playback
//         })
//
//
//
//         try {
//             this.testAudio().then(() => {
//             }, (err: Error) => {
//                 this.fallback(err)
//                 return
//             })
//         } catch (e) {
//             // TODO: See if this still works and fix at some point
//             // @ts-ignore
//             this.fallback(e)
//             return
//         }
//
//         if (Twilio.Device === undefined) {
//             this.fallback(new Error("Twilio.Device is undefined?"))
//         } else {
//             const socket = new WebSocket("wss://chunderw-vpc-gll.twilio.com/signal")
//             if (socket !== null) {
//                 socket.onerror = (ev: Event) => {
//                     this.fallback(ev)
//                 }
//                 socket.onopen = () => {
//                     this.setState({hasSocketConnection: true})
//                     console.debug("Success hasSocketConnection setup") // we we can see this in sentry playback
//                 }
//             } else {
//                 this.fallback(new Error("Failed to create websocket. It was null."))
//             }
//         }
//     }
//
//     isReady = () => {
//         const {hasSocketConnection, playbackTemplate, deviceSetup, canPlayAudio} = this.state
//
//         if (hasSocketConnection && playbackTemplate && deviceSetup && canPlayAudio) {
//             this.clearFallback()
//             return true
//         }
//         return false
//     }
//
//     addToRecordingClick = () => {
//         this.connect()
//     }
//
//     connect() {
//         Twilio.Device.connect()
//         Twilio.Device.on("connect", (conn: any) => {
//             this.setState({callSid: conn.parameters.CallSid})
//
//             conn.volume((inVol: number) => {
//                 this.setState({currentVolume: inVol})
//             })
//         })
//     }
//
//     disconnectAll() {
//         Twilio.Device.disconnectAll()
//         this.setState({currentVolume: 0})
//     }
//
//     startRecording = () => {
//         this.connect()
//     }
//
//     stopRecording = () => {
//         // TODO call parent letting them know audio has been recorded
//         this.disconnectAll()
//         let twilioRecordingSidObj: ApiTwilioRecordingSidResponse
//
//         ApiService.getTwilioRecordingSid(this.state.callSid).then((res: ApiTwilioRecordingSidResponse) => {
//             twilioRecordingSidObj = res
//
//             if (isNullOrUndefined(twilioRecordingSidObj)) {
//                 throw new Error(`The Twilio recordingSid object received from the API is null or undefined.`)
//             }
//
//             if (this.props.saveFinalRecordings && this.state.recordingSids.length > 0) {
//                 this.setState({
//                     recordingSids: this.state.recordingSids + "|" + twilioRecordingSidObj.recordingSid
//                 })
//             } else {
//                 this.setState({recordingSids: twilioRecordingSidObj.recordingSid})
//             }
//
//             if (this.props.saveFinalRecordings) {
//                 this.props.setRecordingSidsToSave(this.state.recordingSids)
//             }
//         })
//     }
//
//     playbackClick = (playbackTemplate: string, onPlaybackEnded?: () => void) => {
//         voiceRecorderStore.playbackState = "playing"
//         voiceRecorderStore.audioHandle = new Audio()
//         let i = 0
//         const playlist = this.state.recordingSids.split("|")
//
//         const playRecordedAudioFile = (audioHandle: HTMLAudioElement) => {
//             audioHandle.play().then(() => {
//             }, (err: Error) => {
//                 Sentry.captureException(err)
//             })
//         }
//
//         voiceRecorderStore.audioHandle.addEventListener(
//             "ended",
//             () => {
//                 if (!voiceRecorderStore.audioHandle) {
//                     return
//                 }
//
//                 // We increment by 1 immediately because you only enter this callback after the first one has "ended".
//                 // Notice below that after this lambda definition there is an `voiceRecorderStore.audioHandle.play()` call. Once that
//                 // `play()` call finishes an `"ended"` event is triggered, and we much move onto the next recording.
//                 i = i + 1
//                 if (i < playlist.length) {
//                     if (playlist[i].length > 0) {
//                         voiceRecorderStore.audioHandle.src = playbackTemplate.replace("{recordingSid}", playlist[i])
//                         playRecordedAudioFile(voiceRecorderStore.audioHandle)
//                     }
//                 } else {
//                     voiceRecorderStore.playbackState = "not"
//                     if (onPlaybackEnded) {
//                         onPlaybackEnded()
//                     }
//                 }
//             },
//             true
//         )
//
//         voiceRecorderStore.audioHandle.src = playbackTemplate.replace("{recordingSid}", playlist[0])
//         playRecordedAudioFile(voiceRecorderStore.audioHandle)
//     }
//
//     onConfirmDeleteRecordings = () => {
//         const recordingSids = ""
//         this.setState({recordingSids})
//         this.props.setRecordingSidsToSave(recordingSids)
//     }
//
//     render() {
//         if (!this.isReady()) {
//             console.debug('Initializing Twillio (render)')
//             return (<span>Initializing...</span>)
//         }
//
//         const {minRecordingLengthMilliseconds, saveFinalRecordings} = this.props
//         const {playbackTemplate, currentVolume, recordingSids} = this.state
//         return (
//             <div data-tst-id="twilio-voice-recorder">
//             <Html5VoiceRecorder
//                 data-tst-id="twilio-recorder"
//                 supportsOtherInputs={true}
//                 availableInputs={Array.from(Twilio.Device.audio.availableInputDevices.values())}
//                 recordingState={this.props.recordingSids.length === 0 ? "none" : "recorded"}
//                 startRecording={this.startRecording}
//                 stopRecording={this.stopRecording}
//                 hasRecordingAvailable={() => recordingSids.length > 0}
//                 saveFinalRecordings={saveFinalRecordings}
//                 minRecordingLengthMilliseconds={minRecordingLengthMilliseconds}
//                 resetRecording={this.onConfirmDeleteRecordings}
//                 startPlaybackRecording={() => {
//                     this.playbackClick(playbackTemplate!!) // !! because otherwise isReady would have caught it and returned early
//                 }}
//                 addToRecording={this.addToRecordingClick}
//                 volume={currentVolume}
//                 displayVolume={true}
//                 isInteractable={true}
//                 maxLengthSeconds={0}
//             />
//             </div>
//
//         )
//     }
// }