import {observer} from "mobx-react"
import * as React from "react"
import {groupMasterStore} from "../../../common/group/GroupMasterStore"
import {AvantCircularProgress, CircularProgressSize} from "../../../common/loaders/AvantCircularProgress"
import {AvantPopperMenuItem} from "../../../generic-components/AvantPopperMenu"
import {ReportsPrintButton} from "../../common/components/ReportsPrintButton"
import {ReportFilters} from "../../common/models/ReportFilters"
import {ReportSubPaths} from "../../ReportSubPaths"


export const SchoolReportsPrintMenu: React.FC<{ filters: ReportFilters }> = observer(({filters}) => {

    const groupInfo = groupMasterStore.groupInfo
    if (groupInfo == null) {
        return <AvantCircularProgress size={CircularProgressSize.SMALL}/>
    }

    const menuContents: AvantPopperMenuItem[] = [
        {
            name: "Summary & Groups",
            link: ReportSubPaths.schoolPrintAll(filters)
        },
        {
            name: "Summary Only",
            link: ReportSubPaths.schoolPrintSummary(filters)
        },
        {
            name: "Groups Only",
            link: ReportSubPaths.schoolPrintList(filters)
        }
    ]

    return <ReportsPrintButton contents={menuContents}/>
})
