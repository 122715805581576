
import {Button, createStyles, Theme} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import React, {useEffect, useState} from "react"
import SSO_BUTTON_FOR_NYCDOE from "../../assets/NYCDOE/NYCDOE.png"
import NycDoeApi from "../../services/NycDoeApi";

const NYCDOELoginStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1)
            // textAlign: "center"
        },
        button: {
            padding: 0,
            maxWidth: 300,
            marginLeft: "75px",
            border: "1px solid #F2A42A"
        },
        image: {
            width: "200px",
            height: "39px",
            cursor: "pointer",
            borderRadius: "10px",
            border: "1px solid white"
        }
    })
)

interface NycDoeOAuthLink {
    url: string
}

export const SignOnWithNYCDOEButton: React.FC = (props) => {
    //When I'll get the geturl's api, Will use this space below.
    const [nycdoe, setNycdoe] = useState<NycDoeOAuthLink>({url: ""})
    const loadLink = async () => {
        const link = await NycDoeApi.getNycDoeOAuthOIDCUrl()
        setNycdoe({url: link.nycDoeSsoLoginLink})
    }

    useEffect(() => {
        loadLink()
    }, [])

    const loginWithNycDoeOauth = () => {
        window.location.href = nycdoe.url
    }

    const classes = NYCDOELoginStyles()
    return (
        <div className={classes.root}>
            <Button
                className={classes.button}
                onClick={loginWithNycDoeOauth}
            >
                <img src={SSO_BUTTON_FOR_NYCDOE} className={classes.image}  alt={"Login with NYCDOE Oauth Button"}/>
            </Button>
        </div>
    )
}
