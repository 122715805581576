import ApiService, {SearchItemResponse} from "../../services/ApiService"
import {GenericHelperInterface} from "../../services/GenericHelper"
import HelperService from "../../services/HelperService"
import {
    ApiErrorResponse,
    ApiItem,
    IItem,
    ItemFormatEnum,
    LastPage,
    LoginProductContentAreaData,
    TestState
} from "../../types/types"
import {ContentAreaId, ProductId} from "../../validation/ValidPrimaryKey"

export interface SearchItemResults {
    count: number
    items: IItem[]
}

export default class AdminHelper implements GenericHelperInterface {

    searchItems = (
        mediaBaseUrl: string,
        currentPage: number,
        pageSize: number,
        productId?: ProductId,
        contentAreaId?: ContentAreaId,
        panelGraphName?: string,
        panelName?: string,
        format?: ItemFormatEnum,
        nameLike?: string,
        skill?: string,
        level?: string
    ): Promise<SearchItemResults> => {
        return new Promise((resolve, reject) => {
            ApiService.searchItems(currentPage, pageSize, productId, contentAreaId, panelGraphName, panelName, format, nameLike, skill, level).then(
                (res: SearchItemResponse) => {
                    let results: SearchItemResults

                    if (res.items) {
                        results = {
                            count: res.itemCount,
                            items: res.items.map((item: ApiItem) => {
                                return HelperService.parseItem(item, mediaBaseUrl)
                            })
                        }
                    }
                    else {
                        results = {
                            count: 0,
                            items: []
                        }
                    }

                    resolve(results)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    refreshTest(props: any, lastPage: LastPage, loginData: LoginProductContentAreaData): Promise<TestState> {
        throw Error("Admin helper should not refresh test.")
    }

    refreshTestCallback(testState: TestState, props: any): void {
        // do nothing
    }
}
