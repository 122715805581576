import {observer} from "mobx-react"
import React from "react"
import {RouteComponentProps} from "react-router"
import {loadingSpinnerStore} from "../../app/common/loaders/LoadingSpinnerStore"
import {productStore} from "../../app/common/products/ProductStore"
import {RoutePaths} from "../../app/routes/Routes"
import HelperService from "../../services/HelperService"
import Button from "../Button/Button"
import {Brand} from "../Brand/Brand"
import LanguageUtils from "../../util/LanguageUtils"

interface LoginStartOrContinueState {
    brandName: string | undefined
}

@observer
export class LoginStartOrContinue extends React.Component<RouteComponentProps, LoginStartOrContinueState> {

    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            brandName: ""
        }
    }

    handleStart = () => {
        this.props.history.push("/profile-login-name")
    }

    handleContinue = () => {
        this.props.history.push("/login-continue-test")
    }

    componentDidMount() {
        document.title = `Avant | Login`
        HelperService.enableTextSelection()
        const loginProduct = productStore.loginProduct

        if (!loginProduct) {
            this.props.history.push(RoutePaths.LOGIN)
        }
        loadingSpinnerStore.hideLoadingSpinner = true

        this.setBrandName(((loginProduct) ? loginProduct.productName : ""))
    }

    render() {
        const product = productStore.loginProduct
        if (!product) {
            return (
                <div style={{fontWeight: "normal", width: "100%", textAlign: "center"}}>
                    <h2>Loading...</h2>
                </div>
            )
        }
        const driver = productStore.driver!!

        const testName = this.state.brandName

        let languageName = LanguageUtils.removeTilde(product.contentAreaName)
        languageName = LanguageUtils.removeMonolingual(languageName)

        let title = driver.strings.formatString(driver.strings.welcomeToTheTest, `${testName} ${languageName}`)
        if (testName === "APT") {
            title = driver.strings.formatString(driver.strings.welcomeToTheTest, "Arabic Proficiency")
        } else if (testName === "SHL") {
            title = driver.strings.formatString(driver.strings.welcomeToTheTest, "SHL")
        }

        return (
            <div className="login">
                <div>
                    <div className="login__image-container">
                        <div>
                            <img
                                className="login__image-container__image"
                                alt=""
                                src="https://cdn.avantassessment.com/resources/common/img/avant-logo.svg"
                                tabIndex={0}
                            />
                        </div>
                    </div>
                    <div className="login__login-container">
                        <div className="login__login-container__top">
                            <div data-tst-id="start-continue-title"
                                 className="login__login-container__top__title">{title}</div>
                        </div>
                        <div style={{textAlign: "center"}}>
                            <div style={{marginBottom: "24px"}}>
                                <Button
                                    testId={"start-continue-new-test"}
                                    className="login__login-container__button avant-button--primary"
                                    onClick={this.handleStart}
                                >
                                    {driver.strings.startNewTest}
                                </Button>
                            </div>
                            <div style={{marginBottom: "48px"}}>
                                <Button
                                    testId={"start-continue-continue-test"}
                                    className="login__login-container__button avant-button--primary"
                                    onClick={this.handleContinue}
                                >
                                    {driver.strings.continueTest}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    setBrandName = async (productName: string) => {
        // The brand name may be changed by the Brand component.
        const brandName: string | undefined = await Brand.buildBrandName(productName)

        this.setState({brandName: brandName})
    }

}
