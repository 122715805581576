import {Dialog, DialogTitle, Grid, IconButton, Typography} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import MaterialTable from "material-table"
import * as React from "react"
import SSOApi from "../../../../../services/SSOApi"

const modalStyles = {
    dialogTitle: {
        backgroundColor: "#f3f3f3",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    closeButton: {
        marginRight: "-15px"
    },
    nameOfClassroom: {
        color: "#767676"
    }
}

export interface CustomAdminViewProps {
    onClose: () => void,
    open: boolean,
}

export const CustomAdminView: React.FC<CustomAdminViewProps> = (props) => {
    const handleClose = () => {
        props.onClose()
    }

    const getData = async () => {
        const response = await SSOApi.getCustomDistrictAdmins()
        return {
            data: response,
            page: 0,
            totalCount: response.length
        }
    }

    return (

        <Grid container={true} alignItems="center" spacing={5} justifyContent="center" direction="column" style={{padding: "10px", height: "65%", maxWidth: "400px"}}>
            <Grid item={true} xs={12}>
                <Dialog fullWidth={true} maxWidth="sm" open={props.open} onClose={handleClose} aria-labelledby="Class Roster">
                    <DialogTitle style={modalStyles.dialogTitle} disableTypography={true} id="simple-dialog-title">
                        <div>
                            <Typography variant="h6">Custom Admins</Typography>
                        </div>
                        <IconButton style={modalStyles.closeButton} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <MaterialTable
                        columns={[
                            { title: "SIS ID", field: "teacherSisId" },
                            { title: "First Name", field: "firstName" },
                            { title: "Last Name", field: "lastName" },
                        ]}
                        options={{
                            thirdSortClick: false,
                            showTitle: false,
                        }}
                        data={getData}
                    />

                </Dialog>
            </Grid>
        </Grid>
    )
}
