import React from "react"
import InputDeviceSelection from "./InputDeviceSelection/InputDeviceSelection"

interface IComponentProps {
    title: string
    message: string
    inputDevices: MediaDeviceInfo[]
}

export default class MessageDropDown extends React.Component<IComponentProps, {}> {

    render() {
        const inputDevices =
            this.props.inputDevices.length > 0 ? <InputDeviceSelection inputDevices={this.props.inputDevices} /> : ""

        return (
            <div className="MessageDropDown">
                <div className={"MessageDropDown__title"}>{this.props.title}</div>
                <div className={"MessageDropDown__message"}>{this.props.message}</div>
                <div className={"MessageDropDown__help"}>
                    <a target="_blank" href="https://avantassessment.com/headset-guide" rel={"noopener noreferrer"}>
                        Are you a proctor? Get microphone support {">"}{" "}
                    </a>
                </div>
                {inputDevices}
            </div>
        )
    }
}
