/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import React, { useReducer } from "react"
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    ListItem,
    Paper,
    ListItemText,
    List
} from "@material-ui/core"
import {BodyText, H2Text, H3Text} from "../../../../styles/AvantTypography"
import {AvantCircularProgress, CircularProgressSize} from "../../../../app/common/loaders/AvantCircularProgress"
import DevToolsApi from "../../../../services/DevToolsApi"

// Interfaces

interface ExamCreationConfirmationDialogProps {
    isDialogOpen: boolean
    handleCloseExamCreationDialog: () => void
    sendExamsToP360: () => void
    selectedIdLength: number
    loading: boolean
    success: boolean
    error: boolean
    data: P360247CreateExamReturn
}

interface Proctor247CreationToolProps {
    selectedIds: string[]
}

interface ExamCreationToolState {
    loading: boolean
    error: boolean
    success: boolean
    isDialogOpen: boolean
    data: P360247CreateExamReturn
}

interface Action {
    type: string
    data: P360247CreateExamReturn
}

interface AlreadyExistsObj {
    loginId: number
    testCode: string
}

interface HasErrorsObj {
    message: string
    loginId: number | null
    testCode: string | null
}

export interface P360247CreateExamReturn {
    alreadyExists: AlreadyExistsObj[]
    hasErrors: HasErrorsObj[]
}


// End Interfaces

function ExamCreationConfirmationDialog(props: ExamCreationConfirmationDialogProps) {

    const { isDialogOpen, handleCloseExamCreationDialog, sendExamsToP360, selectedIdLength, loading, success, error, data } = props

    return (
        <Dialog open={isDialogOpen} onClose={handleCloseExamCreationDialog}>
            <DialogTitle>Assign selected exams to 24/7 Proctoring?</DialogTitle>
            { selectedIdLength > 0
                ? <DialogContent>
                    <DialogContentText>
                        All selected exams will be assigned to 24/7 proctoring. Do you wish to continue?
                    </DialogContentText>
                </DialogContent>
                : <DialogContent>
                    <DialogContentText>
                        Please select one or more exams to be assigned to 24/7 proctoring.
                    </DialogContentText>
                </DialogContent>
            }
            <Grid container={true} direction="column">
                <Grid container={true} item={true} justifyContent="space-evenly">
                    <Grid item={true}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleCloseExamCreationDialog}
                            disabled={loading || selectedIdLength == 0}
                        >
                            Exit
                        </Button>
                    </Grid>
                    <Grid item={true} style={{ marginBottom: "20px"}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={sendExamsToP360}
                            disabled={loading || selectedIdLength == 0}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
                {loading == true &&
                    <Grid container={true} item={true} style={{margin: "10px 0px 25px 0px"}}>
                        <AvantCircularProgress
                            size={CircularProgressSize.MEDIUM}
                        />
                    </Grid>
                }
                {
                    success == true &&
                    <Grid container={true} item={true} style={{margin: "10px 0px 25px 0px"}} justifyContent="center">
                        <H3Text>SUCCESS!</H3Text>
                    </Grid>
                }
                {
                    error == true &&
                    <Grid container={true} item={true} style={{margin: "10px 0px 25px 0px"}}>
                        <H3Text>ERROR</H3Text>
                    </Grid>
                }
                <Grid container={true} item={true} justifyContent="space-between" wrap='nowrap' style={{padding: "0px 28px"}} direction="column">
                    {/*If the list of exams that already exists is greater than 0, render out the loginIds of the exams
                    that already exist.*/}
                    { data.alreadyExists.length > 0 && success == true &&
                        <>
                            <Grid container={true} item={true} direction="row">
                                <BodyText>
                                    The following exams were already set to be proctored:
                                </BodyText>
                            </Grid>
                            <Grid container={true} item={true} direction="row">
                                <List>
                                {
                                    data.alreadyExists.map(exam => (
                                        <ListItem>
                                            <ListItemText
                                                primary={
                                                    exam.testCode
                                                        ? `Test Code: ${exam.testCode.toString()}`
                                                        : "TEST CODE MISSING"
                                                }
                                                secondary={
                                                    exam.loginId
                                                        ? `Login Id: ${exam.loginId.toString()}`
                                                        : "LOGIN ID MISSING"
                                                }
                                            />
                                        </ListItem>
                                    ))
                                }
                                </List>
                            </Grid>
                        </>
                    }
                    {/*If the list of exams that had errors while being sent to the proctoring service is greater than
                      0, render out the loginIds and error messages of those exams. If one of the exams is missing a
                      login id, "Login Id Missing" will be rendered instead*/}
                    { data.hasErrors.length > 0 && success == true &&
                        <>
                            <Grid container={true} item={true} direction="row">
                                <BodyText>
                                    The following exams had errors while they were being sent to the proctoring service:
                                </BodyText>
                            </Grid>
                            <Grid container={true} item={true} direction="row">
                                <List>
                                    {
                                        data.hasErrors.map(exam => (
                                            <ListItem>
                                                <ListItemText
                                                    primary={
                                                        exam.testCode
                                                            ? `Test Code: ${exam.testCode.toString()}`
                                                            : "TEST CODE MISSING"
                                                    }
                                                    secondary={
                                                        exam.loginId
                                                            ? `Login Id: ${exam.loginId.toString()}, Error Message: ${exam.message.toString()}`
                                                            : `LOGIN ID MISSING, Error Message: ${exam.message.toString()}`
                                                    }
                                                />
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Grid>
                        </>
                    }
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default function Proctor247CreationTool(props: Proctor247CreationToolProps) {

    const { selectedIds } = props

    // Reducer

    // Defines actions of reducer. Each action corresponds to a state of the api call that is made in this component
    function reducer(state: ExamCreationToolState, action: Action) {
        switch (action.type) {
            case "loading":
                return {...state,
                    loading: true,
                    success: false,
                    error: false,
                }
            case "success":
                return {...state,
                    loading: false,
                    success: true,
                    error: false,
                    data: action.data
                }
            case "error":
                return {...state,
                    loading: false,
                    success: false,
                    error: true,
                    data: action.data
                }
            case "openDialog":
                return {...state,
                    error: false,
                    success: false,
                    isDialogOpen: true
                }
            case "closeDialog":
                return {...state,
                    isDialogOpen: false
                }
            default: return {...state}
        }
    }

    // Defines component state and initial values of state
    const [state, dispatch] = useReducer(reducer,
        {
            loading: false,
            error: false,
            success: false,
            isDialogOpen: false,
            data: {
                alreadyExists: [],
                hasErrors: []
            },
        }
    )

    // End Reducer

    function handleOpenExamCreationDialog() {
        dispatch({ type: "openDialog", data: {
                alreadyExists: [],
                hasErrors: []
            }, })
    }

    function handleCloseExamCreationDialog() {
        dispatch({ type: "closeDialog", data: {
                alreadyExists: [],
                hasErrors: []
            }
        })
    }

    function sendExamsToP360() {
        const loginIds: number[] = selectedIds.map((link: string) => {
            const splitLink = link.split("/")
            return Number(splitLink[splitLink.length - 1])
        })

        dispatch({type: "loading", data: {
                alreadyExists: [],
                hasErrors: []
            }
        })

        DevToolsApi.createP360247Exams(loginIds)
         .then((response) => {
             dispatch({ type: "success", data: response})
         })
         .catch(() => {
             dispatch({ type: "error", data: {
                     alreadyExists: [],
                     hasErrors: []
                 }
             })
         })
    }

    return (
        <Grid item={true} xs={12} style={{ position: "relative", height: "100%" }}>
            <Paper elevation={3} style={{ height: "100%" }}>
                <Grid container={true}
                      justifyContent="space-between"
                      alignItems="center"
                      style={{padding: "28px"}}
                >
                    <Grid item={true}>
                        <H2Text>Create 24/7 Exam</H2Text>
                        <BodyText>Assign selected exams to proctor 24/7</BodyText>
                    </Grid>
                    <Grid item={true}>
                        <Button
                            onClick={handleOpenExamCreationDialog}
                            variant="contained"
                            color="primary"
                        >
                            Create 24/7 Exams
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <ExamCreationConfirmationDialog
                isDialogOpen={state.isDialogOpen}
                handleCloseExamCreationDialog={handleCloseExamCreationDialog}
                sendExamsToP360={sendExamsToP360}
                loading={state.loading}
                success={state.success}
                error={state.error}
                selectedIdLength={selectedIds.length}
                data={state.data}
            />
        </Grid>
    )
}