import {Section} from "../app/section/models/Section"
import ApiService from "../services/ApiService"
import {ApiErrorResponse, IItem, TLocalAnswer, TSeconds} from "../types/types"
import {PanelId, TakeId} from "../validation/ValidPrimaryKey"

export abstract class ItemContainerHelper {
    oneSecond = 1000
    timeOnItemTimer: any
    timeOnItem: TSeconds = 0
    maxTimeOnItem: TSeconds = 600 // 10 minutes
    timerStarted: boolean = false

    abstract answeredModalText: string

    abstract getCbAnswerCountModalText(item: IItem, answer: TLocalAnswer): string

    abstract isValidCbAnswer(answer: any[], item: IItem, disableRequiredAnswers: boolean): boolean

    abstract isAnswered(answer: TLocalAnswer | null, disableRequiredAnswers: boolean): boolean

    updateTestDuration(section: Section): Promise<void> {
        const takeId = new TakeId(section.takeId)
        const panelId = new PanelId(section.panelId)
        return ApiService.updateTestDuration(takeId, panelId, this.timeOnItem).then(
            () => {
                this.timeOnItem = 0
            },
            (err: ApiErrorResponse) => {
                throw Error(`update-test-duration failed. Could not store test duration. ${err}`)
            }
        )
    }

    stopTimeOnItemTimer(section: Section) {
        this.timerStarted = false
        clearInterval(this.timeOnItemTimer)
        if (this.timeOnItem <= this.maxTimeOnItem) {
            this.updateTestDuration(section)
        }
    }

    startTimeOnItemTimer(section: Section) {
        if (!this.timerStarted) {
            this.timeOnItem = 0
            this.timerStarted = true
            if (section && section.config.storeTestDuration) {
                this.timeOnItemTimer = setInterval(() => {
                    this.timeOnItem++
                    if (this.timeOnItem === this.maxTimeOnItem) {
                        this.stopTimeOnItemTimer(section)
                    }
                }, this.oneSecond)
            }
        }
    }

    restartTimeOnItemTimer(section: Section) {
        this.stopTimeOnItemTimer(section)
        this.startTimeOnItemTimer(section)
    }
}
