/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import * as React from "react"
import {useEffect, useState} from "react"
import {H1Text, H5Text} from "../../../styles/AvantTypography"
import {theme} from "../../../styles/MuiThemes"
import {BreadcrumbHeader} from "../../reports/common/components/Breadcrumbs"
import {Grid} from "@material-ui/core"
import {LevelKeyContent} from "../../reports/common/components/level-key/LevelKeyContent"
import LanguageUtils from "../../../util/LanguageUtils"
import {getStampReportSearchQuery, ReportProps} from "../ReportData"
import {ReportGraphFilters} from "../../reports/common/models/ReportGraphFilters"
import {BlueReportType} from "../../reports/common/models/BlueReportType"
import {getReportTypeFromCurrentUser} from "../printing/PrintViewContainer"
import { FilterAndPrintOptions } from "./FilterAndPrintOptions"

const StampReportHeader = (props: ReportProps) => {
    const [reportType, setReportType] = useState<BlueReportType | undefined>(BlueReportType.CLASS)
    const {filters, groupInfo, printOptions} = props

    const language = LanguageUtils.removeTilde(groupInfo!.language)
    const [query, setQuery] = useState<ReportGraphFilters>()

    useEffect(() => {
        if (!query) {
            setQuery(getStampReportSearchQuery(filters))
        }
        setReportType(getReportTypeFromCurrentUser())
    }, [query])

    if (typeof reportType === 'undefined' || !query) {
        return <></>
    }

    return (
        <div style={{marginBottom: theme.spacing(4), width: "100%"}}>
            {printOptions == null && <BreadcrumbHeader groupInfo={groupInfo} filters={filters}/>}
            <Grid container={true} spacing={1}>
                <Grid item={true} xs={6}>
                    {groupInfo && (
                        <>
                            <H5Text style={{marginBottom: 4}}>
                                {language}
                            </H5Text>
                            <H1Text>
                                {groupInfo.name}
                            </H1Text>
                        </>
                    )}
                </Grid>
                <Grid item={true} xs={6}>
                    <Grid container={true} alignContent={"flex-end"} spacing={1}>

                        {printOptions != null ? (
                            <LevelKeyContent/>) : (
                            <Grid item={true} xs={12}>
                                <FilterAndPrintOptions
                                    filters={filters}
                                    query={query}
                                    reportType={reportType}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default StampReportHeader
