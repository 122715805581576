import {Grid, Paper} from "@material-ui/core"
import MaterialTable from "material-table"
import moment, {Moment} from "moment"
import {ChangeEvent} from "react"
import * as React from "react"
import {RouteComponentProps} from "react-router-dom"
import {Header, HEADER_HEIGHT} from "../../../../components/Header/Header"
import ApiService from "../../../../services/ApiService"
import SSOApi from "../../../../services/SSOApi"
import {H2Text, LabelText} from "../../../../styles/AvantTypography"
import {theme} from "../../../../styles/MuiThemes"
import {
    LoginProductContentAreaData,
    PACKAGE_TO_FULL_NAME, PanelGraphWithLanguage, SsoClassUpdateWritingMethodSummary,
    SsoEntity,
    SSOLicenseCount,
    SsoTeacherClass
} from "../../../../types/types"
import {CONTENT_AREA_ID_TO_LANGUAGE_NAME} from "../../../../util/Constants"
import {screenUtil} from "../../../../util/ScreenUtil"
import {TimeUtil} from "../../../../util/TimeUtil"
import {groupMasterStore} from "../../../common/group/GroupMasterStore"
import {loadingSpinnerStore} from "../../../common/loaders/LoadingSpinnerStore"
import {messageStore} from "../../../common/messages/MessageStore"
import {productStore} from "../../../common/products/ProductStore"
import {REPORTS_MAX_WIDTH, reportsPadding} from "../../../reports/common/components/ReportsPage"
import {ReportSubPaths} from "../../../reports/ReportSubPaths"
import {SSOWrapper} from "../../components/SSOWrapper"
import {ClassList} from "./ClassList"
import {DistrictLicenseCountBanner} from "./LicenseCountBanner"
import {CleverCustomSections} from "./CleverCustomSections"
import {SSOBulkAction} from "./SSOBulkAction"
import {SSOBulkClassSetup} from "./SSOBulkClassSetup"
import {SSOBulkClassSetupConfirm} from "./SSOBulkClassSetupConfirm"
import {SSOBulkConfirm} from "./SSOBulkConfirm"
import {SSOBulkProductReservation} from "./SSOBulkProductReservation"
import {SSOSync} from "./SSOSync"

export const SSODistrictSetup: React.FC<RouteComponentProps> = (props) => {
    return (
        <>
            <Header/>
            <SSOWrapper
                style={{
                    padding: reportsPadding,
                    paddingTop: HEADER_HEIGHT + theme.spacing(4),
                    display: "flex",
                    flexDirection: "column",
                    alignItems: screenUtil.screenWidth > REPORTS_MAX_WIDTH + reportsPadding * 2 ? "center" : undefined,
                }}
            >
                <SSODistrictSetupPage {...props}/>
            </SSOWrapper>
        </>
    )
}

interface SSODistrictSetupState {
    classList: SsoTeacherClass[],
    assignedSeatCountsByProduct: {[key: string]: number},
    consumedSeatCountsByProduct: {[key: string]: number},
    entity: SsoEntity | null,
    isHandwrittenSold: boolean,
    initialized: boolean,
    loginId: number | null,
    selectedIds: number[],
    productOfAllSelectedRows: string,
    bulkProductReservationConfirmation: boolean,
    bulkProductReservationAction: boolean,
    bulkProductReservationProgressCount: number,
    bulkClassSetupConfirmation: boolean,
    bulkClassSetupAction: boolean,
    bulkClassSetupProgressCount: number,
    bulkSelectedPkg: string,
    bulkSelectedHW: boolean,
    bulkDate: string | null | undefined,
    bulkPanelGraph: number,
    bulkPanelGraphId: number,
    isValidBulkDate: boolean,
    testsForSelectedClassGroups: PanelGraphWithLanguage[] | null,
    bulkUseHandwritten: boolean,
    rosterOpen: boolean,
}

class SSODistrictSetupPage extends React.Component<RouteComponentProps, SSODistrictSetupState, {}> {
    tableRef = React.createRef<MaterialTable<SsoTeacherClass>>()

    constructor(props: any) {
        super(props)

        this.state = {
            classList: [],
            assignedSeatCountsByProduct: {},
            consumedSeatCountsByProduct: {},
            entity: null,
            isHandwrittenSold: false,
            initialized: false,
            loginId: null,
            selectedIds: [],
            productOfAllSelectedRows: "",
            bulkProductReservationConfirmation: false,
            bulkProductReservationAction: false,
            bulkProductReservationProgressCount: 0,
            bulkClassSetupConfirmation: false,
            bulkClassSetupAction: false,
            bulkClassSetupProgressCount: 0,
            bulkSelectedPkg: "0",
            bulkSelectedHW: false,
            bulkDate: this.getTodayForDatepicker(),
            bulkPanelGraph: -1,
            bulkPanelGraphId: 0,
            isValidBulkDate: true,
            testsForSelectedClassGroups: null,
            bulkUseHandwritten: false,
            rosterOpen: false,
        }
    }

    componentDidMount(): void {
        // District class report redirect (before the data loads in)
        if (window.location.href.endsWith("?redirectTeacher=True")) {
            const Tdata = localStorage.getItem("TeachThings")
            const row = JSON.parse(Tdata ? Tdata : "")
            const rowData = row as SsoTeacherClass
            const loginProduct: LoginProductContentAreaData = {
                loginId: rowData.logins.id,
                userType: "T",
                productId: rowData.logins.productId,
                productName: rowData.logins.productName,
                contentAreaId: rowData.logins.contentareaid,
                contentAreaName: CONTENT_AREA_ID_TO_LANGUAGE_NAME[rowData.logins.contentareaid],
                rostered: true,
                userName: rowData.logins.testCode,
            }

            // Setup state for the report
            productStore.setLoginProduct(loginProduct)
            groupMasterStore.selectGroup(rowData.groupId) // particularly need to setup the reports redirect
            this.props.history.push(ReportSubPaths.REPORTS_REDIRECT)
        } else {
            this.loadInformation()
        }
    }

    // Created because componentDidMount can not be async
    // This makes sure we dont have a race condition be between the two function on setState
    loadInformation = async () =>  {
        await this.loadEntityInfo()
        await this.loadClassList()
    }

    loadClassList = async () => {
        loadingSpinnerStore.hideLoadingSpinner = false
        await ApiService.getSSODistrictClassList()
            .then(response => {
                loadingSpinnerStore.hideLoadingSpinner = true
                if (response.length) {
                    for (const res of response) {
                        res.estTestingDate = res.estTestingDate ? TimeUtil.formatToUsDate(moment(res.estTestingDate)) : null
                        res.language = res.logins ? CONTENT_AREA_ID_TO_LANGUAGE_NAME[res.logins.contentareaid] : null
                    }
                    this.createCounts(response)
                } else {
                    this.createCounts(response)
                }
            })
    }

    createCounts = (recievedClassList: SsoTeacherClass[]): void => {
        let classList = recievedClassList
        const assignedSeatCountsByProduct = this.compileAssignedSeatCountsByProduct(classList)
        const consumedSeatCountsByProduct = this.compileConsumedSeatCountsByProduct(classList)
        const workingClassList = this.addLicensesSoldToClasses(classList)
        if (workingClassList.length === 0) {
            classList = []
        } else {
            classList = workingClassList
        }
        this.setState({
                classList,
                assignedSeatCountsByProduct,
                consumedSeatCountsByProduct,
            })

    }

    loadEntityInfo = async () => {
        await ApiService.getSSOEntity()
            .then(response => {
                if (response) {
                    const classList = this.state.classList
                    this.setState({
                        entity: response,
                        isHandwrittenSold: this.isHandwrittenSold(response)
                    }, () => {

                            const newClassList  = this.addLicensesSoldToClasses(classList)
                            this.setState({classList: newClassList})
                    })
                }
            })
    }

    // Has this institution purchased handwritten seat licenses?
    isHandwrittenSold = (entity: SsoEntity | null): boolean => {
        let isHandwrittenSold = false

        if (entity && entity.licenses_sold) {
            // Iterate over the licenses sold to the institution.
            entity.licenses_sold.forEach((ssoLicenseCount: SSOLicenseCount) => {
                // If we find handwritten licenses in a quantity greater than zero...
                if (ssoLicenseCount.productPackage === "hw" && ssoLicenseCount.count && ssoLicenseCount.count > 0) {
                    isHandwrittenSold = true
                }
            })
        }
        return isHandwrittenSold
    }

    addLicensesSoldToClasses = (classList: SsoTeacherClass[]): SsoTeacherClass[] => {
        const entity = this.state.entity
        const isHandwrittenSold = this.state.isHandwrittenSold
        const workingClassList = classList

        if (workingClassList && entity && entity.licenses_sold) {
            workingClassList.forEach((classElement, classIndex)  => {
                workingClassList[classIndex].licenses_sold = entity.licenses_sold
                workingClassList[classIndex].isHandwrittenSold = isHandwrittenSold
            })
            return workingClassList
        } else {
            return []
        }
    }

    compileAssignedSeatCountsByProduct = (classList: SsoTeacherClass[]): {[key: string]: number} => {
        const assignedSeatCountsByProduct: {[key: string]: number} = {"hw": 0}

        classList.forEach((ssoTeacherClass)  => {
            // If this class has a product set...
            if (ssoTeacherClass.productName !== null) {
                // If there is no seat count member for this product...
                if (typeof (assignedSeatCountsByProduct[ssoTeacherClass.productName]) === "undefined") {
                    // Initialize a seat count member for this product, and set it to the seat count of this class.
                    assignedSeatCountsByProduct[ssoTeacherClass.productName] = Number(ssoTeacherClass.count)
                } else {
                    // Add the seat count of this class to the seat count member for this product.
                    assignedSeatCountsByProduct[ssoTeacherClass.productName] += Number(ssoTeacherClass.count)
                }

                // If this class has a test with a handwritten panel, or doesn't have a test but is allowed a handwritten panel...
                if ((ssoTeacherClass.logins && ssoTeacherClass.logins.handwritten) || (ssoTeacherClass.logins == null && ssoTeacherClass.handwritten)) {
                    // Add the seat count of this class to the handwritten seat count member.
                    assignedSeatCountsByProduct.hw += Number(ssoTeacherClass.count)
                }
            }
        })
        return assignedSeatCountsByProduct
    }

    compileConsumedSeatCountsByProduct = (classList: SsoTeacherClass[]): {[key: string]: number} => {
        const consumedSeatCountsByProduct: {[key: string]: number} = {"hw": 0}

        classList.forEach((ssoTeacherClass) => {
            // If this class has a product set...
            if (ssoTeacherClass.productName !== null) {
                // If there is no consumed seat count member for this product...
                if (typeof (consumedSeatCountsByProduct[ssoTeacherClass.productName]) === "undefined") {
                    // Initialize a consumed seat count member for this product, and set it to the consumed seat count of this class.
                    consumedSeatCountsByProduct[ssoTeacherClass.productName] = Number(ssoTeacherClass.countConsumed)
                } else {
                    // Add the consumed seat count of this class to the consumed seat count member for this product.
                    consumedSeatCountsByProduct[ssoTeacherClass.productName] += Number(ssoTeacherClass.countConsumed)
                }

                // If this class has a test, and that test has a handwritten panel...
                if (ssoTeacherClass.logins && ssoTeacherClass.logins.handwritten) {
                    // Add the consumed seat count of this class to the handwritten consumed seat count member.
                    consumedSeatCountsByProduct.hw += Number(ssoTeacherClass.countConsumed)
                }
            }
        })
        return consumedSeatCountsByProduct
    }

    viewReports = (event: React.MouseEvent<HTMLElement>, row: SsoTeacherClass): void => {
        // Create a url with a extension that gets checked in componentDidMount save the row to be used in the new tab that opens
        window.open(window.location.href + "?redirectTeacher=True", "_blank")
        localStorage.setItem("TeachThings", JSON.stringify(row))
    }
    startContinueTest = (event: React.MouseEvent<HTMLElement>, row: SsoTeacherClass): void => {
        if (row.logins == null) { return }
        ApiService.allowTest(row.logins.id)
            .then(response => {
                if (response === true) {
                    const newClassList = this.state.classList.slice()
                    for (const c of newClassList) {
                        if (c.groupId === row.groupId) {
                            c.logins.allowTest = true
                        }
                    }
                    this.setState({classList: newClassList})
                    messageStore.setInfoMessage("Test takers can be allowed in the test")
                }
            })
    }
    stopTest = (event: React.MouseEvent<HTMLElement>, row: SsoTeacherClass): void => {
        if (row.logins == null) { return }
        ApiService.disallowTest(row.logins.id)
            .then(response => {
                if (response) {
                    const newClassList = this.state.classList.slice()
                    for (const c of newClassList) {
                        if (c.groupId === row.groupId) {
                            c.logins.allowTest = false
                        }
                    }
                    this.setState({classList: newClassList})
                    messageStore.setInfoMessage("Test takers are not allowed to enter the test")
                }
            })
    }

    // This function checks the package name to confirm that the page contains writing.
    // It is completely dependent on the values in the PACKAGE_TO_FULL_NAME constant.
    isWritingInPackage = (packageVal?: string | number): boolean => {
        // Full package name.
        const packageName = String(PACKAGE_TO_FULL_NAME[String(packageVal)])

        return (packageName.includes("4S") || packageName.includes("Writing"))
    }

    handleProductChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>, row: SsoTeacherClass, tableId: number): void => {
        this.updateProduct(row, event.target.value as string, tableId)

        // If the product was changed on a selected class group...
        if (this.state.selectedIds.includes(row.groupId)) {
            // Build a class list from only the selected classes.
            const selectedClassList: SsoTeacherClass[] = []
            for (const i in this.state.classList) {
                if (this.state.selectedIds.includes(this.state.classList[i].groupId)) {
                    selectedClassList.push(this.state.classList[i])
                }
            }

            this.setTestsForSelectedClassGroupsByProduct(this.getPackageFromRows(selectedClassList))
        }
    }
    toggleHandwritten = (event: ChangeEvent<HTMLInputElement>, row: SsoTeacherClass, tableId: number): void => {
        this.updateHandwritten(row, event.target.checked, tableId)
    }
    handleBulkPackageChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
        this.setState({"bulkSelectedPkg": event.target.value as string})

        if (this.isWritingInPackage(event.target.value as string) === false) {
            this.setState({"bulkSelectedHW": false})
        }
    }
    handleBulkHandwrittenChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({"bulkSelectedHW": event.target.checked})
    }
    handleSyncClick = () => {
        SSOApi.sync()
            .then(response => {
                if (response) {
                    messageStore.setInfoMessage(response.message)
                }
            })
    }

    handleBulkProductReservationApplyButtonClick = () => { this.setState({bulkProductReservationConfirmation: true}) }
    closeBulkProductReservationConfirmationCancel = () => { this.setState({bulkProductReservationConfirmation: false}) }
    closeBulkProductReservationConfirmationProceed = () => {
        this.setState({bulkProductReservationAction: true, bulkProductReservationConfirmation: false}, this.bulkProductReservationUpdate)
    }

    handleBulkClassSetupApplyButtonClick = () => { this.setState({bulkClassSetupConfirmation: true}) }
    closeBulkClassSetupConfirmationCancel = () => { this.setState({bulkClassSetupConfirmation: false}) }
    closeBulkClassSetupConfirmationProceed = () => {
        this.setState({bulkClassSetupAction: true, bulkClassSetupConfirmation: false}, this.bulkClassSetupUpdate)
    }

    handleSelectionChange = (selectedRows: SsoTeacherClass[]): void => {
        const selectedIds = selectedRows.map((data: SsoTeacherClass) => data.groupId)
        const productOfAllSelectedRows = this.getPackageFromRows(selectedRows)
        this.setState({
            // Save the selected row data to state:
            selectedIds,
            productOfAllSelectedRows,
            // Reset the bulk panelgraph data in state:
            bulkPanelGraph: -1 as number,
            bulkPanelGraphId: 0 as number,
        })

        this.setTestsForSelectedClassGroupsByProduct(productOfAllSelectedRows)
    }

    // handleBulkDateChange = (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
    //     this.setState({"bulkDate": value, isValidBulkDate: (Boolean (date && date.isValid())) })
    // }

    handleBulkDateChange = (date: Moment | null): void => {
        this.setState({"bulkDate": date ? date.toString() : null, isValidBulkDate: (Boolean (date && date.isValid())) })
    }

    handleBulkLanguageChange = (event: React.ChangeEvent<{ name?: string; value: any }>): void => {
        const testsForSelectedClassGroups = this.state.testsForSelectedClassGroups
        const index = event.target.value

        if (testsForSelectedClassGroups && index >= 0) {
            this.setState({
                bulkPanelGraph: index as number,
                bulkPanelGraphId: testsForSelectedClassGroups[index].panelGraphId as number,
            })
        } else {
            this.setState({
                bulkPanelGraph: -1 as number,
                bulkPanelGraphId: 0 as number,
            })
        }
    }

    handleBulkUseHandwrittenToggle = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({"bulkUseHandwritten": event.target.checked})
    }

    updateProduct = (row: SsoTeacherClass, pkgShortCode: string, tableId: number): void => {
        this.updateClassProductInState(row.groupId, pkgShortCode)

        ApiService.setTestSettings(row.groupId, pkgShortCode, row.handwritten)
            .then(response => {
                if (response) {
                    const classList = this.state.classList
                    this.createCounts(classList)
                }
            })
    }
    updateHandwritten = (row: SsoTeacherClass, handwritten: boolean, tableId: number): void => {
        if (row.productName === null || row.productName === "0") { return }

        this.updateClassHandwrittenStatusInState(row.groupId, handwritten)

        ApiService.setTestSettings(row.groupId, row.productName, handwritten)
            .then(response => {
                if (response) {
                    const classList = this.state.classList
                    this.createCounts(classList)
                }
            })
    }

    updateClassProductInState = (groupId: number, pkgShortCode: string): void => {
        const classList = this.state.classList

        for (const i in classList) {
            if (classList[i].groupId === groupId) {
                classList[i].productName = pkgShortCode

                // Update the class status
                if (pkgShortCode === "0") {
                    classList[i].status = "NEEDS_DISTRICT_SETUP"
                } else {
                    classList[i].status = "DISTRICT_SETUP_NEED_CLASSROOM_SETUP"
                }
            }
        }

        this.setState({classList}, () => { this.handleSelectionChange(this.getSelectedRows()) })
    }

    updateClassHandwrittenStatusInState = (groupId: number, handwritten: boolean): void => {
        const classList = this.state.classList

        for (const i in classList) {
            if (classList[i].groupId === groupId) {
                classList[i].handwritten = handwritten
            }
        }

        this.setState({classList}, () => { this.handleSelectionChange(this.getSelectedRows()) })
    }

    getSelectedRows = (): SsoTeacherClass[] => {
        return this.state.classList.filter((teacherClass: SsoTeacherClass) => this.state.selectedIds.includes(teacherClass.groupId))
    }

    bulkProductReservationUpdate = (): void => {
        let count = 1
        this.state.selectedIds.map(groupId => {
            this.updateClassProductInState(groupId, this.state.bulkSelectedPkg)
            this.updateClassHandwrittenStatusInState(groupId, this.state.bulkSelectedHW)

            ApiService.setTestSettings(groupId, this.state.bulkSelectedPkg, this.state.bulkSelectedHW)
                .then(response => {
                    if (response) {
                        count++
                    }

                })
            this.setState({bulkProductReservationProgressCount: count})
        })
        this.setState({bulkProductReservationAction: false})
    }

    bulkClassSetupUpdate = (): void => {
        loadingSpinnerStore.hideLoadingSpinner = false
        // Initialize a counter.
        let count = 1
        // Prepare the UTC date object.
        const bulkDate = new Date (Date.parse(String(this.state.bulkDate)))
        const utcDate = TimeUtil.usDateToUtcDate(bulkDate)

        // array that checks to see if all the api calls are complete
        const promiseArray: any[] = []

        // Iterate over the selected class group IDs...
        this.state.selectedIds.map(groupId => {

            // Retrieve the matching data from the class list in state.
            const thisClassInList = this.state.classList.find(classGroup => {
                return classGroup.groupId === groupId
            })
            // Only use handwritten if the bulk use-handwritten option is on and this class is set to allow handwritten.
            const useHandwritten = (this.state.bulkUseHandwritten && (thisClassInList ? thisClassInList.handwritten : false))

            // Update the class group through the API.
            // Created a list of promises to execute after all api calls
            promiseArray.push(ApiService.setupTest(groupId, this.state.bulkPanelGraphId, useHandwritten, utcDate)
                // Success...
                .then(res => {
                    if (res) {
                        const response = res.data
                        const updatedClassList = this.state.classList

                        // Find the matching class in the class list.
                        for (const SsoTeacherClass of updatedClassList) {
                            if (response.groupId === SsoTeacherClass.groupId) {
                                // Update the class in the class list in state:

                                // Update est. testing date.
                                SsoTeacherClass.estTestingDate = response.estTestingDate ? TimeUtil.formatToUsDate(moment(response.estTestingDate)) : null

                                // Update language.
                                SsoTeacherClass.language = response.logins ? CONTENT_AREA_ID_TO_LANGUAGE_NAME[response.logins.contentareaid] : null

                                // Copy login to update handwritten.
                                SsoTeacherClass.logins = response.logins

                                // Update status.
                                SsoTeacherClass.status = response.status

                                break
                            }
                        }
                        // Write the updates to the class list in state.
                        this.setState({classList: updatedClassList})

                        // Increment the counter.
                        count++
                    }
                })
                // Exception...
                .catch((_) => {
                    messageStore.setInfoMessage("We could not set up the test for one or more classes. Please reload the page and review the test list before proceeding.")
                    // Increment the counter.
                    count++
                }))
            // Update the progress count.
            this.setState({bulkClassSetupProgressCount: count})

        })

        // This waits untill all the selected classes are registered. Once all promises are full filled it will go into the then clause removing the loading spinner.
        Promise.all(
            promiseArray
        ).then(_ => {
                loadingSpinnerStore.hideLoadingSpinner = true
            }
        )
        this.setState({bulkClassSetupAction: false})
    }

    getBulkClassUpdateSummaries = (): SsoClassUpdateWritingMethodSummary[] => {
        // Initialize the list to be returned.
        const bulkClassUpdateSummaries: SsoClassUpdateWritingMethodSummary[] = []

        // Iterate over the selected class group IDs...
        this.state.selectedIds.map(groupId => {

            // Retrieve the matching data from the class list in state.
            const thisClassInList = this.state.classList.find(classGroup => {
                return classGroup.groupId === groupId
            })
            // Determine if the handwritten value will be updated to Handwritten
            const useHandwritten = (this.state.bulkUseHandwritten && (thisClassInList ? thisClassInList.handwritten : false))

            if (thisClassInList !== undefined) {
                // Add select information to the summary.
                const classUpdateSummary: SsoClassUpdateWritingMethodSummary = {
                    groupId: thisClassInList.groupId,
                    className: thisClassInList.name ? thisClassInList.name : "[unnamed class]",
                    courseId: thisClassInList.courseId,
                    writingMethod: useHandwritten ? "Handwritten" : "Keyboard"
                }

                // Add the summary to the list to be returned.
                bulkClassUpdateSummaries.push(classUpdateSummary)
            }
        })

        return bulkClassUpdateSummaries
    }

    /**
     * If the product names in all rows are the same, returns that package name.
     * If the product names don't all match, or the package name is empty, returns empty string.
     */
    getPackageFromRows = (rows: SsoTeacherClass[]): string => {
        // Get the product names from the class group rows.
        const productNames = rows.map(row => row.productName)

        // Build a list of unique product names.
        const uniqueProductNames: string[] = []
        productNames.forEach((productName) => {
            if (!uniqueProductNames.includes(productName)) {
                uniqueProductNames.push(productName)
            }
        })

        if (uniqueProductNames.length === 1) {
            // All product names match. Get the first (and only) product name.
            const productPackageId = String(uniqueProductNames.shift())

            if (productPackageId === "0") {
                // This is a special case: the product package name is set to '0' if none is selected.
                return ""
            } else {
                return productPackageId
            }
        } else {
            // There are multiple product names among the products selected, so we can't give a single value.
            return ""
        }
    }

    setTestsForSelectedClassGroupsByProduct = (productPackageId: string): void => {
        // TODO: Store these values in a state variable, and retrieve them when available instead of making an API call every time.

        if (productPackageId === "") {
            // We don't have a valid product name, so we can't set a tests list.
            this.setState({testsForSelectedClassGroups: null})
        } else {
            ApiService.getCurrentPanelGraphs(productPackageId)
                .then(response => {
                    if (response.length) {
                        this.setState({testsForSelectedClassGroups: response})
                    } else {
                        this.setState({testsForSelectedClassGroups: null})
                    }
                })
        }
    }

    handleStartGroup = (): void => {
        ApiService.allowTestingForGroup()
            .then(response => {
                if (response === true) {
                    const newClassList = this.state.classList.slice()
                    for (const c of newClassList) {
                        c.logins.allowTest = true
                    }
                    this.setState({classList: newClassList})
                    messageStore.setInfoMessage("Test takers have been allowed into ALL tests")
                }
            })
    }
    handleStopGroup = (): void => {
        ApiService.disallowTestingForGroup()
            .then(response => {
                if (response) {
                    const newClassList = this.state.classList.slice()
                    for (const c of newClassList) {
                        c.logins.allowTest = false
                    }
                    this.setState({classList: newClassList})
                    messageStore.setInfoMessage("Test takers no longer allowed to enter ALL tests")
                }
            })
    }
    handleCloseRoster = async () => {
        await this.loadClassList()
    }

    handleOpenViewCustomAdmins = () => {

    }
    handleCloseViewCustomAdmins = () => {

    }


    getTodayForDatepicker = (): string => {
        const now = new Date()
        return (now.getUTCMonth() + 1) + "/" + now.getUTCDate() + "/" + now.getUTCFullYear()
    }

    render() {

        const classes = {paper: {
            maxWidth: 400,
            margin: `${theme.spacing(1)}px auto`,
            padding: theme.spacing(2),
        }}
        const classList = this.state.classList  ? this.state.classList : []

        // These strings are passed to the Bulk Class Setup confirmation dialog for display.
        const productName = (this.state.productOfAllSelectedRows && PACKAGE_TO_FULL_NAME[this.state.productOfAllSelectedRows]) ? PACKAGE_TO_FULL_NAME[this.state.productOfAllSelectedRows] : null
        const testLanguage = (this.state.testsForSelectedClassGroups && this.state.testsForSelectedClassGroups[this.state.bulkPanelGraph] && this.state.testsForSelectedClassGroups[this.state.bulkPanelGraph].languageName) ? this.state.testsForSelectedClassGroups[this.state.bulkPanelGraph].languageName : null
        const estimatedStartDate = this.state.bulkDate ? this.state.bulkDate : null
        const writingTestMethod = this.state.bulkUseHandwritten ? "Handwritten (where allowed)" : "Keyboard"

        return (
            <>
                <Grid container={true} spacing={3}>
                    <Grid item={true} xs={6}>
                        <Grid item={true} xs={12}>
                            <DistrictLicenseCountBanner entity={this.state.entity} assignedSeatCountsByProduct={this.state.assignedSeatCountsByProduct} consumedSeatCountsByProduct={this.state.consumedSeatCountsByProduct} />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <CleverCustomSections handleStartGroup={this.handleStartGroup} handleStopGroup={this.handleStopGroup} handleCloseRoster={this.handleCloseRoster}/>
                        </Grid>
                    </Grid>
                    <Grid container={true} xs={6}>
                        <Grid container={true} spacing={3}>
                            {this.state.entity && !this.state.entity.sync_started &&  (
                                <Grid item={true} xs={12}>
                                    <SSOSync handleSyncClick={this.handleSyncClick} syncCompleted={this.state.entity.sync_completed}/>
                                </Grid>
                            )}
                            {this.state.entity && this.state.entity.sync_started &&  (
                                <Grid item={true} xs={12}>
                                    <Paper elevation={3} style={classes.paper}>
                                        <H2Text>Synchronization in progress</H2Text>
                                        <LabelText>Started: {new Date(this.state.entity.sync_started).toString()}</LabelText>
                                    </Paper>
                                </Grid>
                            )}
                            <Grid item={true} xs={12}>
                                { this.state.entity && (
                                    <SSOBulkProductReservation
                                        handleBulkApplyButtonClick={this.handleBulkProductReservationApplyButtonClick}
                                        handleBulkPackageChange={this.handleBulkPackageChange}
                                        handleBulkHandwrittenChange={this.handleBulkHandwrittenChange}
                                        isHandwrittenSold={this.state.isHandwrittenSold}
                                        isWritingInPackage={this.isWritingInPackage}
                                        licenses_sold={this.state.entity.licenses_sold}
                                        selectedPkgValue={this.state.bulkSelectedPkg}
                                        selectedHwValue={this.state.bulkSelectedHW}
                                        totalCount={this.state.selectedIds.length}
                                    />
                                )}
                            </Grid>
                            <Grid item={true} xs={12}>
                                { this.state.entity && (
                                    <SSOBulkClassSetup
                                        handleBulkApplyButtonClick={this.handleBulkClassSetupApplyButtonClick}
                                        bulkDate={this.state.bulkDate}
                                        handleBulkDateChange={this.handleBulkDateChange}
                                        bulkPanelGraph={this.state.bulkPanelGraph}
                                        handleBulkLanguageChange={this.handleBulkLanguageChange}
                                        bulkUseHandwritten={this.state.bulkUseHandwritten}
                                        handleBulkUseHandwrittenToggle={this.handleBulkUseHandwrittenToggle}
                                        isHandwrittenSold={this.state.isHandwrittenSold}
                                        isValidBulkDate={this.state.isValidBulkDate}
                                        testsForSelectedClassGroups={this.state.testsForSelectedClassGroups}
                                        licenses_sold={this.state.entity.licenses_sold}
                                        totalCount={this.state.selectedIds.length}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item={true} xs={12}>
                         <ClassList
                            classList={classList}
                            tableRef={this.tableRef}
                            viewReports={this.viewReports}
                            startContinueTest={this.startContinueTest}
                            stopTest={this.stopTest}
                            handleProductChange={this.handleProductChange}
                            toggleHandwritten={this.toggleHandwritten}
                            updateHandwritten={this.updateHandwritten}
                            isWritingInPackage={this.isWritingInPackage}
                            licenses_sold={this.state.entity ? this.state.entity.licenses_sold : []}
                            handleSelectionChange={this.handleSelectionChange}
                         />
                    </Grid>
                </Grid>

                {this.state.bulkProductReservationConfirmation && (
                    <SSOBulkConfirm handleOK={this.closeBulkProductReservationConfirmationProceed} handleCancel={this.closeBulkProductReservationConfirmationCancel} countToChange={this.state.selectedIds.length}/>
                )}
                {this.state.bulkProductReservationAction && (
                    <SSOBulkAction current={this.state.bulkProductReservationProgressCount} totalCount={this.state.selectedIds.length}/>
                )}

                {this.state.bulkClassSetupConfirmation && (
                    <SSOBulkClassSetupConfirm
                        handleOK={this.closeBulkClassSetupConfirmationProceed}
                        handleCancel={this.closeBulkClassSetupConfirmationCancel}
                        productName={productName}
                        testLanguage={testLanguage}
                        estimatedStartDate={estimatedStartDate}
                        writingTestMethod={writingTestMethod}
                        countToChange={this.state.selectedIds.length}
                        bulkClassUpdateSummaries={this.getBulkClassUpdateSummaries()}
                    />
                )}
                {this.state.bulkClassSetupAction && (
                    <SSOBulkAction current={this.state.bulkClassSetupProgressCount} totalCount={this.state.selectedIds.length}/>
                )}


            </>
        )
    }

}
