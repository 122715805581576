import {FormControl, MenuItem, TextField} from "@material-ui/core"
import React from "react"
import {PanelSkillEnum, PanelSkillFriendlyNameEnum} from "../../../types/types"

interface SkillSelectProps {
    onSkillChanged: (selectedSkill?: string) => void
    currentSkill?: string
    disabled?: boolean
}

interface SkillSelectState {
    currentSkill?: string
    errors: string[]
}

export default class SkillSelectDropdown extends React.Component<SkillSelectProps, SkillSelectState> {
    constructor(props: SkillSelectProps) {
        super(props)

        this.state = {
            currentSkill: props.currentSkill === null ? undefined : props.currentSkill,
            errors: []
        }
    }

    componentDidUpdate(prevProps: SkillSelectProps) {
        if (this.props.currentSkill !== prevProps.currentSkill) {
            this.setState({
                 currentSkill: this.props.currentSkill === null ? undefined : this.props.currentSkill
            })
        }
    }

    handleChange = (event: any) => {
        const currentSkill = event.target.value !== "" ? event.target.value : undefined
        this.setState({ currentSkill })
        this.props.onSkillChanged(currentSkill)
    }

    render() {
        const errors = this.state.errors
        const currentSkill = this.state.currentSkill ? this.state.currentSkill : ""

        return (
            <FormControl>
                <div className="error" hidden={errors.length === 0}>
                    {errors.join(" ") || "placeholder"}
                </div>
                <TextField
                    id={"skill-dropdown"}
                    select={true}
                    onChange={this.handleChange}
                    value={currentSkill}
                    helperText="Skill"
                    style={{width: 200}}
                    disabled={this.props.disabled}
                >
                    <MenuItem value={""}/>
                    {Object.keys(PanelSkillFriendlyNameEnum).map((skill) => {
                        return (
                            <MenuItem
                                key={`skills-${skill}`}
                                id={`skills-${skill}`}
                                value={PanelSkillEnum[skill]}
                                data-tst-id={PanelSkillEnum[skill]}
                            >
                                {PanelSkillFriendlyNameEnum[skill]}
                            </MenuItem>
                        )
                    })}
                </TextField>
            </FormControl>
        )
    }
}

