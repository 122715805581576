import {some} from "lodash"

// Reference: https://html.spec.whatwg.org/multipage/media.html#event-media-canplay

export enum AudioReadyState {
    HAVE_METADATA = 1,
    HAVE_ENOUGH_DATA = 4
}

export class AudioUtils {
    static loadAudio = (srcToLoad: string, desiredReadyState: AudioReadyState) => {
        const pollInterval: number = 100
        return new Promise<void>((resolve, reject) => {
            const audio = new Audio()
            audio.onerror = reject
            const timeoutId = setInterval(() => {
                if (audio.readyState >= desiredReadyState) {
                    resolve()
                    clearInterval(timeoutId)
                }
            }, pollInterval)

            audio.src = srcToLoad
            audio.load()
        })
    }

    static isStandardAudioLink(src: string) {
        if (!src) {
            return false
        }

        const a = document.createElement("a")
        const validFileTypes = [".ogg", ".mp3", ".wav"]
        a.href = src

        const isValidLink = a.host && a.host !== window.location.host
        const isValidType = some(validFileTypes, (type) => src.toLowerCase().indexOf(type) !== -1)

        return isValidLink && isValidType
    }
}
