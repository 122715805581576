import * as _ from "lodash"
import {observer} from "mobx-react"
import React from "react"
import {Alert, Card, Col, Container, FormControl, FormGroup, FormLabel, ListGroup, ListGroupItem, Row} from "react-bootstrap"
import {connect} from "react-redux"
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux"
import {isNullOrUndefined} from "util"
import * as winston from "winston"
import {authStore} from "../../app/common/authentication/AuthStore"
import {productStore} from "../../app/common/products/ProductStore"
import {postProfileSuccess} from "../../redux/app/actions"
import ApiService from "../../services/ApiService"
import HelperService from "../../services/HelperService"
import {ApiErrorResponse, ApiPanelGraph, LastPage, Profile, State, Take, TestState} from "../../types/types"
import {CONTENT_AREA_ID_TO_LANGUAGE_NAME, SUPPORT_MESSAGE} from "../../util/Constants"
import {formString} from "../../util/elvis"
import {validateEmail} from "../../util/validators"
import {TakeId} from "../../validation/ValidPrimaryKey"
import {addTakeDispatches, TakeDispatches} from "../App/App"
import Button from "../Button/Button"
import CheckboxList from "../CheckboxList/CheckboxList"
import PlaceProfileConstants, {IProfileLanguage, IProfileTest} from "../place/PlaceProfileConstants"
import {worldSpeakProfileStore} from "./WorldSpeakProfileStore"

interface IFormErrors {
    firstName: string[]
    lastName: string[]
    testTakerId: string[]
    confirmTestTakerId: string[]
    testTakerEmail: string[]
    testsTaken: string[]
    yearsStudyingOrSpoken: string[]
    gradesStudiedIn: string[]
    familySpeaksOtherLanguage: string[]
    familyLanguage: string[]
    familyMembers: string[]
    otherFamilyMember: string[]
    languageConfidence: string[]
}

interface IStateToProps {
    take: Take | null
    takeCode: string
    panelGraph: ApiPanelGraph
}

function mapStateToProps(state: State): IStateToProps {
    return {
        take: state.app.take,
        takeCode: state.app.takeCode,
        panelGraph: state.app.panelGraph!
    }
}

interface IDispatchToProps extends TakeDispatches {
    profileSuccessDispatch: (profile: Profile) => void
}

function mapDispatchToProps(dispatch: Dispatch): IDispatchToProps {
    const dispatchs = {
        profileSuccessDispatch: (profile: Profile) => {
            dispatch(postProfileSuccess(profile))
        }
    }
    return addTakeDispatches(dispatchs, dispatch)
}

type TComponentProps = IStateToProps & IDispatchToProps & RouteComponentProps<any>

interface IComponentState {
    error: boolean | string
    profile: Profile
    apScore: string
    ibScore: string
    satiiScore: string
    stampScore: string
    opiScore: string
    wptScore: string
    opicScore: string
    take: Take | null
    showModal: boolean
    formErrors: IFormErrors
    formError: string | null
    testsTaken: string[]
    confirmTestTakerId: string
    loaded: boolean
}


@observer
class WorldSpeakProfileForm extends React.Component<TComponentProps, IComponentState> {

    constructor(props: TComponentProps) {
        super(props)
        this.state = {
            error: false,
            profile: {
                firstName: this.props.take && this.props.take.profile ? this.props.take.profile.firstName : "",
                lastName: this.props.take && this.props.take.profile ? this.props.take.profile.lastName : "",
                testTakerId: this.props.take && this.props.take.profile ? this.props.take.profile.testTakerId : "",
                testTakerEmail:
                    this.props.take && this.props.take.profile ? this.props.take.profile.testTakerEmail : "",
                yearsStudyingOrSpoken:
                    this.props.take && this.props.take.profile ? this.props.take.profile.yearsStudyingOrSpoken : "",
                gradesStudiedIn:
                    this.props.take && this.props.take.profile ? this.props.take.profile.gradesStudiedIn : [],
                testsTaken: this.props.take && this.props.take.profile ? this.props.take.profile.testsTaken : [],
                familySpeaksOtherLanguage:
                    this.props.take && this.props.take.profile
                        ? this.props.take.profile.familySpeaksOtherLanguage
                        : undefined,
                familyLanguage:
                    this.props.take && this.props.take.profile ? this.props.take.profile.familyLanguage : -1,
                familyMembers: this.props.take && this.props.take.profile ? this.props.take.profile.familyMembers : [],
                otherFamilyMember:
                    this.props.take && this.props.take.profile ? this.props.take.profile.otherFamilyMember : "",
                languageConfidence:
                    this.props.take && this.props.take.profile
                        ? this.props.take.profile.languageConfidence
                        : PlaceProfileConstants.grades[0],
                schoolName: this.props.take && this.props.take.profile ? this.props.take.profile.schoolName : "",
                testGroupName: this.props.take && this.props.take.profile ? this.props.take.profile.testGroupName : "",
                contentAreaId: this.props.panelGraph ? this.props.panelGraph.contentAreaId : -1,
                userId: authStore.auth!!.loginId ? authStore.auth!!.loginId!! : -1,
                takeCode: this.props.takeCode ? this.props.takeCode : "",
                selfEvaluationIndex:
                    this.props.take && this.props.take.profile ? this.props.take.profile.selfEvaluationIndex : 0
            },
            confirmTestTakerId:
                props.take && props.take.profile && props.take.profile.testTakerId
                    ? props.take.profile.testTakerId
                    : "",
            formErrors: {
                firstName: [],
                lastName: [],
                testTakerId: [],
                confirmTestTakerId: [],
                testTakerEmail: [],
                testsTaken: [],
                yearsStudyingOrSpoken: [],
                gradesStudiedIn: [],
                familySpeaksOtherLanguage: [],
                familyLanguage: [],
                familyMembers: [],
                otherFamilyMember: [],
                languageConfidence: []
            },
            formError: null,
            take: this.props.take,
            showModal: false,
            apScore: "",
            ibScore: "",
            satiiScore: "",
            stampScore: "",
            opiScore: "",
            wptScore: "",
            opicScore: "",
            testsTaken: [],
            loaded: false
        }
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this)
        this.handleLastNameChange = this.handleLastNameChange.bind(this)
        this.handleTestTakerIdChange = this.handleTestTakerIdChange.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handleLanguageChange = this.handleLanguageChange.bind(this)
        this.handleYearsStudyingOrSpokenChange = this.handleYearsStudyingOrSpokenChange.bind(this)
        this.handleGradesStudiedInChange = this.handleGradesStudiedInChange.bind(this)
        this.handleTestsTakenChange = this.handleTestsTakenChange.bind(this)
        this.handleFamilySpeaksOtherLanguageChange = this.handleFamilySpeaksOtherLanguageChange.bind(this)
        this.handleFamilyMemberChange = this.handleFamilyMemberChange.bind(this)
        this.handleFamilyMemberOtherChange = this.handleFamilyMemberOtherChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.closeModal = this.closeModal.bind(this)

        PlaceProfileConstants.languages.sort((a: IProfileLanguage, b: IProfileLanguage) => {
            // Special case: Always sort "OTHER" to last position.
            if (a.name === "OTHER") {
                return 1
            }
            if (b.name === "OTHER" || a.name < b.name) {
                return -1
            }
            if (a.name > b.name) {
                return 1
            }
            return 0
        })
    }

    async componentDidMount() {
        HelperService.enableTextSelection()
        await this.getData()
    }

    async componentDidUpdate() {
        if (this.props.take !== this.state.take) {
            this.setState({take: this.props.take})
        }

        if (this.props.take && this.props.take.profile && this.props.take.profile !== this.state.profile) {
            const profile = this.props.take.profile
            profile.contentAreaId = this.state.profile.contentAreaId
            profile.userId = this.state.profile.userId
            profile.takeCode = this.state.profile.takeCode
            this.setState({profile})
        }

        await this.getData()
    }

    getData = async () => {
        const driver = productStore.driver
        const product = productStore.loginProduct
        const {takeCode, panelGraph} = this.props
        const {loaded} = this.state
        if (driver && !loaded) {
            this.setState({loaded: true})
            document.title = `${driver.config.PRODUCT_TITLE} | Profile`
            let lastPage: LastPage | null = HelperService.getLastPageFromLs()
            if (lastPage) {
                const testState: TestState = await driver.helper.refreshTest(this.props, lastPage, product!)
                if (!testState.take) {
                    throw Error("testState.take is null or undefined")
                }
                if (!testState.take.profile) {
                    throw Error("testState.take.profile is null or undefined")
                }
                testState.take.profile.contentAreaId = this.props.panelGraph.contentAreaId
                testState.take.profile.userId = authStore.auth!!.loginId
                    ? authStore.auth!!.loginId!!
                    : -1
                testState.take.profile.takeCode = this.props.takeCode

                this.setState({confirmTestTakerId:
                        testState.take && testState.take.profile && testState.take.profile.testTakerId !== undefined
                            ? testState.take.profile.testTakerId
                            : ""}
                )

                this.setState({take: testState.take, profile: testState.take.profile})
            } else {
                lastPage = {
                    url: "/worldspeak-profile",
                    takeCode,
                    panelGraphId: panelGraph.id
                }
                localStorage.setItem("lastPage", JSON.stringify(lastPage))
            }
        }
    }

    handleFirstNameChange(e: any) {
        const profile: Profile = this.state.profile
        profile.firstName = formString(e.target.value, "")
        this.setState({profile})
    }

    handleLastNameChange(e: any) {
        const profile: Profile = this.state.profile
        profile.lastName = e.target.value
        this.setState({profile})
    }

    handleTestTakerIdChange(e: any) {
        const profile: Profile = this.state.profile
        profile.testTakerId = e.target.value
        this.setState({profile})
    }

    handleConfirmTestTakerIdChange = (e: any) => {
        this.setState({confirmTestTakerId: e.target.value})
    }

    handleEmailChange(e: any) {
        const profile: Profile = this.state.profile
        profile.testTakerEmail = e.target.value
        this.setState({profile})
    }

    handleLanguageChange(e: any) {
        const profile: Profile = this.state.profile
        profile.familyLanguage = e.target.value
        this.setState({profile})
    }

    handleYearsStudyingOrSpokenChange(e: any) {
        const profile: Profile = this.state.profile
        profile.yearsStudyingOrSpoken = e.target.value
        this.setState({profile})
    }

    handleProficiencyChange = (e: any) => {
        const profile: Profile = this.state.profile
        profile.languageConfidence = e.target.value
        this.setState({profile})
    }

    changHandlerForGradesStudied = (value: string) => {
        this.handleGradesStudiedInChange(value)
    }

    handleGradesStudiedInChange(value: string) {
        const profile: Profile = this.state.profile
        const gradesStudiedIn: string[] = worldSpeakProfileStore.gradesStudied
        const index: number = gradesStudiedIn.indexOf(value)
        if (index > -1) {
            gradesStudiedIn.splice(index, 1)
        } else {
            gradesStudiedIn.push(value)
        }
        profile.gradesStudiedIn = gradesStudiedIn
        this.setState({profile})
    }

    testsTakenCallBack = (value: string) => {
        const curVal: IProfileTest | null | undefined = (_.find(PlaceProfileConstants.tests, (ttp) => ttp.name === value))
        if (curVal !== undefined && curVal !== null) {
            this.handleTestsTakenChange(curVal)
        }
    }

    handleTestsTakenChange(test: IProfileTest) {
        // List of items checked in child componenet
        const testsTaken: string[] = worldSpeakProfileStore.testsTaken

        // Find the index of the testtaken
        const index: number = testsTaken.indexOf(test.name)

        if (test.name !== "None") {
            const noneIndex: number = testsTaken.indexOf("None")
            const noneIndexDB: number = worldSpeakProfileStore.testsTakenDBName.indexOf("none")
            if (noneIndex > -1) {
                // Remove Refrence to none because a test was added
                worldSpeakProfileStore.testsTaken.splice(noneIndex, 1)
                worldSpeakProfileStore.testsTakenDBName.splice(noneIndexDB, 1)
            }
            if (index > -1) {
                // An item that was checked should be unchecked
                worldSpeakProfileStore.testsTaken.splice(index, 1)
                worldSpeakProfileStore.testsTakenDBName.splice(index, 1)

            } else {
                // We have a newly checked item To add to the lists of taken tests
                worldSpeakProfileStore.testsTaken.push(test.name)
                worldSpeakProfileStore.testsTakenDBName.push(test.dbName)
            }
        } else {

            // None is the selected test =>  delete all others
            worldSpeakProfileStore.testsTaken = []
            worldSpeakProfileStore.testsTakenDBName = []
            worldSpeakProfileStore.testsTaken.push(test.name)
            worldSpeakProfileStore.testsTakenDBName.push(test.dbName)
        }

        // Below is what gets stored to the DB
        this.setState({testsTaken: worldSpeakProfileStore.testsTakenDBName})
    }

    handleRadioChange = (value: string) => {
        // Lets use checkboxes so thing look right
        const profile: Profile = this.state.profile
        if (value === "Yes") {
            profile.familySpeaksOtherLanguage = true
            worldSpeakProfileStore.radioButtonValue = ["Yes"]
        } else if (value === "No") {
            profile.familySpeaksOtherLanguage = false
            worldSpeakProfileStore.radioButtonValue = ["No"]
        }
        this.setState({profile})

    }

    handleFamilySpeaksOtherLanguageChange(e: any) {
        const profile: Profile = this.state.profile
        profile.familySpeaksOtherLanguage = e.target.value === "yes"
        this.setState({profile})
    }

    handleFamilyMemberChange(value: string) {
        const profile: Profile = this.state.profile
        const familyMembers = worldSpeakProfileStore.familyMembersSelected
        let otherFamilyMember = worldSpeakProfileStore.otherFamilyMembersSelected
        const index: number = familyMembers.indexOf(value)
        if (index > -1) {
            familyMembers.splice(index, 1)

            if (otherFamilyMember.length > 0) {
                otherFamilyMember = ""
            }
        } else {
            familyMembers.push(value)
        }
        profile.familyMembers = familyMembers
        profile.otherFamilyMember = otherFamilyMember
        this.setState({profile})
    }

    handleFamilyMemberOtherChange(e: any) {
        const profile: Profile = this.state.profile
        profile.otherFamilyMember = e.target.value
        this.setState({profile})
    }

    validate(profile: Profile): { hasErrors: boolean; formErrors: IFormErrors } {
        const formErrors: IFormErrors = {
            firstName: this.getFirstNameErrors(profile),
            lastName: this.getLastNameErrors(profile),
            testTakerId: this.getTestTakerIdErrors(profile),
            confirmTestTakerId: this.getConfirmTestTakerIdErrors(profile),
            testTakerEmail: this.getTestTakerEmailErrors(profile),
            testsTaken: this.getTestsTakenErrors(profile),
            familySpeaksOtherLanguage: this.getFamilySpeaksOtherLanguageErrors(profile),
            yearsStudyingOrSpoken: this.getYearsStudyingOrSpokenyearsStudyingOrSpoken(profile),
            gradesStudiedIn: this.getGradesStudiedInErrors(profile),
            familyLanguage: this.getFamilyLanguageErrors(profile),
            familyMembers: this.getFamilyMembersErrors(profile),
            otherFamilyMember: [],
            languageConfidence: this.getLanguageConfidenceErrors(profile)
        }

        let hasErrors: boolean = false
        Object.keys(formErrors).forEach((key: string) => {
            const value: string[] = formErrors[key]
            if (value && value.length > 0) {
                hasErrors = true
            }
        })

        return {
            hasErrors,
            formErrors
        }
    }

    getFirstNameErrors(profile: Profile): string[] {
        if (isNullOrUndefined(profile.firstName) || profile.firstName.length === 0) {
            return ["Please enter your first name."]
        }
        return []
    }

    getLastNameErrors(profile: Profile): string[] {
        if (isNullOrUndefined(profile.lastName) || profile.lastName.length === 0) {
            return ["Please enter your last name."]
        }
        return []
    }

    getTestTakerIdErrors(profile: Profile): string[] {
        if (isNullOrUndefined(profile.testTakerId) || profile.testTakerId.length === 0) {
            return ["Please enter your test taker id."]
        }
        return []
    }

    getConfirmTestTakerIdErrors(profile: Profile): string[] {
        const confirmTestTakerId = this.state.confirmTestTakerId
        const testTakerId = profile.testTakerId
        if (isNullOrUndefined(confirmTestTakerId) || confirmTestTakerId.length === 0) {
            return ["Please confirm your test taker id."]
        }
        if (confirmTestTakerId !== testTakerId) {
            return ["Your test taker id and test taker id confirmation must match."]
        }
        return []
    }

    getTestTakerEmailErrors(profile: Profile): string[] {
        if (isNullOrUndefined(profile.testTakerEmail) || profile.testTakerEmail.length === 0) {
            return ["Please enter your test taker email."]
        } else if (!validateEmail(profile.testTakerEmail)) {
            return ["Please enter a valid email address."]
        }
        return []
    }

    getTestsTakenErrors(profile: Profile): string[] {
        if (this.state.testsTaken.length === 0) {
            return ["Please select at least one option."]
        }
        return []
    }

    getFamilySpeaksOtherLanguageErrors(profile: Profile): string[] {
        if (profile.familySpeaksOtherLanguage === undefined) {
            return ["Please select an option."]
        }
        return []
    }

    getYearsStudyingOrSpokenyearsStudyingOrSpoken = (profile: Profile): string[] => {
        if (profile.yearsStudyingOrSpoken === "") {
            return ["Please choose an option."]
        }
        return []
    }

    getGradesStudiedInErrors = (profile: Profile): string[] => {
        if (profile.gradesStudiedIn === undefined || profile.gradesStudiedIn.length === 0) {
            return ["Please select at least one option."]
        }
        return []
    }

    getFamilyLanguageErrors = (profile: Profile): string[] => {
        if (profile.familySpeaksOtherLanguage && profile.familyLanguage === -1) {
            return ["Please select at least one option."]
        }
        return []
    }

    getFamilyMembersErrors = (profile: Profile): string[] => {
        if (profile.familySpeaksOtherLanguage && profile.familyMembers && profile.familyMembers.length === 0) {
            return ["Please select at least one option."]
        } else if (profile.familySpeaksOtherLanguage && profile.familyMembers) {
            const hasOther = profile.familyMembers.findIndex((familyMember: string) => familyMember.toLowerCase() === "other") !== -1
            if (hasOther && !isNullOrUndefined(profile.otherFamilyMember) && profile.otherFamilyMember.length === 0) {
                return ["Please enter the other family member you selected."]
            }
        }
        return []
    }

    getLanguageConfidenceErrors = (profile: Profile): string[] => {
        if (profile.languageConfidence === "") {
            return ["Please select at least one option."]
        }
        return []
    }

    handleSubmit() {
        this.setState({
            formError: null,
            formErrors: {
                firstName: [],
                lastName: [],
                testTakerId: [],
                confirmTestTakerId: [],
                testTakerEmail: [],
                testsTaken: [],
                yearsStudyingOrSpoken: [],
                gradesStudiedIn: [],
                familySpeaksOtherLanguage: [],
                familyLanguage: [],
                familyMembers: [],
                otherFamilyMember: [],
                languageConfidence: []
            }
        })
        if (this.props.take === null) {
            this.setState({
                error: SUPPORT_MESSAGE
            })
            return
        }

        const errors = this.validate(this.state.profile)
        if (errors.hasErrors) {
            this.setState({
                formError: "There are errors on the form.",
                formErrors: errors.formErrors
            })
            return
        }

        // Converts the test scores into an array of test db name, score pairs
        const testsTaken: string[][] = []
        if (this.state.profile.testsTaken) {
            this.state.testsTaken.forEach((test: string) => {
                if (test !== "none") {
                    testsTaken.push([test, ""])
                }
            })
        }
        const profile = this.state.profile
        profile.testsTaken = testsTaken

        if (
            profile.contentAreaId === -1 ||
            profile.userId === -1 ||
            isNullOrUndefined(profile.takeCode) ||
            profile.takeCode.length === 0
        ) {
            this.setState({
                error: SUPPORT_MESSAGE
            })
            throw new Error(`PLACE profile is invalid.`)
        } else {
            winston.debug(`[handleSubmit] About to post profile`, profile)
            const productName: string = productStore.driver!!.config.APP_PRODUCT_NAME.toLowerCase()
            ApiService.postProfile(new TakeId(this.props.take.id), profile, productName).then(
                () => {
                    this.props.profileSuccessDispatch(profile)
                    this.props.history.push("/self-evaluation")
                },
                (err: ApiErrorResponse) => {
                    if (err.response.data.errors) {
                        const formErrors = err.response.data.errors
                        this.setState({
                            formError: "There are errors on the form.",
                            formErrors
                        })
                    } else {
                        this.setState({
                            error: SUPPORT_MESSAGE
                        })
                        throw new Error(err.message)
                    }
                }
            )
        }
    }

    closeModal() {
        this.setState({showModal: false})
    }

    renderForm = () => {
        const {panelGraph} = this.props
        const {formError, formErrors} = this.state
        const testLanguage: string = CONTENT_AREA_ID_TO_LANGUAGE_NAME[panelGraph.contentAreaId]

        return (
            <form className="profile-form content-container">
                <Row>
                    <p className="large avant-blue">CREATE PROFILE</p>
                    <p style={{fontWeight: "bold", marginTop: "8px"}}>*Required</p>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <FormLabel>First Name*</FormLabel>
                                <div
                                    className={
                                        formError && formErrors.firstName.length > 0 ? "error" : "error not-visible"
                                    }
                                >
                                    {formErrors.firstName.join(" ") || ""}
                                </div>
                                <FormControl
                                    type="text"
                                    value={this.state.profile.firstName}
                                    placeholder=""
                                    data-tst-id="first-name"
                                    onChange={this.handleFirstNameChange}
                                    readOnly={productStore.loginProduct!!.rostered}
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>Last Name*</FormLabel>
                                <div
                                    className={
                                        formError && formErrors.lastName.length > 0 ? "error" : "error not-visible"
                                    }
                                >
                                    {formErrors.lastName.join(" ") || ""}
                                </div>
                                <FormControl
                                    type="text"
                                    value={this.state.profile.lastName}
                                    placeholder=""
                                    data-tst-id="last-name"
                                    onChange={this.handleLastNameChange}
                                    readOnly={productStore.loginProduct!!.rostered}
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>
                                    {`How many years have you studied or spoken ${testLanguage}?*`}
                                </FormLabel>
                                <div
                                    className={
                                        formError && formErrors.yearsStudyingOrSpoken.length > 0
                                            ? "error"
                                            : "error not-visible"
                                    }
                                >
                                    {formErrors.yearsStudyingOrSpoken.join(" ") || ""}
                                </div>
                                <FormControl
                                    as="select"
                                    placeholder="select"
                                    data-tst-id="years-studied-or-spoken"
                                    onChange={this.handleYearsStudyingOrSpokenChange}
                                    value={this.state.profile.yearsStudyingOrSpoken}
                                >
                                    {PlaceProfileConstants.zeroToTwenty.map((num: string) => {
                                        return (
                                            <option key={num} value={num}>
                                                {num}
                                            </option>
                                        )
                                    })}
                                </FormControl>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>{`What tests have you taken in ${testLanguage}?*`}</FormLabel>
                                <div
                                    className={
                                        formError && formErrors.testsTaken.length > 0 ? "error" : "error not-visible"
                                    }
                                >
                                    {formErrors.testsTaken.join(" ") || ""}
                                </div>
                                <CheckboxList
                                    changeHandler={this.testsTakenCallBack}
                                    listItems={PlaceProfileConstants.testList}
                                    checkedItems={worldSpeakProfileStore.testsTaken}
                                    height={75}
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>
                                    {`Do any members of your family speak a language other than English?*`}
                                </FormLabel>
                                <div
                                    className={
                                        formError && formErrors.familySpeaksOtherLanguage.length > 0
                                            ? "error"
                                            : "error not-visible"
                                    }
                                >
                                    {formErrors.familySpeaksOtherLanguage.join(" ") || ""}
                                </div>
                                <CheckboxList
                                    changeHandler={this.handleRadioChange}
                                    listItems={PlaceProfileConstants.radioButonValues}
                                    checkedItems={worldSpeakProfileStore.radioButtonValue}
                                    height={46}
                                />
                            </FormGroup>

                            {this.state.profile.familySpeaksOtherLanguage && (
                                <div>
                                    <FormGroup>
                                        <FormLabel>What language?*</FormLabel>
                                        <div
                                            className={
                                                formError && formErrors.familyLanguage.length > 0
                                                    ? "error"
                                                    : "error not-visible"
                                            }
                                        >
                                            {formErrors.familyLanguage.join(" ") || ""}
                                        </div>
                                        <FormControl
                                            as="select"
                                            placeholder="select"
                                            data-tst-id="family-language"
                                            onChange={this.handleLanguageChange}
                                            value={this.state.profile.familyLanguage ? this.state.profile.familyLanguage.toString() : undefined}
                                        >
                                            {PlaceProfileConstants.languages.map((language: IProfileLanguage) => {
                                                return (
                                                    language.name !== "English" &&
                                                    <option key={language.name} value={language.contentAreaId}>
                                                        {language.name}
                                                    </option>
                                                )
                                            })}
                                        </FormControl>
                                    </FormGroup>

                                    <FormGroup>
                                        <FormLabel>Identify who*</FormLabel>
                                        <div
                                            className={
                                                formError && formErrors.familyMembers.length > 0
                                                    ? "error"
                                                    : "error not-visible"
                                            }
                                        >
                                            {formErrors.familyMembers.join(" ") || ""}
                                        </div>
                                        <ListGroup data-tst-id="family-members">
                                            <CheckboxList
                                                changeHandler={this.handleFamilyMemberChange}
                                                listItems={PlaceProfileConstants.familyMembers}
                                                checkedItems={worldSpeakProfileStore.familyMembersSelected}
                                                height={46}
                                            />

                                            {worldSpeakProfileStore.familyMembersSelected.includes("Other") && (
                                            <ListGroupItem>

                                                <FormControl
                                                    type="text"
                                                    style={{fontWeight: "normal"}}
                                                    value={this.state.profile.otherFamilyMember}
                                                    placeholder=""
                                                    data-tst-id="other-family-member"
                                                    onChange={this.handleFamilyMemberOtherChange}
                                                />
                                            </ListGroupItem>)}
                                        </ListGroup>
                                    </FormGroup>
                                </div>
                            )}
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <FormLabel>Test Taker ID*</FormLabel>
                                <div
                                    className={
                                        formError && formErrors.testTakerId.length > 0 ? "error" : "error not-visible"
                                    }
                                >
                                    {formErrors.testTakerId.join(" ") || ""}
                                </div>
                                <FormControl
                                    type="text"
                                    value={this.state.profile.testTakerId}
                                    placeholder=""
                                    data-tst-id="test-taker-id"
                                    onChange={this.handleTestTakerIdChange}
                                    readOnly={productStore.loginProduct!!.rostered}
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>Confirm Test Taker ID*</FormLabel>
                                <div
                                    className={
                                        formError && formErrors.confirmTestTakerId.length > 0
                                            ? "error"
                                            : "error not-visible"
                                    }
                                >
                                    {formErrors.confirmTestTakerId.join(" ") || ""}
                                </div>
                                <FormControl
                                    type="text"
                                    value={this.state.confirmTestTakerId}
                                    placeholder=""
                                    data-tst-id="test-taker-id"
                                    onChange={this.handleConfirmTestTakerIdChange}
                                    readOnly={productStore.loginProduct!!.rostered}
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>Email*</FormLabel>
                                <div
                                    className={
                                        formError && formErrors.testTakerEmail.length > 0
                                            ? "error"
                                            : "error not-visible"
                                    }
                                >
                                    {formErrors.testTakerEmail.join(" ") || ""}
                                </div>
                                <FormControl
                                    type="text"
                                    value={this.state.profile.testTakerEmail}
                                    placeholder=""
                                    data-tst-id="email"
                                    onChange={this.handleEmailChange}
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>
                                    {`In what grades have you formerly studied ${testLanguage}?*`}
                                </FormLabel>
                                <div
                                    className={
                                        formError && formErrors.gradesStudiedIn.length > 0
                                            ? "error"
                                            : "error not-visible"
                                    }
                                >
                                    {formErrors.gradesStudiedIn.join(" ") || ""}
                                </div>
                                <CheckboxList
                                    changeHandler={this.changHandlerForGradesStudied}
                                    listItems={PlaceProfileConstants.grades}
                                    checkedItems={worldSpeakProfileStore.gradesStudied}
                                    height={46}
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>
                                    On a scale from 0 to 10 (0 is none and 10 is highly proficient), what is your
                                    overall ability with {testLanguage}
                                    ?*
                                </FormLabel>
                                <div
                                    className={
                                        formError && formErrors.languageConfidence.length > 0
                                            ? "error"
                                            : "error not-visible"
                                    }
                                >
                                    {formErrors.languageConfidence.join(" ") || ""}
                                </div>
                                <FormControl
                                    as="select"
                                    placeholder="select"
                                    data-tst-id="language-confidence"
                                    onChange={this.handleProficiencyChange}
                                    value={this.state.profile.languageConfidence}
                                >
                                    {PlaceProfileConstants.zeroToTen.map((num: string) => {
                                        return (
                                            <option key={num} value={num}>
                                                {num}
                                            </option>
                                        )
                                    })}
                                </FormControl>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Col className="margin-top">
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div className={formError ? "error" : "error not-visible"}>{formError || ""}</div>
                        </div>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Button
                                className="avant-button--default avant-button--primary"
                                block={false}
                                testId="submit"
                                onClick={this.handleSubmit}
                            >
                                SUBMIT
                            </Button>
                        </div>
                    </Col>
                </Row>
            </form>
        )
    }

    render() {
        const {error, take} = this.state

        return (
            <Container className="profile-form-container">
                {error && (
                    <div className="alerts">
                        <Alert variant="danger" onClose={() => this.setState({error: false})}>
                            {error}
                        </Alert>
                    </div>
                )}

                <Row>
                    <Col sm={12}>{take && <Card>{this.renderForm()}</Card>}</Col>
                </Row>
            </Container>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorldSpeakProfileForm)