import {ItemContent} from "../../app/common/item/models/ItemContent"
import {Section} from "../../app/section/models/Section"
import {IAction, ItemResponse, McAnswer, RecordWarningEnum, TSeconds} from "../../types/types"

export interface IUpdateResponsesAction extends IAction {
    result: ItemResponse
}

export interface IUpdateCorrectAnswerAction extends IAction {
    result: {
        correctAnswer: ItemContent
        itemId: number
    }
}

export interface IUpdateSectionAction extends IAction {
    result: Section | null
}

export interface IUpdateTimeRemainingAction extends IAction {
    result: {
        timeRemaining: TSeconds | null
        timeUsed: TSeconds | null
    }
}

export interface IStopTestAction extends IAction {
    result: boolean
}

export interface ILogoutInTestAction extends IAction {
    result: boolean
}

export interface IUpdateItemInstructionsAction extends IAction {
    result: {
        itemIndex: number
        itemContentId: number
        content: string
    }
}

export interface IUpdateItemTrainingAction extends IAction {
    result: {
        itemIndex: number
        content: string
    }
}

export interface IUpdateItemCorrectAnswerAction extends IAction {
    result: {
        itemIndex: number
        newCorrectAnswer: ItemContent
        oldCorrectAnswer: ItemContent
    }
}

export interface IUpdateTestDurationAction extends IAction {
    result: number
}

export interface IUpdateRecordingWarningSeenAction extends IAction {
    result: RecordWarningEnum
}

export const UPDATE_RESPONSES = "UPDATE_RESPONSES"
export const UPDATE_CORRECT_ANSWER = "UPDATE_CORRECT_ANSWER"
export const UPDATE_SECTION = "UPDATE_SECTION"
export const UPDATE_LAST_ITEM_INDEX = "UPDATE_LAST_ITEM_INDEX"
export const UPDATE_TIME_REMAINING = "UPDATE_TIME_REMAINING"
export const STOP_TEST = "STOP_TEST"
export const LOGOUT_IN_TEST = "LOGOUT_IN_TEST"
export const UPDATE_ITEM_INSTRUCTIONS = "UPDATE_ITEM_INSTRUCTIONS"
export const UPDATE_ITEM_TRAINING = "UPDATE_ITEM_TRAINING"
export const UPDATE_ITEM_CORRECT_ANSWER = "UPDATE_ITEM_CORRECT_ANSWER"
export const UPDATE_TEST_DURATION = "UPDATE_TEST_DURATION"
export const UPDATE_RECORDING_WARNING_SEEN = "UPDATE_RECORDING_WARNING_SEEN"

export function updateResponses(answer: McAnswer): IUpdateResponsesAction {
    return {
        type: UPDATE_RESPONSES,
        result: answer
    }
}

export function updateCorrectAnswer(correctAnswer: ItemContent, itemId: number): IUpdateCorrectAnswerAction {
    return {
        type: UPDATE_CORRECT_ANSWER,
        result: {
            correctAnswer,
            itemId
        }
    }
}

export function updateSection(section: Section | null): IUpdateSectionAction {
    return {
        type: UPDATE_SECTION,
        result: section
    }
}

export function updateTimeRemaining(
    timeRemaining: TSeconds | null,
    timeUsed?: TSeconds | null
): IUpdateTimeRemainingAction {
    timeUsed = timeUsed !== undefined ? timeUsed : null
    return {
        type: UPDATE_TIME_REMAINING,
        result: {
            timeRemaining,
            timeUsed
        }
    }
}

export function stopTest(stop: boolean): IStopTestAction {
    return {
        type: STOP_TEST,
        result: stop
    }
}

export function logoutInTest(logout: boolean): ILogoutInTestAction {
    return {
        type: LOGOUT_IN_TEST,
        result: logout
    }
}

export function updateItemInstructions(
    itemIndex: number,
    itemContentId: number,
    content: string
): IUpdateItemInstructionsAction {
    return {
        type: UPDATE_ITEM_INSTRUCTIONS,
        result: {
            itemIndex,
            itemContentId,
            content
        }
    }
}

export function updateItemTraining(itemIndex: number, content: string): IUpdateItemTrainingAction {
    return {
        type: UPDATE_ITEM_TRAINING,
        result: {
            itemIndex,
            content
        }
    }
}

export function updateItemCorrectAnswer(
    itemIndex: number,
    newCorrectAnswer: ItemContent,
    oldCorrectAnswer: ItemContent
): IUpdateItemCorrectAnswerAction {
    return {
        type: UPDATE_ITEM_CORRECT_ANSWER,
        result: {
            itemIndex,
            newCorrectAnswer,
            oldCorrectAnswer
        }
    }
}

export function updateTestDuration(duration: number): IUpdateTestDurationAction {
    return {
        type: UPDATE_TEST_DURATION,
        result: duration
    }
}

export function updateRecordingWarningSeen(recordingWarningSeen: RecordWarningEnum): IUpdateRecordingWarningSeenAction {
    return {
        type: UPDATE_RECORDING_WARNING_SEEN,
        result: recordingWarningSeen
    }
}
