import TextField from "@material-ui/core/TextField/TextField"
import * as React from "react"
import {TEvent} from "../../types/types"

export class RosterTextField extends React.Component<{
    label: string,
    value: string,
    errorMessage?: string,
    onChange: (event: TEvent) => void,
    onBlur?: (event: TEvent) => void
}, {}> {

    render() {
        return (
            <div>
                <TextField
                    value={this.props.value}
                    onChange={(event) => {
                        this.props.onChange(event)
                    }}
                    label={this.props.label}
                    helperText={this.props.errorMessage}
                    error={!!(this.props.errorMessage)}
                    variant={"outlined"}
                    fullWidth={true}
                    onBlur={this.props.onBlur}
                    onPaste={(ev: React.SyntheticEvent<HTMLElement>) => {
                        ev.preventDefault()
                        ev.stopPropagation()
                        ev.nativeEvent.stopImmediatePropagation()
                    }}
                />
            </div>)
    }

}
