import * as React from "react"
import {authStore} from "../common/authentication/AuthStore"
import {HEADER_HEIGHT, Header} from "../../components/Header/Header"
import {REPORTS_MAX_WIDTH, reportsPadding} from "../reports/common/components/ReportsPage"
import {screenUtil} from "../../util/ScreenUtil"
import {SSOWrapper} from "./components/SSOWrapper"
import {SSOAuthorizedTeacher} from "./teacher/SSOAuthorizedTeacher"
import {SSOUnauthorizedTeacher} from "./teacher/SSOUnauthorizedTeacher"
import {theme} from "../../styles/MuiThemes"
import {RouteComponentProps} from "react-router-dom";

/**
 * This page take an SSO user (authenticated at the SSO) and determines if the Teacher has been authorized to use the product
 */
export const SSOTeacherLandingPage: React.FC<RouteComponentProps> = (props) => {
    const activeComponent = authStore.isSsoAvantAuthorized ? <SSOAuthorizedTeacher {...props}/> : <SSOUnauthorizedTeacher/>
    return (
        <>
            <Header/>
            <SSOWrapper
                style={{
                    padding: reportsPadding,
                    paddingTop: HEADER_HEIGHT + theme.spacing(4),
                    display: "flex",
                    flexDirection: "column",
                    alignItems: screenUtil.screenWidth > REPORTS_MAX_WIDTH + reportsPadding * 2 ? "center" : undefined,
                }}
            >
                {activeComponent}
            </SSOWrapper>
        </>
    )
}
