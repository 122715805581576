//import {Checkbox} from "@material-ui/core"
import MaterialTable, {Column} from "material-table"
import * as React from "react"
import {useRef, useState} from "react"
import {messageStore} from "../../../app/common/messages/MessageStore"
import ApiService from "../../../services/ApiService"

function buildSort(orderByField: string | unknown, orderDirection: string): string {
    let sortString = ""
    if (orderByField) {
        sortString = `&sort=${orderByField},${orderDirection}`
    }
    return sortString
}

function buildPredicate(filters: any): string {
    const predicateArray: string[] = []
    filters.forEach((filter: any) => {
        if (filter.column.field === "message") {
            predicateArray.push(`message=contains(${filter.value})`)
        }
    })
    if (predicateArray.length === 0) {
        return ""
    }
    return `&${predicateArray.join("&")}`
}

async function fetchWallMessages(query: any) {
    const sort = (query.orderBy) ? buildSort(query.orderBy.field, query.orderDirection) : ""
    const predicate = (query.filters) ? buildPredicate(query.filters) : ""

    const response = await ApiService.get(`${ApiService.API_URL}rest/messageWalls?page=${query.page}&size=${query.pageSize}${sort}${predicate}`)
    const wallMessageData = response.data._embedded.messageWalls
    return {
        data: wallMessageData,
        page: response.data.page.number,
        totalCount: response.data.page.totalElements
    }
}


export const MessageWallAdminBase: React.FC = () => {
    const tableRef = React.createRef()

    const initialColumns: Column<any>[] = [
        {
            title: "Wall Message",
            field: "message",
            sorting: false,
            searchable: false,
            editable: "always",
            removable: false,
            filtering: false,

        },
        {
            title: "Is Displayed",
            field: "display",
            type: "boolean",
            sorting: false,
            searchable: false,
            filtering: false,
            editable: "always", // we handle this specially to use a checkbox
            removable: false
        },
    ]
    const [columns] = useState(initialColumns)

    const isCanceled = useRef(false)

    const patchRow = (patchLink: string , data: {}) => {
        ApiService.patch(patchLink, data)
            .then(_ => {if (!isCanceled.current) {
                // @ts-ignore
                if (tableRef.current) { tableRef.current.onQueryChange() }
                messageStore.setInfoMessage("Updated Row")}
            })
            .catch(e => {if (!isCanceled.current) {messageStore.setErrorMessage(e.toString())}})

    }

    return (
        <>
            <h1>Wall Message Admin</h1>
            <MaterialTable
                tableRef={tableRef}
                title="Wall Messages"
                columns={columns}
                data={fetchWallMessages}
                options={{
                    filtering: false,
                    search: false,
                    showTitle: true,
                    toolbar: true,
                    padding: "dense",
                    pageSize: 100,
                    pageSizeOptions: [100, 200, 500, 1000],
                    exportButton: true,
                    columnsButton: false,
                    debounceInterval: 500,
                    exportFileName: "MessageWallData",
                    tableLayout: "auto",
                }}
                cellEditable={{
                    onCellEditApproved: (newValue: any, oldValue: any, rowData: any, columnDef: any) => {
                        return new Promise((resolve, reject) => {
                            const updateField = {}
                            updateField[columnDef.field] = newValue
                            patchRow(rowData._links.self.href, updateField)
                        })
                    }
                }}

            />
        </>
    )
}

export const MessageWallAdmin = React.memo(MessageWallAdminBase)
