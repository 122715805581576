import {createStyles, List, ListItem, makeStyles} from "@material-ui/core"
import {ListItemTextProps} from "@material-ui/core/ListItemText"
import React from "react"
import {AvantColors} from "../../../../../styles/AvantColors"
import {SmallBodyText, SmallLabelText} from "../../../../../styles/AvantTypography"
import {theme} from "../../../../../styles/MuiThemes"

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            "& > li:nth-child(even)": {
                backgroundColor: AvantColors.REPORT_BACKGROUND_GRAY_LIGHT,
            }
        }
    }))

/*
    The optional props isHandwritten and panelDisplayName are passed only to the SidebarListItems for the total time
    in section, Section Start Time, and Section Completed Date. If isHandwritten is true, panelDisplayName is "WRITING",
    and primary is one of three options above, then the string "Handwritten" is displayed instead of the duration for that
    section, and the text "N/A" is displayed for the section start and finish time because we do not want to track or
    display the amount of time in a handwritten writing section.
*/
interface SideBarListItemProps extends ListItemTextProps {
    isHandwritten?: boolean,
    panelDisplayName?: string
}

export const SidebarList: React.FC = ({children}) => {
    const classes = useStyles()

    return (
        <List
            dense={true}
            disablePadding={true}
            style={{width: "100%", marginBottom: theme.spacing(3), marginTop: theme.spacing(1)}}
            classes={classes}
            component={"ul"}
        >
            {children}
        </List>
    )
}

export const SidebarListItem: React.FC<SideBarListItemProps> = ({primary, secondary, isHandwritten, panelDisplayName}) => {
    if (!secondary && secondary !== 0) {
        return null
    }

    // if the words on either header or subject are more than 20 characters we will add more space to the height of our rows
    const longtext = Math.max(Math.floor(String(primary).length / 20), Math.floor(String(secondary).length / 20))
    /*
        I've extracted text that is displayed in the ListItem into this component to handle the cases where the secondary
        prop is / is not a string (this was preexisting logic in the component) as well as the case where the ListItem
        describes the "Total Time in Section:", Section start time, or section completed date for a Writing section that
        is marked as handwritten. See the notes above SideBarListItemProps in this component for more details.
    */
    const ListItemText = () => {
        if (typeof secondary === "string") {
            if (isHandwritten && panelDisplayName === "WRITING" && primary === "Total Time in Section:") {
                return (
                    <SmallBodyText align={"right"} style={{maxWidth: "100%", wordWrap: "break-word"}}>
                        Handwritten
                    </SmallBodyText>
                )
            } else if (isHandwritten
                    && panelDisplayName === "WRITING"
                    && (primary === "Section Start Time:" || primary === "Section Completed Date:")) {
                return (
                    <SmallBodyText align={"right"} style={{maxWidth: "100%", wordWrap: "break-word"}}>
                        N/A
                    </SmallBodyText>
                )
            } else {
                return (
                    <SmallBodyText align={"right"} style={{maxWidth: "100%", wordWrap: "break-word"}}>
                        {secondary}
                    </SmallBodyText>
                )
            }
        } else {
            if (isHandwritten && panelDisplayName === "WRITING" && primary === "Total Time in Section:") {
                return (
                    <>Handwritten</>
                )
            } else if (isHandwritten
                    && panelDisplayName === "WRITING"
                    && (primary === "Section Start Time:" || primary === "Section Completed Date:")) {
                return (
                    <>N/A</>
                )
            } else {
                return (
                    <>{secondary}</>
                )
            }
        }
    }

    return (
        <ListItem
            disableGutters={true}
            style={{justifyContent: "space-between", padding: theme.spacing(0, 3), height: 32 + longtext * 16}}
        >
            <SmallLabelText>{primary}</SmallLabelText>
            <ListItemText />
        </ListItem>
    )
}
