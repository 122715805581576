import React, { Component } from "react";
import {ProgressBar} from "react-bootstrap"
import FontAwesome from "react-fontawesome"
import {Section} from "../../app/section/models/Section"

import {ProductDriver} from "../../app/common/products/ProductStore"
import {ApiPanelGraph, LoginProductContentAreaData, TestEngineOptions} from "../../types/types"
import {ADVANCE_PRODUCT_ID, STAMP_4S_PRODUCT_ID,STAMP_SIGN_LANGUAGE_PRODUCT_ID} from "../../util/Constants"
import Button from "../Button/Button"
import PanelTimerContainer from "../PanelTimerContainer"
import {contentAreaStore} from "../../app/common/contentArea/ContentAreaStore"

interface IComponentProps {
    config?: TestEngineOptions
    outOfTimeCallback?: () => void
    driver: ProductDriver
    progressInSection?: number
    handleBack: () => void
    handleNext: () => void
    answered: boolean
    testName: string
    product: LoginProductContentAreaData
    isFirstItem: boolean
    isLastItem: boolean
    section: Section
    isRtl: boolean
    panelGraph?: ApiPanelGraph | null
}
interface IComponentState {
    languageName: string | null;
}
export default class StatusBar extends Component<IComponentProps, IComponentState> {
    constructor(props: IComponentProps) {
        super(props);
        this.state = {
            languageName: null
        };
    }
    componentDidMount() {
        if (this.props.panelGraph) {
            contentAreaStore.fetchContentAreas().then(() => {
                if (contentAreaStore.contentAreas.length) {
                    const languageName = contentAreaStore.convertContentAreaIdToLanguageName(this.props.panelGraph!.contentAreaId);
                    this.setState({ languageName });
                }
            });
        }
    }

    render() {
        const {driver} = this.props
        const { languageName } = this.state;
        if (driver === undefined) {
            return null
        }

        const productId = this.props.product.productId
        const isAdvance = productId === ADVANCE_PRODUCT_ID.value()
        const progress: number[] = getProgress(this.props.section, this.props.progressInSection)
        let statusBarClass = "status-bar"
        if (isAdvance) {
            statusBarClass = "status-bar status-bar--centered"
        }
        if (this.props.isRtl) {
            statusBarClass = "status-bar status-bar--rtl"
        }

        let statusContainerClass = "status-bar__status-container"
        if (isAdvance) {
            statusContainerClass = "status-bar__status-container status-bar__status-container--centered"
        }
        if (this.props.isRtl) {
            statusContainerClass = "status-bar__status-container status-bar__status-container--rtl"
        }

        return (
            <div className={statusBarClass}>
                {isAdvance && (
                    <Button
                        className="status-bar__button"
                        onClick={this.props.handleBack}
                        disabled={this.props.isFirstItem}
                        testId="back"
                    >
                        <FontAwesome name="chevron-circle-left"/> BACK
                    </Button>
                )}

                <div className={statusContainerClass}>
                    <div className="status-bar__section">{languageName}</div>
                    <div className="status-bar__status">
                        {this.props.testName === "Listening" && this.props.product.productId === STAMP_4S_PRODUCT_ID.value()
                            ? ("Listening")
                            : this.props.testName === "Listening" && this.props.product.productId === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()
                            ? ("Receptive")
                            :this.props.testName === "Speaking" && this.props.product.productId === STAMP_SIGN_LANGUAGE_PRODUCT_ID.value()
                            ? ("Expressive")
                            :
                            (this.props && this.props?.testName)
                        }
                    </div>
                </div>

                {typeof this.props.progressInSection !== 'undefined' &&
                !isAdvance &&
                progress.map((progressInSection: number, index) => {

                    return (
                        <ProgressBar
                            key={index+1}
                            className={
                                !this.props.isRtl
                                    ? "status-bar__progress"
                                    : "status-bar__progress status-bar__progress--rtl"
                            }
                            now={progressInSection}
                        />
                    )
                })}

                {this.props.config && this.props.config.showTimer && this.props.outOfTimeCallback !== undefined && (
                    <PanelTimerContainer outOfTimeCallback={this.props.outOfTimeCallback}/>
                )}

                {!this.props.isRtl ? (
                    <Button
                        className="status-bar__button status-bar__button--next"
                        onClick={this.props.handleNext}
                        testId="next"
                        disabled={!isAdvance && !this.props.answered}
                    >
                        {driver.strings.next} <FontAwesome name="chevron-circle-right"/>
                    </Button>
                ) : (
                    <Button
                        className="status-bar__button status-bar__button--rtl-next"
                        onClick={this.props.handleNext}
                        testId="next"
                        disabled={!isAdvance && !this.props.answered}
                    >
                        <FontAwesome name="chevron-circle-left"/> {driver.strings.next}
                    </Button>
                )}
            </div>
        )
    }
}

export const getProgress = (section: Section, progressInSection?: number): number[] => {
    const sectionCount = section
        ? section.info.numSections > 0
            ? section.info.numSections
            : 1
        : 0
    const currentSection = section
        ? section.info.currentSection > 0
            ? section.info.currentSection
            : 1
        : 0
    const sectionProgress = progressInSection ? progressInSection : 0

    const progress: number[] = []
    // sections are 1 indexed, meaning first section is section 1 not section 0
    for (let i = 1; i < sectionCount + 1; i++) {
        if (i < currentSection) {
            progress.push(100)
        } else if (i === currentSection) {
            progress.push(sectionProgress)
        } else {
            progress.push(0)
        }
    }

    return progress
}
