import {makeStyles, TableCell, TableSortLabel} from "@material-ui/core"
import React from "react"
import {AvantColors} from "../../../../../styles/AvantColors"
import {LabelText, SmallLabelText} from "../../../../../styles/AvantTypography"
import {UnstyledLink} from "../../../../generic-components/UnstyledLink"
import {ClassInfoRow} from "../../models/ClassInfoRow"
import {GroupSortName} from "../../models/GroupReportRow"
import {SchoolInfoRow} from "../../models/SchoolInfoRow"
import {DistrictOrSchoolTableStore, districtTableStore, schoolTableStore} from "./DistrictOrSchoolTableStore"

const changeSortHandler = (property: GroupSortName, store: DistrictOrSchoolTableStore<SchoolInfoRow> | DistrictOrSchoolTableStore<ClassInfoRow>) => {
    return () => {
        if (store.activeTableSort === property) {
            store.tableSortDir = store.tableSortDir === "desc" ? "asc" : "desc"
        } else {
            store.activeTableSort = property
        }
        store.resort()
    }
}

interface HeaderCellProps {
    title: string
    property: GroupSortName
}

interface HeaderCellPropsWithStore extends HeaderCellProps {
    store: DistrictOrSchoolTableStore<SchoolInfoRow> | DistrictOrSchoolTableStore<ClassInfoRow>
}

export const DistrictTableHeaderCell = (props: HeaderCellProps) => <TableHeaderCell {...props} store={districtTableStore}/>
export const SchoolTableHeaderCell = (props: HeaderCellProps) => <TableHeaderCell {...props} store={schoolTableStore}/>

const TableHeaderCell = (props: HeaderCellPropsWithStore) => {
    const {store, title, property} = props
    const isActive = store.activeTableSort === property
    return (
        <TableCell>
            <TableSortLabel
                active={store.activeTableSort === property}
                direction={store.tableSortDir}
                onClick={changeSortHandler(property, store)}
            >
                <SmallLabelText
                    style={{
                        fontWeight: isActive ? "bold" : "normal"
                    }}
                >
                    {title}
                </SmallLabelText>
            </TableSortLabel>
        </TableCell>
    )
}

export const DistrictOrSchoolTableCellLink = (props: { contents: string, link: string, enabled: boolean, school?: boolean, printing?: boolean }) => {
    const {contents, link, enabled, school, printing} = props

    // These styles add bold font weight and onHover styles for items in the report table that have data available
    const useClickableStyles = makeStyles({
        clickableText: {
            "& *": {
                transition: "all 0.2s",
                fontWeight: "bold"
            },
            "&:hover *": {
                color: AvantColors.avantDarkBlue,
                textDecoration: "underline",
            }
        }
    })
    const clickableStyles = useClickableStyles()

    const textContents = school ? (
        <SmallLabelText style={{color: enabled ? "" : AvantColors.REPORT_FONT_COLOR_GRAY_3}}>
            {contents}
        </SmallLabelText>
    ) : (
        <LabelText style={{color: enabled ? "" : AvantColors.REPORT_FONT_COLOR_GRAY_3}}>
            {contents}
        </LabelText>
    )

    return (
        <TableCell style={{border: school ? "none" : ""}}>
            {(printing || enabled===false) ? textContents : (
                <UnstyledLink to={printing ? "" : link} className={clickableStyles.clickableText}>
                    {textContents}
                </UnstyledLink>
            )}
        </TableCell>
    )
}
