import * as React from "react"

export const AvantPaper = (props: {children: React.ReactNode, style?: React.CSSProperties}) => (
    <div
        children={props.children}
        style={{
            boxSizing: "border-box",
            border: "1px solid #CDCDCD",
            borderRadius: 6,
            backgroundColor: "#FFFFFF",
            ...props.style
        }}
    />
)
