import {Button, createStyles, Theme} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import React, {useEffect, useState} from "react"
import SSO_BUTTON from "../../assets/Clever/cleverLoginButton.png"
import CleverApi from "../../services/CleverApi"

const useCleverLoginStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
            textAlign: "center"
        },
        button: {
            padding: 0,
            maxWidth: 200
        },
        image: {
            width: "168px"
        }
    })
)
interface CleverLink {
    url: string
}
export const SignOnWithCleverButton: React.FC = (props) => {
    const loadLink = async () => {
        const link = await CleverApi.getCleverId()
        setCleverLink({url: link.cleverUrl})
    }
    const [cleverLink, setCleverLink] = useState<CleverLink>({url: ""})
    useEffect(() => {
        loadLink()
    }, [])
    const classes = useCleverLoginStyles()
    const authWithClever = () => {
        window.location.href = cleverLink.url
    }
    return (
        <div className={classes.root}>
            <Button className={classes.button} onClick={authWithClever}>
                <img src={SSO_BUTTON} className={classes.image} />
            </Button>
        </div>
    )
}
