import * as React from "react"
import ApiService from "../../services/ApiService"

export class AvantLogo extends React.Component<{ title?: string }, {}> {
    render() {
        return (
            <div style={{marginBottom: 24, textAlign: "center"}}>
                <img
                    style={{width: 156, margin: "auto"}}
                    src={`${ApiService.CDN_BASE_URL}resources/common/img/avant-logo.svg`}
                    alt={"Avant Logo"}
                    tabIndex={0}
                />
                <div
                    style={{
                        color: "#767676",
                        fontFamily: "Cera, sans-serif",
                        fontSize: 24,
                        fontWeight: "bold",
                        letterSpacing: 1,
                        textAlign: "center",
                        marginTop: 24
                    }}
                >
                    {this.props.title}
                </div>
            </div>
        )
    }
}
